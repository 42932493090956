import React, { useState, FormEvent, useEffect } from 'react';
import './Register.scss';
import { BaseReact } from '../../base.model';
import LogoFullSvg from '../visuals/LogoSvg';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import { Link, Redirect } from 'react-router-dom';

import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import { ProfessionalProfile } from '../../models/Profile';
import GraphQlClient from '../../services/graphql';
import Select from '../Forms/Select';
import { getCookies } from '../../services/document.cookie';
import { REGIMEN_OPTIONS } from '../../utils/regimen.options';


interface RegisterProps extends BaseReact { }


function Register({ history, location }: RegisterProps) {


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    // Account data
    const [phone, setPhone] = useState('');
    const [rfc, setRfc] = useState('');
    const [regimen, setRegimen] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [lugarExpedicion, setLugarExpedicion] = useState('');

    const [registerVersion, setRegisterVersion] = useState<'lite' | 'profesional'>('lite')

    const [registerRedirect, setRegisterRedirect] = useState(false);
    const [registering, setRegistering] = useState(false);

    const [hasError, setHasError] = useState<{ [key: string]: boolean }>({})


    useEffect(() => {
        const query = location.search.replace('?', '').split('&').filter(s => !!s).reduce((acc, curr) => {
            const [key, value] = curr.split('=');
            acc[key] = value;
            return acc;
        }, {} as { [key: string]: string });
        if (query['version']) {
            setRegisterVersion(query['version'] as 'lite' | 'profesional')
        }
        console.log('Query: ', query);
    }, [location])

    useEffect(() => {
        const { prof_profileid } = getCookies();
        const storageSession = localStorage.getItem('prof_profileid')
        if (prof_profileid || storageSession) {
            // Self contained 
            history.replace('/dashboard');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [registerAccount] = useMutation(graphqlSchema.PROFILE.SETUP.registerAccount, {
        onCompleted: ({ registerAccount }: { registerAccount: { professionalProfile: ProfessionalProfile, token: string } }) => {
            console.log('Registration: ', registerAccount);
            // UseReducer to update object array
            setRegistering(false);
            GraphQlClient.logSession(registerAccount.token)
                .then(d => {
                    console.log('Me Result: ', d);
                    setRegisterRedirect(true);
                });
        },
        onError: (e) => {
            setRegistering(false);
            console.error('Error Registration: ', e);
        }
    })

    const _setEmail = (e: string) => {
        setEmail(e.trim().toLowerCase())
    }

    const _setCompanyName = (e: string) => {
        setCompanyName(e.toUpperCase().trimLeft())
    }

    const _setRfc = (e: string) => {
        setRfc(e.toUpperCase().trimLeft().trimRight())
    }

    const submitRegistration = (e: FormEvent) => {
        e.preventDefault();
        if (passwordConfirm !== password) {
            return;
        }
        if (!password || !phone || !rfc || !companyName || !email || !lugarExpedicion || !regimen) {
            setHasError({
                phone: !phone,
                rfc: !rfc,
                password: !password,
                companyName: !companyName,
                email: !email,
                lugarExpedicion: !lugarExpedicion,
                regimen: !regimen,
            })
            return
        }
        setHasError({})
        setRegistering(true);
        const { _ad_origin } = getCookies()
        registerAccount({
            variables: {
                client: {
                    email,
                    phone,
                    password,
                    regimenFiscal: regimen,
                    nombre: companyName,
                    rfc,
                    lugarExpedicion,
                    registerVersion,
                    adOrigin: _ad_origin || null
                }
            }
        })
    }

    const regimenOptions = REGIMEN_OPTIONS;

    return (
        <div id="Register">
            <form className="_loginForm" onSubmit={submitRegistration}>
                <LogoFullSvg size={252} />
                <h1>¡Bienvenido!</h1>
                <div className="rightSecction">
                    <div className="row three sm-two">
                        <div className="useTwo">
                            <Input hasError={hasError['email']} type="text" label="Email a registrar" value={email} onChange={_setEmail} placeholder="Tu email" />
                        </div>
                        <Input hasError={hasError['phone']} type="text" label="Teléfono" value={phone} onChange={setPhone} placeholder="4423985564" />
                    </div>
                    <div className="row two xs-gap-compressed">
                        <Input type="password" label="Contraseña" value={password} onChange={setPassword} placeholder="Contraseña" hasError={(password && passwordConfirm && (password !== passwordConfirm)) || hasError['password']} />
                        <Input type="password" label="Confirmar Contraseña" value={passwordConfirm} onChange={setPasswordConfirm} placeholder="Repetir Contraseña" hasError={password && passwordConfirm && (password !== passwordConfirm)} />
                    </div>
                    <Input hasError={hasError['companyName']} type="text" label="Nombre de tu Emisor o Razón Social" value={companyName} onChange={_setCompanyName} placeholder="No incluír siglas (S.A, C.V, etc.)" />
                    <div className="row two xs-gap-compressed">
                        <Input hasError={hasError['rfc']} type="text" label="RFC" value={rfc} onChange={_setRfc} placeholder="Tu RFC" />
                        <Input hasError={hasError['lugarExpedicion'] || (lugarExpedicion && lugarExpedicion.length > 5)} type="text" label="Codigo postal Fiscal" value={lugarExpedicion} onChange={setLugarExpedicion} placeholder="76020" />
                    </div>
                    <Select hasError={hasError['regimen']} label="Régimen" value={regimen} onChange={setRegimen} placeholder="Escoge tu régimen" options={regimenOptions} />

                    <div className="actions">
                        <span className="alternate">
                            ¿Ya tienes cuenta?
                            <Link to="/login">
                                Sign in
                            </Link>
                        </span>
                        <Button primary={true} type="submit" disabled={registering}>
                            {
                                registering ?
                                    <span className='small'>Registrando tu cuenta...</span>
                                    :
                                    <span>Registar</span>
                            }
                        </Button>
                    </div>
                </div>
            </form>
            {
                registerRedirect && registerVersion === 'lite' ?
                    <Redirect to="/setup/emisor?showcerts=true" />
                    : ''
            }
            {
                registerRedirect && registerVersion === 'profesional' ?
                    <Redirect to="/planes?planType=professional&step=2" />
                    : ''
            }
        </div>
    )
}

export default Register;