import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { PaybookAccount } from '../../../../models/Administracion/Bank.Accounts';
import { BankProject } from '../../../../models/Administracion/Bank.Projects';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import { graphqlSchema } from '../../../../services/graphql.schema';
import Loading from '../../../Animations/loadScreen';
import NumberDisplay from '../../../components/numberDisplay';
import BarLineAndSpreadChart from '../../../Graphs/BarAndLine/BarLineAndSpread.chart';
import useWindowResize from '../../../Hooks/useWindowResize';
import { formatLineGroupsFromBankSummaries } from '../../helpers/lineGroups.formatting';

import './AdministrationHub.Proyections.graph.scss';

interface AdministrationHubProyectionsGraphProps {
    projects: BankProject<'business'>[]
}


function projectMilestonesFitToLineData(projects: BankProject<'business'>[], lineData: { x: number, y: number }[]) {
    if (!lineData.length || !projects.length) {
        return []
    }

    const projectConcepts = projects.reduce((p, c) => {
        if (c.expenses?.length) {
            p = p.concat(c.expenses.filter(e => e.transaction?.length).map((e) => ({ startsAt: Math.min(...e.transaction.map(t => t.dt_transaction)), name: e.name, type: 'expense' })))
        }
        if (c.incomes?.length) {
            p = p.concat(c.incomes.filter(e => e.transaction?.length).map((e) => ({ startsAt: Math.min(...e.transaction.map(t => t.dt_transaction)), name: e.name, type: 'income' })))
        }
        return p;
    }, [] as { startsAt: number, name: string, type: 'expense' | 'income' }[]).filter(pC => (pC.startsAt >= lineData[0].x) && (pC.startsAt <= lineData[lineData.length - 1].x))

    // console.log('MILES projectConcepts', projectConcepts)

    const tsClosestX = [...new Set(projectConcepts.map(pC => pC.startsAt))].sort((a, b) => a - b).reduce((p, c) => {
        const closestX = lineData.reduce((comp, { x }) => {
            const distance = Math.abs(x - c);
            if (comp.distance > distance) {
                return { x, distance }
            } else {
                return comp;
            }
        }, { x: lineData[0].x, distance: Math.abs(lineData[0].x - c) })
        p[c] = closestX.x
        return p;
    }, {} as { [key: number]: number })

    const milestones: { x: number, y: number, text: string }[] = Object.keys(tsClosestX).map(t => parseInt(t)).map((conceptTs) => {
        const concepts = projectConcepts.filter(pC => pC.startsAt === conceptTs)
        const lineX = tsClosestX[conceptTs]
        const lineY = lineData.find(lD => lD.x === lineX).y;
        const expenseConcepts = concepts.filter(c => c.type === 'expense');
        const incomeConcepts = concepts.filter(c => c.type === 'income');
        let text = '';
        if (expenseConcepts.length > 1 && incomeConcepts.length > 1) {
            text = `${expenseConcepts.length} gastos y ${incomeConcepts.length} ingresos de proyectos inician`;
        }
        else if (expenseConcepts.length > 1 && incomeConcepts.length === 1) {
            text = `${expenseConcepts.length} gastos y el ingreso "${incomeConcepts[0].name}" de proyectos inician`
        }
        else if (expenseConcepts.length === 1 && incomeConcepts.length === 1) {
            text = `Gaasto "${expenseConcepts[0].name}" y el ingreso "${incomeConcepts[0].name}" de proyectos inician`
        }
        else if (expenseConcepts.length > 1) {
            text = `${expenseConcepts.length} gastos de proyectos inician`
        }
        else if (incomeConcepts.length > 1) {
            text = `${incomeConcepts.length} ingresos de proyectos inician`
        }
        else if (expenseConcepts.length === 1) {
            text = `Gasto de proyecto "${expenseConcepts[0].name}" inicia`
        }
        else if (incomeConcepts.length === 1) {
            text = `Ingreso de proyecto "${expenseConcepts[0].name}" inicia`
        }
        return {
            x: lineX,
            y: lineY,
            text
        }
    })

    return milestones;
}

// MAIN COMPONENT
// =====================

interface MonthPeriodSummary {
    income: number;
    expense: number;
    incomeProjects: number;
    expenseProjects: number;
}

function AdministrationHubProyectionsGraph({ projects }: AdministrationHubProyectionsGraphProps) {

    const { windowWidth } = useWindowResize()

    const [summariesLoaded, setSummariesLoaded] = useState(false);
    const [summaryGlobalOrigin, setSummaryGlobal] = useState<BankMovementSummary[]>([]);
    const [summaryDailyOrigin, setSummaryDaily] = useState<BankMovementSummary[]>([]);


    const [thisMonthPeriod, setThisMonthPeriod] = useState<MonthPeriodSummary>(null)
    const [lastMonthPeriod, setLastMonthPeriod] = useState<MonthPeriodSummary>(null)
    // Last Month is used for arrow directions in NumberDisplays
    const [accounts, setAccounts] = useState<PaybookAccount[]>([])


    const [getBankAccounts] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankAccounts, {
        onCompleted: ({ getBankAccounts }: { getBankAccounts: PaybookAccount[] }) => {
            console.log(`(AdminHub) <getBankAccounts> `, getBankAccounts);
            setAccounts(getBankAccounts);

        },
        fetchPolicy: 'cache-and-network'
    })

    const [getMovementsSummary] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankMovementsSummary, {
        onCompleted: ({ getBankMovementsSummary }: { getBankMovementsSummary: BankMovementSummary[] }) => {
            const _getBankMovementsSummary: BankMovementSummary[] = JSON.parse(JSON.stringify(getBankMovementsSummary))
            
            // NOTE: Remove internal movements from global summary
            // >> This way, global summary doesn't reflect internal movements
            _getBankMovementsSummary.forEach(bMS => {
                if(bMS.internalExpense) {
                    bMS.expense -= bMS.internalExpense;
                }
                if(bMS.internalIncome) {
                    bMS.income -= bMS.internalIncome;
                }
            })


            const _summaryDaily = _getBankMovementsSummary.reduce((p, c) => {
                const exists = p.find(e => e.date === c.date);
                if (exists) {
                    exists.expense += c.expense;
                    exists.income += c.income;
                    exists.expenseByProject = exists.expenseByProject.concat(c.expenseByProject);
                    exists.incomeByProject = exists.incomeByProject.concat(c.incomeByProject);
                } else {
                    const _c = JSON.parse(JSON.stringify(c))
                    p.push(_c)
                }
                return p;
            }, [] as BankMovementSummary[]).sort((a, b) => b.date - a.date); // Oldest to newest is intentional

            const _summaryGlobal = _getBankMovementsSummary.reduce((p, c) => {
                const _date = moment(c.date).startOf('month').valueOf()
                const exists = p.find(e => e.date === _date);
                if (exists) {
                    exists.expense += c.expense;
                    exists.income += c.income;
                    exists.expenseByProject = exists.expenseByProject.concat(c.expenseByProject);
                    exists.incomeByProject = exists.incomeByProject.concat(c.incomeByProject);
                } else {
                    const _c = JSON.parse(JSON.stringify(c))
                    p.push(Object.assign({}, _c, { date: _date }))
                }
                return p;
            }, [] as BankMovementSummary[]).sort((a, b) => b.date - a.date)


            const forCurrentMonthPeriod = _summaryGlobal.filter(sG => sG.date >= moment().startOf('month').valueOf());
            const _thisMonthPeriod = forCurrentMonthPeriod.reduce((exists, c) => {
                exists.expense += c.expense;
                exists.income += c.income;
                exists.incomeProjects += c.incomeByProject.reduce((sum, iBP) => sum + iBP.totalIncome, 0);
                exists.expenseProjects += c.expenseByProject.reduce((sum, eBP) => sum + eBP.totalExpense, 0);
                return exists;
            }, { date: forCurrentMonthPeriod[0]?.date || null, expense: 0, id_account: forCurrentMonthPeriod[0]?.id_account || null, income: 0, expenseProjects: 0, incomeProjects: 0 } as MonthPeriodSummary)

            const forLastMonthPeriod = _summaryGlobal.filter(sG => (sG.date >= moment().startOf('month').subtract(1, 'month').valueOf()) && (sG.date < moment().startOf('month').valueOf()));
            const _lastMonthPeriod = forLastMonthPeriod.reduce((exists, c) => {
                exists.expense += c.expense;
                exists.income += c.income;
                exists.incomeProjects += c.incomeByProject.reduce((sum, iBP) => sum + iBP.totalIncome, 0);
                exists.expenseProjects += c.expenseByProject.reduce((sum, eBP) => sum + eBP.totalExpense, 0);
                return exists;
            }, { date: forLastMonthPeriod[0]?.date || null, expense: 0, id_account: forLastMonthPeriod[0]?.id_account || null, income: 0, expenseProjects: 0, incomeProjects: 0 } as MonthPeriodSummary)

            // Build proyection base
            // >> -------------

            // >> Correct X Axis if line graph exceeds
            if (_summaryDaily[0]) {
                const additionalX = moment(_summaryDaily[0].date).add(1, 'month').startOf('month').valueOf()
                if (!!additionalX && !_summaryGlobal.some(sG => sG.date === additionalX)) {
                    _summaryGlobal.unshift({
                        id_account: null,
                        date: additionalX,
                        expense: null,
                        income: null,
                        expenseByCategory: null,
                        incomeByCategory: null
                    })
                }
            }

            // Used for NumberDisplays summary
            setThisMonthPeriod(_thisMonthPeriod)
            setLastMonthPeriod(_lastMonthPeriod)

            // Used for Graphs and lines
            setSummaryGlobal(_summaryGlobal);
            setSummaryDaily(_summaryDaily);

            setSummariesLoaded(true);

            /*
            console.log('Global Period: ', _thisMonthPeriod);
            console.log('Global Period Last: ', _lastMonthPeriod);
            console.log('summaryGlobal: ', _summaryGlobal);
            console.log('getBankMovementsSummary: ', _getBankMovementsSummary);
            */
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        getBankAccounts({
            variables: {
                useCache: true
            }
        })
        getMovementsSummary({
            variables: {
                start: moment().subtract(6, 'months').startOf('month').valueOf(),
                // groupBy: 'month'
                groupBy: 'day'
            }
        })
    }, [getBankAccounts, getMovementsSummary])


    const renderGlobalSummaryEmptyState = () => {
        return (
            <div className='chartSectionEmpty'>
                <div className='_text'>
                    <p>
                        ¡Agrega una cuenta de banco para ver tu gráfica de gastos!
                    </p>
                    <p className='small'>
                        Además de monitorear tus gastos, también podrás ver y categorizar tus <NavLink to="/administration/movimientos">Movimientos</NavLink>.
                    </p>
                </div>
            </div>
        )
    }

    const accountBalanceGlobal = accounts.reduce((p, c) => p += c.balance, 0)
    const summaryGlobal = windowWidth >= 500 ? summaryGlobalOrigin : summaryGlobalOrigin.filter((_, i) => i < 5);
    const lastDate = Math.min(...summaryGlobal.map(s => s.date))
    const summaryDaily = windowWidth >= 500 ? summaryDailyOrigin : summaryDailyOrigin.filter((d) => d.date >= lastDate);

    const lineGraphValues = formatLineGroupsFromBankSummaries(summaryDaily, accountBalanceGlobal)
    const lineGraphMilestones = projectMilestonesFitToLineData(projects, lineGraphValues)

    const renderSummary = () => {
        // Coloring and arrows
        // >> Tracks changes by general expense, proyect type expense and it's sum
        // >> Casual cashflow: Taken from MonthStart -> Today sum result
        // >> Proyect cashflow: Taken from MonthStart -> Today sum result
        const allCashFlow = (thisMonthPeriod?.income || 0) + (thisMonthPeriod?.expense || 0);
        const proyectCashFlow = (thisMonthPeriod?.incomeProjects || 0) + (thisMonthPeriod?.expenseProjects || 0);
        const casualCashFlow = allCashFlow - proyectCashFlow;

        const L_allCashFlow = (lastMonthPeriod?.income || 0) + (lastMonthPeriod?.expense || 0);
        const L_proyectCashFlow = (lastMonthPeriod?.incomeProjects || 0) + (lastMonthPeriod?.expenseProjects || 0);
        const L_casualCashFlow = L_allCashFlow - L_proyectCashFlow;

        // as long as lineGraphValues is sorted from oldest to newest, we can use find to get the first X value that is greater than the last month start
        const lastMonthAtDateBalance = lineGraphValues.find(lGV => lGV.x >= moment().startOf('day').subtract(1, 'month').valueOf())?.y || 0;

        return (
            <div className='periodSummary'>
                <NumberDisplay title="Balance General" value={accountBalanceGlobal} coloring={accountBalanceGlobal < lastMonthAtDateBalance ? "red" : "green"} icon={accountBalanceGlobal < lastMonthAtDateBalance ? 'arrow_downward' : 'arrow_upward'} />
                <NumberDisplay title="Flujo efectivo Proyectos" value={proyectCashFlow} coloring={proyectCashFlow === 0 ? "gray" : (proyectCashFlow < L_proyectCashFlow ? 'red' : "green")} icon={proyectCashFlow < L_proyectCashFlow ? 'arrow_downward' : 'arrow_upward'} />
                <NumberDisplay title="Flujo efectivo General" value={casualCashFlow} coloring={casualCashFlow === 0 ? "gray" : (casualCashFlow < L_casualCashFlow ? 'red' : "green")} icon={casualCashFlow < L_casualCashFlow ? 'arrow_downward' : 'arrow_upward'} />
            </div>
        )
    }

    return (
        <div className='card noShadow monthlyProyection'>
            {renderSummary()}
            <div className='chartSection'>
                {
                    !summaryGlobal.length && summariesLoaded ?
                        renderGlobalSummaryEmptyState()
                        : null
                }
                {
                    summaryGlobal.length && summaryDaily.length && accountBalanceGlobal ?
                        <BarLineAndSpreadChart margin={{ left: 40, top: 20, bottom: 20, right: 40 }}
                            barGroups={[
                                {
                                    color: 'rgba(213, 251, 213, 0.75)',
                                    // Don't apply filter for nulls as this is needed for "additionalX" to render xAxis correctly
                                    values: summaryGlobal.map((sG) => ({
                                        x: sG.date,
                                        y: sG.income
                                    }))
                                },
                                {
                                    color: '#FBD5D5',
                                    // Filter is in place in order to avoid "additionalX" to render a $0 expense with Math.abs
                                    values: summaryGlobal.map((sG) => ({
                                        x: sG.date,
                                        y: sG.expense === null ? null : Math.abs(sG.expense)
                                    })),
                                },
                            ]}
                            lineGroups={[
                                {
                                    color: `#AC2E78`,
                                    values: lineGraphValues,
                                    valueMilestones: lineGraphMilestones
                                }
                            ]}
                            xAxisFormatting={(d: number) => moment(d).format('MMMM')}
                            xAxisExcludeFromTicks={['lineGroups']}
                            xAxisTicks={'from-data'}
                        />
                        :
                        (summariesLoaded ? null : <Loading display={true} relativePos={true} />)
                }
            </div>
        </div>
    )
}

export default AdministrationHubProyectionsGraph;