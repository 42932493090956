import React, { useEffect, useState } from 'react';
import { BillConceptos, Bill, BillConceptTax, FiscalpopImpuesto } from '../../models/Factura';
import AutoComplete from '../Forms/Autocomplete';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import Select from '../Forms/Select';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { post } from '../../services/post';
import { ConceptoCatalogo } from '../../models/Catalogos';
import { clearGqlTypename } from '../../utils/formatting';


interface FacturarConceptoProps {
    cfdi: Bill;
    validRequested: boolean;
    authToken: string;
    // Emitters
    onCfdiChange: (value: BillConceptos[]) => void;

}

const EMPTY_CONCEPTO = (): BillConceptos => (JSON.parse(JSON.stringify({
    claveProdServ: '84111506',
    claveUnidad: 'ACT',
    cantidad: 1,
    descripcion: 'Devolución',
    valorUnitario: 0,
    descuento: 0,
    noIdentificacion: '',
    impuestos: [{ type: 'iva', tipo: 'Fijo', retencion: false, tasa: 0.16 }]
})))

function FacturarConceptosDevolucion({ cfdi, onCfdiChange, validRequested, authToken }: FacturarConceptoProps) {
    const [impuestosList, setImpuestosList] = useState([]);
    useQuery(graphqlSchema.FISCALPOP.IMPUESTOS.getImpuestos, {
        onCompleted: ({ getImpuestos }: { getImpuestos: FiscalpopImpuesto[] }) => {
            console.log('Get Impuestos: ', getImpuestos);
            // For now, filter exento out, unknown if it will be used or if it can be used in credit notes
            setImpuestosList(getImpuestos.filter(c => !c.exento).map(c => clearGqlTypename(c)));
        },
        onError: (e) => {
            console.error('Error loading impuestos: ', e);
        },
        fetchPolicy: 'cache-first'
    })

    const updateConceptoElement = (index: number) => (update: BillConceptos) => {
        const conceptosCommon = cfdi.conceptos;
        console.log('Pre-Updating conceptosCommon: ', JSON.parse(JSON.stringify(conceptosCommon)));
        conceptosCommon[index] = update;
        console.log('Updating concepto Index: ', index);
        console.log('Updating conceptosCommon: ', JSON.parse(JSON.stringify(conceptosCommon)));
        onCfdiChange(conceptosCommon);
    }

    const addNewConcepto = () => {
        const conceptosCommon = cfdi.conceptos;
        conceptosCommon.push(EMPTY_CONCEPTO());
        onCfdiChange(conceptosCommon);
    }

    const removeConcepto = (index: number) => () => {
        const conceptosCommon = cfdi.conceptos;
        conceptosCommon.splice(index, 1);
        onCfdiChange(conceptosCommon);
    }

    return (
        <div id="_Conceptos_Block">
            <h4>Conceptos</h4>
            <div className="card table">
                <div className="cardBody">
                    {
                        cfdi.conceptos.map((concepto, i) =>
                        (
                            <ConceptoElementDevolucion
                                key={i}
                                conceptoIndex={i}
                                concepto={concepto}
                                authToken={authToken}
                                removeConcepto={removeConcepto}
                                validRequested={validRequested}
                                impuestosList={impuestosList}
                                handleConceptoUpdate={updateConceptoElement} />
                        )
                        )
                    }
                    <div className="row">
                        <Button tertiary={true} handleClick={addNewConcepto}>
                            <span>Agregar otra devolución</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}




export function ConceptoElementDevolucion({ authToken, removeConcepto, validRequested, conceptoIndex, concepto, impuestosList, handleConceptoUpdate, hideCantidad, descripcionAsInput }: { authToken: string; validRequested: boolean; conceptoIndex: number, concepto: BillConceptos, impuestosList: FiscalpopImpuesto[], removeConcepto?: (i: number) => () => void, handleConceptoUpdate: (i: number) => (update: BillConceptos) => void, hideCantidad?: boolean, descripcionAsInput?: boolean }) {
    const [isLoading, setIsLoading] = useState(false);
    const [prodServSubject] = useState(new Subject<string>());
    const [unidadSubject] = useState(new Subject<string>());
    const [unidadOptions, setUnidadOptions] = useState<{ label: string; value: string }[]>([])
    const [claveOptions, setClaveOptions] = useState<{ label: string; value: string }[]>([])

    const [conceptoOptions, setConceptoOptions] = useState<ConceptoCatalogo[]>([]);
    const [searchByName] = useState(new Subject<string>());

    const [getByName] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getConceptoByName, {
        onCompleted: ({ getConceptoByName }: { getConceptoByName: ConceptoCatalogo[] }) => {
            setConceptoOptions(getConceptoByName);
            console.log('getConceptoByName: ', getConceptoByName);
        },
        onError: (e) => {
            console.error('Error fetching conceptos: ', e)
        }
    })

    useEffect(() => {
        const subs = searchByName.pipe(debounceTime(200)).subscribe(name => {
            getByName({
                variables: {
                    name
                }
            })
        })
        return () => {
            subs.unsubscribe();
        }
    }, [searchByName, getByName])


    useEffect(() => {
        const fetchUnidad = (defaultUnidadSat: string) => {
            setIsLoading(true);
            return post(`https://api.fiscalpop.com/api/v1/sat/claveUnidades/${authToken}`, {
                compare: defaultUnidadSat,
                clave: defaultUnidadSat
            }).then(response => {
                setIsLoading(false);
                setUnidadOptions(response.map((r: { clave: string, nombre: string }) => ({ label: `(${r.clave}) ${r.nombre}`, value: r.clave })))
            })
        }


        const fetchClaves = (defaultClaveSatProdServ: string) => {
            console.log('[SETUP - DEFAULTS] Fetching claves: ', defaultClaveSatProdServ);
            setIsLoading(true);
            return post(`https://api.fiscalpop.com/api/v1/sat/productosServicios/${authToken}`, {
                compare: defaultClaveSatProdServ,
                clave: defaultClaveSatProdServ
            }).then(response => {
                setIsLoading(false);
                setClaveOptions(response.map((r: { clave: string, descripcion: string }) => ({ label: `(${r.clave}) ${r.descripcion}`, value: r.clave })))
            })
        }

        const prodSubs = prodServSubject.pipe(debounceTime(200)).subscribe(s => {
            fetchClaves(s || '');
        })
        const unidadSubs = unidadSubject.pipe(debounceTime(200)).subscribe(s => {
            fetchUnidad(s || '');
        })

        return () => {
            prodSubs.unsubscribe()
            unidadSubs.unsubscribe()
        }
    }, [prodServSubject, unidadSubject, authToken])

    const autoFillConceptoOption = (conceptoId: string) => {
        const conceptoToFill = conceptoOptions.find(c => c._id === conceptoId);
        console.log('conceptoToFill: ', conceptoToFill);
        handleConceptoUpdate(conceptoIndex)(Object.assign({ cantidad: 1 }, clearGqlTypename(conceptoToFill)));
    }

    const handleConceptoChange = (property: 'claveProdServ' | 'claveUnidad' | 'cantidad' | 'descripcion' | 'valorUnitario' | 'pedimento' | 'noIdentificacion' | 'descuento') => (value: any) => {
        const assigner: { [key: string]: any } = {};
        assigner[property] = value;
        const _concepto: BillConceptos = Object.assign({}, concepto, assigner);
        handleConceptoUpdate(conceptoIndex)(_concepto);
        if (property === 'claveProdServ') {
            prodServSubject.next(value);
        }
        if (property === 'claveUnidad') {
            unidadSubject.next(value);
        }
        if (property === 'descripcion') {
            searchByName.next(value);
        }
    }

    const handleAddNewTax = () => {
        concepto.impuestos.push(EMPTY_CONCEPTO().impuestos[0]);
        console.log('Adding new tax: ', conceptoIndex, concepto);
        handleConceptoUpdate(conceptoIndex)(concepto);
    }

    const handleRemoveTax = (index: number) => (_: any) => {
        const _concepto = Object.assign({}, concepto);
        const taxes = _concepto.impuestos;
        console.log('Removing tax: ', conceptoIndex, concepto);
        if (taxes.length === 1) {
            _concepto.impuestos = EMPTY_CONCEPTO().impuestos;
            handleConceptoUpdate(conceptoIndex)(_concepto);
        } else {
            _concepto.impuestos.splice(index, 1);
            handleConceptoUpdate(conceptoIndex)(_concepto);
        }
    }

    const handleImpuestoUpdate = (index: number) => (impuesto: BillConceptTax) => {
        const _concepto = Object.assign({}, concepto);
        _concepto.impuestos[index] = impuesto;
        handleConceptoUpdate(conceptoIndex)(_concepto);
    }

    const autoFillOptions = conceptoOptions.map(c => ({ label: c.descripcion, value: c._id }));
    return (

        <div className="concepto">
                {
                    removeConcepto ?
                        <Button setClass={'_eliminate'} secondary={true} handleClick={removeConcepto(conceptoIndex)}>
                            <div>
                                <span>Eliminar devolución # {conceptoIndex + 1}
                                </span>
                                <i className="material-icons delete">
                                    delete
                            </i>
                            </div>
                        </Button>

                        : ''
                }
            <div className="row two md-one">
                {
                    descripcionAsInput ?
                        <Input label="Descripción" hasError={validRequested && !concepto.descripcion} value={concepto.descripcion} placeholder="Nombre del producto" type="text" onChange={handleConceptoChange('descripcion')} />

                        :
                        <AutoComplete label="Descripción" hasError={validRequested && !concepto.descripcion} value={concepto.descripcion} placeholder="Nombre del producto" type="text" options={autoFillOptions} displayLabel={true} onChange={handleConceptoChange('descripcion')} onSelect={autoFillConceptoOption} />
                }
                <AutoComplete displayLabel={true} isLoading={isLoading} label="Clave producto o servicio" hasError={validRequested && !concepto.claveProdServ} value={concepto.claveProdServ} placeholder="Clave de producto o servicio" type="text" options={claveOptions} onChange={handleConceptoChange('claveProdServ')} onSelect={handleConceptoChange('claveProdServ')} />
            </div>
            {
                !!hideCantidad ?
                    <div className="row three lg-two md-gap-compressed">
                        <div className="select">
                            <AutoComplete displayLabel={true} isLoading={isLoading} label="Clave unidad" hasError={validRequested && !concepto.claveUnidad} value={concepto.claveUnidad} placeholder="Clave de unidad" type="text" options={unidadOptions} onChange={handleConceptoChange('claveUnidad')} onSelect={handleConceptoChange('claveUnidad')} />
                        </div>
                        <Input type="number" isCurrency={true} hasError={validRequested && !concepto.valorUnitario} label="Valor unitario (sin impuestos)" value={concepto.valorUnitario} placeholder="" onChange={handleConceptoChange('valorUnitario')} min={0.01} />
                    </div>
                    :
                    <div className="row four lg-three sm-two md-gap-compressed">
                        <div className="select">
                            <AutoComplete displayLabel={true} isLoading={isLoading} label="Clave unidad" hasError={validRequested && !concepto.claveUnidad} value={concepto.claveUnidad} placeholder="Clave de unidad" type="text" options={unidadOptions} onChange={handleConceptoChange('claveUnidad')} onSelect={handleConceptoChange('claveUnidad')} />
                        </div>
                        <Input type="number" label="Cantidad" hasError={validRequested && !concepto.cantidad} value={concepto.cantidad} placeholder="" onChange={handleConceptoChange('cantidad')} min={0.001} />
                        <Input type="number" isCurrency={true} hasError={validRequested && !concepto.valorUnitario} label="Valor unitario (sin impuestos)" value={concepto.valorUnitario} placeholder="" onChange={handleConceptoChange('valorUnitario')} min={0.01} />
                    </div>
            }
            {
                concepto.impuestos.map((impuesto, i) => {
                    return (
                        <ConceptoElementImpuesto i={i} key={i} impuesto={impuesto} impuestosList={impuestosList} handleImpuestoUpdate={handleImpuestoUpdate} handleAddNewTax={handleAddNewTax} handleRemoveTax={handleRemoveTax} />
                    )
                })
            }
        </div>
    )
}

export function ConceptoElementImpuesto({ i, impuesto, impuestosList, handleImpuestoUpdate, handleAddNewTax, handleRemoveTax }: { i: number, handleAddNewTax: () => void, handleRemoveTax: (index: number) => (_: any) => void, impuesto: BillConceptTax, impuestosList: FiscalpopImpuesto[], handleImpuestoUpdate: (i: number) => (update: BillConceptTax) => void }) {
    const [isRange, setIsRange] = useState(false);
    const [rangeParams, setRangeParams] = useState([0, 16]);

    const handleImpuestoChange = (index: number, property: 'type' | 'retencion' | 'tasa' | 'cuota') => (value: any) => {
        const assigner: { [key: string]: any } = {};
        assigner[property] = value;
        const currentImpuesto = impuesto;
        if (property === 'type') {
            if (currentImpuesto.type !== value) {
                // Type changed, reset options
                if (value === 'iva') {
                    assigner['retencion'] = false;
                    assigner['tasa'] = 0.16;
                }
                if (value === 'ieps') {
                    assigner['retencion'] = false;
                    assigner['tasa'] = 0.03;
                }
                if (value === 'isr') {
                    assigner['retencion'] = true;
                    assigner['tasa'] = 0.35;
                }
            }
        }
        if (property === 'retencion') {
            if (currentImpuesto.retencion !== value) {
                const valueOption = impuestoValueOptions(currentImpuesto.type, value)[0];
                if (valueOption.isRange) {
                    if (valueOption.factor === 'Cuota') {
                        assigner['cuota'] = valueOption.value;
                        assigner['tasa'] = null;
                    } else {
                        assigner['tasa'] = valueOption.value;
                        assigner['cuota'] = null;
                    }
                    // Open Range options state
                    setIsRange(true);
                    setRangeParams([valueOption.valueMin, valueOption.value]);
                } else {
                    if (valueOption.factor === 'Cuota') {
                        assigner['cuota'] = valueOption.value;
                        assigner['tasa'] = null;
                    } else {
                        assigner['tasa'] = valueOption.value;
                        assigner['cuota'] = null;
                    }
                    // Close Range options state
                    setIsRange(false);
                }
            }
        }
        if (property === 'tasa') {
            assigner['cuota'] = null;
            if (isRange && value > rangeParams[1]) {
                assigner['tasa'] = rangeParams[1];
            } else if (isRange && value < rangeParams[0]) {
                assigner['tasa'] = rangeParams[0];
            }
        }
        if (property === 'cuota') {
            assigner['tasa'] = null;
            if (isRange && value > rangeParams[1]) {
                assigner['cuota'] = rangeParams[1];
            } else if (isRange && value < rangeParams[0]) {
                assigner['cuota'] = rangeParams[0];
            }
        }
        const _impuesto: BillConceptTax = Object.assign({}, currentImpuesto, assigner);
        handleImpuestoUpdate(i)(_impuesto);
    }

    const handleImpuestoChangeValue = (index: number) => (value: number) => {
        // Check if this is tasa or cuota
        const currentImpuesto = impuesto;
        const valueOptions = impuestoValueOptions(currentImpuesto.type, currentImpuesto.retencion);
        const candidate = valueOptions.find(vO => vO.value === value);
        if (candidate.factor === 'Cuota') {
            handleImpuestoChange(index, 'cuota')(candidate.value)
        } else {
            handleImpuestoChange(index, 'tasa')(candidate.value)
        }
        console.log('Candidate change value: ', candidate);
        if (candidate.isRange) {
            // Open isRange here
            setIsRange(true);
            setRangeParams([candidate.valueMin, candidate.value]);
        } else {
            setIsRange(false);
        }
    }

    const impuestoTypeOptions = () => {
        const impuestosSet = new Set(impuestosList.map(iL => iL.impuesto));
        const options: { value: string, label: string }[] = [];
        for (const [e] of impuestosSet.entries()) {
            options.push({ value: e.toLowerCase(), label: e })
        }
        return options;
    }

    const impuestoTranslateOptions = (type: 'iva' | 'ieps' | 'isr') => {
        const _type = type.toUpperCase()
        const candidates = impuestosList.filter(iL => iL.impuesto === _type)
        const options: { value: boolean, label: string }[] = [];
        if (candidates.some(c => c.traslado)) {
            options.push({ value: false, label: 'Traslado' })
        }
        if (candidates.some(c => c.retencion)) {
            options.push({ value: true, label: 'Retención' })
        }
        return options;
    }

    const impuestoValueOptions = (type: 'iva' | 'ieps' | 'isr', retencion: boolean) => {
        const _type = type.toUpperCase()
        const candidates = impuestosList.filter(iL => iL.impuesto === _type).filter((iL) => {
            if (!retencion && iL.traslado) {
                return true;
            }
            else if (retencion && iL.retencion) {
                return true;
            } else {
                return false;
            }
        });
        const options = candidates.map((tax) => ({ value: tax.max, valueMin: tax.tipo === 'Rango' ? (tax.min || 0) : null, isRange: tax.tipo === 'Rango', factor: tax.factor, label: tax.factor === 'Cuota' ? `$ ${tax.min !== null ? tax.min + ' - ' : ''}${tax.max} / unidad` : tax.min !== null ? `${(tax.min * 100).toFixed(1)} - ${(tax.max * 100).toFixed(1)}%` : `${(tax.max * 100).toFixed(1)} %` }))
        return options;
    }
    return (
        <div className="impuesto">
            <i className="material-icons delete" onClick={handleRemoveTax(i)}>
                delete
                            </i>
            <div className="row five md-four sm-two xs-gap-compressed" key={i}>
                <Select label="Impuesto" value={impuesto.type} options={impuestoTypeOptions()} onChange={handleImpuestoChange(i, 'type')} />
                <Select label="Traslado / Retencion" value={impuesto.retencion} options={impuestoTranslateOptions(impuesto.type)} onChange={handleImpuestoChange(i, 'retencion')} />
                <Select label="Valor del impuesto" value={isRange ? rangeParams[1] : impuesto.tasa || impuesto.cuota} options={impuestoValueOptions(impuesto.type, impuesto.retencion)} onChange={handleImpuestoChangeValue(i)} />
                {
                    isRange ?
                        (
                            impuesto.tasa !== null ?
                                <Input type="number" label={`Valor de la tasa`} placeholder="" value={impuesto.tasa} onChange={handleImpuestoChange(i, 'tasa')} min={rangeParams[0]} max={rangeParams[1]} step={0.01} />
                                :
                                <Input type="number" label={`Valor de la cuota`} placeholder="" value={impuesto.cuota} onChange={handleImpuestoChange(i, 'cuota')} min={rangeParams[0]} max={rangeParams[1]} step={0.01} />
                        )
                        : <div></div>
                }
                {
                    <Button secondary={true} handleClick={handleAddNewTax}>
                        <span>Agregar otro impuesto</span>
                    </Button>
                }
            </div>
        </div>
    )
}

export default FacturarConceptosDevolucion;