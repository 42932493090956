import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { PaybookAccount } from '../../../../models/Administracion/Bank.Accounts';
import { BankBudgetsActivityPrediction, BankIncomesActivityPrediction } from '../../../../models/Administracion/Bank.Budgets';
import { BankProject, BankProjectionGoal } from '../../../../models/Administracion/Bank.Projects';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import { graphqlSchema } from '../../../../services/graphql.schema';
import Button from '../../../Forms/Button';
import FlagSvg from '../../../visuals/svg/flagIcon';
import { ProjectionMetaDialog } from './Dialogs/Projection.metas.dialog';
import ProjectionOverviewGoals from './Projection.overview.goals';
import ProjectionOverviewGraphsBalancePrediction from './Projection.overview.graphs.balancePrediction';
import { ToggleMenuDict } from './Projection.overview.menu';

interface ProjectionOverviewGraphsProps {
    projects: BankProject<'business'>[];
    summaryLoaded: boolean;
    summaries: BankMovementSummary[];
    summaryToggleDict: ToggleMenuDict;
}

function ProjectionOverviewGraphs({ projects, summaryLoaded, summaries, summaryToggleDict }: ProjectionOverviewGraphsProps) {

    const [accounts, setAccounts] = useState<PaybookAccount[]>([])
    const [budgetActivity, setBudgetActivity] = useState<BankBudgetsActivityPrediction[]>([])
    const [incomeActivity, setIncomeActivity] = useState<BankIncomesActivityPrediction[]>([])

    const [accountsLoaded, setAccountsLoaded] = useState(false);

    const [goals, setGoals] = useState<BankProjectionGoal[]>([])

    const [openMetas, setOpenMetas] = useState(false);

    const [getBankAccounts] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankAccounts, {
        onCompleted: ({ getBankAccounts }: { getBankAccounts: PaybookAccount[] }) => {
            console.log(`(ProjectionOverviewGraphs) <getBankAccounts> `, getBankAccounts);
            setAccounts(getBankAccounts);
            setAccountsLoaded(true);
        },
        fetchPolicy: 'cache-and-network'
    })
    const [getCategoryBudgetActivity] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBudgetActivitiesPrediction, {
        onCompleted: ({ getBudgetActivitiesPrediction }: { getBudgetActivitiesPrediction: BankBudgetsActivityPrediction[] }) => {
            console.log(`(ProjectionOverviewGraphs) <getBudgetActivitiesPrediction>: `, getBudgetActivitiesPrediction)
            setBudgetActivity(getBudgetActivitiesPrediction)
        },
        onError: (e) => {
            console.error(`Error getting category activity: `, e);
        },
        fetchPolicy: 'cache-and-network'
    })
    const [getIncomeActivity] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getIncomeActivitiesPrediction, {
        onCompleted: ({ getIncomeActivitiesPrediction }: { getIncomeActivitiesPrediction: BankIncomesActivityPrediction[] }) => {
            console.log(`(ProjectionOverviewGraphs) <getIncomeActivitiesPrediction>: `, getIncomeActivitiesPrediction)
            setIncomeActivity(getIncomeActivitiesPrediction)
        },
        onError: (e) => {
            console.error(`Error getting category activity: `, e);
        },
        fetchPolicy: 'cache-and-network'
    })
    const [getProjectionGoals] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.getProjectionGoalsActive, {
        onCompleted: ({ getProjectionGoalsActive }: { getProjectionGoalsActive: BankProjectionGoal[] }) => {
            console.log(`(ProjectionOverviewGraphs) <getProjectionGoalsActive>: `, getProjectionGoalsActive)
            setGoals(getProjectionGoalsActive.sort((a, b) => a.dueDateTS - b.dueDateTS))
        },
        onError: (e) => {
            console.error(`Error getting category activity: `, e);
        },
        fetchPolicy: 'cache-and-network'
    })


    useEffect(() => {
        console.log(`<ProjectionOverviewGraphs> UE`)
        getBankAccounts({
            variables: {
                useCache: true
            }
        })
        getCategoryBudgetActivity({
            variables: {
                monthsAgo: 4
            }
        })
        getIncomeActivity({
            variables: {
                monthsAgo: 4
            }
        })
        getProjectionGoals()
    }, [getBankAccounts, getCategoryBudgetActivity, getIncomeActivity, getProjectionGoals])

    const openMetaDialog = () => {
        setOpenMetas(true);
    }

    const closeMetaDialog = () => {
        setOpenMetas(false);
    }

    const onMetaAdded = (goal: BankProjectionGoal) => {
        setOpenMetas(false);
        setGoals(_goals => [..._goals, goal])
        // Possible re-load goals
    }

    return (
        <div className='card _overviewSection'>
            <div className='_title'>
                <h4>Proyección General</h4>
                <Button tertiary={true} handleClick={openMetaDialog}>
                    <span>
                        Agregar Meta
                        <FlagSvg width={14} color={'#AC2E78'} />
                    </span>
                </Button>
            </div>
            <ProjectionOverviewGraphsBalancePrediction dataLoaded={summaryLoaded && accountsLoaded} projects={projects} summaries={summaries} accounts={accounts} budgetActivity={budgetActivity} incomeActivity={incomeActivity} summaryToggleDict={summaryToggleDict} />
            <ProjectionOverviewGoals goals={goals} />
            <ProjectionMetaDialog isOpen={openMetas} onRequestClose={closeMetaDialog} onMetaAdded={onMetaAdded} />
        </div>
    )
}

export default ProjectionOverviewGraphs