
/**
 * Ingresos Categories List is a "Quick Access" to existing categories appropiate for Income
 */
export const INGRESOS_CATEGORIES_LIST = [
    {
        label: 'Ingresos',
        value: 'ingreso',
        color: '#f57660',
        children: [
            { label: 'Ventas', value: 'negocio-ventas' },
            { label: 'Salario', value: 'finanzas-salario' },
            { label: 'Inversiones', value: 'finanzas-inversion' },
            { label: 'Pago de tarjetas', value: 'finanzas-tarjetas' },
        ]
    }
]

export const CATEGORIES_LIST = [
    {
        label: 'Transporte',
        value: 'transporte',
        color: '#f57660',
        children: [
            { label: 'Servicios', value: 'transporte-servicios' },
            { label: 'Reparaciones', value: 'transporte-reparacion' },
            { label: 'Seguro', value: 'transporte-seguro' },
            { label: 'Gasolina', value: 'transporte-gasolina' },
            { label: 'Limpieza', value: 'transporte-limpieza' },
            { label: 'Casetas', value: 'transporte-casetas' },
            { label: 'Taxi y Apps', value: 'transporte-taxi' },
            { label: 'Viajes', value: 'transporte-viajes' },
            
            { label: 'Otros gasos de transporte', value: 'transporte-otro' },
        ]
    },
    {
        label: 'Negocio',
        value: 'negocio',
        color: '#F5CC60',
        children: [
            { label: 'Ventas', value: 'negocio-ventas' },
            { label: 'Renta', value: 'negocio-renta' },
            { label: 'Servicios', value: 'negocio-servicios' },
            { label: 'Nómina', value: 'negocio-nómina' },
            { label: 'Costos de producción', value: 'negocio-produccion' },
            { label: 'Logística', value: 'negocio-logistica' },
            { label: 'Insumos y Proveedores', value: 'negocio-insumos' },
            { label: 'Comisiones', value: 'negocio-comisiones' },
            { label: 'Hosting y digitales', value: 'negocio-digital' },
            { label: 'Publicidad', value: 'negocio-publicidad' },
            { label: 'Viáticos', value: 'negocio-viaticos' },
            
            { label: 'Otros gasos de negocio', value: 'negocio-otro' },
        ]
    },
    {
        label: 'Casa',
        value: 'casa',
        color: '#A56FFC',
        children: [
            { label: 'Renta', value: 'casa-renta' },
            { label: 'Agua', value: 'casa-agua' },
            { label: 'Gas', value: 'casa-gas' },
            { label: 'Luz', value: 'casa-luz' },
            { label: 'Limpieza', value: 'casa-limpieza' },
            { label: 'Jardinería', value: 'casa-jardineria' },
            { label: 'Teléfono e Internet', value: 'casa-internet' },
            { label: 'Supermercado', value: 'casa-supermercado' },
            { label: 'Servicio a domicilio', value: 'casa-entregas' },
            { label: 'Mascotas', value: 'casa-mascotas' },
            { label: 'Mobiliario', value: 'casa-muebles' },
            { label: 'Reparaciones u Otro', value: 'casa-reparacion' },
            
            { label: 'Hipoteca', value: 'casa-hipoteca' },
            { label: 'Predial', value: 'casa-predial' },
            { label: 'Otros cargos hogar', value: 'casa-otro' },
        ]
    },
    {
        label: 'Financieros',
        value: 'finanzas',
        color: '#61ef29',
        children: [
            { label: 'Salario', value: 'finanzas-salario' },
            { label: 'Créditos personales', value: 'finanzas-creditos' },
            { label: 'Créditos automotrices', value: 'finanzas-automotriz' },
            { label: 'Créditos negocio', value: 'finanzas-negocio' },
            { label: 'Pago de tarjetas', value: 'finanzas-tarjetas' },
            { label: 'Transferencias internas', value: 'finanzas-transferencia' },
            { label: 'Inversiones', value: 'finanzas-inversion' },
            { label: 'Otros cargos financieros', value: 'finanzas-otro' },
        ]
    },
    {
        label: 'Familia y amigos',
        value: 'familia',
        color: '#fc6fae',
        children: [
            { label: 'Regalos', value: 'familia-regalos' },
            { label: 'Reuniones y comidas', value: 'familia-reuniones' },
            { label: 'Otros cargos familiares', value: 'familia-otro' },
        ]
    },
    {
        label: 'Personal',
        value: 'personal',
        color: '#6f9cfc',
        children: [
            { label: 'Entretenimiento', value: 'personal-entretenimiento' },
            { label: 'Restaurantes', value: 'personal-restaurantes' },
            { label: 'Ejercicio', value: 'personal-ejercicio' },
            { label: 'Educación', value: 'personal-educacion' },
            { label: 'Vacaciones', value: 'personal-vacaciones' },
            { label: 'Ropa', value: 'personal-ropa' },
            { label: 'Equipos y Cómputo', value: 'personal-equipement' },

            { label: 'Otros cargos personales', value: 'personal-otro' },
        ]
    },
    {
        label: 'Online',
        value: 'online',
        color: '#FFA172',
        children: [
            { label: 'Streaming y Entretenimiento', value: 'online-streaming' },
            { label: 'Educativo', value: 'online-educativo' },
            { label: 'Social', value: 'online-social' },
            { label: 'Productividad', value: 'online-productividad' },
            { label: 'Gustos Personales', value: 'online-personal' },
            
            { label: 'Otros cargos online', value: 'online-otro' },
        ]
    },
    {
        label: 'Salud',
        value: 'salud',
        color: '#63C5E3',
        children: [
            { label: 'Médicos y consultas', value: 'salud-medico' },
            { label: 'Seguro', value: 'salud-seguro' },
            { label: 'Farmacia', value: 'salud-farmacia' },
            { label: 'Entretenimiento', value: 'salud-entretenimiento' },

            { label: 'Otros cargos de Salud', value: 'salud-otro' },
        ]
    },
]