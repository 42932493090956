import React, { useCallback, useEffect, useRef, useState } from 'react';
import useWindowResize from '../Hooks/useWindowResize';

import './collapsable.container.scss'

interface CollapsableProps {
    initCollapse: boolean;
    triggerComponent: JSX.Element;
    onCollapseChange?: () => void;
    _forceHeightChange?: number; // Used to set UPPER heigh and not interpreted by children
    children: JSX.Element;
}


export function Collapsable(
    {
        initCollapse,
        triggerComponent,
        onCollapseChange,
        _forceHeightChange,
        children,
    }: CollapsableProps) {

    const { windowWidth } = useWindowResize()
    const collapseRef = useRef<HTMLDivElement>()
    const [toHeight, setToHeight] = useState(_forceHeightChange || 0);
    const [collapse, setCollapse] = useState(initCollapse);

    useEffect(() => {
        if (collapseRef && !_forceHeightChange) {
            const childrenBound = collapseRef.current.children[0]?.getBoundingClientRect();
            const childHeight = childrenBound?.height || 0;
            setToHeight(childHeight);
        } else if (collapseRef && !!_forceHeightChange) {
            setToHeight(_forceHeightChange);
        }
    }, [windowWidth, children, collapseRef, _forceHeightChange])

    const toggleCollapse = useCallback(() => {
        if (!_forceHeightChange) {
            const childrenBound = collapseRef.current.children[0]?.getBoundingClientRect();
            const childHeight = childrenBound?.height || 0;
            setToHeight(childHeight);
        }

        setCollapse(v => !v);
        if (onCollapseChange) {
            setTimeout(() => {
                onCollapseChange()
            }, 300)
        }
    }, [collapseRef, _forceHeightChange, onCollapseChange])

    return (
        <div className='collapsable'>
            <div className='collapsableTitle' onClick={toggleCollapse}>
                {
                    triggerComponent
                }
                <span className={`material-icons chevron ${collapse ? '' : '_rotate'}`}>
                    chevron_right
                </span>
            </div>
            <div className={`collapsableOverflow ${_forceHeightChange ? '_scrollable' : ''}`} style={{ maxHeight: collapse ? '0px' : `${toHeight}px` }} ref={collapseRef}>
                {children}
            </div>
        </div>
    )
}

