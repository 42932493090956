import React from 'react';
import { Bill } from '../../models/Factura';
import { numeroALetras } from '../../utils/numeroLetra';
import { calculateTotal } from '../../utils/calculate.conceptos';
import { formatCurrencyStyled } from '../visuals/currency.formatted';

function FacturaTotales({ cfdi }: { cfdi: Bill }) {

    const toLetter = (value: number) => {
        return numeroALetras(value, {
            plural: 'pesos mexicanos',
            singular: 'pesos mexicanos',
            centPlural: 'centavos',
            centSingular: 'centavo'
        }).toLowerCase();
    }

    const { subtotal, taxes, total, descuento } = calculateTotal(cfdi.conceptos);
    return (
        <div className="card totales">
            <div className="letter">
                <p>
                    <b> Total en letra:</b> {toLetter(total)}
                </p>
            </div>
            <div className="totalIs">
                <p>{formatCurrencyStyled(subtotal)}</p>
                <p className="subtotal">Subtotal </p>
                {
                    descuento ?
                        <React.Fragment>
                            <p className="negative">{formatCurrencyStyled(-descuento)}</p>
                            <p className="subtotal negative">Descuento </p>
                        </React.Fragment>
                        : ''
                }
                {
                    taxes.map((tax, i) => {
                        return (
                            <React.Fragment key={i}>
                                <p className={`taxvalue ${tax.value < 0 ? 'negative' : ''}`}> {formatCurrencyStyled(tax.value)}</p>
                                <p className={`tax ${tax.value < 0 ? 'negative' : ''}`}>{tax.key}</p>
                            </React.Fragment>
                        )
                    })
                }
                <p>{formatCurrencyStyled(total)}</p>
                <p className="total">Total </p>
            </div>
        </div>
    )
}

export default FacturaTotales;