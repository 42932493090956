import { useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { BaseReact } from '../../../base.model';
import { BankProject } from '../../../models/Administracion/Bank.Projects';
import { ProfileQL } from '../../../models/Profile';
import { graphqlSchema } from '../../../services/graphql.schema';
import SectionTitleBar from '../../components/titlebar';
import Button from '../../Forms/Button';
import ItemMenu from '../../Forms/menu/MenuComponent';
import BriefcaseSvg from '../../visuals/svg/briefcaseIcon';
import MoneySvg from '../../visuals/svg/moneyIcon';
import { ProjectionBusinessNew } from './Business/Projection.business.new';
import { ProjectionGastosNew } from './Gastos/Projection.gastos.new';
import ProjectionOverview from './Overview/Projection.overview';
import { ProjectionNewDialog } from './Projection.new.dialog';

import './Projection.scss'
import { ProjectionSwitchById } from './Projection.swtichLoad';

interface ProjectsProps extends BaseReact {
    currentUser: ProfileQL;
    editing?: boolean;
}

function Projection({ currentUser, location, history, editing }: ProjectsProps) {

    const { projectid } = useParams<{ projectid: string }>();

    const [openNew, setOpenNew] = useState(false);

    const [projectOptions, setProjectOptions] = useState([]);

    useQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.getProjectionProjects, {
        onCompleted: ({ getProjectionProjects }: { getProjectionProjects: BankProject<'expense' | 'business'>[] }) => {
            console.log(`(Projection) <getProjectionProjects> `, getProjectionProjects);
            setProjectOptions(getProjectionProjects.map((p) => ({
                label: p.name,
                _id: p._id,
                type: p.type
            })))
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        console.log(`Projection UE: `, projectid);
    }, [projectid])

    const openNewDialog = () => {
        setOpenNew(true)
    }

    const closeNewDialog = () => {
        setOpenNew(false)
    }

    const onNewProject = (type: 'business' | 'expense') => {
        setOpenNew(false)
        history.push(`/administration/projection/${type}`)
    }

    const renderProjectView = () => {
        if (projectid === 'expense') {
            return <ProjectionGastosNew history={history} />
        }
        else if (projectid === 'business') {
            return <ProjectionBusinessNew history={history} />
        }
        else if (!!projectid) {
            let reFetch: { [key: string]: string } = {};
            if (location.search) {
                reFetch = location.search.replace('?', '').split('&').reduce((p, c) => {
                    const [key, value] = c.split('=')
                    p[key] = value;
                    return p;
                }, {} as { [key: string]: string })
            }
            return <ProjectionSwitchById history={history} projectId={projectid} editing={editing} recall={reFetch.recall} />
        }
        else {
            return <ProjectionOverview />
        }
    }

    const selectedProject = projectOptions.find(p => p._id === projectid)

    return (
        <div id="Projection">
            <SectionTitleBar currentUser={currentUser} title={`Proyecciones y Projectos`} />
            <div className="projectionContent">
                <div className='selectorSection'>
                    <ItemMenu
                        widthBehaviour={'default'}
                        openOrientation={'center'}
                        triggerContent={
                            <div className='selectorCard card'>
                                <p>
                                    {selectedProject?.label || 'Resumen General'}
                                </p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none">
                                    <path d="M15 1L7.98532 8L1 1" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        }>
                        <NavLink to={`/administration/projection`} exact={true} activeClassName="_optionCurrent">
                            <div className='_option'>
                                <span className="material-icons">
                                    leaderboard
                                </span>
                                <p>
                                    Resumen General
                                </p>
                            </div>
                        </NavLink>
                        {
                            projectOptions.map((proj) => (
                                <NavLink key={proj._id} to={`/administration/projection/${proj._id}`} activeClassName="_optionCurrent">
                                    <div className='_option'>
                                        {
                                            proj.type === 'expense' ?
                                                <MoneySvg width={25} />
                                                :
                                                <BriefcaseSvg width={25} />
                                        }
                                        <p>
                                            {proj.label.substring(0, 45)}
                                        </p>
                                    </div>
                                </NavLink>
                            ))
                        }
                    </ItemMenu>
                    <Button primary={true} handleClick={openNewDialog}>
                        <span>
                            Agregar Proyecto
                        </span>
                    </Button>
                </div >

                {
                    renderProjectView()
                }

            </div >
            <ProjectionNewDialog isOpen={openNew} onRequestClose={closeNewDialog} onNewProject={onNewProject} />
        </div >
    )
}

export default Projection;