import React, { useEffect } from 'react';

import './Checkbox.scss';

interface CheckBoxProps {
    onChange: (bool:boolean) => void;
    label?: string;
    value: boolean;
    disabled?: boolean;
    color?:string;
}

function Checkbox({onChange, label, value, disabled, color}:CheckBoxProps){
    const checkRef = React.createRef<HTMLDivElement>();
    const toggleValue = () => {
        if(disabled){
            return;
        }else{
            onChange(!value);
        }
    }

    useEffect(() => {
        checkRef.current.style.setProperty("--checkbox-color", color || '#5849ee')
    }, [checkRef, color]);

    return (
        <div className={`__Checkbox ${disabled ? 'disabled' : ''}`}>
            <div ref={checkRef} className={`check ${value ? 'checked' : 'no'}`} onClick={toggleValue}>
                <i className={`material-icons noselect`}>
                    check
                </i>
            </div>
            <p>
                {label}
            </p>
        </div>
    )
}

export default Checkbox;