import React from 'react'
import Modal from 'react-modal';
import Button from '../../Forms/Button';
import GoggleMapWrapper from './CartaPorte.map';

import './CartaPorte.map.scss';

interface CartaPorteTypeDialogProps {
    isOpen: boolean;
    googleMapsUrl: string;
    onRequestClose: () => void;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        maxWidth: '96vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

export function GoggleMapDialog({ isOpen, googleMapsUrl, onRequestClose }: CartaPorteTypeDialogProps) {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            contentLabel="Definir tipo de Carta Porte"
        >
            <div className='card row'>
                <div className='card table mapsDialog'>
                    <GoggleMapWrapper googleMapsUrl={googleMapsUrl} />
                </div>
                <div className='row'>
                    <div className='end centerY'>
                        <Button secondary={true} handleClick={onRequestClose}>
                            <span>
                                Cerrar
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </Modal >
    )
}

