import React from 'react';
import { ProfileQL } from '../../models/Profile';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './titlebar.scss';

function SectionCertWarning({ currentUser }: { currentUser: ProfileQL }) {
    const fpStatus = !!currentUser.fiscalpopProfileStatus ? currentUser.fiscalpopProfileStatus : null;
    const fpTimingErr = !fpStatus ? false : (!!fpStatus.notBefore && (new Date(fpStatus.notBefore).getTime() > Date.now())) || (!!fpStatus.notAfter && (new Date(fpStatus.notAfter).getTime() < Date.now()));

    if (!fpStatus) {
        return <div className="__titleMessage _empty"></div>;
    } else if (fpStatus && fpStatus.modulusMatch && !fpTimingErr) {
        return <div className="__titleMessage _empty"></div>;
    }
    const certWarning = `Necesita subir su certificado CSD para facturar`;
    const keyWarning = `Necesita subir su llave de su CSD para facturar`;
    const bothWarning = `Necesita subir su llave y certificado CSD para continuar`;
    const notYetWarning = `El CSD todavia no puede ser usado, espere hasta el ${moment(new Date(fpStatus.notBefore || Date.now()).getTime()).format('dddd, MMMM Do YYYY')}`;
    const notAnymoreWarning = `El CSD ya no es valido, venció el ${moment(new Date(fpStatus.notAfter || Date.now()).getTime()).format('dddd, MMMM Do YYYY')}`;
    const modulusWarning = `Su llave no coincide con su certificado CSD, es necesario agregar los archivos correctos`;
    let finalLabel;
    if (!fpStatus.cerModulus && !fpStatus.keyModulus) {
        finalLabel = bothWarning;
    } else if (!fpStatus.cerModulus) {
        finalLabel = certWarning;
    } else if (!fpStatus.keyModulus) {
        finalLabel = keyWarning;
    } else if (!!fpStatus.notBefore && (new Date(fpStatus.notBefore).getTime() > Date.now())) {
        finalLabel = notYetWarning;
    } else if (!!fpStatus.notAfter && (new Date(fpStatus.notAfter).getTime() < Date.now())) {
        finalLabel = notAnymoreWarning;
    } else {
        finalLabel = modulusWarning;
    }
    return (
        <div className="__titleMessage">
            <Link to="/setup/emisor?showcerts=true">
                <p>
                    {finalLabel}
                </p>
            </Link>
        </div>
    )
}



function SectionTitleBar({ currentUser, title, ignoreWarning }: { currentUser: ProfileQL, title: string, ignoreWarning?: boolean }) {

    return (
        <div className={`__titleBar`}>
            <h3>{title}</h3>
            {
                ignoreWarning ? <div className='__titleMessage _empty' />
                    :
                    <SectionCertWarning currentUser={currentUser} />
            }
        </div >
    )
}

export default SectionTitleBar;