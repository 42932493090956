/* eslint-disable no-useless-escape */
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

type Config = {
  onSubscription?: (registration: ServiceWorkerRegistration, subscription?: { endpoint: string, keys: { p256dh: string, auth: string } }) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};


const urlB64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function registerServiceW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(async (registration) => {
      console.log(`<SW> Service worker Registered: `, registration);
      registration.onupdatefound = () => {
        // A service Worker update was found (Not doing anything right now)
        console.log(`<SW> Service worker update found`)
      };

      // Looking for subscription requires service worker to be ready
      if (config?.onSubscription) {
        const applicationServerKey = urlB64ToUint8Array('BGRT_Tb88XrEp7JRozb4ACdFzTNhB56wGrYbOS5rtULk_Zy-TLyp-d5OA_uzoFhFgP_UaqrTPCUGGb-N3hz58Wk')
        const options = { applicationServerKey, userVisibleOnly: true }
        console.log(`<SW> requesting PushManager Subscription`)
        registration.pushManager.subscribe(options)
          .then(subscription => {
            const subsData: { endpoint: string, keys: { p256dh: string, auth: string } } = JSON.parse(JSON.stringify(subscription)) as any
            console.log(`<SW> subscription`, subsData)
            config.onSubscription(registration, subsData)
          })
          .catch(e => console.log(`<SW> ERROR requesting PushManager Subscription`, e));
      }

    })
    .catch(error => {
      console.error('<SW> Error during service worker registration:', error);
    });
}

// >> Make sure register is called ONLY on registered users, so posting has userId in cookies
export function register(config?: Config) {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    // The URL constructor is available in all browsers that support SW.
    const swUrl = `/js/serviceWorker.js`;
    // TODO: Change to lite.fiscalpop.com once ready
    registerServiceW(swUrl, config);
    navigator.serviceWorker.ready.then(() => {
      console.log(`<SW> ready`);
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(`<SW> Ready promise error`, error.message);
      });
  }
}
