import React from 'react'

export const labelFormat = (label: string, treatAs?: 'negative' | 'possitive') => {
    
    // NOTE: "treatAs" affects next in sequence (post), not previous
    // Cases such as "ingresos bajos, reduce tus gastos" should not cause "gastos" to change treatAs in {pre}
    // -------------------------------------------------------------------------------------------------------

    if (label.includes('pagada')) {
        const [pre, post] = label.split('pagada')
        return <span>{labelFormat(pre, treatAs || null)} <span className='green'>Pagada</span> {labelFormat(post, 'possitive')}</span>
    }
    if (label.includes('aceptada')) {
        const [pre, post] = label.split('aceptada')
        return <span>{labelFormat(pre, treatAs || null)} <span className='green'>Aceptada</span> {labelFormat(post, 'possitive')}</span>
    }
    if (label.includes('cancelada')) {
        const [pre, post] = label.split('cancelada')
        return <span>{labelFormat(pre, treatAs || null)} <span className='red'>Cancelada</span> {labelFormat(post, 'negative')}</span>
    }
    if (label.includes('expirado')) {
        const [pre, post] = label.split('expirado')
        return <span>{labelFormat(pre, treatAs || null)} <span className='red'>Expirado</span> {labelFormat(post, 'negative')}</span>
    }
    if (label.includes('expirar')) {
        const [pre, post] = label.split('expirar')
        return <span>{labelFormat(pre, treatAs || null)} <span className='red'>Expirar</span> {labelFormat(post, 'negative')}</span>
    }
    if (label.includes('excediendo')) {
        const [pre, post] = label.split('excediendo')
        return <span>{labelFormat(pre, treatAs || null)} <span className='red'>Excediendo</span> {labelFormat(post, 'negative')}</span>
    }
    if (label.includes('excedió')) {
        const [pre, post] = label.split('excedió')
        return <span>{labelFormat(pre, treatAs || null)} <span className='red'>Excedió</span> {labelFormat(post, 'negative')}</span>
    }
    if (label.includes('sin asignar')) {
        const [pre, post] = label.split('sin asignar')
        return <span>{labelFormat(pre, treatAs || null)} <span className='orange'>sin asignar</span> {labelFormat(post, treatAs || null)}</span>
    }
    if (label.includes('sin categoría')) {
        const [pre, post] = label.split('sin categoría')
        return <span>{labelFormat(pre, treatAs || null)} <span className='orange'>sin categoría</span> {labelFormat(post, treatAs || null)}</span>
    }
    if (label.includes('ingresos')) {
        const [pre, post] = label.split('ingresos')
        return <span>{labelFormat(pre, treatAs || null)} <span><b>ingresos</b></span> {labelFormat(post, 'possitive')}</span>
    }
    if (label.includes('gastos')) {
        const [pre, post] = label.split('gastos')
        return <span>{labelFormat(pre, treatAs || null)} <span><b>gastos</b></span> {labelFormat(post, 'negative')}</span>
    }
    if (label.includes('presupuesto')) {
        const [pre, post] = label.split('presupuesto')
        return <span>{labelFormat(pre, treatAs || null)} <span><b>presupuesto</b></span> {labelFormat(post, treatAs || null)}</span>
    }
    if (label.includes('Proyectos de gasto')) {
        const [pre, post] = label.split('Proyectos de gasto')
        return <span>{labelFormat(pre, treatAs || null)} <span><b>Proyectos de gasto</b></span> {labelFormat(post, treatAs || null)}</span>
    }
    if (label.includes('Probabilidad de éxito')) {
        const [pre, post] = label.split('Probabilidad de éxito')
        return <span>{labelFormat(pre, treatAs || null)} <span><b>Probabilidad de éxito</b></span> {labelFormat(post, treatAs || null)}</span>
    }


    // REGEX based changes
    if (/(-[0-9]+%)/g.test(label)) {
        const toReplace = /(-[0-9]+%)/g.exec(label)[0]
        const [pre, post] = label.split(toReplace)
        // gastsos negative should be green, else should be red
        const colorBySubject = (pre.includes('gastos') || treatAs === 'negative') ? 'green' : 'red';
        return <span>{labelFormat(pre, treatAs || null)} <span className={colorBySubject}>{toReplace}</span> {labelFormat(post, treatAs || null)}</span>
    }
    if (/(\+[0-9]+%)/g.test(label)) {
        const toReplace = /\+([0-9]+%)/g.exec(label)[0]
        const [pre, post] = label.split(toReplace)
        // gastsos possitive should be red, else should be green
        const colorBySubject = (pre.includes('gastos') || treatAs === 'negative') ? 'red' : 'green';
        return <span>{labelFormat(pre, treatAs || null)} <span className={colorBySubject}>{toReplace}</span> {labelFormat(post, treatAs || null)}</span>
    }
    if (/([0-9]+%)/g.test(label)) {
        const toReplace = /([0-9]+%)/g.exec(label)[0]
        const [pre, post] = label.split(toReplace)
        // gastsos possitive should be red, else should be green
        const colorBySubject = (pre.includes('gastos') || treatAs === 'negative') ? 'red' : 'green';
        return <span>{labelFormat(pre, treatAs || null)} <span className={colorBySubject}>{toReplace}</span> {labelFormat(post, treatAs || null)}</span>
    }
    if (/(\d+)\b/g.test(label)) {
        const toReplace = /(\d+)\b/g.exec(label)[0]
        const [pre, post] = label.split(toReplace)
        return <span>{labelFormat(pre, treatAs || null)} <b>{toReplace}</b> {labelFormat(post, treatAs || null)}</span>
    }

    // >> Special formatting, *<Text>* and _<Text>_
    if (/\*[\w\s]+\*/g.test(label)) {
        const toReplace = /\*[\w\s]+\*/g.exec(label)[0]
        const [pre, post] = label.split(toReplace)
        return <span>{labelFormat(pre, treatAs || null)} <b>{toReplace.replace(/\*/g, '')}</b> {labelFormat(post, treatAs || null)}</span>
    }

    return <span>{label}</span>
}