
import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';
import { BankProjectConcept_Expense, BankProjectConcept_Income } from '../../../../models/Administracion/Bank.Projects';
import unifiedMoment from '../../../../services/unifiedMoment';
import Button from '../../../Forms/Button';
import Input from '../../../Forms/Input';
import Select from '../../../Forms/Select';

import './Projection.income.dialog.scss';
import { ProjectionIngresoRoute } from './Projection.ingreso.ruta';

interface ProjectionExpenseDialogProps {
    isOpen: boolean;
    startDate: number;
    onRequestClose: () => void;
    onNewIncome: (income: BankProjectConcept_Income) => void
    onEditIncome: (income: BankProjectConcept_Income) => void
    existingIncomeNames: string[];
    toEditIncome?: BankProjectConcept_Income
}


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '98vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

const EMPTY_INCOME = (startDate: number): BankProjectConcept_Income => ({
    name: '',
    amount: 0,
    monthRepetition: 1,
    expectedMonthChange: {
        factor: 'percent',
        value: 0
    },
    startsAt: startDate
})


export function ProjectionIncomeNewDialog({
    isOpen,
    startDate,
    onRequestClose,
    onNewIncome,
    onEditIncome,
    existingIncomeNames,
    toEditIncome
}: ProjectionExpenseDialogProps) {

    const [income, setIncome] = useState<BankProjectConcept_Income>(EMPTY_INCOME(startDate))

    useEffect(() => {
        if (!isOpen) {
            setIncome(EMPTY_INCOME(startDate))
        }
        else if (isOpen && toEditIncome) {
            setIncome(toEditIncome)
        }
        else {
            setIncome(exp => ({ ...exp, startsAt: startDate > exp.startsAt ? startDate : exp.startsAt }))
        }
    }, [isOpen, startDate, toEditIncome])

    const handleExpenseChange = (property: keyof BankProjectConcept_Income) => (value: string | number) => {
        setIncome(exp => {
            const ups: { [key: string]: string | number } = {}
            if (property === 'name') {
                value = (value as string).substring(0, 30)
            }
            if (property === 'monthRepetition' && (value as number) < 1) {
                value = 1
            }
            if (property === 'monthRepetition' && (value as number) > 12) {
                value = 12
            }
            if (property === 'amount' && (value as number) < 0) {
                value = 0
            }
            ups[property] = value;

            return ({ ...exp, ...ups })
        })

    }

    const handleExpectedMonthChange = (value: number) => {
        if (value < 0) {
            value = 0;
        }
        if (value > 100) {
            value = 100;
        }
        const expectedMonthChange: any = {
            factor: 'percent',
            value
        }
        handleExpenseChange('expectedMonthChange')(expectedMonthChange)
    }

    const handleExpenseDateEndChange = (value: Date) => {
        let diff = unifiedMoment(value.getTime()).diff(income.startsAt, 'months');
        console.log('DIFF: ', diff);
        handleExpenseChange('monthRepetition')(diff)
    }

    const handleExpenseDateChange = (value: Date) => {
        handleExpenseChange('startsAt')(value.getTime())
    }

    const addIncome = () => {
        onNewIncome(income)
    }

    const updateIncome = () => {
        onEditIncome(income)
    }


    const nameInputError = existingIncomeNames.filter(s => toEditIncome?.name?.toLowerCase() !== s).includes(income.name.toLocaleLowerCase()) ? `Nombre ya usado` :
        ((income.name.length > 29) ? '30 carácteres máximo' : '')
    const isValid = income.name && income.amount && income.startsAt && !nameInputError;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Agregar nuevo ingreso"
        >
            <div className='card table' id="ProjectionExpenseDialog">
                <div className="cardTitle">
                    <span className="material-icons">
                        flag
                    </span>
                    {
                        toEditIncome ?
                            <h4>Editar ingreso esperado</h4>
                            :
                            <h4>Agregar ingreso esperado</h4>
                    }
                </div>
                <div className='cardBody'>
                    <div className='row'>
                        <h4>Ingreso</h4>
                        <div className='row three sm-two'>
                            <Input label='Nombre de ingreso' type='text' value={income.name} onChange={handleExpenseChange('name')}
                                hasError={!!nameInputError}
                                errorLabel={nameInputError}
                            />
                            <Input label='Cantidad inicial' type='number' isCurrency value={income.amount} onChange={handleExpenseChange('amount')} min={0} captionLabel={'Cantidad mensual inicial'} />
                            <Input label='Crecimiento Por Mes' type='number' isPercent value={income.expectedMonthChange.value} onChange={handleExpectedMonthChange} min={0} max={100} captionLabel={'Expectativa de cambio'} />
                        </div>
                    </div>
                    <div className='row'>
                        <h4>Duración del Ingreso</h4>
                        <div className='row three sm-two'>
                            <Input label='Mes inicial' type='date' value={income.startsAt} onChange={handleExpenseDateChange} dateFormat="MMMM yyyy" datepickerFormat='months' minDate={new Date(startDate)} maxDate={unifiedMoment().add(20, 'years').endOf('month').toDate()} />
                            <Input label='Mes Final' type='date'
                                value={unifiedMoment(income.startsAt).add(income.monthRepetition, 'month').valueOf()}
                                onChange={handleExpenseDateEndChange}
                                dateFormat="MMMM yyyy" datepickerFormat='months' minDate={unifiedMoment(startDate).add(1, 'month').toDate()} maxDate={unifiedMoment(startDate).add(12, 'month').toDate()}
                            />
                            <Input label={'Por cuantos Meses'} type='number' value={income.monthRepetition} onChange={handleExpenseChange('monthRepetition')} min={1} max={12} />
                        </div>
                    </div>
                    <p className='small lightGray'>
                        <b>Recuerda: </b> <br />
                        Si tu proyecto de negocio dura mas de 1 año, vuelve a agregar un segundo ingreso al termino del primer año, usando el resultado de esta menta como referencia.
                    </p>
                    <div className='row'>
                        <h4>Ruta</h4>
                        <ProjectionIngresoRoute income={income} />
                    </div>
                    <div className='actions row two '>
                        <div className='start'>
                            <Button secondary handleClick={onRequestClose}>
                                <span>
                                    Cancelar
                                </span>
                            </Button>
                        </div>
                        <div className='end'>
                            {
                                toEditIncome ?
                                    <Button primary disabled={!isValid} handleClick={updateIncome}>
                                        <span>
                                            Guardar
                                        </span>
                                    </Button>
                                    :
                                    <Button primary disabled={!isValid} handleClick={addIncome}>
                                        <span>
                                            Agregar Ingreso
                                        </span>
                                    </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}