import React, { FormEvent, useState, useEffect } from 'react';
import Modal from 'react-modal';

import './Account.cards.scss';
import Button from '../../Forms/Button';
import { useLazyQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../services/graphql.schema';
import sharedToasterSubject from '../../../services/shared.toasterSubject';
import { ProfileQL } from '../../../models/Profile';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { stripeKey } from '../../../stripeKeys';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { getCardsElementOptions } from './Account.cards.options';
import Loading from '../../Animations/loadScreen';
import { getNormalizedCurrentUrl } from '../../../services/window.query';
import RedirectPaymentStatus from './Account.card.redirectSetupIntent';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px 0px 6px',
        border: 'none',
        borderRadius: '12px',
        boxShadow: `0px 4px 16px rgba(0, 60, 128, 0.1)`,
    }
};

interface ActionCardProps {
    isOpen: boolean;
    professionalProfile: ProfileQL['professionalProfile']
    onRequestClose: (refresh: boolean) => void;

}
interface ActionCardFormProps {
    saving: boolean;
    setSaving: (bool: boolean) => void;
    onRequestClose: (refresh: boolean) => void;

}

const stripePromise = loadStripe(stripeKey);

function ActionCardForm({ onRequestClose, saving, setSaving }: ActionCardFormProps) {

    const stripe = useStripe();
    const elements: any = useElements(); // There is a strange TS error if not set to ANY
    if (elements) {
        elements.update({ locale: 'es', })
    }

    const [errorMessage, setErrorMessage] = useState(null);

    const processCard = async (event: FormEvent) => {
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }
        setSaving(true);
        const setRedirectBaseUrl = getNormalizedCurrentUrl()
        console.log(`<processCard> setRedirectBaseUrl: `, setRedirectBaseUrl);
        const { error, setupIntent } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: setRedirectBaseUrl,
            }
        });
        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setSaving(false)
            setErrorMessage(error.message);
            console.log(`Error`, error)
            sharedToasterSubject.next({ type: 'error', message: error.message, clearTs: 10000 })
        } else {
            // Your customer will be redirected to your `return_url`.
            // >> Check if "If Required applies for redirect cases"
            console.log(`confirmed Settup Intent`, setupIntent)
            setSaving(false)
            onRequestClose(true)
            sharedToasterSubject.next({ type: 'confirm', message: `Tarjeta agregada satisfactoriamente` });
            console.log('Card added');

        }
    }

    return (
        <div id="ActionCards">
            <div className="title">
                <h3>Agrega tarjeta</h3>
            </div>
            <form onSubmit={processCard}>
                <PaymentElement />
                <Button type="submit" primary={true} disabled={saving || (!stripe || !elements)}>
                    {
                        saving ?
                            <span>Guardando tarjeta</span>
                            :
                            <span>Guardar tarjeta</span>
                    }
                </Button>
            </form>
        </div>
    )
}

function ActionCards({ isOpen, onRequestClose, professionalProfile }: ActionCardProps) {

    RedirectPaymentStatus(); // Should Pull from Redirect cases 
    const [elementsOptions, setElementsOptions] = useState<StripeElementsOptions>(null);
    const [saving, _setSaving] = useState(false);

    const [getSetupIntent] = useLazyQuery(graphqlSchema.STRIPE.CARDS.getCardSetupIntent, {
        onCompleted: ({ getCardSetupIntent }: { getCardSetupIntent: { client_secret: string } }) => {
            console.log('Setup Secret: ', getCardSetupIntent);
            setElementsOptions(getCardsElementOptions(getCardSetupIntent.client_secret));
        },
        onError: (err) => {
            console.log('ERR: ', err);
        },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        if (isOpen) {
            getSetupIntent()
            _setSaving(false)
        }
    }, [isOpen, getSetupIntent])

    const setSaving = (bool: boolean) => {
        _setSaving(bool)
    }

    const renderPaymentElement = () => {
        return (
            <Elements stripe={stripePromise} options={elementsOptions}>
                <ActionCardForm onRequestClose={onRequestClose} saving={saving} setSaving={setSaving} />
            </Elements>
        )
    }

    const _requestCloseModal = () => {
        if (saving) { return; }
        onRequestClose(false)
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={_requestCloseModal}
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={!saving}
            shouldCloseOnOverlayClick={!saving}
            contentLabel="Agregar Tarjeta Modal"
        >
            {
                elementsOptions ?
                    renderPaymentElement()
                    : (
                        <div id="ActionCards">
                            <Loading relativePos={true} display={true} svgHeight={'90px'} />
                        </div>
                    )
            }

        </Modal>
    )
}

export default ActionCards;