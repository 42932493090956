import React from 'react';

import Modal from 'react-modal';
import BriefcaseSvg from '../../visuals/svg/briefcaseIcon';
import MoneySvg from '../../visuals/svg/moneyIcon';

import './Projection.new.dialog.scss';

interface ProjectionNewDialogProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onNewProject: (type: 'business' | 'expense') => void
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '98vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

export function ProjectionNewDialog({
    isOpen,
    onRequestClose,
    onNewProject
}: ProjectionNewDialogProps) {

    const _onNewProject = (type: 'business' | 'expense') => () => {
        onNewProject(type)
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Agregar nuevo proyecto"
        >
            <div id="NewProjectDialog" className='card table' >
                <div className="cardTitle withClose">
                    <h4>Elige el tipo de proyecto</h4>
                    <i className="material-icons" onClick={onRequestClose}>
                        close
                    </i>
                </div>
                <div className='cardBody'>
                    <div className='row two xs-one'>
                        <div className='card option' onClick={_onNewProject('business')}>
                            <h3>
                                Negocio
                            </h3>
                            <BriefcaseSvg width={48} color={'#AC2E78'} />
                            <p className='small gray'>
                                Objetivos de ingresos y gastos ajenos a tu presupuesto personal.
                            </p>
                        </div>
                        <div className='card option' onClick={_onNewProject('expense')}>
                            <h3>
                                Gasto
                            </h3>
                            <MoneySvg width={60} color={'#AC2E78'} />
                            <p className='small gray'>
                                Gastos especiales como vacaciones, construcciones, remodelaciones, etc.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}