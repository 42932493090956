import React from 'react';

interface SvgProps {
    width: number;
    color?: string
}

function FlagSvg({ width, color }: SvgProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 18 18" fill="none">
            <path d="M10.4424 0.847059C10.334 0.354 9.86701 0 9.3252 0H1.14C0.510395 0 0 0.474052 0 1.05882V16.9412C0 17.5259 0.510395 18 1.14 18C1.7696 18 2.28 17.5259 2.28 16.9412V10.5882H8.664L8.9376 11.8588C9.04104 12.3546 9.51108 12.711 10.0548 12.7059H15.96C16.5896 12.7059 17.1 12.2318 17.1 11.6471V3.17647C17.1 2.5917 16.5896 2.11765 15.96 2.11765H10.716L10.4424 0.847059Z" fill={color || "#AC2E78"} />
        </svg>
    )
}

export default FlagSvg;