import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../../../base.model';
import { ProfileQL } from '../../../../models/Profile';

import './Cotizaciones.editor.scss';

import moment from 'moment';
import SectionTitleBar from '../../../components/titlebar';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../../services/graphql.schema';
import { Cotizacion, CotizacionPublic, CotizacionHistory } from '../../../../models/Cotizaciones';
import Select from '../../../Forms/Select';
import Input from '../../../Forms/Input';
import { formatCurrencyStyled } from '../../../visuals/currency.formatted';
import Button from '../../../Forms/Button';
import CotizacionTotales from '../Cotizaciones.generator.totals';
import { BillConceptos } from '../../../../models/Factura';
import TextArea from '../../../Forms/TextArea';
import CotizacionViewHistory from '../../View/Cotizacion.view.history';
import ItemMenu from '../../../Forms/menu/MenuComponent';
import sharedToasterSubject from '../../../../services/shared.toasterSubject';
import CopyToClipboard, { CopyToClipboardChild } from '../../../visuals/clipboard.copy';

import Modal from 'react-modal';
import { numberToCurrencyString } from '../../../../services/formatting';
import CotizacionEditorStampDialog from './Cotizaciones.editor.stamp';
import { parseStampErrorFromGraphQL } from '../../../../utils/error.graphql';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        border: 'none',
        background: 'transparent',
        boxShadow: 'none',
        padding: '3em'
    }
};
Modal.setAppElement('#root');

moment.locale('es');

interface CotizacionesEditorProps extends BaseReact {
    currentUser: ProfileQL;
}

const DISCOUNT_OPTIONS = [
    { value: '', label: 'Sin descuentos' },
    { value: 'pronto_pago', label: 'Por pronto pago' },
    { value: 'por_cliente', label: 'Cliente frecuente' },
    { value: 'normal', label: 'Normal' },
]
const INSTALLMENT_OPTIONS = [
    { value: 1, label: 'Sin mensualidades' },
    { value: 3, label: '3 Meses sin intereses' },
    { value: 6, label: '6 Meses sin intereses' },
    { value: 9, label: '9 Meses sin intereses' },
    { value: 12, label: '12 Meses sin intereses' },
]

function CotizacionesEditor({ location, history, currentUser }: CotizacionesEditorProps) {
    const { id } = useParams<{ id: string }>();
    const [cotizacion, setCotizacion] = useState<Cotizacion>(null);
    const [changeHistory, setChangeHistory] = useState<CotizacionHistory>(null)
    const [changesParsed, setChangesParsed] = useState<{ title: string; quantity: number; unitPrice: number, date: number }[]>([])

    const [cotizacionId, setCotizacionId] = useState<string>('');


    // Action commit
    const [approved, setApproved] = useState<boolean>(null);
    const [message, setMessage] = useState('');
    const [animateConfirm, setAnimateConfirm] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Stamping 
    const [stampDialog, setStampDialog] = useState(false);

    const [confirmCancelled] = useMutation(graphqlSchema.FISCALPOP.COTIZACION.cancelCotizacion, {
        onCompleted: ({ cancelCotizacion }: { cancelCotizacion: Cotizacion }) => {
            console.log('Cancelled res: ', cancelCotizacion);
            setCotizacion(cancelCotizacion);
            setChangeHistory(null);
            setChangesParsed([]);
            setIsSubmitting(false);
            setMessage('');
            sharedToasterSubject.next({ type: 'warning', message: 'Se canceló la cotización correctamente' });
        },
        onError: (e) => {
            console.error('Error cancelling: ', e.graphQLErrors[0]);
            setIsSubmitting(false);
            sharedToasterSubject.next({ type: 'error', message: `Error al cancelar la cotización: ${e.graphQLErrors[0].message}` });
        }
    })
    const [confirmApprovalChanges] = useMutation(graphqlSchema.FISCALPOP.COTIZACION.approveCotizacionChanges, {
        onCompleted: ({ approveCotizacionChanges }: { approveCotizacionChanges: Cotizacion }) => {
            console.log('Approved changes: ', approveCotizacionChanges);
            setCotizacion(approveCotizacionChanges);
            setChangeHistory(null);
            setChangesParsed([]);
            setIsSubmitting(false);
            setMessage('');
        },
        onError: (e) => {
            console.error('Error approving changes: ', e.graphQLErrors[0]);
            setIsSubmitting(false);
        }
    })
    const [setAsPaidManually] = useMutation(graphqlSchema.FISCALPOP.COTIZACION.manuallySetCotizacionAsPaid, {
        onCompleted: ({ manuallySetCotizacionAsPaid }: { manuallySetCotizacionAsPaid: Cotizacion }) => {
            console.log('PAID changes: ', manuallySetCotizacionAsPaid);
            sharedToasterSubject.next({ type: 'confirm', message: `Se marco la cotización como pagada` });
            // Go to Cotizacion DASHBOARD
            history.push('/cotizaciones/lista');
        },
        onError: (e) => {
            console.error('Error marking as paid: ', e.graphQLErrors[0]);
            setIsSubmitting(false);
            sharedToasterSubject.next({ type: 'error', message: e.graphQLErrors[0].message });
        }
    })
    const [submitToStamp] = useMutation(graphqlSchema.FISCALPOP.COTIZACION.stampCotizacion, {
        onCompleted: ({ stampCotizacion }: { stampCotizacion: Cotizacion }) => {
            console.log('stampCotizacion: ', stampCotizacion);
            sharedToasterSubject.next({ type: 'confirm', message: `Facturado correctamente` });
            getCotizacion({
                variables: {
                    cotizacionId: id
                }
            })
            setStampDialog(false)
        },
        onError: (e) => {
            console.error('Error stamping: ', e.graphQLErrors[0]);
            sharedToasterSubject.next({ type: 'error', message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clickToClose: true })
           
        }
    })

    const [getCotizacion] = useLazyQuery(graphqlSchema.FISCALPOP.COTIZACION.fetchCotizacion, {
        onCompleted: ({ fetchCotizacion }: { fetchCotizacion: CotizacionPublic }) => {
            console.log('fetchCotizacion: ', fetchCotizacion);
            setCotizacion(fetchCotizacion.cotizacion);
            if (fetchCotizacion.cotizacion.changesRequested) {
                const changes = fetchCotizacion.cotizacion.history.find(h => !h.closed && h.event === 'changes_requested');
                if (!!changes) {
                    setChangeHistory(changes);
                    setChangesParsed(changes.applicableConcepts.map((aC) => {
                        const conceptRel = fetchCotizacion.cotizacion.conceptos.find(c => c.descripcion === aC.title);
                        return ({
                            title: aC.title,
                            quantity: aC.quantity,
                            date: changes.createdAt,
                            unitPrice: conceptRel.valorUnitario
                        })
                    }))
                }
            }
        },
        onError: (e) => {
            console.error('Error getting cotizacion: ', e.graphQLErrors[0]);
        }
    });


    useEffect(() => {
        getCotizacion({
            variables: {
                cotizacionId: id
            }
        })
    }, [getCotizacion, id]);


    const stampCotizacionModal = () => {
        setStampDialog(true);
    }

    const onCotizacionStampCancel = () => {
        setStampDialog(false);
    }

    const onCotizacionStamp = ({
        nombre,
        rfc,
        usoCFDI,
        formaPago,
        email,
        regimen,
        zip
    }: { nombre: string; rfc: string; usoCFDI: string; email?: string; formaPago: string, regimen: string, zip: string  }) => {
        submitToStamp({
            variables: {
                cotizacionId: id,
                receptor: {
                    customerId: null,
                    nombre,
                    rfc,
                    usoCFDI,
                    email,
                    regimen, 
                    zip
                }
            }
        })
    }


    const handleCotizacionEdit = (property: 'paymentTermsOffered' | 'discount' | 'discountName') => {
        return (value: number | string) => {
            setCotizacion(cot => {
                if (property === 'paymentTermsOffered') {
                    cot.paymentTermsOffered = value as 1 | 3 | 6 | 9 | 12;
                }
                else if (property === 'discount') {
                    cot[property] = value as number;
                    if (cot[property] < 0) {
                        cot[property] = 0;
                    }
                    if (cot[property] > 99) {
                        cot[property] = 99;
                    }
                }
                else if (property === 'discountName') {
                    cot[property] = value as string;
                }
                return Object.assign({}, cot);
            })
        }
    }

    const setPreResponseApproval = (toApply: boolean) => {
        return () => {
            setApproved(toApply);
            setAnimateConfirm(true);
        }
    }

    const cancelConfirm = () => {
        setAnimateConfirm(false);
        setMessage('');
    }

    const submitConfirm = () => {
        setIsSubmitting(true);
        confirmApprovalChanges({
            variables: {
                historyId: changeHistory._id,
                approval: approved,
                message: message
            }
        })
    }

    const cancelCotizacion = () => {
        confirmCancelled({
            variables: {
                cotizacionId: cotizacion._id
            }
        })
    }

    const markCotizacionAsPaid = () => {
        setAsPaidManually({
            variables: {
                cotizacionId: cotizacion._id,
                paymentMethod: 'otro'
            }
        })
    }

    const closeModal = () => {
        setCotizacionId(null);
    }

    const shareModal = () => {
        setCotizacionId(cotizacion._id);
    }

    if (!cotizacion) {
        return (
            <div id="CotizacionesEditor">
                <SectionTitleBar currentUser={currentUser} title="Cotizaciones" />
            </div>
        )
    }

    // This is percent
    const feeTotalPercent = (cotizacion.feeFiscalpop + cotizacion.feeTransaction);
    const cotizacionAfterMod: Cotizacion = Object.assign({}, cotizacion, {
        conceptos: (JSON.parse(JSON.stringify(cotizacion.conceptos)) as BillConceptos[]).map(c => {
            const _changes = changesParsed.find(cP => cP.title === c.descripcion);
            if (_changes) {
                c.cantidad = _changes.quantity;
            }
            return c;
        }).filter(c => !!c.cantidad)
    })



    // RENDER
    // ================================

    const renderGeneratedModal = () => {

        const urlEndpoint = `https://personal.fiscalpop.com/views/cotizaciones/${cotizacionId || ''}`;
        const logoUrl = `${currentUser.fiscalpopProfile.logoUrl}`;
        const feeTotalPercent = (cotizacion.feeFiscalpop + cotizacion.feeTransaction);
        const totalFeeDiscountConsidered = cotizacion.translateFee ? ((cotizacion.total * (1 + (feeTotalPercent / 100))) * (1 - (cotizacion.discount / 100))) : ((cotizacion.total) * (1 - (cotizacion.discount / 100)))

        const cotizacionMessage = `${currentUser.professionalProfile.companyName || currentUser.fiscalpopProfile.nombre}
Cotización de ${cotizacion.conceptos.length > 1 ? cotizacion.conceptos.length + ' productos' : 'producto'} con valor de $${numberToCurrencyString(totalFeeDiscountConsidered)}

${urlEndpoint}?hLogo=${logoUrl}&hCompany=${encodeURI(currentUser.professionalProfile.companyName || currentUser.fiscalpopProfile.nombre)}`;
        return (
            <Modal
                isOpen={!!cotizacionId}
                onRequestClose={closeModal}
                style={customStyles}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                contentLabel="Cotizacion shareable"
            >
                <div id="CotizacionShareDialog">
                    <h2>
                        Comparte tu cotización al cliente
                    </h2>
                    <div className="_shareable">
                        <p>
                            La cotización fue generada {!!cotizacion.email ? <span className="_sent">y enviada al correo {cotizacion.email}</span> : <span className="_notSent">pero no se envió, al no agregar correo</span>}
                        </p>
                        <p>
                            Copia el siguiente link si deseas enviarla por algún otro medio
                        </p>
                        <div className="copyLink">
                            <CopyToClipboard value={`https://personal.fiscalpop.com/views/cotizaciones/${cotizacionId}`} textLabel={`https://personal.fiscalpop.com/views/cotizaciones/${cotizacionId}`} />
                        </div>
                        <div className="shareables">
                            {
                                /*
                                <a href={`https://api.whatsapp.com/send?text=${encodeURI(cotizacionMessage)}`} target="_blank" rel="noopener noreferrer" >
                                 */
                            }
                            <a href={`whatsapp://send?text=${encodeURI(cotizacionMessage)}`} target="_blank" rel="noopener noreferrer" >
                                <div className="iconShare">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30px" height="30px" viewBox="0 0 1280.000000 720.000000">
                                        <g transform="translate(-490,1020) scale(0.18,-0.18)" stroke="none">
                                            <path d="M6255 6844 c-540 -35 -1107 -229 -1555 -532 -473 -320 -848 -752 -1091 -1256 -133 -276 -216 -536 -273 -856 -43 -240 -52 -602 -22 -880 40 -374 177 -822 362 -1188 l53 -103 -123 -367 c-68 -202 -191 -570 -274 -818 -84 -249 -152 -459 -152 -469 0 -9 13 -22 29 -28 26 -10 29 -14 24 -45 -6 -32 -5 -34 18 -27 41 13 936 298 1314 420 198 63 368 115 378 115 9 0 52 -17 95 -39 366 -184 756 -294 1171 -332 164 -14 498 -7 659 16 954 132 1766 659 2266 1468 163 264 318 632 401 952 79 307 117 688 96 982 -54 781 -356 1473 -881 2017 -509 527 -1157 853 -1895 952 -108 14 -482 26 -600 18z m391 -684 c357 -29 650 -108 959 -259 419 -206 770 -514 1030 -906 200 -301 323 -625 371 -979 23 -168 23 -508 0 -680 -163 -1209 -1161 -2141 -2372 -2217 -427 -26 -824 44 -1212 214 -107 47 -284 143 -339 183 -17 13 -39 24 -49 24 -9 0 -222 -65 -472 -145 -250 -80 -456 -145 -457 -143 -2 2 62 197 141 433 79 237 144 442 144 458 0 16 -18 53 -44 90 -418 599 -554 1426 -351 2127 45 152 82 245 155 390 200 391 505 732 880 982 473 316 1064 472 1616 428z" />
                                            <path d="M5323 5236 c-23 -7 -56 -23 -75 -34 -51 -32 -199 -190 -245 -262 -147 -229 -180 -534 -92 -832 67 -225 149 -397 299 -629 190 -292 313 -450 510 -653 296 -305 545 -476 927 -635 282 -118 490 -185 607 -197 81 -8 258 20 362 58 144 52 309 168 373 262 64 96 130 313 138 457 l6 95 -31 36 c-22 24 -112 78 -294 176 -432 232 -487 254 -555 218 -17 -8 -81 -73 -141 -143 -178 -207 -215 -243 -245 -243 -38 0 -287 127 -403 205 -135 92 -223 166 -334 281 -132 137 -275 333 -355 486 l-18 36 72 79 c95 101 134 162 172 268 39 108 37 141 -20 290 -51 133 -92 243 -163 434 -58 157 -101 221 -161 240 -57 17 -287 22 -334 7z" />
                                        </g>
                                    </svg>
                                    <p>
                                        Watsapp App
                                    </p>
                                </div>
                            </a>

                            <a href={`https://web.whatsapp.com/send?text=${encodeURI(cotizacionMessage)}`} target="_blank" rel="noopener noreferrer" >
                                <div className="iconShare">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30px" height="30px" viewBox="0 0 1280.000000 720.000000">
                                        <g transform="translate(-490,1020) scale(0.18,-0.18)" stroke="none">
                                            <path d="M6255 6844 c-540 -35 -1107 -229 -1555 -532 -473 -320 -848 -752 -1091 -1256 -133 -276 -216 -536 -273 -856 -43 -240 -52 -602 -22 -880 40 -374 177 -822 362 -1188 l53 -103 -123 -367 c-68 -202 -191 -570 -274 -818 -84 -249 -152 -459 -152 -469 0 -9 13 -22 29 -28 26 -10 29 -14 24 -45 -6 -32 -5 -34 18 -27 41 13 936 298 1314 420 198 63 368 115 378 115 9 0 52 -17 95 -39 366 -184 756 -294 1171 -332 164 -14 498 -7 659 16 954 132 1766 659 2266 1468 163 264 318 632 401 952 79 307 117 688 96 982 -54 781 -356 1473 -881 2017 -509 527 -1157 853 -1895 952 -108 14 -482 26 -600 18z m391 -684 c357 -29 650 -108 959 -259 419 -206 770 -514 1030 -906 200 -301 323 -625 371 -979 23 -168 23 -508 0 -680 -163 -1209 -1161 -2141 -2372 -2217 -427 -26 -824 44 -1212 214 -107 47 -284 143 -339 183 -17 13 -39 24 -49 24 -9 0 -222 -65 -472 -145 -250 -80 -456 -145 -457 -143 -2 2 62 197 141 433 79 237 144 442 144 458 0 16 -18 53 -44 90 -418 599 -554 1426 -351 2127 45 152 82 245 155 390 200 391 505 732 880 982 473 316 1064 472 1616 428z" />
                                            <path d="M5323 5236 c-23 -7 -56 -23 -75 -34 -51 -32 -199 -190 -245 -262 -147 -229 -180 -534 -92 -832 67 -225 149 -397 299 -629 190 -292 313 -450 510 -653 296 -305 545 -476 927 -635 282 -118 490 -185 607 -197 81 -8 258 20 362 58 144 52 309 168 373 262 64 96 130 313 138 457 l6 95 -31 36 c-22 24 -112 78 -294 176 -432 232 -487 254 -555 218 -17 -8 -81 -73 -141 -143 -178 -207 -215 -243 -245 -243 -38 0 -287 127 -403 205 -135 92 -223 166 -334 281 -132 137 -275 333 -355 486 l-18 36 72 79 c95 101 134 162 172 268 39 108 37 141 -20 290 -51 133 -92 243 -163 434 -58 157 -101 221 -161 240 -57 17 -287 22 -334 7z" />
                                        </g>
                                    </svg>
                                    <p>
                                        Watsapp Web
                                    </p>
                                </div>
                            </a>
                            <CopyToClipboardChild value={urlEndpoint}>
                                <div className="iconShare">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="25px" height="25px">
                                        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                    </svg>
                                    <p>
                                        Copiar URL
                                    </p>
                                </div>
                            </CopyToClipboardChild>
                        </div>
                    </div>
                    <div className="actions">
                        <Button secondary={true} handleClick={closeModal}>
                            <span>Cerrar</span>
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }

    const titleByStatus = () => {
        if (cotizacion.status === 'aprobada') {
            return 'Cotización aprobada'
        }
        else if (cotizacion.status === 'pagada') {
            return 'Cotización pagada'
        }
        else if (cotizacion.status === 'enviada') {
            return 'Cotización pendiente'
        }
        else if (cotizacion.status === 'negociacion') {
            return 'Cambios solicitados en cotización'
        }
        else if (cotizacion.status === 'pendiente') {
            return 'Cotización pendiente'
        }
        else if (cotizacion.status === 'declinada') {
            return 'Cotización declinada'
        }
    }

    return (
        <div id="CotizacionesEditor">
            <SectionTitleBar currentUser={currentUser} title="Cotizaciones" />
            <div className="cotizacionesEditorContent">
                <div className="card table">
                    <div className="cardTitle">
                        <h4>{titleByStatus()}</h4>
                    </div>
                    <div className="cardBody">
                        <div className="_itemOptions">
                            <ItemMenu triggerContent={<span className="material-icons">
                                more_vert
                            </span>}>
                                <div className="_option warning" onClick={cancelCotizacion}>
                                    <span className="material-icons">
                                        delete
                                    </span>
                                    <p><b>Cancelar cotización</b></p>
                                </div>
                                {
                                    false ?
                                        <div className="_option" onClick={cancelCotizacion}>
                                            <span className="material-icons">
                                                content_copy
                                            </span>
                                            <p>Clonar cotización</p>
                                        </div>
                                        : ''
                                }
                                {
                                    cotizacion.paid && !cotizacion.uuid ?
                                        <div className="_option _bill" onClick={stampCotizacionModal}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="-2 0 21 25" fill="none"><path d="M18 24.2537H3C2.20435 24.2537 1.44129 23.9562 0.87868 23.4267C0.316071 22.8972 0 22.179 0 21.4301V3.07719C0 2.32835 0.316071 1.61017 0.87868 1.08065C1.44129 0.55114 2.20435 0.253662 3 0.253662L18 0.253662C18.7956 0.253662 19.5587 0.55114 20.1213 1.08065C20.6839 1.61017 21 2.32835 21 3.07719V21.4301C21 22.179 20.6839 22.8972 20.1213 23.4267C19.5587 23.9562 18.7956 24.2537 18 24.2537ZM6.5625 17.4588V18.7831H18.375V17.4588H6.5625ZM2.625 13.4875V14.8118H18.375V13.4875H2.625ZM2.625 10.8405V12.1647H18.375V10.8419L2.625 10.8405ZM2.625 8.19343V9.51766H18.375V8.19484L2.625 8.19343ZM3.939 4.22354V6.8706H17.064V4.22496L3.939 4.22354Z" fill="#AC2E78"></path></svg>
                                            <p>Facturar cotización</p>
                                        </div>
                                        : (cotizacion.paid && cotizacion.uuid ?
                                            <a title="Descargar Factura" rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${currentUser.professionalProfile.authToken}?uuid=${cotizacion.uuid}`} download={true}>
                                                <div className="_option">
                                                    <i className="material-icons">
                                                        picture_as_pdf
                                                    </i>
                                                    <span>
                                                        Descargar Factura
                                                    </span>
                                                </div>
                                            </a>
                                            : '')
                                }
                                <div className="_option" onClick={markCotizacionAsPaid}>
                                    <span className="material-icons">
                                        request_quote
                                    </span>
                                    <p>Marcar pagada</p>
                                </div>
                                <div className="_option" onClick={shareModal}>
                                    <span className="material-icons">
                                        link
                                    </span>
                                    <p>Compartir cotización</p>
                                </div>
                            </ItemMenu>
                        </div>
                        <div className="row two lg-one descripcionBlock">
                            <div className="general">
                                <div className="row two sm-gap-compressed sm-one">
                                    <div className="keyVal">
                                        <p className="label"> Cliente:</p>
                                        <p>{cotizacion.name}</p>
                                    </div>
                                    <div className="keyVal">
                                        <p className="label"> Fecha:</p>
                                        <p>{moment(cotizacion.createdAt).format('DD / MMM / YY')}</p>
                                    </div>
                                </div>
                                <Select label="Meses sin intereses" value={cotizacion.paymentTermsOffered} options={INSTALLMENT_OPTIONS} onChange={handleCotizacionEdit('paymentTermsOffered')} disabled={!cotizacion.changesRequested || cotizacion.wasCancelled} />
                                <div className="discountSection">
                                    <Select label="¿Descuentos?" value={cotizacion.discountName} options={DISCOUNT_OPTIONS} onChange={handleCotizacionEdit('discountName')} disabled={!cotizacion.changesRequested || cotizacion.wasCancelled} />
                                    <Input type="number" disabled={!cotizacion.discountName || !cotizacion.changesRequested || cotizacion.wasCancelled} value={cotizacion.discount} label="Monto de descuento" onChange={handleCotizacionEdit('discount')} isPercent={true} placeholder="0%" min={0} max={99} step={0.5} />
                                </div>
                            </div>
                            <div className="description">
                                <p><b>Descripción de la cotización:</b></p>
                                <p>
                                    {cotizacion.note}
                                </p>
                            </div>
                        </div>
                        <div className="conceptosContain">
                            <h2>
                                Conceptos Aprobados
                            </h2>
                            {
                                cotizacion.conceptos.map((concepto, i) => {
                                    const unitaryAmountPreTax = concepto.valorUnitario;
                                    const taxAmount = concepto.impuestos.reduce((p, c) => {
                                        if (c.tasa) {
                                            p += (concepto.valorUnitario * c.tasa);
                                        } else if (c.cuota) {
                                            p += c.cuota;
                                        }
                                        return p
                                    }, 0);
                                    const unitaryAmount = cotizacion.translateFee ? (unitaryAmountPreTax + taxAmount) * (1 + (feeTotalPercent / 100)) : (unitaryAmountPreTax + taxAmount);
                                    // >> Which product(s) was/were rejected
                                    const conceptApproval = (changeHistory?.applicableConcepts?.length ? changeHistory.applicableConcepts : []).find(cA => cA.title === concepto.descripcion);

                                    const hasApproval = true;
                                    const approvalForConcept = !conceptApproval ? true : false;
                                    return (
                                        <div className={`entry ${cotizacion.changesRequested ? 'readonly' : ''} ${!!hasApproval ? (approvalForConcept ? 'approved' : 'rejected') : ''}`} key={i}>
                                            <p className="description">{concepto.descripcion}</p>
                                            <p className="amount">{formatCurrencyStyled(unitaryAmount)}</p>
                                            <div className="cantidaddMod">
                                                <input className="cantidad" type="number" disabled={true} value={concepto.cantidad} />
                                            </div>
                                            <p className="amount">{formatCurrencyStyled(concepto.cantidad * unitaryAmount)}</p>
                                            <div className={`acknowledge ${!!hasApproval ? (approvalForConcept ? 'approved' : 'rejected') : ''}`}>
                                                <span className="material-icons bad"  >
                                                    cancel
                                                </span>
                                                <span className="material-icons good" >
                                                    check_circle
                                                </span>
                                                <div className="bad xs">
                                                    <span className="material-icons" >
                                                        close
                                                    </span>
                                                </div>
                                                <div className="good xs" >
                                                    <span className="material-icons" >
                                                        done
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            cotizacion.changesRequested ?
                                <div className="cambiosContain">
                                    <h2>
                                        Cambios Solicitados
                                    </h2>
                                    <div className={`solicitudEntry ${approved ? 'green' : ''}`}>
                                        {
                                            changesParsed.map((chpar) => {
                                                return (
                                                    <div key={chpar.title} className="conceptoChange">
                                                        <div className="_descripcion">
                                                            <p className="desc">
                                                                {chpar.title}
                                                            </p>
                                                            <p className="_warn">
                                                                Concepto declinado
                                                            </p>
                                                            <p className="_warn">
                                                                {moment(chpar.date).format('DD / MMM / YY')}
                                                            </p>
                                                        </div>
                                                        <p>
                                                            {formatCurrencyStyled(chpar.unitPrice)}
                                                        </p>
                                                        <p className="_qty">
                                                            {chpar.quantity}
                                                        </p>
                                                        <p>
                                                            {formatCurrencyStyled(chpar.unitPrice * chpar.quantity)}
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                        <p className="notes">
                                            {changeHistory?.message || ''}
                                        </p>
                                        <div className="animateWrap">
                                            <div className={`_actionButtons ${!animateConfirm ? '' : '_hidden'}`}>
                                                <div className="actions">
                                                    <Button primary={true} handleClick={setPreResponseApproval(true)}>
                                                        <span>
                                                            Aprobar Cambio
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div className="actions">
                                                    <Button tertiary={true} handleClick={setPreResponseApproval(false)}>
                                                        <span>
                                                            Rechazar Cambio
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className={`_actionConfirm ${animateConfirm ? '' : '_hidden'}`}>
                                                <div className="actions">
                                                    <div className="_confirmSet">
                                                        {
                                                            approved ?
                                                                <span> Cambios aprobados</span>
                                                                :
                                                                <span> Cambios rechazados </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`_messageConfirm ${!animateConfirm ? '_hidden' : ''}`}>
                                            <TextArea label='' placeholder="Escribir mensaje a cliente" value={message} onChange={setMessage} />
                                            <div className="buttons">
                                                <Button tertiary={true} handleClick={cancelConfirm} disabled={isSubmitting}>
                                                    <span>Cancelar</span>
                                                </Button>
                                                <Button primary={true} handleClick={submitConfirm} disabled={isSubmitting}>
                                                    <span>Enviar</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }
                        {
                            cotizacion.changesRequested ?
                                ''
                                :
                                <div className="historyContainer">
                                    <CotizacionViewHistory histories={cotizacion.history} logoUrl={currentUser.fiscalpopProfile.logoUrl} />
                                </div>
                        }
                        <div className="cambiosTotal">
                            {
                                !cotizacion.changesRequested ?
                                    <h2>
                                        Total cotizado
                                    </h2>
                                    :
                                    <h2>
                                        Nuevo total cotizado
                                    </h2>
                            }
                            <CotizacionTotales cotizacion={cotizacionAfterMod} />
                        </div>
                    </div>
                </div>
            </div>
            {renderGeneratedModal()}
            <CotizacionEditorStampDialog
                requestClose={onCotizacionStampCancel}
                requestStamp={onCotizacionStamp}
                modalOpen={stampDialog} />
        </div>
    )
}

export default CotizacionesEditor;