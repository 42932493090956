import React, { useEffect, useState } from 'react';
import { CFDI, FiscalpopCFDI, PagoCfdiPreXml, ToBillArguments, ToBillEvent } from '../../../models/Factura';
import { formatCurrencyStyled } from '../../visuals/currency.formatted';

import './MisFacturas.relationship.scss';

import moment from 'moment';
import { calculateTotal } from '../../..//utils/calculate.conceptos';
import { getMontoFromPagoJson } from '../../../utils/pago.getter';

moment.locale('es');

interface RelationshipProps {
    authToken: string;
    billArgument: ToBillArguments<ToBillEvent>;
    CFDI: CFDI;
    requestClose: () => void
}

function RelationshipPaymentEvent({ event, uuidSelected, authToken }: { event: ToBillEvent; uuidSelected: string; authToken: string }) {
    const [pago, setPago] = useState<FiscalpopCFDI>(null)
    useEffect(() => {
        console.log(`<RelationshipPaymentEvent> uE: `, event);
        if (event.uuid) {
            fetch(`https://api.fiscalpop.com/api/v1/cfdi/find/${authToken}/${event.uuid}`)
                .then(async response => {
                    if (response.ok) {
                        const json = await response.json();
                        return json;
                    } else {
                        const err = await response.text();
                        throw err;
                    }
                })
                .then((b: FiscalpopCFDI[]) => {
                    console.log('Factura origin: ', b[0]);
                    if (b[0]) {
                        setPago(b[0]);
                    } else {
                        setPago(null);
                    }
                })
                .catch(e => {
                    console.error('Error looking for CFDI: ', e);
                    setPago(null);
                })
        }
    }, [event, authToken]);

    const dateRef = event.closedAt || event.when;

    return (
        <div className={`_subRelationship ${uuidSelected === event.uuid ? '_selected' : ''} ${!event.uuid ? '_unstamped' : ''}`}>
            {
                !!pago && !pago.status ?
                    <span className="material-icons _cancelled">
                        close
                    </span>
                    :
                    <span className="material-icons _checked">
                        done
                    </span>
            }
            <div className="row two gap-compressed">
                <p className="_bold">
                    Parcialidad {event.paymentNum}
                </p>
                <p className="_bold end">
                    {
                        !pago ? formatCurrencyStyled(event.paymentCFDI.pagos[0].monto)
                            : formatCurrencyStyled(parseFloat(getMontoFromPagoJson(pago.json as PagoCfdiPreXml)))
                    }
                </p>
            </div>
            <div className="row two gap-compressed">
                <p className="_info"> {moment(dateRef).format('DD MMM YYYY')} </p>
                <p className="_info end"> {!pago ? '' : `${pago.json['@'].Serie} - ${pago.json['@'].Folio}`} </p>
            </div>
            {
                event.uuid ?
                    <p className={`_info ${!!pago && !pago.status ? '_cancelled' : ''}`}> {event.uuid} </p>
                    : ' '
            }
        </div>
    )
}

function RelationshipBillEvent({ event, uuidSelected, authToken }: { event: ToBillEvent; uuidSelected: string; authToken: string }) {
    const [bill, setBill] = useState<FiscalpopCFDI>(null)
    const [notaCredito, setNotaCredito] = useState<FiscalpopCFDI>(null)
    useEffect(() => {
        if (event.uuid) {
            fetch(`https://api.fiscalpop.com/api/v1/cfdi/find/${authToken}/${event.uuid}`)
                .then(async response => {
                    if (response.ok) {
                        const json = await response.json();
                        return json;
                    } else {
                        const err = await response.text();
                        throw err;
                    }
                })
                .then((b: FiscalpopCFDI[]) => {
                    console.log('Factura origin: ', b);
                    if (b[0]) {
                        setBill(b[0]);
                    } else {
                        setBill(null);
                    }
                })
                .catch(e => {
                    console.error('Error looking for CFDI: ', e);
                    setBill(null);
                })
        }
        if (event.notaUUID) {
            fetch(`https://api.fiscalpop.com/api/v1/cfdi/find/${authToken}/${event.notaUUID}`)
                .then(async response => {
                    if (response.ok) {
                        const json = await response.json();
                        return json;
                    } else {
                        const err = await response.text();
                        throw err;
                    }
                })
                .then((b: FiscalpopCFDI[]) => {
                    console.log('Factura origin: ', b);
                    if (b[0]) {
                        setNotaCredito(b[0]);
                    } else {
                        setNotaCredito(null);
                    }
                })
                .catch(e => {
                    console.error('Error looking for CFDI: ', e);
                    setNotaCredito(null);
                })
        }
    }, [event, authToken]);
    const renderNotaCred = () => {
        if (!notaCredito) {
            if (!!event.NotaCreditoCFDI) {
                // This is still a pending bill
                const { total: totalNota } = calculateTotal(event.NotaCreditoCFDI.conceptos)
                return (
                    <div className={`_subRelationship _unstamped`}>
                        <div className="row two gap-compressed">
                            <p className="_bold">
                                Nota de crédito
                            </p>
                            <p className="_bold end">
                                {
                                    formatCurrencyStyled(totalNota)
                                }
                            </p>
                        </div>
                        <div className="row two gap-compressed">
                            <p className="_info"> -- </p>
                            <p className="_info end"> {event.NotaCreditoCFDI.serie} - {event.NotaCreditoCFDI.folio} </p>
                        </div>
                    </div>
                )
            }
            return ''
        }
        return (
            <div className={`_subRelationship ${uuidSelected === notaCredito.uuid ? '_selected' : ''}`}>
                {
                    event.wasCanceled ?
                        <span className="material-icons _cancelled">
                            close
                        </span>
                        :
                        <span className="material-icons _checked">
                            done
                        </span>
                }
                <div className="row two gap-compressed">
                    <p className="_bold">
                        Nota de crédito
                    </p>
                    <p className="_bold end">
                        {
                            !notaCredito ? ''
                                : formatCurrencyStyled(parseFloat(notaCredito.json['@'].Total))
                        }
                    </p>
                </div>
                <div className="row two gap-compressed">
                    <p className="_info"> {moment(new Date(notaCredito.json['@'].Fecha).getTime()).format('DD MMM YYYY')} </p>
                    <p className="_info end"> {!notaCredito ? '' : `${notaCredito.json['@'].Serie} - ${notaCredito.json['@'].Folio}`} </p>
                </div>
                {
                    notaCredito.uuid ?
                        <p className={`_info ${event.wasCanceled ? '_cancelled' : ''}`}> {notaCredito.uuid} </p>
                        : ' '
                }
            </div>
        )
    }
    const renderBill = () => {
        if (!bill) {
            if (!!event.CFDI) {
                // This is still a pending bill
                const { total: totalNota } = calculateTotal(event.CFDI.conceptos)
                return (
                    <div className={`_subRelationship _unstamped`}>
                        <div className="row two gap-compressed">
                            <p className="_bold">
                                Factura Liquidación
                            </p>
                            <p className="_bold end">
                                {
                                    formatCurrencyStyled(totalNota)
                                }
                            </p>
                        </div>
                        <div className="row two gap-compressed">
                            <p className="_info"> -- </p>
                            <p className="_info end"> {event.CFDI.serie} - {event.CFDI.folio} </p>
                        </div>
                    </div>
                )
            }
            return ''
        }
        return (
            <div className={`_subRelationship ${uuidSelected === bill.uuid ? '_selected' : ''}`}>
                <span className="material-icons _checked">
                    done
                </span>
                <div className="row two gap-compressed">
                    <p className="_bold">
                        Factura Liquidación
                    </p>
                    <p className="_bold end">
                        {
                            !bill ? ''
                                : formatCurrencyStyled(parseFloat(bill.json['@'].Total))
                        }
                    </p>
                </div>
                <div className="row two gap-compressed">
                    <p className="_info"> {moment(new Date(bill.json['@'].Fecha).getTime()).format('DD MMM YYYY')} </p>
                    <p className="_info end"> {!bill ? '' : `${bill.json['@'].Serie} - ${bill.json['@'].Folio}`} </p>
                </div>
                {
                    bill.uuid ?
                        <p className="_info"> {bill.uuid} </p>
                        : ' '
                }
            </div>
        )
    }
    return (
        <>
            {renderNotaCred()}
            {renderBill()}
        </>
    )
}

function MisFacturasRelationship({ billArgument, CFDI, authToken, requestClose }: RelationshipProps) {
    useEffect(() => {
        console.log('<MisFacturasRelationship> billArgument: ', billArgument);
        console.log('<MisFacturasRelationship> CFDI: ', CFDI);
        // CFDI, is CFDI clicked to check relationship
    }, [billArgument, CFDI])

    const labelByType = (multipayType: number) => {
        if (multipayType === 0) { return 'Factura normal' }
        if (multipayType === 1) { return 'Factura adelanto' }
        if (multipayType === 2) { return 'Factura del total' }
        if (multipayType === 3) { return 'Factura del total' }
        return 'Factura del total'
    }

    if (!billArgument || !CFDI) {
        return (
            <div id="MisFacturasRelationship" className="card table">
            </div>
        )
    }

    const { total } = calculateTotal(billArgument.MasterCFDI.conceptos)

    return (
        <div id="MisFacturasRelationship" className="card table">
            <div className="cardTitle withClose">
                <h4>Relación de facturas</h4>
                <i className="material-icons" onClick={requestClose}>
                    close
                </i>
            </div>
            <div className="cardBody">
                <div className="relationship">
                    <div className={`_masterRelationship ${billArgument.uuid === CFDI.uuid ? '_selected' : ''}`}>
                        <div className="row two gap-compressed">
                            <p className={`_bold ${billArgument.wasCanceled ? '_canceled' : ''}`}> {labelByType(billArgument.multipayType)} </p>
                            <p className={`_bold end ${billArgument.wasCanceled ? '_canceled' : ''}`}> {formatCurrencyStyled(total)} </p>
                        </div>
                        <div className="row two gap-compressed">
                            <p className="_info"> {moment(billArgument.createdAt).format('DD MMM YYYY')} </p>
                            <p className="_info end"> {billArgument.MasterCFDI.serie} - {billArgument.MasterCFDI.folio}</p>
                        </div>
                        <p className="_info"> {billArgument.MasterCFDI.receptor.nombre} </p>
                        <p className={`_info ${billArgument.wasCanceled ? '_canceled' : ''}`}> {billArgument.uuid} </p>
                    </div>
                    {
                        billArgument.billEvents.map(bE => (
                            <RelationshipBillEvent authToken={authToken} event={bE} uuidSelected={CFDI.uuid} key={bE._id} />
                        ))
                    }
                    {
                        billArgument.paymentEvents.map(pE => (
                            <RelationshipPaymentEvent authToken={authToken} event={pE} uuidSelected={CFDI.uuid} key={pE._id} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}


export default MisFacturasRelationship;