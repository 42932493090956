import React, { useState, useEffect } from 'react';
import { Bill, ToBillArguments, ToBillEvent } from '../../models/Factura';
import Select from '../Forms/Select';
import Input from '../Forms/Input';
import sharedToasterSubject from '../../services/shared.toasterSubject';


interface FacturarFormatoProps {
    cfdi: Bill;
    billArguments: ToBillArguments<ToBillEvent>;
    formaDePagoOptions: { label: string, value: string }[];
    invalidArguments: { key: string; text: string };
    isGlobalBill: boolean;
    // Emitters
    onCfdiChange: (property: 'formaPago' | 'metodoPago') => (value: string) => void;
    onBillArgumentsChange: (argument: ToBillArguments<ToBillEvent>) => void;
}

const PAYMENT_DEFERED_OPTIONS = [
    { value: 0, label: 'Ya se pagó / Hoy mismo' },
    { value: 7, label: 'Este mes, después de emitir la factura' },
    { value: 15, label: '15 días después de emitir la factura' },
    { value: 30, label: '30 días después de emitir la factura' },
    { value: 60, label: '60 días después de emitir la factura' },
    { value: 90, label: '90 días después de emitir la factura' },
    { value: -1, label: 'Otro, especificar dias' }
];

const MULTIPAY_OPTIONS = [
    { value: 0, label: 'Pago único' },
    { value: 1, label: 'Adelanto y una Liquidación' },
    { value: 2, label: 'Parcialidades' },
    { value: 3, label: 'Adelanto y Parcialidades' },
];

const FREQUENCY_OPTIONS = [
    { value: 'daily', label: 'Cada dia' },
    { value: 'weekly', label: 'Semanal' },
    { value: 'monthly', label: 'Mensual' },
    { value: 'bi_monthly', label: 'Bimestral' },
    { value: 'tri_monthly', label: 'Trimestral' },
    { value: 'tetra_monthly', label: 'Tetramestral' },
    { value: 'hexa_monthly', label: 'Semestral' },
    { value: 'yearly', label: 'Anual' },
]

function FacturarFormato({ cfdi, billArguments, formaDePagoOptions, invalidArguments, isGlobalBill, onCfdiChange, onBillArgumentsChange }: FacturarFormatoProps) {
    const [isCustomDefered, setIsCustomDefered] = useState(false);
    const [isMultipay, _setIsMultipay] = useState<0 | 1 | 2 | 3>(0);

    useEffect(() => {
        if (billArguments) {
            console.log('Bill Arguments changed: ', billArguments);
            // console.log('UE Bill args: ',isMultipay,  billArguments)
            if (billArguments.multipayType !== isMultipay) {
                _setIsMultipay(billArguments.multipayType)
            }
            const deferredCandidate = PAYMENT_DEFERED_OPTIONS.find(pd => pd.value === billArguments.timeDefered);
            if (!deferredCandidate && !isCustomDefered) {
                setIsCustomDefered(true);
            }
        }
    }, [billArguments, isMultipay, isCustomDefered])


    useEffect(() => {
        if (isGlobalBill) {
            // Update internal state if this happens
            _setIsMultipay(0)
            setIsCustomDefered(false)
        }
    }, [isGlobalBill])

    const handleCfdiChange = (property: 'formaPago' | 'metodoPago') => (value: any) => {
        onCfdiChange(property)(value);
    }

    const setIsMultipay = (value: 0 | 1 | 2 | 3, keepUpfront = false) => {
        _setIsMultipay(value);
        if (value === 0) {
            const newArguments: ToBillArguments<ToBillEvent> = Object.assign({}, billArguments, { upfront: 0, multiPayments: 1, multipayType: value })
            onBillArgumentsChange(newArguments);
            console.log(`<Back to PUE?> arguments`, newArguments)
            console.log(`<Back to PUE?> cfdi`, cfdi)
            if (newArguments.multipayType === 0 && newArguments.timeDefered <= 7) {
                // IF downgrading to Una sola exhibición, make sure PUE is set for defered <= 7
                if (cfdi.metodoPago === 'PPD') {
                    handleCfdiChange('metodoPago')('PUE');
                    handleCfdiChange('formaPago')('03');
                }
            }
        }
        else if (value === 1) {
            // If bill is PPD, then this option defaults to #3
            if (cfdi.metodoPago === 'PPD') {
                const newArguments: ToBillArguments<ToBillEvent> = Object.assign({}, billArguments, { multiPayments: 2, multipayType: 3 })
                onBillArgumentsChange(newArguments);
                sharedToasterSubject.next({ type: 'warning', message: 'Una factura PPD de adelanto y liquidación se vuelve adelanto y parcialidades', clearTs: 5000 });
            } else {
                const newArguments: ToBillArguments<ToBillEvent> = Object.assign({}, billArguments, { multiPayments: 1, multipayType: value })
                onBillArgumentsChange(newArguments);
            }
        }
        else if (value === 2) {
            // multiPayments 0 to force user to input manually
            const newArguments: ToBillArguments<ToBillEvent> = Object.assign({}, billArguments, { upfront: 0, multiPayments: 0, multipayType: value })
            onBillArgumentsChange(newArguments);
        }
        else if (value === 3) {
            // multiPayments 0 to force user to input manually
            const newArguments: ToBillArguments<ToBillEvent> = Object.assign({}, billArguments, { upfront: keepUpfront ? billArguments.upfront : 0, multiPayments: 0, multipayType: value })
            onBillArgumentsChange(newArguments);
        }
    }


    const handleBillArgumentChange = (property: 'timeDefered' | 'multiPayments' | 'upfront' | 'frequency') => (value: any) => {
        const appended: { [key: string]: any } = {};
        appended[property] = value;
        if (property === 'timeDefered') {
            if (value < 0) {
                appended[property] = 0;
            } else if (value > 365) {
                appended[property] = 365;
            }
        }
        if (property === 'multiPayments') {
            if (value < 1) {
                value = 1
            } else if (value > 240) {
                // Maximum realistic payment is something like Mortage at 20 years
                value = 240;
            }
            appended[property] = value;
        }
        if (property === 'upfront') {
            if (value < 0) {
                value = 0;
                appended[property] = value;
            }
        }
        const newArguments: ToBillArguments<ToBillEvent> = Object.assign({}, billArguments, appended)
        // PPD or PUE change
        // ------------------
        // Time defered changes to PPD
        if (newArguments.timeDefered > 7) {
            // TODO: Handle that pending date is not NEXT month
            handleCfdiChange('metodoPago')('PPD');
            handleCfdiChange('formaPago')('99');
            // if multipayType 
            console.log('ON TIME DEFERED: ', newArguments);
            if (newArguments.multipayType === 1) {
                // PPD Adelanto y liquidación become adelanto y parcialidades by default
                newArguments.multipayType = 3;
                newArguments.multiPayments = 2;
                sharedToasterSubject.next({ type: 'warning', message: 'Una factura PPD de adelanto y liquidación se vuelve adelanto y parcialidades', clearTs: 5000 });
            }
        }
        else if (newArguments.multipayType === 0 && newArguments.timeDefered <= 7) {
            // IF downgrading to Una sola exhibición, make sure PUE is set for defered <= 7
            if (cfdi.metodoPago === 'PPD') {
                handleCfdiChange('metodoPago')('PUE');
                handleCfdiChange('formaPago')('03');
            }
        }
        else if (newArguments.multipayType === 2 && newArguments.multiPayments > 0) {
            // Parcialidades, even with one will create PPD type
            handleCfdiChange('metodoPago')('PPD');
            handleCfdiChange('formaPago')('99');
        }
        else if (newArguments.multiPayments > 1) {
            handleCfdiChange('metodoPago')('PPD');
            handleCfdiChange('formaPago')('99');
        }
        else {
            handleCfdiChange('metodoPago')('PUE');
            if (cfdi.formaPago === '99') {
                handleCfdiChange('formaPago')('03');
            }
        }
        onBillArgumentsChange(newArguments);
    }

    const handleDeferedOptionsChange = (change: number) => {
        if (change < 0) {
            setIsCustomDefered(true);
        } else {
            setIsCustomDefered(false);
            handleBillArgumentChange('timeDefered')(change);
        }
    }

    return (
        <div>
            <h4>Formato de pago</h4>
            <div className="card table">
                <div className="cardBody">
                    <div className="row three lg-two sm-one">
                        <Select label={'¿Cuándo recibirás tu primer pago?'} value={isCustomDefered ? -1 : billArguments.timeDefered} options={PAYMENT_DEFERED_OPTIONS} onChange={handleDeferedOptionsChange} disabled={isGlobalBill} />
                        {
                            isCustomDefered ?
                                <Input label="¿En cuántos dias?" value={billArguments.timeDefered} onChange={handleBillArgumentChange('timeDefered')} type="number" placeholder="En cuanto tiempo recibes el (primer) pago?" max={365} min={0} />
                                : ''
                        }
                    </div>
                    <div className="row three lg-two sm-one">
                        <Select label={'Selecciona como recibirás tu pago'} value={cfdi.formaPago} options={formaDePagoOptions} onChange={handleCfdiChange('formaPago')} disabled={cfdi.metodoPago === 'PPD'} />
                    </div>
                    <div className="row three md-two sm-one">
                        <Select label={'¿Pago único o parcialidades?'} value={isMultipay} options={MULTIPAY_OPTIONS} onChange={setIsMultipay} disabled={isGlobalBill} />
                        {
                            isMultipay >= 2 ?
                                <Input type="number" min={1} max={240} value={billArguments.multiPayments} label={`Número de parcialidades ${isMultipay === 3 ? '(incluyendo el adelanto)' : ''}`} placeholder="" onChange={handleBillArgumentChange('multiPayments')} hasError={!billArguments.multiPayments || (isMultipay === 3 && billArguments.multiPayments < 2)} errorLabel={(isMultipay === 3 && billArguments.multiPayments < 2) ? 'Se requieren mínimo 2 parcialidades' : 'Especifica las parcialidades'} />
                                :
                                <Input type="text" disabled={true} value={'No aplica'} label={`Número de parcialidades`} placeholder="" />

                        }
                        {
                            isMultipay >= 2 ?
                                <Select label={'Frecuencia de pagos esperada'} value={billArguments.frequency} options={FREQUENCY_OPTIONS} onChange={handleBillArgumentChange('frequency')} />
                                :
                                <Select label={'Frecuencia de pagos esperada'} disabled={true} value={'Un solo pago'} options={[{ value: false, label: 'Un solo pago' }]} onChange={handleBillArgumentChange('frequency')} />
                        }
                    </div>
                    <div className="row three md-two sm-one">
                        {
                            isMultipay === 1 || isMultipay === 3 ?
                                <Input type="number" isCurrency={true} min={0} label="Monto del adelanto" value={billArguments.upfront} placeholder="" onChange={handleBillArgumentChange('upfront')} hasError={billArguments.upfront === 0 || (invalidArguments?.key === 'upfront')} errorLabel={'Adelanto debe ser mayor a $0 y menor al total'} />
                                :
                                <Input type="text" disabled={true} label="Monto del adelanto" placeholder="" value={'Sin adelanto'} />
                        }
                        <div className="notice">
                            {
                                isMultipay === 1 && billArguments.timeDefered > 0 ?
                                    <span className="notice">
                                        {`El adelanto se recibirá en ${billArguments.timeDefered} dia(s)`}
                                    </span>
                                    : (
                                        isMultipay === 3 && billArguments.timeDefered > 0 ?
                                            <span className="notice">
                                                {`El adelanto se recibirá en ${billArguments.timeDefered} dia(s) y se considera la primera parcialidad`}
                                            </span>
                                            : ''
                                    )
                            }
                        </div>
                        <Input label="La factura se emite en" type="text" value={cfdi.metodoPago === 'PUE' ? `(PUE) Pago en una sola exibición` : `(PPD) Pago parcialidades diferidas`} placeholder="" disabled={true} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FacturarFormato;