import { useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ProfileQL } from '../../../models/Profile';
import GraphQlClient from '../../../services/graphql';
import { graphqlSchema } from '../../../services/graphql.schema';
import sharedToasterSubject from '../../../services/shared.toasterSubject';
import Button from '../../Forms/Button';

import './CancelSubs.dialog.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px',
        border: 'none',
        background: 'transparent',
        perspective: '1000px',
        overflow: 'visible',
    }
};

interface CancelSubsDialogProps {
    isOpen: boolean;
    onClose: () => void;
    currentUser: ProfileQL;

}

export function CancelSubscription({ isOpen, onClose, }: CancelSubsDialogProps) {

    const [loading, setLoading] = useState(false);
    const history = useHistory()

    const [setPlanChange] = useMutation(graphqlSchema.PROFILE.SETUP.changePlanType, {
        onCompleted: ({ changePlanType }: { changePlanType: ProfileQL }) => {
            console.log(`<setPlanChange> profile: `, changePlanType);
            GraphQlClient.modifyClientMLprofile(Object.assign({}, JSON.parse(JSON.stringify(changePlanType.professionalProfile))))
            // Plan change is executed in dialog
            sharedToasterSubject.next({ type: 'confirm', message: `Cambio a plan LITE satisfactorio.`, clickToClose: true })
            history.push({
                pathname: '/dashboard', // Should next redirect to Administration Hub or Finance Hub
                search: ``,
            })
        },
        onError: (err) => {
            console.error(`<setPlanChange> Error: `, err);
            setLoading(false);
            sharedToasterSubject.next({ type: 'error', message: `Hubo un error al cambiar de plan, notifique a soporte.` })
        }
    })

    const onContinue = () => {
        setLoading(true);
        setPlanChange({
            variables: {
                plan: {
                    planType: 'lite'
                }
            }
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            contentLabel="Cancelar subscripción"
        >
            <div className='card table' id="CancelSubscription">
                <div className='cardTitle'>
                    <h4>
                        Cancelar FiscalPOP Profesional
                    </h4>
                </div>
                <div className='cardBody'>
                    <p>
                        <b>
                            Estas por cancelar tu subscripción a FiscalPOP Profesional.
                        </b>
                    </p>
                    <p>
                        Al continuar, todos tus datos financieros se eliminarán de nuestros servidores y regresarás a la versión <b className='main'>Lite</b>.
                    </p>
                    <p>
                        Puedes regresar a FiscalPOP Profesional en cualquier momento, pero no podrás recuperar los datos de tu cuenta anterior.
                    </p>
                </div>
                <div className='actions'>
                    <Button secondary={true} handleClick={onClose} disabled={loading}>
                        <span>
                            Regresar
                        </span>
                    </Button>
                    <Button primary={true} handleClick={onContinue} disabled={loading}>
                        {
                            loading ?
                                <span>
                                    Espere...
                                </span>
                                :
                                <span>
                                    Continuar
                                </span>
                        }
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

