import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { PaybookAccount } from '../../../../models/Administracion/Bank.Accounts';
import { BankProject } from '../../../../models/Administracion/Bank.Projects';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import { graphqlSchema } from '../../../../services/graphql.schema';
import ProjectionOverviewGraphs from './Projection.overview.graphs';
import ProjectionOverviewMenu, { ToggleMenuDict } from './Projection.overview.menu';

import './Projection.overview.scss'


function ProjectionOverview() {

    const [projectOptions, setProjectOptions] = useState<{ label: string, _id: string, type: 'expense' | 'business' }[]>([]);
    const [projects, setProjects] = useState<BankProject<'business'>[]>([])
    const [summaries, setSummaries] = useState<BankMovementSummary[]>([])


    const [summaryLoaded, setSummaryLoaded] = useState(false);

    const [menuToggleDict, setMenuToggleDict] = useState<ToggleMenuDict>({
        expense: {},
        income: {},
        expenseByProject: {},
        incomeByProject: {}
    })

    const [getProjects] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.getProjectionProjects, {
        onCompleted: ({ getProjectionProjects }: { getProjectionProjects: BankProject<'business'>[] }) => {
            console.log(`(Projection) <getProjectionProjects> `, getProjectionProjects);
            setProjects(getProjectionProjects)
            setProjectOptions(getProjectionProjects.map((p) => ({
                label: p.name,
                _id: p._id,
                type: p.type
            })))
        },
        fetchPolicy: 'cache-and-network'
    })

    const [getMovementsSummary] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankMovementsSummary, {
        onCompleted: ({ getBankMovementsSummary }: { getBankMovementsSummary: BankMovementSummary[] }) => {
            console.log(`(ProjectionOverview) <getBankMovementsSummary> `, getBankMovementsSummary);
            const _getBankMovementsSummary: BankMovementSummary[] = JSON.parse(JSON.stringify(getBankMovementsSummary))
            setSummaries(_getBankMovementsSummary)
            setSummaryLoaded(true);
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        getProjects()
        getMovementsSummary({
            variables: {
                start: moment().subtract(4, 'months').startOf('month').valueOf(),
                // groupBy: 'month'
                groupBy: 'day'
            }
        })
    }, [getMovementsSummary, getProjects])

    const onMenuToggle = (toggleDict: ToggleMenuDict) => {
        console.log('ToggleMenuDict: ', toggleDict)
        setMenuToggleDict(toggleDict)
    }

    return (
        <div id='ProjectosOverview'>
            <ProjectionOverviewMenu summaries={summaries} projectOptions={projectOptions} onMenuToggleChange={onMenuToggle} />
            <ProjectionOverviewGraphs summaryLoaded={summaryLoaded} summaries={summaries} projects={projects} summaryToggleDict={menuToggleDict} />
        </div>
    )
}

export default ProjectionOverview