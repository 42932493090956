import React from 'react';

interface SvgProps {
    width: number;
    height: number;
}

function LiteSvg({height, width }: SvgProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 45 45" fill="none">
            <path d="M33.729 34.229H34.229V33.729V11.271V10.771H33.729H11.271H10.771V11.271V33.729V34.229H11.271H33.729ZM44.5 44.5H0.5V0.5H44.5V44.5Z" fill="#3fa9f5" stroke="#3FA8F4">

            </path>
        </svg>
    )
}

export default LiteSvg;