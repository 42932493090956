import React from 'react';

import './tooltip.scss';


function ToolTip({ text, justify, className, children }: { text: string, justify?: 'left' | 'right', className?: string, children: JSX.Element }) {
    return (
        <div className={`_toolTip ${className ? className : ''}`}>
            <div className="_tooltipSpanner" style={justify === 'right' ? {right: `0px`} : {left: `0px`}}>
                {text}
            </div>
            {children}
        </div>
    )
}

export default ToolTip;