import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import { ProfileQL } from '../../../models/Profile';
import { StripeCard_SourceCard } from '../../../models/Stripe';
import GraphQlClient from '../../../services/graphql';
import { graphqlSchema } from '../../../services/graphql.schema';
import { getNormalizedCurrentUrl } from '../../../services/window.query';
import AccountCard from '../../Account/Account.paymentMethod';
import ActionCards from '../../Account/CardDialogs/Account.cards';
import Button from '../../Forms/Button';

import './ActivationDialog.scss';


interface ActivationDialogProps {
    currentUser: ProfileQL;
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: (paymentMethods: StripeCard_SourceCard[]) => void;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px',
        border: 'none',
        background: 'transparent',
        perspective: '1000px',
        overflow: 'visible',
    }
};

function ActivationDialog({ currentUser, isOpen, onCancel, onConfirm }: ActivationDialogProps) {

    const history = useHistory()

    const [toPayStep, setToPayStep] = useState(false)
    const [changingPlan, setChangingPlan] = useState(false);

    const [paymentMethods, setPaymentMethods] = useState<StripeCard_SourceCard[]>([]);
    const [selectedCard, setSelectedCard] = useState('');
    const [addCardOpen, setAddCardOpen] = useState(false);

    const [getStripeCards] = useLazyQuery(graphqlSchema.STRIPE.CARDS.getCards, {
        onCompleted: ({ getCards }: { getCards: StripeCard_SourceCard[] }) => {
            console.log('Payment methods gotten: ', getCards);
            setPaymentMethods(getCards)
        },
        fetchPolicy: 'network-only'
    });

    const [enforceCardsHaveDefault] = useLazyQuery(graphqlSchema.STRIPE.CARDS.getCardsEnforceDefault, {
        onCompleted: ({ getCardsEnforceDefault }: { getCardsEnforceDefault: StripeCard_SourceCard[] }) => {
            console.log('Payment methods gotten with enforce: ', getCardsEnforceDefault);
            setPaymentMethods(getCardsEnforceDefault)
        },
        fetchPolicy: 'network-only'
    });

    const [setPlanChange] = useMutation(graphqlSchema.PROFILE.SETUP.changePlanType, {
        onCompleted: ({ changePlanType }: { changePlanType: ProfileQL }) => {
            console.log(`<setPlanChange> profile: `, changePlanType);
            GraphQlClient.modifyClientMLprofile(Object.assign({}, JSON.parse(JSON.stringify(changePlanType.professionalProfile))))
            // Plan change is executed in dialog
            history.push({
                pathname: '/dashboard', // Should next redirect to Administration Hub or Finance Hub
                search: `?welcome=profesional`,
            })
        },
        onError: (err) => {
            console.error(`<setPlanChange> Error: `, err);
        }
    })

    useEffect(() => {
        getStripeCards()
    }, [getStripeCards])

    useEffect(() => {
        if (window.location.search) {

            // For url_redirect authorization cases
            const planType = new URLSearchParams(window.location.search).get(
                'planType'
            );
            const planStep = new URLSearchParams(window.location.search).get(
                'step'
            );
            if (planType && planStep) {
                setToPayStep(true)
            }
        }
    }, [])

    const goToPayment = () => {
        const url = getNormalizedCurrentUrl();
        const [base, search] = url.replace('planType=professional&step=2', '').split('?')
        const stepSearch = [(search || ''), `planType=professional&step=2`].filter(s => !!s).join('&')
        const stepUrl = [base, stepSearch].join('?')
        window.history.replaceState({ path: stepUrl }, "", stepUrl);
        setToPayStep(true);
    }

    const confirmPayment = () => {
        if (!paymentMethods.length) {
            return;
        }
        setChangingPlan(true);
        setPlanChange({
            variables: {
                plan: {
                    planType: 'professional'
                }
            }
        })
    }

    const eraseCard = (cardId: string) => {
        setPaymentMethods(paymentMethods.filter(pm => pm.id !== cardId));
    }

    const setDefaultCard = (cardId: string) => {
        const _paymentMethods: StripeCard_SourceCard[] = JSON.parse(JSON.stringify(paymentMethods));
        _paymentMethods.forEach(pM => {
            if (pM.id === cardId) {
                pM.default = true;
            } else {
                pM.default = false;
            }
        })
        setPaymentMethods(_paymentMethods);
    }

    const closeCardModal = (cardSetupIntentConfirmed: boolean) => {
        setAddCardOpen(false);
        console.log('Closing card modal: ', cardSetupIntentConfirmed);
        if (!!cardSetupIntentConfirmed) {
            // When a card is added, make sure there's at least one default card
            enforceCardsHaveDefault();
        }
    }

    const openCardModal = () => {
        setAddCardOpen(true);
    }

    const handleClose = () => {
        onCancel();
        setToPayStep(false)
        setChangingPlan(false);
        setSelectedCard('')
        setAddCardOpen(false)
    }

    const selectCard = (cardId: string) => {
        return () => {
            if (selectedCard === cardId) {
                setSelectedCard('');
            } else {
                setSelectedCard(cardId);
            }
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
            contentLabel="Activar Plan Professional"
        >
            <div id="ActivateDialog" className={`${toPayStep ? 'turn' : ''}`}>
                <div className='temsCard'>
                    <div className="title">
                        <h3>Iniciar prueba de Profesional</h3>
                    </div>
                    <div className='content'>
                        <div className='terms'>

                            <p>
                                Podrás conectar tus cuentas bancarias para planificar finanzas personales.
                            </p>
                            <p>
                                <b>Tus datos financieros no se compartirán con nadie, bajo ningún motivo.</b>
                            </p>
                            <p>Durante tu prueba de FiscalPOP Profesional podrás:</p>
                            <ul>
                                <li>
                                    <p>Conectar una sola cuenta bancaria</p>
                                </li>
                                <li>
                                    <p>Analizar y consultar tus movimientos bancarios</p>
                                </li>
                                <li>
                                    <p>Realizar presupuestos, metas y proyectos de gastos</p>
                                </li>
                                <li>
                                    <p>
                                        Terminar la prueba antes de expirar no generará cargos.
                                    </p>
                                </li>
                            </ul>
                            <p>
                                Debido a que <b>Profesional</b> tiene un costo mensual, es necesario contar con una tarjeta en tu cuenta.
                            </p>
                            <p>
                                <b>No se realizará cargo alguno hasta que expire la prueba gratuita.</b>
                            </p>
                        </div>
                        <div className='actions'>
                            <Button tertiary={true} handleClick={handleClose}>
                                <span>
                                    Mejor después
                                </span>
                            </Button>
                            <Button primary={true} handleClick={goToPayment}>
                                <span>
                                    ¡Entendido!
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='paywallCard' id="paywallCard">
                    <div className="title">
                        <h3>Elegir tarjeta</h3>
                    </div>
                    <div className='content'>
                        <div className='_text'>
                            <p>Agrega tu primera o elige una diferente antes de iniciar</p>
                            <p><b>No se realizará ningun cargo hasta terminar tu prueba</b></p>
                        </div>
                        <div className="cardBlock">
                            <div className='cardMethods'>
                                {
                                    !paymentMethods.length ?
                                        <div className='noCard'>
                                            <p>No tienes tarjetas cargadas, agrega una!</p>
                                        </div>
                                        :
                                        paymentMethods.map((method, id) => {
                                            return <AccountCard
                                                key={id}
                                                selectCard={selectCard}
                                                method={method}
                                                selectedCardId={selectedCard}
                                                onErrase={eraseCard}
                                                noDeleteOption={true}
                                                onSetDefaultCard={setDefaultCard}
                                                menuOverlayAnchor={'paywallCard'}
                                            />
                                        })
                                }
                            </div>
                            <div className='cardAddContain'>
                                <div className='card addCard' onClick={openCardModal}>
                                    <div className='addCardIcon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="22" viewBox="0 0 45 22" fill="none">
                                            <path d="M40.333 0H15.583C14.0642 0 12.833 1.23122 12.833 2.75V19.25C12.833 20.7688 14.0642 22 15.583 22H40.333C41.8518 22 43.083 20.7688 43.083 19.25V2.75C43.083 1.23122 41.8518 0 40.333 0Z" fill="#AC2E78" />
                                            <path d="M12.833 8.25H43.083" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.58331 6.41667C4.89971 6.41666 5.15622 6.67314 5.15625 6.98954L5.15655 10.4268L8.59375 10.4271C8.91015 10.4271 9.16668 10.6836 9.16667 11C9.16665 11.3164 8.91015 11.5729 8.59375 11.5729H5.15655L5.15625 15.0104C5.15622 15.3268 4.89971 15.5834 4.58331 15.5833C4.26691 15.5833 4.01042 15.3268 4.01042 15.0104V11.5729H0.572966C0.256563 11.5729 1.36599e-05 11.3164 5.45576e-10 11C-1.36589e-05 10.6836 0.256464 10.4271 0.572867 10.4271L4.01042 10.4268V6.98964C4.01042 6.67323 4.2669 6.41669 4.58331 6.41667Z" fill="#AC2E78" />
                                        </svg>
                                        <p>
                                            Agregar tarjeta
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='actions'>
                        <Button tertiary={true} handleClick={handleClose} disabled={changingPlan}>
                            <span>
                                Mejor después
                            </span>
                        </Button>
                        <Button primary={true} handleClick={confirmPayment} disabled={!paymentMethods.length || changingPlan}>
                            <span>
                                ¡Iniciar!
                            </span>
                        </Button>
                    </div>
                </div>
                <ActionCards
                    isOpen={addCardOpen}
                    professionalProfile={currentUser.professionalProfile}
                    onRequestClose={closeCardModal} />
            </div>
        </Modal>
    )
}

export default ActivationDialog;