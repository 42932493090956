import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import { graphqlSchema } from '../../services/graphql.schema';
import SectionTitleBar from '../components/titlebar';

import moment from 'moment';

import './MisFacturas.scss';
import { CancelResponse, CFDI, ToBillArguments, ToBillEvent } from '../../models/Factura';
import MisFacturasEntry from './MisFacturas.entry';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Modal from 'react-modal';
import MisFacturasRelationship from './Dialogs/MisFacturas.relationship';
import Button from '../Forms/Button';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import Pagination from '../Forms/Pagination';
import { clearGqlTypename } from '../../utils/formatting';
import MisFacturasFilters from './MisFacturas.filter';
import Loading from '../Animations/loadScreen';
import { MisFacturasCartaPorteShare } from './Dialogs/MisFacturas.CartaPorte.Share';


interface MisFacturasProps extends BaseReact {
    currentUser: ProfileQL;
}

interface ArgumentBillBound { cfdi: CFDI, argument: ToBillArguments<ToBillEvent> }

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '6px',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
        border: 'none',
        boxShadow: 'none',
    }
};

const ITEMS_PER_PAGE = 20;

const SEARCH_METHODS = [
    { label: 'Recientes primero', value: 'recent' },
    { label: 'Por Mes específico', value: 'monthly' },
]

function MisFacturas({ currentUser, history }: MisFacturasProps) {
    const [billArguments, setBillArguments] = useState<ArgumentBillBound[]>([]);
    const [filteredCFDIsIndexMap, setFilteredCFDIsIndexMap] = useState<Map<string, number>>(new Map());

    const [isLoading, setIsLoading] = useState(true);
    const [rfcSearched, setRfcSearched] = useState<string>(null)


    const [start, setStart] = useState(moment().startOf('month').toDate().getTime())
    const [end, setEnd] = useState(moment().endOf('month').toDate().getTime())

    // FILTER STATES ==============
    const [filterExecuteSubject] = useState(new Subject<{ argsBound: ArgumentBillBound[], uuidSearch: string; clientSearch: string; statusFilter: -1 | 0 | 1 }>());
    const [uuidSearch, setUuidSearch] = useState('');
    const [clientSearch, setClientSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState<-1 | 0 | 1>(-1);
    const [argumentTypeFilter, setArgumentTypeFilter] = useState<-1 | 0 | 1 | 2 | 3>(-1);
    const [specialTypeFilter, setSpecialTypeFilter] = useState<'cartaporte' | 'nomina' | null>(null);

    const [page, setPage] = useState(1);

    const [searchOption, setSearchOption] = useState<'recent' | 'monthly'>('recent')
    // ============================

    const [isOpen, setModalIsOpen] = useState(false);
    const [modalBillArguments, setModalBillArguments] = useState<ToBillArguments<ToBillEvent>>(null);
    const [modalCFDI, setModalCFDI] = useState<CFDI>(null);

    const [cancelModal, setCancelModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [isCancelling, setIsCancelling] = useState('');
    const [unsetTransform, setUnsetTransform] = useState<boolean>(false);
    const [intendedToCancel, setIntendedToCancel] = useState<{ argumentId: string, uuid: string }>(null);

    const [shareModal, setShareModal] = useState<string>('');

    // Cancel Status ==============
    const [isCancellable, setIsCancellable] = useState<'Cancelable sin aceptación' | 'Cancelable con aceptación' | 'No Cancelable' | ''>('');

    const [fetchBillArguments] = useLazyQuery(graphqlSchema.FISCALPOP.CFDI.getAllBillArgumentsByQuery, {
        onCompleted: ({ getAllBillArgumentsByQuery }: { getAllBillArgumentsByQuery: ArgumentBillBound[] }) => {
            setBillArguments(getAllBillArgumentsByQuery);
            setIsLoading(false);
            console.log('Get Bill Arguments: ', getAllBillArgumentsByQuery);
        },
        fetchPolicy: 'cache-and-network'
    })

    const [cancelBill] = useMutation(graphqlSchema.FISCALPOP.CFDI.setBillToCancel, {
        onCompleted: ({ setBillToCancel }: { setBillToCancel: CancelResponse }) => {
            const _billArguments: ArgumentBillBound[] = JSON.parse(JSON.stringify(billArguments));
            console.log('Cancel Bill Response: ', setBillToCancel)
            console.log('Cancel Bill _billArguments:', _billArguments)
            setBillToCancel.folios.forEach(f => {
                const _argument = _billArguments.find(cf => cf.cfdi.uuid === f.uuid);
                _argument.cfdi.status = false;
                // Update the argument, BillEvent or PaymentEvent by UUID
                if (_argument.argument.uuid === f.uuid) {
                    _argument.argument.wasCanceled = true
                } else if (_argument.argument.billEvents.some(bE => bE.uuid === f.uuid)) {
                    _argument.argument.billEvents.find(bE => bE.uuid === f.uuid).wasCanceled = true;
                } else if (_argument.argument.billEvents.some(bE => bE.notaUUID === f.uuid)) {
                    _argument.argument.billEvents.find(bE => bE.notaUUID === f.uuid).wasCanceled = true;
                }
                else if (_argument.argument.paymentEvents.some(pE => pE.uuid === f.uuid)) {
                    _argument.argument.paymentEvents.find(pE => pE.uuid === f.uuid).wasCanceled = true;
                } else {
                    // Default to cosmetically canceling the argument
                    _argument.argument.wasCanceled = true;
                }
            })
            setIsCancelling('')
            setBillArguments(_billArguments);
            sharedToasterSubject.next({ type: 'confirm', message: `Se canceló la factura` })
        },
        onError: (e) => {
            console.log('Error canceling bill: ', e);
            sharedToasterSubject.next({ type: 'error', message: `Error: ${e.graphQLErrors[0].message || 'no se pudo cancelar la factura'}` })
            setIsCancelling('')
        }
    })

    useEffect(() => {
        if (searchOption === 'monthly') {
            setIsLoading(true);
            fetchBillArguments({
                variables: {
                    start,
                    end
                }
            })
        }
    }, [searchOption, start, end, currentUser, fetchBillArguments])

    useEffect(() => {
        if (searchOption === 'recent') {
            setIsLoading(true);
            fetchBillArguments({
                variables: {
                    rfc: rfcSearched
                }
            })
        }
    }, [searchOption, rfcSearched, fetchBillArguments])

    useEffect(() => {
        if (!!intendedToCancel) {
            setIsCancellable('');
            fetch(`https://api.fiscalpop.com/api/v1/cfdi/status/${currentUser.professionalProfile.authToken}/${intendedToCancel.uuid}`)
                .then(async response => {
                    if (response.ok) {
                        const json = await response.json();
                        return json;
                    } else {
                        const err = await response.text();
                        throw err;
                    }
                })
                .then((statusCFDI: { toCancelProcess: 'Cancelable sin aceptación' | 'Cancelable con aceptación' | 'No Cancelable', estado: boolean, cancelStatus: string }) => {
                    console.log('Cancellable status for CFDI: ', statusCFDI);
                    setIsCancellable(statusCFDI.toCancelProcess);
                })
                .catch((e) => {
                    console.error('ERROR GETTING CFDI STATUS: ', e)
                    setIsCancellable('Cancelable con aceptación')
                })
        }
    }, [intendedToCancel, currentUser])

    useEffect(() => {
        const _clientSearch = rfcSearched ? rfcSearched.toLowerCase() : clientSearch.toLowerCase()
        filterExecuteSubject.next({ uuidSearch: uuidSearch.toLowerCase(), clientSearch: _clientSearch, statusFilter, argsBound: billArguments })

    }, [uuidSearch, clientSearch, rfcSearched, statusFilter, billArguments, filterExecuteSubject]);

    useEffect(() => {
        filterExecuteSubject.pipe(debounceTime(200))
            .subscribe(({ uuidSearch, clientSearch, statusFilter, argsBound }) => {
                // console.log(`<Subs> Args Bound  (${uuidSearch}, ${clientSearch}, ${statusFilter}): `, argsBound)
                if (!uuidSearch && !clientSearch && statusFilter === -1) {

                    const indexMap = new Map();
                    argsBound.map(c => c.cfdi.uuid).forEach((e, i) => indexMap.set(e, i))
                    setFilteredCFDIsIndexMap(indexMap) // CLEAR resets Map Index
                }
                const uuidForSet = argsBound.filter(({ cfdi: cf, argument }) => {
                    if (!uuidSearch) {
                        return true;
                    }
                    return cf.uuid.toLowerCase().includes(uuidSearch)
                }).filter(({ cfdi: cf }) => {
                    if (!clientSearch) {
                        return true;
                    }
                    return cf.rfc.toLowerCase().includes(clientSearch) || cf.nombre.toLowerCase().includes(clientSearch)
                }).filter(({ cfdi: cf }) => {
                    if (statusFilter === -1) {
                        return true;
                    }
                    else if (statusFilter === 0) {
                        return !cf.status
                    }
                    else {
                        return cf.status
                    }
                }).map(c => c.cfdi.uuid)
                // console.log('UUIDS for Set: ', uuidForSet)
                const indexMap = new Map();
                uuidForSet.forEach((e, i) => {
                    indexMap.set(e, i)
                })
                setFilteredCFDIsIndexMap(indexMap);
                // console.log('<Subs> UUID MAP: ', [...indexMap])
            })

        return () => {
            filterExecuteSubject.unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onAskToCancel = (argumentId: string, uuid: string) => {
        return () => {
            setIntendedToCancel({ argumentId, uuid })
            setCancelModal(true);
        }
    }

    const onAskToEdit = (argumentId: string, uuid: string) => {
        return () => {
            setIntendedToCancel({ argumentId, uuid })
            setEditModal(true);
        }
    }

    const onAskRelationship = (billArgument: ToBillArguments<ToBillEvent>, CFDI: CFDI) => {
        // MUTED UNTIL NOVEMBER 2020,
        // >> Remove comment once relationships need to be completed
        return () => {
            setModalBillArguments(billArgument);
            setModalCFDI(CFDI)
            setModalIsOpen(true);
        }
    }

    const onRequestClose = () => {
        setModalIsOpen(false);
    }

    const onCancelClose = () => {
        setIntendedToCancel(null);
        setCancelModal(false);
    }

    const onEditClose = () => {
        setIntendedToCancel(null);
        setEditModal(false);
    }

    const onShareClose = () => {
        setShareModal('')
    }

    const onShareOpen = (url: string) => () => {
        setShareModal(url)
    }

    const onConfirmToCancel = () => {
        if (!intendedToCancel || !!isCancelling) {
            return;
        }
        setIsCancelling(intendedToCancel.uuid);
        sharedToasterSubject.next({ type: 'warning', message: 'Cancelando factura...' });
        console.log(`To Cancel args: `, {
            variables: {
                uuid: intendedToCancel.uuid,
                billArgumentId: intendedToCancel.argumentId
            }
        })
        cancelBill({
            variables: {
                uuid: intendedToCancel.uuid,
                billArgumentId: intendedToCancel.argumentId
            }
        })
        onCancelClose()
    }

    const onConfirmToEdit = () => {
        if (!intendedToCancel) {
            return;
        }
        const { argument }: ArgumentBillBound = clearGqlTypename(billArguments.find(bA => bA.argument?._id === intendedToCancel.argumentId));
        console.log('Argument: ', argument);
        // Go to facturas using billArgument as state, 
        // >> 1. Check if this bill to cancel is either MasterCFDI, PayEvent or NotaCredito
        const billEventUUIDs = argument.billEvents.map(bE => bE.uuid);
        const billEventNotaUUIDs = argument.billEvents.map(bE => bE.notaUUID);
        const payEventUUIDs = argument.paymentEvents.map(bE => bE.uuid);
        if (argument.uuid && argument.uuid === intendedToCancel.uuid) {
            history.push({
                pathname: `/facturacion/facturar/${argument._id}`,
                state: {
                    billArgument: argument,
                    billEvents: argument.billEvents,
                    paymentEvents: argument.paymentEvents
                }
            })
        }
        else if (billEventUUIDs.length && billEventUUIDs.includes(intendedToCancel.uuid)) {
            // This is the base CFDI for billEvent, it's a CFDI type "I"
            const eventToEdit = argument.billEvents.find(bE => bE.uuid === intendedToCancel.uuid);
            const editBaseArgument: ToBillArguments<ToBillEvent> = {
                multipayType: 0,
                timeDefered: 0,
                multiPayments: 1,
                upfront: 0,
                frequency: 'monthly',
                isClosed: false,
                createdAt: Date.now(),
                billEvents: [],
                paymentEvents: [],
                MasterCFDI: eventToEdit.CFDI
            }
            history.push({
                pathname: `/facturacion/facturar/${eventToEdit.argumentId}/${eventToEdit._id}`,
                state: {
                    billArgument: editBaseArgument,
                    billEvents: [],
                    paymentEvents: []
                }
            })
        }
        else if (billEventNotaUUIDs.length && billEventNotaUUIDs.includes(intendedToCancel.uuid)) {
            history.push({
                pathname: `/facturacion/notacredito/${argument.billEvents.find(bE => bE.notaUUID === intendedToCancel.uuid)._id}`,
                state: {
                    billArgument: argument,
                    billEvents: argument.billEvents,
                    paymentEvents: argument.paymentEvents
                }
            })
        }
        else if (payEventUUIDs.length && payEventUUIDs.includes(intendedToCancel.uuid)) {
            console.log('TO Payments edit: ', {
                billArgument: argument,
                billEvents: argument.billEvents,
                paymentEvents: argument.paymentEvents
            });
            history.push({
                pathname: `/facturacion/setup/pagos/${argument.paymentEvents.find(pE => pE.uuid === intendedToCancel.uuid)._id}`,
                state: {
                    billArgument: argument,
                    billEvents: argument.billEvents,
                    paymentEvents: argument.paymentEvents
                }
            })
        }
    }

    const handleFiltersChange = (filterArgs: {
        uuidSearch: string;
        clientSearch: string;
        statusFilter: -1 | 0 | 1;
        argumentTypeFilter: -1 | 0 | 1 | 2 | 3;
        specialType: 'cartaporte' | 'nomina' | null;
    }) => {
        setUuidSearch(filterArgs.uuidSearch);
        setClientSearch(filterArgs.clientSearch);
        setStatusFilter(filterArgs.statusFilter);
        setArgumentTypeFilter(filterArgs.argumentTypeFilter);
        setSpecialTypeFilter(filterArgs.specialType);
    }

    const handleMonthChange = (monthString: string) => {
        console.log('monthString: ', monthString);
        const [year, month] = monthString.split('-').map(s => parseInt(s));
        const _start = new Date(year, month - 1, 1).getTime();
        setStart(_start);
        setEnd(moment(_start).endOf('month').toDate().getTime());
        setPage(1);
    }

    const populateFilteredPaginatedCFDIs = (billArguments: ArgumentBillBound[]) => {
        // >> Prototype function to try to avoid unpopulated empty list due to filtering outcomes
        // >> When filtering is applied, CFDI entries are "hidden", but not removed from the list
        // >> This causes the list to display "hidden" entries, instead of displaying the filtered entries
        // Fallback is to call .map() on billArguments directly


        const filteredArguments = billArguments.filter(({ cfdi, argument }) => {
            if (!argument) {
                return false;
            }
            const isHidden = !filteredCFDIsIndexMap.has(cfdi.uuid);
            const isArgumentHidden = argumentTypeFilter !== -1 && argumentTypeFilter !== argument.multipayType;
            const isSpecialTypeHidden = specialTypeFilter !== null && specialTypeFilter !== argument.specialType;
            return !isHidden && !isArgumentHidden && !isSpecialTypeHidden;
        })

        return filteredArguments;
    }

    const _billArguments = populateFilteredPaginatedCFDIs(billArguments);
    // const pages = Math.ceil(billArguments.filter(({ cfdi: CFDI }) => filteredCFDIsIndexMap.has(CFDI.uuid)).length / ITEMS_PER_PAGE);
    const pages = Math.ceil(_billArguments.length / ITEMS_PER_PAGE);
    const _page = (page <= pages ? page : pages) || 1; // Page 1 is the minimum base, avoids having page 0 when no results exist

    const titleSuffix = searchOption === 'monthly' ? `durante ${moment(start).format('MMMM YYYY')}` : (!!rfcSearched ? `a ${rfcSearched}` : 'recientemente');
    const startDateValue = new Date(start).toJSON().split('T')[0].split('-').filter((s, i) => i < 2).join('-');

    return (
        <div id="MisFacturas">
            <SectionTitleBar currentUser={currentUser} title="Mis Facturas emitidas" />
            <div className="misFacturasContent">
                <div className="filters">
                    <div className='row two'>
                        <Select label='Buscar por' options={SEARCH_METHODS} value={searchOption} onChange={setSearchOption} />
                        <Input type="month" label="Dentro del Mes" value={`${startDateValue}`} placeholder="Selecciona el mes" onChange={handleMonthChange} disabled={searchOption === 'recent'} />
                    </div>
                    <Loading display={isLoading} relativePos={true} />
                </div>
                <MisFacturasFilters
                    isLoading={isLoading}
                    onReceptorSelected={setRfcSearched}
                    onFiltersChange={handleFiltersChange}
                />
                <div className="card table">
                    <div className="cardTitle">
                        <h4>Facturas emitidas {titleSuffix}</h4>
                    </div>
                    <div className="cardBody" id="MisFacturasBody">
                        <div className={`argumentsWrapper  ${unsetTransform ? 'noTransform' : ''}`}>
                            {
                                // Do not use a filtered entry for this or simple PAGINATION
                                // >> It will break animation transition 
                                //_billArguments.map((bind, i) => {
                                _billArguments
                                    .filter((_, i) => ((i >= (ITEMS_PER_PAGE * (_page - 1)) && (i < (ITEMS_PER_PAGE * _page)))))
                                    .map((bind, i) => {
                                        return (
                                            <MisFacturasEntry
                                                isCancelling={isCancelling}
                                                setUnsetTransform={setUnsetTransform}
                                                onAskToCancel={onAskToCancel}
                                                onAskRelationship={onAskRelationship}
                                                onAskToEdit={onAskToEdit}
                                                onShareOpen={onShareOpen}
                                                // Filtering is now controlled by filter function, not needed to hide entries
                                                isHidden={false}
                                                authToken={currentUser.fiscalpopProfile.authToken}
                                                billArgument={bind.argument}
                                                CFDI={bind.cfdi}
                                                history={history}
                                                key={bind.cfdi.uuid} />
                                        )
                                    })
                            }
                        </div>
                        <Pagination page={_page} pages={pages} onPage={setPage} />
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Relación entre facturas"
            >
                <MisFacturasRelationship requestClose={onRequestClose} authToken={currentUser.fiscalpopProfile.authToken} billArgument={modalBillArguments} CFDI={modalCFDI} />
            </Modal>
            <Modal
                isOpen={cancelModal}
                onRequestClose={onCancelClose}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Confirmar cancelar facturas"
            >
                <div className="card table" id="cancelModal">
                    <div className="cardTitle _red">
                        <h4>Confirma cancelar factura</h4>
                    </div>
                    <div className="cardBody">
                        <p>¿Estas seguro(a) que quieres cancelar la factura <br /> <b>{!!intendedToCancel && intendedToCancel.uuid ? intendedToCancel.uuid : ''}</b>?</p>
                        <p>También puedes sustituirla si requieres corregirla en lugar de cancelarla</p>
                        <div className="actions">
                            <Button secondary={true} handleClick={onCancelClose}>
                                <span>Mejor no</span>
                            </Button>
                            <Button primary={true} handleClick={onConfirmToCancel} disabled={!isCancellable || isCancellable === 'No Cancelable'}>
                                {
                                    !isCancellable ?
                                        <span>Revisando status de CFDI</span>
                                        :
                                        (isCancellable === 'No Cancelable' ?
                                            <span>CFDI No Cancelable</span>
                                            :
                                            <span>Si, Cancelar factura</span>
                                        )
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={editModal}
                onRequestClose={onEditClose}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Confirmar editar facturas"
            >
                <div className="card table" id="editModal">
                    <div className="cardTitle _yellow">
                        <h4>Confirma editar factura</h4>
                    </div>
                    <div className="cardBody">
                        <p>¿Estas seguro(a) que quieres editar la factura <br /> <b>{!!intendedToCancel && intendedToCancel.uuid ? intendedToCancel.uuid : ''}</b>?</p>
                        <p>Solamente puede editarse si no hay alguna otra factura relacionada con esta, como complemento de pago o notas de crédito.</p>
                        <div className="actions">
                            <Button secondary={true} handleClick={onEditClose}>
                                <span>Mejor no</span>
                            </Button>
                            <Button primary={true} handleClick={onConfirmToEdit} disabled={!isCancellable || isCancellable === 'No Cancelable'}>
                                {
                                    !isCancellable ?
                                        <span>Revisando status de CFDI</span>
                                        :
                                        (isCancellable === 'No Cancelable' ?
                                            <span>CFDI No Editable</span>
                                            :
                                            <span>Si, Editar factura</span>
                                        )
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <MisFacturasCartaPorteShare
                isOpen={!!shareModal}
                shareUrl={shareModal}
                companyName={currentUser.professionalProfile.companyName || currentUser.fiscalpopProfile.nombre}
                logoUrl={currentUser.fiscalpopProfile.logoUrl || ''}
                closeModal={onShareClose}
            />
        </div>
    )
}


export default MisFacturas;