import React from 'react';
import { NavLink } from 'react-router-dom';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import BarPercentPill from '../../../Graphs/BarPercent/BarPercent.pill';


function ExpenseByCategory({
    globalPeriod
}: { globalPeriod: BankMovementSummary }) {

    const renderEmptyStateNoMovements = () => {
        return (
            <div className='_isEmpty'>
                <h4>No hay movimientos este mes</h4>
                <h5>Manten tu cuentas de banco sincronizadas</h5>
            </div>
        )
    }

    const renderEmptyStateNoBudget = () => {
        return (
            <div className='_isEmpty'>
                <h4>¡Falta categorizar movimientos!</h4>
                <h5>Haz <NavLink to="/administration/movimientos">click aquí</NavLink> para categorizarlos</h5>
            </div>
        )
    }

    const noGlobalSummary = !globalPeriod;
    const noGlobalExpense = !globalPeriod?.expense
    const noGlobalCategory = !!globalPeriod && !globalPeriod?.expenseByCategory?.length

    return (
        <div>
            <h4>Gastos por categoría del mes</h4>
            <div className='card noShadow table'>
                <div className='bankcategoryExpense header'>
                    <p>Subcategoría</p>
                    <p>% de gasto total</p>
                    <p>% de presupuesto</p>
                </div>
                <div className='expenseScrollable'>
                    {
                        noGlobalSummary || noGlobalExpense || noGlobalCategory ?
                            (noGlobalSummary || noGlobalExpense ?
                                renderEmptyStateNoMovements()
                                : renderEmptyStateNoBudget()
                            )
                            :
                            globalPeriod.expenseByCategory.filter((cat, i) => !!cat.totalExpense).filter((cat, i) => i < 9).map((cat, index) => {
                                const totalPercent = Math.abs(cat.totalExpense / globalPeriod.expense);
                                const percent = !cat.totalBudgetted ? 0 : Math.abs(cat.totalExpense / cat.totalBudgetted);
                                const color = percent > 1 ? '#EA2B2B' :
                                    (percent > 0.9 ? '#FF8800' : '#60CB5E')
                                return (
                                    <div className='bankcategoryExpense' key={`${cat.budgetCategory}-${cat.budgetSubcategory}`}>
                                        <p>{cat.budgetSubcategory.split('-')[1]}</p>
                                        <BarPercentPill
                                            color='#7D7F8F'
                                            decimalPercent={totalPercent}
                                        />
                                        <BarPercentPill
                                            color={color}
                                            decimalPercent={percent}
                                        />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
        </div>
    )
}

export default ExpenseByCategory;