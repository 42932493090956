import React from 'react';
import { NavLink } from 'react-router-dom';

import './Setup.menu.scss';

function SetupMenu() {
    return (
        <div className='setupNavigation'>
            <NavLink to="/setup/emisor" activeClassName="active">
                Mi Emisor
                <span className="_border" />
            </NavLink>
            <NavLink to="/setup/pagos" activeClassName="active">
                Planes & Pagos
                <span className="_border" />
            </NavLink>
            <NavLink to="/setup/cobros" activeClassName="active">
                Cotizaciones y cobros
                <span className="_border" />
            </NavLink>
            <NavLink to="/support" activeClassName="active">
                Soporte
                <span className="_border" />
            </NavLink>
        </div>
    )
}

export default SetupMenu;