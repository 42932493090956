import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import './input.search.scss';

interface InputSearch {
    onChange: (v: string) => void;
    placeholder?: string;
    debounceTs?: number
}

function InputSearch({ onChange, placeholder, debounceTs = 300 }: InputSearch) {
    const [searchByName] = useState(new Subject<string>());

    useEffect(() => {
        const subs = searchByName.pipe(debounceTime(debounceTs)).subscribe(name => {
            console.log('Name Searched', name);
            onChange(name);
        })
        return () => {
            subs.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchByName])

    const searchFor = (e: React.ChangeEvent<HTMLInputElement>) => {
        const textValue = e.target.value;
        searchByName.next(textValue);
        // setSearch(textValue);
    }

    return (
        <div className="searchFor">
            <span className="material-icons">
                search
            </span>
            <input onChange={searchFor} type="text" placeholder={placeholder} />
        </div>
    )
}

export default InputSearch;