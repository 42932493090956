import React, { useReducer, useState, useEffect } from 'react';
import { BaseReact } from '../../base.model';
import { FiscalpopProfile, ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';

import './Facturar.scss';
import Select from '../Forms/Select';
import { Bill, ToBillArguments, BillConceptos, ToBillEvent } from '../../models/Factura';
import Input from '../Forms/Input';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import FacturarFormato from './Facturar.formato';
import FacturarConceptos from './Factura.conceptos';
import FacturaTotales from './Factura.totales';
import Button from '../Forms/Button';
import { calculateTotal } from '../../utils/calculate.conceptos';
import { upfrontBillFactory, paymentRequestFactory } from '../../services/calculations.cfdi';
import { useHistory, useParams } from 'react-router-dom';
import { validateCfdiIsComplete } from '../../services/validation.cfdi';
import { Receptor, SerieTracker } from '../../models/Catalogos';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import AutoComplete from '../Forms/Autocomplete';
import { clearGqlTypename, mesesFormat } from '../../utils/formatting';
import { validateBillArgumentos } from '../../services/validation.arguments';
import { REGIMEN_OPTIONS } from '../../utils/regimen.options';
import moment from '../../services/unifiedMoment';

interface DashboardProps extends BaseReact {
    currentUser: ProfileQL;
}

/**
 * Used for CFDI recovery that might get polluted by "null"
 */
function cfdiCleaner(cfdiRecovered: Bill, fpProfile: FiscalpopProfile) {
    if (!cfdiRecovered.lugarExpedicion) {
        cfdiRecovered.lugarExpedicion = fpProfile.lugarExpedicion;
    }
    if (!cfdiRecovered.fecha) {
        delete cfdiRecovered.fecha;
    }
    if (!cfdiRecovered.tipoDeComprobante) {
        delete cfdiRecovered.tipoDeComprobante;
    }
    return cfdiRecovered;
}

const REGIMEN_OPT = REGIMEN_OPTIONS.map((o) => ({ value: o.value, label: o.secondLabel }))

const GLOBAL_PERIODO_OPT = [
    { value: '01', label: 'Diario' },
    { value: '02', label: 'Semanal' },
    { value: '03', label: 'Quincenal' },
    { value: '04', label: 'Mensual' },
]

const GLOBAL_MES = new Array(12).fill(1).map((_, i) => {
    moment().startOf('year').add(i, 'month').format('MMMM')
    const e = {
        value: mesesFormat(`${i + 1}`),
        label: moment().startOf('year').add(i, 'month').format('MMMM')
    }
    return e;
})

function billReducer(state: Bill, { property, value }: { property: 'receptor' | 'conceptos' | 'cfdiRelacionados' | 'comprobante' | 'global' | '_replace', value: any }): Bill {
    if (property === 'receptor') {
        return Object.assign({}, state, { receptor: value });
    } else if (property === 'conceptos') {
        return Object.assign({}, state, { conceptos: value });
    }
    else if (property === 'comprobante') {
        return Object.assign({}, state, value);
    }
    else if (property === 'cfdiRelacionados') {
        return Object.assign({}, state, { cfdiRelacionados: value });
    }
    else if (property === 'global') {
        return Object.assign({}, state, { global: value, metodoPago: 'PUE', formaPago: '03' });
    }
    else if (property === '_replace') {
        return Object.assign({}, state, value);
    }
    else {
        // Force update only
        return Object.assign({}, state);
    }
}

function Facturar({ currentUser, location }: DashboardProps) {
    const history = useHistory();
    const { argumentid, eventid } = useParams<{ argumentid?: string, eventid?: string }>();

    const [tracekrOptions, setTrackerOptions] = useState<SerieTracker[]>([]);
    const [receptorOptions, setReceptorOptions] = useState<Receptor[]>([]);
    const [receptoSubject] = useState(new Subject<string>());
    const [receptoSubjectByRfc] = useState(new Subject<string>());

    const [validRequested, setValidRequested] = useState(false);
    const [validConceptos, setValidConceptos] = useState(false);
    const [invalidArguments, setInvalidArguments] = useState<{ key: string; text: string }>(null);
    const [usoCfdiError, setUsoCfdiError] = useState('');


    const [cfdi, dispatchBill] = useReducer(billReducer, {
        formaPago: '03',
        metodoPago: 'PUE',
        serie: 'A',
        folio: '1',
        receptor: { nombre: '', rfc: '', usoCFDI: 'G03', email: '', regimen: '612', zip: '' },
        conceptos: [{
            claveProdServ: '',
            claveUnidad: '',
            cantidad: 1,
            descripcion: '',
            valorUnitario: 0,
            descuento: 0,
            pedimento: [],
            noIdentificacion: '',
            impuestos: [{ type: 'iva', tipo: 'Fijo', retencion: false, tasa: 0.16 }]
        }],
    } as Bill);
    const [billArgumento, _setBillArgumento] = useState<ToBillArguments<ToBillEvent>>({
        timeDefered: 0,
        multipayType: 0,
        multiPayments: 1,
        upfront: 0,
        frequency: 'monthly',
        isClosed: false,
        createdAt: Date.now(),
        billEvents: [],
        paymentEvents: []
    })

    const [usosCFDI, setUsosCFDI] = useState<{ value: string, label: string, forRegimen: string[] }[]>([]);
    const [formasPago, setFormasPago] = useState<{ value: string, label: string }[]>([]);

    useEffect(() => {
        const state = location.state as { billArgument: ToBillArguments<ToBillEvent>; billEvents: ToBillEvent[]; paymentEvents: ToBillEvent[] };
        console.log('FACTURA Recover State: ', state);
        if (state && state.billArgument) {
            if (!!state.billEvents && state.billArgument.multipayType === 1) {
                // BillArgument CFDI is original MasterCFDI
                dispatchBill({ property: '_replace', value: state.billEvents[0].CFDI })
            } else {
                // Some cases, lugar de Expedición might get lost when re-editing bills
                dispatchBill({ property: '_replace', value: cfdiCleaner(state.billArgument.MasterCFDI, currentUser.fiscalpopProfile) })
            }
            setBillArgumento({
                timeDefered: state.billArgument.timeDefered,
                multipayType: state.billArgument.multipayType,
                multiPayments: state.billArgument.multiPayments,
                upfront: state.billArgument.upfront,
                frequency: state.billArgument.frequency,
                isClosed: state.billArgument.isClosed,
                createdAt: state.billArgument.createdAt,
                billEvents: state.billArgument.billEvents,
                paymentEvents: state.billArgument.paymentEvents,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])



    // GRAPHQL ---------
    useQuery(graphqlSchema.PROFILE.CATALOGOS.getAllTrackers, {
        onCompleted: ({ getAllTrackers }: { getAllTrackers: SerieTracker[] }) => {
            setTrackerOptions(getAllTrackers.filter(t => !t.receptorRfc));
            const trackerUsing = getAllTrackers.find(t => t.serie === cfdi.serie);
            if (trackerUsing && trackerUsing.folio) {
                dispatchBill({ property: 'comprobante', value: { folio: `${trackerUsing.folio}` } })
            }
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        },
        fetchPolicy: 'cache-and-network'
    })
    const [getByName] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByName, {
        onCompleted: ({ getClienteByName }: { getClienteByName: Receptor[] }) => {
            setReceptorOptions(getClienteByName.map(c => clearGqlTypename(c)));
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })
    const [getByRfc] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByRfc, {
        onCompleted: ({ getClienteByRfc }: { getClienteByRfc: Receptor[] }) => {
            setReceptorOptions(getClienteByRfc.map(c => clearGqlTypename(c)));
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })


    useEffect(() => {
        const s = receptoSubject.pipe(debounceTime(200)).subscribe(s => {
            getByName({
                variables: {
                    name: s
                }
            })
        })
        const s2 = receptoSubjectByRfc.pipe(debounceTime(200)).subscribe(s => {
            getByRfc({
                variables: {
                    rfc: s
                }
            })
        })
        getByName({
            variables: {
                name: ' '
            }
        })
        return () => {
            s.unsubscribe();
            s2.unsubscribe();
        }
    }, [receptoSubject, receptoSubjectByRfc, getByName, getByRfc])

    useEffect(() => {
        // Execute only when validation failed before
        if (validRequested) {
            if (validateCfdiIsComplete(cfdi, validConceptos)) {
                setValidRequested(false);
            }
        }
    }, [cfdi, validRequested, validConceptos])

    // Global bill check and modify (UE)
    useEffect(() => {
        if (cfdi.receptor?.rfc) {
            if (cfdi.receptor.rfc === 'XAXX010101000') {
                if (!cfdi.global) {
                    console.log(`UE RFC Generic Enabled: `, cfdi);
                    // if global is present, do not execute again
                    const comprobante: Bill = Object.assign({}, cfdi);
                    comprobante.receptor.nombre = 'PUBLICO EN GENERAL';
                    comprobante.receptor.usoCFDI = 'S01';
                    comprobante.receptor.email = currentUser.professionalProfile.email;
                    comprobante.receptor.zip = currentUser.fiscalpopProfile.lugarExpedicion;

                    comprobante.global = {
                        ano: `${new Date().getFullYear()}`,
                        mes: mesesFormat(`${new Date().getMonth() + 1}`),
                        periodo: '01'
                    }
                    comprobante.metodoPago = 'PUE';
                    comprobante.conceptos.forEach((c) => {
                        c.descripcion = 'Venta';
                        c.claveProdServ = '01010101'
                        c.claveUnidad = 'ACT'
                        c.cantidad = 1
                    })

                    dispatchBill({ property: 'comprobante', value: comprobante })

                    // Change Bill argument to be PUE
                    const arg = Object.assign({}, billArgumento)
                    arg.timeDefered = 0;
                    arg.multipayType = 0;
                    arg.upfront = 0;
                    _setBillArgumento(arg);
                }
            } else {
                if (cfdi.global) {
                    console.log(`UE RFC Generic Disabled: `, cfdi);
                    const comprobante: Bill = Object.assign({}, cfdi);
                    comprobante.global = null;
                    dispatchBill({ property: 'global', value: null })
                }
            }
        }
    }, [cfdi, billArgumento, currentUser])


    useQuery(graphqlSchema.FISCALPOP.USO_CFDI.getUsoCfdi, {
        onCompleted: ({ getUsoCfdi }: { getUsoCfdi: { clave: string, descripcion: string, forRegimen: string[] }[] }) => {
            console.log('Got Usos CFDI: ', getUsoCfdi);
            setUsosCFDI(getUsoCfdi.map(ucfdi => ({ value: ucfdi.clave, label: `(${ucfdi.clave}) ${ucfdi.descripcion}`, forRegimen: ucfdi.forRegimen })).sort((a, b) => a.value.localeCompare(b.value)));
        },
        onError: (e) => {
            console.error('Error getting uso CFDI: ', e);
        }
    })
    useQuery(graphqlSchema.FISCALPOP.FORMA_PAGO.getFormaPago, {
        onCompleted: ({ getFormaPago }: { getFormaPago: { key: string, label: string }[] }) => {
            //console.log('Got Usos CFDI: ', getFormaPago);
            setFormasPago(getFormaPago.map(gFP => ({ value: gFP.key, label: `(${gFP.key}) ${gFP.label}` })));
        },
        onError: (e) => {
            console.error('Error getting uso CFDI: ', e);
        }
    })


    const handleReceptorChange = (property: 'nombre' | 'rfc' | 'usoCFDI' | 'email' | 'regimen' | 'zip') => (value: string) => {
        const receptor = Object.assign({}, cfdi.receptor);
        if (property === 'rfc') {
            value = (value || '').toUpperCase();
        }
        if (property === 'email') {
            value = (value || '').toLowerCase().trim();
        }
        receptor[property] = value;
        dispatchBill({ property: 'receptor', value: receptor });
        if (property === 'nombre') {
            receptoSubject.next(value);
        }
        if (property === 'rfc') {
            receptoSubjectByRfc.next(value);
        }
    }

    const handleReceptorAutofill = (receptorAsString: string) => {
        const receptor: Receptor = JSON.parse(receptorAsString);
        console.log('Receptor to add: ', receptor);
        const _receptor = Object.assign({}, cfdi.receptor, { nombre: receptor.name, rfc: receptor.rfc, customerId: receptor._id });
        if (receptor.email) {
            _receptor.email = receptor.email;
        }
        if (receptor.zip) {
            _receptor.zip = receptor.zip;
        }
        if (receptor.regimen) {
            _receptor.regimen = receptor.regimen;
        }
        const comprobante: Bill = Object.assign({}, cfdi);
        comprobante.receptor = _receptor;
        if (receptor.seriePreference) {
            // Pull serie ref
            comprobante.serie = receptor.seriePreference;
            // get Tracker reference for folio
            const hasFolio = tracekrOptions.find(tO => tO.serie === receptor.seriePreference);
            if(!!hasFolio){
                comprobante.folio = `${hasFolio.folio}`;
            }
        }
        dispatchBill({ property: 'comprobante', value: comprobante });
    }

    const handleGlobalChange = (property: 'periodo' | 'mes' | 'ano') => (value: any) => {
        const global = Object.assign({}, cfdi.global);
        if (property === 'ano' && value.length === 4) {
            const aV = parseInt(value);
            if (aV < 2022) {
                value = '2022'
            } else if (aV > new Date().getFullYear()) {
                value = `${new Date().getFullYear()}`

            }
        }
        global[property] = value;
        dispatchBill({ property: 'global', value: global })
    }

    const handleComprobanteChange = (property: 'formaPago' | 'metodoPago' | 'serie' | 'folio') => (value: any) => {
        const comprobante = Object.assign(cfdi);
        comprobante[property] = value;
        if (property === 'serie') {
            const tOption = tracekrOptions.find(t => t.serie === value);
            if (!!tOption) {
                comprobante['folio'] = `${tOption.folio}`;
            }
        }
        dispatchBill({ property: 'comprobante', value: comprobante });
    }

    const setBillArgumento = (argument: ToBillArguments<ToBillEvent>) => {
        _setBillArgumento(argument);
        setInvalidArguments(null);
    }

    const handleConceptosChange = (updated: BillConceptos[]) => {
        dispatchBill({ property: 'conceptos', value: updated });
    }

    const _clearBill = () => {

        dispatchBill({
            property: '_replace', value: {
                formaPago: '03',
                metodoPago: 'PUE',
                serie: 'A',
                folio: '1',
                receptor: { nombre: '', rfc: '', usoCFDI: 'G03', email: '', regimen: '612', zip: '' },
                conceptos: [{
                    claveProdServ: '',
                    claveUnidad: '',
                    cantidad: 1,
                    descripcion: '',
                    valorUnitario: 0,
                    descuento: 0,
                    pedimento: [],
                    noIdentificacion: '',
                    impuestos: [{ type: 'iva', tipo: 'Fijo', retencion: false, tasa: 0.16 }]
                }],
            }
        });
        setBillArgumento({
            timeDefered: 0,
            multipayType: 0,
            multiPayments: 1,
            upfront: 0,
            frequency: 'monthly',
            isClosed: false,
            createdAt: Date.now(),
            billEvents: [],
            paymentEvents: []
        })
        if (argumentid) {
            history.push({
                state: {},
                pathname: '/facturacion/facturar'
            })
        }

    }

    const _structureToBillEvents = (billArgument: ToBillArguments<ToBillEvent>, masterCfdi: Bill) => {
        const billEvents: ToBillEvent[] = [];
        const paymentEvents: ToBillEvent[] = [];
        const { total } = calculateTotal(masterCfdi.conceptos);
        billArgument.billEvents = [];
        billArgument.paymentEvents = [];
        billArgument.MasterCFDI = masterCfdi;
        if (billArgument.multipayType === 0) {
            if (masterCfdi.metodoPago === 'PUE') {
                // Do nothing
            } else {
                const toPaymentEvents = paymentRequestFactory({
                    upfront: 0,
                    total,
                    lugarExpedicion: currentUser.fiscalpopProfile.lugarExpedicion,
                    receptor: {
                        nombre: masterCfdi.receptor.nombre,
                        rfc: masterCfdi.receptor.rfc,
                        usoCFDI: 'P01',
                        email: masterCfdi.receptor.email || null
                    },
                    frequency: billArgument.frequency,
                    multiPayments: billArgument.multiPayments,
                    timeDefered: billArgument.timeDefered
                })
                for (const e of toPaymentEvents) {
                    paymentEvents.push(e);
                }
            }
        } else if (billArgument.multipayType === 1) {
            if (masterCfdi.metodoPago === 'PUE') {
                const upfrontEvents = upfrontBillFactory({
                    upfront: billArgument.upfront,
                    timeDefered: billArgument.timeDefered,
                    masterCfdi: masterCfdi,
                });
                billArgument.MasterCFDI = upfrontEvents.adelantoBill;
                billEvents.push(upfrontEvents.billEvents[0]);
            } else {
                const toPaymentEvents = paymentRequestFactory({
                    upfront: billArgument.upfront,
                    total,
                    lugarExpedicion: currentUser.fiscalpopProfile.lugarExpedicion,
                    receptor: {
                        nombre: masterCfdi.receptor.nombre,
                        rfc: masterCfdi.receptor.rfc,
                        usoCFDI: 'P01',
                        email: masterCfdi.receptor.email || null
                    },
                    frequency: billArgument.frequency,
                    multiPayments: billArgument.multiPayments,
                    timeDefered: billArgument.timeDefered
                })
                for (const e of toPaymentEvents) {
                    paymentEvents.push(e);
                }
            }
        } else if (billArgument.multipayType === 2) {
            const toPaymentEvents = paymentRequestFactory({
                upfront: billArgument.upfront,
                total,
                lugarExpedicion: currentUser.fiscalpopProfile.lugarExpedicion,
                receptor: {
                    nombre: masterCfdi.receptor.nombre,
                    rfc: masterCfdi.receptor.rfc,
                    usoCFDI: 'P01',
                    email: masterCfdi.receptor.email || null
                },
                frequency: billArgument.frequency,
                multiPayments: billArgument.multiPayments,
                timeDefered: billArgument.timeDefered
            })
            for (const e of toPaymentEvents) {
                paymentEvents.push(e);
            }
        } else if (billArgument.multipayType === 3) {
            const toPaymentEvents = paymentRequestFactory({
                upfront: billArgument.upfront,
                total,
                lugarExpedicion: currentUser.fiscalpopProfile.lugarExpedicion,
                receptor: {
                    nombre: masterCfdi.receptor.nombre,
                    rfc: masterCfdi.receptor.rfc,
                    usoCFDI: 'P01',
                    email: masterCfdi.receptor.email || null
                },
                frequency: billArgument.frequency,
                multiPayments: billArgument.multiPayments,
                timeDefered: billArgument.timeDefered
            })
            for (const e of toPaymentEvents) {
                paymentEvents.push(e);
            }
        }

        // General settings
        if (billArgument.timeDefered === 0) {
            billArgument.isClosed = true;
        } else {
            billArgument.isClosed = false;
        }
        billArgument.createdAt = Date.now();
        return {
            billArgument,
            billEvents,
            paymentEvents
        }
    }

    const validateRegimenUsoCfdi = () => {
        const canUseByRegimen = !usosCFDI.length ? true : usosCFDI.find(u => u.value === cfdi.receptor.usoCFDI).forRegimen.includes(cfdi.receptor.regimen)
        const invalidUsoCfdi = !!cfdi.receptor.usoCFDI && !!cfdi.receptor.regimen && !canUseByRegimen ? 'Uso de CFDI no aplica para el régimen de receptor' : ''
        return invalidUsoCfdi;
    }


    const submitPreview = () => {
        console.log('PREVIEW BILL (billArgumento): ', billArgumento)
        console.log('PREVIEW BILL (cfdi): ', cfdi);

        // Confirm that CFDI is correct else, state error
        if (!validateCfdiIsComplete(cfdi, validConceptos)) {
            setValidRequested(true);
            return;
        }
        // Confirm that upfront will not EXCEED bill's total, etc.
        const _invalidArguments = validateBillArgumentos(billArgumento, cfdi);
        if (_invalidArguments) {
            setInvalidArguments(_invalidArguments);
            setValidRequested(false);
            return;
        }

        const _usoCfdiError = validateRegimenUsoCfdi();
        if (_usoCfdiError) {
            setUsoCfdiError(_usoCfdiError);
            setValidRequested(false);
            return;
        }

        const { billArgument, billEvents, paymentEvents } = _structureToBillEvents(billArgumento, cfdi);
        console.log('PREVIEW BILL (templates): ', { billArgument, billEvents, paymentEvents });
        const pathName = eventid ? `/facturacion/facturar/preview/${argumentid}/${eventid}` : (argumentid ? `/facturacion/facturar/preview/${argumentid}` : '/facturacion/facturar/preview');
        history.push({
            pathname: pathName,
            state: { billArgument, billEvents, paymentEvents }
        })
    }

    const trackerToOptions = tracekrOptions.map(t => ({ label: t.serie, value: t.serie, template: <p className="_serie">{t.serie} <span>(folio: {t.folio})</span></p> }));
    const receptorNameOptions = receptorOptions.map(r => ({ label: r.name, value: JSON.stringify(r), template: <div><p className="_big">{r.name}</p><p className="_small">{r.rfc}</p></div> }))
    const receptorRFCOptions = receptorOptions.map(r => ({ label: r.rfc, value: JSON.stringify(r), template: <div><p className="_big">{r.rfc}</p><p className="_small">{r.name}</p></div> }))

    const invalidUsoCfdi = validateRegimenUsoCfdi();

    const usosCFDIOptions = usosCFDI.map((u) => {
        const disabled = !u.forRegimen.length ? false : !u.forRegimen.includes(cfdi.receptor.regimen)
        return Object.assign({}, u, { disabled })
    })

    const GLOBAL_ANO = new Array(2).fill(1).map((_, i) => {
        const e = {
            value: `${new Date().getFullYear() - i}`,
            label: `${new Date().getFullYear() - i}`
        }
        return e;
    })

    return (
        <div id="Facturar">
            <SectionTitleBar currentUser={currentUser} title={argumentid ? `Editar Factura` : `Emitir Factura`} />
            <div className="facturarContent">
                <div>
                    <h4>Receptor de CFDI</h4>
                    <div className="card table">
                        <div className="cardBody">
                            <div className="row three lg-two xxs-one cfdiBase xs-gap-compressed">
                                <AutoComplete label="Nombre" hasError={validRequested && !cfdi.receptor.nombre} value={cfdi.receptor.nombre} placeholder="Razón social de receptor" type="text"
                                    noChangeOnSelect={true} options={receptorNameOptions} forcePropsValue={true} onChange={handleReceptorChange('nombre')} onSelect={handleReceptorAutofill} />

                                <AutoComplete label="RFC" hasError={validRequested && !cfdi.receptor.rfc} value={cfdi.receptor.rfc} placeholder="RFC de receptor" type="text"
                                    noChangeOnSelect={true} options={receptorRFCOptions} forcePropsValue={true} onChange={handleReceptorChange('rfc')} onSelect={handleReceptorAutofill} />

                                <div className='row lg-hidden' />
                                <Select label="Régimen" hasError={validRequested && !cfdi.receptor.regimen} value={cfdi.receptor.regimen} onChange={handleReceptorChange('regimen')} placeholder="Régimen fiscal del receptor" options={REGIMEN_OPT} disabled={cfdi.receptor.rfc === 'XAXX010101000'} />

                                <Select label={'Uso CFDI'} hasError={(validRequested && !cfdi.receptor.usoCFDI) || !!invalidUsoCfdi} errorLabel={invalidUsoCfdi} value={cfdi.receptor.usoCFDI} options={usosCFDIOptions} onChange={handleReceptorChange('usoCFDI')} />
                                <Input label="Código Postal" hasError={validRequested && ((cfdi.receptor.zip || '').length !== 5)} value={cfdi.receptor.zip} onChange={handleReceptorChange('zip')} type="text" placeholder="Codigo Postal Domicilio Fiscal" disabled={cfdi.receptor.rfc === 'XAXX010101000'} />

                                <AutoComplete label="Serie de factura" value={cfdi.serie} onChange={handleComprobanteChange('serie')} type="text" placeholder="Serie Factura"
                                    noChangeOnSelect={true} options={trackerToOptions} forcePropsValue={true} onSelect={handleComprobanteChange('serie')} />

                                <Input label="Folio" value={cfdi.folio} onChange={handleComprobanteChange('folio')} type="text" placeholder="Folio Factura" />
                                <Input label="Correo (Opcional, se envia automáticamente)" value={cfdi.receptor.email} onChange={handleReceptorChange('email')} type="text" placeholder="Email" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className={`global ${cfdi.global ? '' : 'hidden'}`}>
                    <h4>Publico en General o Global</h4>
                    <div className="card table">
                        <div className="cardBody">
                            <div className="row three lg-two xxs-one cfdiBase xs-gap-compressed">
                                <Select label="Periodo" value={cfdi.global?.periodo || '01'} onChange={handleGlobalChange('periodo')} placeholder="Rango de tiempo para venta mostrador" options={GLOBAL_PERIODO_OPT} disabled={!cfdi.global} />
                            </div>
                            <div className="row three lg-two xxs-one cfdiBase xs-gap-compressed">
                                <Select label="Año" value={cfdi.global?.ano || ''} onChange={handleGlobalChange('ano')} placeholder="Año" options={GLOBAL_ANO} disabled={!cfdi.global} />
                                <Select label="Mes" value={cfdi.global?.mes || '05'} onChange={handleGlobalChange('mes')} placeholder="Mes" options={GLOBAL_MES} disabled={!cfdi.global} />
                            </div>
                        </div>
                    </div>
                </div>


                <div className={`global ${cfdi.global ? '' : ''}`}>
                    <FacturarFormato cfdi={cfdi} isGlobalBill={cfdi.receptor?.rfc === 'XAXX010101000'} onCfdiChange={handleComprobanteChange} billArguments={billArgumento} invalidArguments={invalidArguments} onBillArgumentsChange={setBillArgumento} formaDePagoOptions={formasPago} />
                </div>
                <FacturarConceptos
                    conceptos={cfdi.conceptos}
                    isGlobalBill={cfdi.receptor?.rfc === 'XAXX010101000'}
                    validRequested={validRequested}
                    authToken={currentUser.fiscalpopProfile.authToken}
                    onCfdiChange={handleConceptosChange}
                    onValidationTest={setValidConceptos}
                />
                <FacturaTotales cfdi={cfdi} />
                <div className="actions">
                    {

                        validRequested && !validConceptos ?
                            <p className="message error">
                                <span className="error">
                                    Conceptos con claves de producto o unidad inválidas
                                </span>
                            </p>
                            :
                            (validRequested ?
                                <p className="message error">
                                    <span className="error">
                                        Algunos campos faltan de llenarse o contienen errores
                                    </span>
                                </p>
                                : (
                                    invalidArguments ?
                                        <p className="message error">
                                            <span className="error">
                                                {invalidArguments.text}
                                            </span>
                                        </p>
                                        : (
                                            usoCfdiError ?
                                                <p className="message error">
                                                    <span className="error">
                                                        {usoCfdiError}
                                                    </span>
                                                </p>
                                                : '')

                                ))
                    }
                    <Button secondary={true} handleClick={_clearBill}>
                        <span>
                            Limpiar
                        </span>
                    </Button>
                    <Button primary={true} handleClick={submitPreview}>
                        <span>
                            Previsualizar Factura
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Facturar;