import React from "react";

import './loadScreen.scss';

interface LoadingProps {
    display: boolean;
    relativePos?: boolean;
    svgWidth?: string;
    svgHeight?: string;
    children?:never[];
    inmediate?:boolean;
}

export default class Loading extends React.Component {
    props: LoadingProps
    
    render() {
        const displayClass = this.props.display ? '' : 'noDisplay';
        const positionClass = this.props.relativePos ? ' relative' : '';
        const width = this.props.svgWidth || '150pt';
        const height = this.props.svgHeight || '150pt';
        return (
            <div id="LoadingSaver" className={`${displayClass}${positionClass}${this.props.inmediate ? ' inmediate':''}`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width={width} height={height} viewBox="0 0 150 150" version="1.1">
                    <g id="surface1">
                        <path id="TL" d="M 8.6875 57.027344 L 8.6875 56.785156 C 8.6875 36.609375 22.039062 25.96875 40.328125 25.96875 L 50.421875 25.96875 L 50.421875 18.328125 C 50.421875 5.449219 70.816406 0.492188 70.816406 0.492188 L 70.816406 50.503906 L 40.75 50.652344 C 33.496094 50.652344 28.835938 50.988281 28.835938 57.710938 L 28.835938 57.957031 C 28.835938 65.296875 33.496094 67.179688 40.855469 67.179688 L 50.160156 67.179688 L 50.160156 67.238281 L 70.816406 67.238281 L 70.816406 87.113281 L 39.277344 87.113281 C 20.671875 87.113281 8.6875 75.984375 8.6875 57.027344 Z M 8.6875 57.027344 " />
                        <path id="BL" d="M 71.046875 150.03125 C 71.046875 150.03125 50.652344 145.078125 50.652344 132.320312 C 50.652344 123.929688 50.652344 121.902344 50.652344 121.488281 C 50.652344 121.488281 46.335938 121.488281 33.800781 121.488281 C 21.265625 121.488281 22.125 101.800781 22.125 101.800781 L 24.421875 101.800781 L 24.421875 101.886719 L 71.046875 101.886719 Z M 71.046875 150.03125 " />
                        <path id="TR" d="M 81.519531 0.492188 C 81.519531 0.492188 101.917969 5.449219 101.917969 18.207031 C 101.917969 30.964844 101.917969 32.082031 101.917969 32.082031 C 101.917969 32.082031 106.230469 32.082031 118.765625 32.082031 C 131.300781 32.082031 130.445312 51.769531 130.445312 51.769531 L 128.144531 51.769531 L 128.144531 51.683594 L 81.519531 51.683594 Z M 81.519531 0.492188 " />
                        <path id="BR" d="M 143.882812 96.785156 C 143.882812 116.964844 130.53125 127.605469 112.238281 127.605469 L 102.144531 127.605469 L 102.144531 132.199219 C 102.144531 145.078125 81.753906 150.035156 81.753906 150.035156 L 81.753906 103.070312 L 111.820312 102.921875 C 119.074219 102.921875 123.730469 102.582031 123.730469 95.859375 L 123.730469 95.613281 C 123.730469 88.277344 119.074219 86.390625 111.710938 86.390625 L 102.410156 86.390625 L 102.410156 86.332031 L 81.75 86.332031 L 81.75 66.457031 L 113.289062 66.457031 C 131.898438 66.457031 143.882812 77.585938 143.882812 96.542969 Z M 143.882812 96.785156 " />
                    </g>
                </svg>
            </div>
        )
    }
}