import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Cotizacion } from '../../../models/Cotizaciones';
import { graphqlSchema } from '../../../services/graphql.schema';
import sharedToasterSubject from '../../../services/shared.toasterSubject';
import { parseStampErrorFromGraphQL } from '../../../utils/error.graphql';
import Button from '../../Forms/Button';
import Input from '../../Forms/Input';
import Select from '../../Forms/Select';


interface CotizacionCardToBillProps {
    isPaid: boolean,
    cotizacion: Cotizacion,
    token: string;
    profToken: string;
    onStampRequestSubmitted: (cotizacionUpdated: Cotizacion) => void
}

function CotizacionViewToBillCard({ cotizacion, isPaid, token, onStampRequestSubmitted, profToken }: CotizacionCardToBillProps) {
    const [animate, setAnimate] = useState(false);

    // Billing entry
    const [nombre, setNombre] = useState(cotizacion.name || '');
    const [rfc, setRFC] = useState(cotizacion.rfc || '');
    const [usoCFDI, setUsoCFDI] = useState('G03');
    const [email, setEmail] = useState(cotizacion.email || '');

    const [usosCFDI, setUsosCFDI] = useState<{ value: string, label: string }[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useQuery(graphqlSchema.FISCALPOP.USO_CFDI.getUsoCfdiTokenized, {
        variables: {
            token
        },
        onCompleted: ({ getUsoCfdiTokenized }: { getUsoCfdiTokenized: { clave: string, descripcion: string }[] }) => {
            //console.log('Got Usos CFDI: ', getUsoCfdiTokenized);
            setUsosCFDI(getUsoCfdiTokenized.map(ucfdi => ({ value: ucfdi.clave, label: `(${ucfdi.clave}) ${ucfdi.descripcion}` })));
        },
        onError: (e) => {
            console.error('Error getting uso CFDI: ', e);
        },
        fetchPolicy: 'cache-first'
    })

    const [submitToStamp] = useMutation(graphqlSchema.FISCALPOP.COTIZACION.stampCotizacion, {
        onCompleted: ({stampCotizacion}: {stampCotizacion: Cotizacion}) => {
            console.log('stampCotizacion: ', stampCotizacion);
            sharedToasterSubject.next({type: 'confirm', message: `Facturado correctamente`});
            onStampRequestSubmitted(stampCotizacion);
            setIsSubmitting(false);
        },
        onError: (e) => {
            console.error('Error stamping: ', e.graphQLErrors[0]);
            sharedToasterSubject.next({type:'error', message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clickToClose: true})
            setIsSubmitting(false);
        }
    })

    useEffect(() => {
        if (isPaid) {
            setAnimate(true);
        }
    }, [isPaid]);

    const stampToMopstrador = () => {
        if(cotizacion.uuid){
            return;
        }
        setIsSubmitting(true);
        submitToStamp({
            variables: {
                cotizacionId: cotizacion._id,
                receptor: {
                    nombre: 'PUBLICO EN GENERAL',
                    rfc: 'XAXX010101000',
                    usoCFDI: 'S01',
                    regimen: '616',
                    zip: '66039'
                }
            }
        })
    }
    const stampToClient = () => {
        if(cotizacion.uuid){
            return;
        }
        setIsSubmitting(true);
        submitToStamp({
            variables: {
                cotizacionId: cotizacion._id,
                receptor: {
                    nombre,
                    rfc,
                    usoCFDI,
                    email,
                    customerId: cotizacion.clientId || null
                }
            }
        })
    }

    const handleRFC = (_rfc: string)=> {setRFC(_rfc.toUpperCase())}
    const handleEmail = (_email: string)=> {setEmail(_email.toLowerCase().trim())}

    const inputsDisabled = isSubmitting || !!cotizacion.uuid;
    return (
        <div className={`_toBillRequested ${isPaid ? '_display' : (animate ? '_hide' : '')}`}>
            <div className={`newCard requestToBillContain`}>
                <h2>
                    Facturar pago
                </h2>
                <div className={`animateWrap`}>
                    <div className={`_billDownload ${cotizacion.uuid ? '_display' : ''}`}>
                        <p>
                            <b>
                            Tu pago ya fué facturado y enviado a tu correo! 
                            </b>
                        </p>
                        <p>
                            Descarga tu PDF y XML aquí si lo deseas.
                        </p>
                        <div className="downloads">
                            <a href={`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${atob(profToken)}?uuid=${cotizacion.uuid}`}>
                                Descargar PDF
                            </a>
                            <a href={`https://api.fiscalpop.com/api/v1/cfdi/download/xml/${atob(profToken)}?uuid=${cotizacion.uuid}`}>
                                Descargar XML
                            </a>
                        </div>
                    </div>
                    <div className={`_billingReceiver`}>
                        <Input label="Nombre o Razón social" value={nombre} onChange={setNombre} type="text" disabled={inputsDisabled}/>
                        <Input label="RFC" value={rfc} onChange={handleRFC} type="text"  disabled={inputsDisabled}/>
                        <Input label="Email a enviar factura" value={email} onChange={handleEmail} type="text"  disabled={inputsDisabled}/>
                        <Select options={usosCFDI} value={usoCFDI} onChange={setUsoCFDI} label="Uso de CFDI"  disabled={inputsDisabled}/>
                    </div>
                </div>
                <div className={`_actions ${cotizacion.uuid ? '_hide' : ''}`}>
                    <Button tertiary={true} handleClick={stampToMopstrador} disabled={inputsDisabled}>
                        <span>
                            No requiero Factura
                        </span>
                    </Button>
                    <Button primary={true} handleClick={stampToClient} disabled={inputsDisabled}>
                        <span>
                            Solicitar factura
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    )
}


export default CotizacionViewToBillCard;