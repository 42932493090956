import { BankMovementSummary } from "../../../models/Administracion/Bank.Transactions";


export const formatLineGroupsFromBankSummaries = (data: BankMovementSummary[], lastBalance: number) => {
    // [CURRENT, -1, -2, -3 ... N]

    /*
    Pagos de 10065 [40 Meses]
    {N 54536} Credito de 344'000 
    - 402'600 Pago Total
        - 289'454 Intereses 
        - 113'146 a Capital
    {N 73726} Credito de 345'400 [23 Meses]
    - 231'495 Pago Total
        - 180'779 Intereses
        - 50'716 a Capital

    return data.sort((a, b) => b.date - a.date).reduce((p, c) => {
        if (!p.length) {
            // First case
            p.push({ x: c.date, y: lastBalance })
        } else {
            // Next entries are SUBTRACTED as this is an inverse fill process
            p.push({ x: c.date, y: p[p.length - 1].y - (c.income + c.expense) })
        }
        return p;
    }, [] as { x: number, y: number }[]).sort((a, b) => a.x - b.x)
    */
    return data.sort((a, b) => b.date - a.date).reduce((p, c, ind, arr) => {
        if (!p.length) {
            // First case
            p.push({ x: c.date, y: lastBalance })
        } else {
            // Next entries are SUBTRACTED as this is an inverse fill process
            const lastEntry = arr[ind - 1];
            const lastUtility = (lastEntry.income + lastEntry.expense)
            p.push({ x: c.date, y: p[p.length - 1].y - lastUtility })
        }
        return p;
    }, [] as { x: number, y: number }[]).sort((a, b) => a.x - b.x)
}