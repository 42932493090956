import { ToBillArguments, ToBillEvent, Bill } from './../models/Factura';
import { PagoRequest, PagoRequestReceptor } from '../models/Pago';


export const frequencyToMsTime = (frequency: ToBillArguments<ToBillEvent>['frequency']) => {
    switch (frequency) {
        case 'daily':
            return 24 * 60 * 60 * 1000;
        case 'weekly':
            return 7 * 24 * 60 * 60 * 1000;
        case 'monthly':
            return 30 * 24 * 60 * 60 * 1000;
        case 'bi_monthly':
            return 2 * 30 * 24 * 60 * 60 * 1000;
        case 'tri_monthly':
            return 3 * 30 * 24 * 60 * 60 * 1000;
        case 'tetra_monthly':
            return 4 * 30 * 24 * 60 * 60 * 1000;
        case 'hexa_monthly':
            return 6 * 30 * 24 * 60 * 60 * 1000;
        case 'yearly':
            return 12 * 30 * 24 * 60 * 60 * 1000;
        default:
            return 0;
    }
}

interface PayRequestFactory {
    upfront: number;
    total: number;
    lugarExpedicion: string;
    receptor: PagoRequestReceptor;
    moneda?: string;
    frequency: ToBillArguments<ToBillEvent>['frequency'];
    multiPayments: number;
    timeDefered: number;
}

export const paymentRequestFactory = ({ upfront, total, lugarExpedicion, receptor, moneda, frequency, multiPayments, timeDefered }: PayRequestFactory) => {
    const paymentEvents: ToBillEvent[] = [];
    const montoForEach = !!upfront ? Math.round(((total - (upfront || 0)) / (multiPayments - 1)) * 100) / 100 : Math.round((total / multiPayments) * 100) / 100;
    const baseDate = Date.now() + (timeDefered * 24 * 60 * 60 * 1000);
    for (let index = 0; index < multiPayments; index++) {
        const monto = !!upfront && index === 0 ? upfront : montoForEach;
        const sequenceFecha = baseDate + (index * frequencyToMsTime(frequency))
        const payRequest: PagoRequest = {
            lugarExpedicion,
            receptor,
            pagos: [{
                fecha: new Date(sequenceFecha).toJSON(),
                formaDePago: '03',
                moneda: !!moneda ? moneda : 'MXN',
                monto,
                facturaRelacionada: [{
                    uuid: ''
                }]
            }]
        };
        paymentEvents.push({
            isClosed: false,
            when: sequenceFecha,
            closedAt: null,
            type: 'pago',
            paymentNum: index + 1,
            paymentCFDI: payRequest,
            CFDI: null,
            NotaCreditoCFDI: null
        });
    }
    return paymentEvents;
}

interface UpfrontBillFactory {
    upfront: number;
    timeDefered: number;
    masterCfdi: Bill;
}

export const upfrontBillFactory = ({ upfront, timeDefered, masterCfdi }: UpfrontBillFactory) => {
    const billEvents: ToBillEvent[] = [];
    const upfrontNoTax = Math.round((upfront / 1.16) * 100) / 100;
    // Bill to be used for Current
    const adelantoBill: Bill = Object.assign({}, masterCfdi);
    adelantoBill.conceptos = [{
        claveProdServ: '84111506',
        claveUnidad: 'ACT',
        cantidad: 1,
        descripcion: 'Anticipo del bien o servicio',
        valorUnitario: upfrontNoTax,
        impuestos: [{
            type: 'iva',
            tipo: 'Fijo',
            retencion: false,
            tasa: 0.16
        }]
    }];
    const notaCreditoBill: Bill = Object.assign({}, masterCfdi);
    notaCreditoBill.tipoDeComprobante = 'E';
    notaCreditoBill.formaPago = '30';
    notaCreditoBill.cfdiRelacionados = {
        tipoRelacion: '07',
        uuids: []
    };
    notaCreditoBill.conceptos = [{
        claveProdServ: '84111506',
        claveUnidad: 'ACT',
        descripcion: "Aplicación del Anticipo",
        valorUnitario: upfrontNoTax,
        cantidad: 1,
        impuestos: [{
            type: 'iva',
            retencion: false,
            tasa: 0.16
        }]
    }];
    billEvents.push({
        isClosed: false,
        when: null,
        closedAt: null,
        type: 'bill',
        paymentNum: 1,
        paymentCFDI: null,
        CFDI: Object.assign({}, masterCfdi),
        NotaCreditoCFDI: notaCreditoBill
    })
    return {adelantoBill, billEvents}
}