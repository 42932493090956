import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BankBudgetsActivity } from '../../../../models/Administracion/Bank.Budgets';
import { formatCurrencyStyled } from '../../../visuals/currency.formatted';

import './PresupuestosConfigure.Summary.scss'

interface PresupuestosConfigureSummaryProps {
    subcategories: BankBudgetsActivity[]
}

interface SummaryStats {
    budget: number;
    unbudget: number;
    month: number;
    lastMonth: number;
    percentRatio: number;
    percentRatioSymbol: string;
}

function PresupuestosConfigureSummary({
    subcategories
}: PresupuestosConfigureSummaryProps) {

    const [stats, setStats] = useState<SummaryStats>({ budget: 0, unbudget: 0, month: 0, lastMonth: 0, percentRatio: 0, percentRatioSymbol: '%' })

    useEffect(() => {
        const MONTH_TS = moment().startOf('month').subtract(1, 'month').valueOf()
        const _budgets = subcategories.reduce((p, c) => p += c.goal, 0);
        const _unbudgets = subcategories.filter(s => !s.goal).reduce((p, c) => p += c.current, 0);
        const _month = subcategories.reduce((p, c) => p += c.current, 0);
        const _lastMonth = subcategories.reduce((p, c) => p += c.expenses.find(e => e.monthTs === MONTH_TS)?.expense || 0, 0)
        const _percentRatio = !_lastMonth && !_month ? 0 : (!_lastMonth ? 100 : (!_month ? -100 : Math.round(((_month - _lastMonth) / _lastMonth) * 100)))

        setStats({
            budget: _budgets,
            unbudget: _unbudgets,
            month: _month,
            lastMonth: _lastMonth,
            percentRatio: _percentRatio > 1000 ? Math.round(_percentRatio / 10) : _percentRatio,
            percentRatioSymbol: _percentRatio > 1000 ? 'x' : '%',
        })

    }, [subcategories])

    return (
        <div className='card noShadow presupuestoConfigureSummary'>
            <div className='row'>
                <h3 className='gray'>
                    Presupuestos
                </h3>
                <p>
                    {formatCurrencyStyled(stats.budget)}
                </p>
            </div>
            <div className='card'>
                <h3 className='red'>
                    Sin presupuestar
                </h3>
                <p>
                    {formatCurrencyStyled(stats.unbudget)}
                </p>
            </div>
            <div className='card'>
                <h3 className='gray'>
                    Gasto del mes
                </h3>
                <p>
                    {formatCurrencyStyled(stats.month)}
                </p>
            </div>
            <div className='card'>
                <h3 className={stats.percentRatio <= 0 ? 'green' : 'orange'}>
                    Cambio vs Mes anterior
                </h3>
                <p className={stats.percentRatio <= 0 ? 'green' : 'orange'}>
                    {stats.percentRatio > 0 ? '+' : ''}{stats.percentRatio}{stats.percentRatioSymbol}
                </p>
            </div>
            <div className='card'>
                <h3 className='gray'>
                    Gasto mes anterior
                </h3>
                <p>
                    {formatCurrencyStyled(stats.lastMonth)}
                </p>
            </div>
        </div>
    )
}

export default PresupuestosConfigureSummary;