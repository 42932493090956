import React, { useState, FormEvent } from 'react';
import './Login.scss';
import { BaseReact } from '../../base.model';
import Input from '../Forms/Input';
import { Link, Redirect } from 'react-router-dom';
import Button from '../Forms/Button';
import LogoFullSvg from '../visuals/LogoSvg';
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import { ProfessionalProfile } from '../../models/Profile';
import GraphQlClient from '../../services/graphql';
import sharedToasterSubject from '../../services/shared.toasterSubject';


interface LoginProps extends BaseReact {
}

function Login({ history, location }: LoginProps) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const [registerRedirect, setRegisterRedirect] = useState(false);
    const [registering, setRegistering] = useState(false);

    const [loginAccount] = useMutation(graphqlSchema.PROFILE.login, {
        onCompleted: ({ login }: { login: { professionalProfile: ProfessionalProfile, token: string } }) => {
            console.log('<Login> Login: ', login);
            // UseReducer to update object array
            setRegistering(false);
            GraphQlClient.logSession(login.token)
                .then(d => {
                    console.log('<Login> Me Result: ', d);
                    setRegisterRedirect(true);
                });
        },
        onError: (e) => {
            console.error('<Login> Error Logging in: ', e.graphQLErrors[0].message);
            // setLoginError(e.graphQLErrors[0].message);
            sharedToasterSubject.next({
                type: 'error',
                message: e.graphQLErrors[0].message
            })
            setRegistering(false);
        }
    })

    const submitLogin = (e: FormEvent) => {
        e.preventDefault();
        if (!email || !password) {
            return;
        }
        setRegistering(true);
        loginAccount({
            variables: {
                login: {
                    email,
                    password
                }
            }
        })
    }

    const _setEmail = (e: string) => {
        setEmail(e.trim().toLowerCase())
    }

    return (
        <div id="Login">
            <form className="_loginForm" onSubmit={submitLogin}>
                <LogoFullSvg size={275} />
                <Input type="text" label="Email" value={email} onChange={_setEmail} placeholder="Tu email u Seller Token" hasError={false} />
                <Input type="password" label="Contraseña" value={password} onChange={setPassword} placeholder="Contraseña" />
                <div className="actions">
                    <Link to="/recover" >¿Olvidaste tu contraseña?</Link>
                    <Button primary={true} type="submit" accent={false} disabled={registering || !(email && password)}>
                        {
                            registering ?
                                <span>Entrando</span>
                                :
                                <span>Entrar</span>
                        }
                    </Button>
                </div>
                <div className="_toRegiser">
                    <Link to="/register">
                        ¿No tienes cuenta? <b>Regístrate</b>
                    </Link>
                </div>
            </form>
            {
                registerRedirect ?
                    <Redirect to="/" />
                    : ''
            }
        </div>
    )
}

export default Login;