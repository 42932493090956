import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ProfileQL } from '../../../models/Profile';
import ProfesionalSvg from '../svgs/profesionalSvg';

import './PlanWelcomeDialog.profesional.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px',
        border: 'none',
        background: 'transparent',
        perspective: '1000px',
        overflow: 'visible',
    }
};

interface PlanWelcomeDialogProfessionalProps {
    currentUser: ProfileQL;
}

function PlanWelcomeDialogProfessional({ currentUser, }: PlanWelcomeDialogProfessionalProps) {

    const [open, setOpen] = useState(false);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (location.search) {
            console.log(`<PlanWelcomeDialogProfessional> UE search: `, location.search)
            const search = location.search.replace('?', '').split('&').reduce((p, c) => {
                const [key, value] = c.split('=');
                p[key] = value;
                return p;
            }, {} as { [key: string]: string })

            if (search['welcome'] === 'profesional') {
                setOpen(true);
            }
        }
    }, [location.search])

    const onClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            isOpen={open}
            onRequestClose={onClose}
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            contentLabel="Bienvenido a FiscalPOP Profesional"
        >
            <div id="WelcomeProfesional" className='card'>
                <div className='_greet'>

                    <div className='_logoBack'>
                        <ProfesionalSvg height={145} width={145} />
                    </div>
                    <div className='_title'>
                        <h2>
                            ¡Haz iniciado tu prueba
                        </h2>
                        <h1>
                            FiscalPOP Profesional!
                        </h1>
                    </div>
                </div>
                <div className='_instructions'>
                    <p>
                        Ya puedes disfrutar de todas las increíbles ventajas que <br /><span>FiscalPOP</span> te ofrece. Comienza a explorarlas <Link to="/administration/bancos" onClick={onClose}>aquí</Link>.
                    </p>
                </div>
                <div className='_starRight'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="50" viewBox="0 0 22 20" fill="none">
                        <path d="M5.35159 7.2569L6.90009 2.20288L8.03691 7.15005C8.18857 7.81006 8.66393 8.34849 9.30005 8.58079L13.3401 10.0561L9.15679 11.5838C8.60109 11.7867 8.16374 12.2253 7.96232 12.7815L6.44009 16.9855L5.33147 12.9033C5.16128 12.2766 4.69741 11.7715 4.08743 11.5488L8.7738e-05 10.0561L4.12539 8.54965C4.7141 8.33466 5.16799 7.85614 5.35159 7.2569Z" fill="#B899FB" />
                        <path d="M14.5779 2.78114L15.307 0.35498L15.8302 2.6764C15.9788 3.33559 16.4502 3.87519 17.0835 4.11096L18.8601 4.77244L16.9429 5.48624C16.3886 5.69263 15.954 6.1338 15.7561 6.69119L15.0532 8.6702L14.5579 6.81069C14.391 6.18416 13.9307 5.6774 13.3231 5.45117L11.5001 4.77244L13.3603 4.07984C13.9471 3.86138 14.3977 3.38076 14.5779 2.78114Z" fill="#B899FB" />
                        <path d="M13.513 13.6665L13.8794 12.366L14.1335 13.5686C14.272 14.224 14.7295 14.7666 15.3521 15.0139L16.1001 15.3109L15.2205 15.6602C14.672 15.8781 14.2476 16.3272 14.0612 16.8872L13.7208 17.9094L13.4935 16.9994C13.3375 16.3744 12.89 15.8629 12.2913 15.6252L11.5001 15.3109L12.3261 14.9829C12.9052 14.7529 13.344 14.2662 13.513 13.6665Z" fill="#B899FB" />
                    </svg>
                </div>
                <div className='_starLeft'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="50" viewBox="0 0 22 20" fill="none">
                        <path d="M5.35159 7.2569L6.90009 2.20288L8.03691 7.15005C8.18857 7.81006 8.66393 8.34849 9.30005 8.58079L13.3401 10.0561L9.15679 11.5838C8.60109 11.7867 8.16374 12.2253 7.96232 12.7815L6.44009 16.9855L5.33147 12.9033C5.16128 12.2766 4.69741 11.7715 4.08743 11.5488L8.7738e-05 10.0561L4.12539 8.54965C4.7141 8.33466 5.16799 7.85614 5.35159 7.2569Z" fill="#B899FB" />
                        <path d="M14.5779 2.78114L15.307 0.35498L15.8302 2.6764C15.9788 3.33559 16.4502 3.87519 17.0835 4.11096L18.8601 4.77244L16.9429 5.48624C16.3886 5.69263 15.954 6.1338 15.7561 6.69119L15.0532 8.6702L14.5579 6.81069C14.391 6.18416 13.9307 5.6774 13.3231 5.45117L11.5001 4.77244L13.3603 4.07984C13.9471 3.86138 14.3977 3.38076 14.5779 2.78114Z" fill="#B899FB" />
                        <path d="M13.513 13.6665L13.8794 12.366L14.1335 13.5686C14.272 14.224 14.7295 14.7666 15.3521 15.0139L16.1001 15.3109L15.2205 15.6602C14.672 15.8781 14.2476 16.3272 14.0612 16.8872L13.7208 17.9094L13.4935 16.9994C13.3375 16.3744 12.89 15.8629 12.2913 15.6252L11.5001 15.3109L12.3261 14.9829C12.9052 14.7529 13.344 14.2662 13.513 13.6665Z" fill="#B899FB" />
                    </svg>
                </div>
            </div>
        </Modal>
    )
}

export default PlanWelcomeDialogProfessional;