import React, { useState, FunctionComponent } from 'react';
import { createPortal } from 'react-dom';

import './MenuComponent.scss';

interface ItemMenuProps {
    triggerContent: React.ReactNode;
    actionWillRedirect?: boolean;
    noPadding?: boolean;
    onOpenChange?: (bool: boolean) => void;
    overlayRootElement?: string;
    openOrientation?: 'left' | 'right' | 'center';
    widthBehaviour?: 'auto' | 'default' | 'unset';
}

const ItemMenuOverlay = ({ onClick, overlayRootElement }: { overlayRootElement: string, onClick: () => void }) => {
    return createPortal(
        <div className="_itemOverlay" onClick={onClick} />,
        document.getElementById(overlayRootElement)
    )
}

const ItemMenu: FunctionComponent<ItemMenuProps> = ({ openOrientation, onOpenChange, children, actionWillRedirect, triggerContent, noPadding, overlayRootElement, widthBehaviour }) => {
    const [opened, setOpened] = useState(false);

    const open = () => {
        setOpened(true);
        if (onOpenChange) {
            onOpenChange(true);
        }
    }

    const close = () => {
        setOpened(false);
        if (onOpenChange) {
            onOpenChange(false);
        }
    }

    const pseudoBlur = () => {
        if (actionWillRedirect) {
            // Avoids setState on unmounted ItemMenu error
            return;
        }
        setTimeout(() => {
            close()
        }, 225);
    }

    const openOprientationClass = openOrientation === 'right' ? '_rightOriented' : ( openOrientation === 'center'  ? '_centerOriented' : '')
    return (

        <div className="__ItemMenu">
            <div className={`_trigger ${opened ? 'opened' : ''}`} onClick={open}>
                {triggerContent}
            </div>
            {
                !widthBehaviour || (widthBehaviour === 'unset') ?
                    <div className={`_contentCollapse ${openOprientationClass} ${opened ? 'opened' : 'closed'} ${noPadding ? 'noPadding' : ''}`} onClick={pseudoBlur}>
                        {children}
                    </div>
                    :
                    <div style={{ width: widthBehaviour === 'default' ? `100%` : widthBehaviour }} className={`_contentCollapse ${openOprientationClass} ${opened ? 'opened' : 'closed'} ${noPadding ? 'noPadding' : ''}`} onClick={pseudoBlur}>
                        {children}
                    </div>
            }
            {
                opened ?
                    //<div className="_itemOverlay" onClick={close}></div>
                    <ItemMenuOverlay onClick={close} overlayRootElement={overlayRootElement || 'root'} />
                    : ''
            }
        </div>
    )
}

export default ItemMenu;