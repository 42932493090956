import { Bill } from "../models/Factura";
import { PagoRequest } from "../models/Pago";

export const validateCfdiIsComplete = (cfdi: Bill, conceptoClavesAreValid: boolean) => {
    if (!cfdi) {
        return false;
    }
    if (!cfdi.metodoPago) {
        return false;
    }
    if (!cfdi.conceptos) {
        return false;
    }
    if (!cfdi.receptor) {
        return false;
    }
    else if (!cfdi.receptor.rfc) { return false; }
    else if (!cfdi.receptor.nombre) { return false; }
    else if (!cfdi.receptor.usoCFDI) { return false; }
    else if (!cfdi.receptor.regimen) { return false; }
    else if (!cfdi.receptor.zip) { return false; }
    else if (cfdi.receptor.zip.length !== 5) { return false; }
    else if (!cfdi.conceptos.every(c => c.claveProdServ)) { return false; }
    else if (!cfdi.conceptos.every(c => c.claveUnidad)) { return false; }
    else if (!cfdi.conceptos.every(c => c.cantidad)) { return false; }
    else if (!cfdi.conceptos.every(c => c.valorUnitario)) { return false; }
    else if (!cfdi.conceptos.every(c => c.descripcion)) { return false; }
    else if (!cfdi.conceptos.every(c => !!c.impuestos.length)) { return false; }
    // All fields have data, check for specific cases
    else if (!conceptoClavesAreValid) { return false; }
    // Global Bill test
    else if (cfdi.receptor.rfc === 'XAXX010101000' && !cfdi.conceptos.every(c => !!c.noIdentificacion)) { return false; }
    return true;
}

export const validateNotaCredIsComplete = (cfdi: Bill) => {
    if (!cfdi) {
        return false;
    }
    if (!cfdi.metodoPago) {
        return false;
    }
    if (!cfdi.conceptos) {
        return false;
    }
    if (!cfdi.receptor) {
        return false;
    }
    if (!cfdi.cfdiRelacionados) {
        return false;
    }
    if (!cfdi.cfdiRelacionados.uuids.length) {
        return false;
    }
    else if (!cfdi.receptor.rfc) { return false; }
    else if (!cfdi.receptor.nombre) { return false; }
    else if (!cfdi.receptor.usoCFDI) { return false; }
    else if (!cfdi.receptor.regimen) { return false; }
    else if (!cfdi.receptor.zip) { return false; }
    else if (cfdi.receptor.zip.length !== 5) { return false; }
    else if (!cfdi.conceptos.every(c => c.claveProdServ)) { return false; }
    else if (!cfdi.conceptos.every(c => c.claveUnidad)) { return false; }
    else if (!cfdi.conceptos.every(c => c.cantidad)) { return false; }
    else if (!cfdi.conceptos.every(c => c.valorUnitario)) { return false; }
    else if (!cfdi.conceptos.every(c => c.descripcion)) { return false; }
    else if (!cfdi.conceptos.every(c => !!c.impuestos.length)) { return false; }
    return true;
}

export const validatePagoIsComplete = (cfdi: PagoRequest) => {
    if (!cfdi) {
        return false;
    }
    if (!cfdi.lugarExpedicion) {
        return false;
    }
    if (!cfdi.receptor) {
        return false;
    }
    if (!cfdi.pagos) {
        return false;
    }
    else if (!cfdi.receptor.rfc) { return false; }
    else if (!cfdi.receptor.nombre) { return false; }
    else if (!cfdi.receptor.usoCFDI) { return false; }
    else if (!cfdi.receptor.regimen) { return false; }
    else if (!cfdi.receptor.zip) { return false; }
    else if (cfdi.receptor.zip.length !== 5) { return false; }
    else if (!cfdi.pagos.every(c => c.formaDePago)) { return false; }
    else if (!cfdi.pagos.every(c => c.monto)) { return false; }
    else if (!cfdi.pagos.every(c => c.facturaRelacionada)) { return false; }
    else if (!cfdi.pagos.every(c => c.facturaRelacionada.every(d => d.uuid))) { return false; }
    else if (!cfdi.pagos.every(c => c.facturaRelacionada.every(d => d.importePagado))) { return false; }
    return true;
}