import React, { useState, FormEvent, useEffect } from 'react';
import Modal from 'react-modal';

import Input from '../../Forms/Input';
import Select from '../../Forms/Select';

import { StripeCard_SourceCard, StripePaymentOrder } from '../../../models/Stripe';

import './Account.payment.scss';
import Button from '../../Forms/Button';
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../services/graphql.schema';

import * as LogRocket from 'logrocket';
import { formatCurrencyStyled } from '../../visuals/currency.formatted';

import { stripeKey } from '../../../stripeKeys';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '350px',
        border: 'none',
        background: 'transparent',
        perspective: '1000px',
        overflow: 'visible',
    }
};

interface AccountPaymentProps {
    isOpen: boolean;
    paymentMethods: StripeCard_SourceCard[];
    onRequestClose: (response?: StripePaymentOrder) => void;


}

interface AccountPaymentInnerProps extends AccountPaymentProps {
    // Extensions for Inner
    paymentIntent?: StripePaymentOrder;
    setPaymentIntent?: (intent: StripePaymentOrder) => void;
    tryingToPay?: boolean;
    setTryingToPay?: (bool: boolean) => void;
}

const stripePromise = loadStripe(stripeKey);

function AccountPayment({ isOpen, onRequestClose, paymentMethods }: AccountPaymentProps) {

    const [paymentIntent, setPaymentIntent] = useState<StripePaymentOrder>(null)
    const [tryingToPay, setTryingToPay] = useState(false);

    const closePaymentModal = (overridePaymentIntent: StripePaymentOrder = null) => {
        // Override is used as UseMutation doesn't update call's scope
        onRequestClose(overridePaymentIntent || paymentIntent);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => closePaymentModal(paymentIntent)}
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={!tryingToPay}
            shouldCloseOnOverlayClick={!tryingToPay}
            contentLabel="Producto Edit Modal"
        >
            <Elements stripe={stripePromise} >
                <AccountPaymentInner
                    isOpen={isOpen}
                    onRequestClose={onRequestClose}
                    paymentMethods={paymentMethods}
                    paymentIntent={paymentIntent}
                    setPaymentIntent={setPaymentIntent}
                    tryingToPay={tryingToPay}
                    setTryingToPay={setTryingToPay}
                />
            </Elements>
        </Modal>
    )
}

function AccountPaymentInner({ isOpen, onRequestClose, paymentMethods, paymentIntent, setPaymentIntent, tryingToPay, setTryingToPay }: AccountPaymentInnerProps) {

    const stripe = useStripe();

    const [cardPayError, setCardPayError] = useState<{ [key: string]: boolean }>({});
    const [payOutcome, setPayOutcome] = useState<'success' | 'fail'>(null)
    const [payMethod, setPayMethod] = useState<string>('spei');
    const [amount, setAmount] = useState<number | string>(100);

    // const [tryingToPay, setTryingToPay] = useState(false);
    //const [paymentIntent, setPaymentIntent] = useState<StripePaymentOrder>(null)

    useEffect(() => {
        // Either when it closes or when it opens
        if (isOpen) {
            setPayOutcome(null);
            setTryingToPay(false);
            setPaymentIntent(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])


    const closePaymentModal = (overridePaymentIntent: StripePaymentOrder = null) => {
        // Override is used as UseMutation doesn't update call's scope
        onRequestClose(overridePaymentIntent || paymentIntent);
    }

    const [confirmRequiresAction] = useMutation(graphqlSchema.STRIPE.CARDS.confirmRequiredActionIntent, {
        onCompleted: ({ confirmRequiredActionIntent }: { confirmRequiredActionIntent: { order: StripePaymentOrder, intent: { status: StripePaymentOrder['paymentIntentStatus'], client_secret: string } } }) => {
            console.log('Card Requires Auth Confirm: ', confirmRequiredActionIntent);
            if (confirmRequiredActionIntent.order) {
                // If there's an order, this succeeded, only "processing" or "succeeded" arrive here
                setPayOutcome('success');
                setTimeout(() => {
                    closePaymentModal(confirmRequiredActionIntent.order);
                }, 4000)
            } else {
                const cardErr: { [key: string]: boolean } = Object.assign({}, cardPayError);
                cardErr[payMethod] = true;
                setCardPayError(cardErr);
                setPayOutcome('fail');
                setTimeout(() => {
                    closePaymentModal(null);
                }, 4000)
            }
        },
        onError: (e) => {
            const cardErr: { [key: string]: boolean } = Object.assign({}, cardPayError);
            cardErr[payMethod] = true;
            setCardPayError(cardErr);

            setPayOutcome('fail');
            setTimeout(() => {
                closePaymentModal(null);
            }, 4000)
        }
    })

    const [addBalanceWithCard] = useMutation(graphqlSchema.STRIPE.CARDS.requestCardPayment, {
        onCompleted: ({ requestCardPayment }: { requestCardPayment: { order: StripePaymentOrder, intent: { status: StripePaymentOrder['paymentIntentStatus'], client_secret: string } } }) => {
            console.log('Card payment: ', requestCardPayment);
            // >> Handle Possible Requires_action or not
            if (requestCardPayment.order) {
                LogRocket.track('CardPaymentSuccess')
                setPaymentIntent(requestCardPayment.order);
                setTryingToPay(false);
                const status = requestCardPayment.order.paymentIntentStatus
                if (status === 'succeeded' || status === 'processing') {
                    setPayOutcome('success');
                    setTimeout(() => {
                        closePaymentModal(requestCardPayment.order);
                    }, 4000)
                } else {
                    // Everything else should FAIL (including requires_payment_method)
                    const cardErr: { [key: string]: boolean } = Object.assign({}, cardPayError);
                    cardErr[payMethod] = true;
                    setCardPayError(cardErr);
                    setPayOutcome('fail');
                    setTimeout(() => {
                        closePaymentModal(requestCardPayment.order);
                    }, 4000)
                }
            } else {
                // No order Created cases, Only requires_action has a route out
                if (requestCardPayment.intent.status === 'requires_action') {
                    // Try to authorize on requires_action
                    stripe.handleCardAction(requestCardPayment.intent.client_secret)
                        .then(({ error, paymentIntent }) => {
                            if (error) {
                                const cardErr: { [key: string]: boolean } = Object.assign({}, cardPayError);
                                cardErr[payMethod] = true;
                                setCardPayError(cardErr);

                                setPayOutcome('fail');
                                setTimeout(() => {
                                    // Close payment Modal with null;
                                    closePaymentModal(null);
                                }, 4000)
                            } else {
                                // Should arrive here with a "requires confirmation" status
                                console.log('<To_Confirm> paymentIntent: ', paymentIntent);
                                confirmRequiresAction({
                                    variables: {
                                        intentId: paymentIntent.id
                                    }
                                })
                            }
                        })
                    // ----------------------------------
                } else {
                    const cardErr: { [key: string]: boolean } = Object.assign({}, cardPayError);
                    cardErr[payMethod] = true;
                    setCardPayError(cardErr);

                    setPayOutcome('fail');
                    setTimeout(() => {
                        // Close payment Modal with null;
                        closePaymentModal(null);
                    }, 4000)
                }
            }
        },
        onError: (e) => {
            setTryingToPay(false);
            setPaymentIntent(null);
            const cardErr: { [key: string]: boolean } = Object.assign({}, cardPayError);
            cardErr[payMethod] = true;
            setCardPayError(cardErr);
            LogRocket.track('CardPaymentError')
            console.error('Error Adding card payment: ', e);
            console.error(typeof e === 'string' ? JSON.parse(e) : e);
            // Display Failed Error dialog
            setPayOutcome('fail');
            setTimeout(() => {
                // Close payment Modal with null;
                closePaymentModal(null);
            }, 4000)
        }
    });
    const [addBalanceWithSpei] = useMutation(graphqlSchema.STRIPE.SPEI.requestSpeiPayment, {
        onCompleted: ({ requestSpeiPayment }: { requestSpeiPayment: StripePaymentOrder }) => {
            console.log('SPEI payment: ', requestSpeiPayment);
            // UseReducer to update object array
            setPaymentIntent(requestSpeiPayment);
            onRequestClose(requestSpeiPayment);
            setTryingToPay(false);
        },
        onError: (e) => {
            setTryingToPay(false);
            setPaymentIntent(null);
            console.error('Error Adding SPEI: ', e);
        }
    });

    const handleAmountChange = (am: number) => {
        if (isNaN(am)) {
            setAmount('');
        } else {
            setAmount(am);
        }
    }

    const checkBiggerThan = (am: number) => {
        console.log('AM: ', am);
        if (!am) {
            setAmount(100);
        } else if (am < 100) {
            setAmount(100);
        }
    }

    const calculateComission = () => {
        const amountVal = !amount ? 0 : amount as number;
        if (payMethod === 'spei') {
            return (7 * 1.16);
        } else {
            return (3 + (amountVal * .036)) * 1.16;
        }
    }

    const requestMoreCredit = (e: FormEvent) => {
        e.preventDefault();
        setTryingToPay(true);
        if (payMethod === 'spei') {
            addBalanceWithSpei({
                variables: {
                    amount
                }
            })
        } else {
            const selected = paymentMethods.find(pM => pM.id === payMethod)
            console.log('requestCardPayment: ', selected);
            addBalanceWithCard({
                variables: {
                    amount,
                    sourceid: payMethod,
                    funding: selected.funding
                }
            })
        }
    }

    const paymentOptions = paymentMethods.map(m => ({ value: m.id, label: `${m.brand} ${m.funding === 'credit' ? 'crédito' : 'débito'} - ${m.last4}` })).concat([
        { value: 'spei', label: 'Transferencia SPEI' },
    ]);
    const amountVal = !amount ? 0 : amount as number;
    return (
        <div id="AccountPayment" className={payOutcome ? 'turn' : ''}>
            <div className='requestPayment'>
                <div className="title">
                    <h3>Agregar Saldo a tu cuenta</h3>
                </div>
                <form onSubmit={requestMoreCredit}>
                    <p>El saldo agregado <b>no tiene vencimiento</b> y se utilizará para pagar tus gastos de facturación</p>
                    <Select label="Método de pago" value={payMethod} options={paymentOptions} onChange={setPayMethod} />
                    <Input label="Saldo a agregar (mínimo $100)" placeholder="Saldo a agregar" value={amount} onChange={handleAmountChange} onBlur={checkBiggerThan} type="number" />
                    <div className="desglose">
                        <p className="accent">Comisión bancaria:</p>
                        <p className="accent">-$ {calculateComission().toFixed(2)}</p>
                        <p><b>Neto a cargar:</b></p>
                        <p><b>$ {(amountVal - calculateComission()).toFixed(2)}</b></p>
                    </div>
                    <Button primary={true} type="submit" disabled={tryingToPay || (cardPayError[payMethod]) || amountVal < 100 || !amountVal} accent={(cardPayError[payMethod])} >
                        <span>{tryingToPay ? 'Pagando...' : (cardPayError[payMethod] ? 'Error, intenta otra forma de pago' : 'Agregar Saldo')}</span>
                    </Button>
                </form>
            </div>
            <div className={`paymentOutcome ${payOutcome || ''}`}>
                <div>
                    <h1>{formatCurrencyStyled(amountVal)} MXN</h1>
                    {
                        payOutcome === 'success' ?
                            <p>Pago recibido correctamente</p>
                            :
                            <p>Error al procesar Pago</p>
                    }
                    {
                        payOutcome === 'success' ?
                            <span className="material-icons">
                                thumb_up
                            </span>
                            :

                            <span className="material-icons">
                                thumb_down
                            </span>
                    }
                </div>
            </div>
        </div>
    )
}

export default AccountPayment;