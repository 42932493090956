

const recursiveCleanup = (cleanupArray:any[], toClear:string, obj:any) => {
    if (typeof obj === 'object' && !!obj) {
        Object.keys(obj).forEach((k) => {
            if (k === toClear) {
                delete obj[k];
            }else if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
                recursiveCleanup(cleanupArray, toClear, obj[k]);
            } else if(Array.isArray(obj[k])){
                for(const e of obj[k]){
                   recursiveCleanup(cleanupArray, toClear, e);
                }
            }
        })
    }
}


export const clearGqlTypename = (obj:any) => {
    const _obj = JSON.parse(JSON.stringify(obj));
    const TO_CLEAR = '__typename';
    const objectsToClear:any[] = [];
    recursiveCleanup(objectsToClear, TO_CLEAR, _obj);
    return _obj;
}

export const mesesFormat = (_num: string) => {
    const num = parseInt(_num)
    if(num < 9) {
        return `0${num}`
    }
    return `${num}`
}