import React, { useState } from 'react';

import './IconButton.scss';

function IconButton({ primary, secondary, disabled, handleClick, children, type }: {
    primary?: boolean; secondary?: boolean; disabled?: boolean; handleClick?: () => void; children: JSX.Element, type?: 'submit' | 'button'
}) {
    const [active, setActive] = useState(false);
    const handleClickAnimate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if(!!handleClick){
            handleClick();
        }
    }
    
    const handleActiveAnimate = () => {
        setActive(true);
    }

    const handleMouseLeave = () => {
        setActive(false);
    }

    return (
        <button className={`${primary ? 'primary' : ''} ${secondary ? 'secondary' : ''} ${active ? '_active' : ''} __Button __icon`} 
        onMouseUp={handleActiveAnimate} onMouseLeave={handleMouseLeave}
        onClick={handleClickAnimate} type={type || "button"} disabled={disabled}>
            {children}
        </button>
    )
}

export default IconButton;