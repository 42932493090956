import React, { useEffect, useReducer, useState } from 'react';

import './Cotizaciones.generator.scss';


import moment from 'moment'
import CopyToClipboard, { CopyToClipboardChild } from '../../visuals/clipboard.copy';
import { BaseReact } from '../../../base.model';
import { ProfileQL } from '../../../models/Profile';
import SectionTitleBar from '../../components/titlebar';
import AutoComplete from '../../Forms/Autocomplete';
import Input from '../../Forms/Input';
import Select from '../../Forms/Select';
import { Cotizacion } from '../../../models/Cotizaciones';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../services/graphql.schema';
import { Receptor } from '../../../models/Catalogos';
import { clearGqlTypename } from '../../../utils/formatting';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import TextArea from '../../Forms/TextArea';
import FacturarConceptos from '../../Facturar/Factura.conceptos';
import { BillConceptos } from '../../../models/Factura';
import CotizacionTotales from './Cotizaciones.generator.totals';
import { calculateTotalWidthComission } from '../../../utils/calculate.conceptos';
import Checkbox from '../../Forms/Checkbox';
import Button from '../../Forms/Button';
import { validateCotizacionIsComplete } from '../../../services/validation.cotizacion';
import sharedToasterSubject from '../../../services/shared.toasterSubject';

import Modal from 'react-modal';
import { numberToCurrencyString } from '../../../services/formatting';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        border: 'none',
        background: 'transparent',
        boxShadow: 'none',
        padding: '3em'
    }
};
Modal.setAppElement('#root');

moment.locale('es')

interface CotGenProps extends BaseReact {
    currentUser: ProfileQL;
}


const DISCOUNT_OPTIONS = [
    { value: '', label: 'Sin descuentos' },
    { value: 'pronto_pago', label: 'Por pronto pago' },
    { value: 'por_cliente', label: 'Cliente frecuente' },
    { value: 'normal', label: 'Normal' },
]

const DISCOUNT_EXP = [
    { value: 0, label: 'Sin vencimiento' },
    { value: 1, label: '1 Día' },
    { value: 3, label: '3 Días' },
    { value: 5, label: '5 Días' },
    { value: 7, label: '7 Días' },
    { value: 10, label: '10 Días' },
    { value: 15, label: '15 Días' },
    { value: 30, label: '30 Días' },
    { value: 45, label: '45 Días' },
    { value: 60, label: '60 Días' },
]

function cotizacionReducer(state: Cotizacion, { property, value }: { property: 'cliente' | 'conceptos' | 'discount' | 'expiration' | 'transaction' | '_replace', value: any }): Cotizacion {
    if (property === 'cliente') {
        return Object.assign({}, state, value);
    } else if (property === 'conceptos') {
        // 1. Calculate total
        // 2. Check if totals apply for installments,
        const { total } = calculateTotalWidthComission(value, state.feeTransaction);
        return Object.assign({}, state, { conceptos: value, total });
    }
    else if (property === 'discount') {
        if (!!value.discountName) {
            // DiscountName modifier
            if (value.discountName === '') {
                value.discount = 0;
                value.discountFormat = 'percent';
                value.discountExpiration = 0;
            } else if (value.discountName === 'pronto_pago') {
                value.discountExpiration = 10;
            } else if (value.discountName === 'por_cliente') {
                value.discountExpiration = 0;
            } else if (value.discountName === 'normal') {
                value.discountExpiration = 0;
            }
        }
        return Object.assign({}, state, value);
    }
    else if (property === 'expiration') {
        return Object.assign({}, state, { cfdiRelacionados: value });
    }
    else if (property === 'transaction') {
        return Object.assign({}, state, value);
    }
    else if (property === '_replace') {
        return Object.assign({}, state, value);
    }
    else {
        // Force update only
        return Object.assign({}, state);
    }
}

function CotizacionGenerator({ location, currentUser }: CotGenProps) {
    const history = useHistory();

    const [receptorOptions, setReceptorOptions] = useState<Receptor[]>([]);
    const [receptoSubject] = useState(new Subject<string>());
    const [receptoSubjectByRfc] = useState(new Subject<string>());

    const [validRequested, setValidRequested] = useState(false);
    const [validConceptos, setValidConceptos] = useState(false);

    const [cotizacionId, setCotizacionId] = useState<string>('');
    const [generating, isGenerating] = useState(false);

    const [cotizacion, dispatchCotizacion] = useReducer(cotizacionReducer, {
        clientId: '',
        rfc: '',
        name: '',
        email: '',
        expiration: null,
        translateFee: false,
        feeFiscalpop: 0.35,
        feeTransaction: 3.6,
        paymentTermsOffered: 1,
        discount: 0,
        discountName: '',
        discountFormat: 'percent',
        discountExpiration: 0,
        clientAccepted: false,
        vendorAccepted: false,
        note: '',
        total: 0,
        conceptos: [{
            claveProdServ: '',
            claveUnidad: '',
            cantidad: 1,
            descripcion: '',
            valorUnitario: 0,
            descuento: 0,
            pedimento: [],
            noIdentificacion: '',
            impuestos: [{ type: 'iva', tipo: 'Fijo', retencion: false, tasa: 0.16 }]
        }]
    } as Cotizacion);

    const [generateCotizacion] = useMutation(graphqlSchema.FISCALPOP.COTIZACION.generateCotizacion, {
        onCompleted: ({ generateCotizacion }: { generateCotizacion: Cotizacion }) => {
            console.log('Generated cotización: ', generateCotizacion);
            setCotizacionId(generateCotizacion._id);
            isGenerating(false);
            sharedToasterSubject.next({ type: 'confirm', message: `Cotización generada ${generateCotizacion.email ? 'y enviada ' : ''}correctamente` });
        },
        onError: (e) => {
            isGenerating(false);
            console.error('Error generating cotizacion: ', e.graphQLErrors);
            sharedToasterSubject.next({ type: 'error', message: `Error generando cotización: ${e.graphQLErrors[0].message}` });
        }
    })

    const [getByName] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByName, {
        onCompleted: ({ getClienteByName }: { getClienteByName: Receptor[] }) => {
            setReceptorOptions(getClienteByName.map(c => clearGqlTypename(c)));
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })
    const [getByRfc] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByRfc, {
        onCompleted: ({ getClienteByRfc }: { getClienteByRfc: Receptor[] }) => {
            setReceptorOptions(getClienteByRfc.map(c => clearGqlTypename(c)));
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })

    useEffect(() => {
        const s = receptoSubject.pipe(debounceTime(200)).subscribe(s => {
            getByName({
                variables: {
                    name: s
                }
            })
        })
        const s2 = receptoSubjectByRfc.pipe(debounceTime(200)).subscribe(s => {
            getByRfc({
                variables: {
                    rfc: s
                }
            })
        })
        return () => {
            s.unsubscribe();
            s2.unsubscribe();
        }
    }, [receptoSubject, receptoSubjectByRfc, getByName, getByRfc]);


    const handleCotizacionChange = (property: keyof Cotizacion) => {
        return (value: string | number | boolean) => {
            if (property === 'paymentTermsOffered') {
                const _installment = installmentOptions().find(i => i.value === value);
                return dispatchCotizacion({ property: 'transaction', value: { paymentTermsOffered: value as number, feeTransaction: _installment.comission } })
            }
            if (property === 'name') { receptoSubject.next(value as string); return dispatchCotizacion({ property: 'cliente', value: { name: value } }) }
            if (property === 'rfc') { receptoSubjectByRfc.next(value as string); return dispatchCotizacion({ property: 'cliente', value: { rfc: value } }) }
            if (property === 'email') { return dispatchCotizacion({ property: 'cliente', value: { email: (value as string).toLowerCase().trim() } }) }
            if (property === 'note') { return dispatchCotizacion({ property: '_replace', value: { note: value } }) }
            if (property === 'translateFee') { return dispatchCotizacion({ property: 'transaction', value: { translateFee: value as boolean } }) }
            if (property === 'discountName') { return dispatchCotizacion({ property: 'discount', value: { discountName: value } }) }
            if (property === 'discountExpiration') { return dispatchCotizacion({ property: 'discount', value: { discountExpiration: value } }) }
            if (property === 'discount') {
                if ((value as number) > 99) {
                    value = 99;
                }
                if ((value as number) < 0) {
                    value = 0;
                }
                return dispatchCotizacion({ property: 'discount', value: { discount: value } });
            }
        }
    }
    const handleConceptosChange = (conceptos: BillConceptos[]) => {
        dispatchCotizacion({ property: 'conceptos', value: conceptos })

    }
    const handleReceptorAutofill = (receptorAsString: string) => {
        const receptor: Receptor = JSON.parse(receptorAsString);
        console.log('Receptor to add: ', receptor);
        const receptorBlock: Receptor = { name: receptor.name, rfc: receptor.rfc, email: receptor.email, clientId: receptor._id }
        if (receptor.zip) {
            receptorBlock.zip = receptor.zip;
        }
        if (receptor.regimen) {
            receptorBlock.regimen = receptor.regimen;
        }
        dispatchCotizacion({ property: 'cliente', value: receptorBlock });
    }

    const submitCotizacion = () => {
        console.log('Cotizacion to submit: ', cotizacion)
        if (!validateCotizacionIsComplete(cotizacion, validConceptos)) {
            setValidRequested(true);
            return;
        }
        setValidRequested(false);
        isGenerating(true);
        generateCotizacion({
            variables: {
                cotizacion: cotizacion
            }
        })
    }

    const installmentOptions = () => {
        const _options: { value: number, label: string, comission: number }[] = [];
        if (cotizacion.total > 2400) {
            _options.unshift({ value: 12, label: '12 Meses (16.45%)', comission: 16.1 })
        }
        if (cotizacion.total > 1800) {
            _options.unshift({ value: 9, label: '9 Meses (13.95%)', comission: 13.6 })
        }
        if (cotizacion.total > 1200) {
            _options.unshift({ value: 6, label: '6 Meses (11.45%)', comission: 11.1 })
        }
        if (cotizacion.total > 3) {
            _options.unshift({ value: 3, label: '3 Meses (8.95%)', comission: 8.6 })
        }
        _options.unshift({ value: 1, label: 'Un solo pago (3.95%)', comission: 3.6 })
        return _options;
    }

    const closeModal = () => {
        setCotizacionId(null);
        // history.push('/');
        history.push('/cotizaciones/lista');
    }


    const receptorNameOptions = receptorOptions.map(r => ({ label: r.name, value: JSON.stringify(r), template: <div><p className="_big">{r.name}</p><p className="_small">{r.rfc}</p></div> }));
    const receptorRFCOptions = receptorOptions.map(r => ({ label: r.rfc, value: JSON.stringify(r), template: <div><p className="_big">{r.rfc}</p><p className="_small">{r.name}</p></div> }))


    // RENDER METHODS
    // =========================

    const renderGeneratedModal = () => {

        const urlEndpoint = `https://personal.fiscalpop.com/views/cotizaciones/${cotizacionId || ''}`;
        const logoUrl = `${currentUser.fiscalpopProfile.logoUrl}`;
        const feeTotalPercent = (cotizacion.feeFiscalpop + cotizacion.feeTransaction);
        const totalFeeDiscountConsidered = cotizacion.translateFee ? ((cotizacion.total * (1 + (feeTotalPercent / 100))) * (1 - (cotizacion.discount / 100))) : ((cotizacion.total) * (1 - (cotizacion.discount / 100)))

        const cotizacionMessage = `${currentUser.professionalProfile.companyName || currentUser.fiscalpopProfile.nombre}
Cotización de ${cotizacion.conceptos.length > 1 ? cotizacion.conceptos.length + ' productos' : 'producto'} con valor de $${numberToCurrencyString(totalFeeDiscountConsidered)}

${urlEndpoint}?hLogo=${logoUrl}&hCompany=${encodeURI(currentUser.professionalProfile.companyName || currentUser.fiscalpopProfile.nombre)}`;
        return (
            <Modal
                isOpen={!!cotizacionId}
                onRequestClose={closeModal}
                style={customStyles}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                contentLabel="Cotizacion shareable"
            >
                <div id="CotizacionShareDialog">
                    <h2>
                        Comparte tu cotización al cliente
                    </h2>
                    <div className="_shareable">
                        <p>
                            La cotización fue generada {!!cotizacion.email ? <span className="_sent">y enviada al correo {cotizacion.email}</span> : <span className="_notSent">pero no se envió, al no agregar correo</span>}
                        </p>
                        <p>
                            Copia el siguiente link si deseas enviarla por algún otro medio
                        </p>
                        <div className="copyLink">
                            <CopyToClipboard value={`https://personal.fiscalpop.com/views/cotizaciones/${cotizacionId}`} textLabel={`https://personal.fiscalpop.com/views/cotizaciones/${cotizacionId}`} />
                        </div>
                        <div className="shareables">
                            {
                                /*
                                <a href={`https://api.whatsapp.com/send?text=${encodeURI(cotizacionMessage)}`} target="_blank" rel="noopener noreferrer" >
                                */
                            }
                            <a href={`whatsapp://send?text=${encodeURI(cotizacionMessage)}`} target="_blank" rel="noopener noreferrer" >
                                <div className="iconShare">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30px" height="30px" viewBox="0 0 1280.000000 720.000000">
                                        <g transform="translate(-490,1020) scale(0.18,-0.18)" stroke="none">
                                            <path d="M6255 6844 c-540 -35 -1107 -229 -1555 -532 -473 -320 -848 -752 -1091 -1256 -133 -276 -216 -536 -273 -856 -43 -240 -52 -602 -22 -880 40 -374 177 -822 362 -1188 l53 -103 -123 -367 c-68 -202 -191 -570 -274 -818 -84 -249 -152 -459 -152 -469 0 -9 13 -22 29 -28 26 -10 29 -14 24 -45 -6 -32 -5 -34 18 -27 41 13 936 298 1314 420 198 63 368 115 378 115 9 0 52 -17 95 -39 366 -184 756 -294 1171 -332 164 -14 498 -7 659 16 954 132 1766 659 2266 1468 163 264 318 632 401 952 79 307 117 688 96 982 -54 781 -356 1473 -881 2017 -509 527 -1157 853 -1895 952 -108 14 -482 26 -600 18z m391 -684 c357 -29 650 -108 959 -259 419 -206 770 -514 1030 -906 200 -301 323 -625 371 -979 23 -168 23 -508 0 -680 -163 -1209 -1161 -2141 -2372 -2217 -427 -26 -824 44 -1212 214 -107 47 -284 143 -339 183 -17 13 -39 24 -49 24 -9 0 -222 -65 -472 -145 -250 -80 -456 -145 -457 -143 -2 2 62 197 141 433 79 237 144 442 144 458 0 16 -18 53 -44 90 -418 599 -554 1426 -351 2127 45 152 82 245 155 390 200 391 505 732 880 982 473 316 1064 472 1616 428z" />
                                            <path d="M5323 5236 c-23 -7 -56 -23 -75 -34 -51 -32 -199 -190 -245 -262 -147 -229 -180 -534 -92 -832 67 -225 149 -397 299 -629 190 -292 313 -450 510 -653 296 -305 545 -476 927 -635 282 -118 490 -185 607 -197 81 -8 258 20 362 58 144 52 309 168 373 262 64 96 130 313 138 457 l6 95 -31 36 c-22 24 -112 78 -294 176 -432 232 -487 254 -555 218 -17 -8 -81 -73 -141 -143 -178 -207 -215 -243 -245 -243 -38 0 -287 127 -403 205 -135 92 -223 166 -334 281 -132 137 -275 333 -355 486 l-18 36 72 79 c95 101 134 162 172 268 39 108 37 141 -20 290 -51 133 -92 243 -163 434 -58 157 -101 221 -161 240 -57 17 -287 22 -334 7z" />
                                        </g>
                                    </svg>
                                    <p>
                                        Whatsapp App
                                    </p>
                                </div>
                            </a>

                            <a href={`https://web.whatsapp.com/send?text=${encodeURI(cotizacionMessage)}`} target="_blank" rel="noopener noreferrer" >
                                <div className="iconShare">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30px" height="30px" viewBox="0 0 1280.000000 720.000000">
                                        <g transform="translate(-490,1020) scale(0.18,-0.18)" stroke="none">
                                            <path d="M6255 6844 c-540 -35 -1107 -229 -1555 -532 -473 -320 -848 -752 -1091 -1256 -133 -276 -216 -536 -273 -856 -43 -240 -52 -602 -22 -880 40 -374 177 -822 362 -1188 l53 -103 -123 -367 c-68 -202 -191 -570 -274 -818 -84 -249 -152 -459 -152 -469 0 -9 13 -22 29 -28 26 -10 29 -14 24 -45 -6 -32 -5 -34 18 -27 41 13 936 298 1314 420 198 63 368 115 378 115 9 0 52 -17 95 -39 366 -184 756 -294 1171 -332 164 -14 498 -7 659 16 954 132 1766 659 2266 1468 163 264 318 632 401 952 79 307 117 688 96 982 -54 781 -356 1473 -881 2017 -509 527 -1157 853 -1895 952 -108 14 -482 26 -600 18z m391 -684 c357 -29 650 -108 959 -259 419 -206 770 -514 1030 -906 200 -301 323 -625 371 -979 23 -168 23 -508 0 -680 -163 -1209 -1161 -2141 -2372 -2217 -427 -26 -824 44 -1212 214 -107 47 -284 143 -339 183 -17 13 -39 24 -49 24 -9 0 -222 -65 -472 -145 -250 -80 -456 -145 -457 -143 -2 2 62 197 141 433 79 237 144 442 144 458 0 16 -18 53 -44 90 -418 599 -554 1426 -351 2127 45 152 82 245 155 390 200 391 505 732 880 982 473 316 1064 472 1616 428z" />
                                            <path d="M5323 5236 c-23 -7 -56 -23 -75 -34 -51 -32 -199 -190 -245 -262 -147 -229 -180 -534 -92 -832 67 -225 149 -397 299 -629 190 -292 313 -450 510 -653 296 -305 545 -476 927 -635 282 -118 490 -185 607 -197 81 -8 258 20 362 58 144 52 309 168 373 262 64 96 130 313 138 457 l6 95 -31 36 c-22 24 -112 78 -294 176 -432 232 -487 254 -555 218 -17 -8 -81 -73 -141 -143 -178 -207 -215 -243 -245 -243 -38 0 -287 127 -403 205 -135 92 -223 166 -334 281 -132 137 -275 333 -355 486 l-18 36 72 79 c95 101 134 162 172 268 39 108 37 141 -20 290 -51 133 -92 243 -163 434 -58 157 -101 221 -161 240 -57 17 -287 22 -334 7z" />
                                        </g>
                                    </svg>
                                    <p>
                                        Whatsapp Web
                                    </p>
                                </div>
                            </a>
                            <CopyToClipboardChild value={urlEndpoint}>
                                <div className="iconShare">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="25px" height="25px">
                                        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                    </svg>
                                    <p>
                                        Copiar URL
                                    </p>
                                </div>
                            </CopyToClipboardChild>
                        </div>
                    </div>
                    <div className="actions">
                        <Button secondary={true} handleClick={closeModal}>
                            <span>Cerrar</span>
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <div id="CotizacionGenerator">
            <SectionTitleBar currentUser={currentUser} title="Nueva Cotización" />
            <div className="cotizacionesGenContent">
                <div>
                    <h4>Datos Cliente</h4>
                    <div className="card table">
                        <div className="cardBody">
                            <div className="row three">
                                <AutoComplete label="Nombre de cliente" hasError={validRequested && !cotizacion.name} value={cotizacion.name} placeholder="Nombre o Razón social de cliente" type="text"
                                    noChangeOnSelect={true} options={receptorNameOptions} forcePropsValue={true} onChange={handleCotizacionChange('name')} onSelect={handleReceptorAutofill} />

                                <AutoComplete label="RFC (Opcional)" hasError={validRequested && !cotizacion.rfc} value={cotizacion.rfc} placeholder="RFC de receptor" type="text"
                                    noChangeOnSelect={true} options={receptorRFCOptions} forcePropsValue={true} onChange={handleCotizacionChange('rfc')} onSelect={handleReceptorAutofill} />
                                <Input label="Correo (Opcional, se envia automáticamente)" value={cotizacion.email} onChange={handleCotizacionChange('email')} type="text" placeholder="Email" />
                            </div>
                            <div className="row">
                                <TextArea label="Descripción de la cotización" value={cotizacion.note} onChange={handleCotizacionChange('note')} placeholder={`Descripción, acuerdos o términos.`} />
                            </div>
                        </div>
                    </div>
                </div>
                <FacturarConceptos
                    conceptos={cotizacion.conceptos}
                    validRequested={validRequested}
                    authToken={currentUser.fiscalpopProfile.authToken}
                    onCfdiChange={handleConceptosChange}
                    onValidationTest={setValidConceptos}
                />
                <div>
                    <h4>Condiciones de Pago</h4>
                    <div className="card table">
                        <div className="cardBody">
                            <div className="row two">
                                <Select label="Plazo a Meses" value={cotizacion.paymentTermsOffered} onChange={handleCotizacionChange('paymentTermsOffered')} options={installmentOptions()} />
                                <div className="row two">
                                    <p className="asLabel">
                                        Comisión tarjeta{cotizacion.paymentTermsOffered > 1 ? ' a plazos' : ''} {(cotizacion.feeTransaction + cotizacion.feeFiscalpop).toFixed(2)}%
                                    </p>
                                    <Checkbox label="Cobrar comisión a cliente" value={cotizacion.translateFee} onChange={handleCotizacionChange('translateFee')} />
                                </div>
                            </div>
                            <div className="row two">
                                <Select label="¿Descuentos?" value={cotizacion.discountName} onChange={handleCotizacionChange('discountName')} options={DISCOUNT_OPTIONS} />
                                <div className="row two">
                                    <Input type="number" disabled={!cotizacion.discountName} value={cotizacion.discount} label="Monto de descuento" onChange={handleCotizacionChange('discount')} isPercent={true} placeholder="0%" min={0} max={99} step={0.5} />
                                    <Select label="Vigencia del descuento" disabled={!cotizacion.discountName} value={cotizacion.discountExpiration} onChange={handleCotizacionChange('discountExpiration')} options={DISCOUNT_EXP} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CotizacionTotales cotizacion={cotizacion} />
                <div className="actions">
                    {
                        validRequested && !validConceptos ?
                            <p className="message error">
                                <span className="error">
                                    Conceptos con claves de producto o unidad inválidas
                                </span>
                            </p>
                            :
                            (
                                validRequested ?
                                    <p className="message error">
                                        <span className="error">
                                            Algunos campos faltan de llenarse o contienen errores
                                        </span>
                                    </p>
                                    : ''
                            )
                    }
                    <Button secondary={true} disabled={generating}>
                        <span>
                            Limpiar
                        </span>
                    </Button>
                    <Button primary={true} handleClick={submitCotizacion} disabled={generating}>
                        <span>
                            Emitir y compartir cotización
                        </span>
                    </Button>
                </div>
            </div>
            {renderGeneratedModal()}
        </div>
    )
}

export default CotizacionGenerator;