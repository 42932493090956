import React, { useEffect, useState } from 'react';
import { ProfileQL, LinkerAccount } from '../../models/Profile';


import './accountLinker.dialog.scss'
import sharedLinkerSubject from '../../services/shared.linkerSubject';


const iconMap = {
    mercadolibre: 'https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/ml-icon.png',
    amazon: 'https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/amazon-icon.png',
    claroshop: 'https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/claro-icon.png',
    fiscalpop: 'https://misventas-static.nyc3.digitaloceanspaces.com/LogoSmall.png',
    izettle: 'https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/izettle-icon.png',
    tiendanube: 'https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/TiendaNube.png',
}


function AccountSwitch({ currentUser }: { currentUser: ProfileQL }) {
    // AUTO LINK
    const [accounts, setAccounts] = useState<LinkerAccount[]>([]);
    // ---------

    // Linker Options for modal
    useEffect(() => {
        if (!!currentUser.professionalProfile.linkerId) {
            fetch(`https://binding.fiscalpop.com/app/sessions/linked/${currentUser.professionalProfile.linkerId}`, { credentials: 'include' })
                .then(r => r.json())
                .then((linkerAccounts: LinkerAccount[]) => {
                    const otherAccounts = linkerAccounts.filter(acc => !(acc.email === currentUser.professionalProfile.email && acc.origin === 'fiscalpop'));
                    console.log('(AccountSwitch) Other Accounts: ', otherAccounts);
                    setAccounts(otherAccounts);
                })
                .catch(e => null)
        }
    }, [currentUser.professionalProfile.email, currentUser.professionalProfile.linkerId]);

    useEffect(() => {
        const subs = sharedLinkerSubject.subscribe((linkerId) => {
            // When linker is updated but current profile already has it, it just updates
            fetch(`https://binding.fiscalpop.com/app/sessions/linked/${linkerId}`, { credentials: 'include' })
                .then(r => r.json())
                .then((linkerAccounts: LinkerAccount[]) => {
                    const otherAccounts = linkerAccounts.filter(acc => !(acc.email === currentUser.professionalProfile.email && acc.origin === 'fiscalpop'))
                    setAccounts(otherAccounts);
                })
                .catch(e => null)
        });
        return () => {
            subs.unsubscribe();
        }

    }, [currentUser])


    const baseUrlByOrigin = (origin: LinkerAccount['origin']) => {
        if (origin === 'amazon') { return 'https://mws.fiscalpop.com/ordenes' } else
            if (origin === 'izettle') { return 'https://zettle.fiscalpop.com/app/ordenes' } else
                if (origin === 'tiendanube') { return 'https://tiendanube.fiscalpop.com/app/ordenes' } else
                    if (origin === 'claroshop') { return 'https://claro.fiscalpop.com/ordenes' } else
                        if (origin === 'mercadolibre') { return 'https://mercadolibre.fiscalpop.com/app/ordenes' } else { return 'https://personal.fiscalpop.com/dashboard' }
    }


    const goToAccount = (acc: LinkerAccount) => {
        return () => {
            console.log('Navigate to: ', acc);
            const baseUrl = baseUrlByOrigin(acc.origin);
            const query = `token=${acc.token}&linker=${currentUser.professionalProfile.linkerId}&${acc.tokenKey}=${atob(acc.token)}`;
            window.location.assign(`${baseUrl}?${query}`);
        }
    }

    const renderCurrentLinker = () => {
        const rfcState = !!currentUser.fiscalpopProfile ? currentUser.fiscalpopProfile.rfc : 'Sin RFC';
        return (
            <div className="currentProfile">
                <div className="_avatar">
                    {
                        !!currentUser?.fiscalpopProfile?.logoUrl ?
                            <img src={currentUser.fiscalpopProfile.logoUrl} alt="logo" />
                            :
                            <img src="https://misventas-static.nyc3.digitaloceanspaces.com/LogoSmall.png?i=r0b6s6cef28" alt="logo" />
                    }
                </div>
                <div className="profileInfo">
                    <h3 className="_origin">{rfcState}</h3>
                    <p className="_chip">
                        FiscalPOP
                    </p>
                    <p className="small">{currentUser.professionalProfile.email}</p>
                </div>
            </div>
        )
    }


    const renderOtherAccounts = () => {
        return accounts.map((acc, i) => {
            return (
                <div className="accountOption" onClick={goToAccount(acc)} key={i}>
                    <div className="_icon">
                        <img src={iconMap[acc.origin]} alt="logo plataforma" />
                    </div>
                    <div className="info">
                        <p className="_origin">{acc.origin}</p>
                        <p className="small">{acc.email}</p>
                    </div>
                </div>
            )
        })
    }

    return (
        <div id="AccountLink">
            {renderCurrentLinker()}
            {renderOtherAccounts()}
        </div>
    )
}


export default AccountSwitch;