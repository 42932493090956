import React from 'react';
import { NavLink } from 'react-router-dom';

import './Catalogo.menu.scss';

function CatalogoMenu(){
    return (
        <div className='catalogNavigation'>
            <NavLink to="/catalogos/clientes" activeClassName="active">
                Clientes
                <span className="_border" />
            </NavLink>
            <NavLink to="/catalogos/conceptos" activeClassName="active">
                Conceptos
                <span className="_border" />
            </NavLink>
        </div>
    )
}

export default CatalogoMenu;