import React, { useEffect, useState } from 'react';

import './Setup.certificates.scss';
import Input from '../Forms/Input';
import { FiscalPopClientStatus, FiscalpopProfile } from '../../models/Profile';
import GraphQlClient from '../../services/graphql';
import sharedToasterSubject from '../../services/shared.toasterSubject';

import Button from '../Forms/Button';
import UploadButton from '../Forms/Input.buttonUpload';
import unifiedMoment from '../../services/unifiedMoment';

interface SetupUploadProps {
    fiscalpopProfileStatus: FiscalPopClientStatus;
    fiscalpopProfile: FiscalpopProfile;
}

function SetupCertificate({ fiscalpopProfileStatus, fiscalpopProfile, }: SetupUploadProps) {

    const [certFile, setCertFile] = useState<File>(null);

    const [keyFile, setKeyFile] = useState<File>(null);
    const [keyPassword, setKeyPassword] = useState('');
    const [keyError, setKeyError] = useState('');
    const [cerError, setCerError] = useState('');

    // UI For file uploads
    const [cerIsUploading, setCerIsUploading] = useState(false);
    const [keyIsUploading, setKeyIsUploading] = useState(false);

    // #SetupCertificates
    useEffect(() => {
        const showCerts = window.location.search.includes('showcerts')
        if (showCerts) {
            document.getElementById('SetupFiscalPop').scrollTo(0, document.getElementById('SetupCertificates').scrollHeight)
        }
    }, []);

    const submitCertFile = () => {
        if (!certFile) {
            return;
        }
        // Fill multiform data
        let fileBrowserSupport: File = null;
        try {
            fileBrowserSupport = new File([certFile], `${fiscalpopProfile.rfc}.cer`, { type: 'application/pkix-cert' });
        } catch (e) {
            let blobFile: any = new Blob([certFile], { type: 'application/pkix-cert' });
            blobFile.name = `${fiscalpopProfile.rfc}.cer`;
            fileBrowserSupport = blobFile as File;
        }
        const formData = new FormData();
        formData.append('upload', fileBrowserSupport);
        setCerIsUploading(true);
        return fetch(`https://api.fiscalpop.com/api/v1/upload/cer/${fiscalpopProfile.authToken}`, {
            method: 'POST',
            credentials: 'omit',
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw response.text();
            }
        })
            .then((body) => {
                setCerError('')
                setCerIsUploading(false);
                GraphQlClient.recallCurrentUser();
                sharedToasterSubject.next({ type: 'confirm', message: `Certificado CSD cargado correctamente` });
                return body;
            })
            .catch(errProm => {
                return errProm.then((err: string) => {
                    setCerIsUploading(false);
                    console.log('Cert submit err: ', err);
                    sharedToasterSubject.next({ type: 'error', message: err });
                    if (err.includes('no pertenece al RFC del cliente')) {
                        const _certErrorRFC = err.split(')')[0].split('(')[1].replace(' ', '');
                        console.log('certErrorRFC: ', _certErrorRFC);
                    }
                    setCerError(err)

                })
                    .catch(() => null as any);
            });

    }

    const submitKeyFile = () => {

        if (!keyPassword || !keyFile) {
            return;
        }
        // Fill multiform data
        let fileBrowserSupport: File = null;
        try {
            fileBrowserSupport = new File([keyFile], `${fiscalpopProfile.rfc}.key`, { type: 'application/pkcs8' });
        } catch (e) {
            let blobFile: any = new Blob([keyFile], { type: 'application/pkcs8' });
            blobFile.name = `${fiscalpopProfile.rfc}.key`;
            fileBrowserSupport = blobFile as File;
        }
        const formData = new FormData();
        formData.append('upload', fileBrowserSupport);
        formData.append('password', keyPassword);
        setKeyIsUploading(true);
        return fetch(`https://api.fiscalpop.com/api/v1/upload/key/${fiscalpopProfile.authToken}`, {
            method: 'POST',
            credentials: 'omit',
            body: formData
        }).then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const errText = await response.text();
                throw errText;
            }
        })
            .then((body) => {
                setKeyIsUploading(false);
                setKeyError('');
                setKeyPassword('');
                GraphQlClient.recallCurrentUser();
                console.log('Key upload body: ', body);
                sharedToasterSubject.next({ type: 'confirm', message: `Llave CSD cargado correctamente` });
                return body;
            })
            .catch(err => {
                setKeyError(err);
                setKeyIsUploading(false);
                console.error('Key Upload error: ', err);
                sharedToasterSubject.next({ type: 'error', message: err });
                return null;
            });
    }

    const handleKeyFile = (file: File) => {
        setKeyFile(file);
    }

    const handleCertFile = (file: File) => {
        setCertFile(file);
    }

    const handleKeyPassword = (password: string) => {
        setKeyPassword(password);
    }

    const onUpdateCert = () => {
        submitCertFile()
    }
    const onUpdateKey = () => {
        if (!keyPassword) {
            return
        }
        submitKeyFile()
    }

    const renderCertStatus = () => {
        const [status, statusColor] = fiscalpopProfileStatus.modulusMatch ? ['Certificado Válido', '_green'] :
            (cerError ? [cerError, '_red'] :
                (!fiscalpopProfileStatus.modulusMatch && fiscalpopProfileStatus.cerModulus && !fiscalpopProfileStatus.keyModulus ? ['Falta Llave', '_orange'] :
                    (!fiscalpopProfileStatus.modulusMatch && fiscalpopProfileStatus.cerModulus && fiscalpopProfileStatus.keyModulus ? ['Llave no pertenece a Certificado', '_red'] : ['N / A', ''])
                )
            )
        const [cad, cadColor] = (!!fiscalpopProfileStatus.notAfter && ((new Date(fiscalpopProfileStatus.notAfter).getTime() - (1000 * 60 * 60 * 24)) < Date.now())) ?
            ['Certificado Vencido', '_red'] : (
                !!fiscalpopProfileStatus.notBefore && (new Date(fiscalpopProfileStatus.notBefore).getTime() > Date.now()) ?
                    ['Inicio de vigencia', '_orange'] : (
                        !!fiscalpopProfileStatus.notAfter && !!fiscalpopProfileStatus.notBefore ?
                            [`Válido hasta ${unifiedMoment(new Date(fiscalpopProfileStatus.notAfter).getTime()).format('MMMM DD, YYYY')}`, '_green'] :
                            ['N / A', '']
                    )
            )
        return (
            <div className='row three _cerLabels'>
                <div className='start '>
                    <p className='_label small'>
                        Número de serie del certificado
                    </p>
                    <p className='small'>
                        {fiscalpopProfileStatus.cerNumber || 'N / A'}
                    </p>
                </div>
                <div className='start'>
                    <p className='_label small'>
                        Status
                    </p>
                    <p className={`small ${statusColor}`}>
                        {status || 'N / A'}
                    </p>
                </div>
                <div className='start'>
                    <p className='_label small'>
                        Caducidad
                    </p>
                    <p className={`small ${cadColor}`}>
                        {cad || 'N / A'}
                    </p>
                </div>
            </div>
        )
    }

    const renderKeyStatus = () => {
        const [status, statusColor] = fiscalpopProfileStatus.modulusMatch ? ['Llave Válido', '_green'] :
            (keyError ? [keyError, '_red'] :
                (!fiscalpopProfileStatus.modulusMatch && fiscalpopProfileStatus.cerModulus && !fiscalpopProfileStatus.keyModulus ? ['Falta Certificado', '_orange'] :
                    (!fiscalpopProfileStatus.modulusMatch && fiscalpopProfileStatus.cerModulus && fiscalpopProfileStatus.keyModulus ? ['No pertenece a Certificado', '_red'] : ['N / A', ''])
                )
            )

        return (
            <div className='row three md-two xs-one'>
                <div className='start centerY'>
                    <p className='_label small'>
                        Status
                    </p>
                    <p className={`small ${statusColor}`}>
                        {status || 'N / A'}
                    </p>
                </div>
                <Input placeholder='Contraseña de tu llave' label='Contraseña de tu llave' type='text' onChange={handleKeyPassword} value={keyPassword} hasError={!!keyError} errorLabel={keyError} />
            </div>
        )
    }

    return (

        <div id="SetupCertificates">
            <h4>Certificado CSD</h4>
            {renderCertStatus()}
            <div className="fileBlock">
                <UploadButton placeholder='Certificado Digital (.cer)' label='Sube el certificado de tu CSD (.cer)' accept={'.cer'} onChange={handleCertFile} value={null} filenameTrim={31} disabled={cerIsUploading} />
                <Button handleClick={onUpdateCert} disabled={!certFile} primary={true}>
                    <span>
                        {
                            cerIsUploading ?
                                'Cargando...'
                                : 'Actualizar'
                        }
                    </span>
                </Button>
            </div>
            <h4>Llave CSD</h4>
            {renderKeyStatus()}
            <div className="fileBlock">
                <UploadButton placeholder='Llave de tu CSD (.key)' label='Sube tu Llave CSD (.key)' accept={'.key'} onChange={handleKeyFile} value={null} filenameTrim={31} disabled={keyIsUploading} />
                <Button handleClick={onUpdateKey} disabled={!keyFile || !keyPassword} primary={true}>
                    <span>
                        {
                            keyIsUploading ?
                                'Cargando...'
                                : 'Actualizar'
                        }
                    </span>
                </Button>
            </div>
        </div>
    )
}

export default SetupCertificate;