import React from "react";
import { useHistory } from "react-router";
import { Cotizacion } from "../../models/Cotizaciones";
import Button from "../Forms/Button";

import './Cotizaciones.status.scss';

function CotizacionesStatusLabel({ cotizacion }: { cotizacion: Cotizacion }) {
    const history = useHistory();

    const goToCotizacion = (cotizacionId: string) => {
        return () => {
            history.push({
                pathname: `/cotizacion/${cotizacionId}`
            })
        }
    }

    return (
        <Button setClass={`${cotizacion.status}`} handleClick={goToCotizacion(cotizacion._id)}>
            <span className="micro">
                {cotizacion.status}
            </span>
        </Button>
    )
}

export default CotizacionesStatusLabel;