import React, { useEffect } from 'react';

import './RadioCheck.scss';

interface CheckBoxProps {
    onChange: (bool:boolean) => void;
    label?: string;
    value: boolean;
    disabled?: boolean;
    color?:string;
}

function RadioCheck({onChange, label, value, disabled, color}:CheckBoxProps){
    const checkRef = React.createRef<HTMLDivElement>();
    const toggleValue = () => {
        if(disabled){
            return;
        }else{
            onChange(!value);
        }
    }

    useEffect(() => {
        checkRef.current.style.setProperty("--checkbox-color", color || '#AC2E78')
    }, [checkRef, color]);

    return (
        <div className={`__Radiocheck ${disabled ? 'disabled' : ''}`}>
            <div ref={checkRef} className={`check ${value ? 'checked' : 'no'}`} onClick={toggleValue}>
                <div className='_radio'/>
            </div>
            <p>
                {label}
            </p>
        </div>
    )
}

export default RadioCheck;