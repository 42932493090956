import React, { useState, useEffect } from 'react';

import './ToasterNotifications.scss';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import ToasterNotificationEntry from './ToasterNotifications.toast';

export default function ToasterNotifications() {
    const [notificationSub] = useState(sharedToasterSubject);
    const [toaster, setToaster] = useState<{ type: 'warning' | 'confirm' | 'error', message: string, clearTs?: number, clickToClose?: boolean, id?: string }[]>([]);

    useEffect(() => {
        const subs = notificationSub.subscribe((toast) => {
            toast.id = Math.random().toString(32).split('.')[1];
            setToaster(toaster => toaster.concat([toast]));
            // setCurrentToast(currId => !currId ? toast.id : currId);
        })

        return () => {
            subs.unsubscribe()
        }
    }, [notificationSub])

    const onToastDelete = (id: string) => {
        setToaster(toaster => toaster.filter(_t => _t.id !== id))
    }
    
    return (
        <div id="TOASTER">
            
            {
                toaster.map((toast) => (
                    <ToasterNotificationEntry 
                        {...toast}
                        onDelete={onToastDelete}
                        key={toast.id}
                    />
                ))
            }
        </div>
    )
}