import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { ProfileQL } from '../../models/Profile';
import { graphqlSchema } from '../../services/graphql.schema';
import Button from '../Forms/Button';

import './Setup.stripe.account.scss';

interface SetupProps  {
    currentUser: ProfileQL;
}


// Registration submitted
// ====================================
function StripeAccountRegistered({ currentUser }: { currentUser: ProfileQL }) {
    const [registerStripeAcc] = useMutation(graphqlSchema.PROFILE.SETUP.registerStripeClient, {
        onCompleted: ({ registerStripeClient }: { registerStripeClient: string }) => {
            console.log('registerStripeClient: ', registerStripeClient);
            window.location.href = registerStripeClient;
        },
        onError: (e) => {
            console.error('Error registering stripe: ', e);
        }
    })

    const registerStripe = () => {
        registerStripeAcc({})
    }

    const goToStripe = () => {
        window.open('https://dashboard.stripe.com/', '_blank');
    }

    const requirementsMissing = currentUser.stripeAccount.requirements.currently_due.length;
    const verificationDone = !currentUser.stripeAccount.requirements.pending_verification.length;
    const actionsNeeded = requirementsMissing || !verificationDone;
    return (
        <div id="stripeRegistered">
            {
                requirementsMissing ?
                    <h2>
                        Falta un par de cosas de llenar de tu cuenta
                    </h2>
                    :
                    (
                        !verificationDone ?
                            <h2>
                                Algunos documentos estan por verificarse
                            </h2>
                            :
                            <h2>
                                Tu cuenta esta lista!
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                            </h2>
                    )
            }
            {
                !!requirementsMissing ?
                    <div className="_pending">

                        <h4>
                            Los siguientes registros necesitan completarse:
                        </h4>
                        <ul>
                            {currentUser.stripeAccount.requirements.currently_due.map(s => (
                                <li key={s}>
                                    {s.split('.').join(' ')}
                                </li>
                            ))}
                        </ul>
                    </div>
                    : ''
            }
            {
                !verificationDone ?
                    <div className="_pending">
                        <h4>
                            Los siguientes registros están en validación:
                        </h4>
                        <ul>
                            {currentUser.stripeAccount.requirements.pending_verification.map(s => (
                                <li key={s}>
                                    {s.split('.').join(' ')}
                                </li>
                            ))}
                        </ul>
                    </div>
                    : ''
            }
            {
                actionsNeeded ?
                    <p>
                        Una vez terminen los pasos anteriores tu cuenta estará lista para cobrar a tus clientes con tarjera las cotizaciones que les envíes así como complementos de pago.
                </p>
                    :
                    <p>
                        <b>Tus clientes pueden ahora pagarte tus cotizaciones con tarjeta</b> <br /><br />
                    Tus cotizaciones ahora contienen una liga de pago para poder cobrarlas a tus clientes.
                </p>
            }
            <div className="row two _stripeActions">
                {
                    actionsNeeded ?
                        <Button primary={true} handleClick={registerStripe}>
                            <span>
                                Completar registro de stripe
                            </span>
                        </Button>
                        :
                        <Button primary={true} handleClick={goToStripe} setClass="_completed">
                            <span>
                                Ir a tu perfil de stripe
                            </span>
                        </Button>

                }
                <img src="/stripe_logo_slate_small.png" alt="stripe logo" id="_stripeLogo"/>
            </div>
        </div>
    )
}


// FIRST REGISTRATION
// ====================================
function StripeAccountToLink({ currentUser }: { currentUser: ProfileQL }) {
    const [registerStripeAcc] = useMutation(graphqlSchema.PROFILE.SETUP.registerStripeClient, {
        onCompleted: ({ registerStripeClient }: { registerStripeClient: string }) => {
            console.log('registerStripeClient: ', registerStripeClient);
            window.location.href = registerStripeClient;
        },
        onError: (e) => {
            console.error('Error registering stripe: ', e);
        }
    })

    const registerStripe = () => {
        registerStripeAcc({})
    }

    return (
        <div id="stripeIsEmpty">
            <h2>
                No has configurado tu cuenta para recibir pagos!
        </h2>
            <p>
                ¡Configura tu perfil de Stripe para que tus clientes puedan pagar tus cotizaciones y complementos de pago con tarjeta!
        </p>
            <p>
                Podrás mandar tus cotizaciones con una forma de pago de tarjeta, tu cotización se convertirá en factura una vez pagada y serás notificado por correo y por FisccalPOP.
        </p>
            <div className="row two _stripeActions">
                <Button primary={true} handleClick={registerStripe}>
                    <span>
                        Activar cobros con tarjeta
                </span>
                </Button>
                <img src="/stripe_logo_slate_small.png" alt="stripe logo" id="_stripeLogo"/>
            </div>
        </div>
    )
}


function StripeAccount({ currentUser }: SetupProps) {
    if (!currentUser.stripeAccount) {
        return <StripeAccountToLink currentUser={currentUser} />
    }
    if (currentUser.stripeAccount && !currentUser.stripeAccount.details_submitted) {
        return <StripeAccountToLink currentUser={currentUser} />
    }
    return <StripeAccountRegistered currentUser={currentUser} />
}


export default StripeAccount;