import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../../base.model';
import { BankBudgets } from '../../../models/Administracion/Bank.Budgets';
import { BankMovementSummary } from '../../../models/Administracion/Bank.Transactions';
import { ProfileQL } from '../../../models/Profile';
import { graphqlSchema } from '../../../services/graphql.schema';
import unifiedMoment from '../../../services/unifiedMoment';
import SectionTitleBar from '../../components/titlebar';
import PresupuestosDetailsList from './DetailsList/Presupuestos.DetailsList';

import PresupuestosOverview from './Overview/Presupuestos.Overview';
import PresupuestosOverviewGraph from './Overview/Presupuestos.Overview.graph';

import './Presupuestos.scss'

interface PresupuestosProps extends BaseReact {
    currentUser: ProfileQL;
}

function Presupuestos({ currentUser, location, history }: PresupuestosProps) {

    const [monthChoosen, setMonthChoosen] = useState(unifiedMoment().startOf('month').valueOf())
    const [budgets, setBudgets] = useState<BankBudgets[]>([])
    const [banksSummary, setBanksSummary] = useState<BankMovementSummary[]>([])

    const [fetchMovements] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankMovementsSummary, {
        onCompleted: ({ getBankMovementsSummary }: { getBankMovementsSummary: BankMovementSummary[] }) => {
            const _getBankMovementsSummary: BankMovementSummary[] = JSON.parse(JSON.stringify(getBankMovementsSummary))
            console.log(`(Presupuestos) <getBankMovementsSummary> `, _getBankMovementsSummary);
            setBanksSummary(_getBankMovementsSummary);
        },
        fetchPolicy: 'cache-and-network'
    })

    const [fetchBudgets] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBudgets, {
        onCompleted: ({ getBudgets }: { getBudgets: BankBudgets[] }) => {
            console.log(`(Presupuestos) <getBudgets>: `, getBudgets);
            setBudgets(getBudgets)
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        fetchMovements({
            variables: {
                start: monthChoosen,
                end: unifiedMoment(monthChoosen).endOf('month').valueOf()
            }
        })
        fetchBudgets({
            variables: {
                monthTs: monthChoosen,
            }
        })
    }, [monthChoosen, fetchMovements, fetchBudgets])

    return (
        <div id="Presupuestos">
            <SectionTitleBar currentUser={currentUser} title={`Resumen de Presupuestos`} />
            <div className="presupuestosContent">
                <div className='presupuestosOverviewSection'>
                    <PresupuestosOverview history={history} budgets={budgets} banksSummary={banksSummary} />
                    <PresupuestosOverviewGraph budgets={budgets} monthChosen={monthChoosen} onMonthChange={setMonthChoosen} banksSummary={banksSummary} />
                </div>
                <div className='presupuestosDetailsSection'>
                    <h4>Gastos sobre presupuestos asignados</h4>
                    <PresupuestosDetailsList history={history} budgets={budgets} banksSummary={banksSummary} />
                </div>
            </div>
        </div>
    )
}

export default Presupuestos;