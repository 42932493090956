import React, { useState } from 'react';

import Modal from 'react-modal';
import Button from '../../Forms/Button';

import './CartaPorte.info.whatIs.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px 0px 6px',
        border: 'none',
        borderRadius: '12px',
        boxShadow: `0px 4px 16px rgba(0, 60, 128, 0.1)`,
    }
};

export function CartaPorteInfoWhatIsDialog() {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const close = () => {
        setIsOpen(false);
    }
    const open = () => {
        setIsOpen(true);
    }
    return (
        <div className='_CartaPorteWhatIsDialog'>
            <div className='textIconTrigger' onClick={open}>
                <p>
                    <b>
                        ¿Qué es una Carta Porte?
                    </b>
                </p>
                <i className='material-icons'>help_outline</i>
            </div>
            <Modal
                isOpen={isOpen}
                onRequestClose={close}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="card table" id="CartaPorteWhatIsDialog">
                    <div className="cardTitle">
                        <h4>¿Qué es una carta porte y para qué sirve?</h4>
                    </div>
                    <div className="cardBody">
                        <div className='_svgHolder'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="249" height="88" viewBox="0 0 249 88" fill="none">
                                <path d="M39.4866 5H115.529V66.62H39.4866V5Z" stroke="#AC2E78" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M39.4866 28.7002H19.2086L4 42.9202V66.6202H39.4866V28.7002Z" stroke="#AC2E78" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M94.4063 84.0001C89.2733 84.0001 85.1122 80.1095 85.1122 75.3101C85.1122 70.5108 89.2733 66.6201 94.4063 66.6201C99.5393 66.6201 103.7 70.5108 103.7 75.3101C103.7 80.1095 99.5393 84.0001 94.4063 84.0001Z" stroke="#AC2E78" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M24.278 84.0001C19.145 84.0001 14.9838 80.1095 14.9838 75.3101C14.9838 70.5108 19.145 66.6201 24.278 66.6201C29.411 66.6201 33.5721 70.5108 33.5721 75.3101C33.5721 80.1095 29.411 84.0001 24.278 84.0001Z" stroke="#AC2E78" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M94.376 20.2236H168.729C173.841 20.2236 178.023 23.3604 178.023 27.1942V69.0177C178.023 72.8516 173.841 75.9883 168.729 75.9883H94.376C89.2643 75.9883 85.0819 72.8516 85.0819 69.0177V27.1942C85.0819 23.3604 89.2643 20.2236 94.376 20.2236Z" fill="white" stroke="#AC2E78" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M178.023 27.1943L131.552 51.5914L85.0818 27.1943" fill="white" />
                                <path d="M178.023 27.1943L131.552 51.5914L85.0818 27.1943" stroke="#AC2E78" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M187.792 63H211.792" stroke="#AC2E78" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M219.792 34H243.792" stroke="#AC2E78" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M216.792 47H219.792" stroke="#AC2E78" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M194.792 47H204.792" stroke="#AC2E78" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M195.792 20H192.792" stroke="#AC2E78" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M230.792 20H206.792" stroke="#AC2E78" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M177.792 5H193.792" stroke="#AC2E78" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className='_textHolder'>
                            <p className='gray'>
                                <b>Para Servicios de Autotransporte y Ligística</b><br />
                                Es un documento fiscal digital, que se emite por la prestación de servicios de autotransporte, tiene como objetivo manifestar la obtención del ingreso, y la mercancía trasladada por el cual se obtiene el mismo.
                            </p>
                            <p className='gray'>
                                <b>Para movimiento de tus propios productos</b><br />
                                Es un documento fiscal digital, que se emite para el traslado de mercancías de un lugar a otro, tiene como objetivo manifestar la propiedad de los <b>productos durante su traslado en vias federales</b>. 
                            </p>
                            <br />
                            <p className='gray'>
                                La utilidad de la carta porte es:
                            </p>
                            <ul>
                                <li>
                                    <p className='gray'>
                                        Identificación de mercancías
                                    </p>
                                </li>
                                <li>
                                    <p className='gray'>
                                        Prevención de riesgos durante el recorrido
                                    </p>
                                </li>
                                <li>
                                    <p className='gray'>
                                        Demostrar la legalidad del contrato de translado
                                    </p>
                                </li>
                                <li>
                                    <p className='gray'>
                                        Como factura a la empresa exportadora
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className='row'>
                            <div className='row center centerY'>
                                <Button primary={true} handleClick={close}>
                                    <span>
                                        Entendido
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}