import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../../base.model';
import { BankBudgets, BankBudgetsActivity } from '../../../models/Administracion/Bank.Budgets';
import { ProfileQL } from '../../../models/Profile';
import { graphqlSchema } from '../../../services/graphql.schema';
import sharedToasterSubject from '../../../services/shared.toasterSubject';
import SectionTitleBar from '../../components/titlebar';
import { CATEGORIES_LIST } from '../helpers/categories.list';
import PresupuestosConfigureDetails from './Details/PresupuestosConfigure.Details';

import './PresupuestosConfigure.scss'
import PresupuestosConfigureSummary from './Summary/PresupuestosConfigure.Summary';

interface PresupuestosConfigureProps extends BaseReact {
    currentUser: ProfileQL;
}

const categoryOptions = CATEGORIES_LIST.map(c => ({
    label: c.label,
    value: c.value
}))

function PresupuestosConfigure({ currentUser, location, history }: PresupuestosConfigureProps) {

    const { category } = (location.search || '').replace('?', '').split('&').reduce((p, c) => {
        const [key, value] = c.split('=');
        p[key] = value;
        return p
    }, {} as { [key: string]: string });

    const [onCategory, setOnCategory] = useState(category)
    const [subcatActivities, setSubcatActivities] = useState<BankBudgetsActivity[]>([])

    const [getCategoryBudget] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBudgetActivitiesByCategory, {
        onCompleted: ({ getBudgetActivitiesByCategory }: { getBudgetActivitiesByCategory: BankBudgetsActivity[] }) => {
            console.log(`<PresupuestosConfigure> getBudgetActivitiesByCategory: `, getBudgetActivitiesByCategory)
            setSubcatActivities(getBudgetActivitiesByCategory)
        },
        onError: (e) => {
            console.error(`Error getting category activity: `, e);
        },
        fetchPolicy: 'cache-and-network'
    })

    const [submitBudget] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.assignBankBudget, {
        onCompleted: ({ assignBankBudget }: { assignBankBudget: BankBudgets }) => {
            console.log(`<PresupuestosConfigure> assignBankBudget: `, assignBankBudget)
            const _subcatActivities: BankBudgetsActivity[] = JSON.parse(JSON.stringify(subcatActivities))
            const modified = _subcatActivities.find(s => s.subcategory === assignBankBudget.subcategory)
            if (modified) {
                modified.goal = assignBankBudget.goal;
            }
            setSubcatActivities(_subcatActivities);
            sharedToasterSubject.next({ type: 'confirm', message: `Presupuesto actualizado` })
        },
    })

    useEffect(() => {
        getCategoryBudget({
            variables: {
                category: onCategory
            }
        })
    }, [onCategory, getCategoryBudget])


    const onBudgetSubmit = (amount: number, subcategory: string, category: string) => {
        submitBudget({
            variables: {
                goal: amount,
                category,
                subcategory
            }
        })
    }


    return (
        <div id="PresupuestosConfigure">
            <SectionTitleBar currentUser={currentUser} title={`Configurar Presupuestos por Categoría`} />
            <div className="presupuestosConfigureContent">
                <div className='presupuestosConfigureColumns'>
                    <PresupuestosConfigureSummary subcategories={subcatActivities} />
                    <PresupuestosConfigureDetails categoryOptions={categoryOptions} subcategories={subcatActivities} category={onCategory} onCategorySelect={setOnCategory} onBudgetSubmit={onBudgetSubmit} />
                </div>
            </div>
        </div>
    )
}

export default PresupuestosConfigure;