import { useQuery, useSubscription } from '@apollo/react-hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { PaybookAccount } from '../../models/Administracion/Bank.Accounts';
import { CredentialUpdateSubscription } from '../../models/Administracion/Bank.Credentials';
import { TransactionUpdateSubscription } from '../../models/Administracion/Bank.Transactions';
import { PaybookOrganization } from '../../models/Administracion/Banks';
import { graphqlSchema } from '../../services/graphql.schema';
import { stateLabelByCode } from '../Administration/Bancos/Credentials/Credential.entry.format';

import './ToasterActions.scss';

/*
 * ToasterActons
 * A bottom Toaster element used to express "Active" or Ongoing Actions.
 * 
 * Intended for: Bank Credentials, Bank Transactions
 */

interface ToasterActionEntry {
    id: string;
    ts?: number;
    message: string;
    organizationName?: string;
    progressValue?: number; // Complete if needed, currently not used
    progress: 'indefinite' | 'definite' | 'none',
    closeOnClick: boolean;
    type: 'message' | 'success' | 'failure'
}


function ToasterActionNotificationEntry({ data, onClose }: { data: ToasterActionEntry, onClose: () => void }) {

    useEffect(() => {
        if (data.ts) {
            setTimeout(() => {
                onClose()
            }, data.ts)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _close = () => {
        if (!data.closeOnClick) {
            return;
        }
        onClose()
    }

    return (
        <div className={`_ToasterAction_Entry ${data.type} ${data.closeOnClick ? 'clickable' : ''}`} onClick={_close}>
            <div className='_text'>
                <p>
                    {data.message}
                </p>
                {
                    data.organizationName ?
                        <p className='small'>
                            {data.organizationName}
                        </p>
                        : null
                }
            </div>
            <div className='_progressShow'>

            </div>
        </div>
    )
}


export default function ToasterActionNotifications() {

    const [toasterEntries, setToasterEntries] = useState<ToasterActionEntry[]>([]);
    const [organizations, setOrganizations] = useState<PaybookOrganization[]>([]);

    useQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getOrganizations, {
        onCompleted: ({ getOrganizations }: { getOrganizations: PaybookOrganization[] }) => {
            setOrganizations(getOrganizations)
        }
    })

    useQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankAccounts, {
        onCompleted: ({ getBankAccounts }: { getBankAccounts: PaybookAccount[] }) => {
            // Test when was last connected and remind to connect
            console.log(`(ToasterNotifications) <getBankAccounts> `, getBankAccounts);
            const unRefreshedAccounts = getBankAccounts.filter(gBA => gBA.dt_refresh < moment().subtract(7, 'days').valueOf())
            if (unRefreshedAccounts.length) {
                const pl = unRefreshedAccounts.length > 1; // Plural
                const entry: ToasterActionEntry = {
                    id: Math.random().toString(32),
                    ts: 30000,
                    message: `Hay ${unRefreshedAccounts.length} cuenta${pl ? 's' : ''} bancaria${pl ? 's' : ''} que requieren actualizarse!`,
                    progress: 'none',
                    closeOnClick: true,
                    type: 'failure',
                }
                setToasterEntries((next) => {
                    if (!next.some(n => n.id === entry.id)) {
                        next.push(entry)
                    }
                    return JSON.parse(JSON.stringify(next))
                })
            }

        },
        fetchPolicy: 'cache-and-network'
    })

    const onClose = (id: string) => () => {
        // toasterEntries scoped does not get updated here if called on child
        setToasterEntries(next => {
            return JSON.parse(JSON.stringify(next.filter(t => t.id !== id)))
        });
    }

    useSubscription<TransactionUpdateSubscription>(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.bankTransactionsUpdates, {
        onSubscriptionData: ({ subscriptionData }) => {
            // Check if re-call is actually needed
            const bankTransactionsUpdates = subscriptionData.data.bankTransactionsUpdates;
            const id = `move-${bankTransactionsUpdates.id_credential}`;
            const organizationName = organizations.find(o => o.id_site_organization === bankTransactionsUpdates.id_site_organization)

            setToasterEntries((next) => {
                const exists = next.find(c => c.id === id)
                if (exists) {
                    // Update Action state
                    exists.ts = 12000;
                    exists.type = bankTransactionsUpdates.state === 'completed' ? 'success' : 'message';
                    exists.progress = 'none';
                    exists.closeOnClick = true;
                    exists.organizationName = organizationName.name || null;
                    exists.message = bankTransactionsUpdates.message;
                    return JSON.parse(JSON.stringify(next))
                } else {
                    next.push({
                        id,
                        ts: 12000,
                        type: bankTransactionsUpdates.state === 'completed' ? 'success' : 'message',
                        progress: 'none',
                        closeOnClick: true,
                        message: bankTransactionsUpdates.message,
                        organizationName: organizationName.name || null,
                    })
                    return JSON.parse(JSON.stringify(next))
                }
            })
        }
    })

    useSubscription<CredentialUpdateSubscription>(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.bankCredentialUpdates, {
        onSubscriptionData: ({ subscriptionData }) => {
            // Check if re-call is actually needed
            const bankCredentialUpdates = subscriptionData.data.bankCredentialUpdates;
            const id = `creds-${bankCredentialUpdates.id_credential}`;
            const organizationName = organizations.find(o => o.id_site_organization === bankCredentialUpdates.id_site_organization)
            const lastState = bankCredentialUpdates.status[bankCredentialUpdates.status.length - 1]
            const stateLabel = stateLabelByCode({ code: lastState.code })
            const isSuccess = lastState.code >= 200 && lastState.code < 300;
            const isError = lastState.code > 413 || lastState.code === 411;
            setToasterEntries((next) => {
                const exists = next.find(c => c.id === id)
                if (exists) {
                    // Update Action state
                    exists.ts = 12000;
                    exists.type = isSuccess ? 'success' : (isError ? 'failure' : 'message');
                    exists.progress = 'none';
                    exists.closeOnClick = true;
                    exists.organizationName = organizationName.name || null;
                    exists.message = isSuccess ? `Conectado correctamente` : stateLabel;
                    return JSON.parse(JSON.stringify(next))
                } else {
                    next.push({
                        id,
                        ts: 12000,
                        type: isSuccess ? 'success' : (isError ? 'failure' : 'message'),
                        progress: 'none',
                        closeOnClick: true,
                        message: isSuccess ? `Conectado correctamente` : stateLabel,
                        organizationName: organizationName.name || null,
                    })
                    return JSON.parse(JSON.stringify(next))
                }
            })
        }
    })

    const renderActionElements = () => {
        return (
            <div className={`_ToasterActionInner ${toasterEntries.length ? 'display' : ''}`}>
                {
                    toasterEntries.map((entry) => <ToasterActionNotificationEntry key={entry.id} data={entry} onClose={onClose(entry.id)} />)
                }
            </div>
        )
    }

    return createPortal(
        <div className="_ToasterAction">
            {renderActionElements()}
        </div>,
        document.getElementById('root')
    )
}