
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CartaPorteMunicipio, CartaPorteState, CartaPorteZip } from '../../models/Administracion/CartaPorte';
import { BillCartaPorte } from '../../models/Factura';
import { CartaPorteComplemento_Ubicacion_Destino, TrasladoRequest_Domicilio } from '../../models/Factura.complementos';
import { ProfileQL } from '../../models/Profile';
import { graphqlSchema } from '../../services/graphql.schema';
import AutoComplete from '../Forms/Autocomplete';
import Button from '../Forms/Button';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import { CartaPorteInfoUbicacionDialog } from './dialogs/CartaPorte.info.ubicacion';
import { GoggleMapDialog } from './helper/CartaPorte.map.dialog';
import { formatMapsUrlForCartaPorte } from './helper/CartaPorte.maps.url';

interface CartaPorteUbicacionProps {
    cartaPorte: BillCartaPorte['cartaPorte']
    ubicacion: CartaPorteComplemento_Ubicacion_Destino;
    currentUser: ProfileQL;
    title?: string;
    isDestino: boolean;
    rfcDefault: string;
    isSelfReceptor: boolean;
    onUbicacionDispatch: (ubicacion: CartaPorteComplemento_Ubicacion_Destino) => void;
    dateMin?: number;
    dateMax?: number;
}



export function CartaPorteUbicacion({
    cartaPorte,
    ubicacion,
    currentUser,
    title,
    isDestino,
    rfcDefault,
    isSelfReceptor,
    onUbicacionDispatch,
    dateMin,
    dateMax,
}: CartaPorteUbicacionProps) {

    const [countries, setCountries] = useState<{ value: string, label: string }[]>([])
    const [estados, setEstados] = useState<{ value: string, label: string }[]>([])
    const [municipios, setMunicipios] = useState<{ value: string, label: string }[]>([])
    const [zipcodes, setZipCodes] = useState<{ value: string, label: string, localidad: string, municipio: string }[]>([])

    const [addressSubject] = useState(new Subject<{ estado: string, zip: string }>());
    const [openMaps, setOpenMaps] = useState(false);

    const [recoverFetchLock, setRecoverFetchLock] = useState(false);

    useQuery(graphqlSchema.FISCALPOP.CARTAPORTE.getCountries, {
        onCompleted: ({ getCountries }: { getCountries: { clave: string, pais: string }[] }) => {
            console.log('getCountries: ', getCountries);
            setCountries(getCountries.map(c => ({ label: c.pais, value: c.clave })));
        },
        onError: (e) => {
            console.error('Error getting countries: ', e.graphQLErrors[0])
        },
        fetchPolicy: 'cache-and-network'
    })

    useQuery(graphqlSchema.FISCALPOP.CARTAPORTE.getState, {
        onCompleted: ({ getState }: { getState: CartaPorteState[] }) => {
            console.log('getState: ', getState);
            setEstados(getState.map(c => ({ label: c.nombre, value: c.clave })));
        },
        onError: (e) => {
            console.error('Error getting countries: ', e.graphQLErrors[0])
        },
        fetchPolicy: 'cache-and-network'
    })

    const [fetchMunicipios] = useLazyQuery(graphqlSchema.FISCALPOP.CARTAPORTE.getMunicipios, {
        onCompleted: ({ getMunicipios }: { getMunicipios: CartaPorteMunicipio[] }) => {
            console.log('getMunicipios: ', getMunicipios);
            setMunicipios(getMunicipios.map(c => ({ label: c.municipioName, value: c.municipio })));
        },
        onError: (e) => {
            console.error('Error getting countries: ', e.graphQLErrors[0])
        },
        fetchPolicy: 'cache-and-network'
    })

    const [fetchZip] = useLazyQuery(graphqlSchema.FISCALPOP.CARTAPORTE.getCodigoPostal, {
        onCompleted: ({ getCodigoPostal }: { getCodigoPostal: CartaPorteZip[] }) => {
            console.log('getCodigoPostal: ', getCodigoPostal);
            setZipCodes(getCodigoPostal.map(c => ({ label: c.codigoPostal, value: c.codigoPostal, localidad: c.localidad || '', municipio: c.municipio })));
        },
        onError: (e) => {
            console.error('Error getting countries: ', e.graphQLErrors[0])
        },
        fetchPolicy: 'cache-and-network'
    })


    useEffect(() => {
        if (!recoverFetchLock && ubicacion?.domicilio?.estado) {
            console.log('Address RECOVERY UE: ', recoverFetchLock, ubicacion?.domicilio?.estado)
            if (ubicacion?.domicilio?.estado) {
                // Refetched Bill Arguments will return Domicilio Estado but no municipios
                fetchMunicipios({
                    variables: {
                        estado: ubicacion.domicilio.estado
                    }
                })
            }
            if (ubicacion?.domicilio?.estado) {
                // Refetched Bill Arguments will return Domicilio Estado but no municipios
                fetchZip({
                    variables: {
                        estado: ubicacion.domicilio.estado,
                        zip: ubicacion.domicilio.codigoPostal || '',
                    }
                })
            }
            // This will prevent the useEffect to be called again
            setRecoverFetchLock(true);
        }
    }, [ubicacion, recoverFetchLock, fetchMunicipios, fetchZip])

    useEffect(() => {
        console.log('Address UE')
        const subs = addressSubject.pipe(debounceTime(200)).subscribe((subs) => {
            if (subs.estado) {
                fetchMunicipios({
                    variables: {
                        estado: subs.estado
                    }
                })
                fetchZip({
                    variables: {
                        estado: subs.estado,
                        zip: subs.zip,
                    }
                })
            }
        })
        return () => {
            subs.unsubscribe();
        }
    }, [addressSubject, fetchMunicipios, fetchZip])


    const handleDestinoChange = (property: 'fecha' | 'rfc' | 'distancia' | 'domicilio') => (value: any) => {
        const _destino: CartaPorteComplemento_Ubicacion_Destino = Object.assign({}, ubicacion);
        if (property === 'rfc') {
            _destino[property] = value as string;
        }
        else if (property === 'domicilio') {
            _destino[property] = value;
        }
        else {
            _destino[property] = value as number;
        }
        onUbicacionDispatch(_destino)
    }

    const handleDestinoDomicilio = (property: keyof TrasladoRequest_Domicilio) => (value: string) => {
        const _domicilio = { ...ubicacion.domicilio };
        _domicilio[property] = value;
        if (property === 'pais' && value !== 'MEX') {
            // Not Mex type, reset child dependencies (zip excluded)
            _domicilio.estado = '';
            _domicilio.municipio = '';
            _domicilio.localidad = '';

        }
        if (property === 'estado') {
            // Not Mex type, reset child dependencies (zip excluded)
            _domicilio.municipio = '';
            _domicilio.localidad = '';
            if (_domicilio.pais === 'MEX') {
                addressSubject.next({ estado: _domicilio.estado, zip: _domicilio.codigoPostal })
            }
        }
        if (property === 'codigoPostal') {
            // Not Mex type, reset child dependencies (zip excluded)
            if (_domicilio.pais === 'MEX') {
                addressSubject.next({ estado: _domicilio.estado, zip: _domicilio.codigoPostal })
            }
        }
        handleDestinoChange('domicilio')(_domicilio)
        if (!recoverFetchLock) {
            setRecoverFetchLock(true);
        }
    }

    const handleCodigoPostalSelect = (zipSelect: string) => {
        console.log(`zipSelect: `, zipSelect);
        const zipEntry = zipcodes.find(zC => zC.value === zipSelect);
        console.log(`zipSelect Entry: `, zipEntry);
        const _domicilio = { ...ubicacion.domicilio };
        _domicilio['codigoPostal'] = zipSelect;

        if (zipEntry && zipEntry?.localidad) {
            _domicilio['localidad'] = zipEntry.localidad;
        }
        if (zipEntry && zipEntry?.municipio) {
            _domicilio['municipio'] = zipEntry.municipio;
        }
        handleDestinoChange('domicilio')(_domicilio)
        if (!recoverFetchLock) {
            setRecoverFetchLock(true);
        }
    }

    const handleDate = (date: Date) => {
        const _destino: CartaPorteComplemento_Ubicacion_Destino = { ...ubicacion, fecha: date.getTime() }
        onUbicacionDispatch(_destino)
    }

    const handleMapOpen = () => {
        setOpenMaps(true)
    }

    const handleMapClose = () => {
        setOpenMaps(false)
    }

    const googleMapsUrl = formatMapsUrlForCartaPorte(cartaPorte, countries, estados)

    return (
        <div className='ubicacion'>
            <h4>{title || 'Ubicación'}</h4>
            <div className={`card table`}>

                <CartaPorteInfoUbicacionDialog isSelfReceptor={isSelfReceptor} />
                <div className="cardBody">
                    <div className='row three sub-sm-two sm-gap-compressed xxs-one'>
                        <Input
                            type='date'
                            value={ubicacion.fecha}
                            onChange={handleDate}
                            label='Fecha'
                            minDate={dateMin ? new Date(dateMin) : null}
                            maxDate={dateMax ? new Date(dateMax) : null}
                        />
                        <Input
                            type='text'
                            label={`RFC ${isDestino ? 'Destinatario' : ''}`}
                            value={ubicacion.rfc || rfcDefault}
                            placeholder={rfcDefault}
                            onChange={handleDestinoChange('rfc')}
                        />
                        <Select
                            label="País"
                            options={countries}
                            value={ubicacion.domicilio.pais}
                            onChange={handleDestinoDomicilio('pais')}
                        />

                        {
                            ubicacion.domicilio.pais === 'MEX' ?
                                <Select
                                    label="Estado"
                                    options={estados}
                                    value={ubicacion.domicilio.estado}
                                    onChange={handleDestinoDomicilio('estado')}
                                />
                                :
                                <Input
                                    label="Estado"
                                    type='text'
                                    value={ubicacion.domicilio.estado}
                                    onChange={handleDestinoDomicilio('estado')}
                                />
                        }
                        {
                            ubicacion.domicilio.pais === 'MEX' ?
                                <Select
                                    label="Municipio"
                                    options={municipios}
                                    disabled={!ubicacion.domicilio.estado}
                                    captionLabel={!ubicacion.domicilio.estado ? "Elija el Estado primero" : (municipios.length ? '' : 'Cargando municipios')}
                                    value={ubicacion.domicilio.municipio}
                                    onChange={handleDestinoDomicilio('municipio')}
                                />
                                :
                                <Input
                                    label="Municipio"
                                    type='text'
                                    value={ubicacion.domicilio.municipio}
                                    onChange={handleDestinoDomicilio('municipio')}
                                />
                        }
                        {
                            ubicacion.domicilio.pais === 'MEX' ?
                                <AutoComplete label="Código Postal" value={ubicacion.domicilio.codigoPostal} placeholder="Código postalr" type="text"
                                    disabled={!ubicacion.domicilio.estado}
                                    noChangeOnSelect={true} options={zipcodes} forcePropsValue={true} onChange={handleDestinoDomicilio('codigoPostal')} onSelect={handleCodigoPostalSelect} />
                                :
                                <Input
                                    label="Código Postal"
                                    type='text'
                                    value={ubicacion.domicilio.codigoPostal}
                                    onChange={handleDestinoDomicilio('codigoPostal')}
                                />
                        }
                        {
                            isDestino ?
                                <Input
                                    type='number'
                                    label='Distancia aproximada'
                                    value={ubicacion.distancia}
                                    placeholder="Distancia aprox."
                                    captionLabel='En Kilómetros'
                                    onChange={handleDestinoChange('distancia')}
                                />
                                : null
                        }
                        {
                            isDestino ?
                                <Button secondary={true} setClass="mapTrigger" disabled={!googleMapsUrl} handleClick={handleMapOpen}>
                                    <span>
                                        Consultar distancia en Maps
                                    </span>
                                </Button>
                                : null
                        }
                    </div>
                </div>
            </div>
            <GoggleMapDialog googleMapsUrl={googleMapsUrl} isOpen={openMaps} onRequestClose={handleMapClose} />
        </div>
    )
}