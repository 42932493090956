export const post = (url: string, data: any = {}, credentials:boolean = true) => {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: credentials ? 'include' : 'omit'
    })
    // Use external Catch!
}