
import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import { numberToCurrencyString } from '../../services/formatting';
import SectionTitleBar from '../components/titlebar';
import Button from '../Forms/Button';
import ToolTip from '../visuals/tooltip';
import ActivationDialog from './ActivationDialog/ActivationDialog';
import { CancelSubscription } from './CancelSubsDialog/CancelSubs.dialog';

import './Planes.scss'
import CheckSvg from './svgs/checkSvg';
import LiteSvg from './svgs/liteSvg';
import ProfesionalSvg from './svgs/profesionalSvg';


interface PlanesProps extends BaseReact {
    currentUser: ProfileQL;
}


function Planes({ currentUser, location }: PlanesProps) {

    const [mobileOption, setMobileOption] = useState('profesional');
    
    const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);

    const onCancel = () => {
        setConfirmDialogIsOpen(false);
    }

    const onConfirm = () => {
        setConfirmDialogIsOpen(false);
    }

    const choosePlan = (plan: 'profesional' | 'empresarial') => () => {
        setConfirmDialogIsOpen(true)
    }

    const mobileToggleOption = (plan: 'lite' | 'profesional') => () => {
        setMobileOption(plan);
    }

    const closeCancelSubscriptionModal = () => {
        setCancelDialogIsOpen(false)
    }
    
    const openCancelSubscriptionModal = () => {
        setCancelDialogIsOpen(true)
    }

    useEffect(() => {
        if (window.location.search) {
            // For url_redirect authorization cases
            const planType = new URLSearchParams(window.location.search).get(
                'planType'
            );
            const planStep = new URLSearchParams(window.location.search).get(
                'step'
            );
            if (planType && planStep) {
                setConfirmDialogIsOpen(true)
            }
        }
    }, [])

    const renderLitePlan = () => {
        const isLite = currentUser.professionalProfile.planType === 'lite';

        return (
            <div className='planOption _lite'>
                <div className='_logo' onClick={mobileToggleOption('lite')}>
                    <LiteSvg height={150} width={150} />
                </div>
                <h3 className='_lite'>
                    LITE
                </h3>
                <div className='_priceCta'>
                    <div className='_price'>
                        <h1>
                            Gratis
                        </h1>
                        <p>
                            $2.50 MXN por factura
                        </p>
                    </div>
                    {
                        isLite ?
                            <div className='_cta'>
                                <Button secondary={true} setClass={`_current`} disabled={true}>
                                    <span>
                                        Plan Actual
                                    </span>
                                </Button>
                            </div>
                            :
                            <div className='_cta'>
                                <Button secondary={true} setClass={`_downgrade`} handleClick={openCancelSubscriptionModal}>
                                    <span>
                                        Bajar a Lite
                                    </span>
                                </Button>
                            </div>
                    }
                </div>
                <div className='_featureList'>
                    <span className='label'>

                    </span>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#3FA8F4'} />
                        <p>
                            Cotizaciones
                        </p>
                    </div>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#3FA8F4'} />
                        <p>
                            Notas de Crédito
                        </p>
                    </div>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#3FA8F4'} />
                        <p>
                            Complementos de pago
                        </p>
                    </div>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#3FA8F4'} />
                        <p>
                            Facturas Globales
                        </p>
                    </div>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#3FA8F4'} />
                        <p>
                            Catalogo de clientes
                        </p>
                    </div>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#3FA8F4'} />
                        <p>
                            Catalogo de productos
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    const renderProfessionalPlan = () => {
        const isPro = currentUser.professionalProfile.planType === 'professional';
        return (
            <div className='planOption _professional'>
                <div className='_logo' onClick={mobileToggleOption('profesional')}>
                    <ProfesionalSvg height={200} width={200} />
                </div>
                <h3 className='_profesional'>
                    Profesional
                </h3>
                <div className='_priceCta'>

                    <div className='_price'>
                        <h1>
                            $750 <span>MXN</span>
                        </h1>
                        <p className='_right'>
                            al mes
                        </p>
                    </div>
                    {
                        isPro ?
                            <div className='_cta'>
                                <Button secondary={true} setClass={`_current`} disabled={true}>
                                    <span>
                                        Plan Actual
                                    </span>
                                </Button>
                                <p className='_profesional'>
                                    Todo lo incuído en LITE
                                </p>
                            </div>
                            :
                            <div className='_cta'>
                                <Button secondary={true} handleClick={choosePlan('profesional')}>
                                    <span>
                                        Prueba 1 mes <b>gratis</b>
                                    </span>
                                </Button>
                                <p className='_profesional'>
                                    Todo lo incuído en LITE
                                </p>
                            </div>
                    }
                </div>
                <div className='_featureList'>
                    <span className='label'>
                        Además
                    </span>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                        <p>
                            Presupuestos
                        </p>
                    </div>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                        <p>
                            Proyecciones & Metas
                        </p>
                    </div>

                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                        <p>
                            Creación Carta Porte
                        </p>
                    </div>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                        <p>
                            <b>
                                Finanzas personales
                            </b>
                        </p>
                    </div>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                        <div>

                            <p>
                                2 Conexiones a Bancos
                            </p>
                            <p className='small'>
                                + $45 MXN por Conexión extra
                            </p>
                        </div>
                    </div>
                    <div className='feature'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                        <div>

                            <p>
                                100 facturas gratis al mes
                            </p>
                            <p className='small'>
                                + $2.10 MXN por Factura extra
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderDesktopPlanList = () => {
        return (
            <div className='planList'>

                {
                    renderLitePlan()
                }
                {
                    renderProfessionalPlan()
                }
            </div>
        )
    }

    const renderMobilePlanList = () => {
        return (
            <div className={`planList _mobileXs ${mobileOption}`}>
                <div className='chevron _right' onClick={mobileToggleOption('profesional')}>
                    <span className="material-icons">
                        arrow_forward_ios
                    </span>
                </div>
                <div className='chevron _left' onClick={mobileToggleOption('lite')}>
                    <span className="material-icons">
                        arrow_back_ios
                    </span>
                </div>
                {
                    renderLitePlan()
                }
                {
                    renderProfessionalPlan()
                }
            </div>
        )
    }

    const renderProFeatureTag = () => {
        return (
            <div className='_proTag'>
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="20" viewBox="0 0 46 20" fill="none">
                    <rect x="9.19995" y="1.27881" width="36.8" height="18.4783" rx="8" fill="#5B50EF" />
                    <path d="M19.2159 14.2026V5.80264H22.3239C22.9959 5.80264 23.5519 5.91464 23.9919 6.13864C24.4319 6.36264 24.7599 6.67064 24.9759 7.06264C25.1919 7.45464 25.2999 7.89464 25.2999 8.38264C25.2999 8.84664 25.1959 9.27464 24.9879 9.66664C24.7799 10.0506 24.4559 10.3626 24.0159 10.6026C23.5759 10.8346 23.0119 10.9506 22.3239 10.9506H20.7519V14.2026H19.2159ZM20.7519 9.70264H22.2279C22.7639 9.70264 23.1479 9.58664 23.3799 9.35464C23.6199 9.11464 23.7399 8.79064 23.7399 8.38264C23.7399 7.96664 23.6199 7.64264 23.3799 7.41064C23.1479 7.17064 22.7639 7.05064 22.2279 7.05064H20.7519V9.70264ZM26.469 14.2026V8.25064H27.837L27.981 9.36664C28.197 8.98264 28.489 8.67864 28.857 8.45464C29.233 8.22264 29.673 8.10664 30.177 8.10664V9.72664H29.745C29.409 9.72664 29.109 9.77864 28.845 9.88264C28.581 9.98664 28.373 10.1666 28.221 10.4226C28.077 10.6786 28.005 11.0346 28.005 11.4906V14.2026H26.469ZM33.9773 14.3466C33.4013 14.3466 32.8813 14.2146 32.4173 13.9506C31.9613 13.6866 31.5973 13.3226 31.3253 12.8586C31.0613 12.3866 30.9293 11.8426 30.9293 11.2266C30.9293 10.6106 31.0653 10.0706 31.3373 9.60664C31.6093 9.13464 31.9733 8.76664 32.4293 8.50264C32.8933 8.23864 33.4133 8.10664 33.9893 8.10664C34.5573 8.10664 35.0693 8.23864 35.5253 8.50264C35.9893 8.76664 36.3533 9.13464 36.6173 9.60664C36.8893 10.0706 37.0253 10.6106 37.0253 11.2266C37.0253 11.8426 36.8893 12.3866 36.6173 12.8586C36.3533 13.3226 35.9893 13.6866 35.5253 13.9506C35.0613 14.2146 34.5453 14.3466 33.9773 14.3466ZM33.9773 13.0146C34.3773 13.0146 34.7253 12.8666 35.0213 12.5706C35.3173 12.2666 35.4653 11.8186 35.4653 11.2266C35.4653 10.6346 35.3173 10.1906 35.0213 9.89464C34.7253 9.59064 34.3813 9.43864 33.9893 9.43864C33.5813 9.43864 33.2293 9.59064 32.9333 9.89464C32.6453 10.1906 32.5013 10.6346 32.5013 11.2266C32.5013 11.8186 32.6453 12.2666 32.9333 12.5706C33.2293 12.8666 33.5773 13.0146 33.9773 13.0146Z" fill="white" />
                    <path d="M5.35159 7.2569L6.90009 2.20288L8.03691 7.15005C8.18857 7.81006 8.66393 8.34849 9.30005 8.58079L13.3401 10.0561L9.15679 11.5838C8.60109 11.7867 8.16374 12.2253 7.96232 12.7815L6.44009 16.9855L5.33147 12.9033C5.16128 12.2766 4.69741 11.7715 4.08743 11.5488L8.7738e-05 10.0561L4.12539 8.54965C4.7141 8.33466 5.16799 7.85614 5.35159 7.2569Z" fill="#B899FB" />
                    <path d="M14.5779 2.78114L15.307 0.35498L15.8302 2.6764C15.9788 3.33559 16.4502 3.87519 17.0835 4.11096L18.8601 4.77244L16.9429 5.48624C16.3886 5.69263 15.954 6.1338 15.7561 6.69119L15.0532 8.6702L14.5579 6.81069C14.391 6.18416 13.9307 5.6774 13.3231 5.45117L11.5001 4.77244L13.3603 4.07984C13.9471 3.86138 14.3977 3.38076 14.5779 2.78114Z" fill="#B899FB" />
                    <path d="M13.513 13.6665L13.8794 12.366L14.1335 13.5686C14.272 14.224 14.7295 14.7666 15.3521 15.0139L16.1001 15.3109L15.2205 15.6602C14.672 15.8781 14.2476 16.3272 14.0612 16.8872L13.7208 17.9094L13.4935 16.9994C13.3375 16.3744 12.89 15.8629 12.2913 15.6252L11.5001 15.3109L12.3261 14.9829C12.9052 14.7529 13.344 14.2662 13.513 13.6665Z" fill="#B899FB" />
                </svg>
            </div>
        )
    }

    const renderPlanTables = () => {
        return (
            <div className='planTable'>
                <div className='line title'>
                    <div className='_label'>
                        <h2>Facturación</h2>
                    </div>
                    <div className='_logo _lite'>
                        <LiteSvg height={52} width={52} />
                    </div>
                    <div className='_logo _pro'>
                        <ProfesionalSvg height={56} width={56} />
                    </div>
                </div>
                <div className='line'>
                    <p>
                        Facturas mensuales
                    </p>
                    <div className='_lite'>
                        <p>
                            ${numberToCurrencyString(2.5)} por <br /> factura
                        </p>
                    </div>
                    <div className='_pro'>
                        <p>
                            100 facturas <br /> al mes
                        </p>
                    </div>
                </div>
                <div className='line'>
                    <p>
                        Cotizaciones
                    </p>
                    <div className='_lite'>
                        <CheckSvg height={20} width={20} color={'#3FA8F4'} />
                    </div>
                    <div className='_pro'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                    </div>
                </div>
                <div className='line'>
                    <p>
                        Notas de crédito
                    </p>
                    <div className='_lite'>
                        <CheckSvg height={20} width={20} color={'#3FA8F4'} />
                    </div>
                    <div className='_pro'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                    </div>
                </div>
                <div className='line'>
                    <p>
                        Carta Porte
                    </p>
                    <div className='_lite'>

                    </div>
                    <div className='_pro'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                    </div>
                </div>

                <div className='line title _section'>
                    <div className='_label'>
                        <h2>Finanzas</h2>
                        {renderProFeatureTag()}
                    </div>
                </div>
                <div className='line'>
                    <ToolTip text='Se incluyen 2 cuentas (accesos bancarios). Una sola cuenta bancaria puede contener varias tarjetas, prestamos, etc.'>
                        <p>
                            Cuentas Bancarias Conectadas
                            <span className="material-icons">
                                help_outline
                            </span>
                        </p>
                    </ToolTip>
                    <div className='_lite'>

                    </div>
                    <div className='_pro'>
                        <div className='_bank'>
                            <p>
                                2 Cuentas <br />
                                <span>INCLUÍDAS</span>
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="39" viewBox="0 0 49 40" fill="none">
                                <path d="M19.3866 0.35962C19.0653 0.124411 18.6965 0 18.3204 0C17.9443 0 17.5755 0.124411 17.2542 0.35962L0.986965 12.2677C0.604103 12.5471 0.3078 12.9694 0.143318 13.4703C-0.0211601 13.9713 -0.0447731 14.5232 0.0760956 15.0417C0.19696 15.5603 0.455658 16.017 0.81266 16.3421C1.16967 16.6672 1.60534 16.8428 2.05313 16.8421H34.5877C35.0348 16.8419 35.4697 16.666 35.826 16.341C36.1823 16.0161 36.4405 15.5599 36.5613 15.0421C36.682 14.5242 36.6587 13.9731 36.4949 13.4727C36.3311 12.9723 36.0357 12.55 35.6539 12.2702L19.3866 0.35962Z" fill="#5B50EF" />
                                <path d="M2.10501 35.7891H34.5319C35.6946 35.7891 36.6372 36.7316 36.6372 37.8943C36.6372 39.057 35.6946 39.9996 34.5319 39.9996H2.10501C0.942307 39.9996 -0.00025177 39.057 -0.00025177 37.8943C-0.00025177 36.7316 0.942307 35.7891 2.10501 35.7891Z" fill="#5B50EF" />
                                <path d="M5.30176 32.9473L5.30176 18.9473H10.7974V32.9473H5.30176Z" fill="#5B50EF" />
                                <path d="M16.2927 32.9473V18.9473H21.7883V32.9473H16.2927Z" fill="#5B50EF" />
                                <path d="M27.2844 32.9473V18.9473H32.78V32.9473H27.2844Z" fill="#5B50EF" />
                                <path d="M31.6212 21.334H28.8734C28.272 21.334 27.6765 21.4489 27.1208 21.6723C26.5652 21.8957 26.0604 22.223 25.6351 22.6357C25.2098 23.0484 24.8725 23.5384 24.6423 24.0776C24.4122 24.6168 24.2937 25.1948 24.2937 25.7784C24.2937 26.3621 24.4122 26.94 24.6423 27.4792C24.8725 28.0185 25.2098 28.5084 25.6351 28.9211C26.0604 29.3338 26.5652 29.6612 27.1208 29.8846C27.6765 30.1079 28.272 30.2229 28.8734 30.2229H31.6212M37.1168 30.2229H39.8647C40.4661 30.2229 41.0616 30.1079 41.6172 29.8846C42.1729 29.6612 42.6777 29.3338 43.103 28.9211C43.9618 28.0876 44.4443 26.9572 44.4443 25.7784C44.4443 24.5997 43.9618 23.4692 43.103 22.6357C42.2441 21.8022 41.0793 21.334 39.8647 21.334H37.1168" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M38.0328 25.7773H30.7053" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M31.6212 21.334H28.8734C28.272 21.334 27.6765 21.4489 27.1208 21.6723C26.5652 21.8957 26.0604 22.223 25.6351 22.6357C25.2098 23.0484 24.8725 23.5384 24.6423 24.0776C24.4122 24.6168 24.2937 25.1948 24.2937 25.7784C24.2937 26.3621 24.4122 26.94 24.6423 27.4792C24.8725 28.0185 25.2098 28.5084 25.6351 28.9211C26.0604 29.3338 26.5652 29.6612 27.1208 29.8846C27.6765 30.1079 28.272 30.2229 28.8734 30.2229H31.6212M37.1168 30.2229H39.8647C40.4661 30.2229 41.0616 30.1079 41.6172 29.8846C42.1729 29.6612 42.6777 29.3338 43.103 28.9211C43.9618 28.0876 44.4443 26.9572 44.4443 25.7784C44.4443 24.5997 43.9618 23.4692 43.103 22.6357C42.2441 21.8022 41.0793 21.334 39.8647 21.334H37.1168" stroke="#5B50EF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M38.0328 25.7773H30.7053" stroke="#5B50EF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='line'>
                    <ToolTip text='Se incluyen 2 cuentas (accesos bancarios). Una sola cuenta bancaria puede contener varias tarjetas, prestamos, etc.'>
                        <p>
                            Cuenta Bancaria Adicional
                            <span className="material-icons">
                                help_outline
                            </span>
                        </p>
                    </ToolTip>
                    <div className='_lite'>

                    </div>
                    <div className='_pro'>
                        <p>
                            $35 MXN
                        </p>
                    </div>
                </div>
                <div className='line'>
                    <p>
                        Presupuestos
                    </p>
                    <div className='_lite'>

                    </div>
                    <div className='_pro'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                    </div>
                </div>
                <div className='line'>
                    <p>
                        Movimientos Bancarios
                    </p>
                    <div className='_lite'>

                    </div>
                    <div className='_pro'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                    </div>
                </div>
                <div className='line'>
                    <p>
                        Trackeo de Gastos
                    </p>
                    <div className='_lite'>

                    </div>
                    <div className='_pro'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                    </div>
                </div>

                <div className='line title _section'>
                    <div className='_label'>
                        <h2>Proyecciones</h2>
                        {renderProFeatureTag()}
                    </div>
                </div>
                <div className='line'>
                    <ToolTip text='Planifica gastos especiales que no están en presupuesto, como: viajes, remodelaciones, compras grandes, etc.'>
                        <p>
                            Planeación de Proyectos de Gastos
                            <span className="material-icons">
                                help_outline
                            </span>
                        </p>
                    </ToolTip>
                    <div className='_lite'>

                    </div>
                    <div className='_pro'>
                        <p>
                            Metas, Seguimiento <br />
                            & Proyección
                        </p>
                    </div>
                </div>
                <div className='line'>
                    <ToolTip text='Pronostica ingresos y gastos esperados, analiza cómo afectan tu balance futuro y monitorea el cumplimiento de tus metas'>
                        <p>
                            Planeación de Proyectos de Negocios
                            <span className="material-icons">
                                help_outline
                            </span>
                        </p>
                    </ToolTip>
                    <div className='_lite'>

                    </div>
                    <div className='_pro'>
                        <p>
                            Metas, Seguimiento <br />
                            & Proyección
                        </p>
                    </div>
                </div>
                <div className='line'>
                    <ToolTip text='Define metas a futuro de ingresos, gastos o balance deseadas de manera sencilla. FiscalPOP te indicará la probabilidad de lograrla para que tomes acción'>
                        <p>
                            Metas simples
                            <span className="material-icons">
                                help_outline
                            </span>
                        </p>
                    </ToolTip>
                    <div className='_lite'>

                    </div>
                    <div className='_pro'>
                        <CheckSvg height={20} width={20} color={'#5B50EF'} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="Planes">
            <SectionTitleBar currentUser={currentUser} title={`Planes`} ignoreWarning={true}/>
            <div className="planesContent">

                <div>
                    <div className="card table">

                        <div className='presentation'>
                            <h1>Elige el plan apropiado para tí.</h1>
                            <p>Ya seas independiente, profesionista, o pequeña empresa, FiscalPOP puede ayudarte a administrar tus finanzas, metas y projecciones.</p>
                        </div>
                        {
                            renderDesktopPlanList()
                        }
                        {
                            renderMobilePlanList()
                        }
                        {
                            renderPlanTables()
                        }
                    </div>
                </div>
            </div>
            <ActivationDialog
                currentUser={currentUser}
                isOpen={confirmDialogIsOpen}
                onCancel={onCancel}
                onConfirm={onConfirm}
            />
            <CancelSubscription
                isOpen={cancelDialogIsOpen}
                currentUser={currentUser}
                onClose={closeCancelSubscriptionModal}
            />
        </div>
    )
}


export default Planes;