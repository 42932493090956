import { gql } from 'apollo-boost';

export const CORE_CFDI_COMPLETE_FIELDS = gql`
    fragment MasterCfdiCompleteFields on BillTemplate {
        serie
        folio
        formaPago
        metodoPago
        lugarExpedicion
        tipoDeComprobante
        receptor {
            customerId
            nombre
            rfc
            usoCFDI
            zip
            regimen
            email
            emailCC
        }
        conceptos {
            claveProdServ
            claveUnidad
            cantidad
            descripcion
            valorUnitario
            descuento
            pedimento
            noIdentificacion
            impuestos {
                type
                tipo
                retencion
                tasa
                cuota
            }
        }
        cfdiRelacionados {
            tipoRelacion
            uuids
        }
        cartaPorte {
            mercancias {
                claveProdServ
                claveUnidad
                cantidad
                descripcion
                valorUnitario
                noIdentificacion
                claveProdTransporte
                pesoKg
            }
            ubicacion {
                origen {
                    fecha
                    rfc
                    domicilio {
                        calle
                        codigoPostal
                        colonia
                        estado
                        municipio
                        localidad
                        numExt
                        pais
                    }
                }
                destino {
                    fecha
                    rfc
                    domicilio {
                        calle
                        codigoPostal
                        colonia
                        estado
                        municipio
                        localidad
                        numExt
                        pais
                    }
                }
            }
            autotransporte {
                permisoSCT
                numPermisoSCT
                vehiculo {
                    anoVehiculo
                    tipoVehiculo
                    placas
                }
                seguro {
                    aseguradora
                    poliza
                }
                remolques {
                    tipoRemolque
                    placa
                }
            }
            operadoresTransporte {
                tipo
                rfc
                licencia
            }
        }
    }
`;

export const COTIZACION_COMPLETE_FIELDS = gql`
    fragment CotizacionCompleteFields on Cotizacion {
        _id
        clientId
        rfc
        name
        email
        note
        total
        expiration
        translateFee
        feeTransaction
        feeFiscalpop
        discount
        discountName
        discountFormat
        discountExpiration
        paymentTermsOffered
        changesRequested
        clientAccepted
        vendorAccepted
        createdAt
        wasCancelled
        conceptos {
            claveProdServ
            claveUnidad
            cantidad
            descripcion
            valorUnitario
            descuento
            pedimento
            noIdentificacion
            impuestos {
                type
                tipo
                retencion
                tasa
                cuota
            }
        }
        paid
        paidConfirmed
        paidConfirmUrl
        paidAt
        status
        uuid
    }
`;