import React from 'react';

import './Button.scss';

function Button({primary, secondary, tertiary, accent, disabled, handleClick, children, type, setClass}:{
    primary?: boolean; secondary?: boolean; tertiary?:boolean; accent?:boolean; disabled?:boolean; handleClick?: () => void; children:JSX.Element, type?:'submit'|'button', setClass?: string}){
    return(
        <button className={`${accent ? 'accent':''} ${primary?'primary':''} ${secondary?'secondary':''} ${tertiary ? 'tertiary' : ''} __Button ${setClass ?  setClass : ''}`} onClick={handleClick} type={type || "button"} disabled={disabled}>
            {children}
        </button>
    )
}

export default Button;