import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Receptor } from '../../models/Catalogos';
import { CFDI, ToBillArguments, ToBillEvent } from '../../models/Factura';
import { graphqlSchema } from '../../services/graphql.schema';
import { clearGqlTypename } from '../../utils/formatting';
import AutoComplete from '../Forms/Autocomplete';
import Input from '../Forms/Input';
import Select from '../Forms/Select';

interface ArgumentBillBound { cfdi: CFDI, argument: ToBillArguments<ToBillEvent> }

const STATUS_OPTIONS = [
    { value: -1, label: 'Todas' },
    { value: 1, label: 'Emitidas' },
    { value: 0, label: 'Canceladas' },
]

const ARGUMENT_TYPE_OPTIONS = [
    { value: 'TODO', label: 'Todo tipo de facturas' },
    { value: 'NORMAL', label: 'Facturas normales' },
    { value: 'ADELANTO', label: 'Facturas con adelanto' },
    { value: 'PARCIALIDAD', label: 'Parcialidades' },
    { value: 'PARCIALIDAD_ADELANTO', label: 'Parcialidades con adelanto' },
    { value: 'TODO', label: '', disabled: true },
    { value: 'CARTA_PORTE', label: 'Carta Porte' },
]

function MisFacturasFilters({ onFiltersChange, onReceptorSelected, isLoading }: {
    onFiltersChange: (filterArgs: {
        uuidSearch: string;
        clientSearch: string;
        statusFilter: -1 | 0 | 1;
        argumentTypeFilter: -1 | 0 | 1 | 2 | 3;
        specialType: 'cartaporte' | 'nomina' | null;
    }) => void,
    onReceptorSelected: (receptorRfc: string) => void,
    isLoading: boolean
}) {
    const [subFilterExecuteSubject] = useState(new Subject<{
        uuidSearch: string;
        clientSearch: string;
        statusFilter: -1 | 0 | 1;
        argumentTypeFilter: -1 | 0 | 1 | 2 | 3;
        specialType: 'cartaporte' | 'nomina' | null;
    }>())

    const [receptorOptions, setReceptorOptions] = useState<Receptor[]>([]);
    const [receptorSelected, setReceptorSelected] = useState<Receptor>(null)

    const [uuidSearch, setUuidSearch] = useState('');
    const [clientSearch, setClientSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState<-1 | 0 | 1>(-1);

    const [typeLabelFilter, setTypeLabelFilter] = useState<string>('TODO')

    const [argumentTypeFilter, setArgumentTypeFilter] = useState<-1 | 0 | 1 | 2 | 3>(-1);
    const [specialType, setSpecialType] = useState<'cartaporte' | 'nomina'>(null);

    useEffect(() => {
        subFilterExecuteSubject.next({ uuidSearch: uuidSearch.toLowerCase(), clientSearch: clientSearch.toLowerCase(), statusFilter, argumentTypeFilter: argumentTypeFilter, specialType: specialType })
    }, [uuidSearch, clientSearch, statusFilter, argumentTypeFilter, subFilterExecuteSubject, specialType]);

    useEffect(() => {
        subFilterExecuteSubject.pipe(debounceTime(350)).subscribe(filterArgs => {
            onFiltersChange(filterArgs);
            if (filterArgs.clientSearch) {
                getByName({
                    variables: {
                        name: filterArgs.clientSearch
                    }
                })
            }
        })

        return () => {
            subFilterExecuteSubject.unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [getByName] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByName, {
        onCompleted: ({ getClienteByName }: { getClienteByName: Receptor[] }) => {
            setReceptorOptions(getClienteByName.map(c => clearGqlTypename(c)));
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })

    const handleClientSearch = (val: string) => {
        // console.log('HANDLING SEARCH: ', val);
        setClientSearch(val);
        if (receptorSelected) {
            onReceptorSelected(null)
            setReceptorSelected(null);
        }
    }

    const handleReceptorAutofill = (receptorAsString: string) => {
        const receptor: Receptor = JSON.parse(receptorAsString);
        setClientSearch(receptor.name);
        setReceptorSelected(receptor);
        if (receptorSelected?.rfc !== receptor.rfc) {
            // Don't re-call receptor if it's same
            onReceptorSelected(receptor.rfc)
        }
    }

    const handleTipoDeFacturaFilter = (val: string) => {
        if (val === 'TODO') {
            setArgumentTypeFilter(-1);
            setSpecialType(null);
        } else if (val === 'NORMAL') {
            setArgumentTypeFilter(0);
            setSpecialType(null);
        } else if (val === 'ADELANTO') {
            setArgumentTypeFilter(1);
            setSpecialType(null);
        } else if (val === 'PARCIALIDAD') {
            setArgumentTypeFilter(2);
            setSpecialType(null);
        } else if (val === 'PARCIALIDAD_ADELANTO') {
            setArgumentTypeFilter(3);
            setSpecialType(null);
        } else if (val === 'CARTA_PORTE') {
            setArgumentTypeFilter(0);
            setSpecialType('cartaporte');
        }
        setTypeLabelFilter(val);
    }

    const receptorNameOptions = receptorOptions.map(r => ({ label: r.name, value: JSON.stringify(r), template: <div><p className="_big">{r.name}</p><p className="_small">{r.rfc}</p></div> }))

    return (
        <div className="filters">
            <AutoComplete label="Buscar cliente" hasError={false} value={clientSearch} placeholder="Buscar por RFC o nombre" type="text"
                disabled={isLoading} noChangeOnSelect={true} options={receptorNameOptions} forcePropsValue={true} onChange={handleClientSearch} onSelect={handleReceptorAutofill} />
            <Input disabled={isLoading} type="text" label="Filtrar por UUID" value={uuidSearch} placeholder="Busca por UUID, serie o Folio" onChange={setUuidSearch} />
            <Select disabled={isLoading} label="Por tipo de factura" options={ARGUMENT_TYPE_OPTIONS} value={typeLabelFilter} onChange={handleTipoDeFacturaFilter} />
            <Select disabled={isLoading} label="Por estado de factura" options={STATUS_OPTIONS} value={statusFilter} onChange={setStatusFilter} />
        </div>
    )
}

export default MisFacturasFilters;