import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BankProjectConcept_Income } from '../../../../models/Administracion/Bank.Projects';
import { numberToCurrencyString } from '../../../../services/formatting';
import unifiedMoment from '../../../../services/unifiedMoment';

import './Projection.ingreso.ruta.scss';

interface ProjectionGastosRouteProps {
    income: BankProjectConcept_Income
}

interface ExpenseRailFlag {
    name: string;
    months: number;
    monthStartRef: number;
    xPercent: number;
    yOffset: number; // When two labels are at the same time
}

interface AmountRailFlag {
    estimate: number;
    higherThan: boolean;
    endTs: number;
    xPercent: number;
}

export function ProjectionIngresoRoute({ income }: ProjectionGastosRouteProps) {

    const [labelFlags, setLabelFlags] = useState<ExpenseRailFlag[]>([])

    const [dates, setDates] = useState<[number, number]>([null, null])

    useEffect(() => {
        if (income) {
            const startsAt = income.startsAt
            const endTs = moment(startsAt).add(income.monthRepetition, 'months').valueOf()
            const tsSpread = endTs - startsAt;


            const cycles = new Array(income.monthRepetition + 1).fill(0).map((_, i) => i)
                .reduce((p, i) => {
                    const cTS = moment(startsAt).add(i, 'months').startOf('month').valueOf()
                    const xPercent = Math.round(((cTS - startsAt) / tsSpread) * 10000) / 100;
                    const amount = Math.round((income.amount * Math.pow(1 + ((income.expectedMonthChange?.value || 0) / 100), i)) * 100) / 100;

                    const e: ExpenseRailFlag = ({
                        xPercent,
                        name: numberToCurrencyString(amount, true),
                        months: i,
                        monthStartRef: cTS,
                        yOffset: 0
                    })
                    p.push(e);
                    return p;
                }, [] as ExpenseRailFlag[])

            console.log('Cycles length origin: ', cycles.length)
            if (cycles.length % 2) {
                // Odd
                const LIMIT = 7
                if (cycles.length <= LIMIT) {
                    setLabelFlags(cycles)
                } else {
                    const indexShown = Math.ceil(cycles.length / LIMIT)
                    setLabelFlags(cycles.filter((_c, i) => (i % indexShown) === 1))
                }
            } else {
                // Pair
                const LIMIT = 6
                if (cycles.length <= LIMIT) {
                    setLabelFlags(cycles)
                } else {
                    const indexShown = Math.ceil(cycles.length / LIMIT)
                    setLabelFlags(cycles.filter((_c, i) => !(i % indexShown)))
                }
            }
            setDates([income.startsAt, endTs])
        }
    }, [income])

    return (
        <div id='ProjectionIncomeRoute'>
            <div className='rail'>
                {
                    labelFlags.map((flag, i) => (
                        <div className='labelFlag' style={{ left: `${flag.xPercent}%` }} key={i}>
                            <div className='_text' style={{ bottom: `${1 + flag.yOffset}rem` }}>
                                ${flag.name}
                            </div>
                            <div className='_date'>
                                {moment(flag.monthStartRef).format('MMM, YYYY')}
                            </div>
                        </div>
                    ))
                }
                {
                    dates[0] ?
                        <div className='startLabel'>
                            Inicio
                        </div>
                        : null
                }
                {
                    dates[1] ?
                        <div className='endLabel'>
                            Final
                        </div>
                        : null
                }
            </div>
        </div>
    )
}