
import React, { useState, useReducer, useEffect } from 'react';


import Modal from 'react-modal';
import Button from '../Forms/Button';
import RadioCheck from '../Forms/RadioCheck';

import './CartaPorte.type.dialog.scss';

export type PorteType = 'internal' | 'thirdparty'
interface CartaPorteTypeDialogProps {
    isOpen: boolean;
    onRequestCancel: () => void;
    onRequestType: (type: PorteType) => void
}


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        maxWidth: '96vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

export function CartaPorteTypeDialog({
    isOpen,
    onRequestCancel,
    onRequestType
}: CartaPorteTypeDialogProps) {

    const [type, setType] = useState<PorteType>('internal')

    const typeSelect = (porteType: PorteType) => () => {
        setType(porteType)
    }

    const onCancel = () => {
        onRequestCancel();
    }

    const onContinue = () => {
        onRequestType(type);
    }


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestCancel}
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={false}
            contentLabel="Definir tipo de Carta Porte"
        >
            <div className='card table' id="cartaPorteTypeDialog">
                <div className="cardTitle">
                    <div className='withIcon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="23" viewBox="0 0 28 23" fill="none">
                            <path d="M8.33325 6H20.8333V16.8333H8.33325V6Z" fill="#AC2E78" stroke="#AC2E78" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.33325 10.167H4.99992L2.49992 12.667V16.8337H8.33325V10.167Z" fill="#AC2E78" stroke="#AC2E78" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.66642 22.0003C8.13918 22.0003 9.33309 20.8064 9.33309 19.3337C9.33309 17.8609 8.13918 16.667 6.66642 16.667C5.19366 16.667 3.99976 17.8609 3.99976 19.3337C3.99976 20.8064 5.19366 22.0003 6.66642 22.0003Z" stroke="white" strokeWidth="2" />
                            <path d="M17.4999 22.0003C18.9727 22.0003 20.1666 20.8064 20.1666 19.3337C20.1666 17.8609 18.9727 16.667 17.4999 16.667C16.0272 16.667 14.8333 17.8609 14.8333 19.3337C14.8333 20.8064 16.0272 22.0003 17.4999 22.0003Z" stroke="white" strokeWidth="2" />
                            <path d="M17.4998 20.1663C16.5794 20.1663 15.8332 19.4201 15.8332 18.4997C15.8332 17.5792 16.5794 16.833 17.4998 16.833C18.4203 16.833 19.1665 17.5792 19.1665 18.4997C19.1665 19.4201 18.4203 20.1663 17.4998 20.1663Z" fill="#AC2E78" />
                            <path d="M6.66659 20.1663C5.74611 20.1663 4.99992 19.4201 4.99992 18.4997C4.99992 17.5792 5.74611 16.833 6.66659 16.833C7.58706 16.833 8.33325 17.5792 8.33325 18.4997C8.33325 19.4201 7.58706 20.1663 6.66659 20.1663Z" fill="#AC2E78" />
                            <path d="M14.6666 1H25.3333C26.0666 1 26.6666 1.5625 26.6666 2.25V9.75C26.6666 10.4375 26.0666 11 25.3333 11H14.6666C13.9333 11 13.3333 10.4375 13.3333 9.75V2.25C13.3333 1.5625 13.9333 1 14.6666 1Z" fill="#AC2E78" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M26.6666 2.25L19.9999 6.625L13.3333 2.25" fill="#AC2E78" />
                            <path d="M26.6666 2.25L19.9999 6.625L13.3333 2.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.5 3.08301V15.1663" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.33333 15.1667H0V13.5H3.33333V15.1667Z" fill="white" />
                        </svg>
                        <h4>Crear Carta Porte</h4>
                    </div>
                </div>
                <div className='cardBody'>
                    <div className='row justify'>
                        <p>
                            ¿Quién va a realizar el movimiento de los productos?
                        </p>
                        <div className='row radioHold'>
                            <RadioCheck value={type === 'internal'} onChange={typeSelect('internal')} />
                            <p className='small gray'>
                                Yo realizaré el movimiento de mis productos
                            </p>
                        </div>
                        <div className='row radioHold'>
                            <RadioCheck value={type === 'thirdparty'} onChange={typeSelect('thirdparty')} />
                            <p className='small gray'>
                                Brindaré servicios de transporte para Terceros
                            </p>
                        </div>
                    </div>
                    <div className='row actions'>
                        <Button secondary={true} handleClick={onCancel}>
                            <span>
                                Atrás
                            </span>
                        </Button>
                        <Button primary={true} handleClick={onContinue}>
                            <span>
                                Continuar
                            </span>
                        </Button>

                    </div>
                </div>
            </div>
        </Modal>
    )
}