import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { PaybookAccount } from '../../../../models/Administracion/Bank.Accounts';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import { numberToCurrencyString } from '../../../../services/formatting';
import NumberDisplay from '../../../components/numberDisplay';
import BarAndLineChart from '../../../Graphs/BarAndLine/BarAndLine.chart';
import useWindowResize from '../../../Hooks/useWindowResize';
import { formatCurrencyStyled } from '../../../visuals/currency.formatted';
import { formatLineGroupsFromBankSummaries } from '../../helpers/lineGroups.formatting';

import './AccountSummary.scss';

interface AccountSummaryProps {
    account: PaybookAccount;
    accountSummaries: BankMovementSummary[]
}

function AccountSummary({ account, accountSummaries }: AccountSummaryProps) {
    const { windowWidth } = useWindowResize()

    const period = accountSummaries.find(sG => sG.date >= moment().startOf('month').valueOf());
    const lastPeriod = accountSummaries.find(sG => sG.date >= moment().subtract(1, 'month').startOf('month').valueOf() && sG.date < moment().startOf('month').valueOf());
    const filteredSummaries = windowWidth >= 500 ? accountSummaries : accountSummaries.filter((_, i) => i < 4);
    const balanceLineValues = formatLineGroupsFromBankSummaries(filteredSummaries, account.balance)
    const lastMonthAtDateBalance = balanceLineValues.length >= 2 ? balanceLineValues[balanceLineValues.length - 2]?.y || 0 : 0;

    // console.log(`<Account> Balance: `, account.balance, lastMonthAtDateBalance)

    const renderGraphEmptyState = () => {
        return (
            <div className='chartSectionEmpty'>
                <div className='_text'>
                    <p>
                        ¡Esta cuenta no tiene registro de movimientos todavía!
                    </p>
                    <p className='small'>
                        Además de monitorear tus gastos, también podrás ver y categorizar tus <NavLink to="/administration/movimientos">Movimientos</NavLink>.
                    </p>
                </div>
            </div>
        )
    }

    const renderSubcatEmptyState = () => {
        return (
            <div className='subcatSectionEmpty'>
                {
                    !accountSummaries.length ?
                        <p>
                            ¡Esta cuenta no tiene movimientos categorizados!
                        </p>
                        :
                        (
                            !period ?
                                <p>
                                    No hay movimientos este mes
                                    Falta categorizar los <NavLink to={`/administration/movimientos?account=${account.id_account}`}>Movimientos</NavLink> de esta cuenta.
                                </p>
                                :
                                <p>
                                    Falta categorizar los <NavLink to={`/administration/movimientos?account=${account.id_account}`}>Movimientos</NavLink> de esta cuenta.
                                </p>
                        )
                }
            </div>
        )
    }

    const renderCreditCardExtraInfo = () => {
        if (account.account_type !== 'Credit Card' || !account.extra) {
            return null;
        }
        const { limit, available, minimum, no_interest } = account.extra
        return (
            <div className='creditCardExtra'>
                {
                    !!available || available === 0 ?
                        <div className='_extra card'>
                            <p className='small'>Crédito disponible</p>
                            <p className='_value'>{formatCurrencyStyled(available)}</p>
                        </div>
                        : null
                }
                {
                    !!limit || limit === 0 ?
                        <div className='_extra card'>
                            <p className='small'>Crédito usado</p>
                            <p className='_value'>{(Math.abs(account.balance / limit) * 100).toFixed(1)}%</p>
                        </div>
                        : null
                }
                {
                    !!minimum || minimum === 0 ?
                        <div className='_extra card'>
                            <p className='small'>Págo mínimo</p>
                            <p className='_value'>{formatCurrencyStyled(minimum)}</p>
                        </div>
                        : null
                }
                {
                    no_interest || no_interest === 0 ?
                        <div className='_extra card'>
                            <p className='small'>Págo 0% interés</p>
                            <p className='_value'>{formatCurrencyStyled(no_interest)}</p>
                        </div>
                        : null
                }
            </div>
        )
    }

    const renderBalanceGraphSection = () => {
        if (account.account_type === 'Loan') {
            // Loan Type accounts don't have movement entries (At least on BBVA)
            return null;
        }
        return (
            <div className='chartSection'>
                {
                    !accountSummaries.length ?
                        renderGraphEmptyState() : null
                }
                <BarAndLineChart margin={{ left: 40, top: 20, bottom: 20, right: 40 }}
                    barGroups={[
                        {
                            color: 'rgba(213, 251, 213, 0.75)',
                            values: filteredSummaries.map((sG) => ({
                                x: sG.date,
                                y: sG.income
                            }))
                        },
                        {
                            color: '#FBD5D5',
                            values: filteredSummaries.map((sG) => ({
                                x: sG.date,
                                y: Math.abs(sG.expense)
                            }))
                        },
                    ]}
                    lineGroups={[
                        {
                            color: `#AC2E78`,
                            values: balanceLineValues
                        }
                    ]}
                    xAxisFormatting={(d: number) => moment(d).format('MMMM')}
                    xAxisTicks={'from-data'}
                />
            </div>
        )
    }

    const renderLoanPathForward = () => {
        if (account.account_type !== 'Loan') {
            // Loan Type accounts don't have movement entries (At least on BBVA)
            return null;
        }
        const { loan_term, total_number_of_payments, principal_balance, origination_date } = account.extra;
        if (!total_number_of_payments || !loan_term) {
            // At least we need these two entries
            return (<div className='loanPathForward' />)
        }
        const percent = (total_number_of_payments / loan_term) * 100;
        const left = `calc(${percent}% - (${((percent / 100) * 8) - 4}px))`

        const dateStart = !origination_date ? null : new Date(origination_date).getTime();
        const startOfWeek = moment().startOf('week').valueOf();
        const dateEndMilliseconds = !dateStart || (startOfWeek < dateStart) ? null : (((startOfWeek - dateStart) / total_number_of_payments) * (loan_term - total_number_of_payments));
        const dateEnd = !dateEndMilliseconds ? null : moment().add((Math.round((dateEndMilliseconds / (1000 * 60 * 60 * 24)) / 182.5)) * 6, 'months').format('MMM YY')

        return (
            <div className='loanPathForward'>

                <div className='railPadding'>
                    <div className='railAxis'>
                        <div className='railCurrent' style={{ width: `${percent}%` }} />
                        <div className='referenceStart'>
                            <div className='_balanceReference'>
                                <p className='_label'>Inicio</p>
                                {
                                    principal_balance ?
                                        <p className='_balance'>{formatCurrencyStyled(principal_balance)}</p>
                                        : null
                                }
                            </div>
                            {
                                !!dateStart ?
                                    <div className='_date'>
                                        <p>
                                            {moment(dateStart).format('DD MMM YY')}
                                        </p>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className='referenceNow' style={{ left: `${left}` }}>
                            <div className='_balanceReference _current'>
                                <p className='_label'>Actual</p>
                                {
                                    <p className='_balance'>{formatCurrencyStyled(Math.abs(account.balance))}</p>
                                }
                            </div>
                        </div>
                        <div className='referenceEnd'>
                            <div className='_balanceReference _end'>
                                <p className='_label'>Fin</p>
                                <p className='_balance'>{formatCurrencyStyled(0)}</p>
                            </div>
                            {
                                !!dateEnd ?
                                    <div className='_date'>
                                        <p>
                                            {dateEnd}
                                        </p>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='_bankAccountSummary'>
            <div className='_accountTitle'>
                <div className='avatar'>
                    <div
                        key={account.site.avatar}
                        className='avatarLogo'
                        style={{ backgroundImage: `url('${account.site.avatar}')` }}
                    />
                </div>
                <div>
                    <h3>
                        {account.name}
                    </h3>
                </div>
            </div>
            <div className='_accountBody'>
                <div className='card noShadow accountGraphSection'>
                    <div className='titleDate'>
                        <h4>Resumen total del periodo</h4>
                        <div className='dates'></div>
                    </div>
                    <div className='periodSummary'>
                        {
                            account.account_type !== 'Loan' ?
                                <NumberDisplay title="Ingreso del periodo" value={period?.income || 0} coloring={(period?.income || 0) > (lastPeriod?.income || 0) ? 'green' : 'red'} icon={(period?.income || 0) > (lastPeriod?.income || 0) ? 'arrow_upward' : 'arrow_downward'} />
                                : null
                        }
                        {
                            account.account_type !== 'Loan' ?
                                <NumberDisplay title="Gastos del periodo" value={period?.expense || 0} coloring={(period?.expense || 0) > (lastPeriod?.expense || 0) ? 'green' : 'red'} icon={(period?.expense || 0) > (lastPeriod?.expense || 0) ? 'arrow_downward' : 'arrow_upward'} />
                                : null
                        }
                        {
                            account.account_type !== 'Loan' ?
                                <NumberDisplay title="Balance actual" value={account.balance} coloring={(account.balance || 0) < lastMonthAtDateBalance ? "red" : "green"} icon={(account.balance || 0) < lastMonthAtDateBalance ? 'arrow_downward' : 'arrow_upward'} />
                                :
                                // Loan types always grow but they have no historical balance to compare to
                                <NumberDisplay title="Balance actual" value={account.balance} coloring={`gray`} icon={'account_balance'} />
                        }
                    </div>
                    {
                        renderCreditCardExtraInfo()
                    }
                    {
                        renderLoanPathForward()
                    }
                    {
                        renderBalanceGraphSection()
                    }
                </div>
                {
                    account.account_type !== 'Loan' ?
                        <div className='subcatSummary'>
                            <h4 style={{ marginTop: '0px' }}>Gastos del mes totales</h4>
                            <div className='card noShadow'>
                                <div className='_subcatEntry header'>
                                    <p>
                                        Subcategory
                                    </p>
                                    <p className='currency'>
                                        Monto
                                    </p>
                                </div>
                                <div className='subCatScrollable'>
                                    {
                                        !(period?.expenseByCategory || []).length ?
                                            renderSubcatEmptyState() : null

                                    }
                                    {
                                        (period?.expenseByCategory || []).map((eBC) => (
                                            <div className='_subcatEntry' key={eBC.budgetSubcategory}>
                                                <p>
                                                    {eBC.budgetSubcategory.split('-')[1]}
                                                </p>
                                                <p className='currency'>
                                                    ${numberToCurrencyString(Math.abs(eBC.totalExpense))}
                                                </p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div />
                }
            </div>
        </div>
    )
}


export default AccountSummary;