import React, { useState } from 'react';

import './Setup.security.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile'
import SectionTitleBar from '../components/titlebar';
import Button from '../Forms/Button';
import Input from '../Forms/Input';

import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import SetupMenu from './Setup.menu';
import AccountAvatarHolder from '../components/accountAvatarHolder';

moment.locale('es');

interface SetupProps extends BaseReact {
    currentUser: ProfileQL;
}


function SetupSecurityFiscalPop({ history, location, currentUser }: SetupProps) {
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setNewPassword] = useState('');
    const [passwordConfirm, setNewPasswordConfirm] = useState('');

    const [_changePassword] = useMutation(graphqlSchema.PROFILE.changePasswordToken, {
        onCompleted: ({ changePasswordToken }: { changePasswordToken: string }) => {
            console.log('Updated Password: ', changePasswordToken);
            sharedToasterSubject.next({ type: 'confirm', message: `Se actualizó tu contraseña` })
        },
        onError: (e) => {
            console.error('Error updating contraseña: ', e)
            sharedToasterSubject.next({ type: 'error', message: e.graphQLErrors[0].message })
        }
    });

    const changePassword = () => {
        console.log('TODO: password change');
        _changePassword({
            variables: {
                currentPassword,
                password
            }
        })
    }

    return (
        <div id="SetupSecurityFiscalPop">
            <SectionTitleBar currentUser={currentUser} title="Mi emisor" />
            <div className="setupContent">
                <SetupMenu />
                <div className="card profile">
                    <AccountAvatarHolder currentUser={currentUser} />
                    <div className="setupPadding">
                        <h4>
                            Cambiar contraseña
                        </h4>
                        <div className="row two md-one">
                            <Input type='password' label="Contraseña actual" value={currentPassword} onChange={setCurrentPassword} placeholder="Contraseña actual" />
                            <div className='md-hidden'/>
                            <Input type='password' label="Cambiar contraseña" value={password} onChange={setNewPassword} placeholder="Contraseña nueva" />
                            <div className='md-hidden'/>
                            <Input type='password' label="Confirmar contraseña" value={passwordConfirm} onChange={setNewPasswordConfirm} placeholder="Confirmar Contraseña nueva" />
                            <Button setClass="_last" tertiary={true} handleClick={changePassword} disabled={!currentPassword || !password || !passwordConfirm || (password !== passwordConfirm)}>
                                <span>Cambiar contraseña</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SetupSecurityFiscalPop;