import { graphqlSchema } from './graphql.schema';
import { getCookies } from './document.cookie';
import { NormalizedCacheObject } from 'apollo-boost';
import { ApolloClient } from 'apollo-client';

import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-boost';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

import { getQueryFromWindow } from './window.query';

import { BehaviorSubject } from 'rxjs';
import { ProfileQL, FiscalpopProfile } from '../models/Profile';

import * as LogRocket from 'logrocket';

const AUTH_TOKEN = 'prof_profileid';
const FP_ADMIN_AUTH_TOKEN = 'fp_internal';
// const REDIRECT_TOKEN = 'token';
const GESTOR_TOKEN = 'gestores_profileid';



function getSessionTokens() {
    const storage = localStorage.getItem(AUTH_TOKEN)
    const cookies = getCookies();
    const query = getQueryFromWindow();
    const token = (query[AUTH_TOKEN] ? btoa(query[AUTH_TOKEN]) : '') || storage || cookies[AUTH_TOKEN] || '';
    const isAdmin = query[FP_ADMIN_AUTH_TOKEN] || cookies[FP_ADMIN_AUTH_TOKEN]
    const isGestor = cookies[GESTOR_TOKEN];

    // COOKIE TYPE TOKENS are set by Back End and requires access to ML directly, trust to build "stashed" cookie
    /*
    console.log('[getSessionToken]: ', token, {
        query: query[AUTH_TOKEN],
        cookies: cookies[AUTH_TOKEN],
        storage: storage
     });
     */

    return { token, isAdmin, isGestor }
}

function setSessionFixer() {
    // This should be called before authLink and wsLink
    const query = getQueryFromWindow();
    const { token, isAdmin, isGestor } = getSessionTokens();
    if (token) {
        localStorage.setItem(AUTH_TOKEN, token);
    }
    if (query[AUTH_TOKEN]) {
        // Clean query from URL
        console.log('query: ', query);
        const cleanedQueries = Object.keys(query).filter(k => k !== AUTH_TOKEN).map(k => `${k}=${query[k]}`).join('&');
        const noQueryUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${!cleanedQueries ? '' : `?${cleanedQueries}`}`;
        window.history.replaceState({ path: noQueryUrl }, "", noQueryUrl);
    }
    return { token, isAdmin, isGestor }
}


class GraphQlClientService {
    private _userInvoked: boolean = false;
    private _isGestorUser: boolean = false;
    private _client: ApolloClient<NormalizedCacheObject>;
    private _currentUserSubject: BehaviorSubject<ProfileQL> = new BehaviorSubject({ professionalProfile: null, fiscalpopProfile: null, fiscalpopProfileStatus: null, billing: null, stripeAccount: null });

    private _onFocusFunctionPointer: () => void;
    constructor() {

        setSessionFixer()
        this._setApolloClient();


        // Tab Focus Session Checker for account change
        // ---------------------------------------------
        this._onFocusFunctionPointer = (() => {
            let { token: lastSeenToken } = getSessionTokens()
            const _forOnFocus = () => {
                let { token } = getSessionTokens()
                if (!lastSeenToken || !token) {
                    // don't do anything, just return
                    console.log(`<onFocus> Empty token, reassigning`)
                    lastSeenToken = token;
                } else if (lastSeenToken !== token) {
                    console.log('Last Seen Token: ', lastSeenToken)
                    console.log('Current Seen token: ', token)
                    lastSeenToken = token; // Avoids multiple triggers of alert notify
                    LogRocket.track('SessionMismatchError')
                    alert(`Se detectó una sessión de FiscalPOP diferente, se recargarán los datos antes de continuar.\n\nRecuerde que solo es posible tener una sesión de Personal abierta a la vez.`);
                    // As lazyLoaded queries cannot be re-loaded, hard reset might be needed
                    document.location.reload()
                }
            }
            // >> NOTE: check onblur is not needed as there's no way to change token in page, only when another tab is opened with different profile_id query
            // >> >> As it's possible
            return _forOnFocus;
        })()

        window.onfocus = this._onFocusFunctionPointer;
    }

    // SETS Apollo client or replaces existing one
    private _setApolloClient() {
        const authLink = setContext((_, { headers }) => {
            const { token, isAdmin, isGestor } = getSessionTokens(); // Always recall from Getter
            if ((isGestor || isAdmin) && !this._isGestorUser) {
                this._isGestorUser = true;
            }
            return {
                headers: {
                    ...headers,
                    authorization: token ? `${token}` : '',
                    fpadmin: isAdmin || ''
                }
            }
        })
        const wsLink = new WebSocketLink({
            uri: 'wss://lite.fiscalpop.com/graphql',
            // uri: 'ws://localhost:3021/graphql',
            options: {
                minTimeout: 10000,
                reconnect: true,
                connectionParams: {
                    authorization: (() => {
                        const { token } = getSessionTokens();
                        console.log(`[Apollo WS] token: `, token);
                        return token ? `${token}` : ''
                    })()
                }
            }
        });

        const httpLink = new HttpLink({
            uri: 'https://lite.fiscalpop.com/graphql',
            // uri: 'http://localhost:3021/graphql',
            fetch: window.fetch
        });

        const authHttpLink = authLink.concat(httpLink);

        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            authHttpLink,
        );

        this._client = new ApolloClient({
            link: splitLink,
            cache: new InMemoryCache(),
        });
    }

    private compileCookieSessions(me: ProfileQL) {
        const cookies = getCookies();
        if (!cookies[AUTH_TOKEN]) {
            return;
        }
        if (this._isGestorUser) {
            console.warn('<compileCookieSessions> User is Gestor type')
            return;
        }

    }

    logSession(token: string) {
        if (token) {
            const domain = '.fiscalpop.com'
            localStorage.setItem(AUTH_TOKEN, token);
            document.cookie = `${AUTH_TOKEN}=${token};domain=${domain};path=/;max-age=7776000;secure`;
            this._setApolloClient();
        }
        return this._invokeGQLuser();
    }

    logOut() {
        const storage = localStorage.getItem(AUTH_TOKEN)
        const cookies = getCookies();
        if (storage) {
            localStorage.removeItem(AUTH_TOKEN);
        }
        if (cookies[AUTH_TOKEN]) {
            const domain = '.fiscalpop.com'
            document.cookie = `${AUTH_TOKEN}=;domain=${domain};expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
        }
        this._currentUserSubject.next(null);
        this.client.cache.reset();
        // onFocus will not detect a change in profile session when logging out and back in to another
        this._onFocusFunctionPointer();
        // document.location.reload()
    }

    recallCurrentUser() {
        this._invokeGQLuser();
    }

    private _invokeGQLuser() {
        // To be called whenever user has been updated without using Subscriptions
        this._userInvoked = true;
        return this._client.query({
            query: graphqlSchema.PROFILE.me,
            fetchPolicy: 'network-only'
        })
            .then((response) => {
                if (response.data.me) {
                    console.log('[_invokeGQLuser] Currrent User: ', response.data.me);
                    this._currentUserSubject.next(response.data.me);
                    this.compileCookieSessions(response.data.me);
                } else {
                    console.warn('[_invokeGQLuser] Currrent User: ', response.data.me);
                    console.warn(new Error('User needs Profile Id'))
                    this._currentUserSubject.next(response.data.me);
                }
                return response.data.me as ProfileQL;
            })
            .catch(e => {
                console.log('ERROR on ME: ', e);
            })
    }

    modifyClientMLprofile(props: { [key: string]: any }) {
        const profile = this._currentUserSubject.value;
        const currentProfessionalProfile = profile.professionalProfile;
        const newProfessionalprofile = Object.assign({}, currentProfessionalProfile, props);
        profile.professionalProfile = newProfessionalprofile;
        this._currentUserSubject.next(profile);
    }

    submitFiscalpopClient(client: FiscalpopProfile) {
        return this._client.mutate({
            mutation: graphqlSchema.FISCALPOP.SETUP.setFiscalpopClient,
            variables: {
                client
            }
        })
            .then((clientMutation) => {
                // console.log('Client mutation: ', clientMutation);
                this._invokeGQLuser();
                return clientMutation;
            })
    }

    getCurrentUser() {
        // If user has not been invoked, call it and return Subject
        if (!this._userInvoked) {
            this._invokeGQLuser();
        }
        return this._currentUserSubject;
    }

    get client() {
        return this._client;
    }
}


const GraphQlClient = (() => {
    const client = new GraphQlClientService();
    return client;
})();
export default GraphQlClient;