import React, { useState } from 'react';
import { BankBudgetsActivity } from '../../../../models/Administracion/Bank.Budgets';
import { numberToCurrencyString } from '../../../../services/formatting';
import SelectCard from '../../../Forms/SelectCard';
import { formatCurrencyStyled } from '../../../visuals/currency.formatted';
import PresupuestosConfigureDetailGraph from './PresupuestosConfigure.Details.graph';

import Modal from 'react-modal';

import './PresupuestosConfigure.Details.scss'
import { useLazyQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../../services/graphql.schema';
import { PaybookTransaction } from '../../../../models/Administracion/Bank.Transactions';
import moment from 'moment';
import Loading from '../../../Animations/loadScreen';
import ToolTip from '../../../visuals/tooltip';

interface PresupuestosConfigureDetailsProps {
    category: string
    subcategories: BankBudgetsActivity[]
    categoryOptions: { label: string, value: string }[]
    onCategorySelect: (category: string) => void;
    onBudgetSubmit: (amount: number, subcategory: string, category: string) => void;
}


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '6px',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
        border: 'none',
        boxShadow: 'none',
    }
};

function PresupuestosConfigureDetails({
    category,
    subcategories,
    categoryOptions,
    onCategorySelect,
    onBudgetSubmit
}: PresupuestosConfigureDetailsProps) {

    const [selectIndex, setSelectIndex] = useState<number>(null)

    // Movements exploration
    const [movementsToExplore, setMovementsToExplore] = useState<{ subcategory: string, monthTs: number }>(null);
    const [movements, setMovements] = useState<PaybookTransaction[]>([])
    const [movementsLoading, setMovementsLoading] = useState<boolean>(false)

    const [getMovements] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankMovements, {
        onCompleted: ({ getBankMovements }: { getBankMovements: PaybookTransaction[] }) => {
            console.log(`(PresupuestosConfigureDetails) <getBankMovements> `, getBankMovements);
            const _movements: PaybookTransaction[] = JSON.parse(JSON.stringify(getBankMovements.filter(m => m.amount <= 0)))
            _movements.forEach(t => t.amount = Math.abs(t.amount))
            setMovements(_movements)
            setMovementsLoading(false);
        },
        fetchPolicy: 'cache-and-network'
    })


    const onSelectIndex = (index: number) => () => {
        if (index === selectIndex) {
            return setSelectIndex(null);
        }
        setSelectIndex(index);
    }

    const handleBudgetSubmit = (subcat: string) => (amount: number) => {
        onBudgetSubmit(amount, subcat, category)
    }

    const handleBarMovementsExplore = (subcategory: string, monthTs: number) => {
        setMovementsToExplore({ subcategory, monthTs });
        setMovementsLoading(true);
        getMovements({
            variables: {
                start: monthTs,
                end: moment(monthTs).endOf('month').valueOf(),
                subcategory: subcategory
            }
        })
    }

    const closeMovementsExplore = () => {
        setMovementsToExplore(null);
        setMovementsLoading(false);
        setMovements([])
    }

    return (
        <div className='presupuestoConfigureDetails'>
            <div className='_filter'>
                <SelectCard
                    value={category}
                    options={categoryOptions}
                    onChange={onCategorySelect}
                />
            </div>
            <div className='_header'>
                <div className='data'>
                    <p className='name'>
                        Subcategoría
                    </p>
                    <p>
                        Presupuesto Asignado
                    </p>
                    <p>
                        Gasto del mes hasta hoy
                    </p>
                </div>
            </div>

            {
                subcategories.map((subcat, i) => {

                    const hasntExpended = !subcat.expenses.length && !subcat.current;
                    const isSelected = selectIndex === null ? false : selectIndex === i;
                    const isPostSelected = selectIndex === null ? false : (selectIndex + 1) === i;
                    return (
                        <div className={`_entry ${isSelected ? 'selected' : ''} ${isPostSelected ? 'postSelected' : ''}`} key={subcat.subcategory}>
                            <div className='data'>
                                <p className='name'>
                                    {subcat.subcategory.split('-')[1]}
                                </p>
                                {
                                    !subcat.goal ?
                                        (
                                            hasntExpended ?
                                                <p className='lightGray'>
                                                    Sin gastos
                                                </p>
                                                :
                                                <p className='orange'>
                                                    Sin presupuesto
                                                </p>
                                        )
                                        :
                                        <p>
                                            {formatCurrencyStyled(subcat.goal)}
                                        </p>
                                }
                                {
                                    hasntExpended ?
                                        <p className='lightGray'>
                                            --
                                        </p>
                                        :
                                        <p className={subcat.goal ? (subcat.current > subcat.goal ? ((subcat.current / subcat.goal) > 2 ? 'red' : 'orange') : '') : ''}>
                                            {formatCurrencyStyled(subcat.current)}
                                        </p>
                                }
                                <div className='_expand' onClick={onSelectIndex(i)}>
                                    <span className="material-icons">
                                        expand_more
                                    </span>
                                </div>
                            </div>
                            <div className='graph'>
                                <PresupuestosConfigureDetailGraph subcategory={subcat} onBudgetSubmit={handleBudgetSubmit(subcat.subcategory)} onBarMovementsExplore={handleBarMovementsExplore} />
                            </div>
                        </div>
                    )
                })
            }
            <Modal
                isOpen={!!movementsToExplore}
                onRequestClose={closeMovementsExplore}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Explorar movimientos"
            >
                {
                    movementsToExplore ?

                        <div className='card _movementsExplore table'>
                            <div className='title'>
                                <h4>
                                    {movementsToExplore.subcategory.split('-').join(' - ')}
                                </h4>
                                <p>
                                    {moment(movementsToExplore.monthTs).format('MMM YYYY')} <span> - {formatCurrencyStyled(movements.filter(t => !t.internalPairMovementId).reduce((p, c) => p += c.amount, 0))}</span>
                                </p>
                            </div>
                            <div className='content'>
                                {
                                    movementsLoading ?
                                        <div className='_loading'>
                                            <Loading display={true} relativePos={true} svgHeight="160px" />
                                        </div>
                                        :
                                        <div className='list'>
                                            <div className='_move header'>
                                                <p className='_name'>
                                                    Nombre
                                                </p>
                                                <p className='_quantity'>
                                                    Monto
                                                </p>
                                            </div>
                                            {
                                                movements.map((move) => {
                                                    const isInternal = !!move.internalPairMovementId;
                                                    return (
                                                        <div className={`_move ${isInternal ? '_internal' : ''}`} key={move.id_transaction}>
                                                            <div className='_name'>
                                                                <p className='_description'>
                                                                    {move.description}
                                                                </p>
                                                                {
                                                                    isInternal ?
                                                                        <ToolTip text='Movimientos internos entre cuentas o tarjetas no deben ser considerados gastos.'>
                                                                            <p className='small'>
                                                                                {moment(move.dt_transaction).format('DD MMM')}
                                                                                <span className='_internal'>
                                                                                    Movimiento interno
                                                                                    <span className="material-icons">
                                                                                        help_outline
                                                                                    </span>
                                                                                </span>
                                                                            </p>
                                                                        </ToolTip>
                                                                        :
                                                                        <p className='small'>
                                                                            {moment(move.dt_transaction).format('DD MMM')}
                                                                        </p>
                                                                }
                                                            </div>
                                                            <div className='_quantity'>
                                                                <p>
                                                                    {formatCurrencyStyled(move.amount)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                        : null
                }
            </Modal>
        </div>
    )
}

export default PresupuestosConfigureDetails;