import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { BaseReact } from '../../../base.model';
import { Receptor } from '../../../models/Catalogos';
import { ProfileQL } from '../../../models/Profile';
import { graphqlSchema } from '../../../services/graphql.schema';
import SectionTitleBar from '../../components/titlebar';
import CatalogoMenu from '../Catalogo.menu';


import './Clientes.scss'
import {clearGqlTypename} from '../../../utils/formatting';
import Button from '../../Forms/Button';
import Pagination from '../../Forms/Pagination';
import ClientsCatalogDialog from './Clientes.dialog';
import sharedToasterSubject from '../../../services/shared.toasterSubject';


interface ClientCatalogProps extends BaseReact {
    currentUser: ProfileQL;
}

const ITEMS_PER_PAGE = 20;

function ClientsCatalog({ currentUser }: ClientCatalogProps) {
    const [modalOpen, setModalOpen] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);

    const [clientToUpdate, setClientToUpdate] = useState<Receptor>(null)

    const [clients, setClients] = useState<Receptor[]>([]);
    const [clientsLoaded, setClientsLoaded] = useState<boolean>(false);
    useQuery(graphqlSchema.PROFILE.CATALOGOS.getClientCatalog, {
        onCompleted: ({ getClientCatalog }: { getClientCatalog: Receptor[] }) => {
            console.log('getClientCatalog: ', getClientCatalog);
            setClients(getClientCatalog);
            setClientsLoaded(true);
        },
        onError: (e) => {
            console.error('Error client: ', e)
            setClientsLoaded(true);
        },
        fetchPolicy: 'cache-and-network'
    })

    const [submitClient] = useMutation(graphqlSchema.PROFILE.CATALOGOS.saveCatalogoReceptor, {
        onCompleted: ({ saveCatalogoReceptor }: { saveCatalogoReceptor: Receptor }) => {
            setClients(clients.concat([saveCatalogoReceptor]));
            sharedToasterSubject.next({type: 'confirm', message: `Se agregó el cliente correctamente`})
        },
        onError: (e) => {
            console.error('Error submitting client: ', e);
        }
    })

    const [updateClient] = useMutation(graphqlSchema.PROFILE.CATALOGOS.updateCatalogoReceptor, {
        onCompleted: ({ updateCatalogoReceptor }: { updateCatalogoReceptor: Receptor }) => {
            const updated = clients.find(({_id}) => _id === updateCatalogoReceptor._id);
            Object.assign(updated, updateCatalogoReceptor);
            setClients(JSON.parse(JSON.stringify(clients)));
            sharedToasterSubject.next({type: 'confirm', message: `Se actualizó el cliente correctamente`})
        },
        onError: (e) => {
            console.error('Error submitting client: ', e);
        }
    })


    const openNewClientModal = () => {
        setModalOpen(true);
    }

    const closeClientModal = () => {
        setModalOpen(false);
        setClientToUpdate(null)
    }


    const saveClient = (client: Receptor) => {
        const _client = Object.assign({}, client);
        closeClientModal();
        console.log('Client to Save: ', _client);
        if (_client._id) {
            updateClient({
                variables: {
                    receptor: clearGqlTypename(_client)
                }
            })
        } else {
            submitClient({
                variables: {
                    receptor: clearGqlTypename(_client)
                }
            })
        }
    }

    const searchFor = (e: React.ChangeEvent<HTMLInputElement>) => {
        const textValue = e.target.value;
        setSearchText(textValue);
    }

    const openUpdateClientModal = (concepto: Receptor) => () => {
        setClientToUpdate(concepto);
        setModalOpen(true);
    }

    const clientsFiltered = () => {
        if (!searchText) { return clients }
        const wordsSearch = searchText.toLowerCase().split(' ');
        return clients.filter(c => {
            const _target = (c.name || '').toLowerCase();
            const _targetRFC = (c.rfc || '').toLowerCase();
            const _targetEmail = (c.email || '').toLowerCase();
            return wordsSearch.reduce((p, c) => {
                return p && (_target.includes(c) || _targetRFC.includes(c) || _targetEmail.includes(c));
            }, true as boolean)
        })
    }


    const _clients = clientsFiltered()

    const cardSwitch = () => {

        if (clientsLoaded && !clients.length) {
            return (
                <div className="card table empty">
                    <div className="emptyContent">
                        <p>
                            Aún no tienes ningún cliente en tu catálogo
                        </p>
                        <Button tertiary={true} handleClick={openNewClientModal}>
                            <div>
                                <i className="material-icons">add_circle</i>
                                <span>Dar de alta nuevo concepto</span>
                            </div>
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <div className="card table clientCard">
                <div className="cardTitle">
                    <div className="titleFilter">
                        <div className="searchFor">
                            <span className="material-icons">
                                search
                                </span>
                            <input value={searchText} onChange={searchFor} type="text" />
                        </div>
                        <div className="_action">
                            <div className="addBill" onClick={openNewClientModal}>
                                <i className="material-icons">add_circle</i>
                                <span>Agregar nuevo cliente </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cardBody">
                    <div className="card table" style={{height: '100%', paddingBottom: '0px'}}>
                        <div className="cardTitle headerTitle">
                            <div className="client">
                                <p className="name">Nombre</p>
                                <p className="rfc">RFC</p>
                                <p className="email">Email</p>
                            </div>
                        </div>
                        <div className="cardBody">
                            {
                                clientsFiltered().map((client, i) => {
                                    return (
                                        <div className="client" key={i}>
                                            <p className="name">{client.name}</p>
                                            <p className="rfc">{client.rfc}</p>
                                            <p className="email">{client.email}</p>
                                            <Button tertiary={true} handleClick={openUpdateClientModal(client)}>
                                                <span className="material-icons main">
                                                    edit
                                                </span>
                                            </Button>
                                            <Button tertiary={true}>
                                                <span className="material-icons">
                                                    delete
                                                </span>
                                            </Button>
                                        </div>
                                    )
                                })
                            }
                            <Pagination page={page} pages={Math.ceil(_clients.length / ITEMS_PER_PAGE)} onPage={setPage} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="Catalogo">
            <SectionTitleBar currentUser={currentUser} title="Catálogo de Clientes" />
            <div className="catalogoContent">
                <CatalogoMenu />
                {cardSwitch()}
            </div>
            <ClientsCatalogDialog
                modalOpen={modalOpen}
                updateClient={clientToUpdate}
                saveClient={saveClient}
                closeClientModal={closeClientModal}
            />
        </div>
    )
}

export default ClientsCatalog;