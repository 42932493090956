import React, { useEffect, useState } from 'react';

import { select, scaleLinear, axisBottom, axisLeft, format } from 'd3';

import './BarAndLine.chart.scss';
import useWindowResize from '../../Hooks/useWindowResize';


interface BarStackedChartProps {
    barGroups?: {
        name?: string;
        values: { x: number, y: number, color: string; onClick?: (data?: { x: number, y: number }) => void }[]
    }
    margin: { left?: number; right?: number; top?: number; bottom?: number }
    xAxisTicks?: 'from-data' | 'auto'
    barWidth?: number;
    xAxisFormatting?: (d: number) => string;
}

function BarStackedChart({ barGroups, margin, barWidth, xAxisTicks, xAxisFormatting }: BarStackedChartProps) {
    const { windowWidth } = useWindowResize()
    const containerRef = React.createRef<HTMLDivElement>();

    const [height, setHeight] = useState<number>(null);
    const [width, setWidth] = useState<number>(null);

    useEffect(() => {
        if (containerRef) {
            const _height = containerRef.current.getBoundingClientRect().height;
            const _width = containerRef.current.getBoundingClientRect().width;


            setHeight(_height)
            setWidth(_width)
        }
    }, [containerRef, windowWidth])


    useEffect(() => {
        if (width && height) {
            try {

                // Does it has SVG yet? 
                const hasSvg = select(containerRef.current).select<SVGSVGElement>('svg')
                const svg = !hasSvg.empty() ? hasSvg : select(containerRef.current).append('svg');
                svg
                    // .attr('width', width + (margin.left || 0) + (margin.right || 0))
                    // .attr('height', height + (margin.top || 0) + (margin.bottom || 0))
                    .attr('width', width)
                    .attr('height', height)

                // Does it have child G ? (This ius main G)
                const hasG = svg.select<SVGGElement>('g');
                const svgG = !hasG.empty() ? hasG : svg.append('g');
                svgG.attr('transform', `translate(${(margin.left || 0)},${(margin.top || 0)})`)



                // X Axis
                // ============
                const xData = [...new Set((!!barGroups ? barGroups.values.reduce((p, c) => p.concat([c.x]), [] as number[]) : []).sort((a, b) => a - b))];
                const xAxis = scaleLinear()
                    .domain([Math.min(...xData), Math.max(...xData)])
                    .range([(margin.left || 0), width - ((margin.left || 0) + (margin.right || 0))])

                const hasXaxisG = svgG.select<SVGGElement>('g._xAxis');
                const xAxisG = !hasXaxisG.empty() ? hasXaxisG : svgG.append('g').attr('class', '_xAxis');

                let xAxisConf = axisBottom(xAxis);
                if (xAxisTicks === 'from-data') {
                    xAxisConf = xAxisConf.tickValues(xData)
                }
                if (xAxisFormatting) {
                    xAxisConf = xAxisConf.tickFormat((d, i) => xAxisFormatting(d as any as number))
                }

                xAxisG
                    .attr("transform", `translate(0, ${height - ((margin.bottom || 0) + (margin.top || 0))})`)
                    .call(xAxisConf)
                    .selectAll("text")
                    .attr("transform", "translate(0,0)")
                    .attr('font-family', '"DM Sans", sans-serif')
                    .style("text-anchor", "center")

                xAxisG
                    .select(".domain")
                    .attr("stroke", "#A4A5B3")
                    .attr("opacity", "0")

                // Y Axis
                // ============
                const yData = [...new Set((!!barGroups ? barGroups.values.reduce((p, c) => p.concat([c.y]), [0] as number[]) : []).sort((a, b) => a - b))];
                const yAxis = scaleLinear()
                    .domain([Math.min(...yData), Math.max(...yData)])
                    .range([height - ((margin.top || 0)) - (margin.bottom || 0), 0])


                const hasYaxisG = svgG.select<SVGGElement>('g._yAxis');
                const yAxisG = !hasYaxisG.empty() ? hasYaxisG : svgG.append('g').attr('class', '_yAxis');

                yAxisG.attr('transform', `translate(0, 0)`)
                const yAxisConf = axisLeft(yAxis).tickFormat(format('.2s'));
                yAxisG
                    .call(yAxisConf)
                yAxisG
                    .select(".domain")
                    .attr("opacity", "0")
                yAxisG
                    .selectAll('line')
                    .attr('x2', width - (margin.right || 0) - (margin.left || 0))
                    .attr("opacity", "0.10")

                yAxisG.selectAll('text').attr('font-family', '"DM Sans", sans-serif')

                // 1. BAR ENTRIES
                // =================
                if (!!barGroups && barGroups.values.length) {
                    const BAR_WIDTH = barWidth || 12;

                    const hasBarG = svgG.select<SVGGElement>('g._bars');
                    const svgBarG = !hasBarG.empty() ? hasBarG : svgG.append('g').attr('class', '_bars');
                    svgBarG.attr('transform', `translate(${(-(BAR_WIDTH / 2))},${(0)})`)

                    let groupIndex = 0;

                    // In order to "Stack" each bar, for eavery "stack" the biggest Y value goes first and the smallest Y last
                    // >> [{x: 1, bar: [{y: 13, color: red}, {y: 10, color: yellow}]}]
                    const stackedData = barGroups.values.reduce((p, c) => {
                        const groupExists = p.find(g => g.x === c.x);
                        if (groupExists) {
                            groupExists.bar = groupExists.bar.concat([{ y: c.y, color: c.color, onClick: c.onClick || null }]).sort((a, b) => b.y - a.y)
                        } else {
                            p.push({ x: c.x, bar: [{ y: c.y, color: c.color, onClick: c.onClick || null }] })
                        }
                        return p;

                    }, [] as { x: number, bar: { y: number, color: string, onClick: (d: { x: number, y: number }) => void }[] }[])

                    const hasBarGroupG = svgBarG.select<SVGGElement>(`g._bars_${groupIndex}`);
                    const svgBarGroupG = !hasBarGroupG.empty() ? hasBarGroupG : svgBarG.append('g').attr('class', `_bars_${groupIndex}`);
                    svgBarGroupG.attr('transform', `translate(${0},${(0)})`)
                    // Clear past Rects ?
                    svgBarGroupG.selectAll('rect').remove()
                    for (const xGroup of stackedData) {

                        for (const yGroup of xGroup.bar) {
                            svgBarGroupG
                                .append('rect')
                                .attr("x", () => xAxis(xGroup.x))
                                .attr("y", () => yAxis(yGroup.y))
                                .attr("width", BAR_WIDTH)
                                .attr("height", (d) => ((height - (margin.bottom || 0) - (margin.top || 0)) - yAxis(yGroup.y)))
                                .attr("fill", yGroup.color)
                                .attr("class", !!yGroup.onClick ? 'clickable' : '')
                                .on('click', () => {
                                    if (yGroup.onClick) {
                                        yGroup.onClick({ x: xGroup.x, y: yGroup.y })
                                    }
                                })
                        }

                        ++groupIndex;
                    }
                }

            } catch (e) {
                console.error(`<Donut Chart> error`, e);
            }
        }
    }, [barGroups, height, width, margin, containerRef])

    return (
        <div className='_barLineChartContainer' >
            <div className='_chartOverflowContain' ref={containerRef}>

            </div>
        </div>
    )
}

export default BarStackedChart;