import { BillCartaPorte } from "../../../models/Factura";

export const EMPTY_CFDI = (rfcEmisor?: string): BillCartaPorte => ({
    formaPago: '03',
    metodoPago: 'PUE',
    serie: 'PORTE',
    folio: '1',
    tipoDeComprobante: 'I', // Can be "T" if it's internal
    receptor: { nombre: '', rfc: '', usoCFDI: 'G03', zip: '', email: '', regimen: '' },
    conceptos: [],
    cartaPorte: {
        mercancias: [
            /*
            {
                claveProdServ: "24122003",
                claveUnidad: "H87",
                cantidad: 120,
                descripcion: "Botellas vidrio",
                valorUnitario: 12,
                pesoKg: 60
            }
            */
        ],
        ubicacion: {
            origen: {
                fecha: Date.now(),// Reception Format: new Date(Date.now() + (1000 * 60 * 60 * 24)).toJSON().split('.')[0],
                rfc: rfcEmisor,
                domicilio: {
                    pais: "MEX",
                    estado: "",
                    municipio: "",
                    localidad: "",
                    codigoPostal: ""
                }
            },
            destino: {
                fecha: Date.now(),// Reception Format: new Date(Date.now() + (1000 * 60 * 60 * 24 * 4)).toJSON().split('.')[0],
                rfc: rfcEmisor,
                distancia: 98,
                domicilio: {
                    /*
                    "pais": "MEX",
                    "estado": "DIF",
                    "municipio": "015",
                    "localidad": "06",
                    "codigoPostal": "06700"
                    */
                    pais: "MEX",
                    estado: "",
                    municipio: "",
                    localidad: "",
                    codigoPostal: ""
                }
            },
        },
        autotransporte: {
            permisoSCT: 'TPXX00',
            numPermisoSCT: 'Permiso no contemplado en el catálogo',
            vehiculo: {
                anoVehiculo: 2012,
                tipoVehiculo: "VL",
                placas: ''
            },
            seguro: {
                aseguradora: "",
                poliza: ""
            },
            remolques: []
        },
        operadoresTransporte: []
    }
})

