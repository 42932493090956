import React, { useState, useReducer, useEffect } from 'react';

import './Pagos.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';
import Input from '../Forms/Input';
import { PagoRequest, PagoRequestPagoDoctoRel } from '../../models/Pago';

import moment from 'moment';
import Modal from 'react-modal';
import Button from '../Forms/Button';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import { ToBillEvent, ToBillArguments, FiscalpopCFDI } from '../../models/Factura';
import Loading from '../Animations/loadScreen';
import { formatCurrencyStyled } from '../visuals/currency.formatted';
import Checkbox from '../Forms/Checkbox';
import PagoTotales from './Pagos.totales';
import { validatePagoIsComplete } from '../../services/validation.cfdi';

import { clearGqlTypename } from '../../utils/formatting';
import { Receptor, SerieTracker } from '../../models/Catalogos';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import AutoComplete from '../Forms/Autocomplete';
import Select from '../Forms/Select';
import { useParams } from 'react-router-dom';
import PagosExternalBill from './Pagos.externalBill';
import { parseStampErrorFromGraphQL } from '../../utils/error.graphql';
import { REGIMEN_OPTIONS } from '../../utils/regimen.options';

interface PagosProps extends BaseReact {
    currentUser: ProfileQL;
}

interface ArgumentBinded { argument: ToBillArguments<ToBillEvent>, cfdi: FiscalpopCFDI, insoluto: number }

const SET_EMPTY_PAGO_FORM = (currentUser: ProfileQL) => ({
    lugarExpedicion: currentUser.fiscalpopProfile.lugarExpedicion,
    serie: 'PAGO',
    folio: '1',
    receptor: { nombre: '', rfc: '', usoCFDI: 'P01', regimen: '', zip: '' },
    pagos: [{
        fecha: moment(Date.now()).format('YYYY-MM-DD'),
        formaDePago: '',
        monto: 0,
        // ------------
        facturaRelacionada: []
    }],
} as PagoRequest)

function pagoReducer(state: PagoRequest, { property, value }: { property: 'receptor' | 'pago' | 'facturaRelacionada' | 'comprobante' | '_replace', value: any }): PagoRequest {
    if (property === 'receptor') {
        return Object.assign({}, state, { receptor: value });
    } else if (property === 'pago') {
        const pago = Object.assign({}, state.pagos[0], value);
        return Object.assign({}, state, { pagos: [pago] });
    }
    else if (property === 'comprobante') {
        return Object.assign({}, state, value);
    }
    else if (property === 'facturaRelacionada') {
        const _pago = state.pagos[0];
        _pago.facturaRelacionada = value;
        return Object.assign({}, state, { pagos: [_pago] });
    }
    else if (property === '_replace') {
        return Object.assign({}, state, value);
    }
    else {
        // Force update only
        return Object.assign({}, state);
    }
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        padding: '1em',
        border: 'none',
        background: 'transparent'
    }
};


const REGIMEN_OPT = REGIMEN_OPTIONS.map((o) => ({ value: o.value, label: o.secondLabel }))

function Pagos({ currentUser, location, history }: PagosProps) {
    const { eventid } = useParams<{ eventid?: string }>();

    const [validRequested, setValidRequested] = useState(false);
    const [externalBill, setExternalBill] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isStamping, setIsStamping] = useState(false);
    const [findingExecuted, setFindingExecuted] = useState(false);

    const [displayNoRfcWarning, setDisplayNoRfcWarning] = useState(false);

    // Autocomplete states ---
    const [tracekrOptions, setTrackerOptions] = useState<SerieTracker[]>([]);
    const [receptorOptions, setReceptorOptions] = useState<Receptor[]>([]);
    const [receptoSubject] = useState(new Subject<string>());
    const [receptoSubjectByRfc] = useState(new Subject<string>());
    const [formasPago, setFormasPago] = useState<{ value: string, label: string }[]>([]);

    // Functional States ------
    const [binderIndexes, setBinderIndexes] = useState<string[]>([])
    const [bindersSelected, setBindersSelected] = useState<ArgumentBinded[]>([])
    const [argBillBinding, setArgBillBinding] = useState<ArgumentBinded[]>([])
    const [cfdi, dispatchPago] = useReducer(pagoReducer, {
        lugarExpedicion: currentUser.fiscalpopProfile.lugarExpedicion,
        serie: 'PAGO',
        folio: '1',
        receptor: { nombre: '', rfc: '', usoCFDI: 'P01', regimen: '', zip: '' },
        pagos: [{
            fecha: moment(Date.now()).format('YYYY-MM-DD'),
            formaDePago: '',
            monto: 0,
            // ------------
            facturaRelacionada: []
        }],
    } as PagoRequest);

    // ==============
    const [searchRelated] = useLazyQuery(graphqlSchema.FISCALPOP.CFDI.getOpenBillArgumentsByRFC, {
        onCompleted: ({ getOpenBillArgumentsByRFC }: { getOpenBillArgumentsByRFC: { argument: ToBillArguments<ToBillEvent>, pendingPayment: number }[] }) => {
            // Only look for arguments with paymentEvents
            const availableArgs = getOpenBillArgumentsByRFC.filter(args => !!args.argument.paymentEvents.length && !!args.argument.uuid);
            console.log('[searchRelated] availableArgs: ', availableArgs);
            const cfdiProms = availableArgs.map((args) => {
                console.log('Searching for: ', args.argument.uuid);
                return fetch(`https://api.fiscalpop.com/api/v1/cfdi/find/${currentUser.fiscalpopProfile.authToken}/${args.argument.uuid}`)
                    .then(async (responsePromise) => {
                        if (responsePromise.ok) {
                            return responsePromise.json();
                        } else {
                            const err = await responsePromise.text();
                            throw new Error(err);
                        }
                    })
                    .then((cfdi: FiscalpopCFDI[]) => {
                        return (
                            { argument: args.argument, cfdi: cfdi[0], insoluto: args.pendingPayment }
                        )
                    })
            })
            Promise.all(cfdiProms).then((_binded) => {
                console.log('[searchRelated] BINDED: ', _binded);

                const binded = _binded.filter(b => b.argument.paymentEvents.some(pE => !pE.isClosed))
                setIsLoading(false);
                // IF LOCATION STATE EXISTS, SET
                if (!!location.state && (location.state as { billArgument: ToBillArguments<ToBillEvent>; paymentEvents: ToBillEvent[]; }).billArgument && (location.state as { billArgument: ToBillArguments<ToBillEvent>; paymentEvents: ToBillEvent[]; }).paymentEvents) {
                    const { billArgument, paymentEvents } = location.state as { billArgument: ToBillArguments<ToBillEvent>; paymentEvents: ToBillEvent[]; };
                    const applicableBinder = binded.find(b => b.argument.uuid === billArgument.uuid);
                    const applicableBinderIndex = binded.findIndex(b => b.argument.uuid === billArgument.uuid);

                    console.log('[Pagos] STATE: ', location.state)
                    console.log('[Pagos] billArgument: ', billArgument)
                    console.log('[Pagos] paymentEvents: ', paymentEvents)
                    console.log('[Pagos] applicableBinder: ', applicableBinder)
                    // 1. Payment event to edit, if applicable should replace paymentEvent index here
                    const _applicablePaymentEvent = eventid ? (paymentEvents.find(pE => pE._id === eventid) || paymentEvents[0]) : paymentEvents[0];
                    _applicablePaymentEvent.closedAt = null;
                    _applicablePaymentEvent.isClosed = false;
                    const _importePagadoIntended = _applicablePaymentEvent.paymentCFDI.pagos[0].monto;
                    const _importePagadoMax = applicableBinder?.insoluto || 0;
                    const relDocs: PagoRequestPagoDoctoRel[] = [{
                        uuid: billArgument.uuid,
                        importePagado: _importePagadoIntended > _importePagadoMax ? _importePagadoMax : _importePagadoIntended,
                        numParcialidad: _applicablePaymentEvent.paymentNum
                    }];
                    const pago = {
                        monto: relDocs[0].importePagado,
                        facturaRelacionada: relDocs,
                        formaDePago: _applicablePaymentEvent.paymentCFDI.pagos[0].formaDePago || '01'
                    }
                    // 2. reduce previous payment for applicable bill
                    if (eventid) {
                        const editHadPaid = _applicablePaymentEvent.paymentCFDI.pagos[0].monto;
                        applicableBinder.insoluto += editHadPaid;
                    }

                    setBindersSelected([applicableBinder]);
                    setBinderIndexes([binded[applicableBinderIndex].argument._id])
                    dispatchPago({ property: 'pago', value: pago });
                }
                setArgBillBinding(binded);
                setFindingExecuted(true);
            })
        },
        onError: (e) => {
            console.error('Error fetching CFDI Bindings: ', e);
        },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        // Extract state and use for current operations    
        if (!!location.state && (location.state as { billArgument: ToBillArguments<ToBillEvent>; paymentEvents: ToBillEvent[]; }).billArgument && (location.state as { billArgument: ToBillArguments<ToBillEvent>; paymentEvents: ToBillEvent[]; }).paymentEvents) {
            const { billArgument } = location.state as { billArgument: ToBillArguments<ToBillEvent>; paymentEvents: ToBillEvent[]; };
            dispatchPago({ property: 'receptor', value: clearGqlTypename(billArgument.MasterCFDI.receptor) });
            // get relative CFDI from argument
            console.log(`<PAGO> UE state: `, location.state)
            searchRelated({
                variables: {
                    rfc: billArgument.MasterCFDI.receptor.rfc,
                    argumentId: billArgument._id
                }
            })
        }

    }, [location, searchRelated, currentUser]);

    // GRAPHQL ----------------
    useQuery(graphqlSchema.PROFILE.CATALOGOS.getAllTrackers, {
        onCompleted: ({ getAllTrackers }: { getAllTrackers: SerieTracker[] }) => {
            setTrackerOptions(getAllTrackers.filter(t => !t.receptorRfc));
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        },
        fetchPolicy: 'cache-and-network'
    })
    useQuery(graphqlSchema.FISCALPOP.FORMA_PAGO.getFormaPago, {
        onCompleted: ({ getFormaPago }: { getFormaPago: { key: string, label: string }[] }) => {
            //console.log('Got Usos CFDI: ', getFormaPago);
            setFormasPago(getFormaPago.map(gFP => ({ value: gFP.key, label: `(${gFP.key}) ${gFP.label}` })));
        },
        onError: (e) => {
            console.error('Error getting uso CFDI: ', e);
        }
    })
    const [stampPayment] = useMutation(graphqlSchema.FISCALPOP.CFDI.stampFromPaymentEvent, {
        onCompleted: ({ stampFromPaymentEvent }: { stampFromPaymentEvent: ToBillArguments<ToBillEvent>[] }) => {
            console.log('Stamp From Payment: ', stampFromPaymentEvent);
            sharedToasterSubject.next({ type: 'confirm', message: 'Complemento Pago emitido correctamente' });
            history.push({
                pathname: '/',
                state: { stampFromPaymentEvent }
            })
        },
        onError: (e) => {
            console.log('ERROR STAMPING PAYMENT: ', e.graphQLErrors[0].message)
            setIsStamping(false)
            sharedToasterSubject.next({ message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clickToClose: true, type: 'error', clearTs: 5200 });
        }
    });
    const [restampPayment] = useMutation(graphqlSchema.FISCALPOP.CFDI.restampBillPaymentEvent, {
        onCompleted: ({ restampBillPaymentEvent }: { restampBillPaymentEvent: ToBillArguments<ToBillEvent>[] }) => {
            console.log('REStamp From Payment: ', restampBillPaymentEvent);
            sharedToasterSubject.next({ type: 'confirm', message: 'Complemento Pago sustituída correctamente' });
            history.push({
                pathname: '/',
                state: { stampFromPaymentEvent: restampBillPaymentEvent }
            })
        },
        onError: (e) => {
            console.log('ERROR RESTAMPING PAYMENT: ', e.graphQLErrors[0].message)
            setIsStamping(false)
            sharedToasterSubject.next({ message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clearTs: 5200, clickToClose: true, type: 'error' });
        }
    });

    const [getByName] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByName, {
        onCompleted: ({ getClienteByName }: { getClienteByName: Receptor[] }) => {
            setReceptorOptions(getClienteByName);
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })
    const [getByRfc] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByRfc, {
        onCompleted: ({ getClienteByRfc }: { getClienteByRfc: Receptor[] }) => {
            setReceptorOptions(getClienteByRfc);
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    });

    useEffect(() => {
        const s = receptoSubject.pipe(debounceTime(200)).subscribe(s => {
            getByName({
                variables: {
                    name: s
                }
            })
        })
        const s2 = receptoSubjectByRfc.pipe(debounceTime(200)).subscribe(s => {
            getByRfc({
                variables: {
                    rfc: s
                }
            })
        })
        return () => {
            s.unsubscribe();
            s2.unsubscribe();
        }
    }, [receptoSubject, receptoSubjectByRfc, getByName, getByRfc])

    const clearForm = () => {
        setBindersSelected([])
        setBinderIndexes([])
        setArgBillBinding([])
        dispatchPago({ property: '_replace', value: SET_EMPTY_PAGO_FORM(currentUser) });
        if (eventid) {
            history.push({
                pathname: `/facturacion/setup/pagos`,
                state: {
                }
            })
        }
    }


    const handlePagoReceptorChange = (property: 'nombre' | 'rfc' | 'zip' | 'regimen') => (value: any) => {
        const _receptor = cfdi.receptor;
        if (property === 'rfc') {
            value = value.toUpperCase();
            _receptor[property] = value;
        } else {
            _receptor[property] = value;
        }
        dispatchPago({ property: 'receptor', value: _receptor });
        if (property === 'nombre') {
            receptoSubject.next(value);
        }
        if (property === 'rfc') {
            receptoSubjectByRfc.next(value);
        }
    }

    const handleReceptorAutofill = (receptorAsString: string) => {
        const receptor: Receptor = JSON.parse(receptorAsString);
        //console.log('Receptor to add: ', receptor);
        const _receptor = Object.assign({}, cfdi.receptor, { nombre: receptor.name, rfc: receptor.rfc, customerId: receptor._id });
        if (receptor.email) {
            _receptor.email = receptor.email;
        }
        if (receptor.zip) {
            _receptor.zip = receptor.zip;
        }
        if (receptor.regimen) {
            _receptor.regimen = receptor.regimen;
        }
        const comprobante: PagoRequest = Object.assign({}, cfdi);
        comprobante.receptor = _receptor;
        if (receptor.seriePreference) {
            // Pull serie ref
            comprobante.serie = receptor.seriePreference;
            // get Tracker reference for folio
            const hasFolio = tracekrOptions.find(tO => tO.serie === receptor.seriePreference);
            if (!!hasFolio) {
                comprobante.folio = `${hasFolio.folio}`;
            }
        }
        dispatchPago({ property: 'comprobante', value: comprobante });
    }

    const handlePagoSerieBlur = (value: string) => {
        const hasFolio = tracekrOptions.find(tO => tO.serie === value);
        console.log('Serie: ', value, hasFolio)
        if (!!hasFolio) {
            const _comp: { [key: string]: any } = {};
            _comp['folio'] = hasFolio.folio;
            dispatchPago({ property: 'comprobante', value: _comp });
        }
    }
    const handlePagoComprobanteChange = (property: 'serie' | 'folio') => (value: any) => {
        const _comp: { [key: string]: any } = {};
        _comp[property] = value;
        dispatchPago({ property: 'comprobante', value: _comp });
    }
    const handlePagoMainChange = (property: 'fecha' | 'monto' | 'formaDePago') => (value: any) => {
        const _pago: any = cfdi.pagos[0];
        if (property === 'fecha') {
            const _fecha: Date = value;
            console.log('Fecha: ', _fecha, moment(_fecha).format('YYYY-MM-DD'));
            // _pago['fecha'] = moment(_fecha).format('YYYY-MM-DD');
            // >> Since FiscalPOP API takes pago's fecha as a string, we need to keep it as JSON string otherwise it will be converted to UTC
            _pago['fecha'] = moment(_fecha).startOf('day').toDate().toJSON();
        } else {
            _pago[property] = value;
        }
        dispatchPago({ property: 'pago', value: _pago });
    }

    const handleMontoPaidChange = (index: number) => (value: number) => {
        const binder = bindersSelected[index];
        if (value < 0.01) {
            value = 0.01
        }
        if (value > binder.insoluto) {
            value = binder.insoluto;
        }
        const pago = cfdi.pagos[0]
        pago.facturaRelacionada[index].importePagado = value;
        // Correct monto?
        const montoTotal = pago.facturaRelacionada.reduce((p, c) => {
            p += c.importePagado;
            return p
        }, 0);
        pago.monto = montoTotal;
        dispatchPago({ property: 'pago', value: pago });
    }

    const submitPreview = () => {
        console.log('PREVIEW PAGO (billArgumento selected): ', bindersSelected)
        console.log('PREVIEW PAGO (cfdi): ', cfdi);
        // Confirm that CFDI is correct else, state error
        if (!validatePagoIsComplete(cfdi)) {
            setValidRequested(true);
            return;
        }
        setIsStamping(true)
        const stampedArguments = bindersSelected.map(b => {
            const stArg: ToBillArguments<string> = clearGqlTypename(Object.assign({}, b.argument, { paymentEvents: b.argument.paymentEvents.map(pE => pE._id), billEvents: b.argument.billEvents.map(bE => bE._id) }));
            return stArg;
        });

        if (eventid) {
            restampPayment({
                variables: {
                    eventid,
                    arguments: stampedArguments,
                    cfdi: cfdi
                }
            })
        } else {
            stampPayment({
                variables: {
                    arguments: stampedArguments,
                    cfdi: cfdi
                }
            })
        }
        // >> No preview for Payments, yet...
        /*
        console.log('To PReview!')
        history.push({
            pathname: '/facturacion/setup/pagos/preview',
            state: { cfdi, bindersSelected }
        })
        */
    }

    // MODAL METHODS ------
    const closeBillFindModal = () => {
        setModalOpen(false);
    }

    const relateWithExternal = () => {
        setModalOpen(false);
        setExternalBill(true);
    }

    const openBillFindModal = () => {
        if (!cfdi.receptor.rfc) {
            if (!displayNoRfcWarning) {
                // IF guard blocks multi-click effects
                setDisplayNoRfcWarning(true);
                setTimeout(() => {
                    setDisplayNoRfcWarning(false);
                }, 6000)
            }
            return sharedToasterSubject.next({ type: 'warning', message: 'Agrega el RFC y nombre antes de buscar facturas relacionadas' })
        }
        setModalOpen(true);
        setFindingExecuted(false);
        searchBillBindinds()
    }

    const searchBillBindinds = () => {
        setIsLoading(true);
        setFindingExecuted(false);
        searchRelated({
            variables: {
                rfc: cfdi.receptor.rfc
            }
        })
    }

    const checkSelected = (index: string) => () => {
        const _binderIndexes: string[] = JSON.parse(JSON.stringify(binderIndexes))
        if (_binderIndexes.includes(index)) {
            setBinderIndexes(_binderIndexes.filter(i => i !== index));
        } else {
            _binderIndexes.push(index);
            setBinderIndexes(_binderIndexes);
        }
    }

    const selectAndCloseModal = () => {
        const binders = argBillBinding.filter((arg, i) => binderIndexes.includes(arg.argument._id));
        //console.log('BINDER SELECTED: ', binders);
        setBindersSelected(binders)
        // Build facturas Rel
        const pago = cfdi.pagos[0];
        const relDocs: PagoRequestPagoDoctoRel[] = binders.map((binder) => {
            const offset = binder.argument.paymentEvents.some(p => p.paymentNum === 0);
            const nextPayEvent = binder.argument.paymentEvents.filter(ev => !ev.isClosed || (!!ev.isClosed && !!ev.wasCanceled))[0];
            let importePagago = nextPayEvent.paymentCFDI.pagos[0].monto;
            console.log('nextPayEvent.paymentCFDI.pagos[0].monto: ', nextPayEvent.paymentCFDI.pagos[0].monto, binder.insoluto);
            if (importePagago > binder.insoluto) {
                importePagago = binder.insoluto;
            }
            return ({
                uuid: binder.cfdi.uuid,
                importePagado: importePagago,
                numParcialidad: nextPayEvent.paymentNum + (offset ? 1 : 0)
            })
        })
        // Adjust possitive cents 
        const montoTotal = pago.facturaRelacionada.reduce((p, c) => {
            p = Math.round((p + c.importePagado) * 100) / 100;
            return p
        }, 0);
        if ((pago.monto - montoTotal) > 0) {
            let toAdjust = Math.round((pago.monto - montoTotal) * 100) / 100;
            for (const rel of relDocs) {
                const binderIs = binders.find(b => b.cfdi.uuid === rel.uuid);
                const difference = Math.round((binderIs.insoluto - rel.importePagado) * 100) / 100;
                if (difference <= toAdjust) {
                    rel.importePagado = binderIs.insoluto;
                    toAdjust = Math.round((toAdjust - difference) * 100) / 100;
                }
            }
        }

        pago.facturaRelacionada = relDocs;
        const adjustedMontoTotal = pago.facturaRelacionada.reduce((p, c) => {
            p = Math.round((p + c.importePagado) * 100) / 100;
            return p
        }, 0);

        pago.monto = adjustedMontoTotal;
        dispatchPago({ property: 'pago', value: pago });
        closeBillFindModal()
    }

    // ===============


    // RENDER METHODS
    const renderFindRelated = () => {
        return (
            <Modal
                isOpen={modalOpen}
                onRequestClose={closeBillFindModal}
                style={customStyles}
                ariaHideApp={false}
                overlayClassName={'pagosOverlay'}
                contentLabel="Buscar Factura relacionada"
            >
                <div id="_BusquedaFacturas">
                    <h4>Buscar Facturas relacionadas</h4>
                    <div className="card table findBillRelated">
                        <div className="cardTitle withClose">
                            <div />
                            <i className="material-icons" onClick={closeBillFindModal}>
                                close
                            </i>
                        </div>
                        <div className="cardBody">
                            <div className="row three lg-two sm-gap-compressed">
                                <Input type='text' value={cfdi.receptor.nombre} label="Nombre" disabled={true} />
                                <Input type='text' value={cfdi.receptor.rfc} label="RFC" disabled={true} />
                                <div className="actions">
                                    {
                                        isLoading ?
                                            <Loading display={true} relativePos={true} svgHeight={'45px'} svgWidth={'45px'} />
                                            : <div />
                                    }
                                    <Button primary={true} handleClick={searchBillBindinds} disabled={findingExecuted && !argBillBinding.length}>
                                        <span>Buscar</span>
                                    </Button>
                                </div>
                            </div>
                            {
                                findingExecuted && !argBillBinding.length ?
                                    <div className="_empty">
                                        <h3>No se encontraron facturas pendientes con este RFC</h3>
                                        <Button secondary={true} handleClick={relateWithExternal}>
                                            <span>Relacionar con factura emitida fuera de FiscalPOP</span>
                                        </Button>
                                    </div>
                                    : ''
                            }
                            {
                                findingExecuted && argBillBinding.length ?
                                    <div className="card table noShadow _relatedBillCard">
                                        <div className="cardTitle">
                                            <div className="billsHeader billOptions">
                                                <p className="date">Fecha de emisión</p>
                                                <p className="serie">Serie</p>
                                                <p className="folio">Folio</p>
                                                <p className="parcialidad">Parcialidad</p>
                                                <p className="monto">Monto total</p>
                                                <p className="pendiente">Pendiente</p>
                                            </div>
                                        </div>
                                        <div className="cardBody">
                                            <div className="billOptionWrap">
                                                {argBillBinding.map((binding, i) => {
                                                    const offset = binding.argument.paymentEvents.some(p => p.paymentNum === 0);
                                                    // WHen editing, expects isCLosed & wasCanceled
                                                    //console.log(`<${i}> binding.argument`, binding.argument);
                                                    const nextPayEvent = binding.argument.paymentEvents.filter(ev => !ev.isClosed || (!!ev.isClosed && !!ev.wasCanceled) || (ev._id === eventid))[0];
                                                    return (
                                                        <div key={i} className="billOptions">
                                                            <Checkbox value={binderIndexes.includes(binding.argument._id)} onChange={checkSelected(binding.argument._id)} />
                                                            <p className="date">{moment(new Date(binding.cfdi.json["@"].Fecha).getTime()).format('DD/MMM/YYYY')}</p>
                                                            <p className="serie">{binding.cfdi.json["@"].Serie}</p>
                                                            <p className="folio">{binding.cfdi.json["@"].Folio}</p>
                                                            <p className="parcialidad">{nextPayEvent.paymentNum + (offset ? 1 : 0)} de {binding.argument.paymentEvents.length}</p>
                                                            <p className="monto">{formatCurrencyStyled(parseFloat(binding.cfdi.json['@'].Total))}</p>
                                                            <p className="pendiente">{formatCurrencyStyled(binding.insoluto)}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="selectedActions">
                                                <Button primary={true} handleClick={selectAndCloseModal} disabled={!binderIndexes.length}>
                                                    <span>Agregar seleccionados</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }


    const receptorNameOptions = receptorOptions.map(r => ({ label: r.name, value: JSON.stringify(r), template: <div><p className="_big">{r.name}</p><p className="_small">{r.rfc}</p></div> }))
    const receptorRFCOptions = receptorOptions.map(r => ({ label: r.rfc, value: JSON.stringify(r), template: <div><p className="_big">{r.rfc}</p><p className="_small">{r.name}</p></div> }))

    return (
        <div id="Pagos">
            <SectionTitleBar currentUser={currentUser} title={eventid ? `Editar Complemento de pagos` : `Complemento de pagos`} />
            <div className="pagosContent">
                <div>
                    <h4>Pago a registrar</h4>
                    <div className="card table pagoRegister">
                        <div className="cardBody">
                            <div className="row three lg-two xxs-one xs-gap-compressed">
                                <AutoComplete label="Nombre" hasError={(validRequested || displayNoRfcWarning) && !cfdi.receptor.nombre} value={cfdi.receptor.nombre} placeholder="Razón social de receptor" type="text"
                                    noChangeOnSelect={true} options={receptorNameOptions} forcePropsValue={true} onChange={handlePagoReceptorChange('nombre')} onSelect={handleReceptorAutofill} />
                                <AutoComplete label="RFC" hasError={(validRequested || displayNoRfcWarning) && !cfdi.receptor.rfc} value={cfdi.receptor.rfc} placeholder="RFC de receptor" type="text"
                                    noChangeOnSelect={true} options={receptorRFCOptions} forcePropsValue={true} onChange={handlePagoReceptorChange('rfc')} onSelect={handleReceptorAutofill} />
                            </div>
                            <div className="row three lg-two xxs-one xs-gap-compressed">
                                <Select label="Régimen" hasError={validRequested && !cfdi.receptor.regimen} value={cfdi.receptor.regimen} onChange={handlePagoReceptorChange('regimen')} placeholder="Régimen fiscal del receptor" options={REGIMEN_OPT} />
                                <Input label="Código Postal" hasError={validRequested && ((cfdi.receptor.zip || '').length !== 5)} value={cfdi.receptor.zip} onChange={handlePagoReceptorChange('zip')} type="text" placeholder="Codigo Postal Domicilio Fiscal" />
                                <div className='row lg-hidden' />

                                <Input label="Serie de pago" hasError={validRequested && !cfdi.serie} value={cfdi.serie} onChange={handlePagoComprobanteChange('serie')} type="text" placeholder="Serie" onBlur={handlePagoSerieBlur} />
                                <Input label="Folio" hasError={validRequested && !cfdi.folio} value={cfdi.folio} onChange={handlePagoComprobanteChange('folio')} type="text" placeholder="Folio" />
                            </div>
                            <div className="row three lg-two md-two sm-one">
                                <Input type="date" label="Fecha de pago" hasError={validRequested && !cfdi.pagos[0].fecha} value={cfdi.pagos[0].fecha} onChange={handlePagoMainChange('fecha')} placeholder="Fecha al recibir el pago" />
                                <Select label={'Selecciona como recibirás tu pago'} hasError={validRequested && !cfdi.pagos[0].formaDePago} value={cfdi.pagos[0].formaDePago} onChange={handlePagoMainChange('formaDePago')} options={formasPago} placeholder='Elige una de las opciones' />
                                <Input type="number" isCurrency={true} hasError={validRequested && !cfdi.pagos[0].monto} label="Monto total" value={cfdi.pagos[0].monto} placeholder="" onChange={handlePagoMainChange('monto')} min={0.01} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h4>Facturas relacionadas</h4>
                    <div className="card table">
                        <div className="cardBody facturasTable">
                            {
                                !bindersSelected.length ?
                                    (!externalBill ? '' :
                                        <PagosExternalBill display={externalBill} />
                                    )

                                    :
                                    <div className="card table">
                                        <div className="cardTitle">
                                            <div className="_relatedBillHeader _relatedBill">
                                                <p className="date">Fecha de factura</p>
                                                <p className="serieFolio">Serie <br /> Folio</p>

                                                <p className="rfc">RFC</p>
                                                <p className="parcialidad">Parcialidad</p>
                                                <p>Monto Pagado</p>
                                                <p className="pendiente">Quedaría pendiente</p>
                                            </div>
                                        </div>
                                        <div className="cardBody">
                                            <div className="relatedWrap">
                                                {
                                                    bindersSelected.map((binder, i) => {
                                                        const doctoRel = cfdi.pagos[0].facturaRelacionada[i];
                                                        if (!doctoRel) {
                                                            return (
                                                                <div key={i} className="_relatedBill" />
                                                            )
                                                        }
                                                        return (
                                                            <div key={i} className="_relatedBill">
                                                                <p className="date">{moment(new Date(binder.cfdi.json["@"].Fecha).getTime()).format('DD/MMM/YYYY')}</p>
                                                                <div className="row serie">
                                                                    <p>Serie: {binder.cfdi.json["@"].Serie}</p>
                                                                    <p className="small">Folio: {binder.cfdi.json["@"].Folio}</p>
                                                                </div>
                                                                <p className="rfc">{binder.cfdi.json["cfdi:Receptor"]["@"].Rfc}</p>
                                                                <div className="row parcialidad">
                                                                    <p>{doctoRel.numParcialidad} de {binder.argument.paymentEvents.length}</p>
                                                                    <p className="small">Esperado: {formatCurrencyStyled(cfdi.pagos[0].monto)}</p>
                                                                </div>
                                                                <Input type="number" step={0.01} isCurrency={true} label={`Pagado`} value={doctoRel.importePagado} onChange={handleMontoPaidChange(i)} max={binder.insoluto} />
                                                                <div className="remainder">
                                                                    <p>{formatCurrencyStyled(binder.insoluto - doctoRel.importePagado)}</p>
                                                                    <p className="_from">de {formatCurrencyStyled(parseFloat(binder.cfdi.json['@'].Total))}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                            }
                            <div className="_action">
                                <div className="addBill" onClick={openBillFindModal}>
                                    <i className="material-icons">add_circle</i>
                                    <span>Agregar {cfdi.pagos[0].facturaRelacionada.length > 0 ? 'otra Factura Relacionada' : 'una Factura Relacionada'} </span>
                                    {
                                        displayNoRfcWarning ?
                                            <div className='noRfc'>
                                                <span>Agregue receptor primero</span>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PagoTotales cfdi={cfdi} />
                <div className="submitactions">
                    {
                        validRequested ?
                            <p className="message error">
                                <span className="error">
                                    Algunos campos faltan de llenarse o contienen errores
                                </span>
                            </p>
                            : ''
                    }
                    <Button secondary={true} handleClick={clearForm}>
                        <span>
                            Limpiar
                        </span>
                    </Button>
                    <Button primary={true} handleClick={submitPreview} disabled={!bindersSelected.length || isStamping}>
                        {
                            isStamping ?
                                <span>
                                    Emitiendo factura
                                </span>
                                :
                                <span>
                                    Emitir Complemento
                                </span>
                        }

                    </Button>
                </div>
            </div>
            {renderFindRelated()}
        </div>
    )
}

export default Pagos;