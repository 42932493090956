import React from 'react';

import './SwitchCheckbox.scss';

interface CheckBoxProps {
    onChange: (bool:boolean) => void;
    value: boolean;
    checkedColor?: 'main' | 'green';
    disabled?: boolean;
    micro?: boolean;
}

function SwitchCheckbox({onChange, value, disabled, micro, checkedColor = 'main'}:CheckBoxProps){
    const toggleValue = () => {
        if(disabled){
            return;
        }else{
            onChange(!value);
        }
    }

    return (
        <div className={`__SwitchCheckbox ${micro ? '_micro' : ''} ${disabled ? 'disabled' : ''} ${value ? 'checked' : ''} ${checkedColor}`} onClick={toggleValue}>
            <div className='slider' />
        </div>
    )
}

export default SwitchCheckbox;