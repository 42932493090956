import { BillConceptTax, FiscalpopImpuesto } from "../models/Factura";
import { clearGqlTypename } from "./formatting";

export const ENUMERATE_IMPUESTO_OPTIONS = (tax: FiscalpopImpuesto) => {
    let enumLabel = `${tax.impuesto.toUpperCase()}-`
    if (tax.retencion && tax.traslado) {

    } else if (tax.retencion) {
        enumLabel += 'RETE-';
    } else {
        enumLabel += 'TRAS-';
    }
    enumLabel += `${tax.tipo.toUpperCase()}-`; // FIJO o RANGO
    if (tax.exento) {
        enumLabel += 'EXENTO-'
    } else {
        enumLabel += `${tax.factor.toUpperCase()}-` // TASA o CUOTA
    }
    enumLabel += `${tax.max}`
    // IVA-TRAS-FIJO-TASA-0.16
    // IVA-TRAS-FIJO-EXENTO-0
    // IEPS-RET-FIJO-TASA-0.16
    tax._uniqueId = enumLabel;
    return tax;
}

export const getImpuestoUniqueId = (tax: BillConceptTax, impuestosList: FiscalpopImpuesto[]) => {
    // Short circuit for already formatted taxes or unfilled case
    if(tax._uniqueId) {
        return tax._uniqueId;
    }
    if(!impuestosList.length) {
        return ''
    }

    let enumLabel = `${tax.type.toUpperCase()}-`
    if (tax.retencion) {
        enumLabel += 'RETE-';
    } else {
        enumLabel += 'TRAS-';
    }
    enumLabel += `${tax.tipo.toUpperCase()}-`; // FIJO o RANGO
    if (tax.exento) {
        // exento ends here
        enumLabel += 'EXENTO-0'
        return enumLabel;
    }  
    if (tax.tipo === 'Fijo') {
        // fijo has no range
        if (tax.tasa || tax.tasa === 0) {
            enumLabel += `TASA-${tax.tasa}` // TASA
        } else if (tax.cuota || tax.cuota === 0) {
            enumLabel += `CUOTA-${tax.cuota}` // CUOTA
        }
    } else {
        // Rango should search for estimated entry
        let rangedValue: number = null
        if (tax.tasa || tax.tasa === 0) {
            rangedValue = tax.tasa
            enumLabel += `TASA-` // TASA
        } else if (tax.cuota || tax.cuota === 0) {
            rangedValue = tax.cuota
            enumLabel += `CUOTA-` // CUOTA
        }
        const candidates = impuestosList.filter((iL) => iL._uniqueId.includes(enumLabel))
        const target = candidates.find(c => c.min <= rangedValue && c.max >= rangedValue)
        if(target) {
            enumLabel = target._uniqueId
        } else {
            console.error('No match for tax: ', tax, impuestosList)
            enumLabel = candidates[0]._uniqueId
        }
    }
    // IVA-TRAS-FIJO-TASA-0.16
    // IVA-TRAS-FIJO-EXENTO-0
    // IEPS-RETE-FIJO-TASA-0.16
    return enumLabel;
}


export const SPLIT_IMPUESTO_UNIQUE_ID = (getImpuestos: FiscalpopImpuesto[]) => {
    const directImpuestos: FiscalpopImpuesto[] = getImpuestos.map(c => clearGqlTypename(c))

    // Some taxes have traslado and retention at the same time, we need to split them
    const splitImpuestos = directImpuestos.reduce((acc, tax) => {
        if (tax.traslado && tax.retencion) {
            const traslado = Object.assign({}, tax, { retencion: false });
            const retencion = Object.assign({}, tax, { traslado: false });
            return acc.concat([traslado, retencion])
        }
        return acc.concat([tax])
    }, []).map(t => ENUMERATE_IMPUESTO_OPTIONS(t))
    return splitImpuestos;
}