
import React, { useEffect, useState } from 'react';

import './Cotizaciones.list.scss';

import moment from 'moment'
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';
import IconButton from '../Forms/IconButton';
import { formatCurrencyStyled } from '../visuals/currency.formatted';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import { Cotizacion } from '../../models/Cotizaciones';
import { setSortOrder } from '../../utils/cotizaciones.formatting';
import StripeAccount from '../Setup/Setup.stripe.account';
import CotizacionesStatusLabel from './Cotizaciones.status';

import DateRangeInput from './Cotizaciones.list.datepicker';
import DatePicker from 'react-datepicker';
import unifiedMoment from '../../services/unifiedMoment';

moment.locale('es')

interface CotizacionesListProps extends BaseReact {
    currentUser: ProfileQL;
}




function CotizacionesListAdd() {
    const history = useHistory();
    const goToNew = () => {
        history.push({
            pathname: '/cotizacion/new'
        })
    }

    return (
        <div className="card table _cotizacionCard new" onClick={goToNew}>
            <div className="cardBody">
                <div className="newEntry">
                    <IconButton>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <path xmlns="http://www.w3.org/2000/svg" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                        </svg>
                    </IconButton>
                    <p className="small">
                        Nueva cotización
                    </p>
                </div>
            </div>
        </div>
    )
}

function CotizacionesListEntry({ cotizacion, history, authToken }: { cotizacion: Cotizacion, history: BaseReact['history'], authToken: string }) {
    const goToCotizacion = (cotizacionId: string) => {
        return () => {
            history.push({
                pathname: `/cotizacion/${cotizacionId}`
            })
        }
    }
    return (
        <div className="card table _cotizacionCard" onClick={goToCotizacion(cotizacion._id)}>
            <div className="cardTitle">
                <p >{cotizacion.name.substring(0, 41)}</p>
                <p className="micro">
                    {moment(cotizacion.createdAt).format('MMM DD, YYYY')}
                </p>
            </div>
            <div className="cardBody">
                <div className="cotEntry">
                    <p className={`_desc ${cotizacion.conceptos.length === 1 && cotizacion.conceptos[0].descripcion.length > 49 ? '_small' : ''}`}>
                        {cotizacion.conceptos.length === 1 ? cotizacion.conceptos[0].descripcion : `${cotizacion.conceptos.length} productos`}
                    </p>
                    <h2 className="_amount">
                        {formatCurrencyStyled(cotizacion.total)}
                    </h2>
                    <div className="row">
                        <CotizacionesStatusLabel cotizacion={cotizacion} />
                    </div>
                    {
                        cotizacion.uuid ?
                            <div className="stamped">
                                <p className="stamped">
                                    <a href={`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${authToken}?uuid=${cotizacion.uuid}`} rel="noopener noreferrer" download={true}>
                                        Factura emitida
                                    </a>
                                </p>
                            </div>
                            : ''
                    }
                </div>
            </div>
        </div>
    )
}

function CotizacionesList({ location, history, currentUser }: CotizacionesListProps) {
    const [cotizaciones, setCotizaciones] = useState<Cotizacion[]>([]);
    const [searchText, setSearchText] = useState('');
    const [dateRange, setDateRange] = useState<[Date, Date]>([unifiedMoment().endOf('day').subtract(1, 'month').toDate(), unifiedMoment().endOf('day').toDate()]);
    const [startDate, endDate] = dateRange;

    const [fetchCots] = useLazyQuery(graphqlSchema.FISCALPOP.COTIZACION.fetchCotizacionesByDate, {
        onCompleted: ({ fetchCotizacionesByDate }: { fetchCotizacionesByDate: Cotizacion[] }) => {
            setCotizaciones(fetchCotizacionesByDate.sort((a, b) => b.createdAt - a.createdAt).sort((a, b) => (setSortOrder(b) - setSortOrder(a))));
            // console.log('fetchCotizacionesByDate: ', fetchCotizacionesByDate);
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        if (!!startDate && !!endDate) {
            // If any is missing, it means it's still choosing date
            const _endDate = unifiedMoment(endDate?.getTime() || Date.now()).endOf('day').valueOf();
            fetchCots({
                variables: {
                    start: startDate?.getTime() || null,
                    end: _endDate,
                },
            })
        }
    }, [startDate, endDate, fetchCots])


    const searchFor = (e: React.ChangeEvent<HTMLInputElement>) => {
        const textValue = e.target.value;
        setSearchText(textValue);
    }

    const filterCotizacionesFromText = () => {
        if (!searchText) {
            return cotizaciones;
        }
        return cotizaciones.filter(c => {
            const lowCaseText = searchText.toLowerCase();
            const lowCaseTextWords = lowCaseText.split(' ').filter(s => !!s);
            return lowCaseTextWords.reduce((match, word) => {
                const nameMatch = c.name.toLowerCase().includes(word)
                const prodMatch = c.conceptos.map(c => c.descripcion).reduce((p, conc) => {
                    return p || conc.toLowerCase().includes(word)
                }, false as boolean);
                return match && (nameMatch || prodMatch)
            }, true as boolean)
        })
    }

    const hasStartedStripe = currentUser.stripeAccount && !!currentUser.stripeAccount.details_submitted;
    const requirementsMissing = !hasStartedStripe ? true : currentUser.stripeAccount.requirements.currently_due.length;
    const verificationDone = !hasStartedStripe ? false : !currentUser.stripeAccount.requirements.pending_verification.length;
    const stripeActionsNeeded = requirementsMissing || !verificationDone;


    return (
        <div id="CotizacionesList">
            <SectionTitleBar currentUser={currentUser} title="Cotizaciones" />
            <div className="cotizacionesListContent">

                {
                    !stripeActionsNeeded ?
                        <>
                            <h4>Cotizaciones</h4>
                            <div className="card table listTable">
                                <div className="cardTitle">
                                    <div className="titleFilter">
                                        <div className="dateWrapper">
                                            <DatePicker
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(date: [Date, Date]) => setDateRange(date)}
                                                customInput={<DateRangeInput />}
                                                dateFormat="MMMM dd, yyyy"
                                                locale="es"
                                                className='filterDatepicker'
                                            />
                                        </div>
                                        {
                                            currentUser.stripeAccount && !!currentUser.stripeAccount.details_submitted ?
                                                <div className="searchFor">
                                                    <span className="material-icons">
                                                        search
                                                    </span>
                                                    <input value={searchText} onChange={searchFor} type="text" />
                                                </div>
                                                : ''
                                        }
                                    </div>
                                </div>
                                <div className="cardBody">
                                    <div className="_cardList">
                                        <CotizacionesListAdd />
                                        {
                                            filterCotizacionesFromText().map((cotizacion) => (
                                                <CotizacionesListEntry cotizacion={cotizacion} history={history} authToken={currentUser.fiscalpopProfile.authToken} key={cotizacion._id} />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className="_cardEmpty">
                            <div className="_emptyExplainer">
                                <h2>¡Activa tu cuenta de stripe gratuita! </h2>
                                <h3>
                                    Permite que tus clientes te paguen cotizaciones a meses con tarjeta de crédito y débito
                                </h3>
                                <div className="_emptyStripeSection">
                                    <StripeAccount currentUser={currentUser} />
                                    <div className="_emptyCotFeatues">
                                        <div className="_feat">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30px" height="30px" viewBox="0 0 1280.000000 720.000000">
                                                <g transform="translate(-490,1020) scale(0.18,-0.18)" stroke="none">
                                                    <path d="M6255 6844 c-540 -35 -1107 -229 -1555 -532 -473 -320 -848 -752 -1091 -1256 -133 -276 -216 -536 -273 -856 -43 -240 -52 -602 -22 -880 40 -374 177 -822 362 -1188 l53 -103 -123 -367 c-68 -202 -191 -570 -274 -818 -84 -249 -152 -459 -152 -469 0 -9 13 -22 29 -28 26 -10 29 -14 24 -45 -6 -32 -5 -34 18 -27 41 13 936 298 1314 420 198 63 368 115 378 115 9 0 52 -17 95 -39 366 -184 756 -294 1171 -332 164 -14 498 -7 659 16 954 132 1766 659 2266 1468 163 264 318 632 401 952 79 307 117 688 96 982 -54 781 -356 1473 -881 2017 -509 527 -1157 853 -1895 952 -108 14 -482 26 -600 18z m391 -684 c357 -29 650 -108 959 -259 419 -206 770 -514 1030 -906 200 -301 323 -625 371 -979 23 -168 23 -508 0 -680 -163 -1209 -1161 -2141 -2372 -2217 -427 -26 -824 44 -1212 214 -107 47 -284 143 -339 183 -17 13 -39 24 -49 24 -9 0 -222 -65 -472 -145 -250 -80 -456 -145 -457 -143 -2 2 62 197 141 433 79 237 144 442 144 458 0 16 -18 53 -44 90 -418 599 -554 1426 -351 2127 45 152 82 245 155 390 200 391 505 732 880 982 473 316 1064 472 1616 428z" />
                                                    <path d="M5323 5236 c-23 -7 -56 -23 -75 -34 -51 -32 -199 -190 -245 -262 -147 -229 -180 -534 -92 -832 67 -225 149 -397 299 -629 190 -292 313 -450 510 -653 296 -305 545 -476 927 -635 282 -118 490 -185 607 -197 81 -8 258 20 362 58 144 52 309 168 373 262 64 96 130 313 138 457 l6 95 -31 36 c-22 24 -112 78 -294 176 -432 232 -487 254 -555 218 -17 -8 -81 -73 -141 -143 -178 -207 -215 -243 -245 -243 -38 0 -287 127 -403 205 -135 92 -223 166 -334 281 -132 137 -275 333 -355 486 l-18 36 72 79 c95 101 134 162 172 268 39 108 37 141 -20 290 -51 133 -92 243 -163 434 -58 157 -101 221 -161 240 -57 17 -287 22 -334 7z" />
                                                </g>
                                            </svg>
                                            <p>Comparte cotizaciones por Watsapp</p>
                                        </div>
                                        <div className="_feat">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="30px" height="30px">
                                                <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" />
                                            </svg>
                                            <p>Comparte por Mensaje, correo y redes sociales</p>
                                        </div>
                                        <div className="_feat">
                                            <span className="material-icons">
                                                attach_money
                                            </span>
                                            <p>Ofrece pagos y mensualidades con tarjeta</p>
                                        </div>
                                        <div className="_feat">
                                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="30" viewBox="0 0 24 24" width="30">
                                                <g>
                                                    <g>
                                                        <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" /><rect height="1.5" width="5" x="6.25" y="7.72" /><rect height="1.5" width="5" x="13" y="15.75" /><rect height="1.5" width="5" x="13" y="13.25" /><polygon points="8,18 9.5,18 9.5,16 11.5,16 11.5,14.5 9.5,14.5 9.5,12.5 8,12.5 8,14.5 6,14.5 6,16 8,16" /><polygon points="14.09,10.95 15.5,9.54 16.91,10.95 17.97,9.89 16.56,8.47 17.97,7.06 16.91,6 15.5,7.41 14.09,6 13.03,7.06 14.44,8.47 13.03,9.89" />
                                                    </g>
                                                </g>
                                            </svg>
                                            <p>Ajuste flexible de comisiones</p>
                                        </div>
                                        <div className="_feat">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="30px" height="30px">
                                                <path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h8v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z" />
                                            </svg>
                                            <p>Negocía con tus clientes y guarda el historial</p>
                                        </div>
                                        <div className="_feat">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
                                                <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5z" />
                                            </svg>
                                            <p>Asigna vencimiento en la cotización o tus descuentos</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="_emptyFeatures">
                                    <h1>Conoce como funcionan las cotizaciones</h1>
                                    <div className="_sideFeat left">
                                        <div className="_sideFeatExplain">
                                            <h3>Genera una cotización de forma muy similar a una factura</h3>
                                            <ol>
                                                <li>
                                                    <p>Define un receptor de la factura.</p>
                                                </li>
                                                <li>
                                                    <p>El RFC es opcional, tu cliente lo podrá agregar al facturar.</p>
                                                </li>
                                                <li>
                                                    <p>Si agregas correo, se enviará la cotización por correo también.</p>
                                                </li>
                                                <li>
                                                    <p>Agrega los conceptos como lo harías en la factura.</p>
                                                </li>
                                                <li>
                                                    <p>Define las condiciones de pago, empezando por ofrecer mensualidades o un solo pago.</p>
                                                </li>
                                                <li>
                                                    <p>Selecciona si la comisión la deseas pagar tu o cobrarla a tu cliente.</p>
                                                </li>
                                                <li>
                                                    <p>Asigna un descuento opcional y un vencimiento para este.</p>
                                                </li>
                                            </ol>
                                            <p>
                                                <b>
                                                    Generar cotizaciones no tiene ningún costo.
                                                </b>
                                            </p>
                                        </div>
                                        <img id="_generateCot" className="shadowed" alt="generar cotizaciones" src="https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/CotizacionGenerate_M.png" />
                                    </div>
                                    <div className="_sideFeat">
                                        <img id="_generateCot" alt="Cliente cotizaciones" src="https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/CotizacionClient_M.png" />
                                        <div className="_sideFeatExplain">
                                            <h3>¿Como ve tu cliente las cotizaciones?</h3>
                                            <p>Tu cliente recibe una página donde puede interactuar con la cotización, en lugar de un PDF estático.</p>
                                            <ul>
                                                <li>
                                                    <p>Aceptar o rechazar productos cotizados</p>
                                                </li>
                                                <li>
                                                    <p>Escribirle un mensaje sobre porque rechazó un producto y sugerir cambios.</p>
                                                </li>
                                                <li>
                                                    <p>Pagar con tarjeta de débito o crédito de forma segura.</p>
                                                </li>
                                                <li>
                                                    <p>Obtener su factura al momento de pagar si la requiere.</p>
                                                </li>
                                                <li>
                                                    <p>Obtener su factura al momento de pagar si la requiere.</p>
                                                </li>
                                            </ul>
                                            <p>
                                                Los cambios quedarán guardados como registro a futuro para aclaración con el cliente.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="_twoImageContain">
                                        <h3>¿Como interactuas tu con el cliente?</h3>
                                        <p>
                                            Una vez generada la cotización, la vista cambiará para mostrar el estatus de las actividades entre tu y tu cliente.
                                        </p>
                                        <p>
                                            Podrás consultar cambios solicitados, aceptarlos o rechazarlos, cancelar la cotización, marcarla como pagada y consultar el historial.
                                        </p>
                                        <div className="_twoImage">
                                            <img id="_generateCot" alt="Cliente cotizaciones" src="https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/CotizacionVendor1_M.png" />
                                            <img id="_generateCot" alt="Cliente cotizaciones" src="https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/CotizacionVendor2_M.png" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}


export default CotizacionesList;