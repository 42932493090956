import { StripeElementsOptions } from "@stripe/stripe-js";

export const getCardsElementOptions = (clientSetupSecret: string) => {
    const options = {
        // passing the client secret obtained in step 2
        clientSecret: clientSetupSecret,
        // Fully customizable with appearance API.
        appearance: {
            theme: 'flat',
            variables: {
                fontSizeBase: '15px',
                colorText: '#4D4F5C',
            }
        },
      };
    // Done to Possibly retrigger change observer  
    return Object.assign({}, options) as StripeElementsOptions;
}