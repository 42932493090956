import { BillConceptos } from "../models/Factura";


export const calculateTotalWidthComission = (conceptos: BillConceptos[], comissionStripePercent = (3.6), comissionFiscalPOP = 0.35) => {
    let subtotal = 0;
    const taxes: { [key: string]: number } = {};
    for (const concepto of conceptos) {
        const conceptSubtotal = (concepto.cantidad || 0) * (concepto.valorUnitario || 0);
        subtotal += conceptSubtotal;
        subtotal = Math.round(subtotal * 1000) / 1000;
        for (const tax of concepto.impuestos) {
            const taxName = tax.retencion ? `${tax.type.toUpperCase()} retenido` : tax.type.toUpperCase(); // tax.retencion
            const taxTotal = !!tax.tasa ? conceptSubtotal * tax.tasa : (tax.cuota || 0);
            // Remember that Retención is substracted from subtotal
            if (!!taxes[taxName]) {
                taxes[taxName] += tax.retencion ? -1 * taxTotal : 1 * taxTotal;
                taxes[taxName] = Math.round(taxes[taxName] * 1000) / 1000;
            } else {
                taxes[taxName] = tax.retencion ? -1 * taxTotal : 1 * taxTotal;
                taxes[taxName] = Math.round(taxes[taxName] * 1000) / 1000;
            }
        }
    }
    const _taxes = Object.keys(taxes).map(key => {
        return { key: key, value: taxes[key] };
    });
    const total = subtotal + _taxes.reduce((prev, curr) => {
        prev += curr.value;
        return Math.round(prev * 100) / 100;
    }, 0);
    // Stripe leaves IVA outside it's commission, add it
    const feeTransaction = (Math.round(total * ((comissionStripePercent * 1.16) / 100) * 100) / 100) + Math.round((3 * 1.16 * 100)) / 100
    const feeFiscalpop = Math.round(total * (comissionFiscalPOP / 100) * 100) / 100
    return { subtotal, taxes: _taxes, total, feeFiscalpop, feeTransaction };
}

export const calculateTotal = (conceptos: BillConceptos[]) => {
    let subtotal = 0;
    let descuento = 0;
    const taxes: { [key: string]: number } = {};
    for (const concepto of conceptos) {
        const conceptDescuento = (concepto.cantidad || 0) * (concepto.descuento || 0);
        const conceptSubtotal = (concepto.cantidad || 0) * (concepto.valorUnitario || 0);
        descuento += conceptDescuento;
        subtotal += conceptSubtotal;
        subtotal = Math.round(subtotal * 1000) / 1000;
        for (const tax of concepto.impuestos) {
            const taxName = tax.retencion ? `${tax.type.toUpperCase()} retenido` : tax.type.toUpperCase(); // tax.retencion
            const taxTotal = !!tax.tasa ? (conceptSubtotal - conceptDescuento) * tax.tasa : (tax.cuota || 0);
            // Remember that Retención is substracted from subtotal
            if (!!taxes[taxName]) {
                taxes[taxName] += tax.retencion ? -1 * taxTotal : 1 * taxTotal;
                taxes[taxName] = Math.round(taxes[taxName] * 1000) / 1000;
            } else {
                taxes[taxName] = tax.retencion ? -1 * taxTotal : 1 * taxTotal;
                taxes[taxName] = Math.round(taxes[taxName] * 1000) / 1000;
            }
        }
    }

    const _taxes = Object.keys(taxes).map(key => {
        return { key: key, value: taxes[key] };
    });
    const total = (subtotal - descuento) + _taxes.reduce((prev, curr) => {
        prev += curr.value;
        return Math.round(prev * 100) / 100;
    }, 0);
    return { subtotal, taxes: _taxes, total, descuento };
}

export const conceptoToTotales = (_conceptos: BillConceptos[]) => {
    return _conceptos.reduce((p, c) => {
        const baseValue = (c.cantidad * c.valorUnitario);
        const baseDiscount = (c.cantidad * (c.descuento || 0));
        const _iepsTaxes = c.impuestos.filter(tax => tax.type === 'ieps');
        const _ivaTaxes = c.impuestos.filter(tax => tax.type === 'iva');
        const _isrTaxes = c.impuestos.filter(tax => tax.type === 'isr');
        const [iepsRetenido, iepsTrasladado] = _iepsTaxes.reduce((_p, tax) => {
            if (tax.retencion) {
                _p[0] -= (tax.tasa ? tax.tasa * (baseValue - baseDiscount) : (tax.cuota ? tax.cuota * c.cantidad : 0))
            } else {
                _p[1] += (tax.tasa ? tax.tasa * (baseValue - baseDiscount) : (tax.cuota ? tax.cuota * c.cantidad : 0))
            }
            return _p;
        }, [0, 0])
        const postIEPSValue = (baseValue - baseDiscount) + iepsRetenido + iepsTrasladado
        const [ivaRetenido, ivaTrasladado] = _ivaTaxes.reduce((_p, tax) => {
            if (tax.retencion) {
                _p[0] -= (tax.tasa ? tax.tasa * postIEPSValue : (tax.cuota ? tax.cuota * c.cantidad : 0))
            } else {
                _p[1] += (tax.tasa ? tax.tasa * postIEPSValue : (tax.cuota ? tax.cuota * c.cantidad : 0))
            }
            return _p;
        }, [0, 0])
        const [isrRetenido] = _isrTaxes.reduce((_p, tax) => {
            if (tax.retencion) {
                _p[0] -= (tax.tasa ? tax.tasa * (baseValue - baseDiscount) : (tax.cuota ? tax.cuota * c.cantidad : 0))
            } else {
                _p[1] += (tax.tasa ? tax.tasa * (baseValue - baseDiscount) : (tax.cuota ? tax.cuota * c.cantidad : 0))
            }
            return _p;
        }, [0, 0])

        p.subtotal += baseValue;
        p.descuento += baseDiscount;
        p.ieps_retenido += iepsRetenido;
        p.ieps_trasladado += iepsTrasladado;
        p.iva_retenido += ivaRetenido;
        p.iva_trasladado += ivaTrasladado;
        p.isr += isrRetenido;
        p.total += Math.round(((baseValue - baseDiscount) + iepsRetenido + iepsTrasladado + ivaRetenido + ivaTrasladado + isrRetenido) * 100) / 100;
        return p;
    }, { subtotal: 0, descuento: 0, ieps_retenido: 0, ieps_trasladado: 0, iva_retenido: 0, iva_trasladado: 0, isr: 0, total: 0 })
}