
import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../../base.model';




import { useLazyQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../services/graphql.schema';
import Loading from '../../Animations/loadScreen';
import { BankProject } from '../../../models/Administracion/Bank.Projects';
import { ProjectionGastosView } from './Gastos/Projection.gastos.view';
import { ProjectionGastosNew } from './Gastos/Projection.gastos.new';
import { ProjectionBusinessNew } from './Business/Projection.business.new';
import { ProjectionBusinessView } from './Business/Projection.business.view';

interface ProjectsProps {
    history: BaseReact['history']
    projectId: string;
    editing?: boolean;
    recall: string;
}


export function ProjectionSwitchById({
    projectId,
    history,
    editing,
    recall
}: ProjectsProps) {

    const [expenseProject, setExpenseProject] = useState<BankProject<'expense' | 'business'>>(null)

    const [loadProjectById] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.getProjectionProjectById, {
        onCompleted: ({ getProjectionProjectById }: { getProjectionProjectById: BankProject<'expense'> }) => {
            console.log(`(ProjectionGastosView) <getProjectionProjectById> `, getProjectionProjectById);
            if (getProjectionProjectById) {
                setExpenseProject(getProjectionProjectById)
            } else {
                // No project or a forced URL change, return

            }

        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        console.log('ProjectionSwitchById UE: ', projectId, recall);
        loadProjectById({
            variables: {
                id: projectId
            }
        })
    }, [projectId, loadProjectById, recall])


    if (!expenseProject) {
        return (
            <div className='card'>
                <Loading display={true} />
            </div>
        )
    }
    if (expenseProject.type === 'expense') {
        if (editing) {
            return <ProjectionGastosNew history={history} editingProject={expenseProject} />
        }
        return (
            <ProjectionGastosView expenseProject={expenseProject} history={history} />
        )
    } else {
        if (editing) {
            return <ProjectionBusinessNew history={history} editingProject={expenseProject} />
        }
        return (
            <ProjectionBusinessView businessProject={expenseProject as any} history={history} />
        )
    }


}