
import React, { useState, useReducer, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { Subject } from 'rxjs';
import { BaseReact } from '../../base.model';
import { Bill, BillCartaPorte, BillConceptos, ToBillArguments, ToBillEvent } from '../../models/Factura';
import { FiscalpopProfile, ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';
import Input from '../Forms/Input';

import './CartaPorte.scss';
import { EMPTY_CFDI } from './helper/CartaPorte.empty';
import { Receptor, SerieTracker } from '../../models/Catalogos';
import { graphqlSchema } from '../../services/graphql.schema';
import { clearGqlTypename } from '../../utils/formatting';
import { debounceTime } from 'rxjs/operators';
import AutoComplete from '../Forms/Autocomplete';
import Select from '../Forms/Select';
import { REGIMEN_OPTIONS } from '../../utils/regimen.options';
import { CartaPorteTypeDialog, PorteType } from './CartaPorte.type.dialog';
import { CartaPorteConceptos } from './CartaPorte.conceptos';
import { CartaPorteUbicacion } from './CartaPorte.ubicacion';
import { CartaPorteComplemento_Ubicacion_Autotransporte, CartaPorteComplemento_Ubicacion_Destino, CartaPorteComplemento_Ubicacion_Operador, TrasladoRequestConceptos } from '../../models/Factura.complementos';
import { CartaPorteAutotransporte } from './CartaPorte.autotransporte';
import { CartaPorteOperador } from './CartaPorte.operador';
import Button from '../Forms/Button';
import { cartaPortePreMercanciasIsValid } from './helper/CartaPorte.validate';
import { CartaPorteMercancias } from './CartaPorte.mercancias';
import { CartaPorteInfoWhatIsDialog } from './dialogs/CartaPorte.info.whatIs';
import { CartaPorteInfoReceptorDialog } from './dialogs/CartaPorte.info.receptor';

interface CartaPorteProps extends BaseReact {
    currentUser: ProfileQL;
}

const REGIMEN_OPT = REGIMEN_OPTIONS.map((o) => ({ value: o.value, label: o.secondLabel }))

/**
 * Used for CFDI recovery that might get polluted by "null"
 */
function cfdiCleaner(cfdiRecovered: Bill, fpProfile: FiscalpopProfile) {
    if (!cfdiRecovered.lugarExpedicion) {
        cfdiRecovered.lugarExpedicion = fpProfile.lugarExpedicion;
    }
    if (!cfdiRecovered.fecha) {
        delete cfdiRecovered.fecha;
    }
    if (!cfdiRecovered.tipoDeComprobante) {
        delete cfdiRecovered.tipoDeComprobante;
    }
    return cfdiRecovered;
}

function billReducer(state: BillCartaPorte, { property, value }: { property: 'receptor' | 'conceptos' | 'cfdiRelacionados' | 'comprobante' | 'cartaPorte' | '_replace', value: any }): BillCartaPorte {
    if (property === 'receptor') {
        // Check for a state change in RFC for Moral o Fisica
        if ((state.receptor.rfc !== value.rfc) && value.rfc.length > 11) {
            if (value.rfc.length === 12) {
                // Moral, make sure  retention tax is applied
                const _conceptos = state.conceptos;
                const conceptos = _conceptos.map(c => ({
                    ...c, impuestos: [{
                        type: 'iva',
                        retencion: false,
                        tasa: 0.16
                    }, {
                        type: 'iva',
                        retencion: true,
                        tasa: 0.04
                    }]
                }))
                return Object.assign({}, state, { receptor: value, conceptos });
            } else {
                // Fisica, make sure  retention tax is applied
                const _conceptos = state.conceptos;
                const conceptos = _conceptos.map(c => ({
                    ...c, impuestos: [{
                        type: 'iva',
                        retencion: false,
                        tasa: 0.16
                    }]
                }))
                return Object.assign({}, state, { receptor: value, conceptos });
            }
        }
        return Object.assign({}, state, { receptor: value });
    }
    else if (property === 'conceptos') {
        return Object.assign({}, state, { conceptos: value });
    }
    else if (property === 'cartaPorte') {
        return Object.assign({}, state, { cartaPorte: value });
    }
    else if (property === 'comprobante') {
        return Object.assign({}, state, value);
    }
    else if (property === 'cfdiRelacionados') {
        return Object.assign({}, state, { cfdiRelacionados: value });
    }
    else if (property === '_replace') {
        return Object.assign({}, state, value);
    }
    else {
        // Force update only
        return Object.assign({}, state);
    }
}


function CartaPorte({ currentUser, location, history }: CartaPorteProps) {
    const { argumentid } = useParams<{ argumentid: string }>();

    const [tracekrOptions, setTrackerOptions] = useState<SerieTracker[]>([]);
    const [receptorOptions, setReceptorOptions] = useState<Receptor[]>([]);
    const [receptoSubject] = useState(new Subject<string>());
    const [receptoSubjectByRfc] = useState(new Subject<string>());

    const [validRequested, setValidRequested] = useState(false);

    const [usosCFDI, setUsosCFDI] = useState<{ value: string, label: string, forRegimen: string[] }[]>([]);
    const [formasPago, setFormasPago] = useState<{ value: string, label: string }[]>([]);


    const [currentStep, setCurrentStep] = useState<1 | 2>(1);

    const [isSelfReceptor, setIsSelfReceptor] = useState<boolean>(null)

    const [cfdi, dispatchBill] = useReducer(billReducer, EMPTY_CFDI(currentUser.fiscalpopProfile.rfc));
    const [billArgumento, _setBillArgumento] = useState<ToBillArguments<ToBillEvent>>({
        timeDefered: 0,
        multipayType: 0,
        multiPayments: 1,
        upfront: 0,
        frequency: 'monthly',
        specialType: 'cartaporte',
        isClosed: true,
        createdAt: Date.now(),
        billEvents: [],
        paymentEvents: []
    })

    /*
    useEffect(() => {
        setIsSelfReceptor(null)
    }, [])
    */

    useEffect(() => {
        const state = location.state as { billArgument: ToBillArguments<ToBillEvent>; billEvents: ToBillEvent[]; paymentEvents: ToBillEvent[] };
        console.log('FACTURA Recover State: ', state);
        if (state && state.billArgument) {
            // Some cases, lugar de Expedición might get lost when re-editing bills
            dispatchBill({ property: '_replace', value: cfdiCleaner(state.billArgument.MasterCFDI, currentUser.fiscalpopProfile) })
            _setBillArgumento({
                timeDefered: state.billArgument.timeDefered,
                multipayType: state.billArgument.multipayType,
                multiPayments: state.billArgument.multiPayments,
                upfront: state.billArgument.upfront,
                frequency: state.billArgument.frequency,
                isClosed: state.billArgument.isClosed,
                createdAt: state.billArgument.createdAt,
                specialType: state.billArgument.specialType || 'cartaporte',
                billEvents: [],
                paymentEvents: [],
            })
            // Update setIsSelfReceptor from state billArgument
            if (state.billArgument.MasterCFDI?.receptor?.rfc === currentUser.fiscalpopProfile.rfc) {
                setIsSelfReceptor(true);
            } else {
                setIsSelfReceptor(false);
            }
        } else {
            // Set seflReceptor to null to force user to select
            setIsSelfReceptor(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    // GRAPHQL ---------
    useQuery(graphqlSchema.PROFILE.CATALOGOS.getAllTrackers, {
        onCompleted: ({ getAllTrackers }: { getAllTrackers: SerieTracker[] }) => {
            setTrackerOptions(getAllTrackers.filter(t => !t.receptorRfc));
            const trackerUsing = getAllTrackers.find(t => t.serie === cfdi.serie);
            if (trackerUsing && trackerUsing.folio) {
                dispatchBill({ property: 'comprobante', value: { folio: `${trackerUsing.folio}` } })
            }
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        },
        fetchPolicy: 'cache-and-network'
    })
    const [getByName] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByName, {
        onCompleted: ({ getClienteByName }: { getClienteByName: Receptor[] }) => {
            setReceptorOptions(getClienteByName.map(c => clearGqlTypename(c)));
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })
    useQuery(graphqlSchema.FISCALPOP.USO_CFDI.getUsoCfdi, {
        onCompleted: ({ getUsoCfdi }: { getUsoCfdi: { clave: string, descripcion: string, forRegimen: string[] }[] }) => {
            console.log('Got Usos CFDI: ', getUsoCfdi);
            setUsosCFDI(getUsoCfdi.map(ucfdi => ({ value: ucfdi.clave, label: `(${ucfdi.clave}) ${ucfdi.descripcion}`, forRegimen: ucfdi.forRegimen })).sort((a, b) => a.value.localeCompare(b.value)));
        },
        onError: (e) => {
            console.error('Error getting uso CFDI: ', e);
        }
    })
    useQuery(graphqlSchema.FISCALPOP.FORMA_PAGO.getFormaPago, {
        onCompleted: ({ getFormaPago }: { getFormaPago: { key: string, label: string }[] }) => {
            //console.log('Got Usos CFDI: ', getFormaPago);
            setFormasPago(getFormaPago.map(gFP => ({ value: gFP.key, label: `(${gFP.key}) ${gFP.label}` })));
        },
        onError: (e) => {
            console.error('Error getting uso CFDI: ', e);
        }
    })
    const [getByRfc] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByRfc, {
        onCompleted: ({ getClienteByRfc }: { getClienteByRfc: Receptor[] }) => {
            setReceptorOptions(getClienteByRfc.map(c => clearGqlTypename(c)));
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })
    // ---------------

    useEffect(() => {
        const s = receptoSubject.pipe(debounceTime(200)).subscribe(s => {
            getByName({ variables: { name: s } })
        })
        const s2 = receptoSubjectByRfc.pipe(debounceTime(200)).subscribe(s => {
            getByRfc({ variables: { rfc: s } })
        })
        getByName({ variables: { name: '' } })
        return () => {
            s.unsubscribe();
            s2.unsubscribe();
        }
    }, [receptoSubject, receptoSubjectByRfc, getByName, getByRfc])


    // CFDI Handlers
    // =============================================

    const handleComprobanteChange = (property: 'formaPago' | 'metodoPago' | 'serie' | 'folio') => (value: any) => {
        const comprobante = Object.assign({}, cfdi);
        comprobante[property] = value;
        if (property === 'serie') {
            const tOption = tracekrOptions.find(t => t.serie === value);
            if (!!tOption) {
                comprobante['folio'] = `${tOption.folio}`;
            }
        }
        dispatchBill({ property: 'comprobante', value: comprobante });
    }

    const handleReceptorChange = (property: 'nombre' | 'rfc' | 'usoCFDI' | 'email' | 'regimen' | 'zip') => (value: string) => {
        const receptor = Object.assign({}, cfdi.receptor);
        if (property === 'rfc') {
            value = (value || '').toUpperCase();
        }
        if (property === 'email') {
            value = (value || '').toLowerCase();
        }
        receptor[property] = value;
        dispatchBill({ property: 'receptor', value: receptor });
        if (property === 'nombre') {
            receptoSubject.next(value);
        }
        if (property === 'rfc') {
            receptoSubjectByRfc.next(value);
        }
    }

    const handleReceptorAutofill = (receptorAsString: string) => {
        const receptor: Receptor = JSON.parse(receptorAsString);
        console.log('Receptor to add: ', receptor);
        const _receptor = Object.assign({}, cfdi.receptor, { nombre: receptor.name, rfc: receptor.rfc, customerId: receptor._id });
        if (receptor.email) {
            _receptor.email = receptor.email;
        }
        if (receptor.zip) {
            _receptor.zip = receptor.zip;
        }
        if (receptor.regimen) {
            _receptor.regimen = receptor.regimen;
        }
        dispatchBill({ property: 'receptor', value: _receptor });
    }

    const validateRegimenUsoCfdi = () => {
        const canUseByRegimen = !usosCFDI.length ? true : usosCFDI.find(u => u.value === cfdi.receptor.usoCFDI).forRegimen.includes(cfdi.receptor.regimen)
        const invalidUsoCfdi = !!cfdi.receptor.usoCFDI && !!cfdi.receptor.regimen && !canUseByRegimen ? 'Uso de CFDI no aplica para el régimen de receptor' : ''
        return invalidUsoCfdi;
    }

    const onConceptoDispatch = (conceptos: BillConceptos[]) => {
        dispatchBill({ property: 'conceptos', value: conceptos });
    }

    const onOrigenDispatch = (ubicacion: CartaPorteComplemento_Ubicacion_Destino) => {
        const _cartaPorte = cfdi.cartaPorte;
        _cartaPorte.ubicacion.origen = ubicacion
        // Check that origen dates and destino dates are correct
        if (_cartaPorte.ubicacion.origen.fecha > _cartaPorte.ubicacion.destino.fecha) {
            // >> destino should be at least 1 hour more than origin
            _cartaPorte.ubicacion.destino.fecha = (_cartaPorte.ubicacion.origen.fecha + (1000 * 60 * 60 * 1))
        }
        dispatchBill({ property: 'cartaPorte', value: _cartaPorte });
    }

    const onDestinoDispatch = (ubicacion: CartaPorteComplemento_Ubicacion_Destino) => {
        const _cartaPorte = cfdi.cartaPorte;
        _cartaPorte.ubicacion.destino = ubicacion
        if (!(_cartaPorte.ubicacion.origen.fecha < _cartaPorte.ubicacion.destino.fecha)) {
            // >> destino should be at least 1 hour more than origin
            _cartaPorte.ubicacion.destino.fecha = (_cartaPorte.ubicacion.origen.fecha + (1000 * 60 * 60 * 1))
        }
        dispatchBill({ property: 'cartaPorte', value: _cartaPorte });
    }

    const onAutotransporteDispatch = (autotransporte: CartaPorteComplemento_Ubicacion_Autotransporte) => {
        const _cartaPorte = cfdi.cartaPorte;
        _cartaPorte.autotransporte = autotransporte
        dispatchBill({ property: 'cartaPorte', value: _cartaPorte });
    }

    const onOperadorDispatch = (operadores: CartaPorteComplemento_Ubicacion_Operador[]) => {
        const _cartaPorte = cfdi.cartaPorte;
        _cartaPorte.operadoresTransporte = operadores
        dispatchBill({ property: 'cartaPorte', value: _cartaPorte });
    }

    const onMercanciasDispatch = (mercancias: TrasladoRequestConceptos[]) => {
        const _cartaPorte = cfdi.cartaPorte;
        _cartaPorte.mercancias = mercancias
        dispatchBill({ property: 'cartaPorte', value: _cartaPorte });
    }

    // >> Carta Porte Type

    const _setSelfReceptor = () => {
        const receptor: BillCartaPorte['receptor'] = {
            nombre: currentUser.fiscalpopProfile.nombre,
            rfc: currentUser.fiscalpopProfile.rfc,
            regimen: currentUser.fiscalpopProfile.regimenFiscal,
            zip: currentUser.fiscalpopProfile.lugarExpedicion,
            usoCFDI: 'S01'
        }
        const comprobante = Object.assign({}, cfdi);
        comprobante.receptor = receptor;
        comprobante.tipoDeComprobante = 'T';
        dispatchBill({ property: 'comprobante', value: comprobante });
    }

    const _setExternalReceptor = () => {
        const receptor: BillCartaPorte['receptor'] = {
            nombre: '',
            rfc: '',
            regimen: '',
            zip: '',
            usoCFDI: 'G03'
        }
        const comprobante = Object.assign({}, cfdi);
        comprobante.receptor = receptor;
        comprobante.tipoDeComprobante = 'I';
        dispatchBill({ property: 'comprobante', value: comprobante });
    }

    const onRequestCancel = () => {
        history.goBack()
    }

    const onRequestType = (type: PorteType) => {
        if (type === 'internal') {
            setIsSelfReceptor(true)
            _setSelfReceptor()

        } else {
            setIsSelfReceptor(false)
            _setExternalReceptor()
        }
    }

    const onToMain = () => {
        const cartaPorteMainIsValid = cartaPortePreMercanciasIsValid(cfdi, currentUser.fiscalpopProfile.rfc)
        if (cartaPorteMainIsValid) {
            // Has error activation  for receptor
            setValidRequested(true);
        }
        setCurrentStep(1);
    }

    const onToMercancias = () => {
        setCurrentStep(2);
    }

    const onToPreviewSubmit = () => {
        console.log('PREVIEW CARTA PORTE (billArgumento): ', billArgumento)
        console.log('PREVIEW CARTA PORTE (cfdi): ', cfdi);
        // Make sure a validation is executed if necessary
        const _billArgument = Object.assign({}, billArgumento)
        _billArgument.MasterCFDI = cfdi;
        _billArgument.specialType = 'cartaporte';
        const pathName = argumentid ? `/facturacion/cartaporte/preview/${argumentid}` : '/facturacion/cartaporte/preview';
        history.push({
            pathname: pathName,
            state: { billArgument: _billArgument }
        })
    }


    const cartaPorteIsValid = cartaPortePreMercanciasIsValid(cfdi, currentUser.fiscalpopProfile.rfc)

    // ---------------------------------------------

    const receptorIsMoral = cfdi.receptor.rfc.length === 12 && (cfdi.receptor.rfc !== currentUser.fiscalpopProfile.rfc);

    const trackerToOptions = tracekrOptions.map(t => ({ label: t.serie, value: t.serie, template: <p className="_serie">{t.serie} <span>(folio: {t.folio})</span></p> }));
    const receptorNameOptions = receptorOptions.map(r => ({ label: r.name, value: JSON.stringify(r), template: <div><p className="_big">{r.name}</p><p className="_small">{r.rfc}</p></div> }))
    const receptorRFCOptions = receptorOptions.map(r => ({ label: r.rfc, value: JSON.stringify(r), template: <div><p className="_big">{r.rfc}</p><p className="_small">{r.name}</p></div> }))

    const invalidUsoCfdi = validateRegimenUsoCfdi();

    const usosCFDIOptions = usosCFDI.map((u) => {
        const disabled = !u.forRegimen.length ? false : !u.forRegimen.includes(cfdi.receptor.regimen)
        return Object.assign({}, u, { disabled })
    })



    // Render blocks
    // ------------------------

    const renderMainCartaPorte = () => {
        return (
            <div className="facturarContent">
                <div>
                    <h4>Receptor</h4>
                    <div className={`card table`}>
                        <CartaPorteInfoReceptorDialog isSelfReceptor={isSelfReceptor} />
                        <div className="cardBody">
                            <div className='row three sub-sm-two sm-gap-compressed xs-one' id="ForReceptoExternal">
                                <AutoComplete label="Nombre" hasError={validRequested && !cfdi.receptor.nombre} value={cfdi.receptor.nombre} placeholder="Razón social de receptor" type="text"
                                    disabled={isSelfReceptor}
                                    noChangeOnSelect={true} options={receptorNameOptions} forcePropsValue={true} onChange={handleReceptorChange('nombre')} onSelect={handleReceptorAutofill} />
                                <AutoComplete label="RFC" hasError={validRequested && !cfdi.receptor.rfc} value={cfdi.receptor.rfc} placeholder="RFC de receptor" type="text"
                                    disabled={isSelfReceptor}
                                    noChangeOnSelect={true} options={receptorRFCOptions} forcePropsValue={true} onChange={handleReceptorChange('rfc')} onSelect={handleReceptorAutofill} />
                                <div className='row lg-hidden' />
                                <Select label="Régimen" hasError={validRequested && !cfdi.receptor.regimen} value={cfdi.receptor.regimen} onChange={handleReceptorChange('regimen')} placeholder="Régimen fiscal del receptor" options={REGIMEN_OPT} disabled={cfdi.receptor.rfc === 'XAXX010101000' || isSelfReceptor} />

                                <Select label={'Uso CFDI'} hasError={(validRequested && !cfdi.receptor.usoCFDI) || !!invalidUsoCfdi} errorLabel={invalidUsoCfdi} value={cfdi.receptor.usoCFDI} options={usosCFDIOptions} onChange={handleReceptorChange('usoCFDI')} disabled={isSelfReceptor} />
                                <Input label="Código Postal" hasError={validRequested && ((cfdi.receptor.zip || '').length !== 5)} value={cfdi.receptor.zip} onChange={handleReceptorChange('zip')} type="text" placeholder="Codigo Postal Domicilio Fiscal" disabled={cfdi.receptor.rfc === 'XAXX010101000' || isSelfReceptor} />

                                <AutoComplete label="Serie de factura" value={cfdi.serie} onChange={handleComprobanteChange('serie')} type="text" placeholder="Serie Factura"
                                    noChangeOnSelect={true} options={trackerToOptions} forcePropsValue={true} onSelect={handleComprobanteChange('serie')} disabled={isSelfReceptor} />

                                <Input label="Folio" value={cfdi.folio} onChange={handleComprobanteChange('folio')} type="text" placeholder="Folio Factura" disabled={isSelfReceptor} />
                                <Input label="Correo (Opcional)" value={cfdi.receptor.email || ''} onChange={handleReceptorChange('email')} type="text" placeholder="Email" disabled={isSelfReceptor} />
                            </div>
                        </div>
                    </div>
                </div>
                <CartaPorteConceptos
                    isSelfReceptor={isSelfReceptor}
                    receptorIsMoral={receptorIsMoral}
                    cfdi={cfdi}
                    currentUser={currentUser}
                    onConceptoDispatch={onConceptoDispatch}
                />
                <CartaPorteUbicacion
                    cartaPorte={cfdi.cartaPorte}
                    ubicacion={cfdi.cartaPorte.ubicacion.origen}
                    currentUser={currentUser}
                    title="Origen"
                    isDestino={false}
                    rfcDefault={currentUser.fiscalpopProfile.rfc}
                    isSelfReceptor={isSelfReceptor}
                    onUbicacionDispatch={onOrigenDispatch}
                    dateMax={null}
                />
                <CartaPorteUbicacion
                    cartaPorte={cfdi.cartaPorte}
                    ubicacion={cfdi.cartaPorte.ubicacion.destino}
                    currentUser={currentUser}
                    title="Destino"
                    isDestino={true}
                    rfcDefault={cfdi.receptor.rfc || currentUser.fiscalpopProfile.rfc}
                    isSelfReceptor={isSelfReceptor}
                    onUbicacionDispatch={onDestinoDispatch}
                    dateMin={cfdi.cartaPorte?.ubicacion?.origen?.fecha || null}
                />
                <CartaPorteAutotransporte
                    autotransporte={cfdi.cartaPorte.autotransporte}
                    onAutotransporteDispatch={onAutotransporteDispatch}
                />
                <CartaPorteOperador
                    operador={cfdi.cartaPorte.operadoresTransporte}
                    onOperadorDispatch={onOperadorDispatch}
                />
                {
                    !cartaPorteIsValid.valid ?
                        <div className='pendingToValidate'>
                            <b>
                                Revisa tus datos:
                            </b>
                            <p>
                                {cartaPorteIsValid.reason}
                            </p>
                        </div>
                        : null
                }
                <div className='row two actions sm-one'>
                    <div className='start centerY'>
                        <Button secondary={true}>
                            <span>
                                Atrás
                            </span>
                        </Button>
                    </div>
                    <div className='end centerY'>
                        <Button primary={true} disabled={!cartaPorteIsValid.valid} handleClick={onToMercancias} >
                            <span>
                                Continuar a Mercancías
                            </span>
                        </Button>

                    </div>
                </div>
            </div>
        )
    }

    const renderMercancias = () => {
        return (

            <div className="facturarContent">

                <CartaPorteMercancias
                    authToken={currentUser.fiscalpopProfile.authToken}
                    mercancias={cfdi.cartaPorte.mercancias}
                    onMercanciasDispatch={onMercanciasDispatch}
                    onToPreview={onToPreviewSubmit}
                    backToBase={onToMain}
                    isSelfReceptor={isSelfReceptor}
                />
            </div>
        )
    }

    return (
        <div id="CartaPorte">
            <SectionTitleBar currentUser={currentUser} title={!!argumentid ? `Editar Carta Porte` : `Crear Carta Porte`} />
            {
                currentStep === 1 ?
                    <CartaPorteInfoWhatIsDialog />
                    : null
            }
            {
                currentStep === 1 ?
                    renderMainCartaPorte()
                    : null
            }
            {
                currentStep === 2 ?
                    renderMercancias()
                    : null
            }

            <CartaPorteTypeDialog
                isOpen={isSelfReceptor === null}
                onRequestCancel={onRequestCancel}
                onRequestType={onRequestType}
            />
        </div>
    )
}

export default CartaPorte