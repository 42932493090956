import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import React, { FormEvent, useEffect, useState } from 'react';

import Modal from 'react-modal';
import { CredentialUpdateSubscription, PaybookCredentialStatusResponse } from '../../../models/Administracion/Bank.Credentials';
import { PaybookOrganization } from '../../../models/Administracion/Banks';
import { graphqlSchema } from '../../../services/graphql.schema';
import Button from '../../Forms/Button';
import Input from '../../Forms/Input';

import './CredentialDialog.twofa.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        padding: '1em',
        border: 'none',
        background: 'transparent'
    }
};

function CredentialDialogTwoFa() {

    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [organizations, setOrganizations] = useState<PaybookOrganization[]>([])

    const [organizationId, setOrganizationId] = useState<string>('')
    const [siteId, setSiteId] = useState<string>('')
    const [jobId, setJobId] = useState<string>('')
    const [credentials, setCredentials] = useState<{ [key: string]: string }>({})

    const [twoFaStatus, setTwoFaStatus] = useState<PaybookCredentialStatusResponse>(null)

    const [timerId, setTimerId] = useState<NodeJS.Timer>(null)
    const [timeoutAt, setTimoutAt] = useState<number>(null)
    const [timeoutNow, setTimoutNow] = useState<number>(null)

    const [twoFaTimedOut, setTwoFaTimedOut] = useState(false);

    // For Logo display
    useQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getOrganizations, {
        onCompleted: ({ getOrganizations }: { getOrganizations: PaybookOrganization[] }) => {
            setOrganizations(getOrganizations)
        },
        fetchPolicy: 'cache-first'
    })

    useSubscription<CredentialUpdateSubscription>(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.bankCredentialUpdates, {
        onSubscriptionData: ({ subscriptionData }) => {
            console.log(`<bankCredentialUpdates> subscriptionData`, subscriptionData);
            const bankCredentialUpdates = subscriptionData.data.bankCredentialUpdates;


            const lastState = bankCredentialUpdates.status[bankCredentialUpdates.status.length - 1]
            // Only when TWO FA is requested is this dialog updated, otherwise any credential change would re-write this dialog partially
            if (lastState.code === 410) {
                setSiteId(bankCredentialUpdates.id_site)
                setOrganizationId(bankCredentialUpdates.id_site_organization);
                setTwoFaStatus(lastState);
                setJobId(bankCredentialUpdates.id_job)
                setOpen(true);
                // Reset 2FA timeout, 
                // >> Cases where users re-load the page and start with 410 and then a 411, if they retry after the 411 state was received, the 2fa timeout would be already set and disallow input 
                setTwoFaTimedOut(false);
            }
            if (lastState.code === 411) {
                // TWOFA Timeout
                setTwoFaTimedOut(true);
            }
        }
    });


    const closeTwoFaDialog = () => {
        if (timerId) {
            clearInterval(timerId);
        }
        setTimoutAt(null)
        setTimoutNow(null)
        setTimerId(null)
        setTwoFaTimedOut(false)
        setOpen(false);
    }

    const [submitTwoFaData] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.submitTwoFA, {
        onCompleted: ({ submitTwoFA }: { submitTwoFA: boolean }) => {
            console.log('submitTwoFA: ', submitTwoFA);
            if (submitTwoFA) {
                // true, was submitted correctly
                closeTwoFaDialog()
            } else {
                // TODO, false - handle error
            }
        },
        onError: (e) => {
            console.error('Error Adding Credentials: ', e);
            setIsSubmitting(false);
        }
    });

    useEffect(() => {
        if (open && !timerId) {
            setTimoutAt(Date.now() + (1000 * 60 * 1.5))
            setTimoutNow(Date.now())
            const timeoutId = setInterval(() => {
                setTimoutNow(Date.now())
            }, 1000)
            setTimerId(timeoutId);
        } else if (!open && !!timerId) {
            clearInterval(timerId);
        }
    }, [open, timerId])


    useEffect(() => {
        if (timerId && (timeoutAt && timeoutNow) && ((timeoutAt - timeoutNow) < 1000)) {
            // Timed out
            clearInterval(timerId)
            setTimoutAt(null)
            setTwoFaTimedOut(true);
        }
    }, [timeoutAt, timeoutNow, timerId])


    const onTwoFaSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        submitTwoFaData({
            variables: {
                jobId: jobId,
                tokenString: JSON.stringify(credentials),
            }
        })
    }

    const setCredential = (key: string) => (value: string) => {
        const _creds = Object.assign({}, credentials);
        _creds[key] = value;
        setCredentials(_creds);
    }

    const selectedOrg = organizations.find(o => o.id_site_organization === organizationId);
    const selectedSite = !selectedOrg ? null : selectedOrg.sites.find(s => s.id_site === siteId);

    // When 2FA has image, the Lock icon is not displayed
    const twoFaHasImage = twoFaStatus && twoFaStatus.twofa.some(fa => (fa.imgBase64File || fa.imgURL));

    // Disable submit button if any of the credentials is empty
    const submitDisabled = !twoFaStatus ? false : !twoFaStatus.twofa.every(cred => credentials[cred.name]);

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => null}
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Subir Token TWOFA"
        >
            <div className='card table' id="CredentialDialog">
                <div className="cardTitle withClose">
                    <p>Agregar Token</p>
                    <div className='_counterHold'>
                        {
                            timerId && timeoutAt && timeoutNow ?
                                <div className={`timer`}>
                                    <span className="material-icons">
                                        schedule
                                    </span>
                                    <p>
                                        Duración
                                    </p>
                                    <span>
                                        {Math.floor(((timeoutAt - timeoutNow) / 1000) / 60)}
                                        :
                                        {
                                            Math.abs(Math.floor(((timeoutAt - timeoutNow) / 1000) % 60)) < 10 ?
                                                `0${Math.abs(Math.floor(((timeoutAt - timeoutNow) / 1000) % 60))}`
                                                : Math.abs(Math.floor(((timeoutAt - timeoutNow) / 1000) % 60))
                                        }
                                    </span>
                                </div>
                                : (
                                    twoFaTimedOut ?
                                        <div className={`timer ${twoFaTimedOut ? 'timedOut' : ''}`}>
                                            <span className="material-icons">
                                                schedule
                                            </span>
                                            <p>
                                                Duración
                                            </p>
                                            <span>
                                                {Math.floor(0)}
                                                :
                                                {
                                                    `00`
                                                }
                                            </span>
                                        </div>
                                        :
                                        null
                                )
                        }
                    </div>
                </div>
                <form className='dialogContent' onSubmit={onTwoFaSubmit}>
                    {
                        selectedOrg && selectedSite ?
                            <div className='bankProfile'>
                                <div className='avatar'>
                                    <div
                                        key={selectedOrg.id_site_organization}
                                        className='avatarLogo'
                                        style={{ backgroundImage: `url('${selectedOrg.avatar}')` }}
                                    />
                                </div>
                                <div className='bankNames'>
                                    <p>
                                        {selectedOrg.name}
                                    </p>
                                    <p>
                                        <b>
                                            {selectedSite.name}
                                        </b>
                                    </p>
                                </div>
                            </div>
                            : null
                    }
                    {
                        twoFaTimedOut ?
                            <div className={`lickCircle timedOut`}>
                                <span className="material-icons">
                                    priority_high
                                </span>
                            </div>
                            :
                            !twoFaHasImage ?
                                <div className={`lickCircle`}>
                                    <span className="material-icons">
                                        lock
                                    </span>
                                </div>
                                : null
                    }
                    {
                        twoFaStatus ?
                            (!twoFaTimedOut ?
                                twoFaStatus.twofa.map((fa) => {
                                    const imageIs = fa.imgBase64File || fa.imgURL;
                                    return (
                                        <div className='tokenInput' key={fa.name} >
                                            {
                                                imageIs ?
                                                    <div className='tokenImage'>
                                                        <img src={imageIs} alt='' />
                                                    </div>
                                                    : null
                                            }
                                            <Input type={fa.type} label={fa.label}
                                                value={credentials[fa.name] || ''}
                                                onChange={setCredential(fa.name)}
                                                disabled={twoFaTimedOut}
                                            />
                                        </div>
                                    )
                                })
                                :
                                <div className='tokenTimeoutText'>
                                    <p>
                                        Se terminó el tiempo de conexión
                                    </p>
                                    <p className='small'>
                                        Intentalo de nuevo en <b>30 mins</b>.
                                    </p>
                                </div>
                            )
                            : null
                    }
                    {
                        twoFaStatus && !twoFaTimedOut ?
                            <div className='actions'>
                                <Button primary={true} type="submit" disabled={submitDisabled || isSubmitting}>
                                    <span>
                                        {isSubmitting ? 'Procesando Token' : 'Guardar'}
                                    </span>
                                </Button>
                            </div>
                            : null
                    }
                    {
                        twoFaTimedOut ?
                            <div className='actions'>
                                <Button primary={true} type="button" disabled={false} handleClick={closeTwoFaDialog}>
                                    <span>
                                        OK
                                    </span>
                                </Button>
                            </div>
                            : null
                    }
                </form>
            </div>
        </Modal>
    )
}

export default CredentialDialogTwoFa;