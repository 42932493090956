import moment from 'moment';
import React from 'react';
import { PaybookTransaction } from '../../../../models/Administracion/Bank.Transactions';
import { numberToCurrencyString } from '../../../../services/formatting';
import Checkbox from '../../../Forms/Checkbox';
import { formatCurrencyStyled } from '../../../visuals/currency.formatted';
import { CATEGORIES_LIST } from '../../helpers/categories.list';

import './Movement.entry.scss';



function MovementCategoryPill({
    movement,
    textLevel,
    justify,
    small,
}: { movement: PaybookTransaction, textLevel: 'category' | 'subcategory', justify?: 'start' | 'stretch' | 'end', small?: boolean }) {
    let color = null
    const catEntry = CATEGORIES_LIST.find(c => c.value === movement.budgetCategory)
    if (movement.projectId) {
        color = '#76D1BC'
    }
    else if (movement.budgetCategory) {
        color = catEntry?.color
    }
    if (movement.budgetCategory) {
        const text = movement.budgetSubcategory.split('-')
        return (
            <p className={`__categoryPill ${small ? '_small' : ''}`} style={{ background: color, justifySelf: justify || 'stretch' }}>
                {textLevel === 'category' ? text[0] : text[1]}
            </p>
        )
    }
    if (movement.projectId) {
        const text = movement.projectName;
        return (
            <p className={`__categoryPill ${small ? '_small' : ''}`} style={{ background: color, justifySelf: justify || 'stretch' }}>
                {textLevel === 'category' ? 'Proyecto' : text}
            </p>
        )
    }
    // Default uncategorizewd or Project assigned
    return (
        <p className={`__categoryPill ${small ? '_small' : ''}`} style={{ color: '#7D7F8F', justifySelf: justify || 'stretch', background: '#E4E4EB' }}>
            Sin Categoría
        </p>
    )
}

function MovementEntry({ movement, selected, onToggle }: { movement: PaybookTransaction, selected: boolean, onToggle: (bool: boolean) => void }) {

    const renderTypeIcon = () => {
        return (
            movement.amount > 0 ?
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                    <path d="M3.0637 29.2959L31.7416 35.4368L32.8105 30.6369" stroke="#60CB5E" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1.9541 29.7398V12.6521H33.6884V29.7398H1.9541Z" stroke="#60CB5E" strokeWidth="3" strokeLinejoin="round" />
                    <circle cx="17.4884" cy="21.085" r="5.32604" fill="#60CB5E" />
                    <circle cx="26.3652" cy="21.085" r="1.77535" fill="#60CB5E" />
                    <circle cx="8.61177" cy="21.085" r="1.77535" fill="#60CB5E" />
                    <circle cx="3.72945" cy="28.1864" r="1.77535" fill="#60CB5E" />
                    <circle cx="3.72945" cy="13.9836" r="1.77535" fill="#60CB5E" />
                    <circle cx="32.135" cy="13.9836" r="1.77535" fill="#60CB5E" />
                    <circle cx="32.135" cy="28.1864" r="1.77535" fill="#60CB5E" />
                    <circle cx="30.3596" cy="33.5124" r="1.77535" fill="#60CB5E" />
                    <path d="M12.1624 7.32604L17.4884 2L22.8144 7.32604" stroke="#60CB5E" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <path d="M3.12631 19.4602L32.2341 25.735L33.319 20.8304" stroke="#D72020" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 19.9137V2.45352H34.2099V19.9137H2Z" stroke="#D72020" strokeWidth="3" strokeLinejoin="round" />
                    <ellipse cx="17.7672" cy="11.0702" rx="5.40586" ry="5.44214" fill="#D72020" />
                    <ellipse cx="26.7768" cy="11.0702" rx="1.80195" ry="1.81405" fill="#D72020" />
                    <ellipse cx="8.75728" cy="11.0702" rx="1.80195" ry="1.81405" fill="#D72020" />
                    <ellipse cx="3.80195" cy="18.3265" rx="1.80195" ry="1.81405" fill="#D72020" />
                    <ellipse cx="3.80195" cy="3.81405" rx="1.80195" ry="1.81405" fill="#D72020" />
                    <ellipse cx="32.6333" cy="3.81405" rx="1.80195" ry="1.81405" fill="#D72020" />
                    <ellipse cx="32.6333" cy="18.3265" rx="1.80195" ry="1.81405" fill="#D72020" />
                    <ellipse cx="30.8313" cy="23.7686" rx="1.80195" ry="1.81405" fill="#D72020" />
                    <path d="M12.3613 28.7418L17.7672 34.184L23.1731 28.7418" stroke="#D72020" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
        )
    }

    return (
        <div className='movement'>
            <div className='_type'>
                {
                    renderTypeIcon()
                }
            </div>
            <div className='_category'>
                <MovementCategoryPill movement={movement} textLevel={'category'} />
            </div>
            <div className='_description'>
                <p>{movement.description}</p>
                <p className='small'>
                    {moment(movement.dt_transaction).format('DD MMMM YYYY')}
                </p>
                {
                    movement.budgetCategory || movement.projectName ?
                        <MovementCategoryPill movement={movement} textLevel={'subcategory'} justify={'start'} small={true} />
                        : null
                }
            </div>
            <div className='_monto'>
                <p>{formatCurrencyStyled(movement.amount)}</p>
                <div className='iconMobile'>
                    {renderTypeIcon()}
                </div>
            </div>
            <div className='_checkbox'>
                <Checkbox
                    color='#AC2E78'
                    onChange={onToggle}
                    value={selected}
                />
            </div>
        </div>
    )
}

export default MovementEntry;