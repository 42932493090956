import React from 'react';
import Modal from 'react-modal';

import { StripePaymentOrder } from '../../../models/Stripe';

import './Spei.confirm.scss';
import Button from '../../Forms/Button';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '500px',
        padding: '0px',
        border: 'none',
        boxShadow: '0px 4px 16px rgba(0, 60, 128, 0.1)'
    }
};

interface SpeiConfirmProps {
    email: string;
    isOpen: boolean;
    speiOrder: StripePaymentOrder;
    onRequestClose: (response?: any) => void;
}

function SpeiConfirm({ email, isOpen, onRequestClose, speiOrder }: SpeiConfirmProps) {
    const pesosAmount = !speiOrder ? 0 : (speiOrder.amount / 100)
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => onRequestClose()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Producto Edit Modal"
        >{
                !!speiOrder ?
                    <div id="SpeiConfirm">
                        <div className="speiOrder">
                            <div className="bindLogo">
                                <img src="https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/SPEI-LOGO.png" alt="Spei Logo" />
                                <div className="monto">
                                    <h2>$ {pesosAmount.toFixed(0)}<span className="cents">.{pesosAmount.toFixed(2).split('.')[1]}</span> MXN</h2>
                                </div>
                            </div>
                            <div className="infoEntry">
                                <b>Banco:</b>
                                <p>{speiOrder.spei.bank}</p>
                            </div>
                            <div className="infoEntry">
                                <b>CLABE:</b>
                                <p>{speiOrder.spei.clabe}</p>
                            </div>
                            <div className="infoEntry">
                                <b>Referencia:</b>
                                <p>{speiOrder.spei.reference}</p>
                            </div>
                            <p className="instructions small">
                                De ser posible, incluya la <b>referencia</b> mencionada arriba cuando realice su transferencia.
                            </p>
                            <p className='small'>
                                Se envió una copia de estas instrucciones al correo: <br /><b>{email}</b>
                            </p>
                            <Button secondary={true} handleClick={onRequestClose}>
                                <span>Cerrar</span>
                            </Button>
                        </div>
                    </div>
                    : ''}
        </Modal>
    )
}

export default SpeiConfirm;