import React from 'react';

interface SvgProps {
    width: number;
    color?: string;
}

function BriefcaseSvg({ width, color }: SvgProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 23 22" fill="none">
            <path d="M20 5.44531H2.22222C0.994923 5.44531 0 6.44024 0 7.66753V18.7786C0 20.0059 0.994923 21.0009 2.22222 21.0009H20C21.2273 21.0009 22.2222 20.0059 22.2222 18.7786V7.66753C22.2222 6.44024 21.2273 5.44531 20 5.44531Z" fill={color || "#9C9CD2"} />
            <path d="M15.5555 21V3.22222C15.5555 2.63285 15.3214 2.06762 14.9046 1.65087C14.4879 1.23413 13.9227 1 13.3333 1H8.88885C8.29948 1 7.73425 1.23413 7.3175 1.65087C6.90075 2.06762 6.66663 2.63285 6.66663 3.22222V21" stroke="#F4F4FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5555 4.33355V2.35824C15.5555 2.29276 15.3214 2.22995 14.9046 2.18365C14.4879 2.13734 13.9227 2.11133 13.3333 2.11133H8.88885C8.29948 2.11133 7.73425 2.13734 7.3175 2.18365C6.90075 2.22995 6.66663 2.29276 6.66663 2.35824V4.33355" stroke={color || "#9C9CD2"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default BriefcaseSvg;