import React, { useState, useEffect } from 'react';
import { ProfileQL } from '../../models/Profile';

import './ErrorToasts.scss';

interface ErrorToastProps {
    currentUser: ProfileQL;
}

// TODO, finish animation procedure!

export default function ErrorsToasts({currentUser}:ErrorToastProps) {
    const [toaster, setToaster] = useState<{ type: 'warning' | 'confirm' | 'error', message: string, id?: string, acknowledged: boolean, display: boolean }[]>([]);

    const [errorsStated, setErrorsStated] = useState(false);

    useEffect(() => {
        if(!!currentUser && !errorsStated){
            let toastPass:{ type: 'warning' | 'confirm' | 'error', message: string, id?: string, acknowledged: boolean, display: boolean }[] = [];
            if(currentUser.fiscalpopProfileStatus){
                // >> This warning is managed by the titlebar
                /*
                if(!currentUser.fiscalpopProfileStatus.cerModulus || !currentUser.fiscalpopProfileStatus.keyModulus){
                    toastPass.push({type: 'error', message:'Termina de subir tus certificados para poder facturar', acknowledged:false, display: true})
                } else 
                if(!currentUser.fiscalpopProfileStatus.modulusMatch){
                    toastPass.push({type: 'error', message:'Revisa que tu certificado y llave sean los mismos', acknowledged:false, display: true})
                }
                */
            } 
            if(currentUser.billing) {
                if(currentUser.billing.balance < 0){
                    toastPass.push({type: 'error', message:'Se terminaron tus créditos! agrega saldo para reactivar tu cuenta', acknowledged:false, display: true})
                } else if(!currentUser.billing.enabled){
                    toastPass.push({type: 'error', message:'Su cuenta está desactivada, revisa tu saldo o contacta a soporte', acknowledged:false, display: true})
                } 
            }
            if (currentUser?.professionalProfile?.subscriptionLastPaymentStatus === 'past_due') {
                toastPass.push({type: 'error', message:'Su cuenta presenta un saldo vencido, revisa los pagos en "Mi cuenta" para resolverlo', acknowledged:false, display: true})
            }
            toastPass.forEach(t => t.id = Math.random().toString(16).split('.')[1]);
            setToaster(toastPass);
            setErrorsStated(true)
        }
    }, [currentUser, errorsStated]);

    const closeErrToast = (id:string) => {
        return () => {
            const index = toaster.findIndex(t => t.id === id);
            toaster[index].display = false;
            setTimeout(() => {
                toaster[index].acknowledged = true;
            }, 350)
            setToaster(JSON.parse(JSON.stringify(toaster)))
        }
    }

    
    const toastToUse = toaster.filter(t => !t.acknowledged)[0];
    if(!toastToUse){
        return ( <div id="ERROR_TOASTS"></div>)
    }
    return (
        <div id="ERROR_TOASTS">
            {
                toastToUse ?
                <div className={`_toast ${toastToUse.type} ${!toastToUse.display ? '_getOut' : ''}`}>
                    <p>
                        {toastToUse.message}
                    </p>    
                    <i className="material-icons" onClick={closeErrToast(toastToUse.id)}>
                        close
                    </i>
                </div>
                : ''
            }
        </div>
    )
}