import moment from "moment";

const { jStat } = require('jstat');

interface Quantiles {
    ExtremeLow: number;
    Q1: number;
    median: number;
    Q3: number;
    ExtremeTop: number;
}

const derivate = (array: number[]) => {
    return array.reduce((p, c, i, arr) => {
        if (i !== 0) {
            p.push(c - arr[i - 1])
        }
        return p;
    }, [] as number[])
}


const simpleSmoothing = (
    alpha: number,
    dataSet: { value: number, monthTs: number }[]
) => {
    const yObserved = dataSet.sort((a, b) => a.monthTs - b.monthTs).map(a => a.value)
    const yForecast = [yObserved[0]];
    let t = 1;
    let yFt: number;
    for (const yObs of yObserved) {
        yFt = yForecast[t - 1] + alpha * (yObs - yForecast[t - 1]);
        yForecast.push(yFt)
        t++
    }
    // Ends with one more entry than original array
    // console.table({ yForecast, yObserved })
    return { yForecast, yFt };
}


// **** Stats SHARED
export const processQuantiles = (data: number[]) => {
    const quartiles: [number, number, number] = jStat.quantiles(data, [0.25, 0.5, 0.75], 1, 1)
    const IQR = quartiles[2] - quartiles[0];
    const maxRef = quartiles[2] + (1.5 * IQR)
    const minRef = quartiles[0] - (1.5 * IQR)
    let max = Math.max(...data.filter(diff => diff <= maxRef));
    if (max <= quartiles[2]) {
        // If max is not higher than 3rd quartile, there's probably not enough samples, fallback to maxRef
        max = maxRef;
    }
    let min = Math.min(...data.filter(diff => diff >= minRef));
    if (min >= quartiles[0]) {
        min = minRef;
    }
    return {
        originalData: data,
        generalQuartiles: {
            ExtremeLow: min,
            Q1: quartiles[0],
            median: quartiles[1],
            Q3: quartiles[2],
            ExtremeTop: max
        }
    }
}

export const sequencePredictSimple = (
    MONTHS_PREDICTED: number,
    lastEntryTs: number,
    baseProgress: { value: number, monthTs: number }[],
    allowNegative = false
) => {
    //let lastDatumMonthTs = baseProgress[baseProgress.length - 1].monthTs;
    const ALPHAS = [.2, .4, .6, 0.8];
    let lastDatumMonthTs = lastEntryTs;
    const monthsToEstimate = new Array(MONTHS_PREDICTED).fill(0, 0, MONTHS_PREDICTED).map((_, i) => i);
    let lastQuantilesRange: Quantiles = null;

    // console.log('sequencePredictSimple: ', baseProgress)
    let vectors = [baseProgress];

    let predictionVectors: { value: number, monthTs: number }[][] = []
    const data = monthsToEstimate.map((month) => {

        const x = moment(lastDatumMonthTs).startOf('month').add(month + 1, 'month').valueOf();
        const nextCycleVectors: { value: number, monthTs: number }[][] = []
        const cyclePredictions: number[] = []

        for (const vectorPath of vectors) {

            const predVectors = ALPHAS.map((alpha) => ({
                ...simpleSmoothing(alpha, vectorPath),
                alpha,
            }))


            predictionVectors.push(...predVectors.map(pV => pV.yForecast.map((yF, i) => {
                return ({
                    value: yF,
                    monthTs: moment(x).subtract((pV.yForecast.length - 1) - i, 'months').valueOf()
                })
            })));

            // 1. If Data is very homogeneous resulting quartiles would be extremely short
            // >>   Deffer to stDev if there's no clear trend
            const vectorY = vectorPath.map(vP => vP.value);
            const groupDerivativeMeans: number[] = []
            for (const e of predVectors) {
                // >> Forecast has innherit Error, use last predicted within set instead ?

                //const predDerivate = derivate(e.yForecast)
                const predDerivate = derivate(vectorY.concat([e.yFt]))
                const predDerivativeMean = jStat.mean(predDerivate);
                groupDerivativeMeans.push(predDerivativeMean);
            }
            // Alternate test for round 2 or greater
            let noStatisticalSignificance = true;
            if (lastQuantilesRange) {
                const ranges = Object.values(lastQuantilesRange)
                const [qMin, qMax] = jStat.tci(lastQuantilesRange.median, 0.05, ranges);
                const pass = predVectors.filter(pV => !(pV.yFt >= qMin) && (pV.yFt <= qMax))
                noStatisticalSignificance = (pass.length / predVectors.length) >= .5
            }

            // 68-95-99.7% rule as reference
            // >> If 3 times stdev is not biger than derivative mean, then use distribution approach
            const trendDerivativesStd = jStat.stdev(groupDerivativeMeans);
            const trendDerivativesMean = Math.abs(jStat.mean(groupDerivativeMeans))
            const _tolerance = 1.1;
            const hasViableTrend = noStatisticalSignificance && (trendDerivativesMean * _tolerance) < (3 * trendDerivativesStd)
            // console.log(`Has Viable Trend (${month}): `, {hasViableTrend, trendDerivativesStd, trendDerivativesMean, originalStdev: jStat.stdev(vectorY)})

            // 1.1 Divide intop alpha1 & 2 and aplha3 & 4 for best / worst case
            let closeRelationship: number;
            let longRelationship: number;

            const lowAlphaFt = (predVectors[0].yFt + predVectors[1].yFt) / 2;
            const highAlphaFt = (predVectors[2].yFt + predVectors[3].yFt) / 2;

            if (hasViableTrend && (month > 0)) {
                // 1.2a. Save each vector individual prediction for stats 
                // >> (Don't use prediction for first month seeding)
                cyclePredictions.push(...predVectors.map(pV => pV.yFt));
                closeRelationship = lowAlphaFt
                longRelationship = highAlphaFt
            } else {
                // Use std for relationship, (Don't ignore trend prediction as possible Outcomes)
                const alphaMean = jStat.mean(predVectors.map(pV => pV.yFt));
                const candidateValuesY = vectorY.concat([lowAlphaFt, highAlphaFt])

                // >> Old behaviour, effect is Box plot's 'mean'showed no skew
                // const [_innerMin, _innerMax] = jStat.tci(jStat.mean(candidateValuesY), .1, candidateValuesY)
                // const [min, max] = jStat.tci(jStat.mean(candidateValuesY), .05, candidateValuesY)

                // >> current behaviour, Box plot's 'mean' skew towards
                const [_innerMin, _innerMax] = jStat.tci(alphaMean, .1, candidateValuesY)
                const [min, max] = jStat.tci(alphaMean, .05, candidateValuesY)


                closeRelationship = min;
                longRelationship = max; // Max possitive
                // 1.2b. As no Clear trend was available, use dual TCI results
                // cyclePredictions.push(...[min, _innerMin, _innerMax, max]);
                cyclePredictions.push(...[min, _innerMin, lowAlphaFt, alphaMean, highAlphaFt, _innerMax, max]);
                // >> Take mean of last predictions 

            }

            // 2 create two new vectors
            nextCycleVectors.push(vectorPath.concat([{
                monthTs: x,
                value: closeRelationship
            }]))
            nextCycleVectors.push(vectorPath.concat([{
                monthTs: x,
                value: longRelationship
            }]))



        }

        // 3. Save Vector group for next iteration
        vectors = nextCycleVectors;

        // 4. From last predicted build next quantiles and return for this month
        const { generalQuartiles: forecastBox } = processQuantiles(cyclePredictions)
        lastQuantilesRange = forecastBox;
        const data = ({
            x: x,
            yTopOutlier: forecastBox.ExtremeTop,
            yTop: forecastBox.Q3,
            median: forecastBox.median,
            yBottom: forecastBox.Q1,
            yBottomOutlier: forecastBox.ExtremeLow,
        })
        if(!allowNegative) {
            Object.keys(data).forEach((k) => {
                // Expenses or Income cannot be NET < 0
                if (data[k as keyof typeof data] <= 0) {
                    data[k as keyof typeof data] = 0;
                }
            })
        }

        return data;
    })

    return { data, vectors, predictionVectors }
}