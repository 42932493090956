
import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../../../base.model';
import Input from '../../../Forms/Input';
import MoneySvg from '../../../visuals/svg/moneyIcon';

import { BankProject, BankProjectConcept_Expense, BankProjectConcept_Income } from '../../../../models/Administracion/Bank.Projects'

import moment from 'moment';
import Button from '../../../Forms/Button';
import ItemMenu from '../../../Forms/menu/MenuComponent';
import { numberToCurrencyString } from '../../../../services/formatting';
import unifiedMoment from '../../../../services/unifiedMoment';
import { ProjectionExpenseNewDialog } from '../Gastos/Projection.expense.dialog';
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../../services/graphql.schema';
import sharedToasterSubject from '../../../../services/shared.toasterSubject';

import './Projection.business.new.scss';
import BriefcaseSvg from '../../../visuals/svg/briefcaseIcon';
import { ProjectionIncomeNewDialog } from './Projection.income.dialog';
import { BusinessActivityGraph } from './Projection.business.graphs';

interface ProjectsProps {
    history: BaseReact['history']
    editingProject?: BankProject<'business'>
}

export function ProjectionBusinessNew({
    history,
    editingProject
}: ProjectsProps) {

    const [businessProject, setBusinessProject] = useState<BankProject<'business'>>({
        type: 'business',
        name: '',
        createdAt: moment().add(1, 'month').startOf('month').valueOf(),
        expenses: [],
        incomes: []
    })
    const [_openExpenses, setOpenExpenses] = useState(false);
    const [expenseToEdit, setExpenseToEdit] = useState<number>(null);

    const [_openIncome, setOpenIncome] = useState(false);
    const [incomeToEdit, setIncomeToEdit] = useState<number>(null);

    const [isSaving, setIsSaving] = useState(false);
    const [grphUpdateTrigger, setGraphUpdateTrigger] = useState(0)

    useEffect(() => {
        if (!!editingProject && !!editingProject._id) {
            setBusinessProject(editingProject)
        }
    }, [editingProject])

    const [_createBusinessProject] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.createBusinessProject, {
        onCompleted: ({ createBusinessProject }: { createBusinessProject: BankProject<'expense'> }) => {
            console.log('<ProjectionBusinessNew> createBusinessProject: ', createBusinessProject);
            sharedToasterSubject.next({ type: 'confirm', message: `Proyecto de negocio creado correctamente` })
            setIsSaving(false);
            history.push(`/administration/projection/${createBusinessProject._id}`)
        },
        onError: (e) => {
            console.error('<ProjectionBusinessNew> Error creating project: ', e.graphQLErrors);
            sharedToasterSubject.next({ type: 'error', message: `Error al crear proyecto de negocio: ${e.graphQLErrors[0].message}` })
            setIsSaving(false);
        }
    })

    const [_updateBusinessProject] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.updateBusinessProject, {
        onCompleted: ({ updateBusinessProject }: { updateBusinessProject: BankProject<'expense'> }) => {
            console.log('<ProjectionBusinessNew> updateBusinessProject: ', updateBusinessProject);
            sharedToasterSubject.next({ type: 'confirm', message: `Proyecto de negocio actualizado` })
            setIsSaving(false);
            history.push(`/administration/projection/${updateBusinessProject._id}?recall=${Date.now()}`)
        },
        onError: (e) => {
            console.error('<ProjectionBusinessNew> Error creating project: ', e.graphQLErrors);
            sharedToasterSubject.next({ type: 'error', message: `Error al crear proyecto de negocio: ${e.graphQLErrors[0].message}` })
            setIsSaving(false);
        }
    })

    const handleNameChange = (name: string) => {
        setBusinessProject(eP => ({ ...eP, name }));
    }

    const handleStartOfChange = (createdAt: Date) => {
        setBusinessProject(eP => ({ ...eP, createdAt: createdAt.getTime() }));
    }

    const handleAddExpense = (expense: BankProjectConcept_Expense) => {
        setBusinessProject(eP => {
            const expenses = eP.expenses.concat([expense])
            return ({ ...eP, expenses })
        });
        setGraphUpdateTrigger(Date.now())
        closeExpense()
    }

    const openExpense = () => {
        setOpenExpenses(true)
    }

    const closeExpense = () => {
        setOpenExpenses(false)
    }

    const editExpense = (index: number) => () => {
        setExpenseToEdit(index);
        openExpense()
    }

    const deleteExpense = (index: number) => () => {
        setBusinessProject(eP => {
            const expenses = eP.expenses.filter((e, i) => i !== index);
            return ({ ...eP, expenses })
        });
        setGraphUpdateTrigger(Date.now())
    }

    const handleUpdateExpense = (expense: BankProjectConcept_Expense) => {
        const index = expenseToEdit;
        setBusinessProject(eP => {
            const expenses = eP.expenses;
            expenses[index] = expense;
            return ({ ...eP, expenses })
        });
        setGraphUpdateTrigger(Date.now())
        setExpenseToEdit(null);
        closeExpense()
    }


    const handleAddIncome = (income: BankProjectConcept_Income) => {
        setBusinessProject(eP => {
            const incomes = eP.incomes.concat([income])
            return ({ ...eP, incomes })
        });
        setGraphUpdateTrigger(Date.now())
        closeIncome()
    }

    const openIncome = () => {
        setOpenIncome(true)
    }

    const closeIncome = () => {
        setOpenIncome(false)
    }

    const editIncome = (index: number) => () => {
        setIncomeToEdit(index);
        openIncome()
    }

    const deleteIncome = (index: number) => () => {
        setBusinessProject(eP => {
            const incomes = eP.incomes.filter((e, i) => i !== index);
            return ({ ...eP, incomes })
        });
        setGraphUpdateTrigger(Date.now())
    }

    const handleUpdateIncome = (income: BankProjectConcept_Income) => {
        const index = incomeToEdit;
        setBusinessProject(eP => {
            const incomes = eP.incomes;
            incomes[index] = income;
            return ({ ...eP, incomes })
        });
        setGraphUpdateTrigger(Date.now())
        setIncomeToEdit(null);
        closeIncome()
    }

    const handleCancel = () => {
        if (editingProject) {
            history.push(`/administration/projection/${editingProject._id}`)
        } else {
            history.push(`/administration/projection`)
        }
    }

    const submitBusinessProject = () => {
        console.log(`submitBusinessProject - businessProject: `, businessProject);
        setIsSaving(true);
        _createBusinessProject({
            variables: {
                project: businessProject
            }
        })
    }

    const onUpdateBusinessProject = () => {
        // Clean income / expense fields for Input types
        const expenses: BankProjectConcept_Expense[] = businessProject.expenses.map(e => ({
            _id: e._id,
            name: e.name,
            amount: e.amount,
            expenseType: e.expenseType || 'total',
            monthRepetition: e.monthRepetition,
            startsAt: e.startsAt
        }))
        const incomes: BankProjectConcept_Income[] = businessProject.incomes.map(e => ({
            _id: e._id,
            name: e.name,
            amount: e.amount,
            monthRepetition: e.monthRepetition,
            expectedMonthChange: {
                factor: e.expectedMonthChange.factor,
                value: e.expectedMonthChange.value
            },
            startsAt: e.startsAt
        }))
        const expenseUpdateInput: BankProject<'business'> = Object.assign({}, {
            _id: businessProject._id,
            name: businessProject.name,
            createdAt: businessProject.createdAt,
            type: 'business',
            expenses,
            incomes
        } as BankProject<'business'>)
        console.log(`updateExpenseProject - businessProject: `, expenseUpdateInput);
        setIsSaving(true);
        _updateBusinessProject({
            variables: {
                project: expenseUpdateInput
            }
        })
    }

    const isValid = (businessProject.name && businessProject.expenses.length)

    return (
        <div id='ProjectosBusinesssNew' className='card'>
            <div className='title'>
                <BriefcaseSvg width={26} color={'#AC2E78'} />
                {
                    editingProject ?
                        <h4>
                            Editar Proyecto de Negocio
                        </h4>
                        :
                        <h4>
                            Creación de Proyectos de Negocio
                        </h4>
                }
            </div>
            <div className='row'>
                <h4>
                    Proyecto
                </h4>
                <div className='row two start'>
                    <Input
                        label='Tītulo de Proyecto'
                        type='text'
                        value={businessProject.name}
                        onChange={handleNameChange}
                        placeholder="E.g. vacaciones, etc."
                        hasError={!businessProject.name && !!businessProject.expenses.length}
                        errorLabel={`Es necesario agregar un nombre`}
                    />
                    <Input
                        label='Fecha de Inicio'
                        type='date'
                        minDate={moment().subtract(1, 'year').startOf('year').toDate()}
                        maxDate={moment().add(1, 'year').endOf('year').toDate()}
                        value={businessProject.createdAt}
                        onChange={handleStartOfChange}
                    />
                </div>
            </div>
            <div className='row ingresosSection'>
                <h4>
                    Ingresos
                </h4>
                <Button
                    tertiary={true}
                    handleClick={openIncome}
                >
                    <div className='iconLabel'>
                        <span className='material-icons'>
                            add
                        </span>
                        <span>
                            Agregar ingreso nuevo
                        </span>
                    </div>
                </Button>

                <div className='ingresoList'>
                    <div className='ingreso header'>
                        <p>Nombre de ingreso</p>
                        <p className='center'>Cantidad Inicial</p>
                        <p className='center'>Cambio Mensual</p>
                        <p className='center'>Número de Meses</p>
                        <p>Fecha de Inicio & Cierre</p>
                    </div>
                    {
                        businessProject.incomes.map((income, i) => {

                            return (
                                <div className='ingreso' key={i}>
                                    <p>{income.name}</p>
                                    <p className='center'>${numberToCurrencyString(income.amount)}</p>
                                    <div className='row'>
                                        <p className='center'>{income.expectedMonthChange?.value || 0}%</p>

                                    </div>
                                    <p className='center'>
                                        {
                                            income.monthRepetition > 1 ? `${income.monthRepetition} Meses`
                                                : 'Evento Único'
                                        }
                                    </p>
                                    {
                                        income.monthRepetition > 1 ?
                                            <div className='_date dual'>
                                                <p>{unifiedMoment(income.startsAt).format('MMM YYYY')}</p>
                                                <p className='center centerY'>-</p>
                                                <p>{unifiedMoment(income.startsAt).add(income.monthRepetition, 'month').format('MMM YYYY')}</p>
                                            </div>
                                            :
                                            <div className='_date'>
                                                <p> {unifiedMoment(income.startsAt).format('MMM YYYY')}</p>
                                            </div>

                                    }
                                    <ItemMenu widthBehaviour={'auto'} openOrientation={'left'}
                                        triggerContent={<span className="material-icons _settings">more_vert</span>}
                                        noPadding={true}
                                    >
                                        <div className='cardMenu'>
                                            <div className='_option' onClick={editIncome(i)}>
                                                <span className="material-icons">
                                                    edit
                                                </span>
                                                <p>Editar ingreso</p>
                                            </div>
                                            <div className='_option _warning' onClick={deleteIncome(i)}>
                                                <span className="material-icons">
                                                    delete
                                                </span>
                                                <p>Borrar ingreso</p>
                                            </div>
                                        </div>
                                    </ItemMenu>
                                </div>
                            )
                        })
                    }
                    {
                        businessProject.incomes.length ?
                            null :
                            <div className='row _empty'>
                                <div className='center centerY'>
                                    <p className='lightGray'>
                                        Agrega ingresos esperados a tu proyecto
                                    </p>
                                    <p className='lightGray small'>
                                        Asociarás movimientos de banco a estos ingresos para medir resultados
                                    </p>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div className='row gastosSection'>
                <h4>
                    Gastos
                </h4>
                <Button
                    tertiary={true}
                    handleClick={openExpense}
                >
                    <div className='iconLabel'>
                        <span className='material-icons'>
                            add
                        </span>
                        <span>
                            Agregar gasto nuevo
                        </span>
                    </div>
                </Button>

                <div className='gastosList'>
                    <div className='gasto header'>
                        <p>Nombre de gasto</p>
                        <p className='center'>Cantidad</p>
                        <p className='center'>Número de Meses</p>
                        <p>Fecha de Inicio & Cierre</p>
                    </div>
                    {
                        businessProject.expenses.map((expense, i) => {

                            return (
                                <div className='gasto' key={i}>
                                    <p>{expense.name}</p>
                                    {
                                        expense.expenseType === 'total' ?
                                            <div className='row'>
                                                <p className='center'>${numberToCurrencyString(expense.amount)}</p>
                                            </div>
                                            :
                                            <div className='row'>
                                                <p className='center'>${numberToCurrencyString(expense.amount)} Total</p>
                                                <p className='center sub'>${numberToCurrencyString(expense.amount / expense.monthRepetition)} Mensual</p>
                                            </div>
                                    }
                                    <p className='center'>
                                        {
                                            expense.monthRepetition > 1 ? `${expense.monthRepetition} Meses`
                                                : 'Evento Único'
                                        }
                                    </p>
                                    {
                                        expense.monthRepetition > 1 ?
                                            <div className='_date dual'>
                                                <p>{unifiedMoment(expense.startsAt).format('MMM YYYY')}</p>
                                                <p className='center centerY'>-</p>
                                                <p>{unifiedMoment(expense.startsAt).add(expense.monthRepetition, 'month').format('MMM YYYY')}</p>
                                            </div>
                                            :
                                            <div className='_date'>
                                                <p> {unifiedMoment(expense.startsAt).format('MMM YYYY')}</p>
                                            </div>

                                    }
                                    <ItemMenu widthBehaviour={'auto'} openOrientation={'left'}
                                        triggerContent={<span className="material-icons _settings">more_vert</span>}
                                        noPadding={true}
                                    >
                                        <div className='cardMenu'>
                                            <div className='_option' onClick={editExpense(i)}>
                                                <span className="material-icons">
                                                    edit
                                                </span>
                                                <p>Editar gasto</p>
                                            </div>
                                            <div className='_option _warning' onClick={deleteExpense(i)}>
                                                <span className="material-icons">
                                                    delete
                                                </span>
                                                <p>Borrar gasto</p>
                                            </div>
                                        </div>
                                    </ItemMenu>
                                </div>
                            )
                        })
                    }
                    {
                        businessProject.expenses.length ?
                            null :
                            <div className='row _empty'>
                                <div className='center centerY'>
                                    <p className='lightGray'>
                                        Agrega gastos esperados a tu proyecto
                                    </p>
                                    <p className='lightGray small'>
                                        Asociarás movimientos a estos gastos para medir utilidad mensual
                                    </p>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <BusinessActivityGraph expenses={businessProject.expenses} incomes={businessProject.incomes} lastUpdateTrigger={grphUpdateTrigger} />
            <div className='row two actions'>
                <div className='start'>
                    <Button secondary handleClick={handleCancel}>
                        <span>
                            Cancelar
                        </span>
                    </Button>
                </div>
                <div className='end'>
                    {
                        editingProject ?
                            <Button primary disabled={!isValid || isSaving} handleClick={onUpdateBusinessProject}>
                                <span>
                                    Guardar cambios
                                </span>
                            </Button>
                            :
                            <Button primary disabled={!isValid || isSaving} handleClick={submitBusinessProject}>
                                <span>
                                    Crear Proyecto
                                </span>
                            </Button>
                    }
                </div>
            </div>
            <ProjectionExpenseNewDialog
                isOpen={_openExpenses}
                onRequestClose={closeExpense}
                onNewExpense={handleAddExpense}
                onEditExpense={handleUpdateExpense}
                toEditExpense={expenseToEdit === null ? null : businessProject.expenses[expenseToEdit]}
                startDate={businessProject.createdAt}
                existingExpenseNames={businessProject.expenses.map(e => e.name.toLowerCase())}
            />
            <ProjectionIncomeNewDialog
                isOpen={_openIncome}
                onRequestClose={closeIncome}
                onNewIncome={handleAddIncome}
                onEditIncome={handleUpdateIncome}
                toEditIncome={incomeToEdit === null ? null : businessProject.incomes[incomeToEdit]}
                startDate={businessProject.createdAt}
                existingIncomeNames={businessProject.incomes.map(e => e.name.toLowerCase())}
            />

        </div>
    )
}