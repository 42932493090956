import React, { useState, useEffect } from 'react';

import './Pagination.scss';

interface PaginationProps { pages: number; page: number; onPage: (page: number) => void }

function Pagination({ pages, page, onPage }: PaginationProps) {
    const [offset, setOffset] = useState(0);
    const [pagesOptions, setPagesOptions] = useState([]);
    const LIMITED_TO = 6;
    const PAGES_LIMIT = 199;
    const topPages = pages > PAGES_LIMIT ? PAGES_LIMIT : pages;
    useEffect(() => {
        setPagesOptions((new Array(topPages).fill(1)).map((d, i) => i));
    }, [topPages]);

    const shiftUp = (extreme: boolean = false) => {
        return () => {
            if (!extreme) {
                if (((offset + 2) + LIMITED_TO) > topPages) {
                    return
                }
                setOffset(offset + 1);
            } else {
                if ((topPages - LIMITED_TO - 1) > (offset + LIMITED_TO)) {
                    return setOffset(offset + LIMITED_TO + 1);
                }
                if (((topPages - LIMITED_TO) - 1) > 0) {
                    setOffset(topPages - LIMITED_TO - 1);
                }
            }
        }
    }
    const shiftDown = (extreme: boolean = false) => {
        return () => {
            if (offset < 0) {
                return;
            }
            if (!extreme) {
                setOffset(offset - 1);
            } else {
                if ((offset - LIMITED_TO - 1) > 0) {
                    return setOffset(offset - LIMITED_TO - 1);
                }
                setOffset(0)
            }
        }
    }

    const changePage = (toPage: number) => {
        return () => {

            console.log('Change Page & pages: ', page, topPages);
            if (toPage === page) {
                return;
            }
            console.log('Change from to: ', page, toPage);
            onPage(toPage);
            if (toPage > page) {
                shiftUp();
            } else if (toPage < page) {
                shiftDown();
            }
        }
    }
    
    if(pages < 2){
        return (
            <div className="__pagination empty">
            </div>
        )
    }

    return (
        <div className="__pagination">
            <div className="_paginationContain" style={{ gridTemplateColumns: `repeat(${topPages >= (LIMITED_TO + 1) ? 11 : (10 - (LIMITED_TO - topPages))}, 1fr)` }}>
                <button className="_pageShift" disabled={offset < 1} onClick={shiftDown(true)}>
                    {'<<'}
                </button>
                <button className="_pageShift" disabled={offset < 1} onClick={shiftDown(false)}>
                    {'<'}
                </button>
                {pagesOptions.filter((_) => (_ >= offset) && (_ <= (LIMITED_TO + offset))).map((_, i) => {
                    return (
                        <button className={`_pageEntry ${_ === (page - 1) ? 'active' : ''}`} onClick={changePage(_ + 1)} key={i}>
                            <p>
                                {_ + 1}
                            </p>
                        </button>
                    )
                })}
                <button className="_pageShift" onClick={shiftUp(false)} disabled={(offset + LIMITED_TO) > topPages}>
                    {'>'}
                </button>
                <button className="_pageShift" onClick={shiftUp(true)} disabled={(offset + LIMITED_TO) > topPages}>
                    {'>>'}
                </button>
            </div>
        </div>
    )
}

export default Pagination;