import React, { useState } from 'react';

import { ProfileQL } from '../../models/Profile';

import './profilemenu.scss';
import { Link } from 'react-router-dom';
import GraphQlClient from '../../services/graphql';

interface ProfileMenuProps {
    currentUser: ProfileQL;
}

function ProfileMenu({currentUser}: ProfileMenuProps){
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(io => !io);
    }

    const logout = () => {
        GraphQlClient.logOut();
    }

    const fiscalpopProfile = currentUser.fiscalpopProfile;
    const clientLogo = (!!fiscalpopProfile && fiscalpopProfile.logoUrl ? fiscalpopProfile.logoUrl : null);
    // const randQuery = `?i=${Math.random().toString(32).split('.')[1]}`
    const randQuery = ``
    const assignedLogo = clientLogo ? `${clientLogo}${randQuery}` : '';

    return (
        <div id="ProfileMenu">
            <div className="profileAvatar" onClick={toggleOpen}>
                {
                    assignedLogo ?
                        <img src={assignedLogo} alt="Perfil usuario"/>
                    :
                    <div className="_isEmpty">
                        <span>
                            {currentUser.professionalProfile.companyName.substring(0,2).toUpperCase()}
                        </span>
                    </div>
                }
            </div>
            <div className={`menuLinks ${isOpen ? '_open' : ''}`} onClick={toggleOpen}>
                <Link to="/setup/emisor">
                    Mi emisor
                </Link>
                <Link to="/setup/pagos">
                    Planes & Pagos
                </Link>
                <Link to="/setup/cobros">
                    Cotizaciones
                </Link>
                <Link to="/support" >
                    Soporte
                </Link>
                <div className="splitter"></div>
                <Link to="/setup/notifications">
                    Notificaciones
                </Link>
                <span className="action" onClick={logout}>
                    Cerrar sesión
                </span>
            </div>
            {
                isOpen ?
                <div className="_overlay" onClick={toggleOpen}>
                </div>
                : ''
            }
        </div>
    )
}

export default ProfileMenu;