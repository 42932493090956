import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/************************************************************************

  USAGE of Window resize

  This hook will return {height, width} whenever the window is resized
  Use it on components that require adjusting to display width

************************************************************************/
const useWindowResize = (): { windowHeight: number; windowWidth: number } => {

  const [resizeSubject] = useState(new Subject<{ width: number, height: number }>())
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth as number,
    height: window.innerHeight as number,
  });
  useEffect(() => {
    // Handler to call on window resize
    const subscription = resizeSubject.pipe(debounceTime(350)).subscribe(({ width, height }) => {
      // Set window width/height to state
      setWindowSize({
        width,
        height,
      });
    })

    const handleResize = () => {
      resizeSubject.next({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', handleResize);
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      subscription.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { windowWidth: windowSize.width, windowHeight: windowSize.height };
}

export default useWindowResize;