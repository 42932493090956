import React, { FormEvent, useEffect, useState } from 'react';

import Modal from 'react-modal';
import { Receptor } from '../../../models/Catalogos';
import { REGIMEN_OPTIONS } from '../../../utils/regimen.options';
import Button from '../../Forms/Button';
import Input from '../../Forms/Input';
import Select from '../../Forms/Select';

import './Clientes.scss'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '80vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

interface ClientDialogProps {
    modalOpen: boolean;
    updateClient?: Receptor;
    saveClient: (client: Receptor) => void;
    closeClientModal: () => void;

}

const REGIMEN_OPT = REGIMEN_OPTIONS.map((o) => ({ value: o.value, label: o.secondLabel }))

const getEmptyClient = (): Receptor => {
    return { rfc: '', name: '', email: '' }
}

function ClientsCatalogDialog({ modalOpen, updateClient, closeClientModal, saveClient }: ClientDialogProps) {

    const [newClient, setNewClient] = useState<Receptor>({ rfc: '', name: '', email: '', regimen: '', zip: '' })


    useEffect(() => {
        if (updateClient) {
            setNewClient(updateClient)
        } else {
            setNewClient(getEmptyClient())
        }
    }, [updateClient])


    const editNewClient = (property: 'rfc' | 'name' | 'email' | 'regimen' | 'zip' | 'seriePreference') => {
        return (value: string) => {
            const client = Object.assign({}, newClient);
            if (property === 'rfc') {
                value = value.toUpperCase();
            }
            client[property] = value;
            setNewClient(client);
        }
    }

    const saveClientEdit = (e?: FormEvent) => {
        if (!!e) {
            e.preventDefault();
        }
        const _client = JSON.parse(JSON.stringify(newClient));
        saveClient(_client);
        setNewClient(getEmptyClient())
    }


    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeClientModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Agregar nuevo cliente"
        >
            <div className="card table" id="newClient">
                <div className="cardTitle withClose">
                    <h4>{!!updateClient?._id ? 'Editar cliente' : 'Nuevo cliente'}</h4>
                    <i className="material-icons" onClick={closeClientModal}>
                        cancel
                    </i>
                </div>
                <form className="cardBody" onSubmit={saveClientEdit}>
                    <div className="_clientName">
                        <Input label="Razón social" placeholder="Nombre del cliente" value={newClient.name} onChange={editNewClient('name')} type='text' />
                        <Input label="RFC" placeholder="RFC" value={newClient.rfc} onChange={editNewClient('rfc')} type='text' />
                    </div>
                    <div className="_clientName">
                        <Select label="Régimen" value={newClient.regimen} onChange={editNewClient('regimen')} placeholder="Régimen fiscal del receptor" options={REGIMEN_OPT} />
                        <Input label="Código Postal" value={newClient.zip} onChange={editNewClient('zip')} type="text" placeholder="Codigo Postal Domicilio Fiscal" />
                    </div>
                    <Input label="Email (opcional)" placeholder="Correo (opcional)" value={newClient.email} onChange={editNewClient('email')} type='text' />
                    <div className="_clientName">
                        <Input label="Serie particular" placeholder={`${(newClient.name || '').split(' ').filter((_, i) => i < 2).map(s => s.substring(0, 2)).join('').toUpperCase()}`} value={newClient.seriePreference || ''} onChange={editNewClient('seriePreference')} type='text' />
                    </div>
                    <div className="_actions">
                        <Button primary={true} type="submit">
                            <span>Guardar cliente</span>
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ClientsCatalogDialog;