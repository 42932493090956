import React, { useEffect, useState } from 'react';

import './Preview.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';
import { ToBillArguments, ToBillEvent } from '../../models/Factura';
import Input from '../Forms/Input';
import { numberToCurrencyString } from '../../services/formatting';
import { formatCurrencyStyled } from '../visuals/currency.formatted';
import { numeroALetras } from '../../utils/numeroLetra';

import moment from 'moment';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { parseStampErrorFromGraphQL } from '../../utils/error.graphql';
import { conceptoToTotales } from '../../utils/calculate.conceptos';
import { useParams } from 'react-router-dom';
import { REGIMEN_OPTIONS } from '../../utils/regimen.options';
import Select from '../Forms/Select';
import { mesesFormat } from '../../utils/formatting';

interface PreviewProps extends BaseReact {
    currentUser: ProfileQL;
}

const REGIMEN_OPT = REGIMEN_OPTIONS.map((o) => ({ value: o.value, label: o.secondLabel }))

const GLOBAL_MES = new Array(12).fill(1).map((_, i) => {
    moment().startOf('year').add(i, 'month').format('MMMM')
    const e = {
        value: mesesFormat(`${i + 1}`),
        label: moment().startOf('year').add(i, 'month').format('MMMM')
    }
    return e;
})

function PreviewFactura({ currentUser, location, history }: PreviewProps) {
    const { argumentid, eventid } = useParams<{ argumentid?: string; eventid?: string }>();
    console.log('[FP] Location: ', location);
    const [toBillArgument, setToBillArgument] = useState<ToBillArguments<ToBillEvent>>(null);
    const [toBillEvents, setToBillEvents] = useState<ToBillEvent[]>(null);
    const [toPaymentEvents, setToPaymentEvents] = useState<ToBillEvent[]>(null);

    // UI Elements
    const [paymentError, setPaymentError] = useState('');
    const [stamping, setStamping] = useState(false);
    // ============
    useEffect(() => {
        // Extract state and use for current operations    
        const { billArgument, billEvents, paymentEvents } = location.state as { billArgument: ToBillArguments<ToBillEvent>; billEvents: ToBillEvent[]; paymentEvents: ToBillEvent[] };
        setToBillArgument(billArgument);
        setToBillEvents(billEvents);
        setToPaymentEvents(paymentEvents);
    }, [location]);

    const [setArgumentForStamping] = useMutation(graphqlSchema.FISCALPOP.CFDI.stampFromBillArgument, {
        onCompleted: ({ stampFromBillArgument }: { stampFromBillArgument: ToBillArguments<string> }) => {
            console.log('STAMP FROM BILL ARGUMENT: ', stampFromBillArgument);
            sharedToasterSubject.next({ type: 'confirm', message: 'Factura emitida correctamente' });
            history.push({
                pathname: '/',
                state: { billArgument: stampFromBillArgument }
            })
        },
        onError: (e) => {
            console.error('Error SAving GQL: ', e.graphQLErrors[0]);
            if (e.graphQLErrors[0].message.includes('Saldo insuficiente')) {
                sharedToasterSubject.next({ type: 'error', message: e.graphQLErrors[0].message });
            } else {
                sharedToasterSubject.next({ type: 'error', message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clearTs: 5200, clickToClose: true });
            }
            setStamping(false);
        }
    })

    const [editBillArgument] = useMutation(graphqlSchema.FISCALPOP.CFDI.restampBillArgument, {
        onCompleted: ({ restampBillArgument }: { restampBillArgument: ToBillArguments<string> }) => {
            console.log('EDIT FROM BILL ARGUMENT: ', restampBillArgument);
            sharedToasterSubject.next({ type: 'confirm', message: 'Factura emitida correctamente' });
            history.push({
                pathname: '/',
                state: { billArgument: restampBillArgument }
            })
        },
        onError: (e) => {
            console.error('Error SAving GQL: ', e.graphQLErrors[0]);
            if (e.graphQLErrors[0].message.includes('Saldo insuficiente')) {
                sharedToasterSubject.next({ type: 'error', message: e.graphQLErrors[0].message });
            } else {
                sharedToasterSubject.next({ type: 'error', message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clearTs: 5200, clickToClose: true });
            }
            setStamping(false);
        }
    })

    const [editBillEvent] = useMutation(graphqlSchema.FISCALPOP.CFDI.restampBillEvent, {
        onCompleted: ({ restampBillEvent }: { restampBillEvent: ToBillArguments<string> }) => {
            console.log('EDIT FROM BILL ARGUMENT: ', restampBillEvent);
            sharedToasterSubject.next({ type: 'confirm', message: 'Factura emitida correctamente' });
            history.push({
                pathname: '/',
                state: { billArgument: restampBillEvent }
            })
        },
        onError: (e) => {
            console.error('Error SAving GQL: ', e.graphQLErrors[0]);
            if (e.graphQLErrors[0].message.includes('Saldo insuficiente')) {
                sharedToasterSubject.next({ type: 'error', message: e.graphQLErrors[0].message });
            } else {
                sharedToasterSubject.next({ type: 'error', message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clearTs: 5200 });
            }
            setStamping(false);
        }
    })

    const totales = conceptoToTotales;

    const onChangeEmail = (email: string) => {
        const _toBillArgument = Object.assign({}, toBillArgument);
        _toBillArgument.MasterCFDI.receptor.email = email;
        setToBillArgument(_toBillArgument);
    }

    const onChangeName = (name: string) => {
        const _toBillArgument = Object.assign({}, toBillArgument);
        _toBillArgument.MasterCFDI.receptor.nombre = name;
        setToBillArgument(_toBillArgument);
    }

    const onChangeZip = (zip: string) => {
        const _toBillArgument = Object.assign({}, toBillArgument);
        _toBillArgument.MasterCFDI.receptor.zip = zip;
        setToBillArgument(_toBillArgument);
    }

    const onChangeRegimen = (regimen: string) => {
        const _toBillArgument = Object.assign({}, toBillArgument);
        _toBillArgument.MasterCFDI.receptor.regimen = regimen;
        setToBillArgument(_toBillArgument);
    }

    const onChangePayment = (eventIndex: number) => (value: number) => {
        if (value < 0.01) {
            value = 0.01
        }
        const _toPaymentEvents: ToBillEvent[] = JSON.parse(JSON.stringify(toPaymentEvents));
        _toPaymentEvents[eventIndex].paymentCFDI.pagos[0].monto = value;
        // Check that payments equal 0 at the end
        const masterTotales = totales(toBillArgument.MasterCFDI.conceptos).total;
        const remainder = _toPaymentEvents.reduce((p, c) => {
            p -= (c.paymentCFDI.pagos[0].monto || 0);
            return p;
        }, masterTotales)
        if (Math.round(Math.abs(remainder)) > 0) {
            setPaymentError(`Total pagado es diferente al total por un monto de $ ${numberToCurrencyString(remainder)}`)
        } else {
            setPaymentError('')
        }
        setToPaymentEvents(_toPaymentEvents);
    }

    const onChangePaymentDate = (eventIndex: number) => (date: Date) => {
        console.log('Date: ', date);
        // const offset = new Date().getTimezoneOffset() * 60 * 1000;
        // const ts = (new Date(date)).getTime() + offset + (1 * 60 * 60 * 1000);
        const ts = date.getTime();
        const _toPaymentEvents: ToBillEvent[] = JSON.parse(JSON.stringify(toPaymentEvents));
        _toPaymentEvents[eventIndex].when = ts;
        setToPaymentEvents(_toPaymentEvents);
    }

    const submitArgument = () => {

        console.log('eventid && argumentid: ', eventid && argumentid)
        console.log('argumentid: ', argumentid)
        console.log('toBillEvents: ', toBillEvents)

        setStamping(true);
        // Make sure that no monto 0 payment event is passed
        const _toPaymentEvents = toPaymentEvents.filter(tPE => !!tPE.paymentCFDI.pagos[0].monto);
        if (eventid && argumentid) {
            // Edit single billEvent
            console.log('Editing billEvent: ', eventid, argumentid, {
                billEventToEditId: eventid,
                argument: toBillArgument,
                billEvents: toBillEvents,
                paymentEvents: _toPaymentEvents
            });
            editBillEvent({
                variables: {
                    billEventToEditId: eventid,
                    argument: toBillArgument,
                    billEvents: toBillEvents,
                    paymentEvents: _toPaymentEvents
                }
            })
            /*
            */
        }
        else if (argumentid) {
            // Edit and replace whole bill argument
            editBillArgument({
                variables: {
                    billArgumentToEditId: argumentid,
                    argument: toBillArgument,
                    billEvents: toBillEvents,
                    paymentEvents: _toPaymentEvents
                }
            })
        } else {
            // Create new Bill argument
            setArgumentForStamping({
                variables: {
                    argument: toBillArgument,
                    billEvents: toBillEvents,
                    paymentEvents: _toPaymentEvents
                }
            })
        }
    }

    const returnToModify = () => {
        /*
        history.goBack();
        */
        const pathName = eventid ? `/facturacion/facturar/${argumentid}/${eventid}` : (!!argumentid ? `/facturacion/facturar/${argumentid}` : `/facturacion/facturar`)
        history.push({
            pathname: pathName,
            state: { billArgument: toBillArgument, billEvents: toBillEvents }
        })
    }


    // Render methods ==================
    const renderLiquidacion = () => {
        if (!toBillEvents.length) {
            return ''
        }
        toBillEvents.map((billEv, i) => {
            const cfdiTotales = totales(billEv.CFDI.conceptos)
            const notaTotales = totales(billEv.NotaCreditoCFDI.conceptos)
            return (
                <div className="liquidacionWrap">
                    <h2>
                        Al liquidar el pago
                    </h2>
                    <div className="_liquidacion">
                        <p>Liquidación</p>
                        <p>{formatCurrencyStyled(cfdiTotales.total)}</p>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="_liquidacion">
                        <p>Nota credito por adelanto</p>
                        <p>{formatCurrencyStyled(notaTotales.total)}</p>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )
        })
    }
    const renderPagos = () => {
        if (!toPaymentEvents.length) {
            return ''
        }
        let exerciceTotal = totales(toBillArgument.MasterCFDI.conceptos).total;
        return (
            <div className="pagosWrap">
                <h2>Complementos de pago por parcialidades</h2>
                {
                    toPaymentEvents.map((pago, i) => {
                        exerciceTotal -= pago.paymentCFDI.pagos[0].monto;
                        const isLast = (i + 1) === toPaymentEvents.length;
                        const isFirst = i === 0;
                        const exceedsError = (i + 1) === toPaymentEvents.length && Math.round(Math.abs(exerciceTotal)) > 0;
                        return (
                            <div className="_pago card" key={i}>
                                <div>
                                    {
                                        i === 0 && toBillArgument.multipayType === 3 ?
                                            <p>Primera Parcialidad o Anticipo</p>
                                            :
                                            <p>Parcialidad #{i + 1}</p>
                                    }
                                    <span className={`label ${exceedsError ? 'error' : ''}`}>Saldo pendiente después de pago {formatCurrencyStyled(exerciceTotal)}</span>
                                </div>
                                <Input isCurrency={true} type="number" value={pago.paymentCFDI.pagos[0].monto} label="Monto de parcialidad" onChange={onChangePayment(i)} hasError={exceedsError} />
                                <Input type="date" value={pago.when} label="Fecha esperada" onChange={onChangePaymentDate(i)}
                                    max={isLast ? null : moment(toPaymentEvents[i + 1].when).format('YYYY-MM-DD')}
                                    min={isFirst ? moment(Date.now()).format('YYYY-MM-DD') : moment(toPaymentEvents[i - 1].when).format('YYYY-MM-DD')} />
                                <div></div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    const renderMainBill = () => {
        if (!toBillArgument) {
            return ''
        }
        const { subtotal, descuento, ieps_retenido, ieps_trasladado, iva_retenido, iva_trasladado, isr, total } = totales(toBillArgument.MasterCFDI.conceptos)
        console.log('TOTALES PREVIEW: ', totales(toBillArgument.MasterCFDI.conceptos));

        const mesGlobalLabel = toBillArgument.MasterCFDI?.global?.mes ? GLOBAL_MES.find(gM => gM.value === toBillArgument.MasterCFDI.global.mes).label : '';
        const anoGlobalLabel = toBillArgument.MasterCFDI?.global?.ano ? toBillArgument.MasterCFDI?.global?.ano : '';

        return (
            <div>
                <h4>{toBillArgument.multipayType === 1 ? 'Factura de adelanto' : 'Factura principal'}</h4>
                <div className="card table">
                    <div className="cardBody">
                        <div className="row four xlg-three md-two sm-one">
                            <Input value={currentUser.fiscalpopProfile.nombre} label="Cliente emisor" type="text" disabled={true} />
                            <div className='row xlg-hidden' />
                            <div className='row md-hidden' />
                            {
                                toBillArgument.MasterCFDI.global ?
                                    <div className='row three gap-compressed'>
                                        <Input value={toBillArgument.MasterCFDI.global.periodo} label="Periodo" type="text" disabled={true} />
                                        <Input value={`${mesGlobalLabel}`} label="Mes" type="text" disabled={true} />
                                        <Input value={`${anoGlobalLabel}`} label="Año" type="text" disabled={true} />
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="row emisor">
                            <Input value={toBillArgument.MasterCFDI.receptor.nombre} label="Receptor" type="text" disabled={!!toBillArgument.MasterCFDI.global} onChange={onChangeName} />
                            <Input value={toBillArgument.MasterCFDI.receptor.rfc} label="RFC Receptor" type="text" disabled={true} />
                            <Input value={toBillArgument.MasterCFDI.receptor.email} label="Email" type="text" disabled={false} onChange={onChangeEmail} />
                        </div>
                        <div className="row emisor">
                            <div className="row two">

                                <Select value={toBillArgument.MasterCFDI.receptor.regimen} label="Regimen Receptor" onChange={onChangeRegimen} placeholder="Régimen fiscal del receptor" options={REGIMEN_OPT} disabled={true} />
                                <Input value={toBillArgument.MasterCFDI.receptor.zip} label="Codigo Postal" type="text" disabled={!!toBillArgument.MasterCFDI.global} onChange={onChangeZip} />
                            </div>
                            <div className="row sm-two">
                                <Input value={toBillArgument.MasterCFDI.receptor.usoCFDI} label="Uso CFDI" type="text" disabled={true} />
                            </div>
                            <div className="row two">
                                <Input value={toBillArgument.MasterCFDI.formaPago} label="Forma de pago" type="text" disabled={true} />
                                <Input value={toBillArgument.MasterCFDI.metodoPago} label="Metodo de pago" type="text" disabled={true} />
                            </div>

                        </div>
                        <div className="conceptosWrap">
                            <span className="label">Conceptos:</span>
                            {toBillArgument.MasterCFDI.conceptos.map((concepto, i) => (
                                <div className="concepto" key={i}>
                                    <p className='_index'>#{i + 1}</p>
                                    <p className='_name'>
                                        {concepto.descripcion}
                                        {
                                            concepto.noIdentificacion ?
                                                <span className='small lightGray'><br />{`${concepto.noIdentificacion}`}</span>
                                                : null
                                        }
                                    </p>
                                    <div className="_claves">
                                        <p><span>Clave</span> ProdServ: {concepto.claveProdServ}</p>
                                        <p><span>Clave</span> Unidad: {concepto.claveUnidad}</p>
                                    </div>
                                    <p className="_cantidad">
                                        (x{concepto.cantidad})
                                    </p>
                                    <p className='_price'>
                                        {formatCurrencyStyled(concepto.valorUnitario)}
                                    </p>
                                    <div className="_impuestos">
                                        {concepto.impuestos.map((tax, i) => (
                                            <p key={i}>
                                                {tax.type.toUpperCase()}{tax.retencion ? ' retenido: ' : ':'} {tax.tasa ? `${Math.round((tax.tasa * 100) * 10000) / 10000}%` : (tax.cuota ? `$ ${tax.cuota} / unidad` : '0%')}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="totalWrap">
                            <div className="_letra">
                                <p>
                                    {numeroALetras(total, {
                                        plural: 'pesos mexicanos',
                                        singular: 'pesos mexicanos',
                                        centPlural: 'centavos',
                                        centSingular: 'centavo'
                                    }).toLowerCase()}
                                </p>
                            </div>
                            <div className="_totales">
                                <div>
                                    <p className="_subtotal">{formatCurrencyStyled(subtotal)}</p>
                                    <p className="_subtotal">Subtotal</p>
                                </div>
                                {
                                    descuento ?
                                        <div>
                                            <p className="_subtotal red">{formatCurrencyStyled(-descuento)}</p>
                                            <p className="_subtotal red">Descuento</p>
                                        </div>
                                        : ''
                                }
                                {
                                    ieps_retenido ?
                                        <div className="_tax">
                                            <p className="_tax">{formatCurrencyStyled(ieps_retenido)}</p>
                                            <p className="_tax">IEPS Retenido</p>
                                        </div>
                                        : ''
                                }
                                {
                                    ieps_trasladado ?
                                        <div className="_tax">
                                            <p className="_tax">{formatCurrencyStyled(ieps_trasladado)}</p>
                                            <p className="_tax">IEPS Trasladado</p>
                                        </div>
                                        : ''
                                }
                                {
                                    iva_retenido ?
                                        <div className="_tax">
                                            <p className="_tax">{formatCurrencyStyled(iva_retenido)}</p>
                                            <p className="_tax">IVA Retenido</p>
                                        </div>
                                        : ''
                                }
                                {
                                    iva_trasladado ?
                                        <div className="_tax">
                                            <p className="_tax">{formatCurrencyStyled(iva_trasladado)}</p>
                                            <p className="_tax">IVA Trasladado</p>
                                        </div>
                                        : ''
                                }
                                {
                                    isr ?
                                        <div className="_tax">
                                            <p className="_tax">{formatCurrencyStyled(isr)}</p>
                                            <p className="_tax">ISR Retenido</p>
                                        </div>
                                        : ''
                                }
                                <div>
                                    <p className="_total">
                                        <b>{formatCurrencyStyled(total)}</b>
                                    </p>
                                    <p className="_total">
                                        <b>Total</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {renderLiquidacion()}
                        {renderPagos()}

                    </div>
                </div>
            </div>
        )
    }

    const renderSecondaryBills = () => {
        if (!toBillEvents) {
            return ''
        }
        return toBillEvents.map((billEvent, i) => {
            const notaTotal = totales(!!billEvent.NotaCreditoCFDI ? billEvent.NotaCreditoCFDI.conceptos : []);
            const { subtotal, descuento, ieps_retenido, ieps_trasladado, iva_retenido, iva_trasladado, isr, total } = totales(!!billEvent.CFDI ? billEvent.CFDI.conceptos : [])
            return (
                <div className="billEventGroupper" key={i}>
                    {
                        !billEvent.NotaCreditoCFDI ?
                            '' :
                            <div>

                                <h4>Nota de crédito para el adelanto</h4>
                                <div className="card table">
                                    <div className="cardBody">
                                        <div className="_wrapNota">
                                            <div className="conceptosWrap">
                                                <span className="label">Conceptos:</span>
                                                {billEvent.NotaCreditoCFDI.conceptos.map((concepto, i) => (
                                                    <div className="concepto" key={i}>
                                                        <p className='_index'>#{i + 1}</p>
                                                        <p className='_name'>{concepto.descripcion}</p>
                                                        <div className="_claves">
                                                            <p><span>Clave</span> ProdServ: {concepto.claveProdServ}</p>
                                                            <p><span>Clave</span> Unidad: {concepto.claveUnidad}</p>
                                                        </div>
                                                        <p className="_cantidad">
                                                            (x{concepto.cantidad})
                                                        </p>
                                                        <p className="_valor _price">
                                                            {formatCurrencyStyled(concepto.valorUnitario)}
                                                        </p>
                                                        <div className="_impuestos">
                                                            {concepto.impuestos.map((tax, i) => (
                                                                <p key={i}>
                                                                    {tax.type.toUpperCase()}{tax.retencion ? ' retenido: ' : ':'} {tax.tasa ? `${Math.round((tax.tasa * 100) * 10000) / 10000}%` : (tax.cuota ? `$ ${tax.cuota} / unidad` : '0%')}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="totalWrap">
                                                <div className="_letra">
                                                    <p>
                                                        <b>Nota de crédito por </b>
                                                        {numeroALetras(notaTotal.total, {
                                                            plural: 'pesos mexicanos',
                                                            singular: 'pesos mexicanos',
                                                            centPlural: 'centavos',
                                                            centSingular: 'centavo'
                                                        }).toLowerCase()}
                                                    </p>
                                                </div>
                                                <div className="_totales">
                                                    <div>
                                                        <p className="_subtotal">{formatCurrencyStyled(notaTotal.subtotal)}</p>
                                                        <p className="_subtotal">Subtotal</p>
                                                    </div>
                                                    {
                                                        ieps_retenido ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(notaTotal.ieps_retenido)}</p>
                                                                <p className="_tax">IEPS Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        ieps_trasladado ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(notaTotal.ieps_trasladado)}</p>
                                                                <p className="_tax">IEPS Trasladado</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        iva_retenido ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(notaTotal.iva_retenido)}</p>
                                                                <p className="_tax">IVA Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        iva_trasladado ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(notaTotal.iva_trasladado)}</p>
                                                                <p className="_tax">IVA Trasladado</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        isr ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(notaTotal.isr)}</p>
                                                                <p className="_tax">ISR Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    <div>
                                                        <p className="_total">
                                                            <b>{formatCurrencyStyled(notaTotal.total)}</b>
                                                        </p>
                                                        <p className="_total">
                                                            <b>Total</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    {
                        !billEvent.CFDI ?
                            '' :
                            <div>
                                <h4>Factura de liquidación completa</h4>
                                <div className="card table">
                                    <div className="cardBody">
                                        <div className="_wrapCFDI">
                                            <div className="conceptosWrap">
                                                <span className="label">Conceptos:</span>
                                                {billEvent.CFDI.conceptos.map((concepto, i) => (
                                                    <div className="concepto" key={i}>
                                                        <p className='_index'>#{i + 1}</p>
                                                        <p className='_name'>{concepto.descripcion}</p>
                                                        <div className="_claves">
                                                            <p><span>Clave</span> ProdServ: {concepto.claveProdServ}</p>
                                                            <p><span>Clave</span> Unidad: {concepto.claveUnidad}</p>
                                                        </div>
                                                        <p className="_cantidad">
                                                            (x{concepto.cantidad})
                                                        </p>
                                                        <p className='_price'>
                                                            {formatCurrencyStyled(concepto.valorUnitario)}
                                                        </p>
                                                        <div className="_impuestos">
                                                            {concepto.impuestos.map((tax, i) => (
                                                                <p key={i}>
                                                                    {tax.type.toUpperCase()}{tax.retencion ? ' retenido: ' : ':'} {tax.tasa ? `${Math.round((tax.tasa * 100) * 10000) / 10000}%` : (tax.cuota ? `$ ${tax.cuota} / unidad` : '0%')}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="totalWrap">
                                                <div className="_letra">
                                                    <p>
                                                        {numeroALetras(total, {
                                                            plural: 'pesos mexicanos',
                                                            singular: 'pesos mexicanos',
                                                            centPlural: 'centavos',
                                                            centSingular: 'centavo'
                                                        }).toLowerCase()}
                                                    </p>
                                                </div>
                                                <div className="_totales">
                                                    <div>
                                                        <p className="_subtotal">{formatCurrencyStyled(subtotal)}</p>
                                                        <p className="_subtotal">Subtotal</p>
                                                    </div>
                                                    {
                                                        descuento ?
                                                            <div>
                                                                <p className="_subtotal red">{formatCurrencyStyled(-descuento)}</p>
                                                                <p className="_subtotal red">Descuento</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        ieps_retenido ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(ieps_retenido)}</p>
                                                                <p className="_tax">IEPS Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        ieps_trasladado ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(ieps_trasladado)}</p>
                                                                <p className="_tax">IEPS Trasladado</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        iva_retenido ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(iva_retenido)}</p>
                                                                <p className="_tax">IVA Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        iva_trasladado ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(iva_trasladado)}</p>
                                                                <p className="_tax">IVA Trasladado</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        isr ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(isr)}</p>
                                                                <p className="_tax">ISR Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    <div>
                                                        <p className="_total">
                                                            <b>{formatCurrencyStyled(total)}</b>
                                                        </p>
                                                        <p className="_total">
                                                            <b>Total</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            )
        })
    }

    // const _upfrontError = toBillArgument.multipayType === 1 && (totales())
    const _error = paymentError || null;

    return (
        <div id="FacturaPreview">
            <SectionTitleBar currentUser={currentUser} title={`Previsualizar Factura${argumentid ? ' a editar' : ''}`} />
            <div className="previewContent">
                {renderMainBill()}
                {renderSecondaryBills()}
                <div className="actions">
                    {
                        _error ?
                            <p className="message error">
                                <span className="error">
                                    {_error}
                                </span>
                            </p>
                            : (stamping ?
                                <p className="message success">
                                    <span className="">
                                        Facturando, espere...
                                    </span>
                                </p>
                                : '')

                    }
                    <Button secondary={true} handleClick={returnToModify}>
                        <span>
                            Regresar
                        </span>
                    </Button>
                    <Button primary={true} disabled={!!_error || stamping} handleClick={submitArgument}>
                        <span>
                            Emitir Factura
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default PreviewFactura;