
import React from 'react';
import { CotizacionHistory } from '../../../models/Cotizaciones';
import moment from 'moment';

import './Cotizacion.view.history.scss'

function CotizacionViewHistory({ histories, logoUrl }: { histories: CotizacionHistory[]; logoUrl: string }) {
    const avatarByWhom = (history: CotizacionHistory) => {
        if (history.whom === 'client') {
            return (<div className="_clientAvatar">
                <span>
                    CL
                </span>
            </div>)
        }
        if (history.whom === 'vendor') {
            return (
                <div className="_vendorAvatar">
                    <img src={logoUrl} alt={'Logo empresa'} />
                </div>
            )
        }
        if (history.whom === 'platform') {
            return (<svg className="fiscalpoLogo" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="240 18 155 155" xmlSpace="preserve"><g><g><path className="st0" d="M316.94,22c-40.24,0-72.85,32.62-72.85,72.85c0,40.24,32.62,72.85,72.85,72.85s72.85-32.62,72.85-72.85    C389.79,54.62,357.17,22,316.94,22z M276.52,82.39v-0.15c0-12.42,8.22-18.98,19.48-18.98h6.21v-4.7    c0-7.93,12.56-10.98,12.56-10.98v30.79l-18.51,0.09c-4.47,0-7.34,0.21-7.34,4.35v0.15c0,4.52,2.87,5.68,7.4,5.68h5.73v0.04h12.72    v12.24h-19.42C283.9,100.92,276.52,94.06,276.52,82.39z M314.91,139.66c0,0-12.56-3.05-12.56-10.91c0-5.16,0-6.41,0-6.67    c0,0-2.66,0-10.38,0c-7.72,0-7.19-12.12-7.19-12.12h1.42v0.05h28.71V139.66z M321.36,47.58c0,0,12.56,3.05,12.56,10.91    c0,7.86,0,8.54,0,8.54s2.66,0,10.38,0c7.72,0,7.19,12.12,7.19,12.12h-1.42V79.1h-28.71V47.58z M359.76,106.87    c0,12.42-8.22,18.98-19.48,18.98h-6.21v2.83c0,7.93-12.56,10.98-12.56,10.98v-28.92l18.51-0.09c4.47,0,7.34-0.21,7.34-4.35v-0.15    c0-4.52-2.87-5.68-7.4-5.68h-5.73v-0.04h-12.72V88.2h19.42c11.46,0,18.84,6.85,18.84,18.52V106.87z"></path></g></g></svg>)
        }
    }

    const eventLabel = (history: CotizacionHistory) => {
        if (history.event === 'changes_requested') { return 'Cambios solicitados por cliente' }
        if (history.event === 'changes_applied') { return 'Cambios aprobados' }
        if (history.event === 'changes_rejected') { return 'Cambios rechazados' }
        if (history.event === 'discount_expired') { return 'Descuento vencido' }
        if (history.event === 'envio') { return 'Cotización enviada' }
        if (history.event === 'pago') { return 'Pago realizado' }
        if (history.event === 'pago_confirm') { return 'Transferencia realizada' }
        if (history.event === 'accept_client') { return 'Aceptado por cliente' }
        if (history.event === 'accept_vendor') { return 'Aceptado por vendedor' }
        if (history.event === 'cancelled') { return 'Cotización cancelada' }
    }

    if (!histories.length) {
        return <div />;
    }
    const lastHistoryEvent = histories[histories.length - 1];
    const lastHistoryShouldDisplayMessage = (lastHistoryEvent.event === 'changes_applied' || lastHistoryEvent.event === 'changes_requested' || lastHistoryEvent.event === 'changes_rejected') && !!lastHistoryEvent.message;
    return (
        <div className={`_historyContainer`}>
            <h2>
                Historial
            </h2>
            <div className="_historyEntries">
                <div className="_guidelines" style={{ height: `${110 * (histories.length - 1)}px` }} />
                {histories.map((history) => (
                    <div key={history._id} className={`historyEntry ${history.event === 'cancelled' ? 'cancelled' : ''}`}>
                        {
                            !history.closed ?
                                <div className="_state empty">
                                    <span className="">

                                    </span>
                                </div>
                                :
                                (
                                    history.approved ?
                                        <div className="_state green">
                                            <span className="material-icons ">
                                                done
                                            </span>
                                        </div>
                                        :
                                        <div className="_state red">
                                            <span className="material-icons ">
                                                close
                                            </span>
                                        </div>
                                )
                        }

                        {avatarByWhom(history)}
                        <p className="_date">
                            {moment(history.createdAt).format('DD / MMM / YY')}
                        </p>
                        <p className="historyLabel">
                            <b>
                                {eventLabel(history)}
                            </b>
                        </p>
                        <p className={`historyTitle ${history.event === 'changes_requested' ? 'warning' : ''}`}>
                            {history.title}
                        </p>
                        <div className="xs">
                            <p className="_date">
                                {moment(history.createdAt).format('DD / MMM / YY')}
                            </p>
                            <p className="historyLabel">
                                <b>
                                    {eventLabel(history)}
                                </b>
                            </p>
                            <p className={`historyTitle ${history.event === 'changes_requested' ? 'warning' : ''}`}>
                                {history.title}
                            </p>
                        </div>
                    </div>
                ))}
                {
                    lastHistoryShouldDisplayMessage ?
                        <div className="historyMessage">
                            <p><b>Mensaje {lastHistoryEvent.whom === 'client' ? 'de cliente a vendedor' : 'de vendedor a cliente'}: </b></p>
                            <p>{lastHistoryEvent.message}</p>
                        </div>
                        : ''
                }
            </div>
        </div>
    )
}


export default CotizacionViewHistory;