import { Cotizacion } from "../models/Cotizaciones"

export const defineStatus = (cotizacion: Cotizacion) => {
    if (cotizacion.paid) {
        return { classAdd: 'paid', text: 'Pagada' }
    }
    else if (cotizacion.clientAccepted) {
        return { classAdd: 'approved', text: 'Aprobada' }
    }
    else if (cotizacion.changesRequested) {
        return { classAdd: 'changes', text: 'Negociación' }
    }
    else if (cotizacion.clientAccepted === false) {
        return { classAdd: 'rejected', text: 'Declinada' }
    } else {
        return { classAdd: 'abierta', text: 'Por revisar' }
    }
}

export function setSortOrder(cotizacion: Cotizacion) {
    if (cotizacion.paid) {
        return -1;
    }
    if (cotizacion.changesRequested) {
        return 3;
    }
    if (cotizacion.clientAccepted === false) {
        return 0
    }
    if (cotizacion.vendorAccepted === true) {
        return 2
    }
    return 1;
}