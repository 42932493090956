
import React, { useState } from 'react';
import { CartaPorteComplemento_Ubicacion_Autotransporte, CartaPorteComplemento_Ubicacion_Operador } from '../../models/Factura.complementos';
import Button from '../Forms/Button';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import { CartaPorteInfoOperadoresDialog } from './dialogs/CartaPorte.info.operadores';

interface CartaPorteOperadorProps {
    operador: CartaPorteComplemento_Ubicacion_Operador[];
    onOperadorDispatch: (operador: CartaPorteComplemento_Ubicacion_Operador[]) => void;
}

export const OPERADOR_TIPO_OPTIONS = [
    { value: '01', label: 'Operador' },
    { value: '02', label: 'Propietario' },
    { value: '03', label: 'Arrendador' },
    { value: '04', label: 'Notificado' },
]

export function CartaPorteOperador({
    operador,
    onOperadorDispatch
}: CartaPorteOperadorProps) {

    const [operadorToAdd, setOperadorToAdd] = useState<CartaPorteComplemento_Ubicacion_Operador>(null)

    const openNewOperador = () => {
        setOperadorToAdd({
            tipo: '01',
            rfc: '',
            licencia: ''
        })
    }

    const handleOperatorChange = (property: keyof CartaPorteComplemento_Ubicacion_Operador) => (value: string) => {
        const _operadorToAdd = { ...operadorToAdd };
        if(property === 'rfc') {
            value = value.toUpperCase().split('').filter(s => /\w/g.test(s)).join('')
        }
        _operadorToAdd[property] = value as any;
        setOperadorToAdd(_operadorToAdd);
    }

    const handleOperatorToAdd = () => {
        const _operators: CartaPorteComplemento_Ubicacion_Operador[] = JSON.parse(JSON.stringify(operador))
        _operators.push(operadorToAdd);
        onOperadorDispatch(_operators)
        setOperadorToAdd(null)
    }

    const handleOperadorDelete = (index: number) => () => {
        onOperadorDispatch(operador.filter((_, i) => i !== index))
    }

    const operadorIsValid = () => {
        if(!operadorToAdd){
            return false;
        }
        // Only '01' types require licence
        const licenceField = operadorToAdd.tipo === '01' ? operadorToAdd.licencia.length > 1 : true
        return operadorToAdd.tipo && licenceField && (operadorToAdd.rfc.length > 11 && operadorToAdd.rfc.length < 14)
    }

    return (
        <div className='operador'>
            <h4>{'Operador del Transporte'}</h4>
            <div className={`card table`}>
                <CartaPorteInfoOperadoresDialog />
                <div className="cardBody">
                    <div className='row'>
                        <div className='addOperador'>
                            {
                                !operadorToAdd ?
                                    <div className='row _action'>
                                        <div className="start">
                                            <Button tertiary={true} handleClick={openNewOperador} >
                                                <div className='withIcon'>
                                                    <span className='material-icons'>
                                                        add
                                                    </span>
                                                    <span>
                                                        Agregar operador
                                                    </span>
                                                </div>
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    <div className='row _form'>
                                        <div className='row _operador'>
                                            <Select
                                                label={'Tipo de Operador'}
                                                options={OPERADOR_TIPO_OPTIONS}
                                                onChange={handleOperatorChange('tipo')}
                                                value={operadorToAdd.tipo}
                                            />
                                            <Input
                                                type='text'
                                                label={'RFC operador'}
                                                onChange={handleOperatorChange('rfc')}
                                                value={operadorToAdd.rfc}
                                                errorLabel="RFC incorrecto"
                                                hasError={operadorToAdd.rfc.length && (operadorToAdd.rfc.length < 12 || operadorToAdd.rfc.length > 13)}
                                            />
                                            <Input
                                                type='text'
                                                label={'Licencia'}
                                                onChange={handleOperatorChange('licencia')}
                                                value={operadorToAdd.licencia}
                                            />
                                            <div className='end centerY'>
                                                <Button secondary={true} handleClick={handleOperatorToAdd} disabled={!(operadorIsValid())}>
                                                    <span className='small'>
                                                        Agregar Operador
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className='operatorList'>
                            <div className='operator header'>
                                <p className='tipo'>Tipo de operador</p>
                                <p className='sm-hidden'>RFC</p>
                                <p className='licence'>Licencia</p>
                            </div>
                            {
                                operador.map((rem, i) => {
                                    const tipoDe = OPERADOR_TIPO_OPTIONS.find((Op) => Op.value === rem.tipo)
                                    return (
                                        <div className='operator' key={i}>
                                            <p className='tipo'>
                                                ({tipoDe.value}) {tipoDe.label}
                                            </p>
                                            <p  className='rfc'>{rem.rfc}</p>
                                            <p>{rem.licencia}</p>
                                            <Button tertiary={true} setClass="_delete" handleClick={handleOperadorDelete(i)}>
                                                <span className='material-icons'>
                                                    delete
                                                </span>
                                            </Button>
                                        </div>
                                    )
                                })
                            }
                            {
                                !operador.length ?
                                    <div className='_emtpy'>
                                        <p>
                                            Agrega operadores de transporte
                                        </p>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}