import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ProfileQL } from '../../models/Profile';
import ProfileAvatar from '../visuals/avatar';

import './accountAvatarHolder.scss';

interface AccountAvatarHolderProps {
    currentUser: ProfileQL;
}

const AccountAvatarHolder = ({ currentUser }: AccountAvatarHolderProps) => {
    const history = useHistory();

    const goToPlanes = () => {
        if(currentUser.billing?.isClusterType) {
            // "Gestor" types should not upgrade
            return
        }
        history.push({
            pathname: '/planes'
        })
    }

    return (
        <div className="salut">
            <div className="avatarHolder">
                <ProfileAvatar currentUser={currentUser} absolute={true} />
            </div>
            <div className="whois">
                <h1>¡Hola {currentUser.professionalProfile.companyName}! </h1>
            </div>
            <div className="planInfo">
                {
                    currentUser.professionalProfile.trialExpires ?
                        null :
                        <div className="currentPlan">
                            <span className="whois">Plan actual -</span>
                        </div>
                }
                <div className={`planType ${currentUser.professionalProfile.planType}`} onClick={goToPlanes}>
                    <span>{currentUser.professionalProfile.planType} {currentUser.professionalProfile.trialExpires ? `TRIAL - ${moment(currentUser.professionalProfile.trialExpires).fromNow(true)} RESTANTES` : ''}</span>
                </div>
            </div>
        </div>
    )
}

export default AccountAvatarHolder;