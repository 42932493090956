import React, { useState } from 'react';
import { BankProjectionGoal } from '../../../../models/Administracion/Bank.Projects';
import unifiedMoment from '../../../../services/unifiedMoment';
import { labelFormat } from '../../../components/textFormatting';
import InputSearch from '../../../Forms/Input.search';
import { formatCurrencyStyled } from '../../../visuals/currency.formatted';
import FlagSvg from '../../../visuals/svg/flagIcon';

import './Projection.overview.goals.scss'

function ProjectionOverviewGoals({ goals }: { goals: BankProjectionGoal[] }) {

    const [search, setSearch] = useState('')
    const [selected, setSelected] = useState('');

    const typeFromTarget = (target: string) => {
        if (target === 'balance') { return 'Balance' }
        if (target === 'expense') { return 'Gasto' }
        if (target === 'income') { return 'Ingreso' }
        return ''
    }

    const onSearch = (text: string) => {
        setSearch(text);
        setSelected('')

    }

    const onSelected = (id: string) => () => {
        if (selected === id) {
            setSelected('')
        } else {
            setSelected(id)
        }
    }

    return (
        <div className='_overviewGoals'>
            <div className='goalSlider'>
                <div className='goalSliderRail'>
                    {
                        goals.map((goal, i) => {
                            const isSelected = goal._id === selected;
                            return (
                                <div key={i} className={`goalCard ${isSelected ? 'selected' : ''}`} onClick={onSelected(goal._id)}>
                                    <FlagSvg width={18} color={'#AC2E78'} />
                                    <div className='text noselect'>
                                        <p className='name'>
                                            {goal.name}
                                        </p>
                                        <p className='percent'>
                                            {Math.round(goal.probability * 100)}%
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='goalTable'>
                <div className='_mobileSearch'>
                    <InputSearch placeholder='Buscar meta' onChange={onSearch} debounceTs={200} />
                    <span />
                </div>
                <div className='goalEntry header'>
                    <p>Metas</p>
                    <p className='center'>Tipo de Meta</p>
                    <p className='center'>Chance de éxito</p>
                    <div className='searchBox _search'>
                        <p>Status</p>
                        <InputSearch placeholder='Buscar meta' onChange={onSearch} debounceTs={200} />
                    </div>
                </div>
                {
                    goals.filter(gD => {
                        if (selected) {
                            return gD._id === selected
                        }
                        if (!search) {
                            return true
                        }
                        return gD.name.toLowerCase().includes(search.toLowerCase())
                    }).map((goal, i) => {
                        return (
                            <div className='goalEntry' key={i}>
                                <div>
                                    <p className='id'>{goal.name}</p>
                                    <p className='small date'>{unifiedMoment(goal.dueDateTS).format('MMM DD, YYYY')}</p>
                                </div>
                                <div className='center'>
                                    <p className='type'>{typeFromTarget(goal.target)}</p>
                                    <p className='small'>{formatCurrencyStyled(goal.goal)}</p>
                                </div>
                                <p className='center'>{Math.round(goal.probability * 10000) / 100}%</p>
                                <div className='_search'>
                                    <p className='statusLabel'>{labelFormat(goal.status)}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ProjectionOverviewGoals;