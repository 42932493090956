
import React, { ReactElement, useEffect, useState } from 'react';
import { CartaPorteComplemento_Ubicacion_Autotransporte, CartaPorteComplemento_Ubicacion_Operador, TrasladoRequestConceptos } from '../../models/Factura.complementos';
import Button from '../Forms/Button';
import Input from '../Forms/Input';
import Select from '../Forms/Select';

import Modal from 'react-modal';

import './CartaPorte.mercancia.dialog.scss';
import { post } from '../../services/post';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import AutoComplete from '../Forms/Autocomplete';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { numberToCurrencyString } from '../../services/formatting';
import IconButton from '../Forms/IconButton';
import Pagination from '../Forms/Pagination';

interface CartaPorteMercanciasProps {
    authToken: string
    isSelfReceptor: boolean;
    mercancias: TrasladoRequestConceptos[];
    onToPreview: () => void;
    backToBase: () => void;
    onMercanciasDispatch: (mercancias: TrasladoRequestConceptos[]) => void;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        maxWidth: '96vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

export function CartaPorteMercancias({
    authToken,
    mercancias,
    isSelfReceptor,
    onToPreview,
    backToBase,
    onMercanciasDispatch
}: CartaPorteMercanciasProps) {

    const [mercanciaToAdd, setMercanciaToAdd] = useState<TrasladoRequestConceptos>(null)
    const [page, setPage] = useState<number>(1)

    const [isLoading, setIsLoading] = useState(false);
    const [prodServSubject] = useState(new Subject<string>());
    const [unidadSubject] = useState(new Subject<string>());
    const [unidadOptions, setUnidadOptions] = useState<{ label: string; value: string; template?: ReactElement }[]>([])
    const [claveOptions, setClaveOptions] = useState<{ label: string; value: string; template?: ReactElement }[]>([])



    useEffect(() => {
        const fetchUnidad = (defaultUnidadSat: string) => {
            setIsLoading(true);
            return post(`https://api.fiscalpop.com/api/v1/sat/claveUnidades/${authToken}`, {
                compare: defaultUnidadSat,
                clave: defaultUnidadSat
            }).then(response => {
                setIsLoading(false);
                // console.log('Fetch unidad: ', response);
                setUnidadOptions(response.map((r: { clave: string, nombre: string, template?: ReactElement }) => ({ label: `(${r.clave}) ${r.nombre}`, value: r.clave, template: <p><span >{r.clave}</span><br /><span className='small'>{r.nombre}</span></p> })))
            })
        }


        const fetchClaves = (defaultClaveSatProdServ: string) => {
            // console.log('[SETUP - DEFAULTS] Fetching claves: ', defaultClaveSatProdServ);
            setIsLoading(true);
            return post(`https://api.fiscalpop.com/api/v1/sat/productosServicios/${authToken}`, {
                compare: defaultClaveSatProdServ,
                clave: defaultClaveSatProdServ
            }).then(response => {
                setIsLoading(false);
                setClaveOptions(response.map((r: { clave: string, descripcion: string, template?: ReactElement }) => ({ label: `(${r.clave}) ${r.descripcion}`, value: r.clave, template: <p><span >{r.clave}</span><br /><span className='small'>{r.descripcion}</span></p> })))
            })
        }

        const prodSubs = prodServSubject.pipe(debounceTime(200)).subscribe(s => {
            fetchClaves(s || '');
        })
        const unidadSubs = unidadSubject.pipe(debounceTime(200)).subscribe(s => {
            fetchUnidad(s || '');
        })

        return () => {
            prodSubs.unsubscribe()
            unidadSubs.unsubscribe()
        }
    }, [prodServSubject, unidadSubject, authToken])

    const openNewMercancia = () => {
        setMercanciaToAdd({
            claveProdServ: '',
            claveUnidad: '',
            cantidad: 1,
            descripcion: '',
            valorUnitario: 0,
            // Transporte generates Mercancias based on Concept Keys
            pesoKg: 0
        })
    }

    const handleMercanciaChange = (property: keyof TrasladoRequestConceptos) => (value: string | number) => {
        const _mercanciaToAdd = { ...mercanciaToAdd };
        if (property === 'cantidad' || property === 'valorUnitario' || property === 'pesoKg') {
            _mercanciaToAdd[property] = value as number;
        } else {
            _mercanciaToAdd[property] = value as string;
        }
        if (property === 'claveProdServ') {
            prodServSubject.next(value as string);
        }
        if (property === 'claveUnidad') {
            unidadSubject.next(value as string);
        }
        setMercanciaToAdd(_mercanciaToAdd);
    }

    const handleMercanciaToAdd = () => {
        const _mercancias: TrasladoRequestConceptos[] = JSON.parse(JSON.stringify(mercancias))
        _mercancias.push(mercanciaToAdd);
        setMercanciaToAdd(null)
        onMercanciasDispatch(_mercancias)
    }

    const handleMercanciaToAddNew = () => {
        const _mercancias: TrasladoRequestConceptos[] = JSON.parse(JSON.stringify(mercancias))
        _mercancias.push(mercanciaToAdd);
        onMercanciasDispatch(_mercancias)
        sharedToasterSubject.next({ type: 'warning', message: `Se agregó "${mercanciaToAdd.descripcion}" a mercancías` })
        openNewMercancia()
    }

    const closeMercanciaToAdd = () => {
        setMercanciaToAdd(null)
    }

    const handleMercanciaDelete = (index: number) => () => {
        onMercanciasDispatch(mercancias.filter((_, i) => i !== index))
    }

    const mercanciaIsValid = () => {
        if (!mercanciaToAdd) {
            return false;
        }

        return !!mercanciaToAdd.claveProdServ && !!mercanciaToAdd.claveUnidad && !!mercanciaToAdd.cantidad && !!mercanciaToAdd.descripcion && !!mercanciaToAdd.pesoKg;
    }


    const renderMercanciaDialog = () => {
        if (!mercanciaToAdd) {
            return null;
        }
        return (
            <Modal
                isOpen={!!mercanciaToAdd}
                onRequestClose={closeMercanciaToAdd}
                style={customStyles}
                ariaHideApp={false}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={false}
                contentLabel="Agregar nueva mercancía"
            >
                <div className='card table' id="cartaPorteMercanciaDialog">
                    <div className="cardTitle">
                        <div className='withIcon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="0 0 29 24" fill="none">
                                <path d="M8.75024 6.25H21.8752V17.625H8.75024V6.25Z" fill="#AC2E78" stroke="#AC2E78" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.75024 10.6248H5.25024L2.62524 13.2498V17.6248H8.75024V10.6248Z" fill="#AC2E78" stroke="#AC2E78" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <circle cx="7" cy="20.2498" r="2.75" stroke="white" strokeWidth="2" />
                                <circle cx="18.3752" cy="20.2498" r="2.75" stroke="white" strokeWidth="2" />
                                <path d="M18.3748 21.1252C17.4083 21.1252 16.6248 20.3417 16.6248 19.3752C16.6248 18.4087 17.4083 17.6252 18.3748 17.6252C19.3413 17.6252 20.1248 18.4087 20.1248 19.3752C20.1248 20.3417 19.3413 21.1252 18.3748 21.1252Z" fill="#AC2E78" />
                                <path d="M7.00024 21.1252C6.03375 21.1252 5.25024 20.3417 5.25024 19.3752C5.25024 18.4087 6.03375 17.6252 7.00024 17.6252C7.96674 17.6252 8.75024 18.4087 8.75024 19.3752C8.75024 20.3417 7.96674 21.1252 7.00024 21.1252Z" fill="#AC2E78" />
                                <path d="M15.4002 1H26.6002C27.3702 1 28.0002 1.59062 28.0002 2.3125V10.1875C28.0002 10.9094 27.3702 11.5 26.6002 11.5H15.4002C14.6302 11.5 14.0002 10.9094 14.0002 10.1875V2.3125C14.0002 1.59062 14.6302 1 15.4002 1Z" fill="#AC2E78" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M28.0002 2.3125L21.0002 6.90625L14.0002 2.3125" fill="#AC2E78" />
                                <path d="M28.0002 2.3125L21.0002 6.90625L14.0002 2.3125" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.875 3.18774V15.8752" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M3.5 15.875H0V14.125H3.5V15.875Z" fill="white" />
                            </svg>
                            <h4>Agregar Mercancía</h4>
                        </div>
                    </div>
                    <div className='cardBody'>
                        <div className='_entryGroup'>
                            <div className='_groupIndex'>
                                <p>
                                    {mercancias.length + 1}.
                                </p>
                            </div>
                            <div className='_groupFields'>
                                <div className='_fields'>
                                    <AutoComplete displayLabel={true} isLoading={isLoading} label="Clave producto" hasError={(mercanciaToAdd.claveProdServ && !claveOptions.length)} value={mercanciaToAdd.claveProdServ} forcePropsValue={true} placeholder="Clave de producto o servicio" type="text" options={claveOptions} onChange={handleMercanciaChange('claveProdServ')} onSelect={handleMercanciaChange('claveProdServ')} />
                                    <AutoComplete displayLabel={true} isLoading={isLoading} label="Clave unidad" hasError={(mercanciaToAdd.claveUnidad && !unidadOptions.length)} value={mercanciaToAdd.claveUnidad} forcePropsValue={true} placeholder="Clave de unidad" type="text" options={unidadOptions} onChange={handleMercanciaChange('claveUnidad')} onSelect={handleMercanciaChange('claveUnidad')} />
                                    <Input type='number' value={mercanciaToAdd.cantidad} label='Cantidad' min={0} onChange={handleMercanciaChange('cantidad')} />
                                </div>
                                <div className='_fields'>
                                    <Input type='text' value={mercanciaToAdd.descripcion} label='Descripción' onChange={handleMercanciaChange('descripcion')} placeholder='Nombre o descripción' />
                                    <Input type='number' value={mercanciaToAdd.pesoKg} label='Peso en Kilogramos' captionLabel='Peso total (todas las unidades)'  min={0} onChange={handleMercanciaChange('pesoKg')} placeholder='Peso en KG' />
                                    <Input type='number' isCurrency={true} value={mercanciaToAdd.valorUnitario} label='Valor Unitario' captionLabel='Puede ser $0' min={0} onChange={handleMercanciaChange('valorUnitario')} />

                                </div>
                            </div>
                        </div>
                        <div className='row actions'>
                            <Button primary={true} handleClick={handleMercanciaToAdd} disabled={!mercanciaIsValid()}>
                                <span>
                                    Guardar
                                </span>
                            </Button>
                            <Button primary={true} handleClick={handleMercanciaToAddNew} disabled={!mercanciaIsValid()}>
                                <span>
                                    Guardar y agregar nuevo
                                </span>
                            </Button>

                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    const PER_PAGE = 10;

    return (
        <div className='mercancias'>
            <h4>Mercancías</h4>
            <div className={`card table`}>
                <div className="cardBody">
                    <div className='row'>
                        <div className='mercanciaList'>
                            <div className='mercancia header'>
                                <p className='index'>#</p>
                                <p className='prodServ'>Clave Producto</p>
                                <p className='descripcion'>Descripción</p>
                                <p className='cantidad'>Cantidad</p>
                                <p className='valUnit'>Valor Unitario</p>
                                <p className='weight'>Peso en KG</p>
                                <div />
                            </div>
                            {
                                mercancias.filter((_, i) => i >= ((page - 1) * PER_PAGE) && (i < (page * PER_PAGE))).map((rem, i) => {
                                    return (
                                        <div className='mercancia' key={i}>
                                            <p className='index'>
                                                {(i + 1) + ((page - 1) * PER_PAGE)}.
                                            </p>
                                            <div className='prodServ row'>
                                                <p className='prodServ'>{rem.claveProdServ}</p>
                                                <p className='small gray'>{rem.claveUnidad}</p>
                                            </div>
                                            <p className='descripcion'>{rem.descripcion}</p>
                                            <p className='cantidad'>{rem.cantidad}</p>
                                            <p className='valUnit'>{numberToCurrencyString(rem.valorUnitario)}</p>
                                            <p className='weight'>{rem.pesoKg} KG</p>
                                            <Button tertiary={true} setClass="_delete" handleClick={handleMercanciaDelete(i)}>
                                                <span className='material-icons'>
                                                    delete
                                                </span>
                                            </Button>
                                        </div>
                                    )
                                })
                            }
                            {
                                !mercancias.length ?
                                    <div className='_emtpy'>
                                        <p>
                                            Agrega las mercancías
                                        </p>
                                    </div>
                                    : null
                            }
                            <div className='row'>
                                <div className='row end'>
                                    <IconButton handleClick={openNewMercancia} primary={true}>
                                        <span className='material-icons'>
                                            add
                                        </span>
                                    </IconButton>
                                </div>
                            </div>
                            <Pagination page={page} pages={Math.ceil(mercancias.length / PER_PAGE)} onPage={setPage} />
                        </div>
                    </div>
                </div>
            </div>
            {renderMercanciaDialog()}
            <div className='row two actions sm-one'>
                <div className='start centerY'>
                    <Button secondary={true} handleClick={backToBase}>
                        <span>
                            Atrás
                        </span>
                    </Button>
                </div>
                <div className='end centerY'>
                    <Button primary={true} disabled={!mercancias.length} handleClick={onToPreview} >
                        <span>
                            Previsualizar
                        </span>
                    </Button>

                </div>
            </div>
        </div>
    )
}