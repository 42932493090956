import React, { useState } from 'react';

import './Setup.cotizaciones.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile'
import SectionTitleBar from '../components/titlebar';

import moment from 'moment';
import StripeAccount from './Setup.stripe.account';
import { useQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import { Balances } from '../../models/Cotizaciones';
import { formatCurrencyStyled } from '../visuals/currency.formatted';
import SetupMenu from './Setup.menu';
import AccountAvatarHolder from '../components/accountAvatarHolder';
import AccountAvatarHolderMobile from '../components/accountAvatarHolder.mobile';

moment.locale('es');

interface SetupProps extends BaseReact {
    currentUser: ProfileQL;
}



function SetupCotizacionFiscalPop({ currentUser }: SetupProps) {
    const [balance, setBalance] = useState<Balances>(null);

    useQuery(graphqlSchema.FISCALPOP.STRIPE.fetchStripeBalance, {
        onCompleted: ({ fetchStripeBalance }: { fetchStripeBalance: Balances }) => {
            console.log('fetchStripeBalance: ', fetchStripeBalance);
            if (fetchStripeBalance.balance === null) {
                return;
            }
            setBalance(fetchStripeBalance);
        },
        onError: (e) => {
            console.error('Error fetching StripeBalance: ', e.graphQLErrors[0]);
        }
    })

    const formatTransactType = (transaction: Balances['balanceTransactions'][0]) => {
        if (transaction.type === 'charge') {
            // Cobro a cliente
            return { classType: 'charge', icon: 'monetization_on', text: 'Cobro' }
        }
        if (transaction.type === 'payout') {
            // Cobro a cliente
            return { classType: 'payout', icon: 'account_balance', text: 'Tranferido a Banco' }
        }
        return { classType: 'charge', icon: 'monetization_on', text: 'Tarifa' }
    }

    return (
        <div id="SetupConfigFiscalPop">
            <SectionTitleBar currentUser={currentUser} title="Mi emisor" />
            <div className="setupContent">
                <SetupMenu />
                <div className="card profile">
                    <AccountAvatarHolder currentUser={currentUser} />
                    <AccountAvatarHolderMobile currentUser={currentUser} />
                    <div className="setupPadding">
                        <h4>Recibir pagos de cotizaciones</h4>
                        <StripeAccount currentUser={currentUser} />
                    </div>
                    {
                        balance ?

                            <div className="setupPadding">
                                <h4>Últimos movimientos de tu cuenta Stripe</h4>
                                {
                                    balance.balanceTransactions.length ? '' :
                                        <div className="stripeMovementEmpty">
                                            <h2>
                                                Todavía no cuentas con movimientos en Stripe
                                        </h2>
                                        </div>
                                }
                                {
                                    balance.balanceTransactions.map((transact, i) => {
                                        const { classType, icon, text } = formatTransactType(transact);
                                        return (
                                            <div className={`stripeMovementEntry ${classType} ${transact.status === 'pending' ? 'pending' : ''}`} key={transact.id}>
                                                <span className="material-icons">
                                                    {icon}
                                                </span>
                                                <div className="date">
                                                    <p className="_date">
                                                        {moment(transact.created * 1000).format('MMMM DD, YYYY')}
                                                    </p>
                                                    <p className="_hour">
                                                        {moment(transact.created * 1000).format(' h:mm:ss a')}
                                                    </p>
                                                </div>
                                                <p className="_descripcion">
                                                    {transact.description || text} {classType === 'payout' && !!transact.description ? <span className="small">({text})</span> : ''}
                                                </p>
                                                {
                                                    transact.status === 'pending' ?
                                                        <div className="status _pending">
                                                            <span className="material-icons">
                                                                schedule
                                            </span>
                                                            <p>
                                                                Pendiente
                                            </p>
                                                        </div>
                                                        :
                                                        <div className="status _available">
                                                            <span className="material-icons">
                                                                done
                                            </span>
                                                            <p>
                                                                Disponible
                                            </p>
                                                        </div>
                                                }
                                                <p className="_amount">
                                                    {formatCurrencyStyled(transact.net / 100)}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : ''
                    }
                </div>
            </div>
        </div >
    )
}

export default SetupCotizacionFiscalPop;