
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseReact } from '../../base.model';
import { CartaPorteState } from '../../models/Administracion/CartaPorte';
import { BillCartaPorte, BillQrSello, ToBillArguments, ToBillEvent } from '../../models/Factura';
import { ProfileQL } from '../../models/Profile';
import { numberToCurrencyString } from '../../services/formatting';
import { graphqlSchema } from '../../services/graphql.schema';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import unifiedMoment from '../../services/unifiedMoment';
import { parseStampErrorFromGraphQL } from '../../utils/error.graphql';
import { clearGqlTypename } from '../../utils/formatting';
import SectionTitleBar from '../components/titlebar';
import Button from '../Forms/Button';
import { TIPO_PERMISO_SCT, TIPO_REMOLQUE, TIPO_VEHICULO_OPTIONS } from './CartaPorte.autotransporte';
import { OPERADOR_TIPO_OPTIONS } from './CartaPorte.operador';

import './CartaPorte.preview.scss'
import GoggleMapWrapper from './helper/CartaPorte.map';
import { formatMapsUrlForCartaPorte } from './helper/CartaPorte.maps.url';


interface CartaPortePreviewProps extends BaseReact {
    currentUser: ProfileQL

}

export function CartaPortePreview({ currentUser, location, history }: CartaPortePreviewProps) {
    const { argumentid } = useParams<{ argumentid: string }>();

    const [toBillArgument, setToBillArgument] = useState<ToBillArguments<ToBillEvent, BillCartaPorte>>(null);
    const [qrSello, setQrSello] = useState<BillQrSello>(null);

    const [countries, setCountries] = useState<{ value: string, label: string }[]>([])
    const [estados, setEstados] = useState<{ value: string, label: string }[]>([])

    const [stamping, setStamping] = useState(false);


    useQuery(graphqlSchema.FISCALPOP.CARTAPORTE.getCountries, {
        onCompleted: ({ getCountries }: { getCountries: { clave: string, pais: string }[] }) => {
            console.log('getCountries: ', getCountries);
            setCountries(getCountries.map(c => ({ label: c.pais, value: c.clave })));
        },
        onError: (e) => {
            console.error('Error getting countries: ', e.graphQLErrors[0])
        },
        fetchPolicy: 'cache-and-network'
    })

    useQuery(graphqlSchema.FISCALPOP.CARTAPORTE.getState, {
        onCompleted: ({ getState }: { getState: CartaPorteState[] }) => {
            console.log('getState: ', getState);
            setEstados(getState.map(c => ({ label: c.nombre, value: c.clave })));
        },
        onError: (e) => {
            console.error('Error getting countries: ', e.graphQLErrors[0])
        },
        fetchPolicy: 'cache-and-network'
    })

    const [getQrSello] = useLazyQuery(graphqlSchema.FISCALPOP.CFDI.getBillSignatureByUUID, {
        onCompleted: ({ getBillSignatureByUUID }: { getBillSignatureByUUID: BillQrSello }) => {
            console.log('getQrSello: ', getBillSignatureByUUID);
            setQrSello(getBillSignatureByUUID);
        },
        onError: (e) => {
            console.error('Error getting countries: ', e.graphQLErrors[0])
        },
        fetchPolicy: 'cache-first'
    })

    const [setArgumentForStamping] = useMutation(graphqlSchema.FISCALPOP.CFDI.stampFromBillArgument, {
        onCompleted: ({ stampFromBillArgument }: { stampFromBillArgument: ToBillArguments<string> }) => {
            console.log('STAMP FROM BILL ARGUMENT: ', stampFromBillArgument);
            sharedToasterSubject.next({ type: 'confirm', message: 'Factura emitida correctamente' });
            history.push({
                pathname: '/',
                state: { billArgument: stampFromBillArgument }
            })
        },
        onError: (e) => {
            console.error('Error SAving GQL: ', e.graphQLErrors[0]);
            if (e.graphQLErrors[0].message.includes('Saldo insuficiente')) {
                sharedToasterSubject.next({ type: 'error', message: e.graphQLErrors[0].message });
            } else {
                sharedToasterSubject.next({ type: 'error', message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clearTs: 5200, clickToClose: true });
            }
            setStamping(false);
        }
    })

    const [editBillArgument] = useMutation(graphqlSchema.FISCALPOP.CFDI.restampBillArgument, {
        onCompleted: ({ restampBillArgument }: { restampBillArgument: ToBillArguments<string> }) => {
            console.log('EDIT FROM BILL ARGUMENT: ', restampBillArgument);
            sharedToasterSubject.next({ type: 'confirm', message: 'Factura emitida correctamente' });
            history.push({
                pathname: '/',
                state: { billArgument: restampBillArgument }
            })
        },
        onError: (e) => {
            console.error('Error SAving GQL: ', e.graphQLErrors[0]);
            if (e.graphQLErrors[0].message.includes('Saldo insuficiente')) {
                sharedToasterSubject.next({ type: 'error', message: e.graphQLErrors[0].message });
            } else {
                sharedToasterSubject.next({ type: 'error', message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clearTs: 5200, clickToClose: true });
            }
            setStamping(false);
        }
    })

    useEffect(() => {
        // Extract state and use for current operations    
        const { billArgument } = location.state as { billArgument: ToBillArguments<ToBillEvent, BillCartaPorte>; };
        console.log('Carta Porte Preview: ', billArgument)
        if (billArgument && billArgument.specialType === 'cartaporte') {
            setToBillArgument(clearGqlTypename(billArgument));
            if (billArgument.uuid) {
                getQrSello({ variables: { uuid: billArgument.uuid } });
            }
        } else {
            sharedToasterSubject.next({ type: 'error', message: 'No se encontró la carta porte' })
            history.goBack();
        }
    }, [location, history, getQrSello]);

    const googleMapsUrl = useMemo(() => {
        if (!toBillArgument || !countries.length || !estados.length) {
            return '';
        }
        const cartaPorte = toBillArgument?.MasterCFDI?.cartaPorte;
        return formatMapsUrlForCartaPorte(cartaPorte, countries, estados);
    }, [toBillArgument, countries, estados])

    const labelPermisoSct = (permisoSct: string) => {
        return `${TIPO_PERMISO_SCT.find(tPS => tPS.value === permisoSct)?.label || 'No definido'}`
    }

    const labelTipoVehiculo = (vehiculo: string) => {
        if (!vehiculo) {
            return ''
        }
        return `(${vehiculo}) ${TIPO_VEHICULO_OPTIONS.find(tVO => tVO.value === vehiculo)?.display || 'No definido'}`
    }

    const labelTipoRemolque = (remolque: string) => {
        if (!remolque) {
            return ''
        }
        return `(${remolque}) ${TIPO_REMOLQUE.find(tR => tR.value === remolque)?.label || 'No definido'}`
    }

    const labelTipoOperador = (operador: string) => {
        if (!operador) {
            return ''
        }
        return `(${operador}) ${OPERADOR_TIPO_OPTIONS.find(oTO => oTO.value === operador)?.label || 'No definido'}`
    }

    const labelEstado = (estado: string) => {
        if (!estados.length || !estado) {
            return ''
        }
        return `${estados.find(e => e.value === estado)?.label || estado}`
    }

    const labelTipoComprobante = (tipoComprobante: string) => {
        if (tipoDeComprobante === 'T') {
            return 'Traslado'
        }
        if (tipoDeComprobante === 'I') {
            return 'Ingreso'
        }
        return ''
    }

    const backToEditSubmit = () => {
        console.log('EDIT CARTA PORTE (billArgumento): ', toBillArgument)
        console.log('EDIT CARTA PORTE (cfdi): ', toBillArgument.MasterCFDI);
        // Make sure a validation is executed if necessary
        const _billArgument = Object.assign({}, toBillArgument)
        const pathName = argumentid ? `/facturacion/cartaporte/${argumentid}` : '/facturacion/cartaporte';
        history.push({
            pathname: pathName,
            state: { billArgument: _billArgument }
        })
    }

    const submitArgument = () => {
        setStamping(true);
        console.log('STAMPING CARTA PORTE: ', toBillArgument);
        if (argumentid) {
            // Edit and replace whole bill argument
            editBillArgument({
                variables: {
                    billArgumentToEditId: argumentid,
                    argument: toBillArgument,
                    billEvents: [],
                    paymentEvents: []
                }
            })
        } else {
            // Create new Bill argument
            setArgumentForStamping({
                variables: {
                    argument: toBillArgument,
                    billEvents: [],
                    paymentEvents: []
                }
            })
        }
    }


    // END Handlers and functions
    // --------------------------------------------------------------------------------------------

    const { createdAt } = toBillArgument || {};
    const { serie, folio, tipoDeComprobante } = toBillArgument?.MasterCFDI || {};
    const isSelfReceptor = toBillArgument?.MasterCFDI?.receptor?.rfc === currentUser?.fiscalpopProfile?.rfc;
    const receptor = toBillArgument?.MasterCFDI?.receptor;
    const cartaPorte = toBillArgument?.MasterCFDI?.cartaPorte;


    return (
        <div id="CartaPortePreview">
            <SectionTitleBar currentUser={currentUser} title={argumentid ? 'Ver Carta Porte' : 'Carta Porte'} />
            <div className="content">
                <div className='card table'>
                    <div className='cardBody'>
                        <div>
                            <div className='sectionWithDownload'>
                                <h5>Receptor</h5>
                                {
                                    toBillArgument?.uuid ?
                                        <a title="Descargar PDF" rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${currentUser.fiscalpopProfile.authToken}?uuid=${toBillArgument.uuid}`} download={true}>
                                            <div className="_link">
                                                <span>
                                                    Descargar PDF
                                                </span>
                                                <i className="material-icons">
                                                    picture_as_pdf
                                                </i>
                                            </div>
                                        </a>
                                        : null
                                }
                            </div>
                            <div className='row four lg-three sub-sm-two xs-one gap-compressed'>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Nombre
                                    </p>
                                    <p>
                                        {receptor?.nombre}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        RFC
                                    </p>
                                    <p>
                                        {receptor?.rfc}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Uso CFDI
                                    </p>
                                    <p>
                                        {receptor?.usoCFDI}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Código Postal
                                    </p>
                                    <p>
                                        {receptor?.zip}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Régimen
                                    </p>
                                    <p>
                                        {receptor?.regimen}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Serie y Folio
                                    </p>
                                    <p>
                                        {serie} - {folio}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Tipo comprobante
                                    </p>
                                    <p>
                                        {labelTipoComprobante(tipoDeComprobante)}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Fecha creación
                                    </p>
                                    <p className='_withIcon'>
                                        {unifiedMoment(createdAt || Date.now()).format('DD MMM YYYY')}
                                        <span className="material-icons">
                                            calendar_today
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {
                            isSelfReceptor ? null :
                                <div>
                                    <h5>Conceptos</h5>
                                    <div className='conceptosList'>
                                        <div className='concepto _header'>
                                            <p className='_claveSat'>
                                                Clave SAT
                                            </p>
                                            <p className='_descripcion'>
                                                Descripción
                                            </p>
                                            <p className='_cantidad'>
                                                Cantidad
                                            </p>
                                            <p className='_valorUnitario'>
                                                Valor unitario
                                            </p>
                                            <p className='_impuesto'>
                                                Impuestos
                                            </p>
                                        </div>
                                        {
                                            toBillArgument?.MasterCFDI?.conceptos?.map((concepto, index) => {
                                                return (
                                                    <div key={index} className='concepto _entry'>
                                                        <p className='_claveSat'>
                                                            {concepto.claveProdServ} <br />
                                                            <span className='small'>
                                                                {concepto.claveUnidad}
                                                            </span>
                                                        </p>
                                                        <p className='_descripcion'>
                                                            {concepto.descripcion}
                                                        </p>
                                                        <p className='_cantidad'>
                                                            {concepto.cantidad}
                                                        </p>
                                                        <p className='_valorUnitario'>
                                                            {numberToCurrencyString(concepto.valorUnitario)}
                                                        </p>
                                                        <p className='_impuesto'>
                                                            {concepto.impuestos?.map((impuesto, i) => {
                                                                return (
                                                                    <span key={`${i}-${index}`} className='small'>
                                                                        {impuesto.type} {impuesto.retencion ? 'ret.' : 'tras.'} {impuesto.tasa * 100}% <br />
                                                                    </span>
                                                                )
                                                            })
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                        }
                        {
                            // TODO: Add distance between cities
                            // TODO: Cosider Pulling Sello and Cadena Original from CFDI
                            !qrSello ? null :
                                <div className='_selloQr'>
                                    <img src={qrSello.qrUrl} alt="QR sello" />
                                    <div className='row selloText'>
                                        <div className='row centerY'>
                                            <p>
                                                <b>
                                                    Sello digital del Emisor
                                                </b>
                                            </p>
                                            <p className='small'>
                                                {qrSello.selloEmisor}
                                            </p>
                                        </div>
                                        <div className='row centerY'>
                                            <p>
                                                <b>
                                                    Sello digital del SAT
                                                </b>
                                            </p>
                                            <p className='small'>
                                                {qrSello.selloSat}
                                            </p>
                                        </div>
                                        {
                                            qrSello.cadenaOriginal ?
                                                <div className='row endY'>
                                                    <p>
                                                        <b>
                                                            Cadena original
                                                        </b>
                                                    </p>
                                                    <p className='small'>
                                                        {qrSello.cadenaOriginal}
                                                    </p>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                        }
                        < div >
                            <h5>Origen</h5>
                            <div className='row five xlg-four lg-three sub-sm-two gap-compressed'>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        País
                                    </p>
                                    <p>
                                        {cartaPorte?.ubicacion?.origen?.domicilio?.pais}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Estado
                                    </p>
                                    <p>
                                        {labelEstado(cartaPorte?.ubicacion?.origen?.domicilio?.estado)}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Municipio
                                    </p>
                                    <p>
                                        {cartaPorte?.ubicacion?.origen?.domicilio?.municipio}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Codigo Postal
                                    </p>
                                    <p>
                                        {cartaPorte?.ubicacion?.origen?.domicilio?.codigoPostal}
                                    </p>
                                </div>
                                <div className='labelText'>
                                    <p className='small gray'>
                                        Fecha Salida
                                    </p>
                                    <p className='_withIcon'>
                                        {cartaPorte?.ubicacion?.origen?.fecha ? unifiedMoment(cartaPorte?.ubicacion?.origen?.fecha).format('DD MMM YYYY') : null}
                                        <span className="material-icons">
                                            calendar_today
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5>Destino</h5>
                            <div className='_destino'>
                                <GoggleMapWrapper googleMapsUrl={googleMapsUrl} />
                                <div className='row'>
                                    <div className='row startY three lg-two sub-sm-two gap-compressed'>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                RFC Destino
                                            </p>
                                            <p>
                                                {cartaPorte?.ubicacion?.destino?.rfc || currentUser.fiscalpopProfile.rfc}
                                            </p>
                                        </div>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                País
                                            </p>
                                            <p>
                                                {cartaPorte?.ubicacion?.destino?.domicilio?.pais}
                                            </p>
                                        </div>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Estado
                                            </p>
                                            <p>
                                                {labelEstado(cartaPorte?.ubicacion?.destino?.domicilio?.estado)}
                                            </p>
                                        </div>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Municipio
                                            </p>
                                            <p>
                                                {cartaPorte?.ubicacion?.destino?.domicilio?.municipio}
                                            </p>
                                        </div>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Codigo Postal
                                            </p>
                                            <p>
                                                {cartaPorte?.ubicacion?.destino?.domicilio?.codigoPostal}
                                            </p>
                                        </div>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Fecha Llegada
                                            </p>
                                            <p className='_withIcon'>
                                                {cartaPorte?.ubicacion?.destino?.fecha ? unifiedMoment(cartaPorte?.ubicacion?.destino?.fecha).format('DD MMM YYYY') : null}
                                                <span className="material-icons">
                                                    calendar_today
                                                </span>
                                            </p>
                                        </div>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Distancia Recorrida
                                            </p>
                                            <p>
                                                {cartaPorte?.ubicacion?.destino?.distancia} KM
                                            </p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div>
                            <h5>Autotransporte</h5>
                            <div className='card _autotransporte'>
                                <div>
                                    <p className='gray'>
                                        Vehículo
                                    </p>
                                    <div className='row three sub-sm-two xs-one gap-compressed'>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Tipo de Vehīculo
                                            </p>
                                            <p>
                                                {labelTipoVehiculo(cartaPorte?.autotransporte?.vehiculo?.tipoVehiculo)}
                                            </p>
                                        </div>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Año del Vehīculo
                                            </p>
                                            <p>
                                                {cartaPorte?.autotransporte?.vehiculo?.anoVehiculo}
                                            </p>
                                        </div>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Placas del Vehīculo
                                            </p>
                                            <p>
                                                {cartaPorte?.autotransporte?.vehiculo?.placas}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className='gray'>
                                        Seguro y Permisos
                                    </p>
                                    <div className='row three sub-sm-two xs-one gap-compressed'>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Aseguradora
                                            </p>
                                            <p>
                                                {cartaPorte?.autotransporte?.seguro?.aseguradora}
                                            </p>
                                        </div>
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Póliza
                                            </p>
                                            <p>
                                                {cartaPorte?.autotransporte?.seguro?.poliza}
                                            </p>
                                        </div>
                                        <div className='sub-sm-hidden' />
                                        <div className='labelText'>
                                            <p className='small gray'>
                                                Permiso SCT
                                            </p>
                                            <p>
                                                {labelPermisoSct(cartaPorte?.autotransporte?.permisoSCT)}
                                            </p>
                                        </div>
                                        {
                                            cartaPorte?.autotransporte?.numPermisoSCT && cartaPorte?.autotransporte?.numPermisoSCT !== 'Permiso no contemplado en el catálogo' ?
                                                <div className='labelText'>
                                                    <p className='small gray'>
                                                        Número Permiso SCT
                                                    </p>
                                                    <p>
                                                        {cartaPorte?.autotransporte?.numPermisoSCT}
                                                    </p>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                {
                                    cartaPorte?.autotransporte?.remolques?.length > 0 ?
                                        <div>
                                            <p className='gray'>
                                                Remolques
                                            </p>
                                            {
                                                cartaPorte?.autotransporte?.remolques?.map((remolque, index) => {
                                                    return (
                                                        <div className='row three sub-sm-two xs-one gap-compressed' key={index}>
                                                            <div className='labelText'>
                                                                <p className='small gray'>
                                                                    Tipo de Remolque
                                                                </p>
                                                                <p>
                                                                    {labelTipoRemolque(remolque.tipoRemolque)}
                                                                </p>
                                                            </div>
                                                            <div className='labelText'>
                                                                <p className='small gray'>
                                                                    Placas Remolque
                                                                </p>
                                                                <p>
                                                                    {remolque.placa}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        : null
                                }
                                <div>
                                    <p className='gray'>
                                        Operadores del Transporte
                                    </p>
                                    {
                                        cartaPorte?.operadoresTransporte?.map((operador, index) => {
                                            return (
                                                <div className='operador' key={index}>
                                                    <div className='avatar'>
                                                        <span className="material-icons">
                                                            person
                                                        </span>
                                                    </div>
                                                    <div className='row three sub-sm-two xs-one gap-compressed'>
                                                        <div className='labelText'>
                                                            <p className='small gray'>
                                                                Tipo
                                                            </p>
                                                            <p>
                                                                {labelTipoOperador(operador.tipo)}
                                                            </p>
                                                        </div>
                                                        <div className='labelText'>
                                                            <p className='small gray'>
                                                                RFC
                                                            </p>
                                                            <p>
                                                                {operador.rfc}
                                                            </p>
                                                        </div>
                                                        <div className='labelText'>
                                                            <p className='small gray'>
                                                                Licencia
                                                            </p>
                                                            <p>
                                                                {operador.licencia}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5>Mercancías</h5>
                            <div className='_mercancias'>
                                <div className='mercancia header'>
                                    <p className='_clavesSat'>Clave Producto/Servicio</p>
                                    <p className='_descripcion'>Descripción</p>
                                    <p className='_cantidad'>Cantidad</p>
                                    <p className='_valorUnit'>Valor Unitario</p>
                                    <p className='_pesoKg'>Peso en KG</p>
                                </div>
                                {
                                    cartaPorte?.mercancias?.map((mercancia, index) => {
                                        return (
                                            <div className='mercancia _entry' key={index}>
                                                <div className='_index'>
                                                    <p >{index + 1}.</p>
                                                </div>
                                                <div className='_clavesSat'>
                                                    <p>{mercancia.claveProdServ}</p>
                                                </div>
                                                <p className='_descripcion'>{mercancia.descripcion}</p>
                                                <p className='_cantidad'>{mercancia.cantidad}</p>
                                                <p className='_valorUnit'>{mercancia.valorUnitario}</p>
                                                <p className='_pesoKg'>{mercancia.pesoKg} <span className='lightGray'>KG</span></p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    argumentid ?
                        null
                        :
                        <div className='row two actions sm-one'>
                            <div className='start centerY'>
                                <Button secondary={true} handleClick={backToEditSubmit} >
                                    <span>
                                        Atrás
                                    </span>
                                </Button>
                            </div>
                            <div className='end centerY'>
                                <Button primary={true} handleClick={submitArgument} disabled={stamping}>
                                    {
                                        stamping ?
                                            <span>
                                                Facturando espere...
                                            </span>
                                            :
                                            <span>
                                                Emitir Carta Porte
                                            </span>
                                    }
                                </Button>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}