import React from 'react';

interface CredentialEntryDurationCounterProps {
    color: string;
    days: number;
    nowTs: number;
    credentialTs: number;
}

function CredentialEntryDurationCounter({
    color,
    days,
    nowTs,
    credentialTs
}: CredentialEntryDurationCounterProps) {

    const _timeReference = ((credentialTs + (days * 24 * 60 * 60 * 1000)) - nowTs)
    const timeReference = _timeReference <= 0 ? 0 : _timeReference;
    return (
        <div className='_counterHold'>
            <div className='timer' style={{ color: color }}>
                <span className="material-icons">
                    schedule
                </span>
                <span>
                    {Math.floor(((timeReference / 1000) / 60) / 60)}
                    :
                    {
                        Math.floor((timeReference / 1000) / 60) % 60 < 10 ?
                            `0${Math.floor((timeReference / 1000) / 60) % 60}` :
                            Math.floor((timeReference / 1000) / 60) % 60
                    }
                    :
                    {
                        Math.abs(Math.floor(((timeReference) / 1000) % 60)) < 10 ?
                            `0${Math.abs(Math.floor(((timeReference) / 1000) % 60))}`
                            : Math.abs(Math.floor(((timeReference) / 1000) % 60))
                    }
                </span>
            </div>
        </div>
    )
}

export default CredentialEntryDurationCounter;