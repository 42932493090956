
import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../../../base.model';
import MoneySvg from '../../../visuals/svg/moneyIcon';

import { BankProject } from '../../../../models/Administracion/Bank.Projects'

import { numberToCurrencyString } from '../../../../services/formatting';
import unifiedMoment from '../../../../services/unifiedMoment';
import NumberDisplay from '../../../components/numberDisplay';
import BarLineAndSpreadChart from '../../../Graphs/BarAndLine/BarLineAndSpread.chart';
import { Link } from 'react-router-dom';

import './Projection.business.view.scss';

interface ProjectsProps {
    businessProject: BankProject<'business'>
    history: BaseReact['history']
}

interface BarProp { x: number, y: number, yShadow?: number, }

export function ProjectionBusinessView({
    businessProject,
    history
}: ProjectsProps) {

    const [incomePeriod, setIncomePeriod] = useState(0)
    const [expensePeriod, setExpensePeriod] = useState(0)

    const [expenseBars, setExpenseBars] = useState<BarProp[]>([])
    const [incomeBars, setIncomeBars] = useState<BarProp[]>([])

    const [balanceLine, setBalanceLines] = useState<BarProp[]>([])
    const [balanceLineExpected, setBalanceExpectedLines] = useState<BarProp[]>([])

    useEffect(() => {
        console.log(`<ProjectionGastosView> UE: `, businessProject)
        const expenseTotal = businessProject.expenses.reduce((p, c) => p += c.transaction.reduce((_p, _c) => _p += Math.abs(_c.amount), 0), 0);
        console.log(`<ProjectionGastosView> UE - expenseTotal: `, expenseTotal)

        // Expense bars
        const expensePerMonth = businessProject.expenses.reduce((base, exp) => {
            const dateTsArray = new Array(exp.monthRepetition).fill(0).map((_, i) => unifiedMoment(exp.startsAt).add(i, 'months').valueOf())
            // >> expectedAmount

            dateTsArray.forEach((startTs) => {
                const amountExpected = Math.round((exp.amount / exp.monthRepetition) * 100) / 100;
                const exists = base.find(b => b.x === startTs);
                if (exists) {
                    exists.yShadow += amountExpected;
                    // Exists will map regardless of transactions exist or not, it's based on income's monthRepetition
                } else {
                    base.push({ x: startTs, y: 0, yShadow: amountExpected })
                }
            })

            dateTsArray.forEach((startTs) => {
                const endTs = unifiedMoment(startTs).endOf('month').valueOf()
                const transactions = exp.transaction.filter((move) => move.dt_transaction >= startTs && move.dt_transaction <= endTs)
                const subTotal = transactions.reduce((p, c) => p += Math.abs(c.amount), 0)

                const exists = base.find(b => b.x === startTs);
                if (exists) {
                    exists.y += subTotal;
                    // Exists will map regardless of transactions exist or not, it's based on income's monthRepetition
                } else {
                    base.push({ x: startTs, y: subTotal, yShadow: 0 })
                }
            })
            return base;
        }, [] as BarProp[]).sort((a, b) => a.x - b.x)
        setExpenseBars(expensePerMonth);

        // Income bars
        const incomePerMonth = businessProject.incomes.reduce((base, inc) => {
            const dateTsArray = new Array(inc.monthRepetition).fill(0).map((_, i) => unifiedMoment(inc.startsAt).add(i, 'months').valueOf())
            // >> expectedAmount

            dateTsArray.forEach((startTs) => {
                const monthDiff = unifiedMoment(startTs).diff(unifiedMoment(inc.startsAt), 'months')
                const amountExpected = inc.amount * Math.pow((1 + ((inc.expectedMonthChange?.value || 0) / 100)), monthDiff);
                const exists = base.find(b => b.x === startTs);
                if (exists) {
                    exists.yShadow += amountExpected;
                    // Exists will map regardless of transactions exist or not, it's based on income's monthRepetition
                } else {
                    base.push({ x: startTs, y: 0, yShadow: amountExpected })
                }
            })

            dateTsArray.forEach((startTs) => {
                const endTs = unifiedMoment(startTs).endOf('month').valueOf()
                const transactions = inc.transaction.filter((move) => move.dt_transaction >= startTs && move.dt_transaction <= endTs)
                const subTotal = transactions.reduce((p, c) => p += Math.abs(c.amount), 0)

                const exists = base.find(b => b.x === startTs);
                if (exists) {
                    exists.y += subTotal;
                    // Exists will map regardless of transactions exist or not, it's based on income's monthRepetition
                } else {
                    base.push({ x: startTs, y: subTotal, yShadow: 0 })
                }
            })
            return base;
        }, [] as BarProp[]).sort((a, b) => a.x - b.x)
        setIncomeBars(incomePerMonth);

        // Period numbers
        const periodStartTs = unifiedMoment(expensePerMonth.filter(e => !!e.y).concat(incomePerMonth.filter(i => !!i.y)).reduce((p, c) => p < c.x ? c.x : p, businessProject.createdAt)).startOf('month').valueOf();
        const expenseCase = expensePerMonth.find(ePM => ePM.x === periodStartTs);
        const incomeCase = incomePerMonth.find(iPM => iPM.x === periodStartTs);

        setExpensePeriod(expenseCase?.y || 0)
        setIncomePeriod(incomeCase?.y || 0)

        const entriesAgg = expensePerMonth.map(e => ({ ...e, type: 'expense' })).concat(incomePerMonth.map(i => ({ ...i, type: 'income' })))


        const balanceExpected = entriesAgg.reduce((p, c) => {
            const exists = p.find(e => e.x === c.x);
            if (exists) {
                if (c.type === 'expense') {
                    exists.yShadow -= c.yShadow
                    exists.y -= c.y
                } else {
                    exists.yShadow += c.yShadow
                    exists.y += c.y
                }
            } else {
                p.push({
                    x: c.x,
                    y: c.type === 'expense' ? -c.y : c.y,
                    yShadow: c.type === 'expense' ? -c.yShadow : c.yShadow,
                })
            }
            return p;
        }, [] as { x: number, y: number, yShadow: number }[])
        setBalanceExpectedLines(balanceExpected.map(bE => ({ x: bE.x, y: bE.yShadow })))
        setBalanceLines(balanceExpected.filter(bE => !!bE.y).map(bE => ({ x: bE.x, y: bE.y })))

    }, [businessProject])



    return (
        <div id='ProjectosBusinessView' className='card'>
            <div className='title'>
                <MoneySvg width={26} color={'#AC2E78'} />
                <h4>
                    {businessProject.name}
                </h4>
                <div />
                <p className='lightGray small'>
                    Inicia {unifiedMoment(businessProject.createdAt).format('MMMM DD, YYYY')}
                </p>
                <Link to={`/administration/projection/${businessProject._id}/edit`} className="editLink">
                    <span>Editar Proyecto</span>
                    <span className="material-icons">
                        edit
                    </span>
                </Link>
            </div>
            <div className='row numberSections'>
                <div className='row three start'>
                    <NumberDisplay title='Ingreso del periodo' value={incomePeriod} icon={null} coloring={'green'} />
                    <NumberDisplay title='Gasto del periodo' value={expensePeriod} icon={null} coloring={'red'} />

                </div>
            </div>

            {
                (!expenseBars.length && !incomeBars.length) ?
                    <div className='chartSections'>
                        <div className='row _empty'>
                            <p className='center centerY gray'>
                                No hay movimientos asociados al proyecto todavía <br />
                                <span className='small'>
                                    Haz <Link to="/administration/movimientos">click aquí</Link> para asociar movimientos
                                </span>
                            </p>
                        </div>
                    </div>
                    :
                    <div className='chartSections'>
                        <div className='row'>
                            <BarLineAndSpreadChart
                                barGroups={[{
                                    name: 'Gastos por mes',
                                    color: '#E04E4E',
                                    values: expenseBars
                                }, {
                                    name: 'Ingresos por mes',
                                    color: '#60CB5E',
                                    values: incomeBars
                                }]}
                                lineGroups={[{
                                    name: 'Utilidad esperada',
                                    color: '#ccbbfe',
                                    values: balanceLineExpected
                                }, {
                                    name: 'Utilidad actual',
                                    color: '#AC2E78',
                                    useCircles: true,
                                    values: balanceLine,
                                }]}
                                margin={{ left: 40, top: 20, bottom: 20, right: 40 }}
                                xAxisTicks={'from-data'}
                                xAxisExcludeFromTicks={[]}
                                xAxisFormatting={(d: number) => unifiedMoment(d).format('MMMM')}
                            />
                        </div>
                    </div>
            }
            <div className='row gastosSection'>
                <div className='gastosList'>
                    <div className='gasto header'>
                        <p>Ingreso</p>
                        <p>Cantidad</p>
                        <p >Cambio Mensual</p>
                        <p className='center'>Fecha de Inicio</p>
                        <p className='center'>Número de Meses</p>
                        <p className='center'>Progreso ingreso</p>
                    </div>
                    {
                        businessProject.incomes.map((income, index) => {
                            const percent = (income.expectedMonthChange?.value || 0) / 100;
                            const incomeTotal = Math.round(new Array(income.monthRepetition).fill(0).map((_, i) => i).reduce((p, c) => p += (income.amount * Math.pow(1 + (percent), c)), 0) * 100) / 100

                            const transactionEndTs = unifiedMoment(income.transaction.reduce((p, c) => p < c.dt_transaction ? c.dt_transaction : p, income.startsAt)).startOf('month').valueOf()
                            const incomeAccomulated = income.transaction.filter(t => t.dt_transaction <= transactionEndTs).reduce((p, c) => p += c.amount, 0)

                            const monthsPassed = unifiedMoment(transactionEndTs).diff(unifiedMoment(income.startsAt), 'months')
                            const incomeAccomulatedBase = monthsPassed < 1 ? 0 : Math.round(new Array(monthsPassed).fill(0).map((_, i) => i).reduce((p) => p += income.amount, 0) * 100) / 100

                            let percentAccomulated = 0;
                            if (incomeAccomulated && (incomeAccomulated < incomeAccomulatedBase)) {
                                // More than 1 month of transactions BUT less than expected (negative percent)
                                percentAccomulated = Math.round((1 - (incomeAccomulated / incomeAccomulatedBase)) * 1000) / 10;
                            } else if (incomeAccomulated && (incomeAccomulated > incomeAccomulatedBase)) {
                                // More than 1 month of transactions AND more than expected
                                percentAccomulated = Math.round(Math.pow((incomeAccomulated / incomeAccomulatedBase) - 1, (1 / monthsPassed)) * 1000) / 10
                            }


                            const percentChangeText = income.expectedMonthChange?.value > 0 ? `+ ${income.expectedMonthChange?.value}%` : (income.expectedMonthChange?.value < 0 ? `${income.expectedMonthChange?.value}%` : '')
                            const percentAccomulatedText = percentAccomulated > 0 ? `+ ${percentAccomulated}%` : (percentAccomulated < 0 ? `${percentAccomulated}%` : '')
                            return (
                                <div className='gasto' key={index}>
                                    <p>{income.name}</p>
                                    <div className='row'>
                                        <p>${numberToCurrencyString(incomeTotal, true)} Total</p>
                                        <p className='monthly'>(${numberToCurrencyString(income.amount, true)} / mes)</p>

                                    </div>
                                    <div className='row'>
                                        <p>{percentChangeText} Esperado</p>
                                        {
                                            percentAccomulated ?
                                                <p className={`monthly ${percentAccomulated < income.expectedMonthChange?.value ? 'red' : ''}`}>{percentAccomulatedText} A la fecha</p>
                                                : null
                                        }
                                    </div>
                                    <div className='_date'>
                                        <p className='center'> {unifiedMoment(income.startsAt).format('MMM YYYY')}</p>
                                    </div>
                                    <p className='center'>
                                        {
                                            income.monthRepetition > 1 ? `${income.monthRepetition} Meses`
                                                : 'Evento Único'
                                        }
                                    </p>
                                    <p className={`center`}>

                                    </p>
                                    <div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
            <div className='row gastosSection'>
                <div className='gastosList'>
                    <div className='gasto header'>
                        <p>Gasto</p>
                        <p>Cantidad</p>
                        <p>Presupuesto gasto</p>
                        <p className='center'>Fecha de Inicio</p>
                        <p className='center'>Número de Meses</p>
                        <p className='center'>Porcentaje Gasto</p>
                    </div>
                    {
                        businessProject.expenses.map((expense, i) => {
                            const transactionEndTs = unifiedMoment(expense.transaction.reduce((p, c) => p < c.dt_transaction ? c.dt_transaction : p, expense.startsAt)).add(1, 'month').startOf('month').valueOf()

                            const monthsPassed = unifiedMoment(transactionEndTs).diff(unifiedMoment(expense.startsAt), 'months')
                            const expenseExpected = Math.round(new Array(monthsPassed).fill(0).map((_, i) => i).reduce((p) => p += (expense.amount / expense.monthRepetition), 0) * 100) / 100
                            const expenseAccomulated = expense.transaction.reduce((p, c) => p += Math.abs(c.amount), 0)
                            const percentAccomulated = !expenseAccomulated ? 0 : Math.round((expenseAccomulated / expenseExpected) * 1000) / 10;

                            const expensePercent = Math.round(((expense.transaction.reduce((p, c) => p += Math.abs(c.amount), 0) / expense.amount) * 100) * 100) / 100
                            return (
                                <div className='gasto' key={i}>
                                    <p>{expense.name}</p>
                                    <div className='row'>
                                        <p>${numberToCurrencyString(expense.amount, true)} Total</p>
                                        {
                                            expense.expenseType === 'monthly' ?
                                                <p className='monthly'>(${numberToCurrencyString(expense.amount / expense.monthRepetition, true)} / mes)</p>
                                                : null
                                        }

                                    </div>
                                    <div className='row'>
                                        <p className={`${expensePercent > 100 ? 'red' : ''}`}>
                                            {expensePercent}% Gasto Total
                                        </p>
                                        {
                                            !percentAccomulated ? null :
                                                <p className='monthly'>
                                                    {percentAccomulated}% Acumulado Mensual
                                                </p>
                                        }

                                    </div>
                                    <div className='_date'>
                                        <p className='center'> {unifiedMoment(expense.startsAt).format('MMM YYYY')}</p>
                                    </div>
                                    <p className='center'>
                                        {
                                            expense.monthRepetition > 1 ? `${expense.monthRepetition} Meses`
                                                : 'Evento Único'
                                        }
                                    </p>
                                    <div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}