import { gql } from 'apollo-boost';
import { CORE_CFDI_COMPLETE_FIELDS, COTIZACION_COMPLETE_FIELDS } from './graphql/graphql.cfdi.fragment';


export const graphqlSchema = {
    PROFILE: {
        cicleStats: gql`
        query CicleStats{
            cicleStats{
                totalBilled
                taxDue
            }
        }`,
        me: gql`
        query getMe{
            me {
                professionalProfile {
                    _id
                    companyName
                    cnktId
                    stripeCustomerId
                    authToken
                    linkerId
                    email
                    emailValid
                    createdAt
                    lastActivityAt
                    stripeId
                    planType
                    trialExpires
                    subscriptionLastPaymentStatus
                }
                fiscalpopProfile {
                    rfc
                    regimenFiscal
                    nombre
                    isProduction
                    authToken
                    lugarExpedicion
                    logoUrl
                }
                fiscalpopProfileStatus {
                    authToken
                    modulusMatch
                    cerModulus
                    cerNumber
                    notAfter
                    notBefore
                    keyModulus
                }
                billing {
                    userId
                    lastPayment
                    lastPaymentId
                    balance
                    enabled
                    isClusterType
                    clusterName
                }
                stripeAccount {
                    charges_enabled
                    details_submitted
                    email
                    requirements {
                        currently_due
                        disabled_reason
                        eventually_due
                        past_due
                        pending_verification
                        errors {
                            code
                            reason
                            requirement
                        }
                    }
                    settings {
                        branding {
                            icon
                            logo
                            primary_color
                            secondary_color
                        }
                        card_payments {
                            statement_descriptor_prefix
                        }
                    }
                    capabilities {
                        bancontact_payments
                        card_payments
                        eps_payments
                        giropay_payments
                        ideal_payments
                        oxxo_payments
                        p24_payments
                        sepa_debit_payments
                        sofort_payments
                        transfers
                    }
                }
            }
        }`,
        login: gql`
        mutation Login($login:AccountLogin!) {
            login(login: $login){
                professionalProfile {
                    companyName
                    cnktId
                    authToken
                    linkerId
                    email
                    emailValid
                    createdAt
                    lastActivityAt
                }
                token
            }
        }`,
        changePasswordToken: gql`
        mutation ChangePasswordToken($currentPassword: String!, $password: String!) {
            changePasswordToken(currentPassword: $currentPassword, password: $password)
        }`,
        recoverPassword: gql`
        mutation RecoverPassword($email:String!) {
            recoverPassword(email: $email)
        }`,
        validateToken: gql`
        mutation ValidateToken($token: String!) {
            validateToken(token: $token)
        }`,
        resetPasswordToken: gql`
        mutation ResetPasswordToken($token: String!, $password: String!) {
            resetPasswordToken(token: $token, password: $password){
                professionalProfile {
                    companyName
                    cnktId
                    authToken
                    linkerId
                    email
                    emailValid
                    createdAt
                    lastActivityAt
                }
                token
            }
        }`,
        SETUP: {
            changePlanType: gql`
            mutation ChangePlanType($plan: PlanChangeInput!) {
                changePlanType(plan: $plan){
                    professionalProfile {
                        companyName
                        cnktId
                        authToken
                        linkerId
                        email
                        emailValid
                        createdAt
                        lastActivityAt
                        stripeId
                        planType
                        trialExpires
                        subscriptionLastPaymentStatus
                    }
                    fiscalpopProfile {
                        rfc
                        regimenFiscal
                        nombre
                        isProduction
                        authToken
                        lugarExpedicion
                        logoUrl
                    }
                    fiscalpopProfileStatus {
                        authToken
                        modulusMatch
                        cerModulus
                        cerNumber
                        notAfter
                        notBefore
                        keyModulus
                    }
                    billing {
                        userId
                        lastPayment
                        lastPaymentId
                        balance
                        enabled
                        isClusterType
                        clusterName
                    }
                    stripeAccount {
                        charges_enabled
                        details_submitted
                        email
                        requirements {
                            currently_due
                            disabled_reason
                            eventually_due
                            past_due
                            pending_verification
                            errors {
                                code
                                reason
                                requirement
                            }
                        }
                        settings {
                            branding {
                                icon
                                logo
                                primary_color
                                secondary_color
                            }
                            card_payments {
                                statement_descriptor_prefix
                            }
                        }
                        capabilities {
                            bancontact_payments
                            card_payments
                            eps_payments
                            giropay_payments
                            ideal_payments
                            oxxo_payments
                            p24_payments
                            sepa_debit_payments
                            sofort_payments
                            transfers
                        }
                    }
                }
            }`,
            registerEmisorOnly: gql`
            mutation RegisterEmisorOnly($emisor: EmisorChange!) {
                registerEmisorOnly(emisor: $emisor){
                    professionalProfile {
                        companyName
                        cnktId
                        authToken
                        linkerId
                        email
                        emailValid
                        createdAt
                        lastActivityAt
                    }
                    token
                }
            }`,
            registerAccount: gql`
            mutation RegisterAccount($client: AccountRegistration!) {
                registerAccount(client: $client){
                    professionalProfile {
                        companyName
                        cnktId
                        authToken
                        linkerId
                        email
                        emailValid
                        createdAt
                        lastActivityAt
                    }
                    token
                }
            }`,
            updateEmisor: gql`
            mutation UpdateEmisor($emisor: EmisorChange){
                updateEmisor(emisor: $emisor){
                    nombre
                    rfc
                    regimenFiscal
                    lugarExpedicion
                }
            }`,
            registerStripeClient: gql`
            mutation RegisterStripeClient{
                registerStripeClient
            }`,

        },
        CATALOGOS: {
            getAllTrackers: gql`
            query GetAllTrackers{
                getAllTrackers{
                    _id
                    authToken
                    folio
                    serie
                    receptorRfc
                }
            }`,
            createTracker: gql`
            mutation CreateTracker($tracker: SerieTrackerInput!){
                createTracker(tracker: $tracker){
                    _id
                    authToken
                    folio
                    serie
                    receptorRfc
                }
            }`,
            saveCatalogoReceptor: gql`
            mutation SaveCatalogoReceptor($receptor: ReceptorInput){
                saveCatalogoReceptor(receptor: $receptor){
                    _id
                    rfc
                    name
                    email
                }
            }`,
            updateCatalogoReceptor: gql`
            mutation UpdateCatalogoReceptor($receptor: ReceptorInput){
                updateCatalogoReceptor(receptor: $receptor){
                    _id
                    rfc
                    name
                    email
                }
            }`,
            getClientCatalog: gql`
            query GetClientCatalog{
                getClientCatalog{
                    _id
                    rfc
                    name
                    email
                    zip
                    regimen
                    seriePreference
                }
            }`,
            getClienteByName: gql`
            query GetClienteByName($name: String){
                getClienteByName(name: $name){
                    _id
                    rfc
                    name
                    email
                    zip
                    regimen
                    seriePreference
                }
            }`,
            getClienteByRfc: gql`
            query GetClienteByRfc($rfc: String){
                getClienteByRfc(rfc: $rfc){
                    _id
                    rfc
                    name
                    email
                    zip
                    regimen
                    seriePreference
                }
            }`,
            saveCatalogoConcepto: gql`
            mutation SaveCatalogoConcepto($concepto: ConceptoInput){
                saveCatalogoConcepto(concepto: $concepto){
                    _id
                    claveProdServ
                    claveUnidad
                    descripcion
                    valorUnitario
                    pedimento
                    noIdentificacion
                    impuestos {
                        type
                        tipo
                        retencion
                        tasa
                        cuota
                        exento
                        _uniqueId
                    }
                }
            }`,
            updateCatalogoConcepto: gql`
            mutation UpdateCatalogoConcepto($concepto: ConceptoInput){
                updateCatalogoConcepto(concepto: $concepto){
                    _id
                    claveProdServ
                    claveUnidad
                    descripcion
                    valorUnitario
                    pedimento
                    noIdentificacion
                    impuestos {
                        type
                        tipo
                        retencion
                        tasa
                        cuota
                        exento
                        _uniqueId
                    }
                }
            }`,
            getConceptoCatalog: gql`
            query GetConceptoCatalog{
                getConceptoCatalog{
                    _id
                    claveProdServ
                    claveUnidad
                    descripcion
                    valorUnitario
                    pedimento
                    noIdentificacion
                    impuestos {
                        type
                        tipo
                        retencion
                        tasa
                        cuota
                        exento
                        _uniqueId
                    }
                }
            }`,
            getConceptoByName: gql`
            query GetConceptoByName($name: String){
                getConceptoByName(name: $name){
                    _id
                    claveProdServ
                    claveUnidad
                    descripcion
                    valorUnitario
                    pedimento
                    noIdentificacion
                    impuestos {
                        type
                        tipo
                        retencion
                        tasa
                        cuota
                        exento
                        _uniqueId
                    }
                }
            }`,
        },
        NOTIFICATIONS: {
            getNotifications: gql`
            query GetNotifications($resourceTypes: [String]) {
                getNotifications(resourceTypes: $resourceTypes) {
                    _id
                    profileId
                    label
                    createdAt
                    resourceType
                    level
                    read
                    resourceId
                    endpointUrl
                }
            }`,
            getWebServiceNotification: gql`
            query GetWebServiceNotification {
                getWebServiceNotification {
                    _id
                    accepts
                    label
                    onCredentialExpires
                    onCsdExpires
                    onCotizationPaid
                }
            }
            `,
            modifyWebServiceNotification: gql`
            mutation ModifyWebServiceNotification($config: WebServiceConfig!) {
                modifyWebServiceNotification(config: $config) {
                    _id
                    accepts
                    label
                    onCredentialExpires
                    onCsdExpires
                    onCotizationPaid
                }
            }
            `,
            saveServiceWorkerSubscription: gql`
            mutation SaveServiceWorkerSubscription($subscription: WebServiceSubscription!) {
                saveServiceWorkerSubscription(subscription: $subscription) {
                    _id
                    accepts
                    label
                    onCredentialExpires
                    onCsdExpires
                    onCotizationPaid
                }
            }
            `,
        }
    },
    FISCALPOP: {
        STRIPE: {
            fetchStripeBalance: gql`
            query FetchStripeBalance {
                fetchStripeBalance{
                    balance {
                        available {
                            amount
                            currency
                        }
                        pending{
                            amount
                            currency
                        }
                    }
                    balanceTransactions {
                        id
                        amount
                        available_on
                        created
                        currency
                        description
                        fee
                        fee_details {
                            amount
                            application
                            currency
                            description
                            type
                        }
                        net
                        reporting_category
                        status
                        type
                    }
                }
            }`,
            billingAccountUpdates: gql`
                subscription BillingAccountUpdates {
                    billingAccountUpdates {
                        billingAccountId
                        stripePaymentId
                        dateTs
                        message
                    }
                }`,
        },
        COTIZACION: {
            stampCotizacion: gql`
            mutation StampCotizacion($cotizacionId: String!, $receptor: BillReceptorInput){
                stampCotizacion(cotizacionId: $cotizacionId, receptor: $receptor){
                    _id
                    clientId
                    rfc
                    name
                    email
                    note
                    total
                    expiration
                    translateFee
                    feeTransaction
                    feeFiscalpop
                    discount
                    discountName
                    discountFormat
                    discountExpiration
                    paymentTermsOffered
                    changesRequested
                    clientAccepted
                    vendorAccepted
                    createdAt
                    wasCancelled
                    conceptos {
                        claveProdServ
                        claveUnidad
                        cantidad
                        descripcion
                        valorUnitario
                        descuento
                        pedimento
                        noIdentificacion
                        impuestos {
                            type
                            tipo
                            retencion
                            tasa
                            cuota
                            exento
                            _uniqueId
                        }
                    }
                    paid
                    paidConfirmed
                    paidConfirmUrl
                    paidAt
                    status
                    history {
                        _id
                        createdAt
                        closed
                        whom
                        event
                        title
                        message
                        approved
                        applicableConcepts {
                            title
                            quantity
                        }
                    }
                    uuid
                    clientStamped
                    billArgumentId
                }
            }`,
            cancelCotizacion: gql`
            mutation CancelCotizacion($cotizacionId: String!){
                cancelCotizacion(cotizacionId: $cotizacionId){
                    _id
                    clientId
                    rfc
                    name
                    email
                    note
                    total
                    expiration
                    translateFee
                    feeTransaction
                    feeFiscalpop
                    discount
                    discountName
                    discountFormat
                    discountExpiration
                    paymentTermsOffered
                    changesRequested
                    clientAccepted
                    vendorAccepted
                    createdAt
                    wasCancelled
                    conceptos {
                        claveProdServ
                        claveUnidad
                        cantidad
                        descripcion
                        valorUnitario
                        descuento
                        pedimento
                        noIdentificacion
                        impuestos {
                            type
                            tipo
                            retencion
                            tasa
                            cuota
                            exento
                            _uniqueId
                        }
                    }
                    paid
                    paidConfirmed
                    paidConfirmUrl
                    paidAt
                    status
                    history {
                        _id
                        createdAt
                        closed
                        whom
                        event
                        title
                        message
                        approved
                        applicableConcepts {
                            title
                            quantity
                        }
                    }
                }
            }`,
            approveCotizacionChanges: gql`
            mutation ApproveCotizacionChanges($historyId: String, $approval: Boolean, $message: String){
                approveCotizacionChanges(historyId: $historyId, approval: $approval, message: $message){
                    _id
                    clientId
                    rfc
                    name
                    email
                    note
                    total
                    expiration
                    translateFee
                    feeTransaction
                    feeFiscalpop
                    discount
                    discountName
                    discountFormat
                    discountExpiration
                    paymentTermsOffered
                    changesRequested
                    clientAccepted
                    vendorAccepted
                    createdAt
                    wasCancelled
                    conceptos {
                        claveProdServ
                        claveUnidad
                        cantidad
                        descripcion
                        valorUnitario
                        descuento
                        pedimento
                        noIdentificacion
                        impuestos {
                            type
                            tipo
                            retencion
                            tasa
                            cuota
                            exento
                            _uniqueId
                        }
                    }
                    paid
                    paidConfirmed
                    paidConfirmUrl
                    paidAt
                    status
                    history {
                        _id
                        createdAt
                        closed
                        whom
                        event
                        title
                        message
                        approved
                        applicableConcepts {
                            title
                            quantity
                        }
                    }
                }
            }`,
            requestCotizacionChanges: gql`
            mutation RequestCotizacionChanges($entry: CotizacionHistoryInput){
                requestCotizacionChanges(entry: $entry){
                    _id
                    clientId
                    rfc
                    name
                    email
                    note
                    total
                    expiration
                    translateFee
                    feeTransaction
                    feeFiscalpop
                    discount
                    discountName
                    discountFormat
                    discountExpiration
                    paymentTermsOffered
                    changesRequested
                    clientAccepted
                    vendorAccepted
                    createdAt
                    wasCancelled
                    conceptos {
                        claveProdServ
                        claveUnidad
                        cantidad
                        descripcion
                        valorUnitario
                        descuento
                        pedimento
                        noIdentificacion
                        impuestos {
                            type
                            tipo
                            retencion
                            tasa
                            cuota
                            exento
                            _uniqueId
                        }
                    }
                    paid
                    paidConfirmed
                    paidConfirmUrl
                    paidAt
                    status
                    history {
                        _id
                        createdAt
                        closed
                        whom
                        event
                        title
                        message
                        approved
                        applicableConcepts {
                            title
                            quantity
                        }
                    }
                }
            }`,
            confirmIntent: gql`
            mutation ConfirmIntent($cotizacionId: String!, $intentId: String, $installments:Float){
                confirmIntent(cotizacionId: $cotizacionId, intentId: $intentId, installments: $installments){
                    success
                    intentId
                    needsConfirm
                    plansAreAvailable
                    status
                    plansAvailable {
                        count
                        interval
                        type
                    }
                }
            }
            `,
            setPaymentIntent: gql`
            mutation SetPaymentIntent($cotizacionId: String!, $installments: Float, $paymentMethodId: String){
                setPaymentIntent(cotizacionId: $cotizacionId, installments: $installments, paymentMethodId: $paymentMethodId){
                    success
                    intentId
                    needsConfirm
                    plansAreAvailable
                    status
                    plansAvailable {
                        count
                        interval
                        type
                    }
                }
            }
            `,
            fetchCotizacionesByDate: gql`
            query FetchCotizacionesByDate($start: Float, $end: Float){
                fetchCotizacionesByDate(start: $start, end: $end){
                    _id
                    clientId
                    rfc
                    name
                    email
                    note
                    total
                    expiration
                    translateFee
                    feeTransaction
                    feeFiscalpop
                    discount
                    discountName
                    discountFormat
                    discountExpiration
                    paymentTermsOffered
                    changesRequested
                    clientAccepted
                    vendorAccepted
                    createdAt
                    wasCancelled
                    conceptos {
                        claveProdServ
                        claveUnidad
                        cantidad
                        descripcion
                        valorUnitario
                        descuento
                        pedimento
                        noIdentificacion
                        impuestos {
                            type
                            tipo
                            retencion
                            tasa
                            cuota
                            exento
                            _uniqueId
                        }
                    }
                    paid
                    paidConfirmed
                    paidConfirmUrl
                    paidAt
                    status
                    uuid
                }
            }`,
            fetchCotizaciones: gql`
            query FetchCotizaciones{
                fetchCotizaciones{
                    _id
                    clientId
                    rfc
                    name
                    email
                    note
                    total
                    expiration
                    translateFee
                    feeTransaction
                    feeFiscalpop
                    discount
                    discountName
                    discountFormat
                    discountExpiration
                    paymentTermsOffered
                    changesRequested
                    clientAccepted
                    vendorAccepted
                    createdAt
                    wasCancelled
                    conceptos {
                        claveProdServ
                        claveUnidad
                        cantidad
                        descripcion
                        valorUnitario
                        descuento
                        pedimento
                        noIdentificacion
                        impuestos {
                            type
                            tipo
                            retencion
                            tasa
                            cuota
                            exento
                            _uniqueId
                        }
                    }
                    paid
                    paidConfirmed
                    paidConfirmUrl
                    paidAt
                    status
                    uuid
                }
            }`,
            fetchOpenCotizaciones: gql`
            ${COTIZACION_COMPLETE_FIELDS}
            query FetchOpenCotizaciones{
                fetchOpenCotizaciones{
                    ...CotizacionCompleteFields
                }
            }`,
            fetchCotizacion: gql`
            query FetchCotizacion($cotizacionId: String!){
                fetchCotizacion(cotizacionId: $cotizacionId){
                    cotizacion {
                        _id
                        clientId
                        rfc
                        name
                        email
                        note
                        total
                        expiration
                        translateFee
                        feeTransaction
                        feeFiscalpop
                        discount
                        discountName
                        discountFormat
                        discountExpiration
                        paymentTermsOffered
                        changesRequested
                        clientAccepted
                        vendorAccepted
                        createdAt
                        wasCancelled
                        conceptos {
                            claveProdServ
                            claveUnidad
                            cantidad
                            descripcion
                            valorUnitario
                            descuento
                            pedimento
                            noIdentificacion
                            impuestos {
                                type
                                tipo
                                retencion
                                tasa
                                cuota
                                exento
                                _uniqueId
                            }
                        }
                        paid
                        paidConfirmed
                        paidConfirmUrl
                        paidAt
                        status
                        history {
                            _id
                            createdAt
                            closed
                            whom
                            event
                            title
                            message
                            approved
                            applicableConcepts {
                                title
                                quantity
                            }
                        }
                        uuid
                        clientStamped
                        billArgumentId
                    }
                    fiscalpop {
                        rfc
                        regimenFiscal
                        nombre
                        lugarExpedicion
                        logoUrl
                    }
                    profile {
                        _id
                        token
                        companyName
                        email
                    }
                }
            }`,
            generateCotizacion: gql`
            mutation GenerateCotizacion($cotizacion: CotizacionInput!){
                generateCotizacion(cotizacion: $cotizacion){
                    _id
                    clientId
                    rfc
                    name
                    email
                    note
                    total
                    expiration
                    translateFee
                    feeTransaction
                    feeFiscalpop
                    discount
                    discountName
                    discountFormat
                    discountExpiration
                    paymentTermsOffered
                    clientAccepted
                    vendorAccepted
                    createdAt
                    status
                    conceptos {
                        claveProdServ
                        claveUnidad
                        cantidad
                        descripcion
                        valorUnitario
                        descuento
                        pedimento
                        noIdentificacion
                        impuestos {
                            type
                            tipo
                            retencion
                            tasa
                            cuota
                            exento
                            _uniqueId
                        }
                    }
                }
            }`,
            manuallySetCotizacionAsPaid: gql`
                mutation ManuallySetCotizacionAsPaid($cotizacionId: String!, $paymentMethod: String){
                    manuallySetCotizacionAsPaid(cotizacionId: $cotizacionId, paymentMethod: $paymentMethod){
                        _id
                        clientId
                        rfc
                        name
                        email
                        note
                        total
                        expiration
                        translateFee
                        feeTransaction
                        feeFiscalpop
                        discount
                        discountName
                        discountFormat
                        discountExpiration
                        paymentTermsOffered
                        clientAccepted
                        vendorAccepted
                        createdAt
                        status
                        conceptos {
                            claveProdServ
                            claveUnidad
                            cantidad
                            descripcion
                            valorUnitario
                            descuento
                            pedimento
                            noIdentificacion
                            impuestos {
                                type
                                tipo
                                retencion
                                tasa
                                cuota
                                exento
                                _uniqueId
                            }
                        }
                    }
                }`
        },
        CFDI: {
            setBillToCancel: gql`
            mutation SetBillToCancel($uuid: String, $billArgumentId: String){
                setBillToCancel(uuid: $uuid, billArgumentId: $billArgumentId) {
                    folios {
                        uuid
                        estatus
                    }
                }
            }`,
            getOpenBillArgumentsByRFC: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            query GetOpenBillArgumentsByRFC($rfc: String, $argumentId: String){
                getOpenBillArgumentsByRFC(rfc: $rfc, argumentId: $argumentId){
                    pendingPayment
                    argument {
                        _id
                        authToken
                        multipayType
                        profileId
                        timeDefered
                        multiPayments
                        upfront
                        frequency
                        wasCanceled
                        specialType
                        MasterCFDI {
                            ...MasterCfdiCompleteFields
                        }
                        uuid
                        isClosed
                        createdAt
                        billEvents {
                            _id
                            argumentId
                            authToken
                            isClosed
                            when
                            wasCanceled
                            closedAt
                            type
                            uuid
                            notaUUID
                            paymentNum
                            CFDI {
                                serie
                                folio
                                formaPago
                                metodoPago
                                lugarExpedicion
                                tipoDeComprobante
                                receptor {
                                    customerId
                                    nombre
                                    rfc
                                    usoCFDI
                                    zip
                                    regimen
                                    email
                                    emailCC
                                }
                                conceptos {
                                    claveProdServ
                                    claveUnidad
                                    cantidad
                                    descripcion
                                    valorUnitario
                                    descuento
                                    pedimento
                                    noIdentificacion
                                    impuestos {
                                        type
                                        tipo
                                        retencion
                                        tasa
                                        cuota
                                        exento
                                        _uniqueId
                                    }
                                }
                                cfdiRelacionados {
                                    tipoRelacion
                                    uuids
                                }
                            }
                            NotaCreditoCFDI {
                                serie
                                folio
                                formaPago
                                metodoPago
                                lugarExpedicion
                                tipoDeComprobante
                                receptor {
                                    customerId
                                    nombre
                                    rfc
                                    usoCFDI
                                    zip
                                    regimen
                                    email
                                    emailCC
                                }
                                conceptos {
                                    claveProdServ
                                    claveUnidad
                                    cantidad
                                    descripcion
                                    valorUnitario
                                    descuento
                                    pedimento
                                    noIdentificacion
                                    impuestos {
                                        type
                                        tipo
                                        retencion
                                        tasa
                                        cuota
                                        exento
                                        _uniqueId
                                    }
                                }
                                cfdiRelacionados {
                                    tipoRelacion
                                    uuids
                                }
                            }
                        }
                        paymentEvents {
                            _id
                            argumentId
                            authToken
                            isClosed
                            when
                            wasCanceled
                            closedAt
                            type
                            uuid
                            paymentNum
                            paymentCFDI {
                                lugarExpedicion
                                serie
                                receptor {
                                    customerId
                                    nombre
                                    rfc
                                    usoCFDI
                                    zip
                                    regimen
                                    email
                                    emailCC
                                }
                                pagos {
                                    fecha
                                    formaDePago
                                    moneda
                                    tipoCambio
                                    monto
                                    rfcBanco
                                    numOperacion
                                    bancoOrdenante
                                    cuentaOrdenante
                                    cuentaBeneficiario
                                    facturaRelacionada {
                                        uuid
                                        importePagado
                                        numParcialidad
                                    }
                                }
                            }       
                        }
                    }
                }
            }
            `,
            getAllBillArgumentsByQuery: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            query GetAllBillArgumentsByQuery($start:Float, $end:Float, $uuid: String, $rfc: String){
                getAllBillArgumentsByQuery(start:$start, end:$end, uuid: $uuid, rfc: $rfc){
                    cfdi {
                        created
                        uuid
                        paymentUUIDs
                        authToken
                        status
                        cancelling
                        sandbox
                        email
                        total
                        paymentTotal
                        isPayment
                        rfc
                        nombre
                        serie
                        folio
                    }
                    argument {
                        _id
                        authToken
                        multipayType
                        profileId
                        timeDefered
                        multiPayments
                        upfront
                        frequency
                        wasCanceled
                        specialType
                        MasterCFDI {
                            ...MasterCfdiCompleteFields
                        }
                        uuid
                        rfcTarget
                        isClosed
                        createdAt
                        billEvents {
                            _id
                            argumentId
                            authToken
                            isClosed
                            when
                            wasCanceled
                            closedAt
                            type
                            uuid
                            notaUUID
                            paymentNum
                            CFDI {
                                serie
                                folio
                                formaPago
                                metodoPago
                                lugarExpedicion
                                tipoDeComprobante
                                receptor {
                                    customerId
                                    nombre
                                    rfc
                                    usoCFDI
                                    zip
                                    regimen
                                    email
                                    emailCC
                                }
                                conceptos {
                                    claveProdServ
                                    claveUnidad
                                    cantidad
                                    descripcion
                                    valorUnitario
                                    descuento
                                    pedimento
                                    noIdentificacion
                                    impuestos {
                                        type
                                        tipo
                                        retencion
                                        tasa
                                        cuota
                                        exento
                                        _uniqueId
                                    }
                                }
                                cfdiRelacionados {
                                    tipoRelacion
                                    uuids
                                }
                            }
                            NotaCreditoCFDI {
                                serie
                                folio
                                formaPago
                                metodoPago
                                lugarExpedicion
                                tipoDeComprobante
                                receptor {
                                    customerId
                                    nombre
                                    rfc
                                    usoCFDI
                                    zip
                                    regimen
                                    email
                                    emailCC
                                }
                                conceptos {
                                    claveProdServ
                                    claveUnidad
                                    cantidad
                                    descripcion
                                    valorUnitario
                                    descuento
                                    pedimento
                                    noIdentificacion
                                    impuestos {
                                        type
                                        tipo
                                        retencion
                                        tasa
                                        cuota
                                        exento
                                        _uniqueId
                                    }
                                }
                                cfdiRelacionados {
                                    tipoRelacion
                                    uuids
                                }
                            }
                        }
                        paymentEvents {
                            _id
                            argumentId
                            authToken
                            isClosed
                            when
                            wasCanceled
                            closedAt
                            type
                            uuid
                            paymentNum
                            paymentCFDI {
                                fecha
                                lugarExpedicion
                                serie
                                receptor {
                                    customerId
                                    nombre
                                    rfc
                                    usoCFDI
                                    zip
                                    regimen
                                    email
                                    emailCC
                                }
                                pagos {
                                    fecha
                                    formaDePago
                                    moneda
                                    tipoCambio
                                    monto
                                    rfcBanco
                                    numOperacion
                                    bancoOrdenante
                                    cuentaOrdenante
                                    cuentaBeneficiario
                                    facturaRelacionada {
                                        uuid
                                        importePagado
                                        numParcialidad
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }
            `,
            getAllBillArguments: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            query GetAllBillArguments($start:Float, $end:Float){
                getAllBillArguments(start:$start, end:$end){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    specialType
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    rfcTarget
                    specialType
                    isClosed
                    createdAt
                    billEvents {
                        _id
                        argumentId
                        authToken
                        isClosed
                        when
                        wasCanceled
                        closedAt
                        type
                        uuid
                        notaUUID
                        paymentNum
                        CFDI {
                            serie
                            folio
                            formaPago
                            metodoPago
                            lugarExpedicion
                            tipoDeComprobante
                            receptor {
                                customerId
                                nombre
                                rfc
                                usoCFDI
                                zip
                                regimen
                                email
                                emailCC
                            }
                            conceptos {
                                claveProdServ
                                claveUnidad
                                cantidad
                                descripcion
                                valorUnitario
                                descuento
                                pedimento
                                noIdentificacion
                                impuestos {
                                    type
                                    tipo
                                    retencion
                                    tasa
                                    cuota
                                    exento
                                    _uniqueId
                                }
                            }
                            cfdiRelacionados {
                                tipoRelacion
                                uuids
                            }
                        }
                        NotaCreditoCFDI {
                            serie
                            folio
                            formaPago
                            metodoPago
                            lugarExpedicion
                            tipoDeComprobante
                            receptor {
                                customerId
                                nombre
                                rfc
                                usoCFDI
                                zip
                                regimen
                                email
                                emailCC
                            }
                            conceptos {
                                claveProdServ
                                claveUnidad
                                cantidad
                                descripcion
                                valorUnitario
                                descuento
                                pedimento
                                noIdentificacion
                                impuestos {
                                    type
                                    tipo
                                    retencion
                                    tasa
                                    cuota
                                    exento
                                    _uniqueId
                                }
                            }
                            cfdiRelacionados {
                                tipoRelacion
                                uuids
                            }
                        }
                    }
                    paymentEvents {
                        _id
                        argumentId
                        authToken
                        isClosed
                        when
                        wasCanceled
                        closedAt
                        type
                        uuid
                        paymentNum
                        paymentCFDI {
                            fecha
                            lugarExpedicion
                            serie
                            receptor {
                                customerId
                                nombre
                                rfc
                                usoCFDI
                                zip
                                regimen
                                email
                                emailCC
                            }
                            pagos {
                                fecha
                                formaDePago
                                moneda
                                tipoCambio
                                monto
                                rfcBanco
                                numOperacion
                                bancoOrdenante
                                cuentaOrdenante
                                cuentaBeneficiario
                                facturaRelacionada {
                                    uuid
                                    importePagado
                                    numParcialidad
                                }
                            }
                        }
                        
                    }
                }
            }
            `,
            getBillsByRfc: gql`
            query GetBillsByRfc($rfc: String){
                getBillsByRfc(rfc: $rfc){
                    created
                    uuid
                    paymentUUIDs
                    json
                    xml
                    authToken
                    status
                    cancelling
                    sandbox
                    email
                    total
                    paymentTotal
                    isPayment
                    rfc
                    nombre
                }
            }`,
            getBillEventsByRfc: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            query GetBillEventsByRfc($rfc: String){
                getBillEventsByRfc(rfc: $rfc){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    specialType
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    isClosed
                    createdAt
                    billEvents {
                        _id
                        argumentId
                        authToken
                        isClosed
                        when
                        wasCanceled
                        closedAt
                        type
                        uuid
                        notaUUID
                        paymentNum
                        CFDI {
                            serie
                            folio
                            formaPago
                            metodoPago
                            lugarExpedicion
                            tipoDeComprobante
                            receptor {
                                customerId
                                nombre
                                rfc
                                usoCFDI
                                zip
                                regimen
                                email
                                emailCC
                            }
                            conceptos {
                                claveProdServ
                                claveUnidad
                                cantidad
                                descripcion
                                valorUnitario
                                descuento
                                pedimento
                                noIdentificacion
                                impuestos {
                                    type
                                    tipo
                                    retencion
                                    tasa
                                    cuota
                                    exento
                                    _uniqueId
                                }
                            }
                            cfdiRelacionados {
                                tipoRelacion
                                uuids
                            }
                        }
                        NotaCreditoCFDI {
                            serie
                            folio
                            formaPago
                            metodoPago
                            lugarExpedicion
                            tipoDeComprobante
                            receptor {
                                customerId
                                nombre
                                rfc
                                usoCFDI
                                zip
                                regimen
                                email
                                emailCC
                            }
                            conceptos {
                                claveProdServ
                                claveUnidad
                                cantidad
                                descripcion
                                valorUnitario
                                descuento
                                pedimento
                                noIdentificacion
                                impuestos {
                                    type
                                    tipo
                                    retencion
                                    tasa
                                    cuota
                                    exento
                                    _uniqueId
                                }
                            }
                            cfdiRelacionados {
                                tipoRelacion
                                uuids
                            }
                        }
                    }
                    paymentEvents {
                        _id
                        argumentId
                        authToken
                        isClosed
                        when
                        wasCanceled
                        closedAt
                        type
                        uuid
                        paymentNum
                        paymentCFDI {
                            fecha
                            lugarExpedicion
                            serie
                            receptor {
                                customerId
                                nombre
                                rfc
                                usoCFDI
                                zip
                                regimen
                                email
                                emailCC
                            }
                            pagos {
                                fecha
                                formaDePago
                                moneda
                                tipoCambio
                                monto
                                rfcBanco
                                numOperacion
                                bancoOrdenante
                                cuentaOrdenante
                                cuentaBeneficiario
                                facturaRelacionada {
                                    uuid
                                    importePagado
                                    numParcialidad
                                }
                            }
                        }
                        
                    }
                }
            }
            `,
            getOpenBillArguments: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            query GetOpenBillArguments($start:Float, $end:Float){
                getOpenBillArguments(start:$start, end:$end){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    specialType
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    isClosed
                    createdAt
                    billEvents {
                        _id
                        argumentId
                        authToken
                        isClosed
                        when
                        wasCanceled
                        closedAt
                        type
                        uuid
                        notaUUID
                        paymentNum
                        CFDI {
                            serie
                            folio
                            formaPago
                            metodoPago
                            lugarExpedicion
                            tipoDeComprobante
                            receptor {
                                customerId
                                nombre
                                rfc
                                usoCFDI
                                zip
                                regimen
                                email
                                emailCC
                            }
                            conceptos {
                                claveProdServ
                                claveUnidad
                                cantidad
                                descripcion
                                valorUnitario
                                descuento
                                pedimento
                                noIdentificacion
                                impuestos {
                                    type
                                    tipo
                                    retencion
                                    tasa
                                    cuota
                                    exento
                                    _uniqueId
                                }
                            }
                            cfdiRelacionados {
                                tipoRelacion
                                uuids
                            }
                        }
                        NotaCreditoCFDI {
                            serie
                            folio
                            formaPago
                            metodoPago
                            lugarExpedicion
                            tipoDeComprobante
                            receptor {
                                customerId
                                nombre
                                rfc
                                usoCFDI
                                zip
                                regimen
                                email
                                emailCC
                            }
                            conceptos {
                                claveProdServ
                                claveUnidad
                                cantidad
                                descripcion
                                valorUnitario
                                descuento
                                pedimento
                                noIdentificacion
                                impuestos {
                                    type
                                    tipo
                                    retencion
                                    tasa
                                    cuota
                                    exento
                                    _uniqueId
                                }
                            }
                            cfdiRelacionados {
                                tipoRelacion
                                uuids
                            }
                        }
                    }
                    paymentEvents {
                        _id
                        argumentId
                        authToken
                        isClosed
                        when
                        wasCanceled
                        closedAt
                        type
                        uuid
                        paymentNum
                        paymentCFDI {
                            fecha
                            lugarExpedicion
                            serie
                            receptor {
                                customerId
                                nombre
                                rfc
                                usoCFDI
                                zip
                                regimen
                                email
                                emailCC
                            }
                            pagos {
                                fecha
                                formaDePago
                                moneda
                                tipoCambio
                                monto
                                rfcBanco
                                numOperacion
                                bancoOrdenante
                                cuentaOrdenante
                                cuentaBeneficiario
                                facturaRelacionada {
                                    uuid
                                    importePagado
                                    numParcialidad
                                }
                            }
                        }
                        
                    }
                }
            }
            `,
            restampBillArgument: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            mutation RestampBillArgument($billArgumentToEditId: String, $argument:BillArgumentsInput!, $billEvents: [BillEventInput], $paymentEvents: [BillEventInput]){
                restampBillArgument(billArgumentToEditId: $billArgumentToEditId, argument: $argument, billEvents: $billEvents, paymentEvents: $paymentEvents){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    specialType
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    isClosed
                    createdAt
                }
            }
            `,
            restampBillEvent: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            mutation RestampBillEvent($billEventToEditId: String, $argument:BillArgumentsInput!, $billEvents: [BillEventInput], $paymentEvents: [BillEventInput]){
                restampBillEvent(billEventToEditId: $billEventToEditId, argument: $argument, billEvents: $billEvents, paymentEvents: $paymentEvents){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    specialType
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    isClosed
                    createdAt
                }
            }
            `,
            stampFromBillArgument: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            mutation StampFromBillArgument($argument:BillArgumentsInput!, $billEvents: [BillEventInput], $paymentEvents: [BillEventInput]){
                stampFromBillArgument(argument: $argument, billEvents: $billEvents, paymentEvents: $paymentEvents){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    isClosed
                    createdAt
                }
            }
            `,
            stampFromNotaCredito: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            mutation StampFromNotaCredito($uuidRelated: String!, $cfdi: BillInput!){
                stampFromNotaCredito(uuidRelated: $uuidRelated, cfdi: $cfdi){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    specialType
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    isClosed
                    createdAt
                }
            }
            `,
            restampBillNotaCredito: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            mutation RestampBillNotaCredito($replaceNotaId: String!, $cfdi: BillInput!){
                restampBillNotaCredito(replaceNotaId: $replaceNotaId, cfdi: $cfdi){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    specialType
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    isClosed
                    createdAt
                }
            }
            `,
            stampFromBillEvent: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            mutation StampFromBillEvent($argument:BillArgumentsInput!, $cfdi: [BillEventInput]){
                stampFromBillEvent(argument: $argument, cfdi: $cfdi){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    specialType
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    isClosed
                    createdAt
                }
            }
            `,
            stampFromPaymentEvent: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            mutation StampFromPaymentEvent($arguments:[BillArgumentsInput], $cfdi: PaymentBillInput){
                stampFromPaymentEvent(arguments: $arguments, cfdi: $cfdi){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    specialType
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    isClosed
                    createdAt
                }
            }
            `,
            restampBillPaymentEvent: gql`
            ${CORE_CFDI_COMPLETE_FIELDS}
            mutation RestampBillPaymentEvent($eventid: String!, $arguments:[BillArgumentsInput], $cfdi: PaymentBillInput){
                restampBillPaymentEvent(eventid: $eventid, arguments: $arguments, cfdi: $cfdi){
                    _id
                    authToken
                    multipayType
                    profileId
                    timeDefered
                    multiPayments
                    upfront
                    frequency
                    wasCanceled
                    specialType
                    MasterCFDI {
                        ...MasterCfdiCompleteFields
                    }
                    uuid
                    isClosed
                    createdAt
                }
            }
            `,
            getBillSignatureByUUID: gql`
             query GetBillSignatureByUUID($uuid: String!) {
                getBillSignatureByUUID(uuid: $uuid) {
                    uuid
                    selloEmisor
                    selloSat
                    cadenaOriginal
                    qrUrl
                }
             }
            `,
        },
        CARTAPORTE: {
            getCountries: gql`
                query GetCountries {
                    getCountries {
                        clave
                        pais
                    }
                }
            `,
            getState: gql`
                query GetState {
                    getState {
                        valor
                        clave
                        nombre
                    }
                }
            `,
            getMunicipios: gql`
                query GetMunicipios($estado: String) {
                    getMunicipios(estado: $estado) {
                        municipio
                        municipioName
                        estado
                    }
                }
            `,
            getCodigoPostal: gql`
                query GetCodigoPostal($estado: String, $zip: String) {
                    getCodigoPostal(estado: $estado, zip: $zip) {
                        codigoPostal
                        municipio
                        municipioName
                        localidad
                        estado
                        estimuloFrontera
                    }
                }
            `,
        },

        BANCOS: {
            PROFESSIONAL: {
                getOrganizations: gql`
                query GetOrganizations{
                    getOrganizations {
                        id_country
                        id_site_organization
                        id_site_organization_type
                        name
                        avatar
                        small_cover
                        cover
                        sites {
                            id_site
                            id_site_type
                            is_business
                            is_personal
                            version
                            name
                            endpoint
                            credentials {
                                name
                                type
                                label
                                required
                                username
                                token
                                validation
                                options {
                                    label
                                    value
                                }
                            }
                        }
                    }
                }`,
                getBankCredentials: gql`
                query GetBankCredentials {
                    getBankCredentials {
                        id_credential
                        id_user
                        id_environment
                        id_external
                        id_site
                        id_site_organization
                        id_site_organization_type
                        id_organization
                        is_authorized
                        is_locked
                        is_twofa
                        can_sync
                        ready_in
                        username
                        code
                        keywords
                        dt_authorized
                        dt_refresh
                        dt_execute
                        dt_ready
                        ongoingJob {
                            id_credential
                            id_job_uuid
                            id_job
                            username
                            ws
                            status
                            twofa
                            createdAt
                        }
                    }
                }
                `,
                bankTransactionsUpdates: gql`
                subscription BankTransactionsUpdates {
                    bankTransactionsUpdates {
                        userId
                        id_job
                        id_job_uuid
                        id_site
                        id_credential
                        id_site_organization
                        state
                        message
                    }
                }`,
                bankCredentialUpdates: gql`
                subscription BankCredentialUpdates {
                    bankCredentialUpdates {
                        userId
                        id_job
                        id_job_uuid
                        id_site
                        id_credential
                        id_site_organization
                        status {
                            code
                            address
                            name
                            description
                            end
                            twofa {
                                name
                                type
                                label
                                imgURL
                                imgBase64File
                            }
                        }
                    }
                }`,
                submitTwoFA: gql`
                mutation SubmitTwoFA($jobTwoFaUrl: String, $jobId: String, $tokenString: String) {
                    submitTwoFA(jobTwoFaUrl: $jobTwoFaUrl, jobId: $jobId, tokenString: $tokenString)
                }
                `,
                submitCredential: gql`
                mutation SubmitCredential($data: BankCredentialsInput!) {
                    submitCredential(data: $data) {
                        id_credential
                        id_job_uuid
                        id_job
                        username
                        ws
                        status
                        twofa
                    }
                }
                `,
                submitSyncCredentials: gql`
                mutation SubmitSyncCredentials($credentialId: String!) {
                    submitSyncCredentials(credentialId: $credentialId) {
                        id_credential
                        id_job_uuid
                        id_job
                        username
                        ws
                        status
                        twofa
                    }
                }
                `,
                getBankAccounts: gql`
                query GetBankAccounts($useCache: Boolean) {
                    getBankAccounts(useCache: $useCache) {
                        id_account
                        id_account_type
                        id_currency
                        id_user
                        id_external
                        id_credential
                        id_site
                        id_site_organization
                        id_site_organization_type
                        is_disable
                        name
                        number
                        balance
                        currency
                        account_type
                        extra {
                            owner
                            available
                            points
                            limit
                            minimum
                            no_interest
                            pending_balance
                            principal_balance
                            origination_date
                            loan_term
                            total_number_of_payments
                        }
                        keywords
                        dt_refresh
                        site {
                            id_site
                            id_site_organization
                            id_site_organization_type
                            name
                            organization
                            avatar
                            cover
                            small_cover
                            time_zone
                        }
                    }
                }
                `,
                assignMovementCategory: gql`
                    mutation AssignMovementCategory($category: String, $subcategory: String, $movementIds: [String]){
                        assignMovementCategory(category: $category, subcategory: $subcategory, movementIds: $movementIds)
                    }
                `,
                assignMovementProject: gql`
                    mutation AssignMovementProject($projectId: String, $projectName: String, $projectTrackId: String, $movementIds: [String]){
                        assignMovementProject(projectId: $projectId, projectName: $projectName, projectTrackId: $projectTrackId, movementIds: $movementIds)
                    }
                `,
                getBankMovements: gql`
                    query GetBankMovements($start: Float, $end: Float, $subcategory: String){
                        getBankMovements(start: $start, end: $end, subcategory: $subcategory){
                            _id
                            id_transaction
                            amount
                            currency
                            description
                            dt_refresh
                            dt_transaction
                            id_account
                            id_account_type
                            id_credential
                            id_site
                            id_site_organization
                            id_site_organization_type
                            siteName
                            accountName
                            budgetCategory
                            budgetSubcategory
                            projectId
                            projectName
                            projectTrackId
                            projectTrackName
                            internalPairMovementId
                        }
                    }
                `,
                getBankMovementsSummary: gql`
                    query GetBankMovementsSummary($start: Float, $end: Float, $groupBy: String){
                        getBankMovementsSummary(start: $start, end: $end, groupBy: $groupBy){
                            id_account
                            date
                            expense
                            income
                            internalIncome
                            internalExpense
                            expenseByCategory {
                                budgetCategory
                                budgetSubcategory
                                totalExpense
                                totalBudgetted
                            }
                            incomeByCategory {
                                category
                                subcategory
                                totalIncome
                            }
                            expenseByProject {
                                project
                                projectId
                                projectTrackId
                                totalExpense
                            }
                            incomeByProject {
                                project
                                projectId
                                projectTrackId
                                totalIncome
                            }
                        }
                    }
                `,
                getIncomeActivitiesPrediction: gql`
                    query GetIncomeActivitiesPrediction($monthsAgo: Float) {
                        getIncomeActivitiesPrediction(monthsAgo: $monthsAgo) {
                            projectId
                            subcategory
                            goal
                            current
                            incomes {
                                income
                                monthTs
                            }
                        }
                    }
                `,
                getBudgets: gql`
                    query GetBudgets($monthTs: Float) {
                        getBudgets(monthTs: $monthTs){
                            category
                            subcategory
                            goal
                            current
                        }
                    }
                `,
                getBudgetsByCategory: gql`
                    query GetBudgetsByCategory($category: String!) {
                        getBudgetsByCategory(category: $category) {
                            category
                            subcategory
                            goal
                            current
                        }
                    }
                `,
                getBudgetActivitiesByCategory: gql`
                    query GetBudgetActivitiesByCategory($category: String!) {
                        getBudgetActivitiesByCategory(category: $category) {
                            category
                            subcategory
                            goal
                            current
                            expenses {
                                expense
                                monthTs
                            }
                        }
                    }
                `,
                getBudgetActivitiesPrediction: gql`
                    query GetBudgetActivitiesPrediction($monthsAgo: Float) {
                        getBudgetActivitiesPrediction(monthsAgo: $monthsAgo) {
                            category
                            activity {
                                category
                                subcategory
                                goal
                                current
                                expenses {
                                    expense
                                    monthTs
                                }
                            }
                        }
                    }
                `,
                getBudgetsExpenseReference: gql`
                    query GetBudgetsExpenseReference {
                        getBudgetsExpenseReference {
                            maximum
                            average
                            currentMonth
                            lastMonth
                            windowStart
                            windowEnd
                            goalTarget
                        }
                    }
                `,
                assignBankBudget: gql`
                    mutation AssignBankBudget($goal: Float!, $category: String!, $subcategory: String!) {
                        assignBankBudget(goal: $goal, category: $category, subcategory: $subcategory) {
                            category
                            subcategory
                            goal
                        }
                    }
                `,
                PROJECTIONS: {
                    getProjectionGoalsActive: gql`
                        query GetProjectionGoalsActive {
                            getProjectionGoalsActive {
                                _id
                                name
                                accountBound
                                accountBoundId
                                target
                                goal
                                dueDateTS
                                description
                                probability
                                updatedAt
                                status
                                closed
                            }
                        }
                    `,
                    assignProjectionGoal: gql`
                        mutation AssignProjectionGoal($goal: ProjectionGoalInput) {
                            assignProjectionGoal(goal: $goal) {
                                _id
                                name
                                accountBound
                                accountBoundId
                                target
                                goal
                                dueDateTS
                                description
                                probability
                                updatedAt
                                status
                                closed
                            }
                        }
                    `,
                    updateProjectionGoal: gql`
                        mutation UpdateProjectionGoal($goalId: String, $goal: ProjectionGoalInput) {
                            updateProjectionGoal(goalId: $goalId, goal: $goal) {
                                _id
                                name
                                accountBound
                                accountBoundId
                                target
                                goal
                                dueDateTS
                                description
                                probability
                                updatedAt
                                status
                                closed
                            }
                        }
                    `,
                    createExpenseProject: gql`
                        mutation CreateExpenseProject($project: ProjectionExpenseProjectInput) {
                            createExpenseProject(project: $project) {
                                _id
                                name
                                type
                                createdAt
                                expenses {
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    expenseType
                                    startsAt
                                }
                                incomes {
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    startsAt
                                    expectedMonthChange {
                                        factor
                                        value
                                    }
                                }
                            }
                        }
                    `,
                    updateExpenseProject: gql`
                        mutation UpdateExpenseProject($project: ProjectionExpenseProjectInput) {
                            updateExpenseProject(project: $project) {
                                _id
                                name
                                type
                                createdAt
                                expenses {
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    expenseType
                                    startsAt
                                }
                                incomes {
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    startsAt
                                    expectedMonthChange {
                                        factor
                                        value
                                    }
                                }
                            }
                        }
                    `,
                    createBusinessProject: gql`
                        mutation CreateBusinessProject($project: ProjectionBusinessProjectInput) {
                            createBusinessProject(project: $project) {
                                _id
                                name
                                type
                                createdAt
                                expenses {
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    expenseType
                                    startsAt
                                }
                                incomes {
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    startsAt
                                    expectedMonthChange {
                                        factor
                                        value
                                    }
                                }
                            }
                        }
                    `,
                    updateBusinessProject: gql`
                        mutation UpdateBusinessProject($project: ProjectionBusinessProjectInput) {
                            updateBusinessProject(project: $project) {
                                _id
                                name
                                type
                                createdAt
                                expenses {
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    expenseType
                                    startsAt
                                }
                                incomes {
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    startsAt
                                    expectedMonthChange {
                                        factor
                                        value
                                    }
                                }
                            }
                        }
                    `,
                    getProjectionProjectById: gql`
                        query GetProjectionProjectById($id: String) {
                            getProjectionProjectById(id: $id) {
                                _id
                                name
                                type
                                createdAt
                                expenses {
                                    _id
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    expenseType
                                    startsAt
                                    transaction {
                                        id_transaction
                                        amount
                                        description
                                        dt_transaction
                                    }
                                }
                                incomes {
                                    _id
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    startsAt
                                    expectedMonthChange {
                                        factor
                                        value
                                    }
                                    transaction {
                                        id_transaction
                                        amount
                                        description
                                        dt_transaction
                                    }
                                }
                            }
                        }
                    `,
                    getProjectionProjectsTransactions: gql`
                        query GetProjectionProjects {
                            getProjectionProjects {
                                _id
                                name
                                type
                                createdAt
                                expenses {
                                    _id
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    expenseType
                                    startsAt
                                    transaction {
                                        id_transaction
                                        amount
                                        description
                                        dt_transaction
                                    }
                                }
                                incomes {
                                    _id
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    startsAt
                                    expectedMonthChange {
                                        factor
                                        value
                                    }
                                    transaction {
                                        id_transaction
                                        amount
                                        description
                                        dt_transaction
                                    }
                                }
                            }
                        }
                    `,
                    getProjectionProjects: gql`
                        query GetProjectionProjects {
                            getProjectionProjects {
                                _id
                                name
                                type
                                createdAt
                                expenses {
                                    _id
                                    name
                                    amount
                                    current
                                    expenseType
                                    monthRepetition
                                    startsAt
                                }
                                incomes {
                                    _id
                                    name
                                    amount
                                    current
                                    monthRepetition
                                    startsAt
                                    expectedMonthChange {
                                        factor
                                        value
                                    }
                                }
                            }
                        }
                    `,
                    getProjectionStatusSummary: gql`
                        query GetProjectionStatusSummary {
                            getProjectionStatusSummary {
                                dateTs
                                elementType
                                elementId
                                elementName
                                description
                            }
                        }
                    `,
                }
            },
            getBancos: gql`
                query GetBancos{
                    getBancos{
                        clave
                        rfc
                        nombre
                        alias
                    }
                }
            `
        },
        REGIMEN: {
            getRegimenFiscal: gql`
                query GetRegimenFiscal{
                    getRegimenFiscal{
                        clave
                        descripcion
                    }
                }
            `
        },
        USO_CFDI: {
            getUsoCfdi: gql`
                query GetUsoCfdi{
                    getUsoCfdi{
                        clave
                        descripcion
                        forRegimen
                    }
                }
            `,
            getUsoCfdiTokenized: gql`
                query GetUsoCfdiTokenized($token: String!){
                    getUsoCfdiTokenized(token: $token){
                        clave
                        descripcion
                    }
                }
            `
        },
        FORMA_PAGO: {
            getFormaPago: gql`
                query GetFormaPago{
                    getFormaPago{
                        key
                        label
                    }
                }
            `
        },
        IMPUESTOS: {
            getImpuestos: gql`
                query GetImpuestos{
                    getImpuestos{
                        tipo
                        min
                        max
                        impuesto
                        factor
                        traslado
                        retencion
                        exento
                    }
                }
            `
        },
        SETUP: {
            setFiscalpopClient: gql`
            mutation SetFiscalpopClient($client: FiscalpopClient!){
                setFiscalpopClient(client: $client){
                    rfc
                    regimenFiscal
                    nombre
                    lugarExpedicion
                }
            }`
        },
        SUPPORT: {
            sendSupportEmail: gql`
            mutation SendSupportEmail($support: FiscalpopSupport!){
                sendSupportEmail(support: $support) {
                    success
                }
            }`
        }
    },
    STRIPE: {
        USAGE: {
            getSubscriptionUsage: gql`
            query GetSubscriptionUsage{
                getSubscriptionUsage {
                    item
                    usage
                    period {
                        start
                    }
                }
            }
            `,
            getSubscriptionInvoice: gql`
            query GetSubscriptionInvoice {
                getSubscriptionInvoice {
                    invoiceId
                    paymentIntentId
                    amount
                    createdAt
                    effectiveAt
                    periodStart
                    periodEnd
                    paid
                    status
                    subscriptionStatus
                    invoicePdf
                    hostedInvoiceUrl
                }
            }
            `
        },
        ORDERS: {
            getPaymentOrders: gql`
            query GetPaymentOrders {
                getPaymentOrders {
                    _id
                    objectType
                    method
                    chargeId
                    paymentIntentId
                    createdAt
                    paidAt
                    amount
                    paid
                    fee
                    paymentIntentStatus
                    chargeStatus
                    receiptUrl
                    refunded
                    spei {
                        amountRemaining
                        instructionsUrl
                        bank
                        clabe
                        reference
                    }
                    invoiceUUID
                }
            }`
        },
        SPEI: {
            requestSpeiPayment: gql`
            mutation RequestSpeiPayment($amount: Float!) {
                requestSpeiPayment(amount: $amount){
                    _id
                    objectType
                    method
                    chargeId
                    paymentIntentId
                    createdAt
                    paidAt
                    amount
                    paid
                    fee
                    paymentIntentStatus
                    chargeStatus
                    receiptUrl
                    refunded
                    spei {
                        amountRemaining
                        instructionsUrl
                        bank
                        clabe
                        reference
                    }
                }
            }`
        },
        CARDS: {
            getCardSetupIntent: gql`
                query GetCardSetupIntent {
                    getCardSetupIntent{
                        client_secret
                        status
                    }
                }`,
            retrieveSetupIntent: gql`
                query retrieveSetupIntent($clientSecret: String!) {
                    retrieveSetupIntent(clientSecret: $clientSecret){
                        client_secret
                        status
                    }
                }`,
            confirmRequiredActionIntent: gql`
            mutation ConfirmRequiredActionIntent($intentId: String) {
                confirmRequiredActionIntent(intentId: $intentId) {
                    order {
                        _id
                        objectType
                        method
                        chargeId
                        paymentIntentId
                        createdAt
                        paidAt
                        amount
                        paid
                        fee
                        paymentIntentStatus
                        chargeStatus
                        receiptUrl
                        refunded
                    }
                    intent {
                        client_secret
                        status
                    }
                }
            }`,
            requestCardPayment: gql`
            mutation RequestCardPayment($amount: Float!, $sourceid: String!, $funding: String) {
                requestCardPayment(amount: $amount, sourceid: $sourceid, funding: $funding) {
                    order {
                        _id
                        objectType
                        method
                        chargeId
                        paymentIntentId
                        createdAt
                        paidAt
                        amount
                        paid
                        fee
                        paymentIntentStatus
                        chargeStatus
                        receiptUrl
                        refunded
                    }
                    intent {
                        client_secret
                        status
                    }
                }
            }`,
            getCards: gql`
                query GetCards {
                    getCards{
                        id
                        object
                        brand
                        cvc_check
                        exp_month
                        exp_year
                        funding
                        last4
                        name
                        default
                        hasFailed
                    }
                }`,
            getCardsEnforceDefault: gql`
                query GetCardsEnforceDefault {
                    getCardsEnforceDefault{
                        id
                        object
                        brand
                        cvc_check
                        exp_month
                        exp_year
                        funding
                        last4
                        name
                        default
                        hasFailed
                    }
                }`,
            removeCard: gql`
                mutation RemoveCard($paymentId: String!){
                    removeCard(paymentId: $paymentId){
                        id
                        object
                        deleted
                    }
                }`,
            updateDefaultPayment: gql`
                mutation UpdateDefaultPayment($paymentId: String!){
                    updateDefaultPayment(paymentId: $paymentId)
                }`,
        }
    }
}