import React from 'react';
import { PagoRequest } from '../../models/Pago';
import { numeroALetras } from '../../utils/numeroLetra';
import { formatCurrencyStyled } from '../visuals/currency.formatted';

function PagoTotales({ cfdi }: { cfdi: PagoRequest }) {

    const toLetter = (value: number) => {
        return numeroALetras(value, {
            plural: 'pesos mexicanos',
            singular: 'pesos mexicanos',
            centPlural: 'centavos',
            centSingular: 'centavo'
        }).toLowerCase();
    }

    const total = cfdi.pagos.reduce((p, c) => p += c.monto, 0);

    return (
        <div className="card totales">
            <div className="letter">
                <p>
                    <b> Total el letra:</b> {toLetter(total)}
                </p>
            </div>
            <div className="totalIs">
                <p>{formatCurrencyStyled(total)}</p>
                <p className="total">Total </p>
            </div>
        </div>
    )
}

export default PagoTotales;