import React, { useEffect, useState } from 'react';
import { CFDI, ToBillArguments, ToBillEvent } from '../../models/Factura';

import moment from 'moment';
import { formatCurrencyStyled } from '../visuals/currency.formatted';
import ItemMenu from '../Forms/menu/MenuComponent';
import { BaseReact } from '../../base.model';


interface EntryProps {
    authToken: string;
    billArgument: ToBillArguments<ToBillEvent>;
    CFDI: CFDI;
    isHidden: boolean;
    isCancelling: string; // UUID
    history: BaseReact['history'];
    setUnsetTransform: (bool: boolean) => void;
    onAskToCancel: (argumentId: string, uuid: string) => () => void;
    onAskToEdit: (argumentId: string, uuid: string) => () => void;
    onShareOpen: (url: string) => () => void;
    onAskRelationship: (billArguments: ToBillArguments<ToBillEvent>, CFDI: CFDI) => () => void
}

function MisFacturasEntry({ setUnsetTransform, isCancelling, onAskToCancel, onAskToEdit, onShareOpen, history, billArgument, CFDI, authToken, isHidden, onAskRelationship }: EntryProps) {
    const [argument, setArgument] = useState<{ cfdi: CFDI, argument: ToBillArguments<ToBillEvent> }>(null)

    useEffect(() => {
        /*
        const argumentParent = billArguments.find(bA => {
            if (bA.uuid && bA.uuid === CFDI.uuid) {
                return true
            }
            else {
                const baseUUIDS = bA.paymentEvents.concat(bA.billEvents).map(bA => bA.uuid).filter(s => !!s).includes(CFDI.uuid);
                const notaCredUUIDS = bA.billEvents.map(bA => bA.notaUUID).filter(s => !!s).includes(CFDI.uuid);
                return baseUUIDS || notaCredUUIDS;
            }
        });
        */
        setArgument({ cfdi: CFDI, argument: billArgument });

    }, [CFDI, billArgument]);

    const cartaPortePreview = () => {
        const isCartaPorte = billArgument.specialType === 'cartaporte';
        if (!isCartaPorte) {
            return;
        }
        const pathName = `/facturacion/cartaporte/preview/${billArgument._id}`;
        history.push({
            pathname: pathName,
            state: { billArgument: billArgument }
        })
    }

    // >> TODO: Shareable web url, for now sharing PDF download link instead
    // const shareCartaPorte = billArgument ? onShareOpen(`https://personal.fiscalpop.com/views/cartaporte/${billArgument._id}`) : null
    const shareCartaPorte = billArgument ? onShareOpen(`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${authToken}?uuid=${CFDI.uuid}`) : null

    const typeOfBill = (billArgument: ToBillArguments<ToBillEvent>) => {
        const isCartaPorte = billArgument.specialType === 'cartaporte';
        const associatedEvent = billArgument.paymentEvents.concat(billArgument.billEvents).find(bE => bE.uuid === CFDI.uuid)
        const associatedNotaEvent = billArgument.billEvents.find(bE => bE.notaUUID === CFDI.uuid);

        switch (billArgument.multipayType) {
            case 0:
                // This is a Carta Porte bill
                if (isCartaPorte) {
                    // const isTraslado = billArgument.MasterCFDI?.tipoDeComprobante === 'T';
                    return (
                        <div className="_type">
                            <p className="_0">Carta porte</p>
                            <span></span>
                        </div>
                    )
                }
                // This is a Simple bill
                if (associatedNotaEvent) {
                    return (
                        <div className="_type">
                            <p className="_0">Factura</p>
                            <p onClick={onAskRelationship(billArgument, CFDI)} className="_what">Nota credito</p>
                        </div>
                    )
                }
                else if (associatedEvent) {
                    return (
                        <div className="_type">
                            <p className="_0">Factura</p>
                            <p onClick={onAskRelationship(billArgument, CFDI)} className="_what">Pago diferido</p>
                        </div>
                    )
                }
                else if (billArgument.paymentEvents?.length) {
                    return (
                        <div className="_type">
                            <p className="_0">Factura</p>
                            <p onClick={onAskRelationship(billArgument, CFDI)} className="_what">Total a pagar</p>
                        </div>
                    )
                }
                return (
                    <div className="_type">
                        <p className="_0">Factura</p>
                        <span></span>
                    </div>
                )
            case 1:
                // This is a Normal bill with Upfront

                let leyend = '';
                if (!associatedEvent && !associatedNotaEvent) {
                    leyend = 'Adelanto';
                }
                else if (associatedEvent) {
                    leyend = 'Liquidación';
                }
                else if (associatedNotaEvent) {
                    leyend = 'Nota credito';
                }

                return (
                    <div className="_type">
                        <p className="_1">Factura con adelanto</p>
                        <p onClick={onAskRelationship(billArgument, CFDI)} className="_what">{leyend}</p>
                    </div>
                )
            case 2:
                // This is a defered payment bill
                return (
                    <div className="_type">
                        <p className="_2">Pago parcialidades</p>
                        <p onClick={onAskRelationship(billArgument, CFDI)} className="_what">{!associatedEvent ? 'Factura del total a pagar' : `Parcialidad ${associatedEvent.paymentNum} de ${billArgument.paymentEvents.length}`}</p>
                    </div>
                )
            case 3:
                // This is a deferred payment bill with upfront
                return (
                    <div className="_type">
                        <p className="_3">Parcialidades con adelanto</p>
                        <p onClick={onAskRelationship(billArgument, CFDI)} className="_what">{!associatedEvent ? 'Factura del total a pagar' : !associatedEvent.paymentNum ? `Adelanto` : (`Parcialidad ${associatedEvent.paymentNum + 1} de ${billArgument.paymentEvents.length}`)}</p>
                    </div>
                )

        }
    }

    if (!argument || (!!argument && !argument.argument)) {
        return <div></div>;
    }


    const isCartaPorte = billArgument?.specialType === 'cartaporte';
    const isNotCancellable = argument.argument.paymentEvents.some(pE => (pE.uuid && !pE.wasCanceled)) && !CFDI.isPayment;
    const thisISCancelling = isCancelling === CFDI.uuid;
    // Closed PPD cannot be editted anymore (It's complicated logic with no current bennefit December 29th, 2020)
    const editingIsDisabled = argument.argument.multipayType === 2 && argument.argument.isClosed;
    const hideClass = isHidden ? 'clearOut' : '';
    return (
        <div className={`_argumentEntry ${hideClass}`}>
            {typeOfBill(argument.argument)}
            <div className="_client">
                <p className="_name">{CFDI.nombre}</p>
                <p className="_rfc"> {CFDI.rfc}</p>
            </div>
            <div className="_serie">
                <p className="_uuid">{CFDI.uuid}</p>
                <p className="_folio"><b>Serie:</b> {CFDI.serie} / <b>Folio:</b> {CFDI.folio}</p>
            </div>
            <div className="_fecha">
                <p className="_fecha">{moment(CFDI.created).format('DD  MMM  YYYY')}</p>
                <p className={`_state ${thisISCancelling ? '_cancelling' : (CFDI.status ? '' : '_canceled')}`}> {thisISCancelling ? 'Cancelando' : (CFDI.status ? 'Emitida' : 'Cancelada')}</p>
            </div>
            <div className="_totals">
                <p className="_totals">{CFDI.isPayment ? formatCurrencyStyled(parseFloat(CFDI.paymentTotal)) : formatCurrencyStyled(parseFloat(CFDI.total))}</p>
            </div>
            {
                // Allow editing bills when time's available
                true ?
                    <button className="svg yellow" title="Editar factura emitida" onClick={onAskToEdit(argument.argument._id, argument.cfdi.uuid)} disabled={editingIsDisabled}>
                        <i className="material-icons">
                            create
                        </i>
                    </button>
                    : <div />
            }
            {
                isCartaPorte ?
                    <button className="svg violet" title="Ver Carta Porte" onClick={cartaPortePreview}>
                        <i className="material-icons">
                            visibility
                        </i>
                    </button>
                    :
                    <a className="svg violet" title="Descargar PDF" rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${authToken}?uuid=${CFDI.uuid}`} download={true}>
                        <i className="material-icons">
                            picture_as_pdf
                        </i>
                    </a>

            }
            {
                isCartaPorte ?
                    <button className="svg violet" title="Compartir Carta Porte" onClick={shareCartaPorte}>
                        <i className="material-icons">
                            share
                        </i>
                    </button>
                    :
                    <a className="svg violet" title="Descargar XML" rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/xml/${authToken}?uuid=${CFDI.uuid}`} download={true}>
                        <i className="material-icons">
                            code
                        </i>
                    </a>
            }
            {
                CFDI.status ?
                    <button className="svg red" title="Cancelar factura" onClick={onAskToCancel(argument.argument._id, argument.cfdi.uuid)} disabled={isNotCancellable || !!isCancelling}>
                        <i className="material-icons">
                            delete
                        </i>
                    </button>
                    : <div className='svg' />
            }
            <ItemMenu triggerContent={
                <button className="svg violet" title="Opciónes" disabled={isNotCancellable || !!isCancelling}>
                    <i className="material-icons">
                        more_vert
                    </i>
                </button>
            } noPadding={true} overlayRootElement={'MisFacturasBody'} onOpenChange={setUnsetTransform}>
                {
                    isNotCancellable || !!isCancelling ?
                        <div className="_option _disabled">
                            <i className="material-icons">
                                delete
                            </i>
                            <span>
                                Cancelando...
                            </span>
                        </div>
                        :
                        <div className="_option red" onClick={onAskToCancel(argument.argument._id, argument.cfdi.uuid)}>
                            <i className="material-icons">
                                delete
                            </i>
                            <span>
                                Cancelar factura
                            </span>
                        </div>
                }
                {
                    isCartaPorte ?
                        <div className="_option" onClick={shareCartaPorte} >
                            <i className="material-icons">
                                share
                            </i>
                            <span>
                                Compartir
                            </span>
                        </div>

                        :
                        <a title="Descargar XML" rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/xml/${authToken}?uuid=${CFDI.uuid}`} download={true}>
                            <div className="_option">
                                <i className="material-icons">
                                    code
                                </i>
                                <span>
                                    Descargar XML
                                </span>
                            </div>
                        </a>
                }
                {
                    isCartaPorte ?
                        <div className="_option" onClick={cartaPortePreview} >
                            <i className="material-icons">
                                visibility
                            </i>
                            <span>
                                Ver Carta Porte
                            </span>
                        </div>
                        :
                        <a title="Descargar PDF" rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${authToken}?uuid=${CFDI.uuid}`} download={true}>
                            <div className="_option">
                                <i className="material-icons">
                                    picture_as_pdf
                                </i>
                                <span>
                                    Descargar PDF
                                </span>
                            </div>
                        </a>
                }
                {
                    editingIsDisabled ?
                        <div className="_option _disabled">
                            <i className="material-icons">
                                delete
                            </i>
                            <span>
                                No se puede editar
                            </span>
                        </div>

                        :
                        <div className="_option" onClick={onAskToEdit(argument.argument._id, argument.cfdi.uuid)} >
                            <i className="material-icons">
                                create
                            </i>
                            <span>
                                Editar factura
                            </span>
                        </div>
                }
            </ItemMenu>
        </div>
    )
}

export default MisFacturasEntry;