import React, { ReactElement, useEffect, useState } from 'react';
import { BillConceptos, BillConceptTax, FiscalpopImpuesto } from '../../models/Factura';
import AutoComplete from '../Forms/Autocomplete';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { post } from '../../services/post';
import { ConceptoCatalogo } from '../../models/Catalogos';
import { clearGqlTypename } from '../../utils/formatting';
import Pagination from '../Forms/Pagination';
import { ConceptoElementImpuesto } from './Factura.conceptos.impuesto';
import { SPLIT_IMPUESTO_UNIQUE_ID, getImpuestoUniqueId } from '../../utils/impuestos.formatting';


interface FacturarConceptoProps {
    conceptos: BillConceptos[];
    validRequested: boolean;
    authToken: string;
    isGlobalBill?: boolean;
    // Emitters
    onCfdiChange: (value: BillConceptos[]) => void;
    onValidationTest?: (valid: boolean) => void

}

const EMPTY_CONCEPTO = (isGlobalBill = false): BillConceptos => (JSON.parse(JSON.stringify({
    claveProdServ: isGlobalBill ? '01010101' : '',
    claveUnidad: isGlobalBill ? 'ACT' : '',
    cantidad: 1,
    descripcion: isGlobalBill ? 'Venta' : '',
    valorUnitario: 0,
    descuento: 0,
    noIdentificacion: '',
    impuestos: [{ 
        type: 'iva', 
        tipo: 'Fijo', 
        retencion: false, 
        tasa: 0.16,
        _uniqueId: 'IVA-TRAS-FIJO-TASA-0.16'
     }]
})))

const ITEMS_PER_PAGE = 10;

function FacturarConceptos({
    conceptos,
    onCfdiChange,
    validRequested,
    authToken,
    isGlobalBill,
    onValidationTest }: FacturarConceptoProps) {

    const [impuestosList, setImpuestosList] = useState([]);

    // Validation check (Sets do not need to be re-constructed)
    const [validUnidades] = useState<Set<string>>(new Set());
    const [validClaves] = useState<Set<string>>(new Set());

    // Pagination
    const [page, setPage] = useState(1);

    useQuery(graphqlSchema.FISCALPOP.IMPUESTOS.getImpuestos, {
        onCompleted: ({ getImpuestos }: { getImpuestos: FiscalpopImpuesto[] }) => {
            // Some taxes have traslado and retention at the same time, we need to split them
            const splitImpuestos = SPLIT_IMPUESTO_UNIQUE_ID(getImpuestos);
            console.log('Get Impuestos: ', splitImpuestos);
            setImpuestosList(splitImpuestos);
        },
        onError: (e) => {
            console.error('Error loading impuestos: ', e);
        },
        fetchPolicy: 'cache-first'
    })



    const updateConceptoElement = (index: number) => (update: BillConceptos) => {
        const conceptosCommon = conceptos;
        conceptosCommon[index] = update;
        onCfdiChange(conceptosCommon);
    }

    const reportValidation = () => {
        const allValid = conceptos.every((concepto, i) => {
            const validUnidad = validUnidades.has(concepto.claveUnidad);
            const validClave = validClaves.has(concepto.claveProdServ);
            return validUnidad && validClave;
        })
        console.group('Validation Report');
        console.log('All valid: ', allValid);
        console.log('All valid UNIDAD', ...validUnidades.values())
        console.log('All valid Prod', ...validClaves.values())
        console.groupEnd();
        if (onValidationTest) {
            onValidationTest(allValid);
        }
    }

    const onConceptoValidationTest = (validUnidad: string, validClave: string) => {
        if (validUnidad) {
            validUnidades.add(validUnidad);
        }
        if (validClave) {
            validClaves.add(validClave);
        }
        reportValidation();
    }

    const addNewConcepto = () => {
        const conceptosCommon = conceptos;
        conceptosCommon.push(EMPTY_CONCEPTO(isGlobalBill));
        onCfdiChange(conceptosCommon);
        setPage(Math.ceil(conceptosCommon.length / ITEMS_PER_PAGE))
        reportValidation();
    }

    const removeConcepto = (index: number) => () => {
        const conceptosCommon = conceptos;
        conceptosCommon.splice(index, 1);
        onCfdiChange(conceptosCommon);
        setPage(Math.ceil(conceptosCommon.length / ITEMS_PER_PAGE))
        reportValidation();
    }

    return (
        <div>
            <h4>Conceptos</h4>
            <div className="card table">
                <div className="cardBody">
                    {
                        conceptos
                            .map((_, i) => ({ i, concepto: _ }))
                            .filter(({ i }) => (i >= (ITEMS_PER_PAGE * (page - 1))) && (i <= (ITEMS_PER_PAGE * page)))
                            .map(({ concepto, i }) =>
                            //conceptos.filter((_, i) => (i >= (ITEMS_PER_PAGE * (page - 1))) && (i < (ITEMS_PER_PAGE * page))).map((concepto, i) =>
                            (
                                <ConceptoElement
                                    key={i}
                                    isGlobalBill={!!isGlobalBill}
                                    concepto={concepto}
                                    conceptoIndex={i}
                                    authToken={authToken}
                                    removeConcepto={removeConcepto}
                                    validRequested={validRequested}
                                    impuestosList={impuestosList}
                                    handleConceptoUpdate={updateConceptoElement}
                                    onValidationTest={onConceptoValidationTest} />
                            )
                            )
                    }
                    <Pagination page={page} pages={Math.ceil(conceptos.length / ITEMS_PER_PAGE)} onPage={setPage} />
                    <div className="row">
                        <Button tertiary={true} handleClick={addNewConcepto}>
                            <span>Agregar otro concepto</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

// ****** Single Element Entry ********

interface ConceptoElement {
    authToken: string;
    validRequested: boolean;
    conceptoIndex: number,
    concepto: BillConceptos,
    impuestosList: FiscalpopImpuesto[],
    removeConcepto?: (i: number) => () => void,
    handleConceptoUpdate: (i: number) => (update: BillConceptos) => void,
    hideCantidad?: boolean,
    descripcionAsInput?: boolean,
    isGlobalBill?: boolean
    showNoIdentificacion?: boolean,
    onValidationTest?: (validUnidad: string, validClave: string) => void;
}

export function ConceptoElement({
    authToken,
    removeConcepto,
    validRequested,
    conceptoIndex,
    concepto,
    impuestosList,
    handleConceptoUpdate,
    hideCantidad,
    descripcionAsInput,
    isGlobalBill,
    showNoIdentificacion,
    onValidationTest }: ConceptoElement) {
    const [isLoading, setIsLoading] = useState(false);
    const [prodServSubject] = useState(new Subject<string>());
    const [unidadSubject] = useState(new Subject<string>());
    const [unidadOptions, setUnidadOptions] = useState<{ label: string; value: string; template?: ReactElement }[]>([])
    const [claveOptions, setClaveOptions] = useState<{ label: string; value: string; template?: ReactElement }[]>([])

    const [conceptoOptions, setConceptoOptions] = useState<ConceptoCatalogo[]>([]);
    const [searchByName] = useState(new Subject<string>());

    // Validation states for Unidad & Producto (Users have stored "PIEZA" instead of "H87", which is not validated on Adelanto type bills)
    const [unidadValid, setUnidadValid] = useState<string>(null);
    const [claveValid, setClaveValid] = useState<string>(null);

    const [getByName] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getConceptoByName, {
        onCompleted: ({ getConceptoByName }: { getConceptoByName: ConceptoCatalogo[] }) => {
            setConceptoOptions(getConceptoByName);
            // console.log('getConceptoByName: ', getConceptoByName);
        },
        onError: (e) => {
            console.error('Error fetching conceptos: ', e)
        }
    })

    useEffect(() => {
        const subs = searchByName.pipe(debounceTime(200)).subscribe(name => {
            getByName({
                variables: {
                    name
                }
            })
        })
        return () => {
            subs.unsubscribe();
        }
    }, [searchByName, getByName])

    useEffect(() => {
        const fetchUnidad = (defaultUnidadSat: string) => {
            setIsLoading(true);
            return post(`https://api.fiscalpop.com/api/v1/sat/claveUnidades/${authToken}`, {
                compare: defaultUnidadSat,
                clave: defaultUnidadSat
            }).then(response => {
                setIsLoading(false);
                // console.log('Fetch unidad: ', response);
                setUnidadOptions(response.map((r: { clave: string, nombre: string, template?: ReactElement }) => ({ label: `(${r.clave}) ${r.nombre}`, value: r.clave, template: <p><span >{r.clave}</span><br /><span className='small'>{r.nombre}</span></p> })))
                // >> Confirm clave is valid here
                const valid = response.find(({ clave }: { clave: string }) => defaultUnidadSat === clave);
                setUnidadValid(valid?.clave || null)
            })
        }


        const fetchClaves = (defaultClaveSatProdServ: string) => {
            // console.log('[SETUP - DEFAULTS] Fetching claves: ', defaultClaveSatProdServ);
            setIsLoading(true);
            return post(`https://api.fiscalpop.com/api/v1/sat/productosServicios/${authToken}`, {
                compare: defaultClaveSatProdServ,
                clave: defaultClaveSatProdServ
            }).then(response => {
                setIsLoading(false);
                setClaveOptions(response.map((r: { clave: string, descripcion: string, template?: ReactElement }) => ({ label: `(${r.clave}) ${r.descripcion}`, value: r.clave, template: <p><span >{r.clave}</span><br /><span className='small'>{r.descripcion}</span></p> })))
                // >> Confirm clave is valid here
                const valid = response.find(({ clave }: { clave: string }) => defaultClaveSatProdServ === clave);
                setClaveValid(valid?.clave || null)
            })
        }

        const prodSubs = prodServSubject.pipe(debounceTime(200)).subscribe(s => {
            fetchClaves(s || '');
        })
        const unidadSubs = unidadSubject.pipe(debounceTime(200)).subscribe(s => {
            fetchUnidad(s || '');
        })

        return () => {
            prodSubs.unsubscribe()
            unidadSubs.unsubscribe()
        }
    }, [prodServSubject, unidadSubject, authToken])

    useEffect(() => {
        console.log(`UE on Prod: `, concepto.claveProdServ)
        prodServSubject.next(concepto.claveProdServ);
    }, [concepto.claveProdServ, prodServSubject])

    useEffect(() => {
        unidadSubject.next(concepto.claveUnidad);
    }, [concepto.claveUnidad, unidadSubject])

    useEffect(() => {
        if (onValidationTest) {
            // Claves are propagated upstream to set a list of validated clave values to test agains
            onValidationTest(unidadValid, claveValid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unidadValid, claveValid])

    const autoFillConceptoOption = (conceptoId: string) => {
        const conceptoToFill = conceptoOptions.find(c => c._id === conceptoId);
        // Update claves fetch to avoid misplaced errors
        if (conceptoToFill.claveProdServ) {
            prodServSubject.next(conceptoToFill.claveProdServ)
        }
        if (conceptoToFill.claveUnidad) {
            unidadSubject.next(conceptoToFill.claveUnidad)
        }
        const clearedConceptoToFill: ConceptoCatalogo = clearGqlTypename(conceptoToFill)
        clearedConceptoToFill.impuestos.forEach((impuesto) => {
            // Pre-fill impuestos with uniqueId
            impuesto._uniqueId = getImpuestoUniqueId(impuesto, impuestosList)
        })
        handleConceptoUpdate(conceptoIndex)(Object.assign({ cantidad: 1 }, clearedConceptoToFill));
    }

    const handleConceptoChange = (property: 'claveProdServ' | 'claveUnidad' | 'cantidad' | 'descripcion' | 'valorUnitario' | 'pedimento' | 'noIdentificacion' | 'descuento') => (value: any) => {
        const assigner: { [key: string]: any } = {};
        if (property === 'valorUnitario' || property === 'cantidad' || property === 'descuento') {
            if (value < 0) {
                value = 0;
            }
            assigner[property] = value;
        } else {
            assigner[property] = value;
        }
        const _concepto: BillConceptos = Object.assign({}, concepto, assigner);
        if (property === 'descripcion') {
            searchByName.next(value);
            if (_concepto._id) {
                // It name was modified after selecting a product, consider this a new product instead
                delete _concepto._id
            }
        }
        handleConceptoUpdate(conceptoIndex)(_concepto);
    }

    const handleAddNewTax = () => {
        concepto.impuestos.push(EMPTY_CONCEPTO(isGlobalBill).impuestos[0]);
        // console.log('Adding new tax: ', conceptoIndex, concepto);
        handleConceptoUpdate(conceptoIndex)(concepto);
    }

    const handleRemoveTax = (index: number) => (_: any) => {
        const _concepto = Object.assign({}, concepto);
        const taxes = _concepto.impuestos;
        // console.log('Removing tax: ', conceptoIndex, concepto);
        if (taxes.length === 1) {
            _concepto.impuestos = EMPTY_CONCEPTO(isGlobalBill).impuestos;
            handleConceptoUpdate(conceptoIndex)(_concepto);
        } else {
            _concepto.impuestos.splice(index, 1);
            handleConceptoUpdate(conceptoIndex)(_concepto);
        }
    }

    const handleImpuestoUpdate = (index: number) => (impuesto: BillConceptTax) => {
        const _concepto = Object.assign({}, concepto);
        _concepto.impuestos[index] = impuesto;
        handleConceptoUpdate(conceptoIndex)(_concepto);
    }

    const autoFillOptions = conceptoOptions.map(c => ({ label: c.descripcion, value: c._id }));
    const noIdentificacionOnTop = isGlobalBill || (!hideCantidad && (showNoIdentificacion || concepto.noIdentificacion))
    const noIdentificacionOnMiddle = !noIdentificacionOnTop && (showNoIdentificacion || concepto.noIdentificacion)
    return (

        <div className={`__concepto ${conceptoIndex > 0 ? '_enum' : ''}`}>
            {
                conceptoIndex > 0
                    ?
                    <span className="enum">
                        # {conceptoIndex + 1}
                    </span>

                    : ''
            }
            {
                removeConcepto ?
                    <Button secondary={true} handleClick={removeConcepto(conceptoIndex)}>
                        <div>
                            <span>Eliminar concepto # {conceptoIndex + 1}
                            </span>
                            <i className="material-icons delete">
                                delete
                            </i>
                        </div>
                    </Button>

                    : ''
            }
            <div className={`row _description ${noIdentificacionOnTop ? 'two xs-gap-compressed sm-one' : ''}`}>
                {
                    descripcionAsInput ?
                        <Input label="Descripción" hasError={validRequested && !concepto.descripcion} value={concepto.descripcion} placeholder="Nombre del producto" type="text" onChange={handleConceptoChange('descripcion')} />
                        :
                        <AutoComplete label="Descripción" hasError={validRequested && !concepto.descripcion} value={concepto.descripcion} placeholder="Nombre del producto" type="text" options={autoFillOptions} displayLabel={true} onChange={handleConceptoChange('descripcion')} onSelect={autoFillConceptoOption} disabled={!!isGlobalBill} />
                }
                {
                    noIdentificacionOnTop && isGlobalBill ?
                        <Input label="Número o ID de Venta" hasError={validRequested && !concepto.noIdentificacion} value={concepto.noIdentificacion} placeholder="Id que identifica la venta o ticket" type="text" onChange={handleConceptoChange('noIdentificacion')} />
                        :
                        (
                            noIdentificacionOnTop && !isGlobalBill ?
                                <Input label="SKU o No serie (opcional)" value={concepto.noIdentificacion} placeholder="SKU o No. Serie (opcional)" type="text" onChange={handleConceptoChange('noIdentificacion')} />
                                :
                                null
                        )
                }
            </div>
            <div className="row two xs-one xs-gap-compressed">
                <AutoComplete displayLabel={true} isLoading={isLoading} label="Clave producto o servicio" hasError={(validRequested && !concepto.claveProdServ) || (concepto.claveProdServ && !claveOptions.length) || (concepto.claveUnidad && !claveValid)} value={concepto.claveProdServ} placeholder="Clave de producto o servicio" type="text" options={claveOptions} onChange={handleConceptoChange('claveProdServ')} onSelect={handleConceptoChange('claveProdServ')} disabled={!!isGlobalBill} />
                <AutoComplete displayLabel={true} isLoading={isLoading} label="Clave unidad" hasError={(validRequested && !concepto.claveUnidad) || (concepto.claveUnidad && !unidadOptions.length) || (concepto.claveUnidad && !unidadValid)} value={concepto.claveUnidad} placeholder="Clave de unidad" type="text" options={unidadOptions} onChange={handleConceptoChange('claveUnidad')} onSelect={handleConceptoChange('claveUnidad')} disabled={!!isGlobalBill} />
            </div>
            {
                !!hideCantidad ?
                    <div className="row two xs-one xs-gap-compressed">
                        <Input type="number" isCurrency={true} hasError={validRequested && !concepto.valorUnitario} label="Valor unitario (sin impuestos)" value={concepto.valorUnitario} placeholder="" onChange={handleConceptoChange('valorUnitario')} min={0} />
                        {
                            noIdentificacionOnMiddle ?
                                <Input label="SKU o No serie (opcional)" value={concepto.noIdentificacion} placeholder="SKU o No. Serie (opcional)" type="text" onChange={handleConceptoChange('noIdentificacion')} />
                                :
                                null
                        }
                    </div>
                    :
                    <div className="row three sm-two xs-gap-compressed">
                        <Input type="number" label="Cantidad" hasError={validRequested && !concepto.cantidad} value={concepto.cantidad} placeholder="" onChange={handleConceptoChange('cantidad')} min={0} step={1} disabled={!!isGlobalBill} />
                        <Input type="number" isCurrency={true} hasError={validRequested && !concepto.valorUnitario} label="Valor unitario (sin impuestos)" value={concepto.valorUnitario} placeholder="" onChange={handleConceptoChange('valorUnitario')} min={0} />
                        <Input type="number" isCurrency={true} label="Descuento unitario" value={concepto.descuento || 0} placeholder="" onChange={handleConceptoChange('descuento')} min={0} />
                    </div>
            }
            {
                concepto.impuestos.map((impuesto, i) => {
                    return (
                        <ConceptoElementImpuesto i={i} key={i} impuesto={impuesto} impuestosList={impuestosList} handleImpuestoUpdate={handleImpuestoUpdate} handleAddNewTax={handleAddNewTax} handleRemoveTax={handleRemoveTax} />
                    )
                })
            }
        </div>
    )
}


export default FacturarConceptos;