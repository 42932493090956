import { useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { graphqlSchema } from '../../../../services/graphql.schema';

import Modal from 'react-modal';
import Input from '../../../Forms/Input';
import Select from '../../../Forms/Select';
import Button from '../../../Forms/Button';
import { REGIMEN_OPTIONS } from '../../../../utils/regimen.options';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        border: 'none',
        background: 'transparent',
        boxShadow: 'none',
        padding: '3em'
    }
};
Modal.setAppElement('#root');

interface CotizacionEditorStampDialogProps {
    modalOpen: boolean;
    requestClose: () => void;
    requestStamp: (data: { nombre: string; rfc: string; usoCFDI: string; email?: string; formaPago: string, regimen: string, zip: string }) => void;
}

const REGIMEN_OPT = REGIMEN_OPTIONS.map((o) => ({ value: o.value, label: o.secondLabel }))

function CotizacionEditorStampDialog({
    modalOpen,
    requestClose,
    requestStamp,
}: CotizacionEditorStampDialogProps) {
    // Stamping 
    const [usosCFDI, setUsosCFDI] = useState<{ value: string, label: string }[]>([]);
    const [formasPago, setFormasPago] = useState<{ value: string, label: string }[]>([]);
    const [receptorName, setReceptorName] = useState('PUBLICO EN GENERAL');
    const [receptorRfc, setReceptorRfc] = useState('XAXX010101000');
    const [receptorUso, setReceptorUso] = useState('G03');
    const [receptorPago, setReceptorPago] = useState('04');
    const [receptorEmail, setReceptorEmail] = useState('');
    const [receptorRegimen, setReceptorRegimen] = useState('616');
    const [receptorZip, setReceptorZip] = useState('66039');

    useQuery(graphqlSchema.FISCALPOP.USO_CFDI.getUsoCfdi, {
        onCompleted: ({ getUsoCfdi }: { getUsoCfdi: { clave: string, descripcion: string }[] }) => {
            //console.log('Got Usos CFDI: ', getUsoCfdi);
            setUsosCFDI(getUsoCfdi.map(ucfdi => ({ value: ucfdi.clave, label: `(${ucfdi.clave}) ${ucfdi.descripcion}` })));
        },
        onError: (e) => {
            console.error('Error getting uso CFDI: ', e);
        }
    })
    useQuery(graphqlSchema.FISCALPOP.FORMA_PAGO.getFormaPago, {
        onCompleted: ({ getFormaPago }: { getFormaPago: { key: string, label: string }[] }) => {
            //console.log('Got Usos CFDI: ', getFormaPago);
            setFormasPago(getFormaPago.map(gFP => ({ value: gFP.key, label: `(${gFP.key}) ${gFP.label}` })));
        },
        onError: (e) => {
            console.error('Error getting uso CFDI: ', e);
        }
    })

    const stampOver = () => {
        if (!(
            !receptorName ||
            !receptorRfc ||
            !receptorPago ||
            !receptorUso
        )) {

            requestStamp({
                nombre: receptorName,
                rfc: receptorRfc,
                usoCFDI: receptorUso,
                formaPago: receptorPago,
                email: receptorEmail,
                regimen: receptorRegimen,
                zip: receptorZip
            })
        }
    }

    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={requestClose}
            style={customStyles}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            contentLabel="Cotizacion shareable"
        >
            <div id="CotizacionesEditorStampModal">
                <h2>
                    Receptor de factura
                </h2>
                <div className="receptor">
                    <Input label="Razon social" value={receptorName} onChange={setReceptorName} type="text" placeholder="Razon social" />
                    <div className="row two">
                        <Input label="RFC" value={receptorRfc} onChange={setReceptorRfc} type="text" placeholder="RFC" />
                        <Select label={'Uso CFDI'} value={receptorUso} options={usosCFDI} onChange={setReceptorUso} />
                    </div>
                    <div className="row two">
                        <Select label={'Régimen Receptor'} value={receptorRegimen} options={REGIMEN_OPT} onChange={setReceptorRegimen} />
                        <Input label="Código Postal" value={receptorZip} onChange={setReceptorZip} type="text" placeholder="06700" />
                    </div>
                    <div className="row two">
                        <Select label={'Forma Pago'} value={receptorPago} options={formasPago} onChange={setReceptorPago} />
                        <Input label="Email" value={receptorEmail} onChange={setReceptorEmail} type="text" placeholder="Email" />
                    </div>
                </div>
                <div className="actions">
                    <Button secondary={true} handleClick={requestClose}>
                        <span>Cancelar</span>
                    </Button>
                    <Button primary={true} handleClick={stampOver}>
                        <span>Facturar</span>
                    </Button>
                </div>
            </div>
        </Modal>
    )
}


export default CotizacionEditorStampDialog