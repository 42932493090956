import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { BaseReact } from '../../../base.model';
import { ConceptoCatalogo } from '../../../models/Catalogos';
import { ProfileQL } from '../../../models/Profile';
import { graphqlSchema } from '../../../services/graphql.schema';
import SectionTitleBar from '../../components/titlebar';
import Button from '../../Forms/Button';
import Pagination from '../../Forms/Pagination';
import { formatCurrencyStyled } from '../../visuals/currency.formatted';
import CatalogoMenu from '../Catalogo.menu';
import ConceptosCatalogDialog from './Conceptos.dialog';

import { clearGqlTypename } from '../../../utils/formatting';

import './Conceptos.scss';
import sharedToasterSubject from '../../../services/shared.toasterSubject';

interface ConceptosCatalogProps extends BaseReact {
    currentUser: ProfileQL;
}

const ITEMS_PER_PAGE = 20;

function ConceptosCatalog({ currentUser }: ConceptosCatalogProps) {

    const [modalOpen, setModalOpen] = useState(false);

    const [conceptos, setConceptos] = useState<ConceptoCatalogo[]>([]);
    const [conceptosLoaded, setConceptosLoaded] = useState<boolean>(false);

    const [conceptoToUpdate, setConceptoToUpdate] = useState<ConceptoCatalogo>(null)

    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);

    useQuery(graphqlSchema.PROFILE.CATALOGOS.getConceptoCatalog, {
        onCompleted: ({ getConceptoCatalog }: { getConceptoCatalog: ConceptoCatalogo[] }) => {
            console.log('getConceptoCatalog: ', getConceptoCatalog);
            setConceptos(getConceptoCatalog);
            setConceptosLoaded(true);
        },
        onError: (e) => {
            console.error('Error client: ', e)
            setConceptosLoaded(true);
        },
        fetchPolicy: 'cache-and-network'
    })

    const [submitConcepto] = useMutation(graphqlSchema.PROFILE.CATALOGOS.saveCatalogoConcepto, {
        onCompleted: ({ saveCatalogoConcepto }: { saveCatalogoConcepto: ConceptoCatalogo }) => {
            setConceptos(conceptos.concat([saveCatalogoConcepto]));
            sharedToasterSubject.next({type: 'confirm', message: `Se agregó el concepto correctamente`})
        },
        onError: (e) => {
            console.error('Error submitting client: ', e);
            sharedToasterSubject.next({type: 'error', message: `Error al agregar el concepto`})
        }
    })
    const [updateConcepto] = useMutation(graphqlSchema.PROFILE.CATALOGOS.updateCatalogoConcepto, {
        onCompleted: ({ updateCatalogoConcepto }: { updateCatalogoConcepto: ConceptoCatalogo }) => {
            const updated = conceptos.find(({ _id }) => _id === updateCatalogoConcepto._id);
            Object.assign(updated, updateCatalogoConcepto);
            setConceptos(JSON.parse(JSON.stringify(conceptos)));
            sharedToasterSubject.next({type: 'confirm', message: `Se editó el concepto correctamente`})
        },
        onError: (e) => {
            console.error('Error submitting client: ', e);
            sharedToasterSubject.next({type: 'error', message: `Error al editar el concepto`})
        }
    })


    const openNewConceptosModal = () => {
        setModalOpen(true);
    }

    const openUpdateConceptosModal = (concepto: ConceptoCatalogo) => () => {
        setConceptoToUpdate(concepto);
        setModalOpen(true);
    }

    const closeConceptosModal = () => {
        setModalOpen(false);
        setConceptoToUpdate(null);
    }

    const saveConcepto = (newConcepto: ConceptoCatalogo) => {
        const concepto = Object.assign({}, newConcepto);
        closeConceptosModal();
        if (concepto._id) {
            console.log('Concepto to update: ', concepto);
            updateConcepto({
                variables: {
                    concepto: clearGqlTypename(concepto)
                }
            })
        } else {
            console.log('Concepto to save: ', concepto);

            submitConcepto({
                variables: {
                    concepto: clearGqlTypename(concepto)
                }
            })
        }
    }

    const searchFor = (e: React.ChangeEvent<HTMLInputElement>) => {
        const textValue = e.target.value;
        setSearchText(textValue);
    }

    const conceptosFiltered = () => {
        if (!searchText) { return conceptos }
        const wordsSearch = searchText.toLowerCase().split(' ');
        return conceptos.filter(c => {
            const _target = c.descripcion.toLowerCase()
            return wordsSearch.reduce((p, c) => {
                return p && _target.includes(c);
            }, true as boolean)
        })
    }

    const cardSwitch = () => {

        if (conceptosLoaded && !conceptos.length) {
            return (
                <div className="card table empty">
                    <div className="emptyContent">
                        <p>
                            Aún no tienes ningún concepto en tu catálogo
                        </p>
                        <Button tertiary={true} handleClick={openNewConceptosModal}>
                            <div>
                                <i className="material-icons">add_circle</i>
                                <span>Dar de alta nuevo concepto</span>
                            </div>
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <div className="card table conceptoCard">
                <div className="cardTitle">
                    <div className="titleFilter">
                        <div className="searchFor">
                            <span className="material-icons">
                                search
                                </span>
                            <input value={searchText} onChange={searchFor} type="text" />
                        </div>
                        <div className="_action">
                            <div className="addBill" onClick={openNewConceptosModal}>
                                <i className="material-icons">add_circle</i>
                                <span>Agregar nuevo concepto </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cardBody">
                    <div className="card table" style={{height: '100%', paddingBottom: '0px'}}>
                        <div className="cardTitle headerTitle">
                            <div className="concepto">
                                <p className="name">Nombre</p>
                                <p className="valor">Valor unitario</p>
                                <p className="taxes">Impuestos</p>
                                <p className="claveProd">Clave prod</p>
                                <p className="claveUnidad">Clave unidad</p>
                                <div />
                                <div />
                            </div>
                        </div>
                        <div className="cardBody">
                            {
                                _conceptos.map((concepto, i) => {
                                    return (
                                        <div className="concepto" key={i}>
                                            <p className="name">{concepto.descripcion}</p>
                                            <p className="valor">{formatCurrencyStyled(concepto.valorUnitario)}</p>
                                            <div className="taxes">
                                                {concepto.impuestos.map((tax, j) => (
                                                    <p className="tax" key={j}>
                                                        {tax.type.toUpperCase()} {tax.retencion ? 'retenido' : 'trasladado'} {tax.tasa ? `${Math.round(tax.tasa * 1000000) / 10000}%` : (tax.cuota ? `$ ${tax.cuota} / unidad` : '')}
                                                    </p>
                                                ))}
                                            </div>
                                            <p className="claveProd">{concepto.claveProdServ}</p>
                                            <p className="claveUnidad">{concepto.claveUnidad}</p>
                                            <Button tertiary={true} handleClick={openUpdateConceptosModal(concepto)}>
                                                <span className="material-icons main">
                                                    edit
                                                </span>
                                            </Button>
                                            <Button tertiary={true}>
                                                <span className="material-icons">
                                                    delete
                                                </span>
                                            </Button>
                                        </div>
                                    )
                                })
                            }
                            <Pagination page={page} pages={Math.ceil(_conceptos.length / ITEMS_PER_PAGE)} onPage={setPage} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const _conceptos = conceptosFiltered();

    return (
        <div id="Catalogo">
            <SectionTitleBar currentUser={currentUser} title="Catálogo de Conceptos" />
            <div className="catalogoContent">
                <CatalogoMenu />
                {cardSwitch()}
            </div>
            <ConceptosCatalogDialog
                authToken={currentUser.fiscalpopProfile.authToken}
                modalOpen={modalOpen}
                updateConcepto={conceptoToUpdate}
                saveConcepto={saveConcepto}
                closeConceptosModal={closeConceptosModal} />
        </div>
    )
}

export default ConceptosCatalog;