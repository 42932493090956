import React, { useEffect, useState } from 'react';
import { BankProjectConcept_Expense } from '../../../../models/Administracion/Bank.Projects';
import { numberToCurrencyString } from '../../../../services/formatting';
import unifiedMoment from '../../../../services/unifiedMoment';

import './Projection.gastos.ruta.scss';

interface ProjectionGastosRouteProps {
    expenses: BankProjectConcept_Expense[]
    lastUpdateTrigger?: number; // Forces Update refresh
}

interface DateExpense {
    startTs: number;
    endTs: number;
    amount: number;
    name: string;
    months: number;
    monthStartRef: number;
}

interface ExpenseRailFlag {
    name: string;
    months: number;
    monthStartRef: number;
    xPercent: number;
    yOffset: number; // When two labels are at the same time
}

interface AmountRailFlag {
    estimate: number;
    higherThan: boolean;
    endTs: number;
    xPercent: number;
}

export function ProjectionGastosRoute({ expenses, lastUpdateTrigger }: ProjectionGastosRouteProps) {

    const [labelFlags, setLabelFlags] = useState<ExpenseRailFlag[]>([])
    const [amountFlags, setAmountFlags] = useState<AmountRailFlag[]>([])

    const [dates, setDates] = useState<[number, number]>([null, null])

    useEffect(() => {
        console.log(`<ProjectionGastosRoute> UE: `, expenses);
        if (expenses.length) {

            const dateExpense = expenses.map((e) => ({
                startTs: e.startsAt,
                endTs: unifiedMoment(e.startsAt).add(e.monthRepetition, 'month').startOf('month').valueOf(),
                amount: e.amount,
                name: e.name,
                months: e.monthRepetition,
                monthStartRef: 1, // For labeling only
            }))
                .sort((a, b) => a.startTs - b.startTs)
            // Split by MAX 12 months

            const labelBlocksBase = dateExpense
                .reduce((p, c) => {
                    if (c.months > 12) {
                        const blocks = Math.ceil(c.months / 12)
                        for (let i = 0; i < blocks; i++) {
                            let months = (i + 1) * 12 < c.months ? 12 : (c.months - (i * 12));
                            let monthStartRef = !i ? 1 : (i * 12);
                            let startTs = unifiedMoment(c.startTs).add(i * 12, 'months').valueOf()
                            let endTs = unifiedMoment(c.startTs).add((i * 12) + months, 'months').valueOf()
                            const subBlock = { ...c, startTs, endTs, months, monthStartRef }
                            p.push(subBlock)
                        }
                    } else {
                        p.push(c)
                    }
                    return p;
                }, [] as DateExpense[])
            const startTs = Math.min(...dateExpense.map((e) => e.startTs))
            const endTs = Math.max(...dateExpense.map((e) => e.endTs))
            const tsSpread = endTs - startTs;

            // Percentile set flag points
            const labelFlags = labelBlocksBase.map((dE) => {
                const xPercent = dE.months < 2 ?
                    Math.round(((dE.startTs - startTs) / tsSpread) * 100) :
                    Math.round(((((dE.endTs - dE.startTs) / 2) / tsSpread) + ((dE.startTs - startTs) / tsSpread)) * 100)
                return ({
                    xPercent,
                    name: dE.name,
                    months: dE.months,
                    monthStartRef: dE.monthStartRef,
                    yOffset: 0
                }) as ExpenseRailFlag
            }).map((lF, index, others) => {
                if (index > 0) {
                    const clashesWith = others.filter((o, i) => (i < index) && o.xPercent > (lF.xPercent - 10));
                    if (clashesWith.length) {
                        const minIs = Math.min(...clashesWith.map(cW => cW.yOffset))
                        const maxIs = Math.max(...clashesWith.map(cW => cW.yOffset))
                        const offsets = new Array(clashesWith.length + 1).fill(1).map((_, i) => i)
                        const minAvailable = offsets.reduce((p, c) => {
                            if (p > c) {
                                return c;
                            }
                            return p;
                        }, minIs);
                        lF.yOffset = minAvailable < minIs ? minAvailable : maxIs + 1
                    }
                }
                return lF;
            })
            console.log(`<ProjectionGastosRoute> labelFlags: `, labelFlags)

            const amountFlags = dateExpense.reduce((p, c) => {
                const overlap = p.find(aF => (aF.endTs === c.endTs))
                if (overlap) {
                    overlap.estimate += c.amount;
                    overlap.higherThan = true;
                } else {
                    p.push({
                        estimate: c.amount,
                        endTs: c.endTs,
                        higherThan: false,
                        xPercent: Math.round(((c.endTs - startTs) / tsSpread) * 100),
                    })
                }
                return p;
            }, [] as AmountRailFlag[])

            let sumBase = 0;
            amountFlags.forEach((a) => {
                a.estimate += sumBase;
                sumBase = a.estimate;
            })

            console.log(`<ProjectionGastosRoute> amountFlags: `, amountFlags)

            setLabelFlags(labelFlags)
            setAmountFlags(amountFlags)
            setDates([startTs, endTs])
        }
    }, [expenses, lastUpdateTrigger])

    return (
        <div id='ProjectionExpensesRoute'>
            <div className='rail'>
                {
                    labelFlags.map((flag, i) => (
                        <div className='labelFlag' style={{ left: `${flag.xPercent}%` }} key={i}>
                            <div className='_text' style={{ bottom: `${1 + flag.yOffset}rem` }}>
                                {flag.name}
                            </div>
                        </div>
                    ))
                }
                {
                    amountFlags.map((flag, i) => (
                        <div className='amountFlag' style={{ left: `${flag.xPercent}%` }} key={i}>
                            <div className='_text'>
                                {flag.higherThan ? '>' : ''} ${numberToCurrencyString(flag.estimate)}
                            </div>
                        </div>
                    ))
                }
                {
                    dates[0] ?
                        <div className='startLabel'>
                            {unifiedMoment(dates[0]).format('MMM YYYY')}
                        </div>
                        : null
                }
                {
                    dates[1] ?
                        <div className='endLabel'>
                            {unifiedMoment(dates[1]).format('MMM YYYY')}
                        </div>
                        : null
                }
            </div>
        </div>
    )
}