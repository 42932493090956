import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { BankBudgets } from '../../../../models/Administracion/Bank.Budgets';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import { numberToCurrencyString } from '../../../../services/formatting';
import { graphqlSchema } from '../../../../services/graphql.schema';
import unifiedMoment from '../../../../services/unifiedMoment';
import PercentBar from '../../../components/barPercent';
import ToolTip from '../../../visuals/tooltip';

import './AdministrationHub.Proyections.Budgets.scss';

interface ExpenseReference { maximum: number, average: number, currentMonth: number, lastMonth: number, windowStart: number, windowEnd: number, goalTarget: number }
function AdministrationHubBudgets() {

    // Expected to be always constant
    const graphRef = useRef<HTMLDivElement>()

    const [monthChoosen, setMonthChoosen] = useState(unifiedMoment().startOf('month').valueOf())

    const [budgets, setBudgets] = useState<BankBudgets[]>([])
    const [budgetsLoaded, setBudgetsLoaded] = useState<boolean>(false)

    const [banksSummaryLoaded, setBanksSummaryLoaded] = useState<boolean>(false)
    const [banksSummary, setBanksSummary] = useState<BankMovementSummary[]>([])
    const [monthExpense, setMonthExpense] = useState(0)

    const [expenseReference, setExpenseReference] = useState({ maximum: 0, average: 0, currentMonth: 0, lastMonth: 0, windowStart: 0, windowEnd: 0, goalTarget: 0 });
    const [expenseReferenceLoaded, setExpenseReferenceLoaded] = useState<boolean>(false)

    const [fetchBudgetsExpenseReference] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBudgetsExpenseReference, {
        onCompleted: ({ getBudgetsExpenseReference }: { getBudgetsExpenseReference: ExpenseReference }) => {
            const _getBudgetsExpenseReference: ExpenseReference = JSON.parse(JSON.stringify(getBudgetsExpenseReference))
            console.log(`(Presupuestos) <getBudgetsExpenseReference> `, _getBudgetsExpenseReference);
            setExpenseReference(_getBudgetsExpenseReference);
            setExpenseReferenceLoaded(true);
        },
        fetchPolicy: 'cache-and-network'
    })
    const [fetchMovements] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankMovementsSummary, {
        onCompleted: ({ getBankMovementsSummary }: { getBankMovementsSummary: BankMovementSummary[] }) => {
            const _getBankMovementsSummary: BankMovementSummary[] = JSON.parse(JSON.stringify(getBankMovementsSummary))
            console.log(`(Presupuestos) <getBankMovementsSummary> `, _getBankMovementsSummary);
            setBanksSummary(_getBankMovementsSummary);
            setBanksSummaryLoaded(true)
        },
        fetchPolicy: 'cache-and-network'
    })

    const [fetchBudgets] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBudgets, {
        onCompleted: ({ getBudgets }: { getBudgets: BankBudgets[] }) => {
            const trackedBudgets = getBudgets.filter(b => b.goal).reduce((p, c) => {
                const exists = p.find(b => b.subcategory === c.subcategory)
                if (exists) {
                    exists.goal += c.goal;
                    exists.current += c.current;
                } else {
                    p.push(Object.assign({}, c))
                }
                return p;
            }, [] as BankBudgets[])
            console.log(`<AdministrationHubBudgets> getBudgets: `, trackedBudgets);
            setBudgets(trackedBudgets)
            setBudgetsLoaded(true)
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        fetchMovements({
            variables: {
                start: monthChoosen,
                end: unifiedMoment(monthChoosen).endOf('month').valueOf()
            }
        })
        fetchBudgets({
            variables: {
                monthTs: monthChoosen,
            }
        })
        fetchBudgetsExpenseReference()
    }, [monthChoosen, fetchBudgets, fetchMovements, fetchBudgetsExpenseReference])

    useEffect(() => {
        const _monthExpense = Math.abs(banksSummary.reduce((p, c) => p += c.expense, 0))
        setMonthExpense(_monthExpense);
    }, [banksSummary])


    const renderGraph = () => {
        const maxVal = Math.max(...[expenseReference.maximum, expenseReference.goalTarget])
        const yValue = maxVal || 1;
        const maxExpense = (expenseReference.maximum || 0) / yValue;
        const avgExpense = expenseReference.average / yValue;
        const goalExpense = (expenseReference.goalTarget / yValue);
        const heightRelative = (graphRef?.current?.getBoundingClientRect().height || 247) - 10;

        return (
            <div className='graphPlacer'>
                <div className='_keys'>
                    <div className='_key last'>
                        <p className='small lightGray'>
                            Mes Pasado
                        </p>
                    </div>
                    <div className='_key current'>
                        <p className='small lightGray'>
                            Mes Actual
                        </p>
                    </div>
                </div>

                {
                    // Empty State
                    expenseReferenceLoaded && budgetsLoaded && banksSummaryLoaded && !budgets.length ?
                        <div className='_isEmpty'>
                            <div>
                                <p className='lightGray _info'>
                                    Debes agregar Presupuestos para visualizar el resumen mensual
                                </p>
                                <p className='lightGray small'>
                                    Haz <NavLink to="/administration/presupuestos">click aquí</NavLink> para ir a presupuestos
                                </p>
                            </div>
                        </div>
                        : (
                            expenseReferenceLoaded && budgetsLoaded && banksSummaryLoaded && (!expenseReference.currentMonth) ?
                                // true ?
                                <div className='_isEmpty'>
                                    <div>
                                        <p className='lightGray _info'>
                                            Debes categorizar movimientos y tener tus bancos conectados
                                        </p>
                                        <p className='lightGray small'>
                                            Haz <NavLink to="/administration/movimientos">click aquí</NavLink> para ir a movimientos
                                        </p>
                                        <p className='lightGray small'>- ó -</p>
                                        <p className='lightGray small'>
                                            Haz <NavLink to="/administration/bancos">click aquí</NavLink> para conectar bancos
                                        </p>
                                    </div>
                                </div>
                                : null
                        )
                }
                <div className='graphBase' ref={graphRef}>
                    {
                        expenseReference.windowStart !== expenseReference.windowEnd ?
                            <div className='_reference _max' style={{ transform: `translateY(-${maxExpense * heightRelative}px)` }} />
                            : null
                    }
                    {
                        !!avgExpense ?
                            <div className='_reference _avg' style={{ transform: `translateY(-${avgExpense * heightRelative}px)` }} />
                            : null
                    }
                    {
                        !!goalExpense ?
                            <div className='_reference _expect' style={{ transform: `translateY(-${goalExpense * heightRelative}px)` }} />
                            : null
                    }
                    {
                        expenseReference.currentMonth ?
                            <div className='_bar _current' style={{ height: `${(expenseReference.currentMonth / yValue) * 100}%` }} >
                                <ToolTip text={`$${numberToCurrencyString(expenseReference.currentMonth)}`} justify="right">
                                    <div />
                                </ToolTip>
                            </div>
                            : null
                    }
                    {
                        expenseReference.lastMonth ?
                            <div className='_bar _lastMonth' style={{ height: `${(expenseReference.lastMonth / yValue) * 100}%` }} >
                                <ToolTip text={`$${numberToCurrencyString(expenseReference.lastMonth)}`} justify="right">
                                    <div />
                                </ToolTip>
                            </div>
                            : null
                    }
                </div>
            </div>
        )
    }


    const renderEmptyState = () => {
        if (budgetsLoaded && banksSummaryLoaded && !banksSummary.length) {
            return (
                <div className='_isEmpty'>
                    <div>
                        <p className='lightGray _info'>
                            No hay movimientos este mes, ¿Estan sincronizadas tus cuentas?
                        </p>
                        <p className='lightGray small'>
                            Haz <NavLink to="/administration/bancos">click aquí</NavLink> para sincronizar tus bancos
                        </p>
                    </div>
                </div>
            )
        }
        else if (budgetsLoaded && banksSummaryLoaded && !budgets.length) {
            return (
                <div className='_isEmpty'>
                    <div>
                        <p className='lightGray _info'>
                            Debes configurar Presupuestos para visualizar el gasto proporcional
                        </p>
                        <p className='lightGray small'>
                            Haz <NavLink to="/administration/presupuestos">click aquí</NavLink> para ir a presupuestos
                        </p>
                    </div>
                </div>
            )
        }
        else if (budgetsLoaded && banksSummaryLoaded && !budgets.filter(b => b.current).length) {
            return (
                <div className='_isEmpty'>
                    <div>
                        <p className='lightGray _info'>
                            No hay movimientos asociados a tus categorías
                        </p>
                        <p className='lightGray small'>
                            Haz <NavLink to="/administration/movimientos">click aquí</NavLink> para asignar movimientos
                        </p>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }

    return (
        <div className='budgetsProjectsContainer'>
            <div className='_monthByMonth'>
                <h4>Resumen Mensual Presupuestos</h4>
                <div className='card noShadow'>
                    {renderGraph()}
                </div>
            </div>
            <div>
                <h4>Gastos por categoría totales</h4>
                <div className='card noShadow'>
                    <div className='budgetEntry header'>
                        <p>Subcategoría</p>
                        <p>% de gasto total</p>
                        <p>% de asignado</p>
                    </div>
                    <div className='_scroller'>
                        {
                            budgets.filter(b => b.current).map(b => {
                                const percent = (b.current / b.goal);
                                const totalPercent = b.current / monthExpense;
                                const [category, subcategory] = b.subcategory.split('-')
                                return (
                                    <div className='budgetEntry' key={`${b.subcategory}`}>
                                        <div>
                                            <p className='_category small lightGray'>{category}</p>
                                            <p className='_category'>{subcategory}</p>
                                        </div>
                                        <PercentBar decimalPercent={totalPercent} divideByCero={!monthExpense} />
                                        <PercentBar decimalPercent={percent} divideByCero={false} />
                                    </div>
                                )
                            })
                        }

                        {
                            renderEmptyState()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdministrationHubBudgets;