import { BillCartaPorte } from "../../../models/Factura";

export const formatMapsUrlForCartaPorte = (
    cartaPorte: BillCartaPorte['cartaPorte'],
    countries: { value: string, label: string }[],
    estados: { value: string, label: string }[],
) => {
    if (!cartaPorte || !countries.length || !estados.length) {
        return '';
    }
    // https://developers.google.com/maps/documentation/embed/embedding-map
    const token = `AIzaSyBEbyW2ZWn0umpSIhdDbdNe2TE2_lbONDY`;
    const origen = cartaPorte?.ubicacion?.origen;
    const destino = cartaPorte?.ubicacion?.destino;
    if(!origen.domicilio.pais || !origen.domicilio.estado || !origen.domicilio.codigoPostal) {
        return '';
    }
    if(!destino.domicilio.pais || !destino.domicilio.estado || !destino.domicilio.codigoPostal) {
        return '';
    }

    const paisOrigen = countries.find(c => c.value === origen?.domicilio?.pais)
    const paisDestino = countries.find(c => c.value === destino?.domicilio?.pais)
    const paisOrigenName = paisOrigen.label.split('(')[0].trim();
    const paisDestinoName = paisDestino.label.split('(')[0].trim();

    const estadoDestino = estados.find(c => c.value === destino?.domicilio?.estado)
    const estadoOrigen = estados.find(c => c.value === origen?.domicilio?.estado)
    const estadoDestinoName = estadoDestino.label;
    const estadoOrigenName = estadoOrigen.label;

    const originUrl = `${paisOrigenName}+${estadoOrigenName}+${origen.domicilio?.codigoPostal}`
    const destinoUrl = `${paisDestinoName}+${estadoDestinoName}+${destino.domicilio?.codigoPostal}`
    return `https://www.google.com/maps/embed/v1/directions?key=${token}&origin=${originUrl}&destination=${destinoUrl}&mode=driving&units=metric`
}