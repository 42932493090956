import React from 'react';
import Modal from 'react-modal';
import Button from '../../Forms/Button';
import CopyToClipboard, { CopyToClipboardChild } from '../../visuals/clipboard.copy';

import './MisFacturas.CartaPorte.Share.scss'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        border: 'none',
        background: 'transparent',
        boxShadow: 'none',
        padding: '3em'
    }
};
Modal.setAppElement('#root');

interface MisFacturasCartaPorteShareProps {
    isOpen: boolean;
    shareUrl: string;
    companyName: string;
    logoUrl: string;
    closeModal: () => void;
}

export const MisFacturasCartaPorteShare = ({ isOpen, shareUrl, companyName, logoUrl, closeModal }: MisFacturasCartaPorteShareProps) => {

    const cartaPorteMessage = `${companyName}
Carta porte compartida desde Fiscalpop

${shareUrl}${shareUrl.includes('?') ? '&' : '?'}hLogo=${encodeURI(logoUrl)}&hCompany=${encodeURI(companyName)}`;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            contentLabel="Cotizacion shareable"
        >
            <div id="CartaPorteShareDialog">
                <h2>
                    Comparte tu carta porte
                </h2>
                <div className="_shareable">
                    <p>
                        Usa alguna de estas opciones para compartir tu carta porte
                    </p>
                    <div className="shareables">
                        {
                            /*
                            <a href={`https://api.whatsapp.com/send?text=${encodeURI(cotizacionMessage)}`} target="_blank" rel="noopener noreferrer" >
                            */
                        }
                        <a href={`whatsapp://send?text=${encodeURI(cartaPorteMessage)}`} target="_blank" rel="noopener noreferrer" >
                            <div className="iconShare">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30px" height="30px" viewBox="0 0 1280.000000 720.000000">
                                    <g transform="translate(-490,1020) scale(0.18,-0.18)" stroke="none">
                                        <path d="M6255 6844 c-540 -35 -1107 -229 -1555 -532 -473 -320 -848 -752 -1091 -1256 -133 -276 -216 -536 -273 -856 -43 -240 -52 -602 -22 -880 40 -374 177 -822 362 -1188 l53 -103 -123 -367 c-68 -202 -191 -570 -274 -818 -84 -249 -152 -459 -152 -469 0 -9 13 -22 29 -28 26 -10 29 -14 24 -45 -6 -32 -5 -34 18 -27 41 13 936 298 1314 420 198 63 368 115 378 115 9 0 52 -17 95 -39 366 -184 756 -294 1171 -332 164 -14 498 -7 659 16 954 132 1766 659 2266 1468 163 264 318 632 401 952 79 307 117 688 96 982 -54 781 -356 1473 -881 2017 -509 527 -1157 853 -1895 952 -108 14 -482 26 -600 18z m391 -684 c357 -29 650 -108 959 -259 419 -206 770 -514 1030 -906 200 -301 323 -625 371 -979 23 -168 23 -508 0 -680 -163 -1209 -1161 -2141 -2372 -2217 -427 -26 -824 44 -1212 214 -107 47 -284 143 -339 183 -17 13 -39 24 -49 24 -9 0 -222 -65 -472 -145 -250 -80 -456 -145 -457 -143 -2 2 62 197 141 433 79 237 144 442 144 458 0 16 -18 53 -44 90 -418 599 -554 1426 -351 2127 45 152 82 245 155 390 200 391 505 732 880 982 473 316 1064 472 1616 428z" />
                                        <path d="M5323 5236 c-23 -7 -56 -23 -75 -34 -51 -32 -199 -190 -245 -262 -147 -229 -180 -534 -92 -832 67 -225 149 -397 299 -629 190 -292 313 -450 510 -653 296 -305 545 -476 927 -635 282 -118 490 -185 607 -197 81 -8 258 20 362 58 144 52 309 168 373 262 64 96 130 313 138 457 l6 95 -31 36 c-22 24 -112 78 -294 176 -432 232 -487 254 -555 218 -17 -8 -81 -73 -141 -143 -178 -207 -215 -243 -245 -243 -38 0 -287 127 -403 205 -135 92 -223 166 -334 281 -132 137 -275 333 -355 486 l-18 36 72 79 c95 101 134 162 172 268 39 108 37 141 -20 290 -51 133 -92 243 -163 434 -58 157 -101 221 -161 240 -57 17 -287 22 -334 7z" />
                                    </g>
                                </svg>
                                <p>
                                    Whatsapp App
                                </p>
                            </div>
                        </a>

                        <a href={`https://web.whatsapp.com/send?text=${encodeURI(cartaPorteMessage)}`} target="_blank" rel="noopener noreferrer" >
                            <div className="iconShare">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30px" height="30px" viewBox="0 0 1280.000000 720.000000">
                                    <g transform="translate(-490,1020) scale(0.18,-0.18)" stroke="none">
                                        <path d="M6255 6844 c-540 -35 -1107 -229 -1555 -532 -473 -320 -848 -752 -1091 -1256 -133 -276 -216 -536 -273 -856 -43 -240 -52 -602 -22 -880 40 -374 177 -822 362 -1188 l53 -103 -123 -367 c-68 -202 -191 -570 -274 -818 -84 -249 -152 -459 -152 -469 0 -9 13 -22 29 -28 26 -10 29 -14 24 -45 -6 -32 -5 -34 18 -27 41 13 936 298 1314 420 198 63 368 115 378 115 9 0 52 -17 95 -39 366 -184 756 -294 1171 -332 164 -14 498 -7 659 16 954 132 1766 659 2266 1468 163 264 318 632 401 952 79 307 117 688 96 982 -54 781 -356 1473 -881 2017 -509 527 -1157 853 -1895 952 -108 14 -482 26 -600 18z m391 -684 c357 -29 650 -108 959 -259 419 -206 770 -514 1030 -906 200 -301 323 -625 371 -979 23 -168 23 -508 0 -680 -163 -1209 -1161 -2141 -2372 -2217 -427 -26 -824 44 -1212 214 -107 47 -284 143 -339 183 -17 13 -39 24 -49 24 -9 0 -222 -65 -472 -145 -250 -80 -456 -145 -457 -143 -2 2 62 197 141 433 79 237 144 442 144 458 0 16 -18 53 -44 90 -418 599 -554 1426 -351 2127 45 152 82 245 155 390 200 391 505 732 880 982 473 316 1064 472 1616 428z" />
                                        <path d="M5323 5236 c-23 -7 -56 -23 -75 -34 -51 -32 -199 -190 -245 -262 -147 -229 -180 -534 -92 -832 67 -225 149 -397 299 -629 190 -292 313 -450 510 -653 296 -305 545 -476 927 -635 282 -118 490 -185 607 -197 81 -8 258 20 362 58 144 52 309 168 373 262 64 96 130 313 138 457 l6 95 -31 36 c-22 24 -112 78 -294 176 -432 232 -487 254 -555 218 -17 -8 -81 -73 -141 -143 -178 -207 -215 -243 -245 -243 -38 0 -287 127 -403 205 -135 92 -223 166 -334 281 -132 137 -275 333 -355 486 l-18 36 72 79 c95 101 134 162 172 268 39 108 37 141 -20 290 -51 133 -92 243 -163 434 -58 157 -101 221 -161 240 -57 17 -287 22 -334 7z" />
                                    </g>
                                </svg>
                                <p>
                                    Whatsapp Web
                                </p>
                            </div>
                        </a>
                        <CopyToClipboardChild value={shareUrl}>
                            <div className="iconShare">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="25px" height="25px">
                                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                </svg>
                                <p>
                                    Copiar URL
                                </p>
                            </div>
                        </CopyToClipboardChild>
                    </div>
                </div>
                <div className="actions">
                    <Button secondary={true} handleClick={closeModal}>
                        <span>Cerrar</span>
                    </Button>
                </div>
            </div>
        </Modal>
    )
}