import React, { useState, useReducer, useEffect } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import './App.scss';
import Loading from './controllers/Animations/loadScreen';
import { ProfileQL } from './models/Profile';
import { Redirect, BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import ToasterNotifications from './controllers/visuals/ToasterNotifications';
import MenuGeneral from './controllers/Menu/Menu';
import Support from './controllers/Support/Support';
import GraphQlClient from './services/graphql';
import Register from './controllers/Registration/Register';
import Login from './controllers/Registration/Login';
import RecoverPassword from './controllers/Registration/LostPassword';
import SetupFiscalPop from './controllers/Setup/Setup';
import Dashboard from './controllers/Dashboard/Dashboard';
import SetupSecurityFiscalPop from './controllers/Setup/Setup.security';
import Facturar from './controllers/Facturar/Facturar';
import PreviewFactura from './controllers/FacturaPreview/Preview';
import Pagos from './controllers/Pagos/Pagos';
import MisFacturas from './controllers/MisFacturas/MisFacturas';
import PreviewBillEventFactura from './controllers/FacturaPreview/Preview.billEvents';
import ClientsCatalog from './controllers/Catalogos/Clientes/Clientes';
import ConceptosCatalog from './controllers/Catalogos/Conceptos/Conceptos';
import ErrorsToasts from './controllers/visuals/ErrorToasts';
import CreditNote from './controllers/creditNotes/CreditNote';

import LogRocket from 'logrocket';
import SetupCotizacionFiscalPop from './controllers/Setup/Setup.cotizaciones';
import CotizacionesEditor from './controllers/Cotizaciones/Generator/Editor/Cotizaciones.editor';
import CotizacionesList from './controllers/Cotizaciones/Cotizaciones.list';
import CotizacionGenerator from './controllers/Cotizaciones/Generator/Cotizaciones.generator';
import CotizacionClientView from './controllers/Cotizaciones/View/Cotizacion.view';


import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import ResetPassword from './controllers/Registration/ResetPassword';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import AdministrationHub from './controllers/Administration/AdministrationHub/AdministrationHub';
import Bancos from './controllers/Administration/Bancos/Bancos';
import Movimientos from './controllers/Administration/Movimientos/Movimientos';
import Presupuestos from './controllers/Administration/Presupuestos/Presupuestos';
import PresupuestosConfigure from './controllers/Administration/PresupuestosConfigure/PresupuestosConfigure';
import Projection from './controllers/Administration/Projection/Projection';
import MainHub from './controllers/Administration/MainHub/MainHub';
import CredentialDialogTwoFa from './controllers/Administration/CredentialDialog/CredentialDialog.twofa';
import Planes from './controllers/Planes/Planes';
import PlanWelcomeDialogProfessional from './controllers/Planes/WelcomeDialog/PlanWelcomeDialog.profesional';
import { stripeKey } from './stripeKeys';
import SetupPagosFiscalPop from './controllers/Setup/Setup.Pagos';
import ToasterActionNotifications from './controllers/visuals/ToasterActions';
import { register } from './serviceWorker';
import NotificationSetup from './controllers/Support/NotificationSetup';
import CartaPorte from './controllers/CartaPorte/CartaPorte';
import { CartaPortePreview } from './controllers/CartaPorte/CartaPorte.preview';
import RegisterFromLinker from './controllers/Registration/RegisterFromLinker';

declare var Conekta: any;
declare global {
  interface Window { dataLayer: any; gtag: any }
}


const stripePromise = loadStripe(stripeKey);

registerLocale('es', es)
setDefaultLocale('es');

function currentUserReducer(state: ProfileQL, action: { type: string, payload: ProfileQL }) {
  switch (action.type) {
    case 'set':
      return Object.assign({}, action.payload);
    case 'refresh':
      return Object.assign({}, action.payload);
    default:
      return Object.assign({}, action.payload);
  }
}

function AppLayout({ children, currentUser }: { children: JSX.Element, currentUser: ProfileQL, }) {
  const locationHook = useLocation();
  const hideMenuOn = () => {
    if (locationHook.pathname.includes('/views/cotizaciones')) {
      return true;
    }
    if (locationHook.pathname.includes('/login')) {
      return true;
    }
    if (locationHook.pathname.includes('/register')) {
      return true;
    }
    if (locationHook.pathname.includes('/recover')) {
      return true;
    }
    return false;
  }
  const renderMenuForLoggedIn = (RenderComponent: JSX.Element) => {
    if (hideMenuOn()) {
      return '';
    }
    return !!currentUser && currentUser.professionalProfile ? RenderComponent : '';
  }

  const fpStatus = !!currentUser && !!currentUser.fiscalpopProfileStatus ? currentUser.fiscalpopProfileStatus : null;
  const hasMessage = !!fpStatus && !(fpStatus.modulusMatch && fpStatus.keyModulus && fpStatus.cerModulus);
  const fpTimingErr = !fpStatus ? false : (!!fpStatus.notBefore && (new Date(fpStatus.notBefore).getTime() > Date.now())) || (!!fpStatus.notAfter && (new Date(fpStatus.notAfter).getTime() < Date.now()));
  /*
  console.log('MAIN user: ', currentUser);
  console.log('MAIN user menuNotLogged - USER: ', (!!currentUser && currentUser.professionalProfile));
  console.log('MAIN user menuNotLogged - hideMenuOn: ', locationHook.pathname, hideMenuOn());
  */
  return (
    <div id="Master" className={`${hasMessage || fpTimingErr ? 'withMessage' : ''} ${(!!currentUser && currentUser.professionalProfile) && !hideMenuOn() ? '' : 'notLogged'} ${currentUser?.professionalProfile?.planType !== 'lite' ? 'withSectionButton' : ''}`}>
      <Route path="/" render={(props) => renderMenuForLoggedIn(<MenuGeneral {...props} currentUser={currentUser} />)} />
      {children}
    </div>
  )
}

function App() {

  const [userFetched, setUserFetched] = useState(false);
  const [currentUser, dispatchCurrentUser] = useReducer(currentUserReducer, null as ProfileQL);


  useEffect(() => {
    const userrSubs = GraphQlClient.getCurrentUser()
      .subscribe(user => {
        console.log('[APP] Setting user from token: ', userFetched, user);
        if (!user) {
          // #1 Invoked user returned NULL, user is confirmed not logged in
          setUserFetched(true);
          dispatchCurrentUser({ type: 'set', payload: user });
        } else if (user.professionalProfile && !userFetched) {
          // #2 Invoked user returned a valid user, user is confirmed logged in
          dispatchCurrentUser({ type: 'set', payload: user });
          setUserFetched(true);
          LogRocket.identify(`${user.professionalProfile._id}`, {
            name: `${user.professionalProfile.companyName}`,
            email: user.professionalProfile.email,
            // >> Add your own custom user variables here, ie:
            linker: user.professionalProfile.linkerId,
            subscriptionType: user.professionalProfile.planType === 'professional' ? 'Professional' : 'Lite',
            canBill: !!user.professionalProfile && !!user.professionalProfile.authToken ? `true` : 'false',
            hasDonePayments: !!user.billing && !!user.billing.lastPaymentId ? `true` : 'false'
          });
          // Empty register starts SW without requesting subscription manager
          register();
        } else if (user.professionalProfile && !!userFetched) {
          // #3 Invoked user returned a valid user as subsequent call, user is confirmed logged in
          dispatchCurrentUser({ type: 'refresh', payload: user });
          setUserFetched(true);
        }
      }, (e) => {
        console.error('[APP] Error fetching user: ', e);
        setUserFetched(true);
      })
    return () => {
      userrSubs.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderLoading = () => {
    return (
      <div id="_AppLoading">
        <Loading display={true} />
      </div>
    )
  }

  const _hasIncomepleteRegister = () => {
    // Caused by Account Spawnner with "Otro RFC"
    return !!currentUser?.professionalProfile && !currentUser?.fiscalpopProfile;
  }

  const renderForIncompleteRegister = (RenderComponent: JSX.Element) => {
    return _hasIncomepleteRegister() ? RenderComponent : <Redirect to="/setup/emisor" />;
  }

  const renderForLoggedOut = (RenderComponent: JSX.Element) => {
    if (_hasIncomepleteRegister()) {
      return <Redirect to="/register-incomplete" />
    }
    return !!currentUser?.professionalProfile ? <Redirect to="/dashboard" /> : RenderComponent;
  }

  const renderForRedirect = (RenderComponent: JSX.Element) => {
    if (_hasIncomepleteRegister()) {
      return <Redirect to="/register-incomplete" />
    }
    return !currentUser?.professionalProfile ? <Redirect to="/login" /> : RenderComponent;
  }

  const renderProfessionalForRedirect = (RenderComponent: JSX.Element) => {
    if (!currentUser?.professionalProfile) {
      // Standard guard for not logged in
      return <Redirect to="/login" />
    }
    const isProfessional = currentUser?.professionalProfile?.planType !== 'lite';
    return !isProfessional ? <Redirect to="/dashboard" /> : RenderComponent;
  }


  // Main render is only called once userFetched is true (Response from Back End is received)
  const renderMain = () => {
    const isProfesional = !!currentUser?.professionalProfile?.planType && currentUser?.professionalProfile?.planType !== 'lite';
    return (
      <Router>
        <ToasterNotifications />

        {
          !isProfesional ? null :
            <CredentialDialogTwoFa />
        }
        {
          !isProfesional ? null :
            <ToasterActionNotifications />
        }

        <PlanWelcomeDialogProfessional currentUser={currentUser} />

        <ErrorsToasts currentUser={currentUser} />
        <AppLayout currentUser={currentUser}>


          <Switch>
            <Route exact path="/" render={(props) => renderForRedirect(
              <Redirect to="/dashboard" {...props} />
            )} />
            {
              /* 
              =================
              MAIN FINANCE ROUTES (Professional & Business)
              */
            }
            <Route exact path="/administration" render={(props) => renderProfessionalForRedirect(
              <Redirect to="/administration/hub" {...props} />
            )} />
            <Route exact path="/administration/hub" render={(props) => renderProfessionalForRedirect(
              <MainHub {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/administration/dashboard" render={(props) => renderProfessionalForRedirect(
              <AdministrationHub {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/administration/bancos" render={(props) => renderProfessionalForRedirect(
              <Bancos {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/administration/movimientos" render={(props) => renderProfessionalForRedirect(
              <Movimientos {...props} currentUser={currentUser} />
            )} />
            {
              /*
            <Route exact path="/administration/movimientos/:accountid" render={(props) => renderProfessionalForRedirect(
              <Movimientos {...props} currentUser={currentUser} />
            )} />
              */
            }
            <Route exact path="/administration/presupuestos/configure" render={(props) => renderProfessionalForRedirect(
              <PresupuestosConfigure {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/administration/presupuestos" render={(props) => renderProfessionalForRedirect(
              <Presupuestos {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/administration/projection" render={(props) => renderProfessionalForRedirect(
              <Projection {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/administration/projection/:projectid" render={(props) => renderProfessionalForRedirect(
              <Projection {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/administration/projection/:projectid/edit" render={(props) => renderProfessionalForRedirect(
              <Projection {...props} currentUser={currentUser} editing={true} />
            )} />

            {
              /*
              =================
              MAIN BILLING ROUTES (Professional & Business)
              */
            }

            <Route exact path="/facturacion/cartaporte" render={(props) => renderProfessionalForRedirect(
              <CartaPorte {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/cartaporte/preview" render={(props) => renderProfessionalForRedirect(
              <CartaPortePreview {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/cartaporte/:argumentid" render={(props) => renderProfessionalForRedirect(
              <CartaPorte {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/cartaporte/preview/:argumentid" render={(props) => renderProfessionalForRedirect(
              <CartaPortePreview {...props} currentUser={currentUser} />
            )} />

            {
              /* 
              =================
              MAIN BILLING ROUTES (LITE)
              */
            }
            <Route exact path="/dashboard" render={(props) => renderForRedirect(
              <Dashboard {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/facturar/preview/billevent" render={(props) => renderForRedirect(
              <PreviewBillEventFactura {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/facturar/preview" render={(props) => renderForRedirect(
              <PreviewFactura {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/facturar/preview/:argumentid" render={(props) => renderForRedirect(
              <PreviewFactura {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/facturar/preview/:argumentid/:eventid" render={(props) => renderForRedirect(
              <PreviewFactura {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/setup/pagos" render={(props) => renderForRedirect(
              <Pagos {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/setup/pagos/:eventid" render={(props) => renderForRedirect(
              <Pagos {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/facturar" render={(props) => renderForRedirect(
              <Facturar {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/facturar/:argumentid" render={(props) => renderForRedirect(
              <Facturar {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/facturar/:argumentid/:eventid" render={(props) => renderForRedirect(
              <Facturar {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/notacredito" render={(props) => renderForRedirect(
              <CreditNote {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/facturacion/notacredito/:eventid" render={(props) => renderForRedirect(
              <CreditNote {...props} currentUser={currentUser} />
            )} />

            <Route exact path="/facturas" render={(props) => renderForRedirect(
              <MisFacturas {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/setup/emisor" render={(props) => renderForRedirect(
              <SetupFiscalPop {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/setup/pagos" render={(props) => renderForRedirect(
              <SetupPagosFiscalPop {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/setup/security" render={(props) => renderForRedirect(
              <SetupSecurityFiscalPop {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/setup/cobros" render={(props) => renderForRedirect(
              <SetupCotizacionFiscalPop {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/support" render={(props) => renderForRedirect(
              <Support {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/setup/notifications" render={(props) => renderForRedirect(
              <NotificationSetup {...props} currentUser={currentUser} />
            )} />

            <Route exact path="/planes" render={(props) => renderForRedirect(
              <Planes {...props} currentUser={currentUser} />
            )} />

            <Route exact path="/catalogos" render={(props) => renderForRedirect(
              <Redirect to="/catalogos/clientes" {...props} />
            )} />
            <Route exact path="/catalogos/clientes" render={(props) => renderForRedirect(
              <ClientsCatalog {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/catalogos/conceptos" render={(props) => renderForRedirect(
              <ConceptosCatalog {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/cotizaciones/lista" render={(props) => renderForRedirect(
              <CotizacionesList {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/cotizacion/new" render={(props) => renderForRedirect(
              <CotizacionGenerator {...props} currentUser={currentUser} />
            )} />
            <Route exact path="/cotizacion/:id" render={(props) => renderForRedirect(
              <CotizacionesEditor {...props} currentUser={currentUser} />
            )} />
            <Route path="/register" render={(props) => <Register {...props} />} />
            <Route path="/login" render={(props) => renderForLoggedOut(<Login {...props} />)} />
            <Route path="/recover" render={(props) => renderForLoggedOut(<RecoverPassword {...props} />)} />
            <Route path="/register-incomplete" render={(props) => renderForIncompleteRegister(<RegisterFromLinker {...props} currentUser={currentUser} />)} />

            <Route path="/reset/:token" render={(props) => <ResetPassword {...props} />} />
            <Route path="/views/cotizaciones/:id" render={(props) =>
              <Elements stripe={stripePromise}>
                <CotizacionClientView currentUser={currentUser} {...props} />
              </Elements>
            } />
            <Route path="/setup" render={(props) => <Redirect to="/setup/emisor" {...props} />} />
            <Route path="*" render={(props) => <Redirect to="/" {...props} />} />
          </Switch>


        </AppLayout>
      </Router>
    )
  }

  return (
    <ApolloProvider client={GraphQlClient.client}>
      {
        userFetched ?
          renderMain() :
          renderLoading()
      }
    </ApolloProvider>
  )
}

export default App;
