
import React, { useState } from 'react';
import SectionTitleBar from '../components/titlebar';
import { BaseReact } from '../../base.model';

import './Dashboard.scss';
import { ProfileQL } from '../../models/Profile';
import NumberDisplay from '../components/numberDisplay';
import { useQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import { ToBillArguments, ToBillEvent } from '../../models/Factura';

import { formatCurrencyStyled } from '../visuals/currency.formatted';
import Button from '../Forms/Button';
import { clearGqlTypename } from '../../utils/formatting';
import { Balances, Cotizacion } from '../../models/Cotizaciones';
import { setSortOrder } from '../../utils/cotizaciones.formatting';
import Pagination from '../Forms/Pagination';
import Loading from '../Animations/loadScreen';

import unifiedMoment from '../../services/unifiedMoment';
import CotizacionesStatusLabel from '../Cotizaciones/Cotizaciones.status';
import { ActivitiesNotification } from '../../models/Activities';
import { conceptoToTotales } from '../../utils/calculate.conceptos';

const moment = unifiedMoment;

interface DashboardProps extends BaseReact {
    currentUser: ProfileQL;
}

const ITEMS_PER_PAGE = 15;

function Dashboard({ history, location, currentUser }: DashboardProps) {
    const [openBillArgs, setOpenBillArgs] = useState<ToBillArguments<ToBillEvent>[]>([]);
    const [openBillEvents, setOpenBillEvents] = useState<ToBillEvent[]>([]);
    const [openBillsLoaded, setOpenBillsLoaded] = useState(false);
    // Cotizaciones
    const [cotizaciones, setCotizaciones] = useState<Cotizacion[]>([]);
    const [cotizacionLoaded, setCotizacionLoaded] = useState(false);
    const [balance, setBalance] = useState<Balances>(null);

    const [stats, setStats] = useState<{ totalBilled: number; taxDue: number }>({ totalBilled: 0, taxDue: 0 })

    // Notifications
    const [notifications, setNotifications] = useState<ActivitiesNotification[]>([])

    // Pagination for facturas
    const [page, setPage] = useState(1)

    useQuery(graphqlSchema.PROFILE.cicleStats, {
        onCompleted: ({ cicleStats }: { cicleStats: { totalBilled: number; taxDue: number } }) => {
            setStats(cicleStats);
            console.log('setStats: ', cicleStats);
        },
        onError: (e) => console.error('Error loading stats: ', e),
        fetchPolicy: 'cache-and-network'
    })

    useQuery(graphqlSchema.FISCALPOP.CFDI.getOpenBillArguments, {
        onCompleted: ({ getOpenBillArguments }: { getOpenBillArguments: ToBillArguments<ToBillEvent>[] }) => {
            console.log('getOpenBillArguments: ', getOpenBillArguments);
            setOpenBillArgs(getOpenBillArguments);
            setOpenBillEvents(getOpenBillArguments.reduce((p, c) => {
                p = p.concat(c.billEvents, c.paymentEvents).sort((a, b) => (a.when || 0) - (b.when || 0));
                return p;
            }, [] as ToBillEvent[]).filter(pE => !pE.isClosed));
            setOpenBillsLoaded(true);
        },
        fetchPolicy: 'cache-and-network'
    })

    useQuery(graphqlSchema.FISCALPOP.COTIZACION.fetchOpenCotizaciones, {
        onCompleted: ({ fetchOpenCotizaciones }: { fetchOpenCotizaciones: Cotizacion[] }) => {
            setCotizaciones(fetchOpenCotizaciones.filter(c => !(c.wasCancelled || c.paid)).sort((a, b) => (setSortOrder(b) - setSortOrder(a))));
            setCotizacionLoaded(true);
            console.log(`fetchCotizaciones: `, fetchOpenCotizaciones);
        },
        onError: (e) => {
            console.error('Error fetching cotizaciones: ', e.graphQLErrors[0]);
        },
        fetchPolicy: 'cache-and-network'
    })

    useQuery(graphqlSchema.PROFILE.NOTIFICATIONS.getNotifications, {
        onCompleted: ({ getNotifications }: { getNotifications: ActivitiesNotification[] }) => {
            console.log(`getNotifications: `, getNotifications);
            setNotifications(getNotifications);
        },
        onError: (e) => {
            console.error('Error fetching getNotifications: ', e.graphQLErrors[0]);
        },
        fetchPolicy: 'cache-and-network'
    })

    useQuery(graphqlSchema.FISCALPOP.STRIPE.fetchStripeBalance, {
        onCompleted: ({ fetchStripeBalance }: { fetchStripeBalance: Balances }) => {
            console.log('fetchStripeBalance: ', fetchStripeBalance);
            if (fetchStripeBalance.balance === null) {
                return;
            }
            setBalance(fetchStripeBalance);
        },
        onError: (e) => {
            console.error('Error fetching StripeBalance: ', e.graphQLErrors[0]);
        },
        fetchPolicy: 'cache-and-network'
    })

    const goToEmitBillEvent = (billEvent: ToBillEvent, parentArgument: ToBillArguments<ToBillEvent>) => {
        return () => {
            history.push({
                pathname: '/facturacion/facturar/preview/billevent',
                state: { billArgument: parentArgument, billEvents: [billEvent] }
            })
        }
    }

    const goToEmitPago = (billEvent: ToBillEvent, parentArgument: ToBillArguments<ToBillEvent>) => {
        return () => {

            history.push({
                pathname: '/facturacion/setup/pagos',
                state: { billArgument: clearGqlTypename(parentArgument), paymentEvents: [clearGqlTypename(billEvent)] }
            })
        }
    }

    const goToNotification = (notification: ActivitiesNotification) => () => {
        if (notification.resourceType === 'cotizaciones') {
            history.push({
                pathname: `/cotizacion/${notification.resourceId}`
            })

        } else if (notification.resourceType === 'facturas') {
            history.push({
                pathname: `/cotizacion/${notification.resourceId}`
            })

        }
    }

    return (
        <div id="Dashboard">
            <SectionTitleBar currentUser={currentUser} title="Resumen de Facturación" />
            <div className="dashboardContent">
                <div className="summaryActivity">
                    <div className="summaryCards">
                        <div className="card mainStatus">
                            <h3>
                                ¡Hola {currentUser.fiscalpopProfile.nombre}!
                            </h3>

                        </div>
                        <div>
                            <h4>Resumen General</h4>
                            <div className="summary">
                                <NumberDisplay title="Monto total facturado" value={stats.totalBilled} coloring="green" icon={'arrow_upward'} />
                                <NumberDisplay title="Impuestos generados" value={stats.taxDue} coloring="red" icon={'arrow_downward'} />
                                {
                                    balance ?
                                        <NumberDisplay title="Ingresos de cotizaciones" value={balance.balanceTransactions.filter(b => b.net > 0).reduce((p, c) => p += c.net, 0) / 100} coloring="orange" icon={'warning'} />
                                        : ''
                                }

                            </div>
                        </div>
                    </div>
                    <div className="activity card">
                        <p className="big">
                            Actividad
                        </p>
                        {
                            !notifications.length ?
                                <div className="_empty">
                                    <p>No hay actividades a atender</p>
                                </div>
                                :
                                <div className="notificationList">
                                    {
                                        notifications.map((notification) => (
                                            <div className="notification" key={notification._id} onClick={goToNotification(notification)}>
                                                <p>
                                                    {notification.level === 'warning' ? <span className="material-icons">
                                                        warning
                                                    </span> : ''}
                                                    {
                                                        notification.label
                                                    }
                                                </p>
                                                <p className="small">
                                                    {moment(notification.createdAt).format('DD/MMM/YYYY')}
                                                </p>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                    </div>
                </div>

                <div className={`tableDashboard  _widthSide`}>
                    <div className="_bills">
                        <h4>Facturas o complementos de pago pendientes</h4>

                        <div className="card table">
                            <div className="cardTitle">
                                <div className="header billPreview">
                                    <p className="cliente">Cliente</p>
                                    <p className="tipo">Tipo de factura</p>
                                    <p className="monto">Monto</p>
                                </div>
                            </div>
                            <div className="cardBody">
                                {
                                    !openBillArgs.length ?
                                        (
                                            openBillsLoaded ?
                                                <div className="noPreviews">
                                                    <p>
                                                        No hay facturas pendientes de revisar
                                                    </p>
                                                </div>
                                                :
                                                <Loading svgHeight={'40px'} display={true} relativePos={true} />
                                        )
                                        :
                                        ''
                                }
                                {
                                    /*
                                        BillEvent
                                    */
                                    openBillEvents.filter((_, i) => (i >= (ITEMS_PER_PAGE * (page - 1)) && (i < (ITEMS_PER_PAGE * page)))).map((billEv, i) => {
                                        const parentArgument = openBillArgs.find(bA => bA._id === billEv.argumentId);
                                        if (billEv.CFDI) {

                                            const { total } = conceptoToTotales(billEv.CFDI.conceptos)
                                            return (
                                                <div className="billPreview" key={i}>
                                                    <div className="_receptor">
                                                        <p className="_name">{billEv.CFDI.receptor.nombre}</p>
                                                        <p className="text3">{billEv.CFDI.conceptos.length > 1 ? `${billEv.CFDI.conceptos.length} conceptos` : billEv.CFDI.conceptos[0].descripcion}</p>

                                                    </div>
                                                    {
                                                        parentArgument.upfront ?
                                                            // BillEvent with upfront payment are Liquidación types
                                                            // >> BillEvent.when only exists on PaymentEvents
                                                            <div className="_billInfo">
                                                                <p className="_bill">Total a pagar</p>
                                                                <p className={`date `}>adelanto {moment(parentArgument.createdAt).fromNow()}</p>
                                                            </div>
                                                            :
                                                            <div className="_billInfo">
                                                                <p className="_bill">Factura</p>
                                                                <p className={`date `}>{parentArgument.createdAt ? moment(parentArgument.createdAt).fromNow() : ''}</p>
                                                            </div>
                                                    }
                                                    <div className="_monto">
                                                        <p className="text2">{formatCurrencyStyled(total)}</p>
                                                        {
                                                            // If Bill Event is stamped and canceled, we can re-emit it
                                                            // >> If Bill Event is stamped and not canceled, show no buttom
                                                            // >> Normally, only open events are loaded, so we shouldn't see
                                                            billEv.uuid ?
                                                                (billEv.wasCanceled ?
                                                                    <Button secondary={true} handleClick={goToEmitBillEvent(billEv, parentArgument)}>
                                                                        <span>Re-Emitir</span>
                                                                    </Button>
                                                                    : '') :
                                                                <Button primary={true} handleClick={goToEmitBillEvent(billEv, parentArgument)}>
                                                                    <span>Emitir</span>
                                                                </Button>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else if (billEv.paymentCFDI) {
                                            // Payment event
                                            // >> ELSE-IF is explicit as there was a bug when canceling Credit Ntoes reopened bills 
                                            const isOld = billEv.when ? billEv.when < Date.now() : false;

                                            return (
                                                <div className="billPreview payment" key={i}>
                                                    <div className="_receptor">
                                                        <p className="_name">{billEv.paymentCFDI.receptor.nombre}</p>
                                                        <p className="text3">Pago de factura</p>
                                                    </div>
                                                    <div className="_billInfo">
                                                        <p className="_payment">Pago parcialidad <span className="small">{billEv.paymentNum} de {parentArgument.paymentEvents.length}</span></p>
                                                        {
                                                            billEv.wasCanceled ?
                                                                <p className="warning">
                                                                    Cancelada anteriormente
                                                                </p>
                                                                :
                                                                (isOld ?
                                                                    <p className={`date old`}><i className="material-icons">warning</i> {moment(billEv.when).fromNow()}</p>
                                                                    :
                                                                    <p className={`date `}>{billEv.when ? `esperado ${moment(billEv.when).fromNow()}` : '- -'}</p>)
                                                        }
                                                    </div>

                                                    <div className="_monto">
                                                        <p className="text2">{formatCurrencyStyled(billEv.paymentCFDI.pagos[0].monto)}</p>
                                                        {
                                                            billEv.uuid ?
                                                                (billEv.wasCanceled ?
                                                                    <Button secondary={true} handleClick={goToEmitPago(billEv, parentArgument)}>
                                                                        <span>Re-Emitir</span>
                                                                    </Button>
                                                                    : '') :
                                                                <Button primary={true} handleClick={goToEmitPago(billEv, parentArgument)}>
                                                                    <span>Emitir</span>
                                                                </Button>
                                                        }
                                                    </div>
                                                </div>

                                            )
                                        }
                                        return null
                                    })
                                }
                                <Pagination page={page} pages={Math.ceil(openBillEvents.length / ITEMS_PER_PAGE)} onPage={setPage} />
                            </div>
                        </div>
                    </div>
                    <div className="_quotes">
                        <h4>Cotizaciones en proceso</h4>
                        <div className="card table">
                            <div className="cardTitle">
                                <div className="header cotizacionPreview">
                                    <p>Cliente</p>
                                    <p className="monto">Monto</p>
                                </div>
                            </div>
                            <div className="cardBody">
                                {
                                    !cotizaciones.length ?
                                        (
                                            cotizacionLoaded ?

                                                <div className="noPreviews">
                                                    <p>
                                                        No hay Cotizaciones pendientes revisar
                                                    </p>
                                                </div>
                                                :
                                                <Loading svgHeight={'40px'} display={true} relativePos={true} />
                                        )
                                        :
                                        ''
                                }
                                {
                                    cotizaciones.map((cot, i) => {
                                        return (
                                            <div className="cotizacionPreview" key={i}>
                                                <div className="_receptor">
                                                    <p className="_name">{cot.name}</p>
                                                    <p className="date"> {moment(cot.createdAt).format('DD / MMM / YYYY')}</p>
                                                </div>
                                                <div className="_monto">
                                                    <p className="text2">{formatCurrencyStyled(cot.total)}</p>
                                                    <CotizacionesStatusLabel cotizacion={cot} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;