import React from 'react';

interface SvgProps {
    width: number;
    height: number;
    color: string;
}

function CheckSvg({ height, width, color }: SvgProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
            <path d="M19 9.17715V10.0051C18.9989 11.9459 18.3704 13.8344 17.2084 15.3888C16.0463 16.9432 14.413 18.0804 12.5518 18.6307C10.6907 19.1809 8.70153 19.1149 6.88102 18.4423C5.06051 17.7697 3.50619 16.5266 2.44986 14.8985C1.39354 13.2704 0.891812 11.3444 1.01951 9.40783C1.14721 7.47126 1.89749 5.62784 3.15845 4.15252C4.41942 2.67719 6.1235 1.649 8.01657 1.22128C9.90963 0.79357 11.8902 0.989255 13.663 1.77915" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.9998 2.80518L9.9998 11.8142L7.2998 9.11417" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CheckSvg;