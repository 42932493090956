import React, { useEffect, useState } from 'react';

import './NotificationSetup.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';
import SetupMenu from '../Setup/Setup.menu';
import AccountAvatarHolder from '../components/accountAvatarHolder';
import Checkbox from '../Forms/Checkbox';
import Modal from 'react-modal';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import { ProfessionalWebPushNotification } from '../../models/Notifications';
import { register } from '../../serviceWorker';
import Button from '../Forms/Button';
import AccountAvatarHolderMobile from '../components/accountAvatarHolder.mobile';


interface SupportProps extends BaseReact {
    currentUser: ProfileQL;
}


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '80vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

function NotificationSetup({ history, location, currentUser }: SupportProps) {


    const [isOpen, setIsOpen] = useState(false);
    const [notConfig, setNotificationConfiguration] = useState<ProfessionalWebPushNotification>(null)

    const [activeNotifications, setActiveNotifications] = useState(window.Notification.permission)

    const [fetchWebServiceNotify] = useLazyQuery(graphqlSchema.PROFILE.NOTIFICATIONS.getWebServiceNotification, {
        onCompleted: ({ getWebServiceNotification }: { getWebServiceNotification: ProfessionalWebPushNotification }) => {
            console.log(`<NotificationSetup> getWebServiceNotification:`, getWebServiceNotification);
            if (!getWebServiceNotification) {
                setNotificationConfiguration({
                    _id: null,
                    accepts: true,
                    label: '',
                    onCredentialExpires: true,
                    onCsdExpires: true,
                    onCotizationPaid: true,
                })
            } else {
                setNotificationConfiguration(getWebServiceNotification)
            }
        },
        onError: (error) => {
            console.log('Error getting Notification WS config')
        },
        fetchPolicy: 'network-only'
    })

    const [modifyWebSNotification] = useMutation(graphqlSchema.PROFILE.NOTIFICATIONS.modifyWebServiceNotification, {
        onCompleted: ({ modifyWebServiceNotification }: { modifyWebServiceNotification: ProfessionalWebPushNotification }) => {
            console.log('Updated Notification config: ', modifyWebServiceNotification)
            setNotificationConfiguration(modifyWebServiceNotification)
        },
        onError: (err) => {
            console.log(`<NotificationSetup> Error updating Notification Configuration: `, err);
        }
    })

    const [saveSubscription] = useMutation(graphqlSchema.PROFILE.NOTIFICATIONS.saveServiceWorkerSubscription, {
        onCompleted: ({ saveServiceWorkerSubscription }: { saveServiceWorkerSubscription: ProfessionalWebPushNotification }) => {
            console.log('Save Notification subscription: ', saveServiceWorkerSubscription)
            setNotificationConfiguration(saveServiceWorkerSubscription)
        },
        onError: (err) => {
            console.log(`<NotificationSetup> Error saving subscription: `, err);
        }
    })

    useEffect(() => {
        fetchWebServiceNotify();
    }, [fetchWebServiceNotify])


    const toggleBrowserNotifications = () => {
        if (window.Notification.permission === 'denied') {
            // This is a hard lock on notifications, nothing can be done here;
            setActiveNotifications(window.Notification.permission);
            return;
        }
        else if (window.Notification.permission === 'default') {
            setActiveNotifications(window.Notification.permission);
            setIsOpen(true);
        }
        else if (window.Notification.permission === 'granted') {
            // Already active, nothing to do here (Must have been previously registered)
            setActiveNotifications(window.Notification.permission);
            return;
        }
    }

    const modifyNotifyConfig = (property: 'accepts' | 'onCredentialExpires' | 'onCsdExpires' | 'onCotizationPaid') => (bool: boolean) => {
        const config: ProfessionalWebPushNotification = JSON.parse(JSON.stringify(notConfig));
        config[property] = bool;
        setNotificationConfiguration(config); // Eager update
        modifyWebSNotification({
            variables: {
                config: {
                    onCsdExpires: config.onCsdExpires,
                    onCotizationPaid: config.onCotizationPaid,
                    onCredentialExpires: config.onCredentialExpires,
                }
            }
        })
    }

    const requestPermissionEnable = () => {
        window.Notification.requestPermission().then((permission) => {
            setActiveNotifications(permission);
            if (permission === 'granted') {
                setActiveNotifications('granted');
                // If notifications are granted, register service worker
                register({
                    onSubscription: (registration, subscription) => {
                        console.log(`<NotificationSetup> registration: `, registration)
                        console.log(`<NotificationSetup> subscription: `, subscription)
                        const payload = {
                            endpoint: subscription.endpoint,
                            keysAuth: subscription.keys.auth,
                            keysPdh: subscription.keys.p256dh,
                        }
                        console.log(`<NotificationSetup> payload: `, payload)
                        saveSubscription({
                            variables: {
                                subscription: payload
                            }
                        })
                    }
                });

            }
            setIsOpen(false);
        }).catch(e => {
            console.error("Error on granting Notificacion permissions: ", e);
            setIsOpen(false);
        })
    }

    return (
        <div id="NotificationSetup">
            <SectionTitleBar currentUser={currentUser} title="Configurar notificaciónes" />
            <div className="setupContent">
                <SetupMenu />
                <div className="card profile">
                    <AccountAvatarHolder currentUser={currentUser} />
                    <AccountAvatarHolderMobile currentUser={currentUser} />
                    <div className="supportContent">
                        <div className='notificationConfig'>
                            <div className='browserActive'>
                                {
                                    activeNotifications === 'default' ?
                                        <Button
                                            primary={true}
                                            handleClick={toggleBrowserNotifications}
                                        >
                                            <span>
                                                Activar notificaciones en este browser
                                            </span>
                                        </Button>
                                        : null
                                }
                                {
                                    activeNotifications === 'denied' ?
                                        <Button
                                            tertiary={true}
                                            disabled={true}
                                        >
                                            <span>
                                                Browser no puede recibir notificaciones
                                            </span>
                                        </Button>
                                        : null
                                }
                                {
                                    activeNotifications === 'granted' ?
                                        <Button
                                            tertiary={true}
                                            disabled={true}
                                        >
                                            <span>
                                                Browser puede recibir notificaciones
                                            </span>
                                        </Button>
                                        : null
                                }
                            </div>
                            <div className='checkboxes'>
                                <div className='masterCheckbox'>
                                    <Checkbox
                                        label='Activar generales notificaciones'
                                        value={notConfig?.accepts}
                                        disabled={activeNotifications !== 'granted'}
                                        onChange={modifyNotifyConfig('accepts')}
                                    />
                                </div>
                                <Checkbox
                                    label='Notificar CSD por expirar'
                                    value={notConfig?.onCsdExpires}
                                    disabled={!notConfig?.accepts || activeNotifications !== 'granted'}
                                    onChange={modifyNotifyConfig('onCsdExpires')}

                                />
                                <Checkbox
                                    label='Notificar sobre cotizaciones pagadas'
                                    value={notConfig?.onCotizationPaid}
                                    disabled={!notConfig?.accepts || activeNotifications !== 'granted'}
                                    onChange={modifyNotifyConfig('onCotizationPaid')}
                                />
                                <Checkbox
                                    label='Notificar cuando renovar conexión bancaria'
                                    value={notConfig?.onCredentialExpires}
                                    disabled={!notConfig?.accepts || activeNotifications !== 'granted'}
                                    onChange={modifyNotifyConfig('onCredentialExpires')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isOpen}
                onRequestClose={requestPermissionEnable}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Agregar nuevo concepto"
            >
                <div className='card table' id="newPermissionDialog">
                    <div className="cardTitle withClose">
                        <h4>Activar Notificaciones</h4>
                        <i className="material-icons" onClick={requestPermissionEnable}>
                            close
                        </i>
                    </div>
                    <div className='cardBody'>
                        <p>
                            Se solicitará permiso para recibir notificaciones en este browser.
                        </p>
                        <p>
                            <b>Al aceptar permisos</b> recibirás solo las notificaciones importantes.
                        </p>
                        <p>
                            No recibirás notificaciones que no requieran acciones de tu parte.
                        </p>
                        <img src="https://fiscalpop.sfo2.digitaloceanspaces.com/assets/notificationRequest.png" alt="Ejemplo permisos" />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default NotificationSetup;