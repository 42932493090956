import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../../base.model';
import { ProfileQL } from '../../../models/Profile';
import SectionTitleBar from '../../components/titlebar';
import Button from '../../Forms/Button';

import SitesDialog from './SitesDialog/SitesDialog';

import './Bancos.scss'
import { CredentialResponse, CredentialUpdateSubscription, PaybookCredentialResponse } from '../../../models/Administracion/Bank.Credentials';
import { useLazyQuery, useQuery, useSubscription } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../services/graphql.schema';
import { PaybookOrganization } from '../../../models/Administracion/Banks';
import { PaybookAccount } from '../../../models/Administracion/Bank.Accounts';
import CredentialEntry from './Credentials/Credential.entry';
import NumberDisplay from '../../components/numberDisplay';
import BarAndLineChart from '../../Graphs/BarAndLine/BarAndLine.chart';
import moment from 'moment';
import { formatCurrencyStyled } from '../../visuals/currency.formatted';
import { numberToCurrencyString } from '../../../services/formatting';
import { BankMovementSummary, TransactionUpdateSubscription } from '../../../models/Administracion/Bank.Transactions';
import ExpenseByCategory from './ExpenseCategory/ExpenseCategory';
import AccountSummary from './AccountSummary/AccountSummary';
import { NavLink } from 'react-router-dom';
import useWindowResize from '../../Hooks/useWindowResize';
import { formatLineGroupsFromBankSummaries } from '../helpers/lineGroups.formatting';
import { BankBudgets } from '../../../models/Administracion/Bank.Budgets';
import unifiedMoment from '../../../services/unifiedMoment';

interface PagosProps extends BaseReact {
    currentUser: ProfileQL;
}

function Bancos({ currentUser, location, history }: PagosProps) {
    const { windowWidth } = useWindowResize()

    const [openDialog, _onOpenDialog] = useState(false);
    // For Avatar and logo cases
    const [organizations, setOrganizations] = useState<PaybookOrganization[]>([])
    const [credentials, setCredentials] = useState<PaybookCredentialResponse[]>([])
    const [accounts, setAccounts] = useState<PaybookAccount[]>([])

    const [preSiteId, setPreSiteId] = useState('');
    const [preOrganizationId, setOrganizationId] = useState('');

    const [summariesLoaded, setSummariesLoaded] = useState(false);
    const [summaries, setSummaries] = useState<BankMovementSummary[]>([]);
    const [summaryGlobalOrigin, setSummaryGlobal] = useState<BankMovementSummary[]>([]);
    const [globalPeriod, setGlobalPeriod] = useState<BankMovementSummary>(null)
    const [lastGlobalPeriod, setLastGlobalPeriod] = useState<BankMovementSummary>(null)
    const [monthlyBudget, setMontlyBudget] = useState<number>(0)

    useQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getOrganizations, {
        onCompleted: ({ getOrganizations }: { getOrganizations: PaybookOrganization[] }) => {
            console.log(`(Bancos) <getOrganizations> `, getOrganizations);
            setOrganizations(getOrganizations)
        }
    })

    const [getBankCredentials] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankCredentials, {
        onCompleted: ({ getBankCredentials }: { getBankCredentials: PaybookCredentialResponse[] }) => {
            console.log(`(Bancos) <getBankCredentials> `, getBankCredentials);
            setCredentials(getBankCredentials)
        },
        fetchPolicy: 'cache-and-network'
    })
    const [getBankAccounts] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankAccounts, {
        onCompleted: ({ getBankAccounts }: { getBankAccounts: PaybookAccount[] }) => {
            console.log(`(Bancos) <getBankAccounts> `, getBankAccounts);
            setAccounts(getBankAccounts);

        },
        fetchPolicy: 'cache-and-network'
    })

    const [getMovementsSummary] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankMovementsSummary, {
        onCompleted: ({ getBankMovementsSummary }: { getBankMovementsSummary: BankMovementSummary[] }) => {
            console.log(`(Bancos) <getBankMovementsSummary> `, getBankMovementsSummary);
            const _getBankMovementsSummary: BankMovementSummary[] = JSON.parse(JSON.stringify(getBankMovementsSummary))
            setSummaries(_getBankMovementsSummary)

            const _summaryGlobal = _getBankMovementsSummary.reduce((p, c) => {
                const exists = p.find(e => e.date === c.date);
                if (exists) {
                    exists.expense += c.expense; // always negative
                    exists.income += c.income;
                    // NOTE: Remove internal movements from global summary
                    // >> This way, global summary doesn't reflect internal movements
                    if(c.internalExpense) {
                        exists.expense -= c.internalExpense;
                    }
                    if(c.internalIncome) {
                        exists.income -= c.internalIncome;
                    }
                    // -------------------------------------------------------
                    if (c.expenseByCategory?.length) {
                        c.expenseByCategory.forEach((eBC) => {
                            const existsCat = exists.expenseByCategory.find(eeBC => eeBC.budgetSubcategory === eBC.budgetSubcategory)
                            if (existsCat) {
                                existsCat.totalExpense += eBC.totalExpense;
                            } else {
                                exists.expenseByCategory.push(eBC)
                            }
                        })
                    }
                    if (c.expenseByProject?.length) {
                        c.expenseByProject.forEach((eBP) => {
                            const existsProj = exists.expenseByProject.find(eeBP => eeBP.projectId === eBP.projectId)
                            if (existsProj) {
                                existsProj.totalExpense += eBP.totalExpense;
                            } else {
                                exists.expenseByProject.push(eBP)
                            }
                        })
                    }
                } else {
                    // If the pushed object is not cloned, referenced object will be modified by reference
                    const _c = JSON.parse(JSON.stringify(c))
                    if(_c.internalExpense) {
                        _c.expense -= _c.internalExpense;
                    }
                    if(_c.internalIncome) {
                        _c.income -= _c.internalIncome;
                    }
                    p.push(_c)
                }
                return p;
            }, [] as BankMovementSummary[]).sort((a, b) => b.date - a.date)


            const globalPeriods = _summaryGlobal.filter(sG => sG.date >= moment().startOf('month').valueOf());
            const _globalPeriod = globalPeriods.reduce((exists, c) => {
                exists.expense += c.expense;
                exists.income += c.income;
                if (c.expenseByCategory?.length) {
                    c.expenseByCategory.forEach((eBC) => {
                        const existsCat = exists.expenseByCategory.find(eeBC => eeBC.budgetSubcategory === eBC.budgetSubcategory)
                        if (existsCat) {
                            existsCat.totalExpense += eBC.totalExpense;
                        } else {
                            exists.expenseByCategory.push(eBC)
                        }
                    })
                }
                if (c.expenseByProject?.length) {
                    c.expenseByProject.forEach((eBP) => {
                        const existsProj = exists.expenseByProject.find(eeBP => eeBP.projectId === eBP.projectId)
                        if (existsProj) {
                            existsProj.totalExpense += eBP.totalExpense;
                        } else {
                            exists.expenseByProject.push(eBP)
                        }
                    })
                }
                return exists;
            }, { date: globalPeriods[0]?.date || null, expense: 0, expenseByCategory: [], expenseByProject: [], id_account: globalPeriods[0]?.id_account || null, income: 0 } as BankMovementSummary)

            const lastGlobalPeriods = _summaryGlobal.filter(sG => sG.date < moment().startOf('month').valueOf() && sG.date >= moment().subtract(1, 'month').startOf('month').valueOf());
            const _lastglobalPeriod = lastGlobalPeriods.reduce((exists, c) => {
                exists.expense += c.expense;
                exists.income += c.income;
                if (c.expenseByCategory?.length) {
                    c.expenseByCategory.forEach((eBC) => {
                        const existsCat = exists.expenseByCategory.find(eeBC => eeBC.budgetSubcategory === eBC.budgetSubcategory)
                        if (existsCat) {
                            existsCat.totalExpense += eBC.totalExpense;
                        } else {
                            exists.expenseByCategory.push(eBC)
                        }
                    })
                }
                if (c.expenseByProject?.length) {
                    c.expenseByProject.forEach((eBP) => {
                        const existsProj = exists.expenseByProject.find(eeBP => eeBP.projectId === eBP.projectId)
                        if (existsProj) {
                            existsProj.totalExpense += eBP.totalExpense;
                        } else {
                            exists.expenseByProject.push(eBP)
                        }
                    })
                }
                return exists;
            }, { date: lastGlobalPeriods[0]?.date || null, expense: 0, expenseByCategory: [], expenseByProject: [], id_account: lastGlobalPeriods[0]?.id_account || null, income: 0 } as BankMovementSummary)

            setGlobalPeriod(_globalPeriod)
            setLastGlobalPeriod(_lastglobalPeriod)

            setSummaryGlobal(_summaryGlobal);
            setSummariesLoaded(true);

            console.log('Global Period: ', _globalPeriod);
            console.log('Last Global Period: ', _lastglobalPeriod);
            console.log('summaryGlobal: ', _summaryGlobal);
        },
        fetchPolicy: 'cache-and-network'
    })

    const [fetchBudgets] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBudgets, {
        onCompleted: ({ getBudgets }: { getBudgets: BankBudgets[] }) => {
            console.log(`getBudgets: `, getBudgets);
            setMontlyBudget(getBudgets.reduce((p, c) => p += c.goal, 0))
        },
        fetchPolicy: 'cache-and-network'
    })


    useSubscription<TransactionUpdateSubscription>(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.bankTransactionsUpdates, {
        onSubscriptionData: ({ subscriptionData }) => {
            // Check if re-call is actually needed
            const bankTransactionsUpdates = subscriptionData.data.bankTransactionsUpdates;
            console.log(`[Bancos] <bankTransactionsUpdates> bankTransactionsUpdates`, bankTransactionsUpdates);
            if (bankTransactionsUpdates.state === 'completed') {
                // If status is completed, re-work account and transaction pulls
                getBankAccounts()
                getMovementsSummary({
                    variables: {
                        start: moment().subtract(5, 'months').startOf('month').valueOf(),
                        groupBy: 'month'
                    }
                })
            }
        }
    })

    useSubscription<CredentialUpdateSubscription>(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.bankCredentialUpdates, {
        onSubscriptionData: ({ subscriptionData }) => {
            // Check if re-call is actually needed
            const bankCredentialUpdates = subscriptionData.data.bankCredentialUpdates;
            console.log(`[Bancos] <bankCredentialUpdates> bankCredentialUpdates`, bankCredentialUpdates);
            const lastState = bankCredentialUpdates.status[bankCredentialUpdates.status.length - 1]
            const _credentials: PaybookCredentialResponse[] = JSON.parse(JSON.stringify(credentials));
            const exists = _credentials.find(cred => cred.id_credential === bankCredentialUpdates.id_credential)
            if (!exists && (lastState.code < 200 || lastState.code > 300)) {
                // Not a successful call yet, only creds
                return getBankCredentials()
            } else if (!exists) {
                // If it doesn't exist, update creds
                console.log(`[Bancos] <bankCredentialUpdates> credentials not yet exists: `, !exists);
                return getBankCredentials()
            } else if (exists && (lastState.code > 199 && lastState.code < 300)) {
                // Successful change on existing credentials, update
                getBankAccounts()
            }
            if (lastState.code === 411) {
                // 411 is the only state which will trigger credential refresh, because "dt_ready" is needed
                getBankAccounts()
            } else {
                // If credential already exists, just update state of changed cred.
                exists.code = lastState.code;
                exists.dt_execute = Date.now()
                setCredentials(_credentials);
            }
        }
    });

    useEffect(() => {
        getBankCredentials()
        getBankAccounts()
        fetchBudgets({
            variables: {
                monthTs: unifiedMoment().startOf('month').valueOf(),
            }
        })
        getMovementsSummary({
            variables: {
                start: moment().subtract(5, 'months').startOf('month').valueOf(),
                groupBy: 'month'
            }
        })
    }, [getBankCredentials, getBankAccounts, getMovementsSummary, fetchBudgets])

    const onCloseDialog = (credentialsSubmitted: CredentialResponse) => {
        console.log(`<onCloseDialog> `, credentialsSubmitted);
        _onOpenDialog(false);
        setPreSiteId('')
        setOrganizationId('')
        if (!credentialsSubmitted) {
            return;
        }
        // Credentials have been submitted, preventive state change for Cards that 'retry' credentials
        const _credentials: PaybookCredentialResponse[] = JSON.parse(JSON.stringify(credentials));
        const exists = _credentials.find(cred => cred.id_credential === credentialsSubmitted.id_credential)
        if (exists) {
            exists.code = 100;
            setCredentials(_credentials);
        }

        getBankCredentials()
    }

    const onOpenDialog = () => {
        _onOpenDialog(true);
    }

    const onRetryCredentials = (siteId: string, organizationId: string) => {
        setPreSiteId(siteId)
        setOrganizationId(organizationId)
        _onOpenDialog(true);
    }

    const accountBalanceGlobal = accounts.reduce((p, c) => p += c.balance, 0)

    // const totalBudbget = ((globalPeriod?.expenseByCategory || []).reduce((p, c) => p += c.totalBudgetted, 0))
    const totalBudbget = monthlyBudget
    const budgetExceeded = !totalBudbget ? 1 : Math.abs((globalPeriod?.expense || 0) - ((globalPeriod?.expenseByProject || []).reduce((p, c) => p += c.totalExpense, 0))) / totalBudbget;

    const renderGlobalSummaryEmptyState = () => {
        return (
            <div className='chartSectionEmpty'>
                <div className='_text'>
                    <p>
                        ¡Agrega una cuenta de banco para ver tu gráfica de gastos!
                    </p>
                    <p className='small'>
                        Además de monitorear tus gastos, también podrás ver y categorizar tus <NavLink to="/administration/movimientos">Movimientos</NavLink>.
                    </p>
                </div>
            </div>
        )
    }

    const renderSubcatEmptyState = () => {
        return (
            <div className='subcatSectionEmpty'>
                <p>
                    ¡Agrega una cuenta para ver tus gastos mensuales!
                </p>
            </div>
        )
    }


    const summaryGlobal = windowWidth >= 500 ? summaryGlobalOrigin : summaryGlobalOrigin.filter((_, i) => i < 4);
    const globalSummaryBalanceLine = formatLineGroupsFromBankSummaries(summaryGlobal, accountBalanceGlobal)
    const lastPeriodAccountBalance = globalSummaryBalanceLine.length >= 2 ? globalSummaryBalanceLine[globalSummaryBalanceLine.length - 2]?.y || 0 : 0;

    return (
        <div id="Bancos">
            <SectionTitleBar currentUser={currentUser} title={`Actividad Bancaria`} />
            <div className="bancosContent">
                <div className='bancosMain'>
                    <div>
                        <h4>Cuentas Conectadas</h4>
                        <div className="bankLinkContainerSection">
                            <div className='accountList'>
                                {
                                    !!organizations.length && credentials.map((credential, index) => {
                                        const organization = organizations.find(o => o.id_site_organization === credential.id_site_organization)
                                        const credAccounts = accounts.filter(a => a.id_credential === credential.id_credential);
                                        return (
                                            <CredentialEntry
                                                key={credential.id_credential}
                                                credential={credential}
                                                organization={organization}
                                                accounts={credAccounts}
                                                index={index}
                                                onRetryCredentials={onRetryCredentials}
                                            />
                                        )
                                    })
                                }

                            </div>
                            <Button primary={true} handleClick={onOpenDialog}>
                                <div className='_iconButton'>
                                    <i className="material-icons">
                                        add
                                    </i>
                                    <span>
                                        Agregar Banco
                                    </span>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div>
                        <h4>Resumen</h4>
                        <div className='bankSummaryContainerSection'>

                            <div className='card noShadow'>
                                <div className='titleDate'>
                                    <h4>Resumen total del periodo</h4>
                                    <div className='dates'></div>
                                </div>
                                <div className='periodSummary'>
                                    <NumberDisplay title="Ingreso del periodo" value={globalPeriod?.income || 0} coloring={(globalPeriod?.income || 0) > (lastGlobalPeriod?.income || 0) ? "green" : 'red'} icon={(globalPeriod?.income || 0) > (lastGlobalPeriod?.income || 0) ? 'arrow_upward' : 'arrow_downward'} />
                                    <NumberDisplay title="Gastos del periodo" value={globalPeriod?.expense || 0} coloring={(globalPeriod?.expense || 0) > (lastGlobalPeriod?.expense || 0) ? 'green' : "red"} icon={(globalPeriod?.expense || 0) > (lastGlobalPeriod?.expense || 0) ? 'arrow_downward' : 'arrow_upward'} />
                                    <NumberDisplay title="Balance del periodo" value={accountBalanceGlobal} coloring={accountBalanceGlobal < lastPeriodAccountBalance ? "red" : "green"} icon={accountBalanceGlobal < lastPeriodAccountBalance ? 'arrow_downward' : 'arrow_upward'} />
                                </div>
                                <div className='chartSection'>
                                    {
                                        !summaryGlobal.length && summariesLoaded ?
                                            renderGlobalSummaryEmptyState()
                                            : null
                                    }
                                    <BarAndLineChart margin={{ left: 40, top: 20, bottom: 20, right: 40 }}
                                        barGroups={[
                                            {
                                                color: 'rgba(213, 251, 213, 0.75)',
                                                values: summaryGlobal.map((sG) => ({
                                                    x: sG.date,
                                                    y: sG.income
                                                }))
                                            },
                                            {
                                                color: '#FBD5D5',
                                                values: summaryGlobal.map((sG) => ({
                                                    x: sG.date,
                                                    y: Math.abs(sG.expense)
                                                }))
                                            },
                                        ]}
                                        lineGroups={[
                                            {
                                                color: `#AC2E78`,
                                                values: globalSummaryBalanceLine
                                            }
                                        ]}
                                        xAxisFormatting={(d: number) => moment(d).format('MMMM')}
                                        xAxisTicks={'from-data'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='totalExpenseCategorySection'>
                            <ExpenseByCategory globalPeriod={globalPeriod} />
                            {
                                !summaryGlobal && summariesLoaded ?
                                    renderSubcatEmptyState() :
                                    <div>
                                        <h4>Gastos del mes totales</h4>
                                        <div className='card noShadow'>
                                            <div className='_numberDisplay'>
                                                <div className='text'>
                                                    <p>Gastos Totales</p>
                                                    <h2>
                                                        {formatCurrencyStyled(Math.abs(globalPeriod?.expense || 0))}
                                                    </h2>
                                                </div>
                                                {
                                                    (globalPeriod?.expense || 0) > (lastGlobalPeriod?.expense || 0) ?
                                                        <div className='icon green'>
                                                            <span className="material-icons">arrow_downward</span>
                                                        </div>

                                                        :
                                                        <div className='icon red'>
                                                            <span className="material-icons">arrow_upward</span>
                                                        </div>
                                                }
                                            </div>
                                            <div className='divider' />
                                            <div className='expenseGroup'>
                                                <p>Personal / Fijos</p>
                                                <p className='currency'>${numberToCurrencyString(Math.abs((globalPeriod?.expense || 0) - ((globalPeriod?.expenseByProject || []).reduce((p, c) => p += c.totalExpense, 0))))}</p>
                                                {
                                                    !totalBudbget ?
                                                        <p className={`tag red`}>
                                                            Sin Presupuesto
                                                        </p>
                                                        :
                                                        (budgetExceeded > 1 ?
                                                            <p className={`tag red`}>
                                                                {Math.round((budgetExceeded - 1) * 1000) / 10}% Excedido
                                                            </p>
                                                            :
                                                            <p className={`tag green`}>
                                                                En presupuesto
                                                            </p>
                                                        )
                                                }
                                            </div>
                                            <div className='divider light' />
                                            <div className='expenseGroup'>
                                                <p>Proyectos</p>
                                                <p className='currency'>${numberToCurrencyString(Math.abs((globalPeriod?.expenseByProject || []).reduce((p, c) => p += c.totalExpense, 0)))}</p>
                                                <p className={`tag green`}>
                                                    En presupuesto
                                                </p>
                                            </div>
                                            <div className='expenseByProjectGroup'>
                                                {
                                                    (globalPeriod?.expenseByProject || []).map((eBP) => {
                                                        return (
                                                            <div className='expenseProyect' key={eBP.projectId}>
                                                                <p>{eBP.project}</p>
                                                                <p className='currency'>${numberToCurrencyString(Math.abs(eBP.totalExpense))}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                {
                    accounts.map((acc) => (
                        <AccountSummary account={acc} key={acc.id_account} accountSummaries={summaries.filter(sm => sm.id_account === acc.id_account)} />
                    ))
                }
            </div>
            <SitesDialog
                openDialog={openDialog}
                onClose={onCloseDialog}
                presetSiteId={preSiteId}
                presetOrganizationId={preOrganizationId}
            />
        </div >
    )
}

export default Bancos;