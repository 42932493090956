export const post = (url: string, data: any = {}) => {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then(async (responsePromise) => {
            if (responsePromise.ok) {
                return responsePromise.json();
            } else {
                const err = await responsePromise.text();
                throw new Error(err);
            }
        });
    // Use external Catch!
}
