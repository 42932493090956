

import React, { useEffect, useState } from 'react';
import { numberToCurrencyString } from '../../../../services/formatting';
import Button from '../../../Forms/Button';

import { RouteComponentProps } from "react-router-dom";
import { CATEGORIES_LIST } from '../../helpers/categories.list';

import './Presupuestos.DetailsList.scss'
import BaseDonutChart from '../../../Graphs/Donut/Donut.chart';
import { BankBudgets } from '../../../../models/Administracion/Bank.Budgets';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import ToolTip from '../../../visuals/tooltip';
import PercentBar from '../../../components/barPercent';
import { formatCurrencyStyled } from '../../../visuals/currency.formatted';

interface PresupuestosDetailsListProps {
    history: RouteComponentProps['history'];
    budgets: BankBudgets[]
    banksSummary: BankMovementSummary[]
}

interface BudgetData { name: string, value: number, goal: number }

function PresupuestosDetailsList({
    history,
    budgets,
    banksSummary
}: PresupuestosDetailsListProps) {

    const [budgetData, setBudgetData] = useState<BudgetData[]>([])
    const [unBudgetData, setUnBudgetData] = useState<BudgetData[]>([])
    const [unBudgetExpense, setUnBudgetExpense] = useState(0)
    const [budgetExpense, setBudgetExpense] = useState(0)

    const [monthExpense, setMonthExpense] = useState(0)

    useEffect(() => {
        const _budgetData = budgets.reduce((p, c) => {
            const exists = p.find(bD => bD.name === c.subcategory)
            if (exists) {
                exists.goal += (c.goal || 0);
                exists.value += (c.current || 0);
            } else {
                p.push({
                    name: c.subcategory,
                    goal: (c.goal || 0),
                    value: (c.current || 0),
                })
            }
            return p;
        }, [] as BudgetData[]).filter(d => d.value > 0 || d.goal > 0);
        const _budgetted = _budgetData.filter(d => !!d.goal)
        const _unBudgetted = _budgetData.filter(d => !d.goal)
        console.log(`<Details List> budgets: `, budgets)
        console.log(`<Details List> _unBudgetted: `, _unBudgetted.reduce((p, c) => p += c.value, 0), _unBudgetted)
        console.log(`<Details List> _budgetted: `, _budgetted.reduce((p, c) => p += c.value, 0), _budgetted)
        console.log(`<Details List> _budgetted + _unBudgetted: `, _unBudgetted.reduce((p, c) => p += c.value, 0) + _budgetted.reduce((p, c) => p += c.value, 0))

        setBudgetData(_budgetted)
        setUnBudgetData(_unBudgetted)
        setUnBudgetExpense(_unBudgetted.reduce((p, c) => p += c.value, 0))
        setBudgetExpense(_budgetted.reduce((p, c) => p += c.value, 0))

    }, [budgets])

    useEffect(() => {
        const _monthExpense = Math.abs(banksSummary.reduce((p, c) => p += c.expense, 0))
        console.log(`<Details List> _monthExpense: `, banksSummary.reduce((p, c) => p += c.expense, 0), _monthExpense)
        setMonthExpense(_monthExpense);
    }, [banksSummary])

    const [selectedIndex, setSelectedIndex] = useState(null)


    const goToAssignBudget = (category: string) => () => {
        history.push({
            pathname: '/administration/presupuestos/configure',
            search: `category=${category}`
        })
    }

    const selectIndex = (i: number) => () => {
        if (i === selectedIndex) {
            setSelectedIndex(null)
            return;
        }
        setSelectedIndex(i)
    }

    const renderPercentBar = (decimalPercent: number, divideByCero = false) => {
        return <PercentBar decimalPercent={decimalPercent} divideByCero={divideByCero} />
    }

    // choose a category that's biggest culprit 
    const toUnassignedCandidate = unBudgetData.sort((a, b) => b.value - a.value)[0];
    const toUnassignedCategory = !toUnassignedCandidate ? CATEGORIES_LIST[0].value : toUnassignedCandidate.name.split('-')[0]

    return (
        <div className='presupuestoDetailList'>
            <div className='header'>
                <div className='entry'>
                    <p>
                        <span className='sm_hidden'>
                            % Total de Gastos Totales
                        </span>
                        <span className='sm_label'>
                            Categoría
                        </span>
                    </p>
                    <p>
                        Asignado
                    </p>
                    <p>
                        Gasto
                    </p>
                    <p>
                        % Cubierto
                    </p>
                </div>
            </div>
            <div className={`detailEntry unassigned ${selectedIndex === 0 ? 'before' : ''}`}>
                <div className='detailEntry'>
                    <div className='entry'>
                        <div className='percentDonut'>
                            <BaseDonutChart
                                color='white'
                                startAngle={-1}
                                percent={(unBudgetExpense) / (monthExpense || 1)}
                            />
                            <p>
                                {Math.round((unBudgetExpense / (monthExpense || 1)) * 100)}%
                            </p>
                        </div>
                        <p className='name'>
                            Sin Asignar
                        </p>
                        <div className='_budgetState'>
                            <ToolTip text='Se consideran solo movimientos con categoría'>
                                <p>
                                    Presupuesto no asignado
                                </p>
                            </ToolTip>
                        </div>
                        <p className='budget'>
                            No asignado
                        </p>
                        <p className='expense'>
                            {formatCurrencyStyled(unBudgetExpense)}
                        </p>
                        <Button secondary={true} handleClick={goToAssignBudget(toUnassignedCategory)}>
                            <span>
                                Asignar
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
            {
                CATEGORIES_LIST.map((category, i) => {

                    // Concat unbudgetted and budgetted and filter only those that have budget or expense
                    const categoryEntries = budgetData.concat(unBudgetData).filter(b => b.name.includes(category.value)).filter(b => b.goal || b.value);
                    const categoryBudget = categoryEntries.reduce((p, c) => p += c.goal, 0)
                    const categoryExpense = categoryEntries.reduce((p, c) => p += c.value, 0)

                    const exceedsBudget = categoryEntries.filter(c => c.value > c.goal);

                    const selectRel = !selectedIndex && (selectedIndex !== 0) ? '' :
                        (
                            (selectedIndex - 1) === i ? 'before' : (
                                selectedIndex === i ? 'selected' : (
                                    (selectedIndex + 1) === i ? 'after' : ''
                                )))
                    return (
                        <div className={`detailEntry ${selectRel}`} style={{ maxHeight: selectedIndex === i ? `${72 + (categoryEntries.length * 72) + 58}px` : '72px' }} key={category.value}>
                            <div className='entry'>
                                <div className='percentDonut'>
                                    <BaseDonutChart
                                        color='#AC2E78'
                                        startAngle={-1}
                                        percent={categoryExpense / (monthExpense || 1)}
                                    />
                                    <p>
                                        {Math.round((categoryExpense / (monthExpense || 1)) * 100)}%
                                    </p>
                                </div>
                                <p className='name'>
                                    {category.label}
                                </p>
                                {!exceedsBudget.length ?
                                    (categoryBudget ?
                                        <p className='_budgetState'>
                                            Categorías dentro de presupuesto
                                        </p>
                                        :
                                        <p className='_budgetState lightGray'>
                                            Categoría sin presupuesto
                                        </p>
                                    )
                                    :
                                    <p className='red _budgetState'>
                                        {exceedsBudget.length} Subcategoría{exceedsBudget.length > 1 ? 's' : ''} excede{exceedsBudget.length > 1 ? 'n' : ''} presupuesto
                                    </p>
                                }
                                <p className={`budget ${!categoryBudget && !categoryExpense ? 'lightGray' : (!!categoryExpense && !categoryBudget ? 'red' : '')}`}>
                                    {formatCurrencyStyled(categoryBudget)}
                                </p>
                                <p className={`expense ${!categoryExpense ? 'lightGray' : (!!categoryExpense && !categoryBudget ? 'red2' : '')}`}>
                                    {formatCurrencyStyled(categoryExpense)}
                                </p>

                                {renderPercentBar(categoryExpense / (categoryBudget || 1), !categoryBudget)}
                                <div className='_expand' onClick={selectIndex(i)}>
                                    <span className="material-icons">
                                        expand_more
                                    </span>
                                </div>
                            </div>
                            {
                                categoryEntries.map((subcategory) => {

                                    return (
                                        <div className='entry subcategory' key={subcategory.name}>
                                            <div className='percentDonut'>

                                            </div>
                                            <p className='name'>
                                                {subcategory.name.split('-')[1]}
                                            </p>
                                            <div />
                                            <p>
                                                {formatCurrencyStyled(subcategory.goal)}
                                            </p>
                                            <p>
                                                {formatCurrencyStyled(subcategory.value)}
                                            </p>

                                            {renderPercentBar(subcategory.value / (subcategory.goal || 1), !subcategory.goal)}
                                        </div>
                                    )
                                })
                            }
                            <div className='actions'>
                                <Button primary={true} handleClick={goToAssignBudget(category.value)}>
                                    <span>
                                        Editar Presupuesto
                                    </span>
                                </Button>
                            </div>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default PresupuestosDetailsList;