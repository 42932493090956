import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { color } from 'd3-color'
import { PaybookAccount } from '../../../../models/Administracion/Bank.Accounts';
import { BankBudgetsActivityPrediction, BankIncomesActivityPrediction } from '../../../../models/Administracion/Bank.Budgets';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import BarLineAndSpreadChart from '../../../Graphs/BarAndLine/BarLineAndSpread.chart';
import { formatLineGroupsFromBankSummaries } from '../../helpers/lineGroups.formatting';
import { calculateCurrentBudget, clusterSummaryByDateRange, processBalanceBoxChartData, processBalanceBoxChartDataV2, processExpenseBoxChartData, processIncomeBoxChartData } from '../helpers/prediction.stats';

import NumberDisplay from '../../../components/numberDisplay';
import { ToggleMenuDict } from './Projection.overview.menu';
import useWindowResize from '../../../Hooks/useWindowResize';
import { BankProject, BankProjectConcept_Expense, BankProjectConcept_Income } from '../../../../models/Administracion/Bank.Projects';

import './Projection.overview.graphs.balancePrediction.scss'
import Loading from '../../../Animations/loadScreen';


interface ProjectionOverviewGraphsProps {
    dataLoaded: boolean;
    projects: BankProject<'business'>[];
    summaries: BankMovementSummary[]
    accounts: PaybookAccount[]
    budgetActivity: BankBudgetsActivityPrediction[]
    incomeActivity: BankIncomesActivityPrediction[]
    summaryToggleDict: ToggleMenuDict;
}

interface StandardGraphGroup {
    color: string;
    values: {
        x: number;
        y: number;
        yShadow?: number;
        colorOverride?: string; // Only on bar graphs
    }[]
}

interface CandleStickGraphGroup {
    name?: string;
    color: string;
    values: {
        x: number;
        yTop: number;
        yTopOutlier: number;
        median?: number; // As Reference
        yBottom: number;
        yBottomOutlier: number;
    }[]
}

function ProjectionOverviewGraphsBalancePrediction({ dataLoaded, projects, summaries, accounts, budgetActivity, incomeActivity, summaryToggleDict }: ProjectionOverviewGraphsProps) {

    const [barGroups, setBarGroups] = useState<StandardGraphGroup[]>([])
    const [lineGroups, setLineGroups] = useState<StandardGraphGroup[]>([])
    const [candlestickGroups, setCandlestickGroups] = useState<CandleStickGraphGroup[]>([])

    const [accountBalance, setAccountBalance] = useState(null)

    // Expected numbers
    const [expenseProjected, setExpenseProjected] = useState(0)
    const [incomeProjected, setIncomeProjected] = useState(0)
    const [balanceProjected, setBalanceProjected] = useState(0)

    const { windowWidth } = useWindowResize()

    const compressGraph = windowWidth < 510;

    // const xCompressedRef = useMemo(() => )

    useEffect(() => {
        if (accounts?.length && summaries?.length && budgetActivity?.length && incomeActivity?.length) {
            const MONTH_PREDICTION = 2; // Pending to use a Date Selector or similar (Test it as TODO)
            // 1. Line Graph block
            const _summaries: BankMovementSummary[] = JSON.parse(JSON.stringify(summaries))
            const _summaryDaily = clusterSummaryByDateRange(_summaries, 'natural')
            const currentBalance = accounts.reduce((p, c) => p += c.balance, 0);
            const balanceLine = formatLineGroupsFromBankSummaries(_summaryDaily, currentBalance);
            // ********************

            // 2. Bar Graph block calculations
            const _summaryMonthly = clusterSummaryByDateRange(_summaries, 'month')
            const lastEntryTs = Math.max(..._summaryMonthly.map(sM => sM.date)) // as Start Of month
            const currentBudget = calculateCurrentBudget(budgetActivity)
            // ********************


            // 3. Bar Graph Variables
            const currentIncomeBar: StandardGraphGroup = {
                color: 'rgba(96, 203, 94, 0.5)',
                values: _summaryMonthly.map((sG) => ({
                    x: sG.date,
                    y: sG.income
                }))
            }
            const currentExpenseBar: StandardGraphGroup = {
                color: 'rgba(215, 32, 32, 0.5)',
                values: _summaryMonthly.map((sG) => ({
                    x: sG.date,
                    y: Math.abs(sG.expense)
                }))
            }
            currentExpenseBar.values.forEach(v => {
                // >> Most recent expense
                if (v.x === moment(lastEntryTs).startOf('month').valueOf()) {
                    v.yShadow = currentBudget;
                    v.colorOverride = '#EA2B2B'
                }
            })
            currentIncomeBar.values.forEach(v => {
                // >> Most recent income (No bar prediction ATM)
                if (v.x === moment(lastEntryTs).startOf('month').valueOf()) {
                    v.colorOverride = '#7ED77C'
                }
            })


            // ********************
            // 4. Process Data for Box Plots (Candle Chart)
            // 4.1 >> Expense Budgetted CandleStick Data 

            console.log('<Prediction> projects: ', projects);

            console.time('Expenses Processing')
            // Filter those on Side Menu Toggle

            const _projects = projects.filter(p => summaryToggleDict.expenseByProject[p._id] === false ? false : true);
            const futureExpenses = _projects.filter(p => p.expenses?.length).reduce((p, c) => p.concat(c.expenses || []), [] as BankProjectConcept_Expense[])
                .reduce((p, exp) => {
                    const monthInstances = new Array(exp.monthRepetition).fill(0).map((_, i) => i).map(i => moment(exp.startsAt).add(i, 'month').startOf('month').valueOf()).filter(ts => ts > lastEntryTs);
                    if (!monthInstances.length) {
                        return p;
                    }
                    if (exp.expenseType === 'monthly') {
                        const monthlyAmount = Math.round((exp.amount / exp.monthRepetition) * 100) / 100;
                        return p.concat(monthInstances.map(ts => ({
                            amount: monthlyAmount,
                            startsAt: ts,
                            name: exp.name,
                            monthRepetition: 1,
                            expenseType: 'monthly'
                        }) as BankProjectConcept_Expense))
                    } else {
                        // Check remaining and split montly
                        const remaining = Math.round((exp.amount - exp.current) * 100) / 100
                        if (remaining < 0) {
                            return p;
                        }
                        const monthlyAmount = Math.round((remaining / monthInstances.length) * 100) / 100;
                        return p.concat(monthInstances.map(ts => ({
                            amount: monthlyAmount,
                            startsAt: ts,
                            name: exp.name,
                            monthRepetition: 1,
                            expenseType: 'monthly'
                        }) as BankProjectConcept_Expense))
                    }
                }, [] as BankProjectConcept_Expense[]).filter(e => e.startsAt > lastEntryTs);
            const futureIncomes = _projects.filter(p => p.incomes?.length).reduce((p, c) => p.concat(c.incomes || []), [] as BankProjectConcept_Income[]).filter(e => e.startsAt > lastEntryTs);
            console.log('<Prediction> futureExpenses: ', futureExpenses);

            const _budgetActivity: BankBudgetsActivityPrediction[] = budgetActivity.map((bA) => ({
                category: bA.category,
                activity: bA.activity.filter(a => summaryToggleDict.expense[a.subcategory] === false ? false : true).filter(a => summaryToggleDict.expenseByProject[a.subcategory] === false ? false : true)
            }))
            const { expenseBoxPlot, generalExpenseProgress } = processExpenseBoxChartData(_budgetActivity, currentBudget, MONTH_PREDICTION, lastEntryTs, futureExpenses)
            //          >> Offset with expected Expenses (Budget, Projects, etc.)
            const expenseCandlestick: CandleStickGraphGroup = {
                color: '#f6c0be',
                values: expenseBoxPlot
            }
            console.timeEnd('Expenses Processing')


            // Filter those on Side Menu Toggle
            const _incomeActivity: BankIncomesActivityPrediction[] = incomeActivity.filter(iA =>
                summaryToggleDict.income[iA.subcategory] === false || summaryToggleDict.incomeByProject[iA.projectId] === false ? false : true
            )
            const { incomeBoxPlot, generalIncomeProgress } = processIncomeBoxChartData(_incomeActivity, MONTH_PREDICTION, lastEntryTs, futureIncomes)
            const incomeCandlestick: CandleStickGraphGroup = {
                color: '#b3e5b0',
                values: incomeBoxPlot
            }

            const dateTsReference = moment(lastEntryTs).startOf('month').valueOf()
            const _lastBalanceReference = balanceLine.filter(bL => bL.x <= dateTsReference);
            const lastBalanceReference = _lastBalanceReference[_lastBalanceReference.length - 1];
            // const { balanceBoxPlot } = processBalanceBoxChartData(generalIncomeProgress, generalExpenseProgress, MONTH_PREDICTION, lastEntryTs, lastBalanceReference?.y || 0, [], [])
            const { balanceBoxPlot } = processBalanceBoxChartDataV2(incomeBoxPlot, expenseBoxPlot, lastBalanceReference?.y || 0)
            const balanceCandlestick: CandleStickGraphGroup = {
                color: '#d666a5',
                values: balanceBoxPlot
            }

            // ********************
            /*
            const demovects = expenseVectorsPath.map((vector, i) => {
                const c = color('gray')
                c.opacity = ((i + 1) / (expenseVectorsPath.length))
                return ({
                    color: c.toString(),
                    values: vector.map(v => ({ x: v.monthTs, y: v.value }))
                })
            })
            const demoForecastvects = expensePredictionVectorsPath.map((vector, i) => {
                const c = color('red')
                c.opacity = ((i + 1) / (expensePredictionVectorsPath.length))
                return ({
                    color: c.toString(),
                    values: vector.map(v => ({ x: v.monthTs, y: v.value }))
                })
            })
            */
            // (Debugging only)

            setAccountBalance(currentBalance);
            setLineGroups(
                [{
                    color: '#AC2E78',
                    values: balanceLine,
                }]
                // .concat(demovects, demoForecastvects)
            )
            setBarGroups([
                currentExpenseBar,
                currentIncomeBar,
            ])
            setCandlestickGroups([
                expenseCandlestick,
                incomeCandlestick,
                balanceCandlestick
            ])

            // ********************
            // 5. Set counter numbers
            setExpenseProjected(expenseBoxPlot[0]?.median || 0)
            setIncomeProjected(incomeBoxPlot[0]?.median || 0)
            setBalanceProjected(balanceBoxPlot[0]?.median || 0)

        }
    }, [projects, summaries, accounts, budgetActivity, incomeActivity, summaryToggleDict])

    const renderGraph = () => {
        if (compressGraph) {
            const xRef = moment(Math.min(...summaries.map(s => s.date))).add(2, 'month').startOf('month').valueOf()
            const _barGroups = barGroups.map((bG) => ({
                color: bG.color,
                values: bG.values.filter(v => v.x >= xRef)
            }))
            const _lineGroups = lineGroups.map((bG) => ({
                color: bG.color,
                values: bG.values.filter(v => v.x >= xRef)
            }))

            return (
                <BarLineAndSpreadChart margin={{ left: 40, top: 20, bottom: 20, right: 40 }}
                    barGroups={_barGroups}
                    lineGroups={_lineGroups}
                    candlestickGroups={candlestickGroups}
                    //spreadGroups={projectionRanges}
                    xAxisFormatting={(d: number) => moment(d).format('MMMM')}
                    xAxisExcludeFromTicks={['lineGroups']}
                    xAxisTicks={'from-data'}
                />
            )
        } else {
            return (
                <BarLineAndSpreadChart margin={{ left: 40, top: 20, bottom: 20, right: 40 }}
                    barGroups={barGroups}
                    lineGroups={lineGroups}
                    candlestickGroups={candlestickGroups}
                    //spreadGroups={projectionRanges}
                    xAxisFormatting={(d: number) => moment(d).format('MMMM')}
                    xAxisExcludeFromTicks={['lineGroups']}
                    xAxisTicks={'from-data'}
                />
            )
        }
    }

    return (
        <div className='_chartSummary'>
            <div className='row three numDisplay'>
                <NumberDisplay
                    title='Ingreso Proyectado'
                    value={incomeProjected}
                    coloring="green"
                    icon={'arrow_upward'}
                />
                <NumberDisplay
                    title='Gasto Proyectado'
                    value={expenseProjected}
                    coloring="red"
                    icon={'arrow_downward'}
                />
                <NumberDisplay
                    title='Balance Próximo'
                    value={balanceProjected}
                    coloring="red"
                    icon={'arrow_downward'}
                />
            </div>
            <div className='balanceChartSection'>
                {

                    barGroups.length && lineGroups.length && accountBalance ?
                        renderGraph()
                        :
                        (
                            dataLoaded ?
                                <div className='chartSectionEmpty'>
                                    <div className='_text'>
                                        <p>
                                            ¡Agrega una cuenta de banco para ver tu gráfica de gastos!
                                        </p>
                                        <p className='small'>
                                            Además de monitorear tus gastos, también podrás ver y categorizar tus <NavLink to="/administration/movimientos">Movimientos</NavLink>.
                                        </p>
                                    </div>
                                </div>
                                : <Loading relativePos={true} display={true} />
                        )
                }
            </div>
        </div>
    )
}

export default ProjectionOverviewGraphsBalancePrediction