import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { PaybookAccount } from '../../../../models/Administracion/Bank.Accounts';
import { CredentialResponse, PaybookCredentialResponse } from '../../../../models/Administracion/Bank.Credentials';
import { PaybookOrganization } from '../../../../models/Administracion/Banks';
import { graphqlSchema } from '../../../../services/graphql.schema';
import sharedToasterSubject from '../../../../services/shared.toasterSubject';
import BaseDonutChart from '../../../Graphs/Donut/Donut.chart';
import { CredentialEntryCaseAssistedRetryDialog, CredentialEntryCaseSupportErrorDialog } from './Credential.entry.cases.dialog';
import CredentialEntryDurationCounter from './Credential.entry.counter';
import { ActionByCode, requiresActionByCode, stateIconByCode, stateLabelByCode } from './Credential.entry.format';

import './Credential.entry.scss'

interface CredentialEntryProps {
    // For  Avatar Purposes
    organization: PaybookOrganization
    accounts: PaybookAccount[];
    credential: PaybookCredentialResponse;
    index?: number;
    onRetryCredentials: (siteId: string, organizationId: string) => void;
}

function CredentialEntry({ organization, accounts, credential, index, onRetryCredentials }: CredentialEntryProps) {

    const [tsNow, setTsNow] = useState(Date.now())
    const [takingAction, setTakingAction] = useState(false)

    // Intermediate Modal dialogs
    const [assistedRetryCase, setAssistedRetryCase] = useState<PaybookCredentialResponse>(null);
    const [supportErrorCase, setSupportErrorCase] = useState<PaybookCredentialResponse>(null);
    const [unavailableCase, setUnavailableCase] = useState<PaybookCredentialResponse>(null);

    const [requestSync] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.submitSyncCredentials, {
        onCompleted: ({ submitSyncCredentials }: { submitSyncCredentials: CredentialResponse }) => {
            console.log('submitSyncCredentials: ', submitSyncCredentials);
            // Don't reset setTaking action as state change can take some time
        },
        onError: (e) => {
            console.error('Error Syncing Credentials: ', e);
            sharedToasterSubject.next({ type: 'error', message: `${e.graphQLErrors[0].extensions.exception.error.message}` })
            setTakingAction(false);
        }
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!document.hidden) {
                // Try to avoid "event update overflow" when tab is open but not looked at, that causes white page long lattency before page responds
                setTsNow(Date.now());
            }
        }, 1000)
        return () => {
            clearInterval(intervalId);
        }
    }, [])

    useEffect(() => {
        console.log(`Code Changed: `, credential.code);
        setTakingAction(false)
    }, [credential.code])

    const actionExecute = (actionToTake: ActionByCode['actionFunction']) => () => {
        if (takingAction) {
            return;
        }
        console.log(`actionExecute: `, actionToTake);
        if (actionToTake === 'sync') {
            requestSync({
                variables: {
                    credentialId: credential.id_credential
                }
            })
            setTakingAction(true);
        }
        else if (actionToTake === 'retry') {
            onRetryCredentials(credential.id_site, credential.id_site_organization)
        }
        else if (actionToTake === 'assisted-retry' || actionToTake === 'assisted-visit-site') {
            // >> Open Warning modal befre retrying credentials with instructions
            // >> User needs to access their bank account first and then retry (Might need to take some bank action)
            setAssistedRetryCase(credential);
        }
        else if (actionToTake === 'support-error') {
            // TODO: Implement support-error
            // >> Contact support before retying credentials as there was a system (5xx) error.
            // NOTE: This is a temporary state, Paybook should change state later on.    
            setSupportErrorCase(credential);
        }
        else if (actionToTake === 'unavailable') {
            // >> Display no-action warning modal stating that the bank is currently unavailable and indicate to retry later.
            // NOTE: This is a temporary state, Paybook should change state later on.    
            
            // It's technically the same context as support-error
            // setUnavailableCase(credential);    
            setSupportErrorCase(credential);
        }
    }

    const onAssistedRetryProceed = () => {
        setAssistedRetryCase(null)
        if (credential.can_sync) {
            requestSync({
                variables: {
                    credentialId: credential.id_credential
                }
            })
            setTakingAction(true);
        }
        else {
            // can_sync is false, so we can only retry username and password
            onRetryCredentials(credential.id_site, credential.id_site_organization)
        }
    }

    const onAssistedRetryAbort = () => {
        setAssistedRetryCase(null)
    }

    const onSupportErrorRetryProceed = () => {
        setSupportErrorCase(null)
        if (credential.can_sync) {
            requestSync({
                variables: {
                    credentialId: credential.id_credential
                }
            })
            setTakingAction(true);
        }
        else {
            // can_sync is false, so we can only retry username and password
            onRetryCredentials(credential.id_site, credential.id_site_organization)
        }
    }

    const onSupportErrorRetryAbort = () => {
        setSupportErrorCase(null)
    }


    // Pending State renders
    // ============================
    const siteIs = organization.sites.find(s => credential.id_site === s.id_site);

    const renderPendingState = () => {
        const label = stateLabelByCode(credential);
        const color = '#3FA8F4';

        return (
            <div className={`refreshStatus`}>
                <BaseDonutChart
                    color={color}
                    startAngle={-40}
                    percent={0}
                />
                <div className='state'>
                    {
                        label.split(' ')
                            .map((str, i) => (
                                <p key={i} style={{ color: i === 0 ? color : 'inherit' }} className={i === 0 ? 'subState' : ''}>
                                    {str}
                                </p>
                            ))
                    }
                    <p>
                        <span className="material-icons spin" style={{ color: '#6d6d6f' }}>
                            {stateIconByCode(credential)}
                        </span>
                    </p>
                </div>
            </div>
        )
    }

    // Failed State renders
    // ============================

    const renderFailedState = (actionCase: ActionByCode) => {
        if (credential.code < 200) {
            return renderPendingState()
        }

        const label = stateLabelByCode(credential);

        const color = (credential.code === 410 || credential.code === 412) ? '#6d6d6f' :
            (credential.code === 411 ? '#FFB065' : '#D72020')

        const waitFor = (credential.can_sync && !actionCase.daysAwait) ? null : (((credential.dt_ready || 0) > Date.now()) ? (credential.dt_ready - credential.dt_execute) / (1000 * 60 * 60 * 24) : actionCase.daysAwait)
        return (
            <div className='refreshStatus'>
                <BaseDonutChart
                    color={color}
                    startAngle={-40}
                    percent={0}
                />
                <div className='state'>
                    {
                        label.split(' ')
                            .map((str, i) => (
                                <p key={i} style={{ color: i > 0 ? color : 'inherit' }} className={i > 0 ? 'subState' : ''}>
                                    {str}
                                </p>
                            ))
                    }
                    <p>
                        <span className="material-icons" style={{ color: color }}>
                            {stateIconByCode(credential)}
                        </span>
                    </p>
                </div>
                {
                    // actionCase.daysAwait ?
                    waitFor ?
                        <CredentialEntryDurationCounter
                            color={color}
                            days={waitFor}
                            nowTs={tsNow}
                            credentialTs={credential.dt_execute}
                        />
                        : null
                }
            </div>
        )
    }


    // Successful State renders
    // ============================

    const renderRefreshStatusForTwoFa = () => {
        const days = 5;
        const _percentIteration = (tsNow - credential.dt_execute) / (days * 24 * 60 * 60 * 1000);
        const percentIteration = 1 - _percentIteration;
        const color = percentIteration < 0.75 ? (percentIteration < 0.45 ? (percentIteration < 0.286 ? '#D72020' : '#FF8800') : '#FF8800') : '#60CB5E'
        const tokenExpired = percentIteration < 0.45;
        const overflowablePercent = percentIteration < 0 ? -1 : percentIteration
        return (
            <div className='refreshStatus'>
                <BaseDonutChart
                    color={color}
                    startAngle={-40}
                    percent={overflowablePercent}
                />
                <div className='state' title='Tiempo para re-sincronizar token'>
                    <p>Token</p>
                    <p className='subState' style={{ color: color }}>{tokenExpired ? 'Expired' : 'Válido'}</p>
                    <p>
                        {
                            tokenExpired ?
                                (
                                    overflowablePercent === -1 ?
                                        <span className="material-icons noSpace" style={{ color: color }}>
                                            priority_high
                                        </span>
                                        :
                                        <span className="material-icons noSpace" style={{ color: color }}>
                                            schedule
                                        </span>
                                )
                                :
                                <span className="material-icons noSpace" style={{ color: color }}>
                                    check
                                </span>
                        }
                    </p>
                </div>
                {
                    overflowablePercent === -1 ?
                        <div className='_counterHold label'>
                            <div className='label' style={{ color }}>
                                <span>
                                    Ingresa nuevo Token
                                </span>
                            </div>
                        </div>
                        :
                        <CredentialEntryDurationCounter
                            color={color}
                            days={days}
                            nowTs={tsNow}
                            credentialTs={credential.dt_execute}
                        />
                }
            </div>
        )
    }

    const renderRefreshStatusForStandard = () => {
        const days = 1;
        const percentIteration = (tsNow - credential.dt_execute) / (days * 24 * 60 * 60 * 1000)
        const color = '#60CB5E';
        return (
            <div className='refreshStatus'>
                <BaseDonutChart
                    color={color}
                    startAngle={-40}
                    percent={percentIteration}

                />
                <div className='state' title="Tiempo para cargar nuevas transacciónes">
                    <p>Cuenta</p>
                    <p className='subState' style={{ color: color }}>Válida</p>
                    <p>
                        <span className="material-icons" style={{ color: color }}>
                            check
                        </span>
                    </p>
                </div>
                <CredentialEntryDurationCounter
                    color={color}
                    days={days}
                    nowTs={tsNow}
                    credentialTs={credential.dt_execute}
                />
            </div>
        )
    }

    const requiresAction = (actionCase: ActionByCode) => {
        const canSync = actionCase.daysAwait ? ((credential.dt_execute + (actionCase.daysAwait * (24 * 60 * 60 * 1000))) < tsNow) : (credential.can_sync ? true : (!credential.dt_ready ? true : credential.dt_ready <= tsNow));
        if (!actionCase.action) {
            return null
        }

        if (takingAction) {
            // Currently working, low level "lock" (UI only)
            return (
                <div className={`actionEntry disabled`}>
                    <p>
                        {'Trabajando, espere...'}
                    </p>
                </div>
            )
        }

        if (!canSync) {
            return (
                <div className={`actionEntry disabled`}>
                    <p>
                        {actionCase.actionText}
                    </p>
                </div>
            )
        }

        return (
            <div className='actionEntry' onClick={actionExecute(actionCase.actionFunction)}>
                <p>
                    {actionCase.actionText}
                </p>
            </div>
        )
    }

    // const stateIsSuccess = (credential.is_authorized || (credential.code > 199 && credential.code < 300));
    const stateIsSuccess = (credential.code > 199 && credential.code < 300);
    const actionCase = requiresActionByCode(credential);

    return (
        <div className='credentialEntry'>
            {
                (!!index || index === 0) ?
                    <p>
                        Cuenta {index + 1}
                    </p>
                    : null
            }
            <div className={`card ${actionCase.action ? 'actionCase' : 'noShadow'} ${stateIsSuccess ? '' : ''}`}>
                <div className='credentialDisplay'>
                    <div className='avatar'>
                        <div
                            key={organization.id_site_organization}
                            className='avatarLogo'
                            style={{ backgroundImage: `url('${organization.avatar}')` }}
                        />
                    </div>
                    <div className='credInfo'>
                        <p>
                            <b>
                                {siteIs.name}
                            </b>
                        </p>
                        <p>
                            Actualizado: {moment(credential.dt_execute).format('DD/MMM')}
                        </p>
                    </div>
                    {
                        stateIsSuccess ? (
                            credential.is_twofa ?
                                renderRefreshStatusForTwoFa() :
                                renderRefreshStatusForStandard()
                        ) : renderFailedState(actionCase)
                    }

                </div>
            </div>
            {requiresAction(actionCase)}
            <CredentialEntryCaseAssistedRetryDialog
                isOpen={!!assistedRetryCase}
                credential={assistedRetryCase}
                onAbort={onAssistedRetryAbort}
                onProceed={onAssistedRetryProceed}
            />
            <CredentialEntryCaseSupportErrorDialog
                isOpen={!!supportErrorCase}
                credential={supportErrorCase}
                onAbort={onSupportErrorRetryAbort}
                onProceed={onSupportErrorRetryProceed}
            />
        </div>
    )
}

export default CredentialEntry;