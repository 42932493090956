import { useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Cotizacion } from '../../../models/Cotizaciones';
import { graphqlSchema } from '../../../services/graphql.schema';
import Button from '../../Forms/Button';
import TextArea from '../../Forms/TextArea';


function CotizacionViewChangeCard({ cotizacionId, onChangesRequestSubmitted, onClearChangesRequest, isRejected, approval }: { cotizacionId:string, onClearChangesRequest: () => void, onChangesRequestSubmitted: (cotizacionUpdated: Cotizacion) => void, isRejected: boolean, approval: { [key: string]: { approval: boolean, quantity: number } } }) {
    const [animate, setAnimate] = useState(false);
    const [message, setMessage] = useState<string>('')
    useEffect(() => {
        if (isRejected) {
            setAnimate(true);
        }
    }, [isRejected]);

    const [requestChanges] = useMutation(graphqlSchema.FISCALPOP.COTIZACION.requestCotizacionChanges, {
        onCompleted: ({requestCotizacionChanges}:{requestCotizacionChanges: Cotizacion}) => {
            onChangesRequestSubmitted(requestCotizacionChanges);
            console.log('requestCotizacionChanges: ', requestCotizacionChanges);
        },
        onError: (e) => {
            console.error('Error requesting changes: ', e.graphQLErrors[0]);
        }
    })

    const requestChangeSubmit = () => {
        if(!isRejected){
            return;
        }
        const historyEntry = {
            cotizacionId: cotizacionId,
            message,
            applicableConcepts: Object.keys(approval).filter(k => !approval[k].approval).map(k => ({
                title: k,
                quantity: approval[k].quantity
            }))
        }
        console.log('historyEntry: ', historyEntry)
        requestChanges({
            variables: {
                entry: historyEntry
            }
        })
    }

    const clearRequest = () => {
        setMessage('');
        onClearChangesRequest()
    }

    return (
        <div className={`_changeRequested ${isRejected ? '_display' : (animate ? '_hide' : '')}`}>
            <div className={`newCard requestChangesContain`}>
                <h2>
                    Cambios solicitados
                </h2>
                <div className="products">
                    {
                        Object.keys(approval).filter(k => !approval[k].approval).map((key) => {
                            return (
                                <p key={key}>
                                    {key}
                                </p>
                            )
                        })
                    }
                </div>
                <TextArea value={message} onChange={setMessage} placeholder={'Mensaje al vendedor sobre cambio solicitado'}/>
                <div className="_actions">
                    <Button primary={true} handleClick={requestChangeSubmit}>
                        <span>
                            Solicitar cambios
                        </span>
                    </Button>
                    <Button tertiary={true} handleClick={clearRequest}>
                        <span>
                            Descartar cambios
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    )
}


export default CotizacionViewChangeCard;