

export const CartaPorteConceptOptions = () => [
    {
        label: '',
        value: 'servicio-de-transporte',
        data: {
            descripcion: 'Servicios de transporte de carga',
            claveProdServ: '78101800',
            claveUnidad: 'E48',
        }
    },
    {
        label: '',
        value: 'servicio-de-transporte-local',
        data: {
            descripcion: 'Servicios de transporte de carga en area local',
            claveProdServ: '78101801',
            claveUnidad: 'E48',
        }
    },
    {
        label: '',
        value: 'servicio-de-transporte-nacional',
        data: {
            descripcion: 'Servicios de transporte de carga a nivel nacional',
            claveProdServ: '78101802',
            claveUnidad: 'E48',
        }
    },
    {
        label: '',
        value: 'servicio-de-transporte-internacional',
        data: {
            descripcion: 'Servicios de transporte de carga a nivel internacional',
            claveProdServ: '78101806',
            claveUnidad: 'E48',
        }
    },
    {
        label: '',
        value: 'servicio-de-reubicacion',
        data: {
            descripcion: 'Servicios de reubicación',
            claveProdServ: '78101804',
            claveUnidad: 'E48',
        }
    },
    {
        label: '',
        value: 'servicio-de-transporte-vehiculos',
        data: {
            descripcion: 'Servicios de transporte de vehículos',
            claveProdServ: '78101803',
            claveUnidad: 'E48',
        }
    },
    {
        label: '',
        value: 'servicio-de-custodia-valores',
        data: {
            descripcion: 'Servicios de custodia de valores',
            claveProdServ: '84121806',
            claveUnidad: 'E48',
        }
    },
    {
        label: '',
        value: 'servicio-de-blindados-valores',
        data: {
            descripcion: 'Servicios de carros blindados y transporte de valores',
            claveProdServ: '92121800',
            claveUnidad: 'E48',
        }
    },
    {
        label: '',
        value: 'servicio-de-blindados',
        data: {
            descripcion: 'Servicios de carros blindados',
            claveProdServ: '92121801',
            claveUnidad: 'E48',
        }
    },
    {
        label: '',
        value: 'servicio-de-blindados',
        data: {
            descripcion: 'Servicios de transporte de dinero',
            claveProdServ: '92121802',
            claveUnidad: 'E48',
        }
    },
].map(s => {
    s.label = s.data.descripcion;
    return s;
})