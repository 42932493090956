import React from 'react';
import { BaseReact } from '../../../base.model';
import { ProfileQL } from '../../../models/Profile';
import SectionTitleBar from '../../components/titlebar';

import './MainHub.scss'

interface PagosProps extends BaseReact {
    currentUser: ProfileQL;
}

function MainHub({ currentUser, location, history }: PagosProps) {

    return (
        <div id="MainHub">
            <SectionTitleBar currentUser={currentUser} title={`Resumen General`} />
            <div className="mainHubContent">

            </div>
        </div>
    )
}

export default MainHub;