import React, { useEffect, useState } from 'react';

import './Preview.billEvents.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile';
import SectionTitleBar from '../components/titlebar';
import { ToBillArguments, ToBillEvent } from '../../models/Factura';
import Input from '../Forms/Input';
import { formatCurrencyStyled } from '../visuals/currency.formatted';
import { numeroALetras } from '../../utils/numeroLetra';

import moment from 'moment';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { parseStampErrorFromGraphQL } from '../../utils/error.graphql';
import { conceptoToTotales } from '../../utils/calculate.conceptos';
import { clearGqlTypename } from '../../utils/formatting';

interface PreviewProps extends BaseReact {
    currentUser: ProfileQL;
}


function PreviewBillEventFactura({ currentUser, location, history }: PreviewProps) {
    console.log('[FP] Location: ', location);
    const [toBillArgument, setToBillArgument] = useState<ToBillArguments<ToBillEvent>>(null);
    const [toBillEvents, setToBillEvents] = useState<ToBillEvent[]>(null);

    // UI Elements
    const [paymentError, setPaymentError] = useState('');
    const [stamping, setStamping] = useState(false);
    // ============
    useEffect(() => {
        // Extract state and use for current operations    
        const { billArgument, billEvents } = location.state as { billArgument: ToBillArguments<ToBillEvent>; billEvents: ToBillEvent[]; };
        setToBillArgument(clearGqlTypename(billArgument));
        setToBillEvents(billEvents.map(e => clearGqlTypename(e)));
    }, [location]);

    const [billEventForStamping] = useMutation(graphqlSchema.FISCALPOP.CFDI.stampFromBillEvent, {
        onCompleted: ({ stampFromBillEvent }: { stampFromBillEvent: ToBillArguments<string> }) => {
            console.log('STAMP FROM BILL ARGUMENT: ', stampFromBillEvent);
            sharedToasterSubject.next({ type: 'confirm', message: 'Factura emitida correctamente' });
            history.push({
                pathname: '/',
                state: { billArgument: stampFromBillEvent }
            })
        },
        onError: (e) => {
            console.error('Error Saving GQL RAW: ', e);
            console.error('Error Saving GQL: ', e.graphQLErrors[0]);
            sharedToasterSubject.next({ type: 'error', message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clearTs: 5200, clickToClose: true });
            setPaymentError(parseStampErrorFromGraphQL(e.graphQLErrors[0]))
            setStamping(false);
        }
    })

    const totales = conceptoToTotales;


    const submitArgument = () => {
        setStamping(true);
        const stringToBillArgs: any = toBillArgument;
        stringToBillArgs.billEvents = stringToBillArgs.billEvents.map((e: ToBillEvent) => e._id);
        toBillEvents.forEach(e => {
            if (!!e.CFDI && !e.CFDI.lugarExpedicion) {
                e.CFDI.lugarExpedicion = currentUser.fiscalpopProfile.lugarExpedicion;
            }
            if (!!e.NotaCreditoCFDI && !e.NotaCreditoCFDI.lugarExpedicion) {
                e.NotaCreditoCFDI.lugarExpedicion = currentUser.fiscalpopProfile.lugarExpedicion;
            }
            if (!!e.NotaCreditoCFDI && !e.NotaCreditoCFDI.tipoDeComprobante) {
                e.NotaCreditoCFDI.tipoDeComprobante = 'E';
            }
            // Having CFDI and Nota Credito store fechas breaks billing
            // >> even if they are set, they should be removed
            if (!!e.CFDI && !e.CFDI.fecha) {
                e.CFDI.fecha = new Date().toJSON()
            }
            if (!!e.CFDI && !e.CFDI.tipoDeComprobante) {
                e.CFDI.tipoDeComprobante = 'I';
            }
            if (!!e.NotaCreditoCFDI && !e.NotaCreditoCFDI.fecha) {
                e.NotaCreditoCFDI.fecha = new Date().toJSON();
            }
            if (!!e.NotaCreditoCFDI && !e.NotaCreditoCFDI.cfdiRelacionados.uuids.length && toBillArgument.uuid) {
                e.NotaCreditoCFDI.cfdiRelacionados.uuids = [toBillArgument.uuid];
            } else if (!!e.NotaCreditoCFDI && !!e.NotaCreditoCFDI.cfdiRelacionados && toBillArgument.uuid) {
                e.NotaCreditoCFDI.cfdiRelacionados = {
                    tipoRelacion: '07',
                    uuids: [toBillArgument.uuid]
                }
            }
        })
        billEventForStamping({
            variables: {
                argument: stringToBillArgs,
                cfdi: toBillEvents,
            }
        })
    }

    const returnToModify = () => {
        history.goBack();
        // 
        /*
        history.push({
            pathname: '/facturacion/facturar',
            state: { billArgument: toBillArgument, billEvents: toBillEvents }
        })
        */
    }

    const handleNotaCreditoEdit = (property: 'serie' | 'folio') => {
        return (value: string) => {
            const aggr: { [key: string]: string } = {}
            aggr[property] = value;
            const _billEvent = Object.assign({}, toBillEvents[0]);
            _billEvent.NotaCreditoCFDI = Object.assign({}, _billEvent.NotaCreditoCFDI, aggr)
            setToBillEvents([_billEvent]);
        }
    }

    const handleCfdiEdit = (property: 'serie' | 'folio') => {
        return (value: string) => {
            const aggr: { [key: string]: string } = {}
            aggr[property] = value;
            const _billEvent = Object.assign({}, toBillEvents[0]);
            _billEvent.CFDI = Object.assign({}, _billEvent.CFDI, aggr)
            setToBillEvents([_billEvent]);
        }
    }


    // Render methods ==================


    const renderMainBill = () => {
        if (!toBillArgument) {
            return ''
        }
        const { subtotal, ieps_retenido, ieps_trasladado, iva_retenido, iva_trasladado, isr, total } = totales(toBillArgument.MasterCFDI.conceptos)
        return (
            <div>
                <h4>Factura original (Factura relacionada)</h4>
                <div className="card table">
                    <div className="cardBody">
                        <div className="row dateUuid">
                            <Input value={moment(toBillArgument.createdAt).format('DD MMMM YYYY,  hh:mm a')} label="Fecha emitida" type="text" disabled={true} />
                            <Input value={toBillArgument.uuid} label="UUID" type="text" disabled={true} />
                        </div>
                        <div className="row emisor">
                            <div className="row two">
                                <Input value={toBillArgument.MasterCFDI.serie || 'A'} label="Serie" type="text" disabled={true} />
                                <Input value={toBillArgument.MasterCFDI.folio || '1'} label="Folio" type="text" disabled={true} />
                            </div>
                        </div>
                        <div className="row emisor">
                            <Input value={toBillArgument.MasterCFDI.receptor.nombre} label="Receptor" type="text" disabled={true} />
                            <Input value={toBillArgument.MasterCFDI.receptor.rfc} label="RFC Receptor" type="text" disabled={true} />
                            <div className="row two">
                                <Input value={toBillArgument.MasterCFDI.formaPago} label="Forma de pago" type="text" disabled={true} />
                                <Input value={toBillArgument.MasterCFDI.metodoPago} label="Metodo de pago" type="text" disabled={true} />
                            </div>
                        </div>
                        <div className="conceptosWrap">
                            <span className="label">Conceptos:</span>
                            {toBillArgument.MasterCFDI.conceptos.map((concepto, i) => (
                                <div className="concepto" key={i}>
                                    <p>#{i + 1}</p>
                                    <p className="_name">{concepto.descripcion}</p>
                                    <div className="_claves">
                                        <p><span>Clave ProdServ:</span> {concepto.claveProdServ}</p>
                                        <p><span>Clave Unidad:</span> {concepto.claveUnidad}</p>
                                    </div>
                                    <p className="_cantidad">
                                        (x{concepto.cantidad})
                                    </p>
                                    <p className="_valor">
                                        {formatCurrencyStyled(concepto.valorUnitario)}
                                    </p>
                                    <div className="_impuestos">
                                        {concepto.impuestos.map((tax, i) => (
                                            <p key={i}>
                                                {tax.type.toUpperCase()}{tax.retencion ? ' retenido: ' : ':'} {tax.tasa ? `${Math.round((tax.tasa * 100) * 10000) / 10000}%` : (tax.cuota ? `$ ${Math.round(tax.cuota * 100) / 100} / unidad` : '0%')}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="totalWrap">
                            <div className="_letra">
                                <p>
                                    {numeroALetras(total, {
                                        plural: 'pesos mexicanos',
                                        singular: 'pesos mexicanos',
                                        centPlural: 'centavos',
                                        centSingular: 'centavo'
                                    }).toLowerCase()}
                                </p>
                            </div>
                            <div className="_totales">
                                <div>
                                    <p className="_subtotal">{formatCurrencyStyled(subtotal)}</p>
                                    <p className="_subtotal">Subtotal</p>
                                </div>
                                {
                                    ieps_retenido ?
                                        <div className="_tax">
                                            <p className="_tax">{formatCurrencyStyled(ieps_retenido)}</p>
                                            <p className="_tax">IEPS Retenido</p>
                                        </div>
                                        : ''
                                }
                                {
                                    ieps_trasladado ?
                                        <div className="_tax">
                                            <p className="_tax">{formatCurrencyStyled(ieps_trasladado)}</p>
                                            <p className="_tax">IEPS Trasladado</p>
                                        </div>
                                        : ''
                                }
                                {
                                    iva_retenido ?
                                        <div className="_tax">
                                            <p className="_tax">{formatCurrencyStyled(iva_retenido)}</p>
                                            <p className="_tax">IVA Retenido</p>
                                        </div>
                                        : ''
                                }
                                {
                                    iva_trasladado ?
                                        <div className="_tax">
                                            <p className="_tax">{formatCurrencyStyled(iva_trasladado)}</p>
                                            <p className="_tax">IVA Trasladado</p>
                                        </div>
                                        : ''
                                }
                                {
                                    isr ?
                                        <div className="_tax">
                                            <p className="_tax">{formatCurrencyStyled(isr)}</p>
                                            <p className="_tax">ISR Retenido</p>
                                        </div>
                                        : ''
                                }
                                <div>
                                    <p className="_total">
                                        <b>{formatCurrencyStyled(total)}</b>
                                    </p>
                                    <p className="_total">
                                        <b>Total</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderSecondaryBills = () => {
        if (!toBillEvents) {
            return ''
        }
        return toBillEvents.map((billEvent, i) => {
            const notaTotal = totales(!!billEvent.NotaCreditoCFDI ? billEvent.NotaCreditoCFDI.conceptos : []);
            const { subtotal, ieps_retenido, ieps_trasladado, iva_retenido, iva_trasladado, isr, total } = totales(!!billEvent.CFDI ? billEvent.CFDI.conceptos : [])
            return (
                <div className="billEventGroupper" key={i}>
                    {
                        !billEvent.NotaCreditoCFDI ?
                            '' :
                            <div>
                                <h4>Nota de crédito para el adelanto</h4>

                                <div className="card table">
                                    <div className="cardBody">
                                        <div className="row emisor">
                                            <div className="row two">
                                                <Input value={billEvent.NotaCreditoCFDI.serie || 'A'} label="Serie" type="text" onChange={handleNotaCreditoEdit('serie')} />
                                                <Input value={billEvent.NotaCreditoCFDI.folio || '1'} label="Folio" type="text" onChange={handleNotaCreditoEdit('folio')} />
                                            </div>
                                        </div>
                                        <div className="row emisor">
                                            <Input value={billEvent.NotaCreditoCFDI.receptor.nombre} label="Receptor" type="text" disabled={true} />
                                            <Input value={billEvent.NotaCreditoCFDI.receptor.rfc} label="RFC Receptor" type="text" disabled={true} />
                                            <div className="row two">
                                                <Input value={billEvent.NotaCreditoCFDI.formaPago} label="Forma de pago" type="text" disabled={true} />
                                                <Input value={billEvent.NotaCreditoCFDI.metodoPago} label="Metodo de pago" type="text" disabled={true} />
                                            </div>
                                        </div>
                                        <div className="_wrapNota">
                                            <div className="conceptosWrap">
                                                <span className="label">Conceptos:</span>
                                                {billEvent.NotaCreditoCFDI.conceptos.map((concepto, i) => (
                                                    <div className="concepto" key={i}>
                                                        <p>#{i + 1}</p>
                                                        <p className="_name">{concepto.descripcion}</p>
                                                        <div className="_claves">
                                                            <p><span>Clave ProdServ:</span> {concepto.claveProdServ}</p>
                                                            <p><span>Clave Unidad:</span> {concepto.claveUnidad}</p>
                                                        </div>
                                                        <p className="_cantidad">
                                                            (x{concepto.cantidad})
                                                        </p>
                                                        <p className="_valor">
                                                            {formatCurrencyStyled(concepto.valorUnitario)}
                                                        </p>
                                                        <div className="_impuestos">
                                                            {concepto.impuestos.map((tax, i) => (
                                                                <p key={i}>
                                                                    {tax.type.toUpperCase()}{tax.retencion ? ' retenido: ' : ':'} {tax.tasa ? `${Math.round((tax.tasa * 100) * 100000) / 100000}%` : (tax.cuota ? `$ ${Math.round(tax.cuota * 100) / 100} / unidad` : '0%')}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="totalWrap">
                                                <div className="_letra">
                                                    <p>
                                                        <b>Nota de crédito por </b>
                                                        {numeroALetras(notaTotal.total, {
                                                            plural: 'pesos mexicanos',
                                                            singular: 'pesos mexicanos',
                                                            centPlural: 'centavos',
                                                            centSingular: 'centavo'
                                                        }).toLowerCase()}
                                                    </p>
                                                </div>
                                                <div className="_totales">
                                                    <div>
                                                        <p className="_subtotal">{formatCurrencyStyled(notaTotal.subtotal)}</p>
                                                        <p className="_subtotal">Subtotal</p>
                                                    </div>
                                                    {
                                                        ieps_retenido ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(notaTotal.ieps_retenido)}</p>
                                                                <p className="_tax">IEPS Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        ieps_trasladado ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(notaTotal.ieps_trasladado)}</p>
                                                                <p className="_tax">IEPS Trasladado</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        iva_retenido ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(notaTotal.iva_retenido)}</p>
                                                                <p className="_tax">IVA Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        iva_trasladado ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(notaTotal.iva_trasladado)}</p>
                                                                <p className="_tax">IVA Trasladado</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        isr ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(notaTotal.isr)}</p>
                                                                <p className="_tax">ISR Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    <div>
                                                        <p className="_total">
                                                            <b>{formatCurrencyStyled(notaTotal.total)}</b>
                                                        </p>
                                                        <p className="_total">
                                                            <b>Total</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    {
                        !billEvent.CFDI ?
                            '' :
                            <div>
                                <h4>Factura de liquidación completa</h4>

                                <div className="card table">
                                    <div className="cardBody">
                                        <div className="_wrapCFDI">
                                            <div className="row emisor">
                                                <div className="row two">
                                                    <Input value={billEvent.CFDI.serie || 'A'} label="Serie" type="text" onChange={handleCfdiEdit('serie')} />
                                                    <Input value={billEvent.CFDI.folio || '1'} label="Folio" type="text" onChange={handleCfdiEdit('folio')} />
                                                </div>
                                            </div>
                                            <div className="row emisor">
                                                <Input value={billEvent.CFDI.receptor.nombre} label="Receptor" type="text" disabled={true} />
                                                <Input value={billEvent.CFDI.receptor.rfc} label="RFC Receptor" type="text" disabled={true} />
                                                <div className="row two">
                                                    <Input value={billEvent.CFDI.formaPago} label="Forma de pago" type="text" disabled={true} />
                                                    <Input value={billEvent.CFDI.metodoPago} label="Metodo de pago" type="text" disabled={true} />
                                                </div>
                                            </div>
                                            <div className="conceptosWrap">
                                                <span className="label">Conceptos:</span>
                                                {billEvent.CFDI.conceptos.map((concepto, i) => (
                                                    <div className="concepto" key={i}>
                                                        <p>#{i + 1}</p>
                                                        <p className="_name">{concepto.descripcion}</p>
                                                        <div className="_claves">
                                                            <p><span>Clave ProdServ:</span> {concepto.claveProdServ}</p>
                                                            <p><span>Clave Unidad:</span> {concepto.claveUnidad}</p>
                                                        </div>
                                                        <p className="_cantidad">
                                                            (x{concepto.cantidad})
                                                        </p>
                                                        <p className="_valor">
                                                            {formatCurrencyStyled(concepto.valorUnitario)}
                                                        </p>
                                                        <div className="_impuestos">
                                                            {concepto.impuestos.map((tax, i) => (
                                                                <p key={i}>
                                                                    {tax.type.toUpperCase()}{tax.retencion ? ' retenido: ' : ':'} {tax.tasa ? `${Math.round((tax.tasa * 100) * 100000) / 100000}%` : (tax.cuota ? `$ ${Math.round(tax.cuota * 100) / 100} / unidad` : '0%')}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="totalWrap">
                                                <div className="_letra">
                                                    <p>
                                                        {numeroALetras(total, {
                                                            plural: 'pesos mexicanos',
                                                            singular: 'pesos mexicanos',
                                                            centPlural: 'centavos',
                                                            centSingular: 'centavo'
                                                        }).toLowerCase()}
                                                    </p>
                                                </div>
                                                <div className="_totales">
                                                    <div>
                                                        <p className="_subtotal">{formatCurrencyStyled(subtotal)}</p>
                                                        <p className="_subtotal">Subtotal</p>
                                                    </div>
                                                    {
                                                        ieps_retenido ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(ieps_retenido)}</p>
                                                                <p className="_tax">IEPS Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        ieps_trasladado ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(ieps_trasladado)}</p>
                                                                <p className="_tax">IEPS Trasladado</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        iva_retenido ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(iva_retenido)}</p>
                                                                <p className="_tax">IVA Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        iva_trasladado ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(iva_trasladado)}</p>
                                                                <p className="_tax">IVA Trasladado</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        isr ?
                                                            <div className="_tax">
                                                                <p className="_tax">{formatCurrencyStyled(isr)}</p>
                                                                <p className="_tax">ISR Retenido</p>
                                                            </div>
                                                            : ''
                                                    }
                                                    <div>
                                                        <p className="_total">
                                                            <b>{formatCurrencyStyled(total)}</b>
                                                        </p>
                                                        <p className="_total">
                                                            <b>Total</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            )
        })
    }

    // const _upfrontError = toBillArgument.multipayType === 1 && (totales())
    const _error = paymentError || null;

    return (
        <div id="FacturaBillPreview">
            <SectionTitleBar currentUser={currentUser} title="Previsualizar Factura" />
            <div className="previewContent">
                {renderMainBill()}
                {renderSecondaryBills()}
                <div className="actions">
                    {
                        _error ?
                            <p className="message error">
                                <span className="error">
                                    {_error}
                                </span>
                            </p>
                            : (stamping ?
                                <p className="message success">
                                    <span className="">
                                        Facturando, espere...
                                    </span>
                                </p>
                                : '')

                    }
                    <Button secondary={true} handleClick={returnToModify}>
                        <span>
                            Regresar
                        </span>
                    </Button>
                    <Button primary={true} disabled={!!_error || stamping} handleClick={submitArgument}>
                        <span>
                            Emitir Factura
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default PreviewBillEventFactura;