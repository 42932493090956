import React, { useState, useEffect } from 'react';


interface ToastEntry {
    type: 'warning' | 'confirm' | 'error',
    message: string,
    clearTs?: number,
    clickToClose?: boolean,
    id?: string,
    // parent execute
    onDelete: (id: string) => void;
}

export default function ToasterNotificationEntry(toast: ToastEntry) {
    const [toastAnimateOut, setToastAnimateOut] = useState<boolean>(false);
    const [uEanimateTO, setUEanimateTO] = useState<NodeJS.Timeout>(null)
    const [uEdeleteTO, setUEdeleteTO] = useState<NodeJS.Timeout>(null)

    useEffect(() => {
        if (toast) {
            const clearTs = (!!toast.clickToClose ? 42200 : (toast.clearTs || 3600));
            setUEanimateTO(setTimeout(() => {
                setToastAnimateOut(true);
            }, clearTs));
            setUEdeleteTO(setTimeout(() => {
                toast.onDelete(toast.id)
            }, clearTs + 400));
        }
    }, [toast])

    const closeToast = () => {
        setToastAnimateOut(true);
        // Timout clearing avois scope update when destroyed error
        if (uEanimateTO) {
            clearTimeout(uEanimateTO)
        }
        if (uEdeleteTO) {
            clearTimeout(uEdeleteTO)
        }
        setTimeout(() => {
            toast.onDelete(toast.id)
        }, 400)
    }

    const {
        type,
        message,
        clickToClose,
    } = toast;

    return (
        <div className={`_toast ${type} ${toastAnimateOut ? '_getOut' : ''} ${clickToClose ? '_clickClose' : ''}`}>
            <div>

                {
                    message.split('\n').map((t, i) => (
                        <p key={i}>
                            {t}
                        </p>
                    ))
                }
            </div>
            {
                clickToClose ?
                    <i className="material-icons" onClick={closeToast}>
                        close
                    </i>
                    : null
            }
        </div>
    )
}