import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BankBudgetsActivity } from '../../../../models/Administracion/Bank.Budgets';
import Button from '../../../Forms/Button';
import Input from '../../../Forms/Input';
import BarStackedChart from '../../../Graphs/BarAndLine/BarStacked.chart';
import { formatCurrencyStyled } from '../../../visuals/currency.formatted';
import ToolTip from '../../../visuals/tooltip';

import './PresupuestosConfigure.Details.graph.scss';
const { jStat } = require('jstat');


interface PresupuestosConfigureDetailGraphProps {
    subcategory: BankBudgetsActivity;
    onBudgetSubmit: (amount: number) => void;
    onBarMovementsExplore: (subcategory: string, monthTs: number) => void;
}

const stdevMeanRatioInterpretation = [
    { label: 'pocos datos', maxValue: 0, color: "#c242a2", explain: 'Se necesitan más datos para calcular correctamente' },
    { label: 'muy constante', maxValue: .10, color: "#3597EC", explain: 'El gasto varía muy poco y parece tener un valor recurrente' },
    { label: 'constante', maxValue: .25, color: "#2CD58A", explain: 'No cambia mucho de mes a mes, asignar un presupuesto esperado' },
    { label: 'predecible', maxValue: .5, color: "#7D7F8C", explain: 'Es recomendable presupuestar un poco más del promedio para cubrir variabilidad' },
    { label: 'variable', maxValue: 1.00, color: "#FFB15D", explain: 'Cambia mucho de mes a mes, se puede asignar un pesupuesto mayor para cubrirlo o especificar uno como objetivo' },
    { label: 'muy variable', maxValue: 2.00, color: "#FF7977", explain: 'Difícil de predecir, aplicar el presupuesto recomendado si es una subcategoría importante, de lo contrario elegir uno apropiado' },
    { label: 'volátil', maxValue: Infinity, color: "#B21C7B", explain: 'Este gasto cambia demasiado para estimarse, asigna el presupuesto que desées como objetivo solamente' },
]

function PresupuestosConfigureDetailGraph({
    subcategory,
    onBudgetSubmit,
    onBarMovementsExplore
}: PresupuestosConfigureDetailGraphProps) {

    const [stdevMeanRatioToUse, setStdevMeanRatioToUse] = useState<{ label: string, maxValue: number, color: string, explain: string }>(null)
    const [stdevMeanRatio, setStdevMeanRatio] = useState<number>(null);
    const [average, setAverage] = useState<number>(null);

    const [newGoal, setNewGoal] = useState<number>(subcategory.goal || 0)
    const [submitting, setSubmitting] = useState(false);

    const MONTH_TS = moment().startOf('month').valueOf();
    // TODO, Automatic "Proyectado" is still pending to apply

    useEffect(() => {
        console.log(`Subcategory: `, subcategory);
        const stDev = jStat.stdev(subcategory.expenses.map(d => d.expense), true);
        const average = Math.round((subcategory.expenses.reduce((p, c) => p += c.expense, 0) / (subcategory.expenses.length || 1)) * 100) / 100;
        const stdevMeanRatio = !stDev ? 0 : stDev / average;
        console.group('Graph STD')
        console.log(`stDev: `, stDev)
        console.log(`average: `, average)
        console.log(`stdevMeanRatio: `, stdevMeanRatio)
        console.groupEnd()
        const stdevMeanRatioApplicableIndex = !stdevMeanRatio ? 0 : stdevMeanRatioInterpretation.findIndex(st => stdevMeanRatio <= st.maxValue);
        const stdevMeanRatioApplicable = stdevMeanRatioInterpretation[stdevMeanRatioApplicableIndex];

        setStdevMeanRatioToUse(stdevMeanRatioApplicable)
        setAverage(average);
        setStdevMeanRatio(stdevMeanRatio);
        setSubmitting(false);
    }, [subcategory])

    const onCancel = () => {
        setNewGoal(subcategory.goal || 0);
    }
    const onSubmitBudget = () => {
        onBudgetSubmit(newGoal);
        setSubmitting(true)
    }

    const exploreBarMovements = (subcategory: string, monthTs: number) => (data: { x: number, y: number }) => {
        onBarMovementsExplore(subcategory, monthTs)
    }

    return (
        <div className='configureDetailsGraph'>
            <div className='_graphKeys'>
                <div>
                    <div className='_key' style={{ background: '#4E88DF' }} />
                    <p>
                        Gasto cubierto
                    </p>
                </div>
                <div>
                    <div className='_key' style={{ background: '#F1CEE3' }} />
                    <p>
                        Mes actual proyectado
                    </p>
                </div>
                <div>
                    <div className='_key' style={{ background: '#AC2E78' }} />
                    <p>
                        Gasto mes actual
                    </p>
                </div>
            </div>
            <div className='_graph'>
                {
                    subcategory.expenses.length ?
                        <BarStackedChart
                            margin={{ left: 40, top: 20, bottom: 40, right: 40 }}
                            xAxisFormatting={(d: number) => moment(d).format('MMMM')}
                            barWidth={45}
                            xAxisTicks={'from-data'}
                            barGroups={
                                {
                                    name: subcategory.subcategory,
                                    values: subcategory.expenses.map((ex) => ({
                                        x: ex.monthTs,
                                        y: ex.expense,
                                        color: ex.monthTs >= MONTH_TS ? '#AC2E78' : '#4E88DF',
                                        onClick: exploreBarMovements(subcategory.subcategory, ex.monthTs)
                                    })).concat(subcategory.goal ? [{ x: MONTH_TS, y: subcategory.goal, color: '#F1CEE3', onClick: null }] : [])
                                }
                            }
                        />
                        :
                        <BarStackedChart
                            margin={{ left: 40, top: 20, bottom: 40, right: 40 }}
                            xAxisFormatting={(d: number) => moment(d).format('MMMM')}
                            barWidth={45}
                            xAxisTicks={'auto'}
                            barGroups={
                                {
                                    name: subcategory.subcategory,
                                    values: ([
                                        {
                                            y: 3000,
                                            x: moment(MONTH_TS).subtract(1, 'month').valueOf(),
                                            color: 'transparent',

                                        }
                                    ]).concat(subcategory.goal ? [{ x: MONTH_TS, y: subcategory.goal, color: '#F1CEE3' }] : [])
                                }
                            }
                        />
                }
                {
                    !subcategory.expenses.length ?
                        <div className='empty'>
                            <div>
                                <p>
                                    No hay movimientos asignados a esta categoría o no hay datos suficientes para visualizarla
                                </p>
                                <p>
                                    Puedes revisar <NavLink to={`/administration/movimientos?category=__null`}>movimientos pendientes de configurar aquí</NavLink>
                                </p>
                            </div>
                        </div>
                        : null
                }
            </div>
            <div className='_inform'>
                <div>
                    <p className='_key'>
                        Presupuesto recomendado:
                    </p>
                    {
                        stdevMeanRatio !== null && average !== null ?
                            <p className='_value'>
                                Menor a {formatCurrencyStyled((1 + stdevMeanRatio) * average)}
                            </p>
                            : null
                    }
                </div>
                <div>
                    <p className='_key'>
                        Gasto promedio:
                    </p>
                    {
                        average !== null ?
                            <p className='_value'>
                                Menor a {formatCurrencyStyled(average)}
                            </p>
                            : null
                    }
                </div>
                <div>
                    <p className='_key'>
                        Variabilidad:
                    </p>
                    {
                        !!stdevMeanRatioToUse ?
                            <ToolTip text={stdevMeanRatioToUse.explain} justify="right">
                                <p style={{ color: stdevMeanRatioToUse.color }}>
                                    {stdevMeanRatioToUse.label}
                                </p>
                            </ToolTip>
                            : null
                    }
                </div>
            </div>
            <div className='_actions'>
                <Input
                    label='Asignar presupuesto'
                    isCurrency={true}
                    value={newGoal}
                    type="number"
                    onChange={setNewGoal}
                />
                <Button secondary={true} handleClick={onCancel} disabled={submitting}>
                    <span>
                        Cancelar
                    </span>
                </Button>
                <Button primary={true} handleClick={onSubmitBudget} disabled={submitting}>
                    <span>
                        Guardar <span className='xss-hidden'>Presupuesto</span>
                    </span>
                </Button>
            </div>
        </div>
    )
}

export default PresupuestosConfigureDetailGraph;