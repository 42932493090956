import React, { useEffect, useState } from 'react';

import {
    select,
    hierarchy, treemap, scaleLinear, min, max, treemapSquarify
} from 'd3';

import './Treemap.chart.scss';
import useWindowResize from '../../Hooks/useWindowResize';


interface TreemapChartProps {
    name: string;
    data?: {
        name: string;
        value: number;
        color?: string;
    }[]
    margin: { left?: number; right?: number; top?: number; bottom?: number }

}

function TreeMapChart({ name, data, margin }: TreemapChartProps) {
    const { windowWidth } = useWindowResize()
    const containerRef = React.createRef<HTMLDivElement>();

    const [height, setHeight] = useState<number>(null);
    const [width, setWidth] = useState<number>(null);

    useEffect(() => {
        if (containerRef) {
            const _height = containerRef.current.getBoundingClientRect().height;
            const _width = containerRef.current.getBoundingClientRect().width;


            setHeight(_height)
            setWidth(_width)
        }
    }, [containerRef, windowWidth])


    useEffect(() => {
        const initData = data.filter(d => d.value > 0);
        if (width && height && initData.length) {
            const dataToTree = { children: initData.map(d => ({ name: d.name, value: d.value, group: 'A' })), name: name }
            const root = hierarchy(dataToTree).sum((d: any) => {
                return d.value
            });

            try {

                const MIN = min(initData.map(d => d.value));
                const MAX = max(initData.map(d => d.value));
                const fontSizeScale = scaleLinear().range([15, 26]).domain([MIN, MAX]);

                console.log(`initData: `, initData)
                treemap()
                    .size([width - (margin.left + margin.right), height - (margin.top + margin.bottom)])
                    .padding(2)
                    .tile(treemapSquarify.ratio(3))
                    (root);

                // Does it has SVG yet? 
                const hasSvg = select(containerRef.current).select<SVGSVGElement>('svg')
                const svg = !hasSvg.empty() ? hasSvg : select(containerRef.current).append('svg');
                svg
                    .attr('width', width)
                    .attr('height', height)

                // Does it have child G ? (This ius main G)
                const hasG = svg.select<SVGGElement>('g');
                const svgG = !hasG.empty() ? hasG : svg.append('g');
                svgG.attr('transform', `translate(${(margin.left || 0)},${(margin.top || 0)})`)

                svgG.selectAll("rect").remove()
                svgG.selectAll("text.label").remove()

                svgG.selectAll("rect")
                    .data(root.leaves())
                    .enter()
                    .append("rect")
                    .attr('x', (d: any) => { return d.x0; })
                    .attr('y', (d: any) => { return d.y0; })
                    .attr('width', (d: any) => { return d.x1 - d.x0; })
                    .attr('height', (d: any) => { return d.y1 - d.y0; })
                    .style("fill", (d: any, i) => initData[i].color)

                const labelsPreText = svgG.selectAll("text.label")
                    .data(root.leaves())
                    .enter()
                    .append("text")
                    .attr("class", (d: any) => {
                        // >> Cases where container is too small, should hide text
                        const _height = d.y1 - d.y0;
                        const _width = d.x1 - d.x0;
                        if((_height < 13) || (_width < 13)) {
                            return `label capitalize hide`
                        }
                        return `label capitalize`
                    })
                    .attr('transform', (d: any) => {
                        return `translate(${d.x0 + 6}, ${d.y0 + 12})`
                    })
                    .attr("font-size", '10px')
                    .attr("font-weight", '500')
                    .attr("fill", "white");


                // Split labels in spans
                labelsPreText
                    .each(function (d: any, i) {
                        const _width = (d.x1 - d.x0) - 5;
                        const _fontSize = () => {
                            const FITHEIGHT = 7 + fontSizeScale(d.data.value);
                            if ((d.y1 - d.y0) < FITHEIGHT) {
                                return (d.y1 - d.y0) - 2;
                            }
                            return fontSizeScale(d.data.value);
                        }
                        const fontSize = _fontSize();
                        const leftOffset = -3;
                        const text: string = d.data.name;
                        const letters = text.split('');
                        const textEl = select(this);
                        let currentSpan = textEl.append('tspan').attr('x', leftOffset).attr('dy', 0).text('');
                        letters.forEach((letter, i) => {
                            const textWidth = currentSpan.node().getComputedTextLength();
                            if (textWidth < (_width - 2)) {
                                const currentText = currentSpan.text();
                                const newText = `${currentSpan.text()}${letter}`;
                                currentSpan.text(newText);
                                const nextTextWidth = currentSpan.node().getComputedTextLength();
                                if (nextTextWidth > _width) {
                                    // extending text would overflow, return to previous and add new span
                                    currentSpan.text(currentText);
                                    currentSpan = textEl.append('tspan').attr('x', leftOffset).attr('dy', `${fontSize}px`).text(letter === ' ' ? '' : letter);
                                }
                            } else {
                                currentSpan = textEl.append('tspan').attr('x', leftOffset).attr('dy', `${fontSize}px`).text(letter === ' ' ? '' : letter);
                            }
                        });
                    })


            } catch (e) {
                console.error(`<TREE Chart> error`, e);
            }
        }
    }, [name, data, height, width, margin, containerRef])

    return (
        <div className='_treeMapChartContainer' >
            <div className='_chartOverflowContain' ref={containerRef}>

            </div>
        </div>
    )
}

export default TreeMapChart;