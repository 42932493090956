import React, { useEffect, useState } from 'react';

import { select, scaleLinear, axisBottom, axisLeft, format, line, curveMonotoneX } from 'd3';

import './BarAndLine.chart.scss';
import useWindowResize from '../../Hooks/useWindowResize';
import { numberToCurrencyString } from '../../../services/formatting';


interface BarAndLineChartProps {
    barGroups?: {
        name?: string;
        color: string;
        values: { x: number, y: number }[]
    }[]
    lineGroups?: {
        name?: string;
        color: string;
        values: { x: number, y: number }[]
    }[];
    margin: { left?: number; right?: number; top?: number; bottom?: number }
    xAxisTicks?: 'from-data' | 'auto'
    xAxisFormatting?: (d: number) => string;
}

function BarAndLineChart({ barGroups, lineGroups, margin, xAxisTicks, xAxisFormatting }: BarAndLineChartProps) {
    const { windowWidth } = useWindowResize()
    const containerRef = React.createRef<HTMLDivElement>();
    const tooltipRef = React.createRef<HTMLDivElement>();

    const [height, setHeight] = useState<number>(null);
    const [width, setWidth] = useState<number>(null);

    useEffect(() => {
        if (containerRef) {
            const _height = containerRef.current.getBoundingClientRect().height;
            const _width = containerRef.current.getBoundingClientRect().width;


            setHeight(_height)
            setWidth(_width)
        }
    }, [containerRef, windowWidth])


    useEffect(() => {
        if (width && height && tooltipRef?.current) {
            try {

                // Does it has SVG yet? 
                const hasSvg = select(containerRef.current).select<SVGSVGElement>('svg')
                const svg = !hasSvg.empty() ? hasSvg : select(containerRef.current).append('svg');
                svg
                    // .attr('width', width + (margin.left || 0) + (margin.right || 0))
                    // .attr('height', height + (margin.top || 0) + (margin.bottom || 0))
                    .attr('width', width)
                    .attr('height', height)

                // Does it have child G ? (This ius main G)
                const hasG = svg.select<SVGGElement>('g');
                const svgG = !hasG.empty() ? hasG : svg.append('g');
                svgG.attr('transform', `translate(${(margin.left || 0)},${(margin.top || 0)})`)



                // X Axis
                // ============
                const xData = [...new Set((!!barGroups ? barGroups.reduce((p, c) => p.concat(c.values.map(v => v.x)), [] as number[]) : []).concat((!!lineGroups ? lineGroups.reduce((p, c) => p.concat(c.values.map(v => v.x)), [] as number[]) : [])).sort((a, b) => a - b))];
                const xAxis = scaleLinear()
                    .domain([Math.min(...xData), Math.max(...xData)])
                    .range([(margin.left || 0), width - ((margin.left || 0) + (margin.right || 0))])

                const hasXaxisG = svgG.select<SVGGElement>('g._xAxis');
                const xAxisG = !hasXaxisG.empty() ? hasXaxisG : svgG.append('g').attr('class', '_xAxis');

                let xAxisConf = axisBottom(xAxis);
                if (xAxisTicks === 'from-data') {
                    xAxisConf = xAxisConf.tickValues(xData)
                }
                if (xAxisFormatting) {
                    xAxisConf = xAxisConf.tickFormat((d, i) => xAxisFormatting(d as any as number))
                }

                xAxisG
                    .attr("transform", `translate(0, ${height - ((margin.bottom || 0) + (margin.top || 0))})`)
                    .call(xAxisConf)
                    .selectAll("text")
                    .attr("transform", "translate(0,0)")
                    .attr('font-family', '"DM Sans", sans-serif')
                    .style("text-anchor", "center")

                xAxisG
                    .select(".domain")
                    .attr("stroke", "#A4A5B3")
                    .attr("opacity", "0")

                // Y Axis
                // ============
                const yData = [...new Set((!!barGroups ? barGroups.reduce((p, c) => p.concat(c.values.map(v => v.y)), [0] as number[]) : []).concat((!!lineGroups ? lineGroups.reduce((p, c) => p.concat(c.values.map(v => v.y)), [] as number[]) : [])).sort((a, b) => a - b))];
                const yAxis = scaleLinear()
                    .domain([Math.min(...yData), Math.max(...yData)])
                    .range([height - ((margin.top || 0)) - (margin.bottom || 0), 0])


                const hasYaxisG = svgG.select<SVGGElement>('g._yAxis');
                const yAxisG = !hasYaxisG.empty() ? hasYaxisG : svgG.append('g').attr('class', '_yAxis');

                yAxisG.attr('transform', `translate(0, 0)`)
                const yAxisConf = axisLeft(yAxis).tickFormat(format('.2s'));
                yAxisG
                    .call(yAxisConf)
                yAxisG
                    .select(".domain")
                    .attr("opacity", "0")
                yAxisG
                    .selectAll('line')
                    .attr('x2', width - (margin.right || 0) - (margin.left || 0))
                    .attr("opacity", (d: any): string => {
                        if (d === 0) {
                            return "0.3"
                        }
                        return "0.10"
                    })

                yAxisG.selectAll('text').attr('font-family', '"DM Sans", sans-serif')

                // 1. BAR ENTRIES
                // =================
                if (!!barGroups && barGroups.length) {
                    const BAR_WIDTH = 12;

                    const hasBarG = svgG.select<SVGGElement>('g._bars');
                    const svgBarG = !hasBarG.empty() ? hasBarG : svgG.append('g').attr('class', '_bars');
                    svgBarG.attr('transform', `translate(${(-(BAR_WIDTH / 2))},${(0)})`)

                    const factorSpread = (n: number) => (index: number) => {
                        const i = (n - 1) / 2;
                        return (index - i)
                    }
                    let groupIndex = 0;

                    for (const group of barGroups) {
                        const hasBarGroupG = svgBarG.select<SVGGElement>(`g._bars_${groupIndex}`);
                        const svgBarGroupG = !hasBarGroupG.empty() ? hasBarGroupG : svgBarG.append('g').attr('class', `_bars_${groupIndex}`);
                        const factorSpreadValue = factorSpread(barGroups.length)(groupIndex) * (BAR_WIDTH + 2)
                        svgBarGroupG.attr('transform', `translate(${factorSpreadValue})`)

                        // Clear past Rects ?
                        svgBarGroupG.selectAll('rect').remove()

                        svgBarGroupG.selectAll('rect')
                            .data(group.values)
                            .enter()
                            .append('rect')
                            .attr("x", (d) => xAxis(d.x))
                            .attr("y", (d) => yAxis(d.y))
                            .attr("width", BAR_WIDTH)
                            .attr("height", (d) => ((height - (margin.bottom || 0) - (margin.top || 0)) - yAxis(d.y)))
                            .attr("fill", group.color)
                            .on('mouseenter', function () {
                                const _x = parseFloat(this.getAttribute('x')) + factorSpreadValue;
                                const _y = this.getAttribute('y')
                                const value = numberToCurrencyString(yAxis.invert(parseFloat(_y)));
                                if (tooltipRef?.current) {
                                    tooltipRef.current.classList.toggle('_display')
                                    tooltipRef.current.innerHTML = `<p>$${value}</p>`
                                    tooltipRef.current.style.transform = `translate(${_x}px, ${_y}px)`;
                                }
                            })
                            .on('mouseleave', function () {
                                if (tooltipRef?.current) {
                                    tooltipRef.current.classList.toggle('_display')
                                }
                            })

                        ++groupIndex;
                    }
                }

                // 2. LINE ENTRIES
                // ==================
                if (!!lineGroups && !!lineGroups.length) {
                    const hasLineG = svgG.select<SVGGElement>('g._line');
                    const svgLineG = !hasLineG.empty() ? hasLineG : svgG.append('g').attr('class', '_line');
                    svgLineG.attr('transform', `translate(${0},${0})`)


                    let groupIndex = 0;
                    for (const group of lineGroups) {
                        const hasLineGroupG = svgLineG.select<SVGGElement>(`g._lines_${groupIndex}`);
                        const svgLineGroupG = !hasLineGroupG.empty() ? hasLineGroupG : svgLineG.append('g').attr('class', `_lines_${groupIndex}`);
                        svgLineGroupG.attr('transform', `translate(${0},${(0)})`)

                        // Clear past Rects ?
                        svgLineGroupG.selectAll('path').remove()
                        svgLineGroupG.selectAll('circle').remove()

                        // Add the line
                        svgLineGroupG.append("path")
                            .datum(group.values)
                            .attr("fill", "none")
                            .attr("stroke", group.color)
                            .attr("stroke-width", 1.5)
                            .attr("d", line()
                                .x((d: any) => { return xAxis(d.x) })
                                .y((d: any) => { return yAxis(d.y) })
                                .curve(curveMonotoneX) as any)

                        svgLineGroupG
                            .selectAll('circle')
                            .data(group.values)
                            .enter()
                            .append('circle')
                            .attr('cx', (d) => xAxis(d.x))
                            .attr('cy', (d) => yAxis(d.y))
                            .attr('r', 5)
                            .attr("fill", group.color)
                            .attr("stroke", 'white')
                            .attr("stroke-width", 1)
                            .on('mouseenter', function () {
                                const _x = parseFloat(this.getAttribute('cx'));
                                const _y = this.getAttribute('cy')
                                const value = numberToCurrencyString(yAxis.invert(parseFloat(_y)));
                                if (tooltipRef?.current) {
                                    tooltipRef.current.classList.toggle('_display')
                                    tooltipRef.current.innerHTML = `<p>$${value}</p>`
                                    tooltipRef.current.style.transform = `translate(${_x}px, ${_y}px)`;
                                }
                            })
                            .on('mouseleave', function () {
                                if (tooltipRef?.current) {
                                    tooltipRef.current.classList.toggle('_display')
                                }
                            })


                        ++groupIndex;
                    }
                }

            } catch (e) {
                console.error(`<Donut Chart> error`, e);
            }
        }
    }, [barGroups, lineGroups, height, width, margin, containerRef, tooltipRef])

    return (
        <div className='_barLineChartContainer' >
            <div className={`_tooltip`} ref={tooltipRef}><p></p></div>
            <div className='_chartOverflowContain' ref={containerRef}>

            </div>
        </div>
    )
}

export default BarAndLineChart;