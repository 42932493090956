import React from 'react';

interface SvgProps {
    width: number;
    height: number;
}

function ProfesionalSvg({ height, width }: SvgProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 50 50" fill="none">
            <path d="M0.5 25.0229C0.5 11.5036 11.4696 0.5 25 0.5C38.5304 0.5 49.5 11.5036 49.5 25.0229C49.5 38.541 38.5316 49.5 25 49.5C11.4684 49.5 0.5 38.541 0.5 25.0229ZM5.73853 25.0229C5.73853 35.657 14.3573 44.2672 25 44.2672C35.6427 44.2672 44.2615 35.657 44.2615 25.0229C44.2615 14.3889 35.6427 5.77864 25 5.77864C14.3573 5.77864 5.73853 14.3889 5.73853 25.0229ZM12.9771 25.0229C12.9771 18.3792 18.3491 13.0115 25 13.0115C31.6509 13.0115 37.0229 18.3792 37.0229 25.0229C37.0229 31.6666 31.6509 37.0344 25 37.0344C18.3491 37.0344 12.9771 31.6666 12.9771 25.0229ZM18.2615 25.0229C18.2615 28.7367 21.2839 31.7557 25 31.7557C28.7161 31.7557 31.7385 28.7367 31.7385 25.0229C31.7385 21.3091 28.7161 18.2901 25 18.2901C21.2839 18.2901 18.2615 21.3091 18.2615 25.0229Z" fill="#5b50ef" stroke="#5b50ef">
            </path>
        </svg>
    )
}

export default ProfesionalSvg;