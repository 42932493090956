
import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';
import { BankProjectConcept_Expense } from '../../../../models/Administracion/Bank.Projects';
import unifiedMoment from '../../../../services/unifiedMoment';
import Button from '../../../Forms/Button';
import Input from '../../../Forms/Input';
import Select from '../../../Forms/Select';

import './Projection.expense.dialog.scss';

interface ProjectionExpenseDialogProps {
    isOpen: boolean;
    startDate: number;
    onRequestClose: () => void;
    onNewExpense: (expense: BankProjectConcept_Expense) => void
    onEditExpense: (expense: BankProjectConcept_Expense) => void
    existingExpenseNames: string[];
    toEditExpense?: BankProjectConcept_Expense
}


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '98vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

const EMPTY_EXPENSE = (startDate: number): BankProjectConcept_Expense => ({
    name: '',
    amount: 0,
    expenseType: 'total',
    monthRepetition: 1,
    startsAt: startDate
})

const amountTypeOptions = [
    { value: 'monthly', label: 'Cantidad Mensual' },
    { value: 'total', label: 'Monto Total' },
]

export function ProjectionExpenseNewDialog({
    isOpen,
    startDate,
    onRequestClose,
    onNewExpense,
    onEditExpense,
    existingExpenseNames,
    toEditExpense
}: ProjectionExpenseDialogProps) {

    const [expense, setExpense] = useState<BankProjectConcept_Expense>(EMPTY_EXPENSE(startDate))
    const [monthlyAmount, setMonthlyAmount] = useState(0);

    useEffect(() => {
        if (!isOpen) {
            setExpense(EMPTY_EXPENSE(startDate))
            setMonthlyAmount(0)
        }
        else if (isOpen && toEditExpense) {
            setExpense(toEditExpense)
            if (toEditExpense.expenseType === 'monthly') {
                setMonthlyAmount(Math.round((toEditExpense.amount / toEditExpense.monthRepetition) * 100) / 100);
            }
        }
        else {
            setExpense(exp => ({ ...exp, startsAt: startDate > exp.startsAt ? startDate : exp.startsAt }))
        }
    }, [isOpen, startDate, toEditExpense])

    const handleExpenseChange = (property: keyof BankProjectConcept_Expense) => (value: string | number) => {
        setExpense(exp => {
            const ups: { [key: string]: string | number } = {}
            if (property === 'name') {
                value = (value as string).substring(0, 30)
            }
            if (property === 'monthRepetition' && value < 1) {
                value = 1
            }
            ups[property] = value;

            return ({ ...exp, ...ups })
        })
        if (property === 'expenseType' && value === 'monthly') {
            setMonthlyAmount(expense.amount / expense.monthRepetition);
        }
        if (expense.expenseType === 'monthly' && property === 'monthRepetition' && value) {
            handleExpenseChange('amount')((value as number) * monthlyAmount);
        }
    }

    const handleMonthlyAmount = (value: number) => {
        setMonthlyAmount(value);
        handleExpenseChange('amount')(value * expense.monthRepetition);
    }

    const handleExpenseDateEndChange = (value: Date) => {
        let diff = unifiedMoment(value.getTime()).diff(expense.startsAt, 'months');
        console.log('DIFF: ', diff);
        handleExpenseChange('monthRepetition')(diff)
    }

    const handleExpenseDateChange = (value: Date) => {
        handleExpenseChange('startsAt')(value.getTime())
    }

    const addExpense = () => {
        onNewExpense(expense)
    }

    const updateExpense = () => {
        onEditExpense(expense)
    }


    const nameInputError = existingExpenseNames.filter(s => toEditExpense?.name?.toLowerCase() !== s).includes(expense.name.toLocaleLowerCase()) ? `Nombre ya usado` :
        ((expense.name.length > 29) ? '30 carácteres máximo' : '')
    const isValid = expense.name && expense.amount && expense.startsAt && !nameInputError;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Agregar nuevo proyecto"
        >
            <div className='card table' id="ProjectionExpenseDialog">
                <div className="cardTitle">
                    <span className="material-icons">
                        flag
                    </span>
                    {
                        toEditExpense ?
                            <h4>Editar gasto esperado</h4>
                            :
                            <h4>Agregar gasto esperado</h4>
                    }
                </div>
                <div className='cardBody'>
                    <div className='row'>
                        <h4>Gasto</h4>
                        <div className='row three sm-two'>
                            <Input label='Nombre de gasto' type='text' value={expense.name} onChange={handleExpenseChange('name')}
                                hasError={!!nameInputError}
                                errorLabel={nameInputError}
                            />
                            <Select label='Monto Total o Mensual' options={amountTypeOptions} value={expense.expenseType} onChange={handleExpenseChange('expenseType')} />
                            {
                                expense.expenseType === 'total' ?
                                    <Input label='Monto total' type='number' isCurrency value={expense.amount} onChange={handleExpenseChange('amount')} min={0} />
                                    :
                                    <Input label='Cantidad mensual' type='number' isCurrency value={monthlyAmount} onChange={handleMonthlyAmount} min={0} />
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <h4>Duración del Gasto</h4>
                        <div className='row three sm-two'>
                            <Input label={expense.expenseType === 'total' ? 'Fecha primer pago' : 'Mes inicial'} type='date'
                                value={expense.startsAt} onChange={handleExpenseDateChange} dateFormat="MMMM yyyy" datepickerFormat='months' minDate={new Date(startDate)} maxDate={unifiedMoment().add(3, 'years').endOf('month').toDate()} />
                            <Input label={expense.expenseType === 'total' ? 'Fecha último pago' : 'Mes Final'} type='date'
                                value={unifiedMoment(expense.startsAt).add(expense.monthRepetition, 'month').format('MMMM, YYYY')}
                                onChange={handleExpenseDateEndChange}
                                dateFormat="MMMM yyyy" datepickerFormat='months' minDate={unifiedMoment(startDate).add(1, 'month').toDate()} maxDate={unifiedMoment(startDate).add(240, 'month').toDate()}
                            />
                            {
                                expense.expenseType === 'total' ?
                                    null
                                    :
                                    <Input label={`¿Cuántas Mensualidades?`} type='number' value={expense.monthRepetition} onChange={handleExpenseChange('monthRepetition')} min={1} max={240} captionLabel={`Monto Total: $${Math.round(expense.amount * 100) / 100}`} />
                            }
                        </div>
                    </div>
                    <p className='small lightGray'>
                        <b>Recuerda: </b> <br />
                        El Monto total puede cubrirse sumando uno o varios movimientos a lo largo de diferentes meses.
                    </p>
                    <div className='actions row two '>
                        <div className='start'>
                            <Button secondary handleClick={onRequestClose}>
                                <span>
                                    Cancelar
                                </span>
                            </Button>
                        </div>
                        <div className='end'>
                            {
                                toEditExpense ?
                                    <Button primary disabled={!isValid} handleClick={updateExpense}>
                                        <span>
                                            Guardar
                                        </span>
                                    </Button>
                                    :
                                    <Button primary disabled={!isValid} handleClick={addExpense}>
                                        <span>
                                            Agregar Gasto
                                        </span>
                                    </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}