import React, { useState } from "react";

import './TextArea.scss';

interface TextAreaProps {
    onChange: Function;
    placeholder: string;
    label?: string;
    value: string;
    accept?: string;
    disabled?: boolean;
    hasError?: boolean;
}

const TextArea = React.forwardRef((props: TextAreaProps, ref: React.Ref<HTMLButtonElement>) => {
    const selectRef = React.createRef<HTMLTextAreaElement>();

    const [active, setActive] = useState(false);

    const activate = () => {
        setActive(true);
    }

    const deactivate = () => {
        setActive(false);
    }

    const changeValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => { 
        props.onChange(event.target.value);
    }

    const classInput = `inputWrapper ${props.disabled ? 'disabled' : ''} ${active ? 'active' : ''} ${props.hasError ? 'error' : ''}`;
    return (
        <div className="textAreaBlock">
            {
                !!props.label ?
                    <label>
                        {props.label}
                    </label>
                    : <label></label>
            }
            <div className={`${classInput}`} >
                {
                    <textarea 
                    ref={selectRef}
                    value={props.value}
                    onFocus={activate} onBlur={deactivate}
                    placeholder={props.placeholder} onChange={changeValue}
                    disabled={!!props.disabled} />

                }
            </div>
        </div>
    )

})

export default TextArea;