import React, { useState, FormEvent, useEffect } from 'react';
import './ResetPassword.scss';
import { BaseReact } from '../../base.model';
import Input from '../Forms/Input';
import { Link, Redirect, useParams } from 'react-router-dom';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { ProfessionalProfile } from '../../models/Profile';
import GraphQlClient from '../../services/graphql';


interface LostPasswordProps extends BaseReact { }

function ResetPassword({ history, location }: LostPasswordProps) {
    const { token } = useParams<{ token: string }>()
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const [tokenFetched, setTokenFetched] = useState(false);
    const [tokenValid, setTokenValid] = useState(false);

    const [invalidError, setInvalidError] = useState('');
    const [registerRedirect, setRegisterRedirect] = useState(false);
    const [registering, setRegistering] = useState(false);

    const [validateToken] = useMutation(graphqlSchema.PROFILE.validateToken, {
        onCompleted: ({ validateToken }: { validateToken: boolean }) => {
            setTokenValid(validateToken);
            setTokenFetched(true);
        },
        onError: (e) => {
            sharedToasterSubject.next({ type: 'error', message: 'Error confirmando el link' })
            console.error('Error testing password reset: ', e.graphQLErrors);
            setInvalidError(e.graphQLErrors[0].message);
            setTokenValid(false)
            setTokenFetched(true);

        }
    })

    const [resetPasswordToken] = useMutation(graphqlSchema.PROFILE.resetPasswordToken, {
        onCompleted: ({ resetPasswordToken }: { resetPasswordToken: { professionalProfile: ProfessionalProfile, token: string } }) => {
            console.log('Login: ', resetPasswordToken);
            // UseReducer to update object array
            setRegistering(false);
            GraphQlClient.logSession(resetPasswordToken.token)
                .then(d => {
                    console.log('Me Result: ', d);
                    setRegisterRedirect(true);
                });

        },
        onError: (e) => {
            sharedToasterSubject.next({ type: 'error', message: 'Error confirmando el link' })
            console.error('Error testing password reset: ', e);
        }
    })

    useEffect(() => {
        validateToken({
            variables: {
                token
            }
        })
    }, [token, validateToken])


    const submitRecover = (e: FormEvent) => {
        e.preventDefault();
        setRegistering(true);
        resetPasswordToken({
            variables: {
                token: token,
                password
            }
        })
    }

    if (!tokenFetched) {
        return (
            <div id="ResetPassword">
                <div className="_loginForm">
                    <h2>Confirmando validez del link</h2>
                    <p>Espere...</p>
                </div>
            </div>
        )
    }

    const renderInvalid = () => {
        return (
            <div id="ResetPassword">
                <div className="_loginForm">
                    <h2>No se puede recuperar la contraseña</h2>
                    <p>{invalidError}</p>
                    <div className="_toRegiser">
                        <Link to="/login">
                            <Button secondary={true} type="button">
                                <span>
                                    Regresar! ya me acordé
                                    </span>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    const renderResetForm = () => {
        return (
            <div id="ResetPassword">
                <form className="_loginForm" onSubmit={submitRecover}>
                    <h3>Escribe una nueva contraseña</h3>
                    <Input type="password" label="Contraseña" value={password} onChange={setPassword} placeholder="Tu nueva contraseña" />
                    <Input type="password" label="Repetir contraseña" value={passwordRepeat} onChange={setPasswordRepeat} placeholder="Repite tu nueva contraseña" />
                    <Button primary={true} type="submit" disabled={!passwordRepeat || !password || (password !== passwordRepeat) || registering}>
                        {
                            registering ?
                                <span>Aplicando contraseña</span>
                                :
                                <span>Cambiar contraseña</span>
                        }
                    </Button>
                    <div className="_toRegiser">
                        <Link to="/login">
                            <Button secondary={true} type="button" disabled={registering}>
                                <span>
                                    Regresar! ya me acordé
                                    </span>
                            </Button>
                        </Link>
                    </div>
                </form>
                {
                    registerRedirect ?
                        <Redirect to="/" />
                        : ''
                }
            </div>
        )
    }

    if (tokenValid) {
        return renderResetForm()
    } else {
        return renderInvalid();
    }

}

export default ResetPassword;