
import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../../../base.model';
import MoneySvg from '../../../visuals/svg/moneyIcon';

import { BankProject } from '../../../../models/Administracion/Bank.Projects'

import { numberToCurrencyString } from '../../../../services/formatting';
import unifiedMoment from '../../../../services/unifiedMoment';
import NumberDisplay from '../../../components/numberDisplay';
import BarAndLineChart from '../../../Graphs/BarAndLine/BarAndLine.chart';
import { Link } from 'react-router-dom';
import PercentDisplay from '../../../components/percentDisplay';

import './Projection.gastos.view.scss';

interface ProjectsProps {
    expenseProject: BankProject<'expense'>
    history: BaseReact['history']
}

interface BarProp { x: number, y: number }

export function ProjectionGastosView({
    expenseProject,
    history
}: ProjectsProps) {

    const [budgetTotal, setBudgetTotal] = useState(0)
    const [budgetPercent, setBudgetPercent] = useState(0)
    const [expenseTotal, setExpenseTotal] = useState(0)

    const [expenseBars, setExpenseBars] = useState<BarProp[]>([])
    const [expenseLine, setLineBars] = useState<BarProp[]>([])

    useEffect(() => {
        console.log(`<ProjectionGastosView> UE: `, expenseProject)
        const budgetTotal = expenseProject.expenses.reduce((p, c) => p += c.amount, 0);
        const expenseTotal = expenseProject.expenses.reduce((p, c) => p += c.transaction.reduce((_p, _c) => _p += Math.abs(_c.amount), 0), 0);
        console.log(`<ProjectionGastosView> UE - expenseTotal: `, expenseTotal)
        const expensePerMonth = expenseProject.expenses.reduce((base, exp) => {
            exp.transaction.forEach((trans) => {
                const monthTS = unifiedMoment(trans.dt_transaction).startOf('month').valueOf()
                const exists = base.find(b => b.x === monthTS);
                if (exists) {
                    exists.y += Math.abs(trans.amount);
                } else {
                    base.push({ x: monthTS, y: Math.abs(trans.amount) })
                }
            })
            return base;
        }, [] as BarProp[]).sort((a, b) => a.x - b.x)

        let lastAmount = 0;
        const expenseProgress = expensePerMonth.reduce((p, c) => {
            if (!p.length) {
                p.push(c)
            } else {
                p.push({ x: c.x, y: c.y + lastAmount })
            }

            lastAmount += c.y;
            return p;
        }, [] as BarProp[])

        setBudgetTotal(budgetTotal);
        setExpenseTotal(expenseTotal);
        setBudgetPercent(Math.round((expenseTotal / budgetTotal) * 100 * 100) / 100)

        setExpenseBars(expensePerMonth);
        setLineBars(expenseProgress);
    }, [expenseProject])

    return (
        <div id='ProjectosGastosView' className='card'>
            <div className='title'>
                <MoneySvg width={26} color={'#AC2E78'} />
                <h4>
                    {expenseProject.name}
                </h4>
                <div />
                <p className='lightGray small'>
                    Inicia {unifiedMoment(expenseProject.createdAt).format('MMMM DD, YYYY')}
                </p>
                <Link to={`/administration/projection/${expenseProject._id}/edit`} className="editLink">
                    <span>Editar Proyecto</span>
                    <span className="material-icons">
                        edit
                    </span>
                </Link>
            </div>
            <div className='row numberSections'>
                <div className='row three start'>
                    <NumberDisplay title='Presupuesto' addClass={'_budget'} value={budgetTotal} icon={null} coloring={'green'} />
                    <PercentDisplay title='Porcentaje de Gasto' addClass={budgetPercent > 100 ? 'warning' : ''} value={budgetPercent} icon={null} coloring={'green'} />
                    <NumberDisplay title='Gasto a la Fecha' value={expenseTotal} icon={null} coloring={'green'} />

                </div>
            </div>

            {
                !expenseBars.length && !expenseLine.length ?
                    <div className='chartSections'>
                        <div className='row _empty'>
                            <p className='center centerY gray'>
                                No hay movimientos asociados al proyecto todavía <br />
                                <span className='small'>
                                    Haz <Link to="/administration/movimientos">click aquí</Link> para asociar movimientos
                                </span>
                            </p>
                        </div>
                    </div>
                    :
                    <div className='chartSections'>
                        <div className='row'>
                            <BarAndLineChart
                                barGroups={[{
                                    name: 'Gastos por mes',
                                    color: '#AC2E78',
                                    values: expenseBars
                                }]}
                                margin={{ left: 40, top: 20, bottom: 20, right: 40 }}
                                xAxisTicks={'from-data'}
                                xAxisFormatting={(d: number) => unifiedMoment(d).format('MMMM')}
                            />
                        </div>
                        <div className='row'>
                            <BarAndLineChart
                                lineGroups={[{
                                    name: 'Gastos por mes',
                                    color: '#AC2E78',
                                    values: expenseLine
                                }]}
                                margin={{ left: 40, top: 20, bottom: 20, right: 40 }}
                                xAxisTicks={'from-data'}
                                xAxisFormatting={(d: number) => unifiedMoment(d).format('MMMM')}
                            />
                        </div>
                    </div>
            }
            <div className='row gastosSection'>
                <div className='gastosList'>
                    <div className='gasto header'>
                        <p>Gasto</p>
                        <p>Cantidad</p>
                        <p className='center'>Fecha de Inicio</p>
                        <p className='center'>Número de Meses</p>
                        <p className='center'>Porcentaje Gasto</p>
                        <p className='center'>Progreso gasto</p>
                    </div>
                    {
                        expenseProject.expenses.map((expense, i) => {
                            const expensePercent = Math.round(((expense.transaction.reduce((p, c) => p += Math.abs(c.amount), 0) / expense.amount) * 100) * 100) / 100
                            return (
                                <div className='gasto' key={i}>
                                    <p>{expense.name}</p>
                                    <div className='row'>
                                        <p>${numberToCurrencyString(expense.amount)}</p>
                                        {
                                            expense.expenseType === 'monthly' ?
                                                <p className='monthly'>(${numberToCurrencyString(expense.amount / expense.monthRepetition)} / mes)</p>
                                                : null
                                        }

                                    </div>
                                    <div className='_date'>
                                        <p className='center'> {unifiedMoment(expense.startsAt).format('MMM YYYY')}</p>
                                    </div>
                                    <p className='center'>
                                        {
                                            expense.monthRepetition > 1 ? `${expense.monthRepetition} Meses`
                                                : 'Evento Único'
                                        }
                                    </p>
                                    <p className={`center ${expensePercent > 100 ? 'red' : ''}`}>
                                        {expensePercent}%
                                    </p>
                                    <div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}