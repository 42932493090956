
import React from 'react';

import './BarPercent.pill.scss'


interface BarPercentPillProps {
    color: string;
    decimalPercent: number;
}

function BarPercentPill({ color, decimalPercent }: BarPercentPillProps) {

    const noOverflowPercent = decimalPercent > 1 ? 1 : decimalPercent;
    return (
        <div className='barPercent'>
            <p>
                {Math.round(decimalPercent * 100)}%
            </p>
            <div className='pill'>
                <div className='pillValue' style={{ transform: `scaleX(${noOverflowPercent})`, backgroundColor: color }}>

                </div>

            </div>
        </div>
    )
}

export default BarPercentPill;