import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { FormEvent, useEffect, useState } from 'react';

import Modal from 'react-modal';
import { CredentialResponse } from '../../../../models/Administracion/Bank.Credentials';
import { PaybookOrganization } from '../../../../models/Administracion/Banks';
import { graphqlSchema } from '../../../../services/graphql.schema';
import Button from '../../../Forms/Button';
import Input from '../../../Forms/Input';
import Select from '../../../Forms/Select';

import './SitesDialog.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '80vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

interface SitesDialog {
    openDialog: boolean;
    presetOrganizationId?: string;
    presetSiteId?: string;
    onClose: (credentialsSubmitted?: CredentialResponse) => void,
}

function SitesDialog({
    onClose,
    openDialog,
    presetSiteId,
    presetOrganizationId,
}: SitesDialog) {

    const [organizations, setOrganizations] = useState<PaybookOrganization[]>([])

    const [organizationId, setOrganizationId] = useState<string>('')
    const [siteId, setSiteId] = useState<string>('')
    const [credentials, setCredentials] = useState<{ [key: string]: string }>({})

    const [step, setStep] = useState<1 | 2 | 3>(1)
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if(presetSiteId && presetOrganizationId) {
            setOrganizationId(presetOrganizationId)
            setSiteId(presetSiteId)
            setStep(2)
        }
    }, [presetSiteId, presetOrganizationId])


    const onCloseAndReset = () => {
        onClose();
        setOrganizationId('')
        setSiteId('')
        setCredentials({})
        setStep(1);
    }

    const onCredentialsSubmitted = (credentialsSubmitted: CredentialResponse) => {
        onClose(credentialsSubmitted);
        setStep(1);
        setOrganizationId('')
        setSiteId('')
        setCredentials({})
    }

    useQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getOrganizations, {
        onCompleted: ({ getOrganizations }: { getOrganizations: PaybookOrganization[] }) => {
            console.log(`(SitesDialog) <getOrganizations> `, getOrganizations);
            setOrganizations(getOrganizations)
        }
    })


    const [submitCredentialData] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.submitCredential, {
        onCompleted: ({ submitCredential }: { submitCredential: CredentialResponse }) => {
            console.log('submitCredential: ', submitCredential);
            onCredentialsSubmitted(submitCredential)
        },
        onError: (e) => {
            console.error('Error Adding Credentials: ', e);
            setSubmitting(false);
        }
    });


    const selectOrganizationId = (id: string) => {
        console.log('SELECTED ', id);
        setOrganizationId(id);
        const selectedOrg = !id ? null : organizations.find(o => o.id_site_organization === id)
        if (selectedOrg) {
            setSiteId(selectedOrg.sites[0].id_site);
        }
    }

    const setCredential = (key: string) => (value: string) => {
        const _creds = Object.assign({}, credentials);
        _creds[key] = value;
        setCredentials(_creds);
    }

    const toCredentials = () => {
        if (!siteId) {
            return;
        }
        setStep(2)
    }

    const onCredentialSubmit = (e: FormEvent) => {
        e.preventDefault();
        const selectedOrg = organizations.find(o => o.id_site_organization === organizationId);
        const selectedSite = selectedOrg.sites.find(s => s.id_site === siteId);
        const submitDisabled = !selectedSite.credentials.every(cred => credentials[cred.name]);

        if (submitDisabled) {
            return;
        }
        if (submitting) {
            return;
        }

        setSubmitting(true)
        submitCredentialData({
            variables: {
                data: {
                    idSite: siteId,
                    credentialString: JSON.stringify(credentials)
                }
            }
        })
    }


    const renderOrganizations = () => {
        const selectedOrg = !organizationId ? null : organizations.find(o => o.id_site_organization === organizationId)
        const sites = !selectedOrg ? null : selectedOrg.sites;
        return (
            <div className={`firstStep ${step !== 1 ? 'inactive' : ''}`}>
                <div className={`bankChoose`}>
                    <div className='avatar'>
                        {
                            selectedOrg ?
                                <div
                                    key={selectedOrg.id_site_organization}
                                    className='avatarLogo animated'
                                    style={{ backgroundImage: `url('${selectedOrg.avatar}')` }}
                                />
                                : null
                        }
                    </div>
                    <div className='bankList'>
                        <Select
                            label='Elige tu banco'
                            value={organizationId}
                            onChange={selectOrganizationId}

                            placeholder="Elige una de las opciones"
                            options={organizations.map((o => ({
                                label: o.name,
                                value: o.id_site_organization
                            })))}
                        />
                        {
                            sites ?
                                <Select
                                    label='Elige tipo de cuenta'
                                    value={siteId}
                                    onChange={setSiteId}
                                    options={sites.map((o => ({
                                        label: o.name,
                                        value: o.id_site
                                    })))}
                                />
                                : null
                        }
                    </div>
                </div>
                <div className='actions'>
                    <Button tertiary={true} disabled={!siteId} handleClick={toCredentials}>
                        <div className='_actButton'>
                            <span>
                                Continuar
                            </span>
                            <span className="material-icons">
                                chevron_right
                            </span>
                        </div>
                    </Button>
                </div>
            </div>
        )
    }

    const renderSiteCredentials = () => {
        if (step < 2 || !siteId) {
            return null;
        }

        const selectedOrg = organizations.find(o => o.id_site_organization === organizationId);
        const selectedSite = !selectedOrg ? null : selectedOrg.sites.find(s => s.id_site === siteId);

        if (!selectedSite) {
            return null
        }
        const submitDisabled = !selectedSite.credentials.every(cred => credentials[cred.name]);

        return (
            <form className={`secondStep ${step !== 2 ? 'inactive' : ''}`} onSubmit={onCredentialSubmit}>
                <div className='bankProfile'>
                    <div className='avatar'>
                        {
                            selectedOrg ?
                                <div
                                    key={selectedOrg.id_site_organization}
                                    className='avatarLogo'
                                    style={{ backgroundImage: `url('${selectedOrg.avatar}')` }}
                                />
                                : null
                        }
                    </div>
                    <div className='bankNames'>
                        <p>
                            {selectedOrg.name}
                        </p>
                        <p>
                            <b>
                                {selectedSite.name}
                            </b>
                        </p>
                    </div>
                </div>
                <div className='credentials'>
                    {selectedSite.credentials.map((cred) => {
                        if (cred.type === 'select') {
                            return (
                                <Select
                                    key={`${selectedSite.name}-${cred.name}`}
                                    label={cred.label}
                                    placeholder="Selecciona una opción"
                                    value={credentials[cred.name] || ''}
                                    onChange={setCredential(cred.name)}
                                    options={cred.options}
                                />
                            )
                        }
                        return (
                            <Input
                                key={`${selectedSite.name}-${cred.name}`}
                                label={cred.label}
                                type={cred.type as 'text' | 'password'}
                                value={credentials[cred.name] || ''}
                                onChange={setCredential(cred.name)}
                            />
                        )
                    })}
                </div>
                <div className='actions'>
                    <Button type='submit' primary={true} disabled={submitDisabled || submitting}>
                        <span>
                            {submitting ? 'Guardando...' : 'Guardar'}
                        </span>
                    </Button>
                </div>
            </form>
        )
    }

    return (
        <Modal
            isOpen={openDialog}
            onRequestClose={onCloseAndReset}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Agregar nuevo cliente"
        >
            <div className='card table' id="newSiteDialog">
                <div className="cardTitle withClose">
                    <p>Agregar banco</p>
                    <i className="material-icons" onClick={onCloseAndReset}>
                        close
                    </i>
                </div>
                <div className='stepContent'>
                    {renderOrganizations()}
                    {renderSiteCredentials()}
                </div>
            </div>
        </Modal>
    )
}

export default SitesDialog;