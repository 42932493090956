import React from 'react';
import { numberToCurrencyString } from '../../services/formatting';

export const formatCurrencyStyled = (value: number) => {
    const [peso, cent] = numberToCurrencyString(value).split('.');
    return (
        <span>$ {peso}<span style={{ fontSize: '.645em', lineHeight: '1.5em', verticalAlign: 'text-top', paddingLeft: '2px', opacity: '0.7' }}>{cent}</span></span>
    )
}

export const formatNumberStyled = (value: number) => {
    const [peso, cent] = numberToCurrencyString(value).split('.');
    return (
        <span>{peso}<span style={{ fontSize: '.645em', lineHeight: '1.5em', verticalAlign: 'text-top', paddingLeft: '2px', opacity: '0.7' }}>{cent}</span></span>
    )
}