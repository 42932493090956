export const getCookies = () => {
    if(document.cookie){
        return document.cookie.split(' ').join('').split(';').reduce((p,c) => {
            const touple = c.split('=');
            p[touple[0]] = touple[1];
            return p;
        }, {} as {[key:string]:string})
    }else{
        return {} as {[key:string]:string};
    }
}