import React from 'react';
import Modal from 'react-modal';
import { PaybookCredentialResponse } from '../../../../models/Administracion/Bank.Credentials';
import Button from '../../../Forms/Button';

import './Credential.entry.cases.dialog.scss';

interface CredentialEntryCasesDialogProps {
    isOpen: boolean;
    credential: PaybookCredentialResponse;
    onProceed: () => void;
    onAbort: () => void;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        border: 'none',
        background: 'transparent',
        perspective: '1000px',
        overflow: 'visible',
        padding: '0px',
    }
};


export function CredentialEntryCaseAssistedRetryDialog({
    isOpen,
    credential,
    onProceed,
    onAbort
}: CredentialEntryCasesDialogProps) {
    if (!credential) return null;

    const canSync = !!credential.can_sync;
    const canRetryIn = credential.ready_in;


    // format milliseconds to seconds and minutes
    const formatMilliseconds = (milliseconds: number) => {
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);

        if (minutes > 0) {
            return `${minutes} min`;
        }
        return `${seconds} sec`;
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onAbort}
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            contentLabel="Assisted bank retry dialog"
        >
            <div className='card table' id="CredentialAssistedRetryDialog">
                <div className="cardTitle">
                    {
                        credential.code === 405 ?
                            <p>Cuenta Bancaria parece Bloqueada</p>
                            :
                            <p>Acción necesaria en Cuenta Bancaria</p>
                    }
                </div>
                {

                    credential.code === 405 ?
                        <div className='cardBody'>
                            <p>
                                <b>
                                    En este caso recomendamos que intentes acceder a tu banca en linea directamente antes de re-intentar la conexión en FiscalPOP.
                                </b>
                            </p>
                            <p>
                                Si tienes problemas para aceder personalmente a tu banca en linea, por favor contacta tu banco para resolverlo.
                            </p>
                            <p>
                                Cuenta Bloqueada puede suceder por varias razones, por ejemplo:
                            </p>
                            <ul>
                                <li>
                                    <p className='small gray'>
                                        Varios re-intentos de conexión fallidos.
                                    </p>
                                </li>
                                <li>
                                    <p className='small gray'>
                                        Accediste directamente a tu banca en línea recientemente y no se cerró la sesión.
                                    </p>
                                </li>
                                <li>
                                    <p className='small gray'>
                                        En algunos casos el banco requiere varios minutos (&gt; 30 mins) antes de permitir una nueva conexión.
                                    </p>
                                </li>
                                <li>
                                    <p className='small gray'>
                                        Mucho tiempo sin entrar a tu banca en línea y está desactivada.
                                    </p>
                                </li>
                                <li>
                                    <p className='small gray'>
                                        Algún error interno que cerró la sesión de tu banca.
                                    </p>
                                </li>
                                <li>
                                    <p className='small gray'>
                                        Banco considero la conexión como sospechosa y bloqueo la cuenta.
                                    </p>
                                </li>
                            </ul>
                            <p className='small'>
                                <b>En general, si puedes acceder a tu banca en linea directamente, debería ser posible la conexión en FiscalPOP.</b>
                            </p>
                        </div>
                        :
                        <div className='cardBody'>
                            <p>
                                <b>
                                    Tu cuenta bancaria a conectar requiere alguna acción.
                                </b>
                            </p>
                            <p>
                                Recibimos del banco una respuesta que indica que tu cuenta bancaria requiere alguna acción, sin mayor detalle a mostrar.
                            </p>
                            <p>
                                Recomendamos acceder a tu banca en linea directamente antes de re-intentar la conexión en FiscalPOP.
                            </p>
                            <p>
                                Recuerda que después de cerrar sesión en tu banca en línea puede ser necesario esperar varios minutos antes de poder acceder nuevamente desde FiscalPOP. (&gt; 30 mins)
                            </p>
                            <p>
                                <b>En general, si puedes acceder a tu banca en linea directamente, debería ser posible la conexión en FiscalPOP.</b>
                            </p>
                        </div>
                }
                <div className='actions'>
                    <Button secondary={true} handleClick={onAbort}>
                        <span>
                            Cancelar
                        </span>
                    </Button>
                    <Button primary={true} handleClick={onProceed} disabled={!!canRetryIn}>
                        {
                            !canRetryIn && canSync ?
                                <span>
                                    Reintentar
                                </span>
                                : (
                                    !canRetryIn && !canSync ?
                                        <span>
                                            Reiniciar conexión
                                        </span>
                                        :
                                        // canRetryIn === true
                                        <span className='small'>
                                            Reintentar en {formatMilliseconds(canRetryIn)}
                                        </span>
                                )

                        }
                    </Button>
                </div>
            </div>

        </Modal>
    )
}



export function CredentialEntryCaseSupportErrorDialog({
    isOpen,
    credential,
    onProceed,
    onAbort
}: CredentialEntryCasesDialogProps) {
    if (!credential) return null;

    const canSync = !!credential.can_sync;
    const canRetryIn = credential.ready_in;


    // format milliseconds to seconds and minutes
    const formatMilliseconds = (milliseconds: number) => {
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);

        if (minutes > 0) {
            return `${minutes} min`;
        }
        return `${seconds} sec`;
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onAbort}
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            contentLabel="Assisted bank retry dialog"
        >
            <div className='card table' id="CredentialAssistedRetryDialog">
                <div className="cardTitle">
                    {
                        credential.code === 500 ?
                            <p>Error del Banco</p>
                            :
                            <p>Error Conexión</p>
                    }
                </div>
                {

                    credential.code === 500 ?
                        <div className='cardBody'>
                            <p>
                                <b>
                                    Recomendamos que intentes re-sincronizar credenciales después de 12 horas.
                                </b>
                            </p>
                            <p>
                                En algunos casos, los bancos realizan mantenimiento en sus servicios o los datos están incompletos y esto genera un error. No es un problema que debas atender personalmente y comúnmente se resuelve después de un tiempo.
                            </p>
                            <p className='small'>
                                <b>En general, si puedes acceder a tu banca en linea directamente, debería ser posible la conexión en FiscalPOP.</b>
                            </p>
                        </div>
                        :
                        <div className='cardBody'>
                            <p>
                                <b>
                                    Se está presentando problemas para conectar al sitio del banco.
                                </b>
                            </p>
                            <p>
                                Es posible que este error se resuelva después de 12 horas de presentarse, ya que comunmente está asociado a mantenimiento o actualizacion de los servicios del banco.
                            </p>
                            <p>
                                <b>En general, si puedes acceder a tu banca en linea directamente, debería ser posible la conexión en FiscalPOP.</b>
                            </p>
                        </div>
                }
                <div className='actions'>
                    <Button secondary={true} handleClick={onAbort}>
                        <span>
                            Cancelar
                        </span>
                    </Button>
                    <Button primary={true} handleClick={onProceed} disabled={!!canRetryIn}>
                        {
                            !canRetryIn && canSync ?
                                <span>
                                    Reintentar
                                </span>
                                : (
                                    !canRetryIn && !canSync ?
                                        <span>
                                            Reiniciar conexión
                                        </span>
                                        :
                                        // canRetryIn === true
                                        <span className='small'>
                                            Reintentar en {formatMilliseconds(canRetryIn)}
                                        </span>
                                )

                        }
                    </Button>
                </div>
            </div>

        </Modal>
    )
}

