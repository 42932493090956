import React, { forwardRef } from "react";

import "react-datepicker/dist/react-datepicker.css";
import './Cotizaciones.list.datepicker.scss';

const DateRangeInput = forwardRef<HTMLDivElement, { value?: [Date, Date], onClick?: (v: any) => void }>(({ value, onClick }, ref) => {
    console.log('Date Picker value: ', value);
    return (
        <div className="_cotizaciones_datepicker card" onClick={onClick} ref={ref}>
            <p className="micro">
                {value}
            </p>
            <span className="material-icons">
                calendar_today
        </span>
        </div>
    )
});

export default DateRangeInput;