import React, { useState, FormEvent } from 'react';
import './LostPassword.scss';
import { BaseReact } from '../../base.model';
import Input from '../Forms/Input';
import { Link } from 'react-router-dom';
import Button from '../Forms/Button';
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';


interface LostPasswordProps extends BaseReact { }

function RecoverPassword({ history, location }: LostPasswordProps) {
    const [email, setEmail] = useState('');
    const [instructionsSent, setInstructionsSent] = useState(false);

    const [recoverPassword] = useMutation(graphqlSchema.PROFILE.recoverPassword, {
        onCompleted: ({ recoverPassword }: { recoverPassword: boolean }) => {
            setInstructionsSent(true);
            setTimeout(() => {
                history.push('/')
            }, 5000)
        }
    })


    const submitRecover = (e: FormEvent) => {
        e.preventDefault();
        recoverPassword({
            variables: {
                email
            }
        })
    }

    const _setEmail = (e: string) => {
        setEmail(e.toLowerCase().trim());
    }

    return (
        <div id="LostPassword">
            <form className="_loginForm" onSubmit={submitRecover}>
                <h3>Recuperar tu contraseña</h3>
                {
                    instructionsSent ?
                        <p>
                            Se envió la liga para recuperar tu contraseña por correo
                    </p>
                        :
                        <>
                            <Input type="text" label="Usuario / Email de tu cuenta" value={email} onChange={_setEmail} placeholder="Tu email u Seller Token" />
                            <Button primary={true} type="submit">
                                <span>Recuperar contraseña</span>
                            </Button>
                        </>
                }
                <div className="_toRegiser">
                    <Link to="/login">
                        <Button secondary={true} type="button">
                            <span>
                                Regresar! ya me acordé
                                </span>
                        </Button>
                    </Link>
                </div>
            </form>
        </div>
    )
}

export default RecoverPassword;