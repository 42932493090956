import React from 'react';
import { formatCurrencyStyled, formatNumberStyled } from '../visuals/currency.formatted';

import './numberDIsplay.scss';

interface NumberDisplayProps {
    title: string;
    value: number;
    icon: 'arrow_upward' | 'arrow_downward' | 'warning';
    coloring: 'green' | 'gray' | 'red' | 'orange';
    addClass?: string;
}

function PercentDisplay({ title, addClass = '', value, coloring, icon }: NumberDisplayProps) {

    return (
        <div className={`_numberDisplay ${addClass} ${!icon ? '_noIcon' : ''}`}>
            <div className="text">
                <h2>
                    {formatNumberStyled(value || 0)} %
                </h2>
                <p>{title}</p>
            </div>
            {
                icon ?
                    <div className={`icon ${coloring ? coloring : ''} ${icon === 'warning' ? 'noBold' : ''}`}>
                        <span className="material-icons">
                            {icon}
                        </span>
                    </div>
                    : null
            }
        </div>
    )
}

export default PercentDisplay;
