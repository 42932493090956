import React, { useState, useReducer } from 'react';

import './Setup.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL, ProfessionalProfile } from '../../models/Profile'
import SectionTitleBar from '../components/titlebar';
import Button from '../Forms/Button';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';

import moment from 'moment'
import SetupMenu from './Setup.menu';
import AccountAvatarHolder from '../components/accountAvatarHolder';
import { REGIMEN_OPTIONS } from '../../utils/regimen.options';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import SetupCertificate from './Setup.certificates';
import AccountAvatarHolderMobile from '../components/accountAvatarHolder.mobile';


const REGIMEN_OPT = REGIMEN_OPTIONS.map((o) => ({ value: o.value, label: o.label }))

moment.locale('es')

interface SetupProps extends BaseReact {
    currentUser: ProfileQL;
}

function currentProfileReducer(state: ProfessionalProfile, action: { property: string, payload: string }) {
    const assigned: { [key: string]: string } = {};
    switch (action.property) {
        case 'email':
            assigned[action.property] = action.payload;
            return Object.assign({}, state, assigned);
        case 'companyName':
            assigned[action.property] = action.payload;
            return Object.assign({}, state, action.payload);
        default:
            return Object.assign({}, state);
    }
}

function SetupFiscalPop({ history, location, currentUser }: SetupProps) {

    const [profile, dispatchProfileReducer] = useReducer(currentProfileReducer, currentUser.professionalProfile as ProfessionalProfile);

    // Emisor options
    const [lugarExpedicion, setLugarExpedicion] = useState(currentUser.fiscalpopProfile.lugarExpedicion)
    const [regimenFiscal, setRegimenFiscal] = useState(currentUser.fiscalpopProfile.regimenFiscal)
    const [nombre, setNombre] = useState(currentUser.fiscalpopProfile.nombre)
    const [rfc, setRFC] = useState(currentUser.fiscalpopProfile.rfc)


    const profileDispatcher = (property: 'email' | 'companyName') => (payload: string) => dispatchProfileReducer({ property, payload });
    // EMISOR --
    const [submitUpdateEmisor] = useMutation(graphqlSchema.PROFILE.SETUP.updateEmisor, {
        onCompleted: ({ updateEmisor }: { updateEmisor: { nombre: string; rfc: string; regimenFiscal: string; lugarExpedicion: string } }) => {
            console.log('Updated Emisor: ', updateEmisor);
            sharedToasterSubject.next({ type: 'confirm', message: `Se actualizó el emisor` })
        },
        onError: (e) => console.error('Error updating emisor: ', e)
    });


    const updateEmisor = () => {
        submitUpdateEmisor({
            variables: {
                emisor: {
                    rfc,
                    nombre,
                    lugarExpedicion,
                    regimenFiscal
                }
            }
        })
    }
    // ---------

    return (
        <div id="SetupFiscalPop">
            <SectionTitleBar currentUser={currentUser} title="Mi cuenta y configuración" />
            <div className="setupContent">
                <SetupMenu />
                <div className="card profile">
                    <AccountAvatarHolder currentUser={currentUser} />
                    <AccountAvatarHolderMobile currentUser={currentUser} />
                    <div className="setupPadding">
                        <div className="row perfil">
                            <div className='row'>
                                <h4>
                                    Mi Perfil
                                </h4>
                                <div className="row three md-two emisor sm-one">
                                    <Input disabled={true} type="text" label="Nombre Completo" value={profile.companyName} onChange={profileDispatcher('companyName')} placeholder="Tu Nombre o de tu compañia" />
                                    <Input disabled={true} type="text" label="Corréo Electrónico" value={profile.email} onChange={profileDispatcher('email')} placeholder="Correo" />
                                </div>
                            </div>
                            <div className="row">
                                <h4>
                                    Emisor fiscal
                                </h4>
                                <div className="row three md-two emisor sm-one">
                                    <Input value={nombre} label="Razón social" onChange={setNombre} placeholder="Razón social de tu emisor" type="text" />
                                    <Input value={rfc} label="RFC" onChange={setRFC} placeholder="RFC de tu emisor" type="text" />

                                    <Select value={regimenFiscal} label="Regimen Fiscal" onChange={setRegimenFiscal} placeholder="Régimen fiscal del receptor" options={REGIMEN_OPT} charLimit={83} />

                                    <Input value={lugarExpedicion} label="Lugar expedición" onChange={setLugarExpedicion} placeholder="Código postal de tu emisor" type="text" />
                                </div>
                                <div className="row three md-two emisor sm-one">
                                    <Button tertiary={true} handleClick={updateEmisor}>
                                        <span>Actualizar emisor</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='divider' />
                        <SetupCertificate
                            fiscalpopProfileStatus={currentUser.fiscalpopProfileStatus}
                            fiscalpopProfile={currentUser.fiscalpopProfile}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SetupFiscalPop;