import React, { useEffect, useState } from 'react';

import { select, arc } from 'd3';

import './Donut.chart.scss';


interface DonutChartProps {
    color: string;
    startAngle?: number;
    percent: number
}

function BaseDonutChart({ color, startAngle, percent }: DonutChartProps) {
    const containerRef = React.createRef<HTMLDivElement>();

    const [chartSize, setChartSize] = useState<number>(null);

    useEffect(() => {
        const _height = containerRef.current.getBoundingClientRect().height;
        const _width = containerRef.current.getBoundingClientRect().width;

        const _size = _height < _width ? _height : _width;

        setChartSize(_size)
    }, [containerRef])


    useEffect(() => {
        if (!!chartSize) {
            try {
                const _percent = percent > 1 ? 1 : percent;
                const _startAngle = startAngle || 120;

                // Does it has SVG yet? 
                const hasSvg = select(containerRef.current).select<SVGSVGElement>('svg')
                const svg = !hasSvg.empty() ? hasSvg : select(containerRef.current).append('svg');
                svg
                    .attr('width', chartSize)
                    .attr('height', chartSize)

                // Does it have child G ?
                const hasG = svg.select<SVGGElement>('g');
                const svgG = !hasG.empty() ? hasG : svg.append('g');
                svgG.attr('transform', `translate(${chartSize / 2},${chartSize / 2})`)

                // Has reference arc path?
                const hasReferenceArc = svgG.select<SVGPathElement>('path._referenceArc')
                const arcReferencePath = !hasReferenceArc.empty() ? hasReferenceArc : svgG.append('path').attr('class', '_referenceArc');

                const referenceStartRadians = _startAngle * (Math.PI / 180);
                const referenceEndRadians = (_startAngle + 360) * (Math.PI / 180);
                const arcReferenceFunc = arc<any>()
                    .innerRadius((chartSize / 2) - 4)
                    .outerRadius((chartSize / 2) - 0)
                    .startAngle(referenceStartRadians)
                    .endAngle(referenceEndRadians);

                arcReferencePath
                    .attr('d', arcReferenceFunc)
                    .attr('fill', `rgba(164, 165, 178, 0.25)`)

                // Has value arc path?
                const hasValueArc = svgG.select<SVGPathElement>('path._valueArc')
                const arcValuePath = !hasValueArc.empty() ? hasValueArc : svgG.append('path').attr('class', '_valueArc');

                const valueStartRadians = _startAngle * (Math.PI / 180);
                const valueEndRadians = (_startAngle + (_percent * 360)) * (Math.PI / 180);
                const arcValueFunc = arc<any>()
                    .innerRadius((chartSize / 2) - 4)
                    .outerRadius((chartSize / 2) - 0)
                    .startAngle(valueStartRadians)
                    .endAngle(valueEndRadians)
                    .cornerRadius(_percent < 0.05 ? 0 : 3);

                arcValuePath
                    //.transition()
                    .attr('d', (d) => {
                        return arcValueFunc(d)
                    })
                    .attr('fill', color || `#3FA8F4`)

            } catch (e) {
                console.error(`<Donut Chart> error`, e);
            }
        }
    }, [color, startAngle, percent, chartSize, containerRef])

    return (
        <div className='_donutChartContainer' ref={containerRef}>
        </div>
    )
}

export default BaseDonutChart;