
import React, { useState, useEffect, FormEvent } from 'react';

import Modal from 'react-modal';
import { Bill } from '../../../models/Factura';
import { REGIMEN_OPTIONS } from '../../../utils/regimen.options';
import Button from '../../Forms/Button';
import Input from '../../Forms/Input';
import Select from '../../Forms/Select';

import './creditNotes.clientEdit.scss'

interface CreditNotesClientProps {
    isOpen: boolean;
    receptor: Bill['receptor'];
    onRequestClose: () => void;
    onClientSubmit: (updated: Bill['receptor']) => void;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '80vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

const REGIMEN_OPT = REGIMEN_OPTIONS.map((o) => ({ value: o.value, label: o.secondLabel }))

function CreditNotesClientDialog({
    isOpen,
    receptor,
    onRequestClose,
    onClientSubmit,
}: CreditNotesClientProps) {

    const [nombre, setNombre] = useState('')
    const [regimen, setRegimen] = useState('')
    const [zip, setZip] = useState('')

    useEffect(() => {
        setNombre(receptor.nombre || '')
        setRegimen(receptor.regimen || '')
        setZip(receptor.zip || '')

    }, [receptor])
    

    const updateClient = (e: FormEvent) => {
        e.preventDefault();
        if (!(nombre && regimen && zip)) {
            return
        }
        onClientSubmit(Object.assign({}, receptor, { nombre, regimen, zip }))
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Producto Edit Modal"
        >
            <div id="CreditClientEdit" className='card table'>
                <div className="cardTitle">
                    <h4>Hay datos que faltan del receptor</h4>
                </div>
                <form onSubmit={updateClient}>
                    <p>
                        Es necesario agregar un par de datos al receptor para usar CFDI 4.0
                    </p>
                    <p>
                        En esta nueva versión de CFDI, la razón social se valida y puede ser necesario actualizarla.
                    </p>
                        <Input type='text' label='Razón Social' value={nombre} onChange={setNombre} />
                    <div className='row two xs-one'>
                        <Input type='text' label='Código postal' hasError={!zip || (!!zip && zip.length !== 5)} value={zip} onChange={setZip} />
                        <Select label="Régimen" value={regimen} hasError={!regimen} onChange={setRegimen} placeholder="Escoge tu régimen" options={REGIMEN_OPT} />
                    </div>

                    <div className="_actions">
                        <Button type="submit" primary={true} disabled={!(nombre && regimen && zip)}>
                            <span>Actualizar Receptor</span>
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default CreditNotesClientDialog;