import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { PaybookAccount } from '../../../../../models/Administracion/Bank.Accounts';
import { BankProjectionGoal, BankProjectionGoalInput } from '../../../../../models/Administracion/Bank.Projects';
import { graphqlSchema } from '../../../../../services/graphql.schema';
import Button from '../../../../Forms/Button';
import Input from '../../../../Forms/Input';
import SelectCard from '../../../../Forms/SelectCard';
import TextArea from '../../../../Forms/TextArea';
import FlagSvg from '../../../../visuals/svg/flagIcon';

import './Projection.metas.dialog.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px 0px 6px',
        border: 'none',
        borderRadius: '12px',
        boxShadow: `0px 4px 16px rgba(0, 60, 128, 0.1)`,
    }
};

interface ProjectionMetaDialogProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onMetaAdded?: (goal: BankProjectionGoal) => void;
}

const EMPTY_META: BankProjectionGoalInput = {
    name: '',
    accountBound: false,
    accountBoundId: null,
    target: null,
    goal: null,
    dueDateTS: null,
    description: ''
}

export function ProjectionMetaDialog({ isOpen, onRequestClose, onMetaAdded }: ProjectionMetaDialogProps) {

    const [saving, isSaving] = useState(false);
    const [validate, setValidate] = useState(false);
    const [accounts, setAccounts] = useState<PaybookAccount[]>([])

    const [meta, _setMeta] = useState(EMPTY_META)

    const [getBankAccounts] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankAccounts, {
        onCompleted: ({ getBankAccounts }: { getBankAccounts: PaybookAccount[] }) => {
            console.log(`(Goal Dialog) <getBankAccounts> `, getBankAccounts);
            setAccounts(getBankAccounts.filter(gBA => gBA.account_type !== 'Loan'));

        },
        fetchPolicy: 'cache-and-network'
    })

    const [createGoal] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.assignProjectionGoal, {
        onCompleted: ({ assignProjectionGoal }: { assignProjectionGoal: BankProjectionGoal }) => {
            console.log(`(Goal Dialog) <assignProjectionGoal> `, assignProjectionGoal);
            onMetaAdded(assignProjectionGoal)
            isSaving(false)
        },
        onError: (e) => {
            console.error(`(Goal Dialog) Goal create Error: `, e);
            isSaving(false)

        }
    })

    useEffect(() => {
        if (isOpen) {
            getBankAccounts()
        } else {
            setValidate(false);
            isSaving(false);
            _setMeta(EMPTY_META)
        }
    }, [getBankAccounts, isOpen])


    // Goal Changes
    const onBoundAccount = (bool: boolean) => {
        _setMeta((meta) => {
            return { ...meta, accountBound: bool, accountBoundId: bool ? meta.accountBoundId : null }
        })
    }
    const onBoundAccountId = (accountId: string) => {
        _setMeta((meta) => {
            return { ...meta, accountBoundId: accountId }
        })
    }
    const onName = (name: string) => {
        _setMeta((meta) => {
            return { ...meta, name: name.substring(0, 30) }
        })
    }
    const onTarget = (target: BankProjectionGoalInput['target']) => {
        _setMeta((meta) => {
            return { ...meta, target: target }
        })
    }
    const onGoal = (goal: BankProjectionGoalInput['goal']) => {
        _setMeta((meta) => {
            return { ...meta, goal: goal }
        })
    }
    const onDueDate = (dueDateTS: Date) => {
        console.log('dueDateTS: ', dueDateTS);
        _setMeta((meta) => {
            return { ...meta, dueDateTS: dueDateTS.getTime() }
        })
    }
    const onDescription = (description: string) => {
        _setMeta((meta) => {
            return { ...meta, description: description }
        })
    }
    // ------------


    const submitMeta = () => {
        setValidate(true);
        if (!(meta.name && meta.target && meta.goal && meta.dueDateTS && (meta.accountBound ? meta.accountBoundId : true))) {
            console.log('Validation failed')
            return; // Validation failed
        }
        isSaving(true);
        createGoal({
            variables: {
                goal: meta
            }
        })
    }


    const accountIdOptions = accounts.map((acc) => ({ value: acc.id_account, label: `${acc.site.organization} - ${acc.name}` }))
    const goalTargetOptions = [
        { value: 'balance', label: 'Balance esperado' },
        { value: 'expense', label: 'Límite de gasto mensual' },
        { value: 'income', label: 'Ingreso deseado mensual' },
    ]


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={!saving}
            shouldCloseOnOverlayClick={!saving}
            contentLabel="Agregar Meta Modal"
        >
            <div id="ProjectionMetasDialog" >
                <div className='title'>
                    <FlagSvg width={14} color={'#AC2E78'} />
                    <h4>Agregar Meta</h4>
                </div>

                <div className='row'>
                    <p className='lightGray'>¿Lo quieres ligar a alguna cuenta de banco?</p>
                    <div className='row three sm-two'>
                        <SelectCard
                            options={[{ label: 'Sí', value: true }, { label: 'No', value: false }]}
                            value={meta.accountBound}
                            placeholder="-"
                            onChange={onBoundAccount}
                            borderOnly={true}
                        />
                        {
                            meta.accountBound ?
                                <SelectCard
                                    options={accountIdOptions}
                                    value={meta.accountBoundId || ''}
                                    placeholder="¿A que cuenta?"
                                    onChange={onBoundAccountId}
                                    disabled={!meta.accountBound}
                                    borderOnly={true}
                                />
                                : null
                        }
                    </div>
                </div>
                <div className='row'>
                    <p className='lightGray'>¿Cuál es tu objectivo?</p>
                    <div className='row three sm-two'>
                        <Input
                            type='text'
                            value={meta.name}
                            label="Título de la meta"
                            onChange={onName}
                            hasError={(validate && !meta.name) || (meta.name.length > 29)}
                            errorLabel={(meta.name.length > 29) ? 'Máximo 30 carácteres' : ''}
                        />
                        <SelectCard
                            options={goalTargetOptions}
                            value={meta.target || ''}
                            placeholder="Objetivo de la meta"
                            onChange={onTarget}
                            hasError={validate && !meta.target}
                            errorLabel="Elije objetivo"
                            borderOnly={true}
                        />
                        <Input
                            type='number'
                            value={meta.goal || ''}
                            label={goalTargetOptions.find(gTO => meta.target === gTO.value)?.label || 'Elije un objetivo primero'}
                            onChange={onGoal}
                            disabled={!meta.target}
                            hasError={(validate && !meta.goal)}
                            isCurrency={true}
                            min={meta.target === 'balance' ? null : 0}
                        />
                        <Input
                            type='date'
                            value={meta.dueDateTS || moment(Date.now()).add(1, 'month').startOf('month').valueOf()}
                            label="Fecha de meta"
                            minDate={moment(Date.now()).add(1, 'month').startOf('month').toDate()}
                            onChange={onDueDate}
                            hasError={(validate && !meta.dueDateTS)}
                            errorLabel="Confirma la fecha"
                        />
                    </div>
                </div>
                <div className='row _autocomplete'>
                    <TextArea
                        value={meta.description}
                        placeholder="Brevemente describe tu meta"
                        label='Descripción (Opcional)'
                        onChange={onDescription}
                    />
                </div>
                <div className='actions'>
                    <Button secondary={true} disabled={saving} handleClick={onRequestClose}>
                        <span>Cancelar</span>
                    </Button>
                    <Button primary={true} disabled={saving} handleClick={submitMeta}>
                        {
                            saving ?
                                <span>Guardando...</span>
                                :
                                <span>Crear Meta</span>
                        }
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
