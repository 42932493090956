import React, { useEffect, useState } from 'react';
import { ProfileQL, CookieLinkerBody, LinkerAccount } from '../../models/Profile';
import { post } from '../../utils/post';
import { getCookies } from '../../services/document.cookie';

import './accountLinker.dialog.scss'

import Modal from 'react-modal';
import Button from '../Forms/Button';
import GraphQlClient from '../../services/graphql';
import sharedLinkerSubject from '../../services/shared.linkerSubject';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px',
        border: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.32) 1px 1px 3px 1px'
    }
};
Modal.setAppElement('#root');

function AccountLinker({ currentUser }: { currentUser: ProfileQL }) {
    // AUTO LINK
    const [linkers, setLinkers] = useState<{ [key: string]: LinkerAccount[] }>({});
    const [showModal, setShowModal] = useState(false);
    const [toLink, setToLink] = useState<LinkerAccount[]>([]);
    const [isConnecting, setIsConnecting] = useState(false);
    // ---------

    // Linker Options for modal
    useEffect(() => {
        const cookies = getCookies()
        if (cookies['linker_optout']) {
            // Outed out do nothing
            return;
        }
        // make sure cookies and localstorage are the same
        if(cookies['prof_profileid'] !== localStorage.getItem('prof_profileid')) {
            // Not using session from cookie, ?profileid=... maybe
            return;
        }
        fetch(`https://binding.fiscalpop.com/app/sessions/active`, { credentials: 'include' })
            .then(r => r.json())
            .then((linkerGroups: { [key: string]: LinkerAccount[] }) => {
                console.log('linkerGroups: ', linkerGroups);
                if (!currentUser.professionalProfile.linkerId) {
                    const linkerCandidates = Object.keys(linkerGroups).reduce((p, key) => {
                        return p.concat(linkerGroups[key]);
                    }, [] as LinkerAccount[]).filter(acc => !(acc.email === currentUser.professionalProfile.email && acc.origin === 'fiscalpop'))
                    setLinkers(linkerGroups);
                    console.log('Linker candidates: ', linkerCandidates);
                    setShowModal(!!linkerCandidates.length);
                } else {
                    const linkerCandidates = Object.keys(linkerGroups).reduce((p, key) => {
                        if (key === currentUser.professionalProfile.linkerId) {
                            return p;
                        } else {
                            return p.concat(linkerGroups[key]);
                        }
                    }, [] as LinkerAccount[]).filter(acc => !(acc.email === currentUser.professionalProfile.email && acc.origin === 'fiscalpop'))
                    setLinkers(linkerGroups);
                    console.log('Linker candidates: ', linkerCandidates);
                    setShowModal(!!linkerCandidates.length);
                }
                // REMOVE AFTER TESTING
                // const defaultLinkers: { [key: string]: LinkerAccount[] } = { 'null': [{ origin: "mercadolibre", email: "gabriel@pixelblocks.mx" }, { origin: "fiscalpop", email: "contacto@fiscalpop.com" }] }
                // setLinkers(defaultLinkers);

                // >> Should filter linker options to display
                // >> Check for opt-out autoLinking
            })
            .catch()
    }, [currentUser]);

    const requestBinding = () => {
        const receivers = toLink.filter(tL => !(tL.email === currentUser.professionalProfile.email && tL.origin === 'fiscalpop'));
        const body: CookieLinkerBody = {
            giver: {
                origin: 'fiscalpop',
                email: currentUser.professionalProfile.email
            },
            receivers: receivers
        }
        console.log('BODY: ', body);
        setIsConnecting(true);
        post(`https://binding.fiscalpop.com/withcookie`, body)
            .then(async (r: Response) => {
                console.log('R: ', r);
                if (r.ok) {
                    return r.text();
                } else {
                    const err = await r.text()
                    throw err;
                }
            })
            .then(linkerId => {
                console.log('Linker Id: ', linkerId);
                if(currentUser.professionalProfile.linkerId){
                    sharedLinkerSubject.next(linkerId);
                }
                GraphQlClient.recallCurrentUser();
                setIsConnecting(false);
            })
            .catch(e => {
                console.error('Error: ', e);
                setIsConnecting(false);
            })
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const optOut = () => {
        document.cookie = `linker_optout=out;domain=.fiscalpop.com;max-age=31536000;path=/;secure`;
        closeModal();
    }

    const selectToAdd = (account: { origin: 'mercadolibre' | 'amazon' | 'izettle' | 'fiscalpop' | 'claroshop' | 'tiendanube', email: string }) => {
        return () => {

            const _toLink: { origin: 'mercadolibre' | 'amazon' | 'izettle' | 'fiscalpop' | 'claroshop' | 'tiendanube', email: string }[] = JSON.parse(JSON.stringify(toLink));
            const indexToRemove = _toLink.findIndex(tL => tL.email === account.email && tL.origin === account.origin);
            if (indexToRemove === -1) {
                _toLink.push(account);
            } else {
                _toLink.splice(indexToRemove, 1);
            }
            setToLink(_toLink);
        }
    }

    const connectAccountsIsDisabled = () => {
        if(isConnecting){
            return true;
        }
        if(currentUser.professionalProfile.linkerId) {
            return toLink.length < 1
        } else {
            return toLink.length < 2
        }
    }

    // RENDER HANDLERS
    // ==============================

    const renderAutoLinkModal = () => {

        return (
            <Modal
                isOpen={showModal}
                onRequestClose={closeModal}
                style={customStyles}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                contentLabel="Auto Link accounts"
            >
                <div id="_FiscalpopAutoLinker">
                    <h4 className="title">
                        Encontramos otras cuentas de Fiscalpop
                    </h4>
                    <p>
                        Conectar cuentas permite usar una sola cuenta de gastos y te permite navegar entre ellas rápidamente.
                    </p>
                    <p>
                        Selecciona las cuentas que quieres ligar entre si.
                    </p>
                    <div className="_accInfo">
                        {Object.keys(linkers).map((group) => (
                            <div key={group} className={`_accGroup ${!!currentUser.professionalProfile.linkerId && (currentUser.professionalProfile.linkerId === group) ? '_connected' : ''}`}>
                                <p className="_info">{group === 'null' ? 'Sin conectar' : ''}</p>
                                {linkers[group].map((acc, i) => {
                                    const isConnected = !!currentUser.professionalProfile.linkerId && (currentUser.professionalProfile.linkerId === group);
                                    const selected = toLink.find(tL => tL.email === acc.email && tL.origin === acc.origin);
                                    if (isConnected) {
                                        return (
                                            <div key={i} className={`_accEntry ${!!selected ? 'selected' : ''}`}>
                                                <p className="origin">{acc.origin}</p>
                                                <p className="email">{acc.email.substring(0, 40)}</p>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={i} className={`_accEntry ${!!selected ? 'selected' : ''}`} onClick={selectToAdd(acc)}>
                                                <p className="origin">{acc.origin}</p>
                                                <p className="email">{acc.email.substring(0, 40)}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        ))}
                    </div>
                    <div className="actions">
                        <Button tertiary={true} handleClick={optOut}>
                            <span>No conectar</span>
                        </Button>
                        <Button secondary={true} handleClick={closeModal}>
                            <span>Después</span>
                        </Button>
                        <Button primary={true} disabled={connectAccountsIsDisabled()} handleClick={requestBinding}>
                            {
                                isConnecting ?
                                    <span>Conectando tus cuentas</span>
                                    :
                                    <span>Conectar seleccionadas</span>
                            }
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }

    return renderAutoLinkModal()
}


export default AccountLinker;