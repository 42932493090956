import React, { useState } from 'react';
import { ProfileQL, LinkerAccount } from '../../models/Profile';
import { post } from '../../utils/post';

import Modal from 'react-modal';

import './connectAccounts.dialog.scss';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import { validateEmailRegex } from '../../utils/regex';
import sharedToasterSubject from '../../services/shared.toasterSubject';

interface SpawningPayload {
    email?: string;
    password?: string;
    company?: string;
    privateKey?: string;
    publicKey?: string;
    sameRFC: boolean;
    // reference auth token is needed, as the casting RFC can be from another platform
    referenceAuthToken: string;
    // In order to initialize a bound client, we need to make use of linkerId or originalProfile
    requester: {
        origin: 'mercadolibre' | 'amazon' | 'izettle' | 'fiscalpop' | 'claroshop' | 'tiendanube';
        originId: string; // converted into ObjectId
    }
}

export interface EmailLinkerBody {
    giver: {
        origin: 'mercadolibre' | 'amazon' | 'izettle' | 'fiscalpop' | 'claroshop' | 'tiendanube';
        email: string;
    }
    receiver: {
        origin: 'mercadolibre' | 'amazon' | 'izettle' | 'fiscalpop' | 'claroshop' | 'tiendanube';
        email: string;
    }
}


const iconMap = {
    mercadolibre: 'https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/ml-icon.png',
    amazon: 'https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/amazon-icon.png',
    claroshop: 'https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/claro-icon.png',
    fiscalpop: 'https://misventas-static.nyc3.digitaloceanspaces.com/LogoSmall.png',
    izettle: 'https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/izettle-icon.png',
    tiendanube: 'https://fiscalpop.sfo2.cdn.digitaloceanspaces.com/assets/TiendaNube.png',
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px',
        border: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.32) 1px 1px 3px 1px'
    }
};
Modal.setAppElement('#root');

function ConnectAccountsDialog({ currentUser }: { currentUser: ProfileQL }) {

    const [isOpen, setIsOpen] = useState(false);

    const [platformChoosen, setPlatformChoosen] = useState<LinkerAccount['origin']>(null);
    const [isConnected, setIsConnected] = useState(null);
    const [sameRFC, setSameRFC] = useState(null);

    // Amazon + Claroshop + Fiscalpop specific
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Amazon + Claroshop specific
    const [company, setCompany] = useState('');

    // Claroshop specific
    const [privateKey, setPrivateKey] = useState('');
    const [publicKey, setPublicKey] = useState('');

    // UI Specific
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [emailLinkSent, setEmailLinkSent] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
        setPlatformChoosen(null);
        setIsConnected(null);
        setSameRFC(null);
        setEmail('');
        setPassword('');
        setCompany('');
        setPrivateKey('');
        setPublicKey('');
        setIsSubmitting(null);
        setEmailLinkSent(null);
    }

    const selectPlatformToConnect = (option: LinkerAccount['origin']) => () => {
        setPlatformChoosen(option);
    }

    const selectIsConnected = (bool: boolean) => () => {
        setIsConnected(bool)
    }
    const selectSameRFC = (bool: boolean) => () => {
        setSameRFC(bool)
    }

    const _setEmail = (e: string) => {
        setEmail(e.trim().toLowerCase())
    }

    // Submission ops
    const connectByEmail = () => {
        setIsSubmitting(true);

        const payload: EmailLinkerBody = {
            giver: {
                email: currentUser.professionalProfile.email,
                origin: 'fiscalpop'
            },
            receiver: {
                email,
                origin: platformChoosen
            }
        }

        post(`https://binding.fiscalpop.com/app/linker/email/bind`, payload)
            .then(async (response) => {
                if (response.ok) { return response.text() }
                const errMess = await response.text();
                throw errMess;
            })
            .then((_id: string) => {
                console.log('Binding reference: ', _id);
                setEmailLinkSent(true);
                setTimeout(() => {
                    closeModal();
                }, 5000)
            })
            .catch((e: string) => {
                console.log('ERROR connecting: ', e);
                setIsSubmitting(false);
                sharedToasterSubject.next({
                    type: 'error', message: e
                })
            })
    }

    const requestConnection = () => {
        const payload: SpawningPayload = {
            email,
            password,
            company,
            privateKey,
            publicKey,
            sameRFC,
            referenceAuthToken: currentUser.fiscalpopProfile.authToken,
            requester: {
                origin: 'fiscalpop',
                originId: atob(localStorage.getItem('prof_profileid'))
            }
        }
        console.log('payload: ', payload);
        
        // return;

        post(`https://binding.fiscalpop.com/link/spawn/${platformChoosen}`, payload)
            .then(async (response) => {
                if (response.ok) { return response.json() }
                const errMess = response.text();
                throw errMess;
            })
            .then(({ _id }: { _id: string }) => {
                /*
                 Logout process to be managed individually per platform
                */
                if (!_id) {
                    // eslint-disable-next-line no-throw-literal
                    throw 'Error creando cuenta a conectar';
                }
                // ML Section
                // ----------------------------
                if (platformChoosen === 'mercadolibre') {
                    fetch(`https://mercadolibre.fiscalpop.com/logout${!!_id ? '?state=' + _id : ''}`, { method: 'GET' })
                        .then(r => r.text())
                        .then(r => {
                            console.log('R: ', r);
                            console.log('Redirect: ', `https://www.mercadolibre.com/jms/mlm/lgz/logout?go=${encodeURIComponent(r)}`);
                            window.location.href = `https://www.mercadolibre.com/jms/mlm/lgz/logout?go=${encodeURIComponent(r)}`;
                        })
                }
                // Amazon Section
                // ----------------------------
                else if (platformChoosen === 'amazon') {
                    document.cookie = `${'mws_profileid'}=;domain=${'.fiscalpop.com'};expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
                    const appId = `amzn1.sellerapps.app.b5727cc3-3fe4-4e1c-8232-2fe1588ec686`;
                    const appRedirect = `https://apimws.fiscalpop.com/spconsent`
                    const state = _id;
                    if (!state) {
                        return;
                    }
                    window.location.href = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${appId}&state=${state}&redirect_uri=${appRedirect}`
                }
                // Claroshop Section
                // ----------------------------
                else if (platformChoosen === 'claroshop') {
                    const token = btoa(_id);
                    const domain = '.fiscalpop.com'
                    localStorage.setItem('claro_profileid', token);
                    document.cookie = `claro_profileid=${token};domain=${domain};`;
                    window.location.href = `https://claro.fiscalpop.com`
                }
                
                // Personal Section
                // ----------------------------
                else if (platformChoosen === 'fiscalpop') {
                    const token = btoa(_id);
                    const domain = '.fiscalpop.com'
                    localStorage.setItem('prof_profileid', token);
                    document.cookie = `prof_profileid=${token};domain=${domain};path=/;`;
                    window.location.href = `https://personal.fiscalpop.com`
                }

                // Zettle Section
                // ----------------------------
                else if (platformChoosen === 'izettle') {
                    const token = btoa(_id);
                    const domain = '.fiscalpop.com'
                    localStorage.setItem('izettle_profileid', token);
                    document.cookie = `izettle_profileid=${token};domain=${domain};path=/;`;
                    // window.location.href = `https://zettle.fiscalpop.com/app`
                    window.location.href = `https://zettle.fiscalpop.com/sso-reconnect/${_id}`
                }

                // TiendaNube Section
                // ----------------------------
                else if (platformChoosen === 'tiendanube') {
                    const token = btoa(_id);
                    const domain = '.fiscalpop.com'
                    localStorage.setItem('tnube_profileid', token);
                    document.cookie = `tnube_profileid=${token};domain=${domain};path=/;`;
                    window.location.href = `https://www.tiendanube.com/apps/4302/authorize?state=${_id}`
                }

            })
            .catch((e: string) => {
                console.error(`Error conecting accounts: `, e);
                sharedToasterSubject.next({ type: 'error', message: e })
            })
    }

    const stepIs = (platformChoosen !== null ? 1 : 0) + (sameRFC !== null ? 1 : 0) + (0);
    const emailRegexValid = !email ? false : validateEmailRegex(email);

    return (
        <>
            <div className="_act primary" onClick={openModal}>
                <p><b>Conectar cuentas</b></p>
            </div>
            <Modal
                isOpen={isOpen}
                style={customStyles}
                onRequestClose={closeModal}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                contentLabel="FiscalPOP Usuarios secundarios"
            >
                <div id="_FiscalpopDialogConnect">
                    <div className="_header">
                        <h4>
                            Conectar cuentas adicionales
                        </h4>
                    </div>
                    <div className={`_body ${!!stepIs ? 'step_' + stepIs : 'step_0'}`}>
                        <div className="platformChooser">
                            <h3>¿Que plataforma deseas conectar?</h3>
                            <div className="_plaformOption" onClick={selectPlatformToConnect('mercadolibre')} >
                                <img src={iconMap.mercadolibre} alt="ML Logo" />
                                <h2>MercdoLibre</h2>
                            </div>
                            <div className="_plaformOption" onClick={selectPlatformToConnect('amazon')}>
                                <img src={iconMap.amazon} alt="Amazon Logo" />
                                <h2>Amazon</h2>
                            </div>
                            <div className="_plaformOption" onClick={selectPlatformToConnect('claroshop')}>
                                <img src={iconMap.claroshop} alt="ClaroShop Logo" />
                                <h2>ClaroShop</h2>
                            </div>
                            <div className="_plaformOption" onClick={selectPlatformToConnect('fiscalpop')}>
                                <img src={iconMap.fiscalpop} alt="Fiscalpop Logo" />
                                <h2>Personal</h2>
                            </div>
                            <div className="_plaformOption" onClick={selectPlatformToConnect('izettle')}>
                                <img src={iconMap.izettle} alt="Zettle Logo" />
                                <h2>Zettle</h2>
                            </div>
                            <div className="_plaformOption" onClick={selectPlatformToConnect('tiendanube')}>
                                <img src={iconMap.tiendanube} alt="Tienda Nube Logo" />
                                <h2>Tienda Nube</h2>
                            </div>
                        </div>
                        {
                            emailLinkSent ?
                                <div className={`accChooserWrap success`}>
                                    <i className="material-icons">
                                        mark_email_read
                                </i>
                                    <p>
                                        <b>Listo!</b> <br />
                                    para terminar la conexión revise el correo enviado a {email}.
                                </p>
                                    <p>
                                        Puedes cerrar el diálogo cuando quieras.
                                </p>
                                </div>
                                :
                                <div className="accChooserWrap">
                                    <div className="existingAccountChooser">
                                        <h3>¿Tienes FiscalPOP ya conectado a esa cuenta?</h3>
                                        <div className={`_rfcOption ${isConnected === true ? '_selected' : ''}`} onClick={selectIsConnected(true)}>
                                            <h2>Si</h2>
                                            <p>Tus cuentas existentes se conectarán</p>
                                        </div>
                                        <div className={`_rfcOption ${isConnected === false ? '_selected' : ''}`} onClick={selectIsConnected(false)}>
                                            <h2>No</h2>
                                            <p>Se creará la cuenta de FiscalPOP y conectará</p>
                                        </div>
                                    </div>
                                    {
                                        isConnected === true ?
                                            <div className={`emailInputChooser`}>
                                                <Input type="text" label="Correo de tu cuenta a conectar" value={email} onChange={_setEmail} placeholder="Correo de cuenta a conectar" />
                                                <Button primary={true} handleClick={connectByEmail} disabled={isSubmitting || !emailRegexValid}>
                                                    {
                                                        isSubmitting ?
                                                            <span>
                                                                Configurando, espere...
                                                        </span>
                                                            :

                                                            <span>
                                                                Conectar cuenta
                                                        </span>
                                                    }
                                                </Button>
                                            </div>
                                            :
                                            (
                                                isConnected === false ?
                                                    <div className="rfcSpawnningChooser">
                                                        <h3>¿Deseas usar el mismo RFC o uno diferente?</h3>
                                                        <div className="_rfcOption" onClick={selectSameRFC(true)}>
                                                            <h2>Mismo RFC</h2>
                                                            <p>Tu cuenta estará lista para usarse una vez conectadas tus cuentas</p>
                                                        </div>
                                                        <div className="_rfcOption" onClick={selectSameRFC(false)}>
                                                            <h2>Otro RFC</h2>
                                                            <p>Tus cuentas iniciarán conectadas y solo requerirás terminar el registro</p>
                                                        </div>
                                                    </div>
                                                    : ''
                                            )
                                    }
                                </div>

                        }

                        <div className={`accountForging ${platformChoosen}`}>
                            <div className="extraData isMercadoLibre">
                                <h2>MercadoLibre</h2>
                                <p>Se cerrará tu sesión y verá un login donde debe usar el usuario de la cuenta a conectar</p>
                                <Button primary={true} disabled={isSubmitting} handleClick={requestConnection}>
                                    <span>
                                        Crear cuenta {sameRFC ? 'con mismo RFC' : 'con otro RFC'}
                                    </span>
                                </Button>
                            </div>
                            <div className="extraData isAmazon">
                                <h2>Amazon</h2>
                                <p>Necesitamos estos datos para crear su nueva cuenta</p>
                                <Input type="text" label="Compañia" value={company} onChange={setCompany} placeholder="Nombre de tu negocio" />
                                <Input type="text" label="Correo" value={email} onChange={_setEmail} placeholder="Correo de su nueva cuenta" />
                                <Input type="text" label="Contraseña" value={password} onChange={setPassword} placeholder="Contraseña de su nueva cuenta" />
                                <Button primary={true} disabled={!emailRegexValid || !company || !password} handleClick={requestConnection}>
                                    <span>
                                        Crear cuenta {sameRFC ? 'con mismo RFC' : 'con otro RFC'}
                                    </span>
                                </Button>
                            </div>
                            <div className="extraData isClaroshop">
                                <h2>ClaroShop</h2>
                                <p>Necesitamos estos datos para crear su nueva cuenta</p>
                                <Input type="text" label="Compañía" value={company} onChange={setCompany} placeholder="Nombre de tu negocio" />
                                <Input type="text" label="Correo" value={email} onChange={_setEmail} placeholder="Correo de su nueva cuenta" />
                                <Input type="text" label="Contraseña" value={password} onChange={setPassword} placeholder="Contraseña de su nueva cuenta" />
                                <Input type="text" label="Llave pública Claroshop" value={publicKey} onChange={setPublicKey} placeholder="Clave publica que obtienes en carga masiva" />
                                <Input type="text" label="Llave privada Claroshop" value={privateKey} onChange={setPrivateKey} placeholder="Clave privada que obtienes en carga masiva" />
                                <Button primary={true} disabled={!emailRegexValid || !company || !password || !publicKey || !privateKey} handleClick={requestConnection}>
                                    <span>
                                        Crear cuenta {sameRFC ? 'con mismo RFC' : 'con otro RFC'}
                                    </span>
                                </Button>
                            </div>
                            <div className="extraData isFiscalpop">
                                <h2>Fiscalpop</h2>
                                <p>Necesitamos estos datos para crear su nueva cuenta</p>
                                <Input type="text" label="Nombre o Compañía" value={company} onChange={setCompany} placeholder="Nombre tuyo o de tu negocio" />
                                <Input type="text" label="Correo" value={email} onChange={_setEmail} placeholder="Correo de su nueva cuenta" />
                                <Input type="text" label="Contraseña" value={password} onChange={setPassword} placeholder="Contraseña de su nueva cuenta" />
                                <Button primary={true} disabled={!emailRegexValid || !password || !company} handleClick={requestConnection}>
                                    <span>
                                        Crear cuenta {sameRFC ? 'con mismo RFC' : 'con otro RFC'}
                                    </span>
                                </Button>
                            </div>
                            <div className="extraData isZettle">
                                <h2>Zettle</h2>
                                <p>Necesitamos estos datos para crear su nueva cuenta</p>
                                <Input type="text" label="Nombre o Compañía" value={company} onChange={setCompany} placeholder="Nombre tuyo o de tu negocio" />
                                <Input type="text" label="Correo" value={email} onChange={_setEmail} placeholder="Correo de su nueva cuenta" />
                                <Input type="text" label="Contraseña" value={password} onChange={setPassword} placeholder="Contraseña de su nueva cuenta" />
                                <Button primary={true} disabled={!emailRegexValid || !password || !company} handleClick={requestConnection}>
                                    <span>
                                        Crear cuenta {sameRFC ? 'con mismo RFC' : 'con otro RFC'}
                                    </span>
                                </Button>
                            </div>
                            <div className="extraData isTiendaNube">
                                <h2>Tienda Nube</h2>
                                <p>Se cerrará tu sesión y verá un login donde debe usar el usuario de la cuenta a conectar</p>
                                <Button primary={true} disabled={isSubmitting} handleClick={requestConnection}>
                                    <span>
                                        Crear cuenta {sameRFC ? 'con mismo RFC' : 'con otro RFC'}
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ConnectAccountsDialog;