import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { BaseReact } from '../../../base.model';
import { ProfileQL } from '../../../models/Profile';
import SectionTitleBar from '../../components/titlebar';

import './Movimientos.scss'
import { CATEGORIES_LIST } from '../helpers/categories.list';
import SelectCard from '../../Forms/SelectCard';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../services/graphql.schema';
import { PaybookTransaction } from '../../../models/Administracion/Bank.Transactions';
import MovementEntry from './MovementEntry/Movement.entry';
import Pagination from '../../Forms/Pagination';
import DateRangeInput from '../../Forms/DateRange';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import MovimientosConfigureToaster, { CategoryProjectOptions } from './MovimientosConfigure/MovimientosConfigure';
import sharedToasterSubject from '../../../services/shared.toasterSubject';
import { PaybookAccount } from '../../../models/Administracion/Bank.Accounts';
import { NavLink, useParams } from 'react-router-dom';
import Checkbox from '../../Forms/Checkbox';
import { BankProject } from '../../../models/Administracion/Bank.Projects';

interface PagosProps extends BaseReact {
    currentUser: ProfileQL;
}

const OFFSET = new Date().getTimezoneOffset();
const CATEGORIES_LIST_OPTIONS = [{ label: 'Quitar filtro', value: '__' }, { label: 'Sin categorías', value: '__null' }, { label: ' ', value: '__null', disabled: true }].concat(CATEGORIES_LIST);
const AMOUNT_TYPE_OPTIONS = [{ label: 'Todos', value: '__' }, { label: 'Ingreso', value: 'ingreso' }, { label: 'Egreso', value: 'egreso' }]

function Movimientos({ currentUser, location, history }: PagosProps) {

    const query = (location.search || '').replace('?', '').split('&').reduce((p, c) => {
        const [key, value] = c.split('=');
        p[key] = value;
        return p
    }, {} as { [key: string]: string });

    const { accountid } = useParams<{ accountid?: string }>()

    // const [startDate, setStartDate] = useState<Date>(moment().subtract(10, 'days').startOf('month').add(OFFSET, 'minutes').toDate())
    const [startDate, setStartDate] = useState<Date>(sessionStorage.getItem('movement_start') ? new Date(parseFloat(sessionStorage.getItem('movement_start'))) : moment().startOf('month').toDate())
    const [endDate, setEndDate] = useState<Date>(sessionStorage.getItem('movement_end') ? new Date(parseFloat(sessionStorage.getItem('movement_end'))) : moment().endOf('day').toDate())

    const [accountOptions, setAccountOptions] = useState<{ label: string; value: string }[]>([])

    const [tipoMovimiento, setTipoMovimiento] = useState('')
    const [category, setCategory] = useState((!!query.category && query.category !== '__') ? query.category : '')
    const [accountId, setAccountId] = useState(query.account || '');

    const [search, setSearch] = useState('');
    const [searchByName] = useState(new Subject<string>());

    const [movements, setMovements] = useState<PaybookTransaction[]>([]);
    const [movementsLoaded, setMovementsLoaded] = useState(false);
    const [selectDict, setSelectDic] = useState<{ [key: string]: boolean }>({})

    const [projectOptions, setProjectOptions] = useState<CategoryProjectOptions[]>([])
    const [projectCategoryExtraOptions, setProjectCategoryExtraOptions] = useState<{label: string, value: string, disabled?: boolean}[]>([])

    // Pagination for facturas
    const [page, setPage] = useState(1)

    useQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankAccounts, {
        onCompleted: ({ getBankAccounts }: { getBankAccounts: PaybookAccount[] }) => {
            console.log(`(Movimientos) <getBankAccounts> `, getBankAccounts);
            const options = [{ label: 'Quitar filtro', value: '__' }].concat(getBankAccounts.map(a => ({ label: a.name, value: a.id_account })))
            console.log('>> Options: ', options);
            setAccountOptions(options);

        },
        fetchPolicy: 'cache-first'
        // fetchPolicy: 'cache-and-network'
    })

    useQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.getProjectionProjects, {
        onCompleted: ({ getProjectionProjects }: { getProjectionProjects: BankProject<'business'>[] }) => {
            console.log(`(Movimientos) <getProjectionProjects> `, getProjectionProjects);
            const _projectOptions = getProjectionProjects.map((gPP: (BankProject<'business'>)) => ({
                label: gPP.name,
                type: gPP.type,
                value: gPP._id,
                incomes: gPP.incomes?.map(i => ({ label: i.name, value: i._id })) || [],
                expenses: gPP.expenses?.map(i => ({ label: i.name, value: i._id })) || [],
            }))
            setProjectOptions(_projectOptions)
            if(getProjectionProjects.length) {
                const extraOptions = getProjectionProjects.map(p => ({ label: p.name, value: `__project_${p._id}`, disabled: false }))
                setProjectCategoryExtraOptions([{label: ' ', value: '__null', disabled: true}].concat(extraOptions))
            }
        },
        fetchPolicy: 'cache-and-network'
    })

    const [getMovements] = useLazyQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.getBankMovements, {
        onCompleted: ({ getBankMovements }: { getBankMovements: PaybookTransaction[] }) => {
            console.log(`(Movimientos) <getBankMovements> `, getBankMovements);
            setMovements(getBankMovements)
            setMovementsLoaded(true);
        },
        fetchPolicy: 'cache-and-network'
    })

    const [assignCategory] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.assignMovementCategory, {
        onCompleted: ({ assignMovementCategory }: { assignMovementCategory: string[] }) => {
            console.log(`(Movimientos) <assignMovementCategory> `, assignMovementCategory);
            sharedToasterSubject.next({ type: 'confirm', message: `Movimientos configurados correctamente` })
        },
    })

    const [assignProject] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.assignMovementProject, {
        onCompleted: ({ assignMovementProject }: { assignMovementProject: string[] }) => {
            console.log(`(Movimientos) <assignMovementProject> `, assignMovementProject);
            sharedToasterSubject.next({ type: 'confirm', message: `Movimientos configurados correctamente` })
        },
    })

    useEffect(() => {
        if (!!accountid) {
            setAccountId(accountid)
        }
    }, [accountid])

    useEffect(() => {
        console.log('(Movimientos) <uE> startDate endDate: ', startDate, endDate);
        if (startDate && endDate) {
            const _endDateTime = moment(endDate.getTime()).endOf('day').valueOf()
            getMovements({
                variables: {
                    start: startDate.getTime(),
                    end: _endDateTime
                }
            })
            // End Date should be logically treated as End Of Day
            sessionStorage.setItem('movement_start', `${startDate.getTime()}`)
            sessionStorage.setItem('movement_end', `${_endDateTime}`)
        }
    }, [startDate, endDate, getMovements])

    useEffect(() => {
        const subs = searchByName.pipe(debounceTime(300)).subscribe(name => {
            setSearch(name.toLowerCase())
            console.log('Name Searched', name);
        })
        return () => {
            subs.unsubscribe();
        }
    }, [searchByName])

    const changeDateRange = (start: Date, end: Date) => {
        setStartDate(start)
        setEndDate(end)
    }

    const searchFor = (e: React.ChangeEvent<HTMLInputElement>) => {
        const textValue = e.target.value;
        searchByName.next(textValue);
        // setSearch(textValue);
    }

    const onSelect = (movementId: string) => (bool: boolean) => {
        setSelectDic(dict => {
            dict[movementId] = bool;
            return Object.assign({}, dict)
        })
    }

    const onChangeCatgegory = (cat: string) => {
        if (cat === '__' || cat === category) {
            setCategory('')
        } else {
            setCategory(cat)
        }
    }

    const onChangeTipoMov = (tipoMov: string) => {
        if (tipoMov === '__' || tipoMov === tipoMovimiento) {
            setTipoMovimiento('')
        } else {
            setTipoMovimiento(tipoMov)
        }
    }

    const onChangeAccount = (acc: string) => {
        if (acc === '__' || acc === accountId) {
            setAccountId('')
        } else {
            setAccountId(acc)
        }
    }

    const onDeselectAll = () => {
        setSelectDic({})
    }

    const onSelectAllToogle = (paginatedMovements: PaybookTransaction[]) => (bool: boolean) => {
        if (bool) {
            const obj = {} as { [key: string]: boolean }
            paginatedMovements.forEach(pM => obj[pM.id_transaction] = true)
            setSelectDic(curr => Object.assign({}, curr, obj));
        } else {
            setSelectDic((curr) => {
                const negativeKeys = paginatedMovements.map(pM => pM.id_transaction);
                const possitiveKeys = Object.keys(curr).filter(k => !negativeKeys.includes(k));
                const obj = {} as { [key: string]: boolean }
                possitiveKeys.forEach(key => obj[key] = true);
                return obj;
            });
        }
    }

    const onCategorize = (category: string, subcategory: string) => {
        const movementIds = Object.keys(selectDict).filter((key) => selectDict[key])
        console.log(`>> movementIds: `, category, subcategory, movementIds);
        assignCategory({
            variables: {
                category,
                subcategory,
                movementIds
            }
        })
        setSelectDic({})
        const _movements: PaybookTransaction[] = JSON.parse(JSON.stringify(movements))
        const updateIdsSet = new Set(movementIds);
        _movements.forEach(m => {
            if (updateIdsSet.has(m.id_transaction)) {
                m.budgetCategory = category;
                m.budgetSubcategory = subcategory;
                m.projectId = null;
                m.projectName = null;
                m.projectTrackId = null;
            }
        })
        setMovements(_movements)
    }

    const onProjectAssign = (projectId: string, projectName: string, projectTrackId: string) => {
        const movementIds = Object.keys(selectDict).filter((key) => selectDict[key])
        console.log(`>> movementIds: `, projectId, projectTrackId, movementIds);
        assignProject({
            variables: {
                projectId,
                projectName,
                projectTrackId,
                movementIds
            }
        })
        setSelectDic({})
        const _movements: PaybookTransaction[] = JSON.parse(JSON.stringify(movements))
        const updateIdsSet = new Set(movementIds);
        _movements.forEach(m => {
            if (updateIdsSet.has(m.id_transaction)) {
                m.projectId = projectId;
                m.projectName = projectName;
                m.projectTrackId = projectTrackId;
                m.budgetCategory = null;
                m.budgetSubcategory = null;
            }
        })
        setMovements(_movements)
    }

    const PER_PAGE = 15;

    const movementsFiltered = movements
        .filter((m) => {
            if (!search) {
                return true;
            }
            const des = m.description.toLowerCase()
            const searchWords = search.split(' ');
            return searchWords.every((sW) => des.includes(sW));
        })
        .filter((m) => {
            if (!tipoMovimiento) {
                return true;
            }
            if (tipoMovimiento === 'ingreso') {
                return m.amount > 0
            } else {
                return m.amount <= 0
            }
        })
        .filter((m) => {
            if (!category) {
                return true;
            }
            if (category === '__null') {
                return !m.budgetCategory && !m.projectTrackId;
            }
            if(category.includes('__project_')) {
                const projectId = category.split('__project_')[1];
                return m.projectId === projectId;
            }
            return m.budgetCategory === category;
        })
        .filter((m) => {
            if (!accountId) {
                return true;
            }
            return m.id_account === accountId;
        })
    const _page = Math.ceil(movementsFiltered.length / PER_PAGE) < page ? Math.ceil(movementsFiltered.length / PER_PAGE) : page;
    const paginatedMovements = movementsFiltered.filter((m, i) => (i >= ((_page - 1) * PER_PAGE)) && (i < (_page * PER_PAGE)))
    const allSelected = !!paginatedMovements.length && paginatedMovements.every(pM => selectDict[pM.id_transaction])
    
    const composedCategories = CATEGORIES_LIST_OPTIONS.concat(projectCategoryExtraOptions as any);
    
    return (
        <div id="Movimientos">
            <SectionTitleBar currentUser={currentUser} title={`Movimientos Bancarios`} />
            <div className="movimientosContent">
                <div className='filterBlock'>
                    <div className='dates'>
                        <DateRangeInput
                            label='Rango fechas'
                            startDate={startDate}
                            endDate={endDate}
                            onChange={changeDateRange}
                            maxDate={moment().endOf('month').toDate()}
                            minDate={moment().subtract(12, 'months').startOf('month').toDate()}
                        />
                    </div>
                    <SelectCard label="Tipo"
                        value={tipoMovimiento}
                        onChange={onChangeTipoMov}
                        placeholder="Filtrar por Tipo"
                        options={AMOUNT_TYPE_OPTIONS} />
                    <SelectCard label="Categorías"
                        value={category}
                        onChange={onChangeCatgegory}
                        placeholder="Filtrar por Categoría"
                        options={composedCategories} />
                    <SelectCard label="Cuentas"
                        value={accountId}
                        onChange={onChangeAccount}
                        placeholder="Filtrar por Cuenta"
                        options={accountOptions} />
                </div>
                <div className='card'>
                    <div className="searchFor mobile">
                        <span className="material-icons">
                            search
                        </span>
                        <input onChange={searchFor} type="text" />
                    </div>
                    <div className='movement header'>
                        <div className='_type'>
                            <p>Tipo</p>
                        </div>
                        <div className='_category'>
                            <p>Categoría</p>
                        </div>
                        <div className='_informacion'>
                            <p>Información Bancaria</p>
                            <div className="searchFor">
                                <span className="material-icons">
                                    search
                                </span>
                                <input onChange={searchFor} type="text" />
                            </div>
                        </div>
                        <div className='_monto'>
                            <p>Monto</p>
                        </div>
                        <div>
                            <Checkbox color='#7D1B55' value={allSelected} onChange={onSelectAllToogle(paginatedMovements)} />
                        </div>
                    </div>
                    {
                        paginatedMovements.map((movement) => {

                            return (
                                <MovementEntry
                                    key={movement.id_transaction}
                                    movement={movement}
                                    onToggle={onSelect(movement.id_transaction)}
                                    selected={selectDict[movement.id_transaction]}
                                />
                            )
                        })
                    }
                    {
                        movementsLoaded && !movements.length ?
                            <div className='_empty'>
                                {
                                    !accountOptions.length ?
                                        <div className='_emptyText'>
                                            <p className='gray'>
                                                Debes agregar una cuenta de banco para ver tus movimientos
                                            </p>
                                            <p className='gray small'>
                                                Haz <NavLink to={`/administration/bancos`}>click aquí</NavLink> para ir a la tab de Bancos
                                            </p>
                                        </div>
                                        :
                                        <div className='_emptyText'>
                                            <p className='gray'>
                                                No hay movimientos a mostrar para este rango de fechas
                                            </p>
                                            <p className='gray small'>
                                                Haz <NavLink to={`/administration/bancos`}>click aquí</NavLink> si quieres re-sincronizar tus cuentas
                                            </p>
                                        </div>
                                }
                            </div>
                            : null
                    }
                    <Pagination page={_page} pages={Math.ceil(movementsFiltered.length / PER_PAGE)} onPage={setPage} />
                </div>
            </div>
            <MovimientosConfigureToaster
                movements={movements.filter(m => selectDict[m.id_transaction])}
                projectOptions={projectOptions}
                onDeselectAll={onDeselectAll}
                onCategorize={onCategorize}
                onProjectAssign={onProjectAssign}
            />
        </div>
    )
}

export default Movimientos;