import React from 'react';


interface PagosExternalProps {
    display: boolean;
}

function PagosExternalBill({ display }: PagosExternalProps) {
    return(
        <div className="card table noShadow">
            <div className="cardTitle">
                <div className="billsHeader">
                    <p>Factura externa a Fiscalpop</p>
                </div>
            </div>
            <div className="cardBody">
                
            </div>
        </div>
    )
}

export default PagosExternalBill;