import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseReact } from '../../../base.model';
import { Cotizacion, CotizacionPublic, IntentConfirm } from '../../../models/Cotizaciones';
import { ProfileQL } from '../../../models/Profile';
import { graphqlSchema } from '../../../services/graphql.schema';
import { clearGqlTypename } from '../../../utils/formatting';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import moment from 'moment';
import { phoneParse } from '../../../utils/phoneParser';

import './Cotizacion.view.scss';
import { formatCurrencyStyled } from '../../visuals/currency.formatted';
import Select from '../../Forms/Select';

import Button from '../../Forms/Button';

import Modal from 'react-modal';
import Input from '../../Forms/Input';
import CotizacionViewChangeCard from './Cotizacion.view.changecard';
import CotizacionViewHistory from './Cotizacion.view.history';
import sharedToasterSubject from '../../../services/shared.toasterSubject';
import CotizacionViewToBillCard from './Cotizacion.view.toBill';
import { Helmet } from 'react-helmet';

moment.locale('es')
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        border: 'none',
        background: 'transparent',
        boxShadow: 'none',
        padding: '3em'
    }
};
Modal.setAppElement('#root');


// Public key



interface CotGenProps extends BaseReact {
    currentUser: ProfileQL;
}


const INSTALLMENT_OPTIONS = [
    { value: 1, label: 'Sin mensualidades' },
    { value: 3, label: '3 Meses sin intereses' },
    { value: 6, label: '6 Meses sin intereses' },
    { value: 9, label: '9 Meses sin intereses' },
    { value: 12, label: '12 Meses sin intereses' },
]


function CotizacionClientView({ location, history, currentUser }: CotGenProps) {
    // PUBLIC COMPONENT, currentUser only specifies that this user is the vendor
    const { id } = useParams<{ id: string }>();
    const stripe = useStripe();
    const elements = useElements();

    const [cotizacion, setCotizacion] = useState<CotizacionPublic>(null);

    // Cotizacion operation
    const [approval, setApproval] = useState<{ [key: string]: { approval: boolean, quantity: number } }>({})

    const [installment, setInstallment] = useState(1);
    const [clientName, setClientName] = useState('');
    const [clientPhone, setClientPhone] = useState('');

    const [paySuccess, setPaySuccess] = useState(false);
    const [payError, setPayError] = useState('');
    const [isPaying, setIsPaying] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);

    const [requiresConfirm, setRequiresConfirm] = useState<IntentConfirm>(null)
    const [noInstallmentsAvail, setNoInstallmentsAvailable] = useState(false);
    const [displayToConfirm, setToConfirm] = useState(false);

    const [onPyamentIntentModal, setOnPaymentIntent] = useState(false)
    const [installmentOption, setInstallmentOption] = useState<{ label: string, value: number }[]>([])

    const [getCot] = useLazyQuery(graphqlSchema.FISCALPOP.COTIZACION.fetchCotizacion, {
        onCompleted: ({ fetchCotizacion }: { fetchCotizacion: CotizacionPublic }) => {
            console.log('fetchCotizacion: ', fetchCotizacion);
            setPaySuccess(fetchCotizacion.cotizacion.paidConfirmed || fetchCotizacion.cotizacion.paid)
            setCotizacion(clearGqlTypename(fetchCotizacion));
            setInstallment(fetchCotizacion.cotizacion.paymentTermsOffered);
            setInstallmentOption(INSTALLMENT_OPTIONS.filter(IO => IO.value <= fetchCotizacion.cotizacion.paymentTermsOffered));
            // If cotizacion is pending changes, reflect them
            const changesRequested = fetchCotizacion.cotizacion.history.find(h => h.event === 'changes_requested' && !h.closed);
            if (fetchCotizacion.cotizacion.changesRequested && !!changesRequested) {
                const _approval = changesRequested.applicableConcepts.reduce((p, c) => {
                    p[c.title] = {
                        approval: false,
                        quantity: c.quantity
                    };
                    return p;
                }, {} as { [key: string]: { approval: boolean, quantity: number } });
                setApproval(_approval);
            }
        },
        onError: (e) => {
            console.error('Error loading cot: ', e);
        }
    })

    const [setIntent] = useMutation(graphqlSchema.FISCALPOP.COTIZACION.setPaymentIntent, {
        onCompleted: ({ setPaymentIntent }: { setPaymentIntent: IntentConfirm }) => {
            console.log('Payment Intent res: ', setPaymentIntent);
            setIsPaying(false);
            if (setPaymentIntent.success) {
                // Completed, finalize
                setToConfirm(false);
                setRequiresConfirm(null);
                setPaySuccess(true)
                setNoInstallmentsAvailable(false);
                setTimeout(() => {
                    setOnPaymentIntent(false);
                }, 1200)

            } else if (setPaymentIntent.needsConfirm) {
                // Plans are either not available or limited, confirm
                setToConfirm(true);
                setRequiresConfirm(setPaymentIntent);
                if (!setPaymentIntent.plansAreAvailable) {
                    setNoInstallmentsAvailable(true);
                } else {
                    setInstallment(1);
                }
            } else {
                // Might be cause due to "No funds" or something similar
                setToConfirm(true);
                setRequiresConfirm(setPaymentIntent);
            }
        },
        onError: (e) => {
            setIsPaying(false);
            setToConfirm(false)
            console.error('Error on payment intent: ', e.graphQLErrors[0]);
            setPayError(e.graphQLErrors[0].message);
            setTimeout(() => {
                setPayError('');
            }, 5000)
        }
    })

    const [setIntentConfirm] = useMutation(graphqlSchema.FISCALPOP.COTIZACION.confirmIntent, {
        onCompleted: ({ confirmIntent }: { confirmIntent: IntentConfirm }) => {
            console.log('Payment intent confirm res: ', confirmIntent);
            setIsPaying(false);
            if (confirmIntent.success) {
                // Completed, finalize
                setToConfirm(false);
                setRequiresConfirm(null);
                setPaySuccess(true)
                setNoInstallmentsAvailable(false);
                setTimeout(() => {
                    setOnPaymentIntent(false);
                }, 2100)

            } else if (confirmIntent.needsConfirm) {
                // Plans are either not available or limited, confirm
                setToConfirm(true);
                setRequiresConfirm(confirmIntent);
                if (!confirmIntent.plansAreAvailable) {
                    setNoInstallmentsAvailable(true);
                } else {
                    setInstallment(1);
                }
            } else {
                // Might be cause due to "No funds" or something similar
                setToConfirm(true);
                setRequiresConfirm(confirmIntent);
            }
        },
        onError: (e) => {
            setIsPaying(false);
            setToConfirm(false);
            console.error('Error on payment intent: ', e.graphQLErrors[0]);
            setPayError(e.graphQLErrors[0].message);
            setTimeout(() => {
                setPayError('');
            }, 5000)
        }
    })
    const [confirmCancelled] = useMutation(graphqlSchema.FISCALPOP.COTIZACION.cancelCotizacion, {
        onCompleted: ({ cancelCotizacion }: { cancelCotizacion: Cotizacion }) => {
            console.log('Cancelled res: ', cancelCotizacion);
            setCotizacion(Object.assign({}, cotizacion, { cotizacion: cancelCotizacion }));
            setIsCancelling(false);
            sharedToasterSubject.next({ type: 'warning', message: 'Se canceló la cotización correctamente' });
        },
        onError: (e) => {
            setIsCancelling(false);
            console.error('Error cancelling: ', e.graphQLErrors[0]);
            sharedToasterSubject.next({ type: 'error', message: `Error al cancelar la cotización: ${e.graphQLErrors[0].message}` });
        }
    })

    useEffect(() => {
        if (!!id) {
            getCot({
                variables: {
                    cotizacionId: id
                }
            })
        }
    }, [id, getCot])

    const discountNameFormat = (discount: string) => {
        if (discount === '') { return 'Sin descuento' }
        if (discount === 'pronto_pago') { return 'Por pronto pago' }
        if (discount === 'por_cliente') { return 'Cliente frecuente' }
        if (discount === 'normal') { return 'Descuento normal' }
        return ''
    }

    const requestChangeQuantyty = (key: string) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            let qty = parseFloat(e.target.value);
            setApproval(appr => {
                if (qty < 0) {
                    qty = 0;
                }
                appr[key].quantity = qty;
                return Object.assign({}, appr);
            })
        }
    }

    const onChangesRequestSubmitted = (cotizacionUpdated: Cotizacion) => {
        setCotizacion(Object.assign({}, cotizacion, { cotizacion: cotizacionUpdated }))
    }

    const onClearChangesRequest = () => {
        setApproval({})
    }

    const openModal = () => {
        setOnPaymentIntent(true)
    }

    const closeModal = () => {
        setOnPaymentIntent(false)
    }

    const parsePhone = () => {
        if (!!clientPhone) {
            setClientPhone(phoneParse(clientPhone, true))
        }
    }

    const submitCancel = () => {
        setIsCancelling(true);
        confirmCancelled({
            variables: {
                cotizacionId: cotizacion.cotizacion._id
            }
        })
    }

    const submitPaymentForm = async (e: FormEvent) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        if (isPaying) {
            return;
        }
        setIsPaying(true);

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: clientName,
                phone: clientPhone
            },
        });

        if (error) {
            console.log('[error]', error);
            setPayError('Datos de pago incorrectos');
            setTimeout(() => {
                setPayError('');
            }, 3500)
            setIsPaying(false);
        } else {
            console.log('[PaymentMethod]', paymentMethod);
            // paymentMethod.card.funding
            setNoInstallmentsAvailable(false);
            setIntent({
                variables: {
                    cotizacionId: cotizacion.cotizacion._id,
                    installments: installment,
                    paymentMethodId: paymentMethod.id
                }
            })
        }
    }

    const confirmWithCard = () => {
        setIsPaying(true);
        setIntentConfirm({
            variables: {
                cotizacionId: cotizacion.cotizacion._id,
                intentId: requiresConfirm.intentId,
                installments: !requiresConfirm.plansAreAvailable ? 1 : installment,
            }
        })
    }
    const changeCard = () => {
        setToConfirm(false);
        const cardElement = elements.getElement(CardElement);
        cardElement.clear();
    }

    const approveConcept = (description: string, bool: boolean, qty: number) => {
        return () => {
            if (cotizacion.cotizacion.changesRequested) {
                return;
            }
            if (!!approval[description] && approval[description].approval === bool) {
                // Same action does nothing
                return;
            }
            setApproval(app => {
                app[description] = {
                    quantity: qty,
                    approval: bool
                };
                return Object.assign({}, app);
            })
        }
    }

    if (!cotizacion) {
        return (
            <div id="CotizacionView">
                <CotizacionViewHelmet location={location} />
            </div>
        );
    }

    // This is percent
    const feeTotalPercent = (cotizacion.cotizacion.feeFiscalpop + cotizacion.cotizacion.feeTransaction);
    const totalFeeConsidered = cotizacion.cotizacion.translateFee ? (cotizacion.cotizacion.total * (1 + (feeTotalPercent / 100))) : (cotizacion.cotizacion.total)
    // Remember, discounts expire
    const totalFeeDiscountConsidered = cotizacion.cotizacion.translateFee ? ((cotizacion.cotizacion.total * (1 + (feeTotalPercent / 100))) * (1 - (cotizacion.cotizacion.discount / 100))) : ((cotizacion.cotizacion.total) * (1 - (cotizacion.cotizacion.discount / 100)))

    // RENDER METHODS
    // ========================

    const renderPaymentForm = () => {
        return (
            <Modal
                isOpen={onPyamentIntentModal}
                onRequestClose={closeModal}
                style={customStyles}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                contentLabel="Formato de pago"
            >
                <form id="StripeForm" onSubmit={submitPaymentForm}>
                    <h2>
                        Pago de cotización
                    </h2>
                    <div className="_closeAnimate" onClick={closeModal}>
                        <span className="material-icons">
                            close
                        </span>
                    </div>
                    <div className="_billingInfo">
                        <div className="cardElement">
                            <Input type="text" value={clientName} onChange={setClientName} placeholder="Nombre en la tarjeta" />
                        </div>
                        <div className="cardElement">
                            <Input type="text" hasError={!!clientPhone && clientPhone.length !== 10} onBlur={parsePhone} value={clientPhone} onChange={setClientPhone} placeholder="Telefono" />
                        </div>

                    </div>
                    <div className="cardElement">
                        <CardElement options={{
                            style: {
                                invalid: {
                                    color: '#FD7977'
                                }
                            }
                        }} />
                    </div>
                    <Button primary={true} type="submit" setClass={`${payError ? '_error' : ''} ${paySuccess ? '_success' : ''}`} disabled={isPaying}>
                        {
                            !!paySuccess ?
                                <span>
                                    Pagado!
                                </span>
                                :
                                (
                                    payError ?
                                        <span>
                                            {payError}
                                        </span>
                                        :
                                        (
                                            isPaying ?
                                                <span>
                                                    Pagando...
                                        </span>
                                                :
                                                <span>
                                                    Pagar {formatCurrencyStyled(totalFeeDiscountConsidered)} {installment > 1 ? `a ${installment} meses` : ''}
                                                </span>
                                        )
                                )
                        }
                    </Button>
                    <div className={`StripeFormPlanIssue ${displayToConfirm && !!requiresConfirm ? '_toConfirm' : ''}`}>
                        {
                            !requiresConfirm ? ''
                                :
                                <div className="confirmAlternate">
                                    {noInstallmentsAvail || !requiresConfirm.plansAreAvailable ?
                                        <p>
                                            <b>No hay mensualidades para esta tarjeta,</b> puedes usar otra o pagar en una sola exhibición.
                                        </p>
                                        :
                                        <div className="_alternateInstallments">
                                            <p>
                                                <b>El plan que seleccionaste no está disponible para esta tarjeta,</b> escoge otro plan o utiliza otra tarjeta
                                            </p>
                                            <Select hasError={!requiresConfirm.plansAvailable.some(pA => pA.count === installment) && installment > 1} value={installment} onChange={setInstallment} label={'A meses disponible'} options={requiresConfirm.plansAvailable.map(p => ({ label: `${p.count} Meses sin intereses`, value: p.count }))} />
                                        </div>
                                    }
                                </div>
                        }
                        <div className="actions">
                            <Button tertiary={true} handleClick={changeCard} disabled={isPaying}>
                                <span>
                                    Usar otra tarjeta
                                </span>
                            </Button>
                            <Button secondary={true} handleClick={confirmWithCard}
                                disabled={isPaying || (!!requiresConfirm && requiresConfirm.plansAreAvailable && (!requiresConfirm.plansAvailable.some(pA => pA.count === installment) && installment > 1))}>
                                {
                                    isPaying ?
                                        <span>
                                            Confirmando...
                                        </span>
                                        :
                                        <span>
                                            Confirmar pago
                                        </span>
                                }
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>
        )
    }

    const isRejected = (cotizacion?.cotizacion?.changesRequested ? true : Object.keys(approval).map(k => approval[k]).reduce((p, c) => p || !c.approval, false));
    const isCancelled = cotizacion.cotizacion.wasCancelled;

    return (
        <div id="CotizacionView">
            <CotizacionViewHelmet location={location} />
            <div className="cotizacionContain">
                <div className="headOfCot">
                    <div className="branding">
                        <img src={cotizacion.fiscalpop.logoUrl} alt={'Logo empresa'} />

                    </div>
                    <div className="headInfo">
                        <h1>
                            {cotizacion.profile.companyName}
                        </h1>
                        <div className="aboutThis">

                            <div className="keyVal">
                                <p className="label">
                                    Fecha:
                            </p>
                                <p className="value">
                                    {moment(cotizacion.cotizacion.createdAt).format('DD/MMM/YYYY')}
                                </p>
                            </div>
                            <div className="keyVal">
                                <p className="label">
                                    Cliente:
                            </p>
                                <p className="value">
                                    {cotizacion.cotizacion.name}
                                </p>
                            </div>
                        </div>
                        <div className="descibe">
                            <div className="keyVal">
                                <p className="label">
                                    <b>Descripción de la cotización</b>:
                            </p>
                                <p className="value">
                                    {cotizacion.cotizacion.note}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="contentOfCot">
                    <h2>
                        Conceptos
                    </h2>
                    {
                        cotizacion.cotizacion.conceptos.map((concepto, i) => {
                            const unitaryAmountPreTax = concepto.valorUnitario;
                            const taxAmount = concepto.impuestos.reduce((p, c) => {
                                if (c.tasa) {
                                    p += (concepto.valorUnitario * c.tasa);
                                } else if (c.cuota) {
                                    p += c.cuota;
                                }
                                return p
                            }, 0);
                            const unitaryAmount = cotizacion.cotizacion.translateFee ? (unitaryAmountPreTax + taxAmount) * (1 + (feeTotalPercent / 100)) : (unitaryAmountPreTax + taxAmount);
                            const hasApproval = approval[concepto.descripcion];
                            const approvalForConcept = cotizacion.cotizacion.paid || approval[concepto.descripcion]?.approval;
                            return (
                                <div className={`entry ${cotizacion.cotizacion.changesRequested ? 'readonly' : ''} ${!!hasApproval ? (approvalForConcept ? 'approved' : 'rejected') : ''}`} key={i}>
                                    <p className="description">{concepto.descripcion}</p>
                                    <p className="amount">{formatCurrencyStyled(unitaryAmount)}</p>
                                    <div className="cantidaddMod">
                                        <input className="cantidad" type="number" disabled={!hasApproval || approvalForConcept || cotizacion.cotizacion.changesRequested} value={(!!hasApproval ? (approvalForConcept ? concepto.cantidad : hasApproval.quantity) : concepto.cantidad)} onChange={requestChangeQuantyty(concepto.descripcion)} />
                                        <span className="_qtyMod">
                                            {(hasApproval?.quantity || 0) - concepto.cantidad}
                                        </span>
                                    </div>
                                    <p className="amount amount_total">{formatCurrencyStyled(concepto.cantidad * unitaryAmount)}</p>
                                    <div className={`acknowledge ${!!hasApproval || cotizacion.cotizacion.paid ? (approvalForConcept ? 'approved' : 'rejected') : ''}`}>
                                        <span className="material-icons bad" onClick={approveConcept(concepto.descripcion, false, 0)}>
                                            cancel
                                        </span>
                                        <span className="material-icons good" onClick={approveConcept(concepto.descripcion, true, concepto.cantidad)}>
                                            check_circle
                                        </span>
                                        <div className="bad xs" onClick={approveConcept(concepto.descripcion, false, 0)}>
                                            <span className="material-icons" >
                                                close
                                        </span>
                                        </div>
                                        <div className="good xs" onClick={approveConcept(concepto.descripcion, true, concepto.cantidad)}>
                                            <span className="material-icons" >
                                                done
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="conceptTotal">
                        <h2>Total: {formatCurrencyStyled(totalFeeConsidered)} MXN</h2>
                    </div>
                    <CotizacionViewHistory histories={cotizacion.cotizacion.history} logoUrl={cotizacion.fiscalpop.logoUrl} />
                    <CotizacionViewChangeCard cotizacionId={cotizacion.cotizacion._id} isRejected={isRejected && (cotizacion?.cotizacion?.changesRequested ? false : true)} approval={approval} onChangesRequestSubmitted={onChangesRequestSubmitted} onClearChangesRequest={onClearChangesRequest} />
                    <CotizacionViewToBillCard cotizacion={cotizacion.cotizacion} token={cotizacion.profile._id} profToken={cotizacion.profile.token} isPaid={cotizacion?.cotizacion?.paid || paySuccess} onStampRequestSubmitted={onChangesRequestSubmitted} />

                    <h2>
                        Condiciones de Pago
                    </h2>
                    <div className={`newCard ${isRejected || isCancelled ? '_rejected' : ''}`}>
                        <div className="mesesSelect">
                            <p>
                                <b>
                                    Meses sin intereses disponible:
                                </b>
                            </p>
                            <Select disabled={paySuccess} label='Meses sin intereses' value={installment} options={installmentOption} onChange={setInstallment} />
                        </div>
                        {
                            cotizacion.cotizacion.discountName ?
                                <div className="mesesSelect">
                                    <p>
                                        <b>{discountNameFormat(cotizacion.cotizacion.discountName)}</b> <br />
                                        {cotizacion.cotizacion.expiration ?
                                            <span className="discountExpires">
                                                Válido hasta {moment(new Date(cotizacion.cotizacion.expiration)).format('DD / MMM / YYYY')}
                                            </span>
                                            : ''}
                                    </p>
                                    <p>
                                        {cotizacion.cotizacion.discount}{cotizacion.cotizacion.discountFormat === 'percent' ? '%' : ' $'} de Descuento
                                    </p>
                                </div>

                                : ''
                        }
                        <div className={`payTotal ${paySuccess ? '_pagado' : ''}`}>
                            <h2>Total {paySuccess ? 'pagado' : 'a pagar'}: {formatCurrencyStyled(totalFeeDiscountConsidered)} MXN</h2>
                            <div className="xs">
                                 <h3>Total {paySuccess ? 'pagado' : 'a pagar'}:</h3>
                                 <h2>{formatCurrencyStyled(totalFeeDiscountConsidered)} MXN</h2>
                            </div>
                            <div className="payActions">
                                <Button secondary={true} setClass="cancel" handleClick={submitCancel} disabled={isCancelled || isCancelling}>
                                    <span>
                                        Cancelar cotización
                                    </span>
                                </Button>
                                <Button primary={true} handleClick={openModal} disabled={isRejected || isCancelled || isCancelling}>
                                    <span>
                                        Ir a Pagar
                                        </span>
                                </Button>
                            </div>
                        </div>
                        <div className={`pagadoContain ${paySuccess ? '_pagado' : ''}`}>
                            <div className="pagadoNotice">
                                <p>
                                    Pagado
                                </p>
                            </div>
                        </div>
                        <div className={`rejectedContain`}>
                            {
                                cotizacion.cotizacion.wasCancelled ?
                                    <div className="rejectedNotice">
                                        <p>
                                            Cotización cancelada
                                        </p>
                                        <p>
                                            No es posible proceder con el pago, si necesitas otra cotización solicítala al vendedor.
                                        </p>
                                    </div>
                                    :
                                    <div className="rejectedNotice">
                                        <p>
                                            Haz solicitado cambios en la cotización.
                                        </p>
                                        <p>
                                            El vendedor debe aprovar o rechazar tus cambios antes de poder pagar
                                        </p>
                                    </div>

                            }
                        </div>
                    </div>
                </div>
            </div>
            {renderPaymentForm()}
        </div>
    )
}


function CotizacionViewHelmet({ location }: { location: CotGenProps['location'] }) {
    const locationParams: { hLogo?: string; hCompany?: string } = location.search ? location.search.replace('?', '').split('&').reduce((p, c) => {
        const [key, val] = c.split('=');
        p[key as 'hLogo' | 'hCompany'] = val;
        return p;
    }, { hLogo: '', hCompany: '' } as { hLogo?: string; hCompany?: string }) : { hCompany: '', hLogo: '' };
    console.log('Cotizacion view location props: ', locationParams);
    return (
        <Helmet title={`${locationParams.hCompany} Cotización`}
            meta={[
                {
                    name: `charset`,
                    content: `utf-8`,
                },
                {
                    name: `description`,
                    content: `Facturación personal y para empresas`,
                },
                {
                    name: `viewport`,
                    content: `width=device-width,initial-scale=1`,
                },
                {
                    name: `description`,
                    content: `Cotización de parte de ${locationParams.hCompany}`,
                },
                {
                    property: `og:title`,
                    content: `${locationParams.hCompany} Cotización`,
                },
                {
                    property: `og:description`,
                    content: `Cotización de parte de ${locationParams.hCompany}`,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:locale`,
                    content: `es_MX`,
                },
                {
                    property: `og:image`,
                    content: `${locationParams.hLogo}`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: `Fiscalpop`,
                },
                {
                    name: `twitter:title`,
                    content: `${locationParams.hCompany} Cotización`,
                },
                {
                    name: `twitter:description`,
                    content: `Cotización de parte de ${locationParams.hCompany}`,
                },
            ]}>
        </Helmet>
    )
}


export default CotizacionClientView;