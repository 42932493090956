import React, { useEffect, useState } from 'react';

import { select, scaleLinear, axisBottom, axisLeft, format, line, area, curveMonotoneX } from 'd3';

import './BarAndLine.chart.scss';
import useWindowResize from '../../Hooks/useWindowResize';
import { numberToCurrencyString } from '../../../services/formatting';


interface BarLineAndSpreadChartProps {
    barGroups?: {
        name?: string;
        color: string;
        values: { x: number, y: number, yShadow?: number, colorOverride?: string }[]
        // yShadow is used to portray a lower opacity bar behind current one
    }[]
    lineGroups?: {
        name?: string;
        color: string;
        useCircles?: boolean;
        values: { x: number, y: number }[]
        valueMilestones?: { x: number, y: number, text: string }[]
    }[];
    spreadGroups?: {
        name?: string;
        color: string;
        values: { x: number, y0: number, y1: number }[]
    }[];
    candlestickGroups?: {
        name?: string;
        color: string;
        values: {
            x: number;
            yTop: number;
            yTopOutlier: number;
            median?: number; // As Reference
            yBottom: number;
            yBottomOutlier: number;
        }[]
    }[];
    margin: { left?: number; right?: number; top?: number; bottom?: number }
    xAxisTicks?: 'from-data' | 'auto'
    xAxisExcludeFromTicks?: ('barGroups' | 'lineGroups' | 'spreadGroups' | 'candlestickGroups')[];
    xAxisFormatting?: (d: number) => string;
}

function BarLineAndSpreadChart({ barGroups, lineGroups, candlestickGroups, spreadGroups, margin, xAxisTicks, xAxisExcludeFromTicks, xAxisFormatting }: BarLineAndSpreadChartProps) {
    const { windowWidth } = useWindowResize()
    const containerRef = React.createRef<HTMLDivElement>();
    const tooltipRef = React.createRef<HTMLDivElement>();

    const [height, setHeight] = useState<number>(null);
    const [width, setWidth] = useState<number>(null);

    // const [tooltipData, setToolTipData] = useState<{ text: string, x: number, y: number }>(null)

    useEffect(() => {
        if (containerRef) {
            const _height = containerRef.current.getBoundingClientRect().height;
            const _width = containerRef.current.getBoundingClientRect().width;


            setHeight(_height)
            setWidth(_width)
        }
    }, [containerRef, windowWidth])


    useEffect(() => {
        if (width && height && tooltipRef?.current) {
            try {

                // Does it has SVG yet? 
                const hasSvg = select(containerRef.current).select<SVGSVGElement>('svg')
                const svg = !hasSvg.empty() ? hasSvg : select(containerRef.current).append('svg');
                svg
                    // .attr('width', width + (margin.left || 0) + (margin.right || 0))
                    // .attr('height', height + (margin.top || 0) + (margin.bottom || 0))
                    .attr('width', width)
                    .attr('height', height)

                // Does it have child G ? (This ius main G)
                const hasG = svg.select<SVGGElement>('g');
                const svgG = !hasG.empty() ? hasG : svg.append('g');
                svgG.attr('transform', `translate(${(margin.left || 0)},${(margin.top || 0)})`)



                // X Axis
                // ============
                const barxValues: number[] = xAxisExcludeFromTicks?.includes('barGroups') ? [] : (barGroups || []).reduce((p, c) => p.concat(c.values.map(v => v.x)), [] as number[])
                const linexValues: number[] = xAxisExcludeFromTicks?.includes('lineGroups') ? [] : (lineGroups || []).reduce((p, c) => p.concat(c.values.map(v => v.x)), [] as number[])
                const spreadxValues: number[] = xAxisExcludeFromTicks?.includes('spreadGroups') ? [] : (spreadGroups || []).reduce((p, c) => p.concat(c.values.map(v => v.x)), [] as number[])
                const candlexValues: number[] = xAxisExcludeFromTicks?.includes('candlestickGroups') ? [] : (candlestickGroups || []).reduce((p, c) => p.concat(c.values.map(v => v.x)), [] as number[])

                const xData = [...new Set(barxValues.concat(linexValues).concat(spreadxValues).concat(candlexValues).sort((a, b) => a - b))];
                const xAxis = scaleLinear()
                    .domain([Math.min(...xData), Math.max(...xData)])
                    .range([(margin.left || 0), width - ((margin.left || 0) + (margin.right || 0))])

                const hasXaxisG = svgG.select<SVGGElement>('g._xAxis');
                const xAxisG = !hasXaxisG.empty() ? hasXaxisG : svgG.append('g').attr('class', '_xAxis');

                let xAxisConf = axisBottom(xAxis);
                if (xAxisTicks === 'from-data') {
                    xAxisConf = xAxisConf.tickValues(xData)
                }
                if (xAxisFormatting) {
                    xAxisConf = xAxisConf.tickFormat((d, i) => xAxisFormatting(d as any as number))
                }

                xAxisG
                    .attr("transform", `translate(0, ${height - ((margin.bottom || 0) + (margin.top || 0))})`)
                    .call(xAxisConf)
                    .selectAll("text")
                    .attr("transform", "translate(0,0)")
                    .attr('font-family', '"DM Sans", sans-serif')
                    .style("text-anchor", "center")

                xAxisG
                    .select(".domain")
                    .attr("stroke", "#A4A5B3")
                    .attr("opacity", "0")

                // Y Axis
                // ============
                const yData = [...new Set(
                    (!!barGroups ? barGroups.reduce((p, c) => p.concat(c.values.map(v => (v.y > (v.yShadow || 0) ? v.y : (v.yShadow || v.y)))), [0] as number[]) : [])
                        .concat((!!lineGroups ? lineGroups.reduce((p, c) => p.concat(c.values.map(v => v.y)), [] as number[]) : []))
                        .concat((!!candlestickGroups ? candlestickGroups.reduce((p, c) => p.concat(c.values.map(v => v.yTopOutlier || v.yTop)), [] as number[]) : []))
                        .concat((!!candlestickGroups ? candlestickGroups.reduce((p, c) => p.concat(c.values.map(v => v.yBottomOutlier || v.yBottom)), [] as number[]) : []))
                        .sort((a, b) => a - b))];

                const yDomain = [Math.min(...yData), Math.max(...yData)];
                // NOTE: Math.log10 doesn't work on 0, at least use -1
                if (yDomain[0] === 0) {
                    yDomain[0] = -1
                }
                if (yDomain[1] === 0) {
                    yDomain[1] = -1
                }
                // console.log(`>>  Y AXIS RAW:`, yDomain)

                const yMinExponent = Math.floor(Math.log10(Math.abs(yDomain[0])))
                const yMinRound = Math.ceil(Math.abs(yDomain[0]) / Math.pow(10, Math.floor(Math.log10(Math.abs(yDomain[0])))))
                const yMinDomain = Math.pow(10, yMinExponent) * yMinRound * (yDomain[0] < 0 ? -1 : 1);

                const yMaxExponent = Math.floor(Math.log10(Math.abs(yDomain[1])))
                const yMaxRound = Math.ceil(Math.abs(yDomain[1]) / Math.pow(10, Math.floor(Math.log10(Math.abs(yDomain[1])))))
                const yMaxDomain = Math.pow(10, yMaxExponent) * yMaxRound * (yDomain[1] < 0 ? -1 : 1);

                // console.log(`>>  Y AXIS DOMAIN:`, [yMinDomain, yMaxDomain])
                const yAxis = scaleLinear()
                    .domain([yMinDomain, yMaxDomain])
                    .range([height - ((margin.top || 0)) - (margin.bottom || 0), 0])


                const hasYaxisG = svgG.select<SVGGElement>('g._yAxis');
                const yAxisG = !hasYaxisG.empty() ? hasYaxisG : svgG.append('g').attr('class', '_yAxis');

                yAxisG.attr('transform', `translate(0, 0)`)
                const yAxisConf = axisLeft(yAxis).tickFormat(format('.2s'));
                yAxisG
                    .call(yAxisConf)
                yAxisG
                    .select(".domain")
                    .attr("opacity", "0")
                yAxisG
                    .selectAll('line')
                    .attr('x2', width - (margin.right || 0) - (margin.left || 0))
                    .attr("opacity", (d: any): string => {
                        if (d === 0) {
                            return "0.3"
                        }
                        return "0.10"
                    })

                yAxisG.selectAll('text').attr('font-family', '"DM Sans", sans-serif')

                // 1. BAR ENTRIES
                // =================
                if (!!barGroups && barGroups.length) {
                    const BAR_WIDTH = 12;

                    const hasBarG = svgG.select<SVGGElement>('g._bars');
                    const svgBarG = !hasBarG.empty() ? hasBarG : svgG.append('g').attr('class', '_bars');
                    svgBarG.attr('transform', `translate(${(-(BAR_WIDTH / 2))},${(0)})`)

                    const factorSpread = (n: number) => (index: number) => {
                        const i = (n - 1) / 2;
                        return (index - i)
                    }
                    let groupIndex = 0;

                    for (const group of barGroups) {
                        const hasBarGroupG = svgBarG.select<SVGGElement>(`g._bars_${groupIndex}`);
                        const svgBarGroupG = !hasBarGroupG.empty() ? hasBarGroupG : svgBarG.append('g').attr('class', `_bars_${groupIndex}`);
                        const factorSpreadValue = factorSpread(barGroups.length)(groupIndex) * (BAR_WIDTH + 2)
                        svgBarGroupG.attr('transform', `translate(${factorSpreadValue},${(0)})`)

                        // Clear past Rects ?
                        svgBarGroupG.selectAll('rect').remove()

                        // Add Shadow rects First (because of mouseover)
                        const shadowValues = group.values.filter(d => d.yShadow)
                        if (shadowValues) {
                            svgBarGroupG.selectAll('rect._shadow')
                                .data(shadowValues)
                                .enter()
                                .append('rect')
                                .attr('class', '_shadow')
                                .attr("x", (d) => xAxis(d.x))
                                .attr("y", (d) => yAxis(d.yShadow))
                                .attr("width", BAR_WIDTH)
                                .attr("height", (d) => ((height - (margin.bottom || 0) - (margin.top || 0)) - yAxis(d.yShadow)))
                                .attr("fill", group.color)
                                .attr("fill-opacity", '0.6')
                                .on('mouseenter', function () {
                                    const _x = parseFloat(this.getAttribute('x')) + factorSpreadValue;
                                    const _y = this.getAttribute('y')
                                    const value = numberToCurrencyString(yAxis.invert(parseFloat(_y)));
                                    if(tooltipRef?.current) {
                                        tooltipRef.current.classList.add('_display')
                                        tooltipRef.current.innerHTML = `<p>$${value}</p>`
                                        tooltipRef.current.style.transform = `translate(${_x}px, ${_y}px)`;
                                    }
                                })
                                .on('mouseleave', function () {
                                    if(tooltipRef?.current) {
                                        tooltipRef.current.classList.remove('_display')
                                    }
                                })
                        }

                        // Add actual values
                        svgBarGroupG.selectAll('rect._actual')
                            .data(group.values.filter(v => v.y !== null))
                            .enter()
                            .append('rect')
                            .attr('class', '_actual')
                            .attr("x", (d) => xAxis(d.x))
                            .attr("y", (d) => yAxis(d.y))
                            .attr("width", BAR_WIDTH)
                            .attr("height", (d) => ((height - (margin.bottom || 0) - (margin.top || 0)) - yAxis(d.y)))
                            .attr("fill", (d) => d.colorOverride || group.color)
                            .on('mouseenter', function () {
                                const _x = parseFloat(this.getAttribute('x')) + factorSpreadValue;
                                const _y = this.getAttribute('y')
                                const value = numberToCurrencyString(yAxis.invert(parseFloat(_y)));
                                tooltipRef.current.classList.add('_display')
                                tooltipRef.current.innerHTML = `<p>$${value}</p>`
                                tooltipRef.current.style.transform = `translate(${_x}px, ${_y}px)`;
                            })
                            .on('mouseleave', function () {
                                tooltipRef.current.classList.remove('_display')
                            })


                        ++groupIndex;
                    }
                }

                // 2. SPREAD ENTRIES
                // ==================
                if (!!spreadGroups && !!spreadGroups.length) {
                    const hasSpreadG = svgG.select<SVGGElement>('g._spread');
                    const svgSpreadG = !hasSpreadG.empty() ? hasSpreadG : svgG.append('g').attr('class', '_spread');
                    svgSpreadG.attr('transform', `translate(${0},${0})`)

                    let groupIndex = 0;
                    for (const group of spreadGroups) {
                        const hasSpreadGroupG = svgSpreadG.select<SVGGElement>(`g._spreads_${groupIndex}`);
                        const svgSpreadGroupG = !hasSpreadGroupG.empty() ? hasSpreadGroupG : svgSpreadG.append('g').attr('class', `_spreads_${groupIndex}`);
                        svgSpreadGroupG.attr('transform', `translate(${0},${(0)})`)

                        // Clear past Rects ?
                        svgSpreadGroupG.selectAll('path').remove()
                        svgSpreadGroupG.selectAll('circle').remove()

                        // Add the line
                        svgSpreadGroupG.append("path")
                            .datum(group.values)
                            .attr("fill", group.color)
                            .attr("stroke", group.color)
                            .attr("stroke-width", 1.5)
                            .attr("d", area()
                                .x((d: any) => { return xAxis(d.x) })
                                .y0((d: any) => { return yAxis(d.y0) })
                                .y1((d: any) => { return yAxis(d.y1) })
                                .curve(curveMonotoneX) as any)


                        ++groupIndex;
                    }
                }

                // 3. CANDLE ENTRIES
                // =================
                if (!!candlestickGroups && candlestickGroups.length) {
                    const BAR_WIDTH = 12;

                    const hasBarG = svgG.select<SVGGElement>('g._candlebars');
                    const svgBarG = !hasBarG.empty() ? hasBarG : svgG.append('g').attr('class', '_candlebars');
                    svgBarG.attr('transform', `translate(${(-(BAR_WIDTH / 2))},${(0)})`)

                    const factorSpread = (n: number) => (index: number) => {
                        const i = (n - 1) / 2;
                        return (index - i)
                    }
                    let groupIndex = 0;

                    for (const group of candlestickGroups) {
                        const hasBarGroupG = svgBarG.select<SVGGElement>(`g._candlebars_${groupIndex}`);
                        const svgBarGroupG = !hasBarGroupG.empty() ? hasBarGroupG : svgBarG.append('g').attr('class', `_candlebars_${groupIndex}`);
                        const factorSpreadValue = factorSpread(candlestickGroups.length)(groupIndex) * (BAR_WIDTH + 2)
                        svgBarGroupG.attr('transform', `translate(${factorSpreadValue},${(0)})`)

                        // Clear past Rects ?
                        svgBarGroupG.selectAll('rect').remove()

                        svgBarGroupG.selectAll('rect')
                            .data(group.values)
                            .enter()
                            .append('rect')
                            .attr("x", (d) => xAxis(d.x))
                            .attr("y", (d) => yAxis(d.yTop))
                            .attr("width", BAR_WIDTH)
                            // .attr("height", (d) => ((height - (margin.bottom || 0) - (margin.top || 0)) - yAxis(d.yTop)))
                            .attr("height", (d) => (yAxis(d.yBottom) - yAxis(d.yTop)))
                            .attr("fill", group.color)
                            .on('mouseenter', function () {
                                // Candle Tooltip requires it's own ToolTip
                                const _x = parseFloat(this.getAttribute('x')) + factorSpreadValue;
                                const _y = this.getAttribute('y')
                                const value = numberToCurrencyString(yAxis.invert(parseFloat(_y)));
                                tooltipRef.current.classList.add('_display')
                                tooltipRef.current.innerHTML = `<p>$${value}</p>`
                                tooltipRef.current.style.transform = `translate(${_x}px, ${_y}px)`;
                            })
                            .on('mouseleave', function () {
                                tooltipRef.current.classList.remove('_display')
                            })

                        svgBarGroupG.selectAll('rect._thread')
                            .data(group.values)
                            .enter()
                            .append('rect')
                            .attr('class', '_thread')
                            .attr("x", (d) => {
                                if ((yAxis(d.yBottom) - yAxis(d.yTop)) < 5) {
                                    // As some scaling can show small IQR proportions as height 0, 
                                    // thread should take over visually if IQR's body's height is too small.
                                    return xAxis(d.x);
                                } else {
                                    return (xAxis(d.x) + (BAR_WIDTH - 2) / 2);
                                }
                            })
                            .attr("y", (d) => yAxis(d.yTopOutlier))
                            .attr("width", (d) => {
                                if ((yAxis(d.yBottom) - yAxis(d.yTop)) < 5) {
                                    // As some scaling can show small IQR proportions as height 0, 
                                    // thread should take over visually if IQR's body's height is too small.
                                    return BAR_WIDTH;
                                } else {
                                    return 2;
                                }
                            })
                            // .attr("height", (d) => ((height - (margin.bottom || 0) - (margin.top || 0)) - yAxis(d.yTop)))
                            .attr("height", (d) => (yAxis(d.yBottomOutlier) - yAxis(d.yTopOutlier)))
                            .attr("fill", group.color)

                        svgBarGroupG.selectAll('rect._median')
                            .data(group.values)
                            .enter()
                            .append('rect')
                            .attr('class', '_median')
                            .attr("x", (d) => xAxis(d.x))
                            .attr("y", (d) => yAxis(d.median))
                            .attr("width", (d) => {
                                return BAR_WIDTH;
                            })
                            // .attr("height", (d) => ((height - (margin.bottom || 0) - (margin.top || 0)) - yAxis(d.yTop)))
                            .attr("height", 1)
                            .attr("fill-opacity", 0.7)
                            .attr("fill", 'white')

                        ++groupIndex;
                    }
                }

                // 4. LINE ENTRIES
                // ==================
                if (!!lineGroups && !!lineGroups.length) {
                    const hasLineG = svgG.select<SVGGElement>('g._line');
                    const svgLineG = !hasLineG.empty() ? hasLineG : svgG.append('g').attr('class', '_line');
                    svgLineG.attr('transform', `translate(${0},${0})`)


                    let groupIndex = 0;
                    for (const group of lineGroups) {
                        const hasLineGroupG = svgLineG.select<SVGGElement>(`g._lines_${groupIndex}`);
                        const svgLineGroupG = !hasLineGroupG.empty() ? hasLineGroupG : svgLineG.append('g').attr('class', `_lines_${groupIndex}`);
                        svgLineGroupG.attr('transform', `translate(${0},${(0)})`)

                        // Clear past Rects ?
                        svgLineGroupG.selectAll('path').remove()
                        svgLineGroupG.selectAll('circle').remove()

                        // Add the line
                        group.values = group.values.sort((a, b) => a.x - b.x)
                        // Outline
                        svgLineGroupG.append("path")
                            .datum(group.values)
                            .attr("fill", "none")
                            .attr("stroke", 'white')
                            .attr("stroke-width", 3.5)
                            .attr("d", line()
                                .x((d: any) => { return xAxis(d.x) })
                                .y((d: any) => { return yAxis(d.y) })
                                .curve(curveMonotoneX) as any)
                        // Filled
                        svgLineGroupG.append("path")
                            .datum(group.values)
                            .attr("fill", "none")
                            .attr("stroke", group.color)
                            .attr("stroke-width", 1.5)
                            .attr("d", line()
                                .x((d: any) => { return xAxis(d.x) })
                                .y((d: any) => { return yAxis(d.y) })
                                .curve(curveMonotoneX) as any)

                        // Optional circle (is overridden by milestones)
                        if (group.useCircles) {
                            svgLineGroupG
                                .selectAll('circle')
                                .data(group.values)
                                .enter()
                                .append('circle')
                                .attr('cx', (d) => xAxis(d.x))
                                .attr('cy', (d) => yAxis(d.y))
                                .attr('r', 5)
                                .attr("fill", group.color)
                                .attr("stroke", 'white')
                                .attr("stroke-width", 1)
                                .on('mouseenter', function () {
                                    const _x = parseFloat(this.getAttribute('cx'));
                                    const _y = this.getAttribute('cy')
                                    const value = numberToCurrencyString(yAxis.invert(parseFloat(_y)));
                                    if(tooltipRef?.current) {
                                        tooltipRef.current.classList.add('_display')
                                        tooltipRef.current.innerHTML = `<p>$${value}</p>`
                                        tooltipRef.current.style.transform = `translate(${_x}px, ${_y}px)`;
                                    }
                                })
                                .on('mouseleave', function () {
                                    if(tooltipRef?.current) {
                                        tooltipRef.current.classList.remove('_display')
                                    }
                                })
                        }

                        // Milestone circles
                        if (group.valueMilestones?.length) {
                            svgLineGroupG
                                .selectAll('circle')
                                .data(group.valueMilestones)
                                .enter()
                                .append('circle')
                                .attr('cx', (d) => xAxis(d.x))
                                .attr('cy', (d) => yAxis(d.y))
                                .attr('r', 5)
                                .attr("fill", group.color)
                                .attr("stroke", 'white')
                                .attr("stroke-width", 1)


                            svgLineGroupG
                                .selectAll('text')
                                .data(group.valueMilestones)
                                .enter()
                                .append('text')
                                .attr('class', 'material-icons')
                                .attr('x', (d) => (xAxis(d.x) - 12))
                                .attr('y', (d) => yAxis(d.y) - 6)
                                .attr("fill", group.color)
                                .text('location_pin')
                                .on('mouseenter', function (e, d) {
                                    // Candle Tooltip requires it's own ToolTip
                                    const _x = parseFloat(this.getAttribute('x'));
                                    const _y = parseFloat(this.getAttribute('y')) - 20;
                                    if (tooltipRef?.current) {
                                        tooltipRef.current.classList.add('_display')
                                        tooltipRef.current.innerHTML = `<p>${d.text}</p>`
                                        tooltipRef.current.style.transform = `translate(${_x}px, ${_y}px)`;
                                    }
                                })
                                .on('mouseleave', function () {
                                    if (tooltipRef?.current) {
                                        tooltipRef.current.classList.remove('_display')
                                    }
                                })
                        }

                        ++groupIndex;
                    }
                }

            } catch (e) {
                console.error(`<Donut Chart> error`, e);
            }
        }
    }, [barGroups, lineGroups, candlestickGroups, spreadGroups, height, width, margin, containerRef, tooltipRef])

    return (
        <div className='_barLineChartContainer' >
            <div className={`_tooltip`} ref={tooltipRef}><p></p></div>
            <div className='_chartOverflowContain' ref={containerRef}>

            </div>
        </div>
    )
}

export default BarLineAndSpreadChart;