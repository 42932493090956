import { GraphQLError } from "graphql";

interface SATerror {
    CodigoError: string; //"CFDI33132"
    ExtraInfo: string; //"" (Might have correct ZIP, Receptor Name or Else)
    FechaRegistro: string; //"2021-11-12T11:29:22"
    IdIncidencia: string; //"19de5732-cb92-44b8-966f-e8d61be516f4"
    MensajeIncidencia: string; // "Este RFC del receptor no existe en la lista de RFC inscritos no cancelados del SAT"
    NoCertificadoPac: string; //"00001000000504204441"
    RfcEmisor: string; //""
    Uuid: string; // ""
    WorkProcessId: string; //"7234b893-9c8b-4bb1-b856-118df5eb02b6"
}

const _SatMoreInfo = (satError: string, ExtraInfo: string, CodigoError: string) => {
    console.log(`SAT MORE INFO: `, ExtraInfo, satError)
    if (!ExtraInfo) {
        // No extra info
        if (CodigoError === 'CFDI40138') {
            // Emisor Name Error
            return `El nombre del emisor debe ser igual al registrado en el CSD`
        }
        if (CodigoError === 'CFDI40147') {
            // Receptor Zip Error
            return `Código Postal del Receptor no es el registrado en el SAT`
        }
        if (CodigoError === 'CFDI40144') {
            // Receptor Name Error
            return `Nombre del Receptor no es el registrado en el SAT`
        }
        return satError
    }
    if (CodigoError === 'CFDI40138') {
        // Emisor Name Error
        return `El nombre del emisor debe ser igual al registrado en el CSD \nPosible nombre de emisor correcto: ${ExtraInfo}`
    }
    if (CodigoError === 'CFDI40147') {
        // Receptor Zip Error
        return `Código Postal del Receptor no es el registrado en el SAT \nCódigo postal correcto: ${ExtraInfo}`
    }
    if (CodigoError === 'CFDI40144') {
        // Receptor Name Error
        return `Nombre del Receptor no es el registrado en el SAT \nNombre correcto: ${ExtraInfo.replace('&Ntilde;', 'Ñ')}`
    }
    return satError
}

export const parseStampErrorFromGraphQL = (error: GraphQLError) => {
    if (!error.extensions) {
        return error.message;
    }
    if (error.extensions && error.extensions.exception) {
        // ERROR came from FiscalPOP or SAT
        if (!!error.extensions.exception.error && error.extensions.exception.error.badRequests) {
            return `${error.extensions.exception.error.code}: ${error.extensions.exception.error.message}`;
        } else if (!!error.extensions.exception.error && error.extensions.exception.error.err && error.extensions.exception.error.err.length) {
            // SAT ERR
            const satErrors: SATerror[] = error.extensions.exception.error.err;
            const satError = satErrors[0]?.MensajeIncidencia
            const moreInfo = satErrors[0]?.ExtraInfo
            if (satError) {
                // Correctly Parsed, some errors might have useful Extra Info
                return `${_SatMoreInfo(satError, moreInfo, satErrors[0].CodigoError)}`;
            }
            console.error('SAT ERROR Failed parsing: ', error.extensions.exception.error.err);
            return 'Error del SAT desconocido, comuníquelo a soporte'
        }
        else {
            // THIS IS SAT ERROR
            console.error('SAT ERROR: ', error.extensions);
            return 'Error del SAT'
        }
    }
}