import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../base.model';
import { ActivitiesNotification } from '../../models/Activities';
import { graphqlSchema } from '../../services/graphql.schema';
import unifiedMoment from '../../services/unifiedMoment';

import './notificationList.scss';
import { labelFormat } from './textFormatting';

interface NotificationListProps {
    resourceTypes: ActivitiesNotification['resourceType'][];
    history: BaseReact['history']
}

function NotificationList({ resourceTypes, history }: NotificationListProps) {

    const [notifications, setNotifications] = useState<ActivitiesNotification[]>([]);

    const [fetchNotifications] = useLazyQuery(graphqlSchema.PROFILE.NOTIFICATIONS.getNotifications, {
        onCompleted: ({ getNotifications }: { getNotifications: ActivitiesNotification[] }) => {
            console.log(`getNotifications: `, getNotifications);
            setNotifications(getNotifications);
        },
        onError: (e) => {
            console.error('Error fetching getNotifications: ', e.graphQLErrors[0]);
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        fetchNotifications({
            variables: {
                resourceTypes
            }
        })
    }, [resourceTypes, fetchNotifications])


    const goToNotification = (urlEndpoint?: string) => () => {
        if (!urlEndpoint) {
            return;
        }
        urlEndpoint = urlEndpoint.replace('https://personal.fiscalpop.com', '')
        // Note: original endpointUrl arrives with host for usage cases such as Push Notifications
        console.log(`Going to: `, urlEndpoint);
        const [pathname, search] = urlEndpoint.split('?')
        history.push({
            pathname: pathname,
            search: search
        })
    }

    return (
        <div id="notificationList" className='card noShadow'>
            {
                notifications.map((notification) => (
                    <div
                        className={`_notification ${notification.level} ${notification.read ? 'read' : ''}`}
                        key={notification._id}
                    >
                        {
                            (notification.level === 'warning' || notification.level === 'highlight') ?
                                <svg onClick={goToNotification(notification.endpointUrl)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 5.5V10.9" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 14.5H10.009" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                :
                                (notification.level === 'document' ?
                                    <svg onClick={goToNotification(notification.endpointUrl)} xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
                                        <path d="M9.5 1H2.7C2.24913 1 1.81673 1.17911 1.49792 1.49792C1.17911 1.81673 1 2.24913 1 2.7V16.3C1 16.7509 1.17911 17.1833 1.49792 17.5021C1.81673 17.8209 2.24913 18 2.7 18H12.9C13.3509 18 13.7833 17.8209 14.1021 17.5021C14.4209 17.1833 14.6 16.7509 14.6 16.3V6.1L9.5 1Z" stroke="#9C9CD2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.5 1V6.1H14.6" stroke="#9C9CD2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.1999 10.35H4.3999" stroke="#9C9CD2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.1999 13.75H4.3999" stroke="#9C9CD2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.0999 6.95001H5.2499H4.3999" stroke="#9C9CD2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    :
                                    (notification.label === 'info' ?
                                        <svg onClick={goToNotification(notification.endpointUrl)} xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                            <path d="M1 1H6.23171C7.15673 1 8.04387 1.36746 8.69796 2.02155C9.35205 2.67564 9.71951 3.56278 9.71951 4.4878V16.6951C9.71951 16.0014 9.44391 15.336 8.95335 14.8454C8.46278 14.3549 7.79743 14.0793 7.10366 14.0793H1V1Z" stroke="#9C9CD2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M18.439 1H13.2073C12.2823 1 11.3951 1.36746 10.741 2.02155C10.0869 2.67564 9.71948 3.56278 9.71948 4.4878V16.6951C9.71948 16.0014 9.99508 15.336 10.4856 14.8454C10.9762 14.3549 11.6416 14.0793 12.3353 14.0793H18.439V1Z" stroke="#9C9CD2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        :
                                        <svg onClick={goToNotification(notification.endpointUrl)} xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                                            <path d="M1.6673 11.285L18.9284 14.9812L19.5718 12.0922" stroke="#9C9CD2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M1 11.5522V1.26715H20.1008V11.5522H1Z" stroke="#9C9CD2" strokeWidth="2" strokeLinejoin="round" />
                                            <circle cx="10.3503" cy="6.34287" r="3.20572" fill="#9C9CD2" />
                                            <circle cx="15.6926" cy="6.34287" r="1.06857" fill="#9C9CD2" />
                                            <circle cx="5.00705" cy="6.34287" r="1.06857" fill="#9C9CD2" />
                                            <circle cx="2.06857" cy="10.6172" r="1.06857" fill="#9C9CD2" />
                                            <circle cx="2.06857" cy="2.06857" r="1.06857" fill="#9C9CD2" />
                                            <circle cx="19.1653" cy="2.06857" r="1.06857" fill="#9C9CD2" />
                                            <circle cx="19.1653" cy="10.6172" r="1.06857" fill="#9C9CD2" />
                                            <circle cx="18.0969" cy="13.8229" r="1.06857" fill="#9C9CD2" />
                                        </svg>
                                    )
                                )
                        }
                        <p onClick={goToNotification(notification.endpointUrl)} className="_right">
                            {unifiedMoment(notification.createdAt).format('MMMM DD, YYYY')}
                        </p>
                        <p onClick={goToNotification(notification.endpointUrl)} className="_description">
                            {labelFormat(notification.label)}
                        </p>
                    </div>
                ))
            }
        </div>
    )
}

export default NotificationList;