import React, { useEffect, useState } from 'react';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import { Collapsable } from '../../../components/collapsable.container';
import SwitchCheckbox from '../../../Forms/SwtichCheckbox';
import BriefcaseSvg from '../../../visuals/svg/briefcaseIcon';
import MoneySvg from '../../../visuals/svg/moneyIcon';

interface ProjectionOverviewMenuProps {
    summaries: BankMovementSummary[]
    projectOptions: { label: string, _id: string, type: 'expense' | 'business' }[]
    onMenuToggleChange: (toggleDict: ToggleMenuDict) => void
}

export interface ToggleMenuDict {
    expense: { [key: string]: boolean }
    income: { [key: string]: boolean }
    expenseByProject: { [key: string]: boolean }
    incomeByProject: { [key: string]: boolean }
}

interface NestedDictionary { [key: string]: { [key: string]: string } }

function ProjectionOverviewMenu({ summaries, projectOptions, onMenuToggleChange }: ProjectionOverviewMenuProps) {

    const [menuCollapsedInit, setMenuCollapsedInit] = useState(window.innerWidth < 736)

    const [expenseNormalOptions, setExpenseNormalOptions] = useState<{ [key: string]: { [key: string]: string } }>({});
    const [expenseNormalDict, setExpenseNormalDict] = useState<{ [key: string]: boolean }>({})

    const [incomeNormalOptions, setIncomeNormalOptions] = useState<{ [key: string]: string }>({});
    const [incomeNormalDict, setIncomeNormalDict] = useState<{ [key: string]: boolean }>({})

    const [expenseProjectOptions, setExpenseProjectOptions] = useState<{ [key: string]: string }>({});
    const [expenseProjectDict, setExpenseProjectDict] = useState<{ [key: string]: boolean }>({})

    const [businessProjectOptions, setBusinessProjectOptions] = useState<{ [key: string]: string }>({});
    const [businessProjectDict, setBusinessProjectDict] = useState<{ [key: string]: boolean }>({})
    // subCollapsable State Changes

    useEffect(() => {
        if (summaries && projectOptions) {
            // Intended to only display active subcategories, not just all of them
            const { expenses: expensesDict, incomes: incomesDict } = summaries.reduce((p, c) => {
                c.expenseByCategory.forEach(ebC => {
                    const subcat = ebC.budgetSubcategory.split('-')[1];
                    if (p['expenses'][ebC.budgetCategory]) {
                        if (!p['expenses'][ebC.budgetCategory][subcat]) {
                            p['expenses'][ebC.budgetCategory][subcat] = ebC.budgetSubcategory;
                        }
                    } else {
                        p['expenses'][ebC.budgetCategory] = {}
                        p['expenses'][ebC.budgetCategory][subcat] = ebC.budgetSubcategory;
                    }
                })
                c.incomeByCategory.forEach(ebC => {
                    const subcat = ebC.subcategory.split('-')[1];
                    if (p['incomes'][subcat]) {
                        if (!p['incomes'][subcat]) {
                            p['incomes'][subcat] = ebC.subcategory;
                        }
                    } else {
                        p['incomes'][subcat] = ebC.subcategory;
                    }
                })
                return p;
            }, { incomes: {}, expenses: {} } as { expenses: NestedDictionary, incomes: { [key: string]: string } })

            // Expenses ------
            setExpenseNormalOptions(expensesDict);
            const normalExpDict = Object.values(expensesDict).reduce((p, c) => {
                Object.values(c).forEach(subcat => p[subcat] = true)
                return p
            }, {} as { [key: string]: boolean })
            setExpenseNormalDict(normalExpDict)

            // Incomes -------
            console.log('incomeNormalOptions: ', incomesDict)
            setIncomeNormalOptions(incomesDict);
            const normalIncomeDict = Object.values(incomesDict).reduce((p, c) => {
                p[c] = true
                return p
            }, {} as { [key: string]: boolean })
            console.log('incomeNormalDict: ', normalIncomeDict)
            setIncomeNormalDict(normalIncomeDict)


            // Expense Projects -------
            const expenseProjectDict = projectOptions.filter(pO => pO.type === 'expense').reduce((p, c) => {
                p[c._id] = c.label;
                return p;
            }, {} as { [key: string]: string })
            const normalExpenseProjectDict = Object.keys(expenseProjectDict).reduce((p, c) => {
                p[c] = true
                return p
            }, {} as { [key: string]: boolean })

            setExpenseProjectOptions(expenseProjectDict)
            setExpenseProjectDict(normalExpenseProjectDict)
            
            // Business Projects ------
            const businessProjectDict = projectOptions.filter(pO => pO.type === 'business').reduce((p, c) => {
                p[c._id] = c.label;
                return p;
            }, {} as { [key: string]: string })
            const normalBusinessProjectDict = Object.keys(businessProjectDict).reduce((p, c) => {
                p[c] = true
                return p
            }, {} as { [key: string]: boolean })


            console.log('businessProjectDict: ', businessProjectDict)
            console.log('normalBusinessProjectDict: ', normalBusinessProjectDict)

            setBusinessProjectOptions(businessProjectDict)
            setBusinessProjectDict(normalBusinessProjectDict)
        }
    }, [summaries, projectOptions])

    const toggleExpenseProject = (dictKey: string) => (bool: boolean) => {
        const expenses = { ...expenseProjectDict }
        expenses[dictKey] = bool;
        setExpenseProjectDict(expenses)

        const toggleDict: ToggleMenuDict = {
            expense: expenseNormalDict,
            income: incomeNormalDict,
            incomeByProject: businessProjectDict,
            expenseByProject: expenses,
        }
        onMenuToggleChange(toggleDict);
    }
    
    const toggleBusinessProject = (dictKey: string) => (bool: boolean) => {
        const business = { ...businessProjectDict }
        business[dictKey] = bool;
        setBusinessProjectDict(business)

        const toggleDict: ToggleMenuDict = {
            expense: expenseNormalDict,
            income: incomeNormalDict,
            incomeByProject: business,
            expenseByProject: expenseProjectDict,
        }
        onMenuToggleChange(toggleDict);
    }

    const toggleIncomeNormal = (dictKey: string) => (bool: boolean) => {
        const incomes = { ...incomeNormalDict }
        incomes[dictKey] = bool;
        setIncomeNormalDict(incomes)

        const toggleDict: ToggleMenuDict = {
            expense: expenseNormalDict,
            income: incomes,
            incomeByProject: {},
            expenseByProject: expenseProjectDict,
        }
        onMenuToggleChange(toggleDict);
    }

    const toggleExpenseNormal = (dictKey: string) => (bool: boolean) => {
        const expenses = { ...expenseNormalDict }
        expenses[dictKey] = bool;
        setExpenseNormalDict(expenses)

        const toggleDict: ToggleMenuDict = {
            expense: expenses,
            income: incomeNormalDict,
            incomeByProject: {},
            expenseByProject: expenseProjectDict,
        }
        onMenuToggleChange(toggleDict);
    }

    return (
        <div className='card _sideSelector'>
            <Collapsable
                initCollapse={menuCollapsedInit}
                triggerComponent={
                    <div className='collTitle'>
                        <MoneySvg width={24} color={'#AC2E78'} />
                        <p> Proyecto de Gasto </p>
                    </div>
                }
            >
                <div className='toggleList'>
                {
                        Object.keys(expenseProjectOptions).map((opt, i) => {
                            const dictLabel = expenseProjectOptions[opt];
                            return (
                                <div className='entry' key={i}>
                                    <p>
                                        {dictLabel}
                                    </p>
                                    <SwitchCheckbox micro={true} checkedColor={'green'} value={expenseProjectDict[opt]} onChange={toggleExpenseProject(opt)} />
                                </div>

                            )
                        })
                    }
                </div>
            </Collapsable>
            <Collapsable
                initCollapse={menuCollapsedInit}
                triggerComponent={
                    <div className='collTitle'>
                        <BriefcaseSvg width={24} color={'#AC2E78'} />
                        <p> Proyecto de Negocio </p>
                    </div>
                }
            >
                <div className='toggleList'>
                    {
                        Object.keys(businessProjectOptions).map((opt, i) => {
                            const dictLabel = businessProjectOptions[opt];
                            return (
                                <div className='entry' key={i}>
                                    <p>
                                        {dictLabel}
                                    </p>
                                    <SwitchCheckbox micro={true} checkedColor={'green'} value={businessProjectDict[opt]} onChange={toggleBusinessProject(opt)} />
                                </div>

                            )
                        })
                    }
                </div>
            </Collapsable>
            <Collapsable
                initCollapse={menuCollapsedInit}
                triggerComponent={
                    <div className='collTitle'>
                        <span className="material-icons">
                            arrow_upward
                        </span>
                        <p> Ingresos Personales</p>
                    </div>
                }
            >
                <div className='toggleList'>
                    {
                        Object.keys(incomeNormalOptions).map((opt, i) => {
                            const dictKey = incomeNormalOptions[opt];
                            return (
                                <div className='entry' key={i}>
                                    <p>
                                        {opt}
                                    </p>
                                    <SwitchCheckbox micro={true} checkedColor={'green'} value={incomeNormalDict[dictKey]} onChange={toggleIncomeNormal(dictKey)} />
                                </div>

                            )
                        })
                    }
                </div>
            </Collapsable>
            <Collapsable
                initCollapse={menuCollapsedInit}
                _forceHeightChange={320}
                triggerComponent={
                    <div className='collTitle'>
                        <span className="material-icons">
                            arrow_downward
                        </span>
                        <p> Egresos Personales</p>
                    </div>
                }
            >
                <div className='toggleList'>
                    {
                        Object.keys(expenseNormalOptions).map((categoryKey) => {

                            return (
                                <Collapsable
                                    key={categoryKey}
                                    initCollapse={true}
                                    triggerComponent={
                                        <div className=''>
                                            <p> {categoryKey} </p>
                                        </div>
                                    }
                                >
                                    <div className='toggleList _sub'>

                                        {
                                            Object.keys(expenseNormalOptions[categoryKey]).map((subcat, i) => (
                                                <div className='entry' key={`${categoryKey}-${subcat}`}>
                                                    <p>
                                                        {subcat}
                                                    </p>
                                                    <SwitchCheckbox micro={true} checkedColor={'green'} value={expenseNormalDict[expenseNormalOptions[categoryKey][subcat]]} onChange={toggleExpenseNormal(expenseNormalOptions[categoryKey][subcat])} />
                                                </div>

                                            ))
                                        }
                                    </div>
                                </Collapsable>
                            )
                        })
                    }
                </div>
            </Collapsable>
        </div>
    )
}

export default ProjectionOverviewMenu;