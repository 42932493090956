
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { BankBudgets } from '../../../../models/Administracion/Bank.Budgets';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import { numberToCurrencyString } from '../../../../services/formatting';
import SelectCard from '../../../Forms/SelectCard';
import TreeMapChart from '../../../Graphs/Treemap/Treemap.chart';
import { CATEGORIES_LIST } from '../../helpers/categories.list';

import './Presupuestos.Overview.graph.scss'


interface PresupuestosOverviewGraphProps {
    budgets: BankBudgets[];
    banksSummary: BankMovementSummary[]
    monthChosen: number;
    onMonthChange: (value: number) => void
}

interface BudgetData { name: string, color: string, value: number, goal: number }

function PresupuestosOverviewGraph({
    budgets,
    banksSummary,
    monthChosen,
    onMonthChange,
}: PresupuestosOverviewGraphProps) {

    const [budgetData, setBudgetData] = useState<BudgetData[]>([])

    const [monthOptions] = useState<{ value: number, label: string }[]>([0, 1, 2, 3, 4].map((i) => ({
        value: moment().startOf('month').subtract(i, 'month').valueOf(),
        label: moment().startOf('month').subtract(i, 'month').format('MMMM'),
    })))

    useEffect(() => {
        const _budgetData = budgets.reduce((p, c) => {
            const { color, label } = CATEGORIES_LIST.find(cat => cat.value === c.category)
            const exists = p.find(bD => bD.name === label)
            if (exists) {
                exists.goal += c.goal;
                exists.value += c.current;
            } else {
                p.push({
                    name: label,
                    goal: c.goal,
                    value: c.current,
                    color,
                })
            }
            return p;
        }, [] as BudgetData[]).filter(d => d.value > 0);
        console.log(`<PresupuestosOverviewGraph> uE _budgetData`, _budgetData)
        setBudgetData(_budgetData)
    }, [budgets])

    return (
        <div className='presupuestosOverviewGraph card noShadow'>
            <div className='titleFilter'>
                <h4>Distribución de gastos por concepto</h4>
                <SelectCard
                    label='Seleccionar mes'
                    options={monthOptions}
                    onChange={onMonthChange}
                    value={monthChosen}
                />
            </div>
            {
                budgetData.length ?

                    <div className='graphColumns'>
                        <div className='treeGraph'>
                            <TreeMapChart
                                name='Categorías'
                                margin={{ left: 2, top: 2, right: 2, bottom: 2 }}
                                data={budgetData}
                            />
                        </div>
                        <div className='conceptList'>
                            {
                                budgetData.map((budget, i) => {

                                    const _percent = budget.value / budget.goal;
                                    const overflows = _percent > 1;
                                    const percent = _percent > 1 ? 100 : (Math.round(_percent * 10000) / 100)
                                    return (
                                        <div className='concept' key={budget.name}>
                                            <div className='_name'>
                                                <p>
                                                    {budget.name}
                                                </p>
                                            </div>
                                            <div className='_value'>
                                                <p>
                                                    ${numberToCurrencyString(budget.value)} de ${numberToCurrencyString(budget.goal)}
                                                </p>
                                            </div>
                                            <div className='valueHolder'>
                                                <div className='valueFill' style={{ width: `${percent}%`, background: `${budget.color}` }} />
                                            </div>
                                            {
                                                overflows ?
                                                    <span className="material-icons">
                                                        warning
                                                    </span>

                                                    : null
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    :
                    <div className='graphColumns'>
                        <div className='_empty'>
                            <div className='_text'>
                                <p className='gray'>
                                    No hay movimientos que revisar en presupuesto este mes
                                </p>
                                {
                                    banksSummary.length ?
                                        <p className='gray small'>
                                            Categoriza tus <NavLink to={`/administration/movimientos`}>movimientos aquí</NavLink> para monitorear tus presupuestos.
                                        </p>
                                        :
                                        <p className='gray small'>
                                            Sincroniza tus <NavLink to={`/administration/bancos`}>cuentas aquí</NavLink> para cargar movimientos mas recientes.
                                        </p>
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default PresupuestosOverviewGraph;