import { PagoCfdiPreXml } from "../models/Factura";


export const getMontoFromPagoJson = (data: PagoCfdiPreXml) => {
    if(data["cfdi:Complemento"]["pago10:Pagos"]) {
        // Version 3.3
        return data['cfdi:Complemento']['pago10:Pagos'][0]['pago10:Pago']['@'].Monto
    }
    if(data["cfdi:Complemento"]["pago20:Pagos"]){
        return data["cfdi:Complemento"]["pago20:Pagos"]["pago20:Totales"]["@"].MontoTotalPagos
    }

    // Something's wrong
    console.error(`<getMontoFromPagoJson> Error obtaining Monto from Pago: `, data);
    return '0';
}