// PaymentStatus.jsx

import { useState, useEffect } from 'react';
import sharedToasterSubject from '../../../services/shared.toasterSubject';
import { useLazyQuery } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../services/graphql.schema';
import { StripeSetupIntent } from '../../../models/Stripe';

const RedirectPaymentStatus = () => {
    const [message, setMessage] = useState(null);

    const [_retrieveSetupIntent] = useLazyQuery(graphqlSchema.STRIPE.CARDS.retrieveSetupIntent, {
        onCompleted: ({ retrieveSetupIntent }: { retrieveSetupIntent: StripeSetupIntent }) => {
            switch (retrieveSetupIntent.status) {
                case 'succeeded':
                    setMessage('Success! Your payment method has been saved.');
                    sharedToasterSubject.next({ type: 'confirm', message: 'Se agregó tu tarjeta correctamente' })
                    break;

                case 'processing':
                    setMessage("Processing payment details. We'll update you when processing is complete.");
                    sharedToasterSubject.next({ type: 'warning', message: 'Se está procesando tu tarjeta' })
                    break;

                case 'requires_payment_method':
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    setMessage('Failed to process payment details. Please try another payment method.');
                    sharedToasterSubject.next({ type: 'warning', message: 'No se pudo procesar tu tarjeta, intenta otro método de pago' })
                    break;
            }
        },
        onError: (err) => {
            console.error('Error Retrieving Setup Intent: ', err)
        },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        // Retrieve the "setup_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            'setup_intent_client_secret'
        );

        // Retrieve the SetupIntent
        if (clientSecret) {
            console.log('RedirectPaymentStatus: ', clientSecret);
            _retrieveSetupIntent({
                variables: {
                    clientSecret
                }
            })
        }
    }, [_retrieveSetupIntent]);

    return message
};

export default RedirectPaymentStatus;