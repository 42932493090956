import React, { useState } from 'react';

import './clipboard.copy.scss';


function CopyToClipboard({ value, textLabel }: { value: string; textLabel: string }) {
    const inputRef = React.createRef<HTMLInputElement>();
    const [hasCopied, setHasCopied] = useState(false);
    const copyValue = () => {
        /* Get the text field */
        const copyText = inputRef.current;

        /* Select the text field */
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        console.log("Copied the text: " + copyText.value);
        setHasCopied(true);
        setTimeout(() => {
            setHasCopied(false);
        }, 1300);

    }

    return (
        <div className="__copyValueClipboard">
            <p onClick={copyValue}>{textLabel}</p>
            <input ref={inputRef} type="text" value={value} className="_COPYCLIPBOARD" readOnly={true} />
            <div className={`copied ${hasCopied ? 'show' : ''}`}>
                <p>Copiado a tu portapapeles!</p>
            </div>
        </div>
    )
}

export function CopyToClipboardChild({ value, children }: { value: string; children: JSX.Element }) {
    const inputRef = React.createRef<HTMLInputElement>();
    const [hasCopied, setHasCopied] = useState(false);
    const copyValue = () => {
        /* Get the text field */
        const copyText = inputRef.current;

        /* Select the text field */
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        console.log("Copied the text: " + copyText.value);
        setHasCopied(true);
        setTimeout(() => {
            setHasCopied(false);
        }, 1300);

    }
    return (
        <div className="__copyValueClipboard whithChild">
            <div className="_children" onClick={copyValue}>
                {children}
            </div>
            <input ref={inputRef} type="text" value={value} className="_COPYCLIPBOARD" readOnly={true} />
            <div className={`copied ${hasCopied ? 'show' : ''}`}>
                <p>Copiado a tu portapapeles!</p>
            </div>
        </div>
    )
}

export default CopyToClipboard;