import React, { useState } from 'react';

import Modal from 'react-modal';
import Button from '../../Forms/Button';

import './CartaPorte.info.general.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px 0px 6px',
        border: 'none',
        borderRadius: '12px',
        boxShadow: `0px 4px 16px rgba(0, 60, 128, 0.1)`,
    }
};

interface CartaPorteInfoGeneralDialogProps {
    isSelfReceptor: boolean;
}

export function CartaPorteInfoUbicacionDialog({ isSelfReceptor }: CartaPorteInfoGeneralDialogProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const close = () => {
        setIsOpen(false);
    }
    const open = () => {
        setIsOpen(true);
    }
    return (
        <div className='_CartaPorteInfoDialog'>
            <div className='textIconTrigger' onClick={open}>
                <i className='material-icons'>help_outline</i>
            </div>
            <Modal
                isOpen={isOpen}
                onRequestClose={close}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="card table" id="CartaPorteGeneralDialog">
                    <div className="cardTitle">
                        {
                            isSelfReceptor ?
                                <h4>¿Cómo configurar la ubicación?</h4>
                                :
                                <h4>¿Cómo configurar la ubicación?</h4>
                        }
                    </div>
                    <div className="cardBody">
                        {
                            isSelfReceptor ?
                                <div className='_textHolder'>
                                    <p className='gray'>
                                        <b>Cuando se traslada tu propia mercancía </b>.
                                    </p>
                                    <p className='gray'>
                                        El único campo a tomar en cuenta es el RFC, donde puede ser tu mismo RFC tanto en origen o destino, así como puedes usar el RFC de la empresa / lugar donde recoges o entregas la mercancía.
                                    </p>
                                </div>

                                :
                                <div className='_textHolder'>
                                    <p className='gray'>
                                        <b>En servicios de autotransporte</b>.
                                    </p>
                                    <p className='gray'>
                                        El único campo a tomar en cuenta es el RFC, donde puede ser tu mismo RFC tanto en origen o destino, así como puedes usar el RFC de la empresa / lugar donde recoges o entregas la mercancía.
                                    </p>
                                </div>
                        }
                        <div className='row'>
                            <div className='row center centerY'>
                                <Button primary={true} handleClick={close}>
                                    <span>
                                        Entendido
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}