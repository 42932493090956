import React from 'react';
import { BaseReact } from '../../../base.model';
import { ProfileQL } from '../../../models/Profile';
import NotificationList from '../../components/notificationList';
import SectionTitleBar from '../../components/titlebar';

import './AdministrationHub.scss'
import AdministrationHubProyections from './HubProyections/AdministrationHub.Proyections';
import AdministrationHubBudgets from './HubProyections/AdministrationHub.Proyections.Budgets';

interface PagosProps extends BaseReact {
    currentUser: ProfileQL;
}

function AdministrationHub({ currentUser, location, history }: PagosProps) {


    return (
        <div id="AdministrationHub">
            <SectionTitleBar currentUser={currentUser} title={`Resumen Finanzas`} />
            <div className="administrationHubContent">
                <div>
                    <h4>Centro de Notificaciones</h4>
                    <NotificationList resourceTypes={['finanzas']} history={history} />
                </div>

                <AdministrationHubProyections />
                <AdministrationHubBudgets />
            </div>
        </div>
    )
}

export default AdministrationHub;