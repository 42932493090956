import { Cotizacion } from "../models/Cotizaciones";


export const validateCotizacionIsComplete = (cfdi: Cotizacion, validConceptos: boolean) => {
    if (!cfdi) {
        return false;
    }

    else if (!cfdi.conceptos.every(c => c.claveProdServ)) { return false; }
    else if (!cfdi.conceptos.every(c => c.claveUnidad)) { return false; }
    else if (!cfdi.conceptos.every(c => c.cantidad)) { return false; }
    else if (!cfdi.conceptos.every(c => c.valorUnitario)) { return false; }
    else if (!cfdi.conceptos.every(c => c.descripcion)) { return false; }
    else if (!cfdi.conceptos.every(c => !!c.impuestos.length)) { return false; }
    // Check claves for conceptos
    else if (!validConceptos) { return false; }

    return true;
}
