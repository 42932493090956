import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

import './Input.scss';

interface InputProps {
    onChange?: Function;
    onBlur?: Function;
    placeholder?: string;
    label?: string;
    value: string | number;
    step?: number;
    type: 'number' | 'text' | 'file' | 'date' | 'password' | 'month';
    accept?: string;
    disabled?: boolean;
    hasError?: boolean;
    errorLabel?: string;
    captionLabel?: string;
    min?: string | number;
    max?: string | number;
    isCurrency?: boolean;
    isPercent?: boolean;
    isDecimalAsPercent?: boolean;
    filenameTrim?: number;
    minDate?: Date;
    maxDate?: Date;
    dateFormat?: string;
    datepickerFormat?: 'standard' | 'months';
}

const Input = React.forwardRef((props: InputProps, ref: React.Ref<HTMLButtonElement>) => {
    const selectRef = React.createRef<HTMLInputElement>();

    const [active, setActive] = useState(false);
    const [fileName, setFileName] = useState('');

    const activate = () => {
        setActive(true);
    }

    const deactivate = () => {
        setActive(false);
        if (!!props.onBlur) {
            props.onBlur(props.value);
        }
    }

    const selectFile = () => {
        selectRef.current.click();
    }

    const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.type === 'number') {
            if (props.onChange) {
                if (!event.target.value) {
                    // Avoid parseFloat('') to return NaN
                    event.target.value = '0';
                }
                if (!!props.isDecimalAsPercent) {
                    // Value is masked as 0.35 decimal to 35% percent, floating point error compensation
                    let value = parseFloat((parseFloat(event.target.value) / 100).toFixed(5));
                    props.onChange(value);
                } else {
                    props.onChange(parseFloat(event.target.value));
                }
            }
        } else if (props.type === 'text' || props.type === 'date' || props.type === 'password' || props.type === 'month') {
            if (props.onChange) {
                props.onChange(event.target.value);
            }
        } else {
            if (!!event.target && !!event.target.files && !!event.target.files[0] && !!event.target.files[0].name) {
                setFileName(event.target.files[0].name);
                if (props.onChange) {
                    props.onChange(event.target.files[0]);
                }
            } else {
                setFileName('');
            }
        }
    }

    const changeDateValue = (date: Date) => {
        if (date) {
            // Guard agains null input when everything is deleted
            const _date = moment(date).startOf('day').toDate()
            console.log('Change Date Value:  ', _date, moment(_date).format('YYYY-MM-DD'));
            props.onChange(_date);
            // props.onChange(moment(date).format('YYYY-MM-DD'));
        }
    }

    const classInput = `inputWrapper ${props.disabled ? 'disabled' : ''} ${props.type === 'date' ? 'date' : ''} ${active ? 'active' : ''} ${props.hasError ? 'error' : ''}`;
    return (
        <div className={`inputBlock ${props.type === 'date' ? 'dateType' : ''} ${active ? 'active' : ''} ${props.disabled ? 'disabled' : ''} ${(props.value || props.value === 0) || (props.type === 'file') ? 'dirty' : ''} ${props.hasError ? 'error' : ''}`}>
            {
                !!props.label ?
                    <label>
                        {props.label}
                    </label>
                    : <label></label>
            }
            <div className={`${classInput} ${props.type === 'file' ? 'fileWrapper' : ''} ${props.isCurrency && !props.isPercent ? 'currency' : ''} ${props.isPercent && !props.isCurrency ? 'percent' : ''}`} >
                {
                    props.type === 'file' ?
                        <div className="fileInput">
                            <input type={props.type}
                                readOnly={!props.onChange}
                                ref={selectRef}
                                accept={props.accept}
                                onFocus={activate} onBlur={deactivate}
                                placeholder={props.placeholder || ''} onChange={changeValue} disabled={!!props.disabled} />

                            <p>{(fileName || 'Nombre de archivo...').substring(0, props.filenameTrim || 100)}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" onClick={selectFile}>
                                <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />
                            </svg>
                        </div>
                        :
                        (
                            props.step && props.type === 'number' ?
                                <input type={props.type}
                                    readOnly={!props.onChange}
                                    ref={selectRef}
                                    value={!!props.isDecimalAsPercent ? Math.round((props.value as number) * 10000000) / 100000 : props.value}
                                    step={!!props.isDecimalAsPercent ? (props.step as number) * 100 : props.step}
                                    min={props.min || props.min === 0 ? (!!props.isDecimalAsPercent ? (props.min as number) * 100 : props.min) : null}
                                    max={(!!props.isDecimalAsPercent ? (props.max as number) * 100 : props.max) || null}
                                    onFocus={activate} onBlur={deactivate}
                                    placeholder={props.placeholder || ''} onChange={changeValue}
                                    disabled={!!props.disabled} />
                                :
                                (props.type === 'date' ?
                                    <DatePicker
                                        // >> We use moment(<String>) instead of new Date(<String>) because moment() is timezone aware, whereas new Date() will consider string as UTC
                                        selected={typeof props.value === 'string' ?
                                            moment(props.value).toDate() :
                                            new Date(props.value)
                                        }
                                        onChange={changeDateValue}
                                        className="input-date"
                                        dateFormat={props.dateFormat || "dd MMMM yyyy"}
                                        locale="es"
                                        disabled={props.disabled || null}
                                        minDate={props.minDate || null}
                                        maxDate={props.maxDate || null}
                                        showMonthYearPicker={props.datepickerFormat === 'months'}
                                        showTwoColumnMonthYearPicker={props.datepickerFormat === 'months'}
                                    />
                                    :
                                    <input type={props.type}
                                        readOnly={!props.onChange}
                                        ref={selectRef}
                                        value={props.value}
                                        min={props.min || props.min === 0 ? props.min : null}
                                        max={props.max || null}
                                        step={(props.isCurrency && !props.step ? 'any' : null)}
                                        onFocus={activate} onBlur={deactivate}
                                        placeholder={props.placeholder || ''} onChange={changeValue}
                                        disabled={!!props.disabled} />)
                        )

                }
            </div>
            <div className="errorText">
                {
                    props.hasError ?
                        <span className="errorText">{props.errorLabel}</span>
                        : ''
                }
                {
                    props.captionLabel && !props.hasError ?
                        <span className="captionText">{props.captionLabel}</span>
                        : ''
                }
            </div>
        </div>
    )

})

export default Input;