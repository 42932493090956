
import React, { useState } from 'react';
import { CartaPorteComplemento_Ubicacion_Autotransporte } from '../../models/Factura.complementos';
import Button from '../Forms/Button';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import { CartaPorteInfoAutotransporteDialog } from './dialogs/CartaPorte.info.autotransporte';

interface CartaPorteAutotransporteProps {
    autotransporte: CartaPorteComplemento_Ubicacion_Autotransporte;
    onAutotransporteDispatch: (autotransporte: CartaPorteComplemento_Ubicacion_Autotransporte) => void;
}

export const TIPO_VEHICULO_OPTIONS = [
    { value: 'VL', display: `Vehículo Ligero`, label: 'Vehículo Ligero (4 llantas)' },
    { value: 'C2', display: `Camión 2 Ejes`, label: 'Camión 2 Ejes (6 llantas)' },
    { value: 'C2R2', display: `Camión 2 Ejes y Remolque 2`, label: 'Camión 2 Ejes con Remolque 2 Ejes (6 llantas en camión y 8 en remolque)' },
    { value: 'C2R3', display: `Camión 2 Ejes y Remolque 3`, label: 'Camión 2 Ejes con remolque 3 Ejes (6 llantas en camión y 12 en remolque)' },
    { value: 'C3', display: `Camión 3 Ejes`, label: 'Camión 3 Ejes' },
    { value: 'C3R2', display: `Camión 3 Ejes y Remolque 2`, label: 'Camión 3 Ejes con remolque 2 Ejes (10 llantas en camión y 8 en remolque)' },
    { value: 'C3R3', display: `Camión 3 Ejes y Remolque 3`, label: 'Camión 3 Ejes con remolque 3 Ejes (10 llantas en camión y 12 en remolque)' },
    { value: '', label: 'Tractocamión con Semiremolque', disabled: true },
    { value: 'T2S1', display: `Tractocamión 2 Ejes y Semi 1`, label: 'Tractocamión 2 Ejes con semiremolque 1 Ejes (6 llantas en camión y 4 en semiremolque)' },
    { value: 'T2S2', display: `Tractocamión 2 Ejes y Semi 2`, label: 'Tractocamión 2 Ejes con semiremolque 2 Ejes (6 llantas en camión y 8 en semiremolque)' },
    { value: 'T2S3', display: `Tractocamión 2 Ejes y Semi 3`, label: 'Tractocamión 2 Ejes con semiremolque 3 Ejes (6 llantas en camión y 12 en semiremolque)' },
    { value: 'T3S1', display: `Tractocamión 3 Ejes y Semi 1`, label: 'Tractocamión 3 Ejes con semiremolque 1 Ejes (10 llantas en camión y 4 en semiremolque)' },
    { value: 'T3S2', display: `Tractocamión 3 Ejes y Semi 2`, label: 'Tractocamión 3 Ejes con semiremolque 2 Ejes (10 llantas en camión y 8 en semiremolque)' },
    { value: 'T3S3', display: `Tractocamión 3 Ejes y Semi 3`, label: 'Tractocamión 3 Ejes con semiremolque 3 Ejes (10 llantas en camión y 12 en semiremolque)' },
    { value: '', label: 'Tractocamión con Semiremolque y Remolque', disabled: true },
    { value: 'T2S1R2', display: `Tractocamión 2 Ejes, Semi 1 y Remolque 2`, label: 'Tractocamión 2 Ejes con semiremolque 1 Ejes y remolque 2 ejes (6 llantas en camión, 4 en semiremolque y 8 en segundo remolque)' },
    { value: 'T2S1R3', display: `Tractocamión 2 Ejes, Semi 1 y Remolque 3`, label: 'Tractocamión 2 Ejes con semiremolque 1 Ejes y remolque 3 ejes (6 llantas en camión, 4 en semiremolque y 12 en segundo remolque)' },
    { value: 'T2S2R2', display: `Tractocamión 2 Ejes, Semi 2 y Remolque 2`, label: 'Tractocamión 2 Ejes con semiremolque 2 Ejes y remolque 2 ejes (6 llantas en camión, 8 en semiremolque y 8 en segundo remolque)' },
    { value: 'T3S1R2', display: `Tractocamión 3 Ejes, Semi 1 y Remolque 2`, label: 'Tractocamión 3 Ejes con semiremolque 1 Ejes y remolque 2 ejes (10 llantas en camión, 4 en semiremolque y 8 en segundo remolque)' },
    { value: 'T3S1R3', display: `Tractocamión 3 Ejes, Semi 1 y Remolque 3`, label: 'Tractocamión 3 Ejes con semiremolque 1 Ejes y remolque 3 ejes (10 llantas en camión, 4 en semiremolque y 12 en segundo remolque)' },
    { value: 'T3S2R2', display: `Tractocamión 3 Ejes, Semi 2 y Remolque 2`, label: 'Tractocamión 3 Ejes con semiremolque 2 Ejes y remolque 2 ejes (10 llantas en camión, 8 en semiremolque y 8 en segundo remolque)' },
    { value: 'T3S2R3', display: `Tractocamión 3 Ejes, Semi 2 y Remolque 3`, label: 'Tractocamión 3 Ejes con semiremolque 2 Ejes y remolque 3 ejes (10 llantas en camión, 8 en semiremolque y 12 en segundo remolque)' },
    { value: 'T3S2R4', display: `Tractocamión 3 Ejes, Semi 2 y Remolque 4`, label: 'Tractocamión 3 Ejes con semiremolque 2 Ejes y remolque 4 ejes (10 llantas en camión, 8 en semiremolque y 16 en segundo remolque)' },
    { value: '', label: 'Tractocamión con Doble Semiremolque', disabled: true },
    { value: 'T2S2S2', display: `Tractocamión 2 Ejes, Semi 2 y Semi 2`, label: 'Tractocamión 2 Ejes con semiremolque 2 Ejes y semiremolque 2 ejes (6 llantas en camión, 8 en semiremolque y 8 en segundo remolque)' },
    { value: 'T3S2S2', display: `Tractocamión 3 Ejes, Semi 2 y Semi 2`, label: 'Tractocamión 3 Ejes con semiremolque 2 Ejes y semiremolque 2 ejes (10 llantas en camión, 8 en semiremolque y 8 en segundo remolque)' },
    { value: 'T3S3S2', display: `Tractocamión 3 Ejes, Semi 3 y Semi 2`, label: 'Tractocamión 3 Ejes con semiremolque 3 Ejes y semiremolque 2 ejes (10 llantas en camión, 12 en semiremolque y 12 en segundo remolque)' },
    { value: '', label: 'Carga esecializada', disabled: true },
    { value: 'OTROEVGP', label: 'Especializado de carga Voluminosa y/o Gran Peso' },
    { value: 'OTROSG', label: 'Servicio de Grúa' },
    { value: 'GPLUTA', label: 'Grúa de Pluma Tipo A' },
    { value: 'GPLUTB', label: 'Grúa de Pluma Tipo B' },
    { value: 'GPLUTC', label: 'Grúa de Pluma Tipo C' },
    { value: 'GPLUTD', label: 'Grúa de Pluma Tipo D' },
    { value: 'GPLATA', label: 'Grúa de Plataforma Tipo A' },
    { value: 'GPLATB', label: 'Grúa de Plataforma Tipo B' },
    { value: 'GPLATC', label: 'Grúa de Plataforma Tipo C' },
    { value: 'GPLATD', label: 'Grúa de Plataforma Tipo D' },
].map(t => ({ value: t.value, label: t.disabled ? t.label : `(${t.value}) ${t.label}`, display: t.display || null, disabled: t.disabled || false }))

export const TIPO_REMOLQUE = [
    { value: 'CTR003', label: 'Caja Abierta' },
    { value: 'CTR004', label: 'Caja Cerrada' },
    { value: 'CTR005', label: 'Caja De Recolección Con Cargador Frontal' },
]

export const TIPO_PERMISO_SCT = [
    { value: 'TPXX00', label: 'Permiso no contemplado en el catálogo' },
    { value: 'TPAF01', label: 'Autotransporte Federal de carga general' },
    { value: 'TPAF02', label: 'Transporte privado de carga' },
    { value: 'TPAF03', label: 'Autotransporte Federal de Carga de materiales peligrosos' },
]

export function CartaPorteAutotransporte({
    autotransporte,
    onAutotransporteDispatch
}: CartaPorteAutotransporteProps) {

    const [remolqueToAdd, setRemolqueToAdd] = useState<CartaPorteComplemento_Ubicacion_Autotransporte['remolques'][0]>(null)

    const handleAutotransporteChange = (property: keyof CartaPorteComplemento_Ubicacion_Autotransporte) => (value: any) => {
        const _autotransporte = { ...autotransporte };
        if (property === 'permisoSCT') {
            if (value === 'TPXX00') {
                _autotransporte['numPermisoSCT'] = 'Permiso no contemplado en el catálogo';
            } else if (_autotransporte['numPermisoSCT'] === 'Permiso no contemplado en el catálogo') {
                // If permiso changed, then reset to an empty value
                _autotransporte['numPermisoSCT'] = '';
            }
        }
        _autotransporte[property] = value as any;

        onAutotransporteDispatch(_autotransporte)
    }

    const handleVehiculoChange = (property: keyof CartaPorteComplemento_Ubicacion_Autotransporte['vehiculo']) => (value: any) => {
        const _vehiculo = { ...autotransporte.vehiculo };
        if (property === 'anoVehiculo') {
            _vehiculo[property] = value as number;
        }
        else if (property === 'placas') {
            // Remove dashes or other non alphanumerical entries
            _vehiculo[property] = (value as string).split('').filter(s => /\w/g.test(s)).join('');
        }
        else if (property === 'tipoVehiculo') {
            _vehiculo[property] = value as any;
        }

        handleAutotransporteChange('vehiculo')(_vehiculo)
    }

    const handleSeguroChange = (property: keyof CartaPorteComplemento_Ubicacion_Autotransporte['seguro']) => (value: string) => {
        const _seguro = { ...autotransporte.seguro };
        _seguro[property] = value;

        handleAutotransporteChange('seguro')(_seguro)
    }

    const handleRemolqueOptionChange = (property: 'tipoRemolque' | 'placa') => (value: any) => {
        setRemolqueToAdd((s) => {
            const _s = Object.assign({}, s);
            if (property === 'placa') {
                value = (value as string).split('').filter(s => /\w/g.test(s)).join('')
            }
            _s[property] = value;
            return _s
        })
    }

    const handleRemolqueAdd = () => {
        if (remolqueToAdd.placa && remolqueToAdd.tipoRemolque) {
            const _remolques = JSON.parse(JSON.stringify(autotransporte.remolques))
            _remolques.push(remolqueToAdd)
            setRemolqueToAdd(null)
            handleAutotransporteChange('remolques')(_remolques)
        }
    }

    const handleRemolqueDelete = (index: number) => () => {
        const remolques = autotransporte.remolques;
        // dispatchBill({ property: 'conceptos', value: conceptos.filter((_, i) => i !== index) });
        handleAutotransporteChange('remolques')(remolques.filter((_, i) => i !== index))
    }

    const openNewRemolque = () => {
        setRemolqueToAdd({
            placa: '',
            tipoRemolque: 'CTR004'
        })
    }

    const canHaveRemolque = () => {
        const type = autotransporte.vehiculo.tipoVehiculo;
        if (type === 'VL') {
            return 1;
        }
        if (type === 'OTROEVGP') {
            return 1;
        }
        if (/^[CT]\d[SR]\d$/.test(type)) {
            return 1;
        }
        if (/^[CT]\d[SR]\d[SR]\d$/.test(type)) {
            return 2;
        }
        return 0
    }

    const remolqueDisplay = (typoRemolque: string) => {
        if (!typoRemolque) {
            return ''
        }
        const found = TIPO_REMOLQUE.find(t => t.value === typoRemolque);
        return found.label
    }

    const remolquesAllowed = canHaveRemolque()

    return (
        <div className='autotransporte'>
            <h4>{'Autotransporte'}</h4>
            <div className={`card table`}>
                <CartaPorteInfoAutotransporteDialog />
                <div className="cardBody">
                    <div className='row'>
                        <h5>Vehículo</h5>
                        <div className='row four xlg-three sub-sm-two sm-gap-compressed xxs-one'>
                            <Input
                                label='Año del Vehículo'
                                type='number'
                                value={autotransporte.vehiculo.anoVehiculo}
                                step={1}
                                min={1950}
                                max={new Date().getFullYear() + 1}
                                onChange={handleVehiculoChange('anoVehiculo')}
                            />
                            <Select
                                label='Tipo de Vehículo'
                                value={autotransporte.vehiculo.tipoVehiculo}
                                options={TIPO_VEHICULO_OPTIONS}
                                onChange={handleVehiculoChange('tipoVehiculo')}
                            />
                            <Input
                                label='Placas de Vehículo'
                                type='text'
                                value={autotransporte.vehiculo.placas}
                                onChange={handleVehiculoChange('placas')}
                            />

                        </div>
                    </div>
                    <div className='row'>
                        <h5>Seguro</h5>
                        <div className='row four xlg-three sm-two sm-gap-compressed xxs-one'>
                            <Input
                                label='Aseguradora'
                                type='text'
                                value={autotransporte.seguro.aseguradora}
                                onChange={handleSeguroChange('aseguradora')}
                            />
                            <Input
                                label='Póliza'
                                type='text'
                                placeholder='La clave de la póliza'
                                value={autotransporte.seguro.poliza}
                                onChange={handleSeguroChange('poliza')}
                            />

                        </div>
                    </div>
                    <div className='row'>
                        <h5>Remolques</h5>
                        <div className='addRemolque'>
                            {
                                !remolqueToAdd ?
                                    <div className='row _action'>
                                        <div className="start">
                                            {
                                                remolquesAllowed <= autotransporte.remolques.length ?
                                                    <Button tertiary={true} disabled={true} >
                                                        <div className='row'>
                                                            {
                                                                remolquesAllowed === 0 ?
                                                                    <span>
                                                                        Vehículo sin remolque
                                                                    </span>
                                                                    :
                                                                    <span>
                                                                        Máximo {remolquesAllowed} remolques
                                                                    </span>
                                                            }
                                                        </div>
                                                    </Button>
                                                    :
                                                    <Button tertiary={true} handleClick={openNewRemolque} >
                                                        <div className='withIcon'>
                                                            <span className='material-icons'>
                                                                add
                                                            </span>
                                                            <span>
                                                                Agregar remolque
                                                            </span>
                                                        </div>
                                                    </Button>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className='row _form'>
                                        <div className='row _remolque'>
                                            <Select
                                                label={'Tipo de Remolque'}
                                                options={TIPO_REMOLQUE}
                                                onChange={handleRemolqueOptionChange('tipoRemolque')}
                                                value={remolqueToAdd.tipoRemolque}
                                            />
                                            <Input
                                                type='text'
                                                label={'Placas del Remolque'}
                                                onChange={handleRemolqueOptionChange('placa')}
                                                value={remolqueToAdd.placa}
                                            />
                                            <div className='row sub-sm-hidden' />
                                            <div className='end centerY'>
                                                <Button secondary={true} handleClick={handleRemolqueAdd} disabled={!(remolqueToAdd.placa && remolqueToAdd.tipoRemolque)}>
                                                    <span className='small'>
                                                        Agregar Remolque
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className='remolqueList'>
                            <div className='remolque header'>
                                <p>Tipo de remolque</p>
                                <p>Placa</p>
                            </div>
                            {
                                autotransporte.remolques.map((rem, i) => {
                                    return (
                                        <div className='remolque' key={i}>
                                            <p>
                                                {rem.tipoRemolque} <br />
                                                <span className='small gray'>{remolqueDisplay(rem.tipoRemolque)}</span>
                                            </p>
                                            <p>{rem.placa}</p>
                                            <div className='hideSm' />
                                            <Button tertiary={true} setClass="_delete" handleClick={handleRemolqueDelete(i)}>
                                                <span className='material-icons'>
                                                    delete
                                                </span>
                                            </Button>
                                        </div>
                                    )
                                })
                            }
                            {
                                !autotransporte.remolques.length ?
                                    <div className='_emtpy'>
                                        {
                                            remolquesAllowed === 0 ?
                                                <p>
                                                    Este vehículo no utiliza remolque
                                                </p>
                                                :
                                                <p>
                                                    Agrega el concepto por servicio de transporte de carga
                                                </p>
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}