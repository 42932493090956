import React, { useState } from 'react';

import './Account.paymentMethod.scss'
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import { StripeCard_SourceCard, StripeCard_RemoveCard } from '../../models/Stripe';
import VisaSvg from '../visuals/svg/visa.logo';
import MasterCardSvg from '../visuals/svg/mastercard.logo';
import AmexCardSvg from '../visuals/svg/amex.logo';
import ItemMenu from '../Forms/menu/MenuComponent';

interface AccountCardProps {
    onErrase: (selectedId: string) => void;
    onSetDefaultCard: (selectedId: string) => void;
    selectCard: (selectedId: string) => () => void;
    method: StripeCard_SourceCard;
    selectedCardId: string;
    menuOverlayAnchor?: string;
    noDeleteOption?: boolean
}

function AccountCard({ selectCard, method, selectedCardId, onErrase, onSetDefaultCard, menuOverlayAnchor, noDeleteOption }: AccountCardProps) {
    const cardRef = React.createRef<HTMLDivElement>();
    const [cardDisabled, setCardDisabled] = useState(false);

    const [requestCardRemoved] = useMutation(graphqlSchema.STRIPE.CARDS.removeCard, {
        onCompleted: ({ removeCard }: { removeCard: StripeCard_RemoveCard }) => {
            console.log('Card Removed: ', removeCard);
            // UseReducer to update object array
            setCardDisabled(false);
            onErrase(removeCard.id);
        },
        onError: (e) => {
            console.error('Error Removing card: ', e);
            setCardDisabled(false);
        }
    })
    const [setAsDefault] = useMutation(graphqlSchema.STRIPE.CARDS.updateDefaultPayment, {
        onCompleted: ({ updateDefaultPayment }: { updateDefaultPayment: string }) => {
            console.log('Card Default: ', updateDefaultPayment);
            // UseReducer to update object array
            setCardDisabled(false);
            onSetDefaultCard(updateDefaultPayment);
        },
        onError: (e) => {
            console.error('Error Defaulting card: ', e);
            setCardDisabled(false);
        }
    })
    const setRemoveCard = (cardId: string) => {
        return () => {
            if (cardDisabled) {
                return;
            }
            setCardDisabled(true);
            requestCardRemoved({
                variables: {
                    paymentId: cardId
                }
            })
        }
    }
    const setDefaultCard = (cardId: string) => {
        return () => {
            if (cardDisabled) {
                return;
            }
            setCardDisabled(true);
            setAsDefault({
                variables: {
                    paymentId: cardId
                }
            })
        }
    }

    return (
        <div ref={cardRef} className={`_cardContainer ${method.id === selectedCardId ? 'selected' : ''}`}>
            {
                method.default ?
                    <div className='_isDefault'>
                        <span className="material-icons">
                            done
                        </span>
                    </div>
                    : null
            }
            <div className="_cardEntry" onClick={selectCard(method.id)}>
                <div className='_brand'>
                    {
                        method.brand === 'visa' ?
                            <VisaSvg width={42} />
                            : null
                    }
                    {
                        method.brand.includes('master') ?
                            <MasterCardSvg width={42} />
                            : null
                    }
                    {
                        method.brand.includes('amex') ?
                            <AmexCardSvg width={42} />
                            : null
                    }
                </div>
                <div>
                    <p className="_brand">
                        {method.funding === 'credit' ? 'Crédito' : 'Débito'}
                    </p>
                    <p className="_last4">
                        **** **** **** {method.last4}
                    </p>
                    <p className="_exp end">
                        {method.exp_month} / {method.exp_year}
                    </p>
                </div>
                <div className='_settings'>
                    {
                        // If noDeleteOption is on and this is Default, there's nothing to do
                        !!noDeleteOption && method.default ?
                            <span className="material-icons _settings _dissabled">settings</span> :
                            <ItemMenu widthBehaviour={'auto'} openOrientation={'left'}
                                triggerContent={<span className="material-icons _settings">settings</span>}
                                noPadding={true} overlayRootElement={menuOverlayAnchor || 'root'}>

                                <div className='cardMenu'>
                                    {
                                        !!noDeleteOption ?
                                            null :
                                            <div className='_option _warning' onClick={setRemoveCard(method.id)}>
                                                <span className="material-icons">
                                                    delete
                                                </span>
                                                <p>Borrar tarjeta</p>
                                            </div>
                                    }
                                    {
                                        !method.default ?
                                            <div className='_option' onClick={setDefaultCard(method.id)}>
                                                <span className="material-icons">
                                                    done
                                                </span>
                                                <p>Usar predeterminada</p>
                                            </div>
                                            : null
                                    }
                                </div>
                            </ItemMenu>
                    }

                </div>
            </div>
        </div>
    )
}

export default AccountCard;