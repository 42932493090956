import React, { useState } from 'react';

import Modal from 'react-modal';
import Button from '../../Forms/Button';

import './CartaPorte.info.general.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px 0px 6px',
        border: 'none',
        borderRadius: '12px',
        boxShadow: `0px 4px 16px rgba(0, 60, 128, 0.1)`,
    }
};

export function CartaPorteInfoOperadoresDialog() {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const close = () => {
        setIsOpen(false);
    }
    const open = () => {
        setIsOpen(true);
    }
    return (
        <div className='_CartaPorteInfoDialog'>
            <div className='textIconTrigger' onClick={open}>
                <i className='material-icons'>help_outline</i>
            </div>
            <Modal
                isOpen={isOpen}
                onRequestClose={close}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="card table" id="CartaPorteGeneralDialog">
                    <div className="cardTitle">
                        <h4>¿Cómo configurar los operadores de transporte?</h4>
                    </div>
                    <div className="cardBody">
                        <div className='_textHolder'>
                            <p className='gray'>
                                Es necesario agregar los datos del o los Operadores (Chofer).
                            </p>
                            <p className='gray'>
                                Opcionalmente si el vehículo es arrendado, se debe agregar el arrendador o dueño de este.
                            </p>
                            <p className='gray'>
                                Solamente es necesario agregar los datos de la licencia de conducir del Operador (chofer).
                            </p>
                        </div>
                        <div className='row'>
                            <div className='row center centerY'>
                                <Button primary={true} handleClick={close}>
                                    <span>
                                        Entendido
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}