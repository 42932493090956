import React, { useState } from 'react';

import Modal from 'react-modal';
import Button from '../../Forms/Button';

import './CartaPorte.info.general.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        padding: '0px 0px 6px',
        border: 'none',
        borderRadius: '12px',
        boxShadow: `0px 4px 16px rgba(0, 60, 128, 0.1)`,
    }
};

export function CartaPorteInfoAutotransporteDialog() {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const close = () => {
        setIsOpen(false);
    }
    const open = () => {
        setIsOpen(true);
    }
    return (
        <div className='_CartaPorteInfoDialog'>
            <div className='textIconTrigger' onClick={open}>
                <i className='material-icons'>help_outline</i>
            </div>
            <Modal
                isOpen={isOpen}
                onRequestClose={close}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="card table" id="CartaPorteGeneralDialog">
                    <div className="cardTitle">
                        <h4>¿Cómo configurar el autotransporte?</h4>
                    </div>
                    <div className="cardBody">
                        <div className='_textHolder'>
                            <p className='gray'>
                                Es importante que las placas y el tipo de vehículo sean los correctos.
                            </p>
                            <p className='gray'>
                                Si mueves tus propios productos solamente determinar si el vehículo es Vehículo Ligero (Menos de 3 Toneladas y media de carga) o Camión de carga.
                            </p>
                            <p className='gray'>
                            El seguro debe ser el aquel del vehículo, de responsabilidad civil (danos a terceros) o superior.
                            </p>
                        </div>
                        <div className='row'>
                            <div className='row center centerY'>
                                <Button primary={true} handleClick={close}>
                                    <span>
                                        Entendido
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}