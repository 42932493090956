import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useState, useReducer, useEffect } from 'react';
import { BaseReact } from '../../base.model';
import { Receptor } from '../../models/Catalogos';
import { Bill, BillConceptos, CFDI, FiscalpopCFDI, ToBillArguments, ToBillEvent } from '../../models/Factura';
import { ProfileQL } from '../../models/Profile';
import { clearGqlTypename } from '../../utils/formatting';
import SectionTitleBar from '../components/titlebar';
import AutoComplete from '../Forms/Autocomplete';
import Input from '../Forms/Input';
import Select from '../Forms/Select';

import { graphqlSchema } from '../../services/graphql.schema';

import './CreditNote.scss'
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import moment from 'moment';
import FacturarConceptosDevolucion from '../Facturar/Factura.devolucion';
import { useParams } from 'react-router-dom';
import Button from '../Forms/Button';
import { formatCurrencyStyled } from '../visuals/currency.formatted';
import FacturaTotales from '../Facturar/Factura.totales';
import { calculateTotal } from '../../utils/calculate.conceptos';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { validateNotaCredIsComplete } from '../../services/validation.cfdi';
import Checkbox from '../Forms/Checkbox';
import Pagination from '../Forms/Pagination';
import CreditNotesClientDialog from './clientModal/creditNotes.clientEdit';
import { parseStampErrorFromGraphQL } from '../../utils/error.graphql';



interface CreditNoteProps extends BaseReact {
    currentUser: ProfileQL;
}


const EMPTY_CFDI = {
    formaPago: '99',
    metodoPago: 'PUE',
    serie: 'DEV',
    folio: '1',
    tipoDeComprobante: 'E',
    receptor: { nombre: '', rfc: '', usoCFDI: 'G02', email: '' },
    cfdiRelacionados: {
        tipoRelacion: '03',
        uuids: ['']
    },
    conceptos: [{
        claveProdServ: '84111506',
        claveUnidad: 'ACT',
        cantidad: 1,
        descripcion: 'Devolucion',
        valorUnitario: 0,
        impuestos: [{ type: 'iva', tipo: 'Fijo', retencion: false, tasa: 0.16 }]
    }],
} as Bill

function billReducer(state: Bill, { property, value }: { property: 'receptor' | 'conceptos' | 'cfdiRelacionados' | 'comprobante' | '_replace', value: any }): Bill {
    if (property === 'receptor') {
        return Object.assign({}, state, { receptor: value });
    } else if (property === 'conceptos') {
        return Object.assign({}, state, { conceptos: value });
    }
    else if (property === 'comprobante') {
        return Object.assign({}, state, value);
    }
    else if (property === 'cfdiRelacionados') {
        return Object.assign({}, state, { cfdiRelacionados: value });
    }
    else if (property === '_replace') {
        return Object.assign({}, state, value);
    }
    else {
        // Force update only
        return Object.assign({}, state);
    }
}

function billRelatedReducer(state: FiscalpopCFDI[], { action, value }: { action: 'select' | 'unselect', value: FiscalpopCFDI }): FiscalpopCFDI[] {
    if (action === 'select') {
        return state.filter((s) => s.uuid !== value.uuid).concat([value])
    } else if (action === 'unselect') {
        return state.filter((b) => b.uuid !== value.uuid)
    } else {
        return state;
    }
}

const TIPO_RELACION = [
    { value: '01', label: '(01) Nota de crédito' },
    { value: '03', label: '(03) Devolución de mercancía' },
    { value: '07', label: '(07) Aplicación del anticipo' },
]

function CreditNote({ currentUser, location, history }: CreditNoteProps) {
    const { eventid } = useParams<{ eventid: string }>();

    const [validRequested, setValidRequested] = useState(false);
    const [stamping, setStamping] = useState(false);

    const [billRelates, setBillRelates] = useState<FiscalpopCFDI[]>([]);
    const [billRelFilter, setBillRelateFilter] = useState('');
    const [billRelFilerPage, setBillRelatePage] = useState(1);
    const pageLimit = 10;

    const [receptorOptions, setReceptorOptions] = useState<Receptor[]>([]);
    const [receptoSubject] = useState(new Subject<string>());
    const [receptoSubjectByRfc] = useState(new Subject<string>());

    const [confirmedRelated, setConfirmRelated] = useState(false);

    const [receptorEditDialog, setReceptorEditDialog] = useState(false);

    const [cfdi, dispatchBill] = useReducer(billReducer, {
        formaPago: '99',
        metodoPago: 'PUE',
        serie: 'DEV',
        folio: '1',
        tipoDeComprobante: 'E',
        receptor: { nombre: '', rfc: '', usoCFDI: 'G02', email: '', regimen: '', zip: '' },
        cfdiRelacionados: {
            tipoRelacion: '03',
            uuids: ['']
        },
        conceptos: [{
            claveProdServ: '84111506',
            claveUnidad: 'ACT',
            cantidad: 1,
            descripcion: 'Devolucion',
            valorUnitario: 0,
            impuestos: [{ type: 'iva', tipo: 'Fijo', retencion: false, tasa: 0.16 }]
        }],
    } as Bill);

    const [cfdiRelatedOptions, dispatchCfdiRelated] = useReducer(billRelatedReducer, [])


    const [getByName] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByName, {
        onCompleted: ({ getClienteByName }: { getClienteByName: Receptor[] }) => {
            setReceptorOptions(getClienteByName.map(c => clearGqlTypename(c)));
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })
    const [getByRfc] = useLazyQuery(graphqlSchema.PROFILE.CATALOGOS.getClienteByRfc, {
        onCompleted: ({ getClienteByRfc }: { getClienteByRfc: Receptor[] }) => {
            setReceptorOptions(getClienteByRfc.map(c => clearGqlTypename(c)));
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })
    const [submitNota] = useMutation(graphqlSchema.FISCALPOP.CFDI.stampFromNotaCredito, {
        onCompleted: ({ stampFromNotaCredito }: { stampFromNotaCredito: ToBillArguments<ToBillEvent> }) => {
            sharedToasterSubject.next({ type: 'confirm', message: 'Nota de crédito emitida correctamente' });
            history.push({
                pathname: '/',
                state: { billArgument: stampFromNotaCredito }
            })
        },
        onError: (e) => {
            console.error('Error submitting nota');
            setStamping(false)
            sharedToasterSubject.next({ type: 'error', message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clearTs: 5200, clickToClose: true })
        }
    })
    const [submitNotaToEdit] = useMutation(graphqlSchema.FISCALPOP.CFDI.restampBillNotaCredito, {
        onCompleted: ({ restampBillNotaCredito }: { restampBillNotaCredito: ToBillArguments<ToBillEvent> }) => {
            sharedToasterSubject.next({ type: 'confirm', message: 'Nota de crédito emitida correctamente' });
            history.push({
                pathname: '/',
                state: { billArgument: restampBillNotaCredito }
            })
        },
        onError: (e) => {
            console.error('Error submitting nota');
            setStamping(false)
            sharedToasterSubject.next({ type: 'error', message: parseStampErrorFromGraphQL(e.graphQLErrors[0]), clearTs: 5200, clickToClose: true })
        }
    })

    const [getBillEventsByRfc] = useLazyQuery(graphqlSchema.FISCALPOP.CFDI.getBillsByRfc, {
        onCompleted: ({ getBillsByRfc }: { getBillsByRfc: CFDI[] }) => {
            // Only Active bills can be related
            const parsedBills = getBillsByRfc.filter(bill => bill.status).map(gBBR => {
                return Object.assign({}, gBBR, { json: JSON.parse(gBBR.json) }) as unknown as FiscalpopCFDI;
            })
            console.log('parsedBills: ', parsedBills);
            setBillRelates(parsedBills);
        },
        onError: (e) => {
            console.error('Error getting by name: ', e.graphQLErrors[0])
        }
    })

    useEffect(() => {
        // Edit nota de crédito
        if (eventid) {
            const { billEvents } = location.state as { billEvents: ToBillEvent[] };
            console.log('UE billEvents: ', billEvents);
            dispatchBill({ property: '_replace', value: billEvents[0].NotaCreditoCFDI });
        }
    }, [eventid, location])


    useEffect(() => {
        if (cfdiRelatedOptions.length) {
            const formasPago = cfdiRelatedOptions.map(({ json }) => json['@'].FormaPago);
            console.log('Dispaching Forma pago', formasPago)
            const _formaPago = formasPago[0] === '99' ? '03' : formasPago[0]
            dispatchBill({ property: 'comprobante', value: { formaPago: _formaPago } })
        }
    }, [cfdiRelatedOptions])

    useEffect(() => {
        const s = receptoSubject.pipe(debounceTime(200)).subscribe(s => {
            getByName({
                variables: {
                    name: s
                }
            })
        })
        const s2 = receptoSubjectByRfc.pipe(debounceTime(200)).subscribe(s => {
            getByRfc({
                variables: {
                    rfc: s
                }
            })
        })
        getByName({
            variables: {
                name: ''
            }
        })
        return () => {
            s.unsubscribe();
            s2.unsubscribe();
        }
    }, [receptoSubject, receptoSubjectByRfc, getByName, getByRfc])

    // Handlers ============

    const handleReceptorChange = (property: 'nombre' | 'rfc' | 'usoCFDI' | 'email') => (value: string) => {
        const receptor = Object.assign({}, cfdi.receptor);
        if (property === 'rfc') {
            value = (value || '').toUpperCase();
        }
        if (property === 'email') {
            value = (value || '').toLowerCase();
        }
        receptor[property] = value;
        dispatchBill({ property: 'receptor', value: receptor });
        if (property === 'nombre') {
            receptoSubject.next(value);
        }
        if (property === 'rfc') {
            receptoSubjectByRfc.next(value);
        }
    }

    const handleReceptorAutofill = (receptorAsString: string) => {
        const receptor: Receptor = JSON.parse(receptorAsString);
        console.log('Receptor to add: ', receptor);
        const _receptor = Object.assign({}, cfdi.receptor, { nombre: receptor.name, rfc: receptor.rfc, customerId: receptor._id });
        if (receptor.email) {
            _receptor.email = receptor.email;
        }
        if (receptor.zip) {
            _receptor.zip = receptor.zip;
        }
        if (receptor.regimen) {
            _receptor.regimen = receptor.regimen;
        }
        const comprobante: Bill = Object.assign({}, cfdi);
        comprobante.receptor = _receptor;
        if (receptor.seriePreference) {
            // Pull serie ref
            comprobante.serie = receptor.seriePreference;
        }
        dispatchBill({ property: 'comprobante', value: comprobante });
        getBillEventsByRfc({
            variables: {
                rfc: receptor.rfc
            }
        })
    }

    const handleConceptosChange = (updated: BillConceptos[]) => {
        dispatchBill({ property: 'conceptos', value: updated });
    }

    const onRelatedBillChange = () => {
        if (confirmedRelated) {
            setConfirmRelated(false);
        } else {
            const uuids = cfdiRelatedOptions.map(({ uuid }) => uuid);
            console.log(`<onRelatedBillChange> cfdiRelatedOptions: `, cfdiRelatedOptions)
            const _related = cfdi.cfdiRelacionados;
            _related.uuids = uuids;
            dispatchBill({ property: 'cfdiRelacionados', value: _related })
            // if (/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/.test(uuid.toLowerCase())) {}
            setBillRelateFilter('');
            setConfirmRelated(true);
            setTimeout(() => {
                const { y } = document.getElementById('_Conceptos_Block')?.getBoundingClientRect();
                if (!!y) {
                    document.getElementById('CreditNote').scrollTop = y;
                }
            }, 550)
        }
    }
    const onRelatedBillSelect = (billSelected: FiscalpopCFDI) => (selected: boolean) => {
        console.log('Selected: ', selected);
        dispatchCfdiRelated({ action: selected ? 'select' : 'unselect', value: billSelected })

    }

    const handleRelationType = (tipoRel: string) => {
        const _related = cfdi.cfdiRelacionados;
        _related.tipoRelacion = tipoRel;
        dispatchBill({ property: 'cfdiRelacionados', value: _related })
    }

    const _clearBill = () => {
        dispatchBill({ property: '_replace', value: EMPTY_CFDI })
        setConfirmRelated(false);
    }


    const _submitNota = () => {
        // Consider some saved receptors might not have ZIP and Regimen, use modal to update Receptor here
        if (!cfdi.receptor.zip || !cfdi.receptor.regimen) {
            setReceptorEditDialog(true);
            return;
        }

        if (!validateNotaCredIsComplete(cfdi)) {
            setValidRequested(true);
            return;
        }
        setStamping(true);
        if (eventid) {
            submitNotaToEdit({
                variables: {
                    replaceNotaId: eventid,
                    cfdi
                }
            })
        } else {
            submitNota({
                variables: {
                    uuidRelated: cfdi.cfdiRelacionados.uuids[0],
                    cfdi
                }
            })
        }
    }

    const handleReceptorEdit = (receptorUpdated: Bill['receptor']) => {
        dispatchBill({ property: 'receptor', value: receptorUpdated });
        setReceptorEditDialog(false);
    }

    const onCloseDialog = () => {
        setReceptorEditDialog(false);
    }

    const renderClientEditModal = () => {

        return (<CreditNotesClientDialog
            isOpen={receptorEditDialog}
            receptor={cfdi.receptor}
            onRequestClose={onCloseDialog}
            onClientSubmit={handleReceptorEdit}
        />)
    }

    const receptorNameOptions = receptorOptions.map(r => ({ label: r.name, value: JSON.stringify(r), template: <div><p className="_big">{r.name}</p><p className="_small">{r.rfc}</p></div> }))
    const receptorRFCOptions = receptorOptions.map(r => ({ label: r.rfc, value: JSON.stringify(r), template: <div><p className="_big">{r.rfc}</p><p className="_small">{r.name}</p></div> }))

    const { total } = calculateTotal(cfdi.conceptos);
    const relatedTotal = cfdiRelatedOptions.reduce((p, c) => p += parseFloat(c.json['@'].Total), 0);

    const totalOverflow = !cfdiRelatedOptions.length ? false : (relatedTotal < total);

    const searchFilter = billRelFilter.toUpperCase();
    const filteredBillRelates = !confirmedRelated ? billRelates.filter((bR) => {
        if (!billRelFilter) {
            return true;
        }
        return (bR.uuid.includes(searchFilter) || bR.json['@'].Serie.toUpperCase().includes(searchFilter) || bR.json['@'].Folio.toUpperCase().includes(searchFilter))
    }) : cfdiRelatedOptions
    const pageValue = billRelFilerPage * (pageLimit) >= filteredBillRelates.length ?
        Math.ceil(filteredBillRelates.length / pageLimit) :
        billRelFilerPage;

    const paginatedFilterBills = filteredBillRelates.filter((_, i) => {
        return (i >= (pageValue - 1) * pageLimit) && (i < (pageLimit * pageValue))
    });

    console.log('CFDI ', cfdi);
    return (
        <div id="CreditNote">
            <SectionTitleBar currentUser={currentUser} title={!!eventid ? `Editar Nota de crédito` : `Emitir Nota de crédito`} />
            <div className="facturarContent">
                <div>
                    <h4>Factura relacionada</h4>
                    <div className={`card table ${confirmedRelated ? 'confirmed' : ''}`} id="billRelated">
                        <div className="cardBody">
                            <div className="row two md-one sm-gap-compressed">
                                <div className="row two xs-one sm-gap-compressed">
                                    <AutoComplete disabled={confirmedRelated} onChange={handleReceptorChange('nombre')} onSelect={handleReceptorAutofill} forcePropsValue={true} noChangeOnSelect={true} label="Razón social del receptor" value={cfdi.receptor.nombre} placeholder="Razón social del receptor" type="text" options={receptorNameOptions} />
                                    <AutoComplete disabled={confirmedRelated} onChange={handleReceptorChange('rfc')} onSelect={handleReceptorAutofill} forcePropsValue={true} noChangeOnSelect={true} label="Rfc receptor" value={cfdi.receptor.rfc} placeholder="RFC del receptor" type="text" options={receptorRFCOptions} />
                                </div>
                                <div className="row">
                                    <Select disabled={confirmedRelated} label="Tipo de relación" value={cfdi.cfdiRelacionados.tipoRelacion} placeholder="" onChange={handleRelationType} options={TIPO_RELACION} />
                                </div>
                            </div>
                            <h4>Factura a relacionar</h4>
                            <div className="card">
                                {
                                    billRelates.length ?
                                        <>
                                            <div className="relatedFilter">
                                                <Input type="text" disabled={confirmedRelated} value={billRelFilter} onChange={setBillRelateFilter} label="Filtrar facturas" placeholder="Busca por Serie, Folio o UUID" />
                                            </div>
                                            <div className="billOptions header">
                                                <div />
                                                <p className="date">
                                                    Fecha de emisión
                                                </p>
                                                <p className="serie">
                                                    Serie / Folio
                                                </p>
                                                <p className="uuid">
                                                    Conceptos / UUID
                                                </p>
                                                <p>
                                                    Monto
                                                </p>
                                                <div />
                                            </div>
                                        </>
                                        : (

                                            <h3 className="instructions">
                                                Elige un receptor guardado o llena sus datos para relacionar una factura
                                            </h3>
                                        )
                                }
                                {
                                    paginatedFilterBills.map((bill) => {
                                        const selectedBill = cfdiRelatedOptions.some(cRO => cRO.uuid === bill.uuid);
                                        const conceptos = bill.json['cfdi:Conceptos']['cfdi:Concepto'];
                                        return (
                                            <div className="billOptions" key={bill.uuid}>
                                                <Checkbox disabled={confirmedRelated} value={selectedBill} onChange={onRelatedBillSelect(bill)} />
                                                <p className="date">
                                                    {moment(bill.json['@'].Fecha).format('DD / MMM / YYYY')}
                                                </p>
                                                <div className="serie">
                                                    <p>
                                                        Serie: {bill.json['@'].Serie}
                                                    </p>
                                                    <p className="small">
                                                        Folio: {bill.json['@'].Folio}
                                                    </p>
                                                </div>
                                                <div className="uuid">
                                                    <p>
                                                        {conceptos.length > 1 ? `${conceptos.length} conceptos` : conceptos[0]['@'].Descripcion}
                                                    </p>
                                                    <p className="small">
                                                        {bill.uuid}
                                                    </p>
                                                </div>
                                                <p>
                                                    {formatCurrencyStyled(parseFloat(bill.json['@'].Total))}
                                                </p>
                                                <div />
                                            </div>
                                        )
                                    })
                                }
                                <Pagination onPage={setBillRelatePage} page={pageValue} pages={Math.ceil(filteredBillRelates.length / pageLimit) || 1} />
                            </div>
                            <div className="relActions">
                                <div className="billPreview">
                                    {
                                        relatedTotal ?
                                            <p>Total de facturas relacionadas: {formatCurrencyStyled(relatedTotal)}</p>
                                            : ''
                                    }
                                </div>
                                <Button primary={true} handleClick={onRelatedBillChange} disabled={!cfdiRelatedOptions.length}>
                                    {
                                        confirmedRelated ?
                                            <span>
                                                Cambiar facturas relacionadas
                                            </span>
                                            :
                                            <span>
                                                Seleccionar facturas relacionadas
                                            </span>
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    confirmedRelated ?
                        (
                            <>
                                <FacturarConceptosDevolucion cfdi={cfdi} onCfdiChange={handleConceptosChange} validRequested={validRequested} authToken={currentUser.fiscalpopProfile.authToken} />
                                <FacturaTotales cfdi={cfdi} />
                                <div className="actions">
                                    {
                                        stamping ?
                                            <p className="message success">
                                                <span className="">
                                                    Facturando, espere...
                                                </span>
                                            </p>
                                            : (validRequested ?
                                                <p className="message error">
                                                    <span className="error">
                                                        Algunos campos faltan de llenarse o contienen errores
                                                    </span>
                                                </p>
                                                : (
                                                    !!relatedTotal && totalOverflow ?
                                                        <p className="message error">
                                                            <span className="error">
                                                                El total de la nota de credito excede el total de la factura original
                                                            </span>
                                                        </p>

                                                        :
                                                        ''))
                                    }
                                    <Button secondary={true} handleClick={_clearBill}>
                                        <span>
                                            Limpiar
                                        </span>
                                    </Button>
                                    <Button primary={true} handleClick={_submitNota} disabled={totalOverflow || !total}>
                                        <span>
                                            Emitir nota de crédito
                                        </span>
                                    </Button>
                                </div>
                            </>
                        ) :
                        null
                }

            </div>
            {renderClientEditModal()}
        </div>
    )
}

export default CreditNote;