import React from 'react';
import { NavLink } from 'react-router-dom';
import { ProfileQL } from '../../models/Profile';
import ItemMenu from '../Forms/menu/MenuComponent';
import ProfileAvatar from '../visuals/avatar';

import './accountAvatarHolder.mobile.scss';

interface AccountAvatarHolderProps {
    currentUser: ProfileQL;
}

const AccountAvatarHolderMobile = ({ currentUser }: AccountAvatarHolderProps) => {

    const menuBurger = () => {
        return (
            <span className="material-icons">
                menu
            </span>
        )
    }

    return (
        <div className="salutMobile">
            <div className='_menu'>
                <ItemMenu widthBehaviour={'auto'} openOrientation={'left'} triggerContent={menuBurger()} noPadding={true} overlayRootElement={'root'}>
                    <div id="salutMobileLinks">
                        <NavLink to="/setup/emisor" activeClassName="active">
                            Mi Emisor
                        </NavLink>
                        <NavLink to="/setup/pagos" activeClassName="active">
                            Planes & Pagos
                        </NavLink>
                        <NavLink to="/setup/cobros" activeClassName="active">
                            Cotizaciones y cobros
                        </NavLink>
                        <NavLink to="/support" activeClassName="active">
                            Soporte
                        </NavLink>
                    </div>
                </ItemMenu>
            </div>
            <div className="avatarHolder">
                <ProfileAvatar currentUser={currentUser} absolute={true} />
            </div>
            <div className='row'>
                <div className="whois">
                    <h1>¡Hola {currentUser.professionalProfile.companyName}! </h1>
                </div>
                <div className="planInfo">
                    {
                        currentUser.professionalProfile.trialExpires ?
                            null :
                            <div className="currentPlan">
                                <span className="whois">Plan actual -</span>
                            </div>
                    }
                    <div className={`planType ${currentUser.professionalProfile.planType}`}>
                        <span>{currentUser.professionalProfile.planType} {currentUser.professionalProfile.trialExpires ? 'TRIAL ACTIVO' : ''}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountAvatarHolderMobile;