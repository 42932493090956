import React, { useState } from "react";
import DatePicker from "react-datepicker";

import './Input.scss';

interface DateRangeProps {
    onChange?: (start: Date, end: Date) => void;
    label?: string;
    startDate: Date;
    endDate: Date;
    disabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
}

const DateRangeInput = React.forwardRef((props: DateRangeProps, ref: React.Ref<HTMLButtonElement>) => {
    const [active] = useState(false);

    const changeDateRangeValue = (dates: [Date, Date]) => {
        console.log(dates)
        const [start, end] = dates;
        props.onChange(start, end);
    }

    const classInput = `inputWrapper date ${props.disabled ? 'disabled' : ''} ${active ? 'active' : ''}`;
    return (
        <div className={`inputBlock dateType ${active ? 'active' : ''} ${props.disabled ? 'disabled' : ''} ${(props.startDate || props.endDate) ? 'dirty' : ''}`}>
            {
                !!props.label ?
                    <label>
                        {props.label}
                    </label>
                    : <label></label>
            }
            <div className={`${classInput} `} >
                <DatePicker
                    startDate={props.startDate}
                    endDate={props.endDate}
                    onChange={changeDateRangeValue}
                    className="input-date"
                    dateFormat="MMMM dd, yyyy"
                    locale="es"
                    selectsRange={true}
                    minDate={props.minDate || null}
                    maxDate={props.maxDate || null}
                    disabled={props.disabled}
                />
            </div>
        </div>
    )

})

export default DateRangeInput;