import React, { useState, FormEvent } from 'react';
import './RegisterFromLinker.scss';
import { BaseReact } from '../../base.model';
import LogoFullSvg from '../visuals/LogoSvg';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import { Redirect } from 'react-router-dom';

import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';
import { ProfessionalProfile, ProfileQL } from '../../models/Profile';
import GraphQlClient from '../../services/graphql';
import Select from '../Forms/Select';
import { REGIMEN_OPTIONS } from '../../utils/regimen.options';

/*
THIS IS INTENDED FOR PARTIAL LINKER REGISTRATION
----------------------------------------------

Unlike "Mismo RFC", selecting "Otro RFC" creates only personalProfile
fiscalpopProfile and AuthToken are missing and need to be completed here. 
*/

interface RegisterFromLinkerProps extends BaseReact {
    currentUser: ProfileQL;
}

function RegisterFromLinker({ history, location, currentUser }: RegisterFromLinkerProps) {

    // Account data
    const [rfc, setRfc] = useState('');
    const [regimen, setRegimen] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [lugarExpedicion, setLugarExpedicion] = useState('');

    const [registerRedirect, setRegisterRedirect] = useState(false);
    const [registering, setRegistering] = useState(false);

    const [hasError, setHasError] = useState<{ [key: string]: boolean }>({})

    const [registerEmisorOnly] = useMutation(graphqlSchema.PROFILE.SETUP.registerEmisorOnly, {
        onCompleted: ({ registerEmisorOnly }: { registerEmisorOnly: { professionalProfile: ProfessionalProfile, token: string } }) => {
            console.log('Registration: ', registerEmisorOnly);
            // UseReducer to update object array
            setRegistering(false);

            GraphQlClient.logSession(registerEmisorOnly.token)
                .then(d => {
                    console.log('Me Result: ', d);
                    setRegisterRedirect(true);
                });
        },
        onError: (e) => {
            setRegistering(false);
            console.error('Error Registration: ', e);
        }
    })

    const _setCompanyName = (e: string) => {
        setCompanyName(e.toUpperCase().trimLeft())
    }

    const _setRfc = (e: string) => {
        setRfc(e.toUpperCase().trimLeft().trimRight())
    }

    const submitRegistration = (e: FormEvent) => {
        e.preventDefault();
        if (!rfc || !companyName || !lugarExpedicion || !regimen) {
            setHasError({
                rfc: !rfc,
                companyName: !companyName,
                lugarExpedicion: !lugarExpedicion,
                regimen: !regimen,
            })
            return
        }
        setHasError({})
        setRegistering(true);

        registerEmisorOnly({
            variables: {
                emisor: {
                    rfc,
                    regimenFiscal: regimen,
                    nombre: companyName.trimRight(), // Avoid trailing space
                    lugarExpedicion
                }
            }
        })
    }

    const regimenOptions = REGIMEN_OPTIONS;

    return (
        <div id="RegisterFromLinker">
            <form className="_loginForm" onSubmit={submitRegistration}>
                <LogoFullSvg size={252} />
                <div className='row'>
                    <h1>Configura tu emisor</h1>
                    <p>{currentUser.professionalProfile.email}</p>
                </div>
                <div className="inputSection">
                    <Input hasError={hasError['companyName']} type="text" label="Nombre de tu Emisor o Razón Social" value={companyName} onChange={_setCompanyName} placeholder="No incluír siglas (S.A, C.V, etc.)" />
                    <div className="row two xs-gap-compressed">
                        <Input hasError={hasError['rfc']} type="text" label="RFC" value={rfc} onChange={_setRfc} placeholder="Tu RFC" />
                        <Input hasError={hasError['lugarExpedicion'] || (lugarExpedicion && lugarExpedicion.length > 5)} type="text" label="Codigo postal Fiscal" value={lugarExpedicion} onChange={setLugarExpedicion} placeholder="76020" />
                    </div>
                    <Select hasError={hasError['regimen']} label="Régimen" value={regimen} onChange={setRegimen} placeholder="Escoge tu régimen" options={regimenOptions} />

                    <div className="actions">
                        <div />
                        <Button primary={true} type="submit" disabled={registering}>
                            {
                                registering ?
                                    <span className='small'>Registrando tu cuenta...</span>
                                    :
                                    <span>Registar</span>
                            }
                        </Button>
                    </div>
                </div>
            </form>
            {
                registerRedirect ?
                    <Redirect to="/setup/emisor?showcerts=true" />
                    : ''
            }
        </div>
    )
}

export default RegisterFromLinker;