export const phoneParse = (phoneString: string, excludeCode = false) => {
    const phoneBase = phoneString.split('(').join('').split(')').join('').split(' ').join('').split('-').join('');
    if(excludeCode){
        return phoneBase;
    }
    if(phoneBase.includes('+52')){
        return phoneBase;
    }else{
        return `+52${phoneBase}`;
    }
}