import { useQuery } from '@apollo/react-hooks';
import React, { FormEvent, useEffect, useState } from 'react';

import Modal from 'react-modal';
import { ConceptoCatalogo } from '../../../models/Catalogos';
import { BillConceptos, FiscalpopImpuesto } from '../../../models/Factura';
import { graphqlSchema } from '../../../services/graphql.schema';
import { ConceptoElement } from '../../Facturar/Factura.conceptos';
import Button from '../../Forms/Button';

import './Conceptos.scss';
import { SPLIT_IMPUESTO_UNIQUE_ID } from '../../../utils/impuestos.formatting';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '94vh',
        maxWidth: '80vw',
        padding: '6px',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: '10px',
        overflow: 'visible',
    }
};

interface ConceptosDialogProps {
    modalOpen: boolean;
    authToken: string;
    updateConcepto?: ConceptoCatalogo;
    saveConcepto: (concepto: ConceptoCatalogo) => void;
    closeConceptosModal: () => void;

}

const getEmptyConcepto = (): ConceptoCatalogo => {
    return {
        claveProdServ: '',
        claveUnidad: '',
        descripcion: '',
        valorUnitario: 0,
        impuestos: [
            { type: 'iva', retencion: false, tasa: 0.16, tipo: 'Fijo' }
        ]
    }
}

function ConceptosCatalogDialog({ authToken, modalOpen, updateConcepto, closeConceptosModal, saveConcepto }: ConceptosDialogProps) {
    const [impuestosList, setImpuestosList] = useState([]);
    const [newConcepto, setNewConcepto] = useState<ConceptoCatalogo>(getEmptyConcepto())

    useEffect(() => {
        if (updateConcepto) {
            setNewConcepto(updateConcepto)
        } else {
            setNewConcepto(getEmptyConcepto())
        }
    }, [updateConcepto])


    useQuery(graphqlSchema.FISCALPOP.IMPUESTOS.getImpuestos, {
        onCompleted: ({ getImpuestos }: { getImpuestos: FiscalpopImpuesto[] }) => {
            const splitImpuestos = SPLIT_IMPUESTO_UNIQUE_ID(getImpuestos);
            console.log('Get Impuestos: ', splitImpuestos);
            setImpuestosList(splitImpuestos);
        },
        onError: (e) => {
            console.error('Error loading impuestos: ', e);
        },
        fetchPolicy: 'cache-first'
    })

    const updateConceptoElement = (index: number) => (update: BillConceptos) => {
        setNewConcepto(update);
    }

    const saveConceptoEdit = (e?: FormEvent) => {
        if (!!e) {
            e.preventDefault();
        }
        if (updateConcepto?._id) {
            newConcepto._id = updateConcepto._id;
        }
        if (!newConcepto.descripcion || !newConcepto.valorUnitario) {
            // Invalid inputs
            return;
        }
        const _concepto = JSON.parse(JSON.stringify(newConcepto));
        delete _concepto.cantidad;
        // Original concepto removes _id, reconstitute it back if any
        saveConcepto(_concepto);
        setNewConcepto(getEmptyConcepto())
    }

    const addaptCantidad = Object.assign({ cantidad: 0 }, newConcepto);
    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeConceptosModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Agregar nuevo concepto"
        >
            <div className="card table" id="newConcepto">
                <div className="cardTitle withClose">
                    <h4>{updateConcepto ? 'Editar concepto' : 'Nuevo concepto'}</h4>
                    <i className="material-icons" onClick={closeConceptosModal}>
                        cancel
                    </i>
                </div>
                <form className="cardBody" onSubmit={saveConceptoEdit}>
                    <ConceptoElement
                        authToken={authToken}
                        descripcionAsInput={true}
                        hideCantidad={true}
                        showNoIdentificacion={true}
                        validRequested={false}
                        conceptoIndex={0}
                        impuestosList={impuestosList}
                        concepto={addaptCantidad}
                        handleConceptoUpdate={updateConceptoElement}
                    />
                    <div className="_actions">
                        <Button primary={true} type="submit">
                            <span>Guardar concepto</span>
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ConceptosCatalogDialog;