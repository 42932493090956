
export const numberToCurrencyString = (value: number, noCents:boolean = false) => {
    const valueString = value.toFixed(2);
    const isNegative = valueString.includes('-');
    const [integers, cents] = valueString.replace('-', '').split('.');
    const integerString = integers.split('').reverse().reduce((p, c, index) => {
        const baseOneIndex = index + 1;
        p.push(c);
        if (!(baseOneIndex % 3) && baseOneIndex < (integers.split('').length)) {
            p.push(`,`);
        }
        return p;
    }, []).reverse().join('');
    if(noCents) {
        return `${isNegative ? '-':''}${integerString}`;
    }
    return `${isNegative ? '-':''}${integerString}.${cents}`;
};
