import { useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { BankProject, BankProjectionStatus_Summary } from '../../../../models/Administracion/Bank.Projects';
import { graphqlSchema } from '../../../../services/graphql.schema';
import AdministrationHubProyectionsGraph from './AdministrationHub.Proyections.graph';
import AdministrationHubProjects from './AdministrationHub.Proyections.Projects';

function AdministrationHubProyections() {

    const [projectDict, setProjectDict] = useState<{ [key: string]: boolean }>({})

    const [projectsLoaded, setProjectsLoaded] = useState(false);
    const [projects, setProjects] = useState<BankProject<'business'>[]>([])
    const [projectActivity, setProjectActivity] = useState<BankProjectionStatus_Summary[]>([])


    useQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.getProjectionStatusSummary, {
        onCompleted: ({ getProjectionStatusSummary }: { getProjectionStatusSummary: BankProjectionStatus_Summary[] }) => {
            console.log(`<AdministrationHubProyections> Summaries: `, getProjectionStatusSummary);
            setProjectActivity(getProjectionStatusSummary);
            setProjectsLoaded(true)
        },
        onError: (e) => {
            console.error('Error fetching getProjectionStatusSummary: ', e.graphQLErrors[0]);
        },
        fetchPolicy: 'cache-and-network'
    })
    
    useQuery(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.getProjectionProjectsTransactions, {
        onCompleted: ({ getProjectionProjects }: { getProjectionProjects: BankProject<'business'>[] }) => {
            console.log(`<AdministrationHubProyections> getProjectionProjects: `, getProjectionProjects);
            setProjects(getProjectionProjects);
        },
        onError: (e) => {
            console.error('Error fetching getProjectionProjects: ', e.graphQLErrors[0]);
        },
        fetchPolicy: 'cache-and-network'
    })

    const onProjectToggle = (projectId: string) => () => {
        const _projectDict = JSON.parse(JSON.stringify(projectDict))
        _projectDict[projectId] = !_projectDict[projectId];
        setProjectDict(_projectDict);
    }

    return (
        <React.Fragment>
            <div>
                <h4>Resumen Mensual Proyecciones</h4>
                <AdministrationHubProyectionsGraph projects={projects}/>
            </div>
            <AdministrationHubProjects projectsLoaded={projectsLoaded} projects={projects} projectDict={projectDict} projectActivity={projectActivity} onProjectToggle={onProjectToggle} />
        </React.Fragment>
    )
}

export default AdministrationHubProyections;
