

import React, { useEffect, useState } from 'react';
import Button from '../../../Forms/Button';

import './Presupuestos.Overview.scss'

import { RouteComponentProps } from "react-router-dom";
import { BankBudgets } from '../../../../models/Administracion/Bank.Budgets';
import { BankMovementSummary } from '../../../../models/Administracion/Bank.Transactions';
import ToolTip from '../../../visuals/tooltip';
import { formatCurrencyStyled } from '../../../visuals/currency.formatted';

interface PresupuestosOverviewProps {
    history: RouteComponentProps['history'];
    budgets: BankBudgets[];
    banksSummary: BankMovementSummary[]
}

function PresupuestosOverview({
    history,
    budgets,
    banksSummary
}: PresupuestosOverviewProps) {
    const [monthBudget, setMonthBudget] = useState(0)
    const [monthExpense, setMonthExpense] = useState(0)
    const [monthExpenseInProject, setMonthExpenseInProject] = useState(0)
    const [monthExpenseInternalPair, setMonthExpenseInternalPair] = useState(0)

    const [budgettedExpense, setBudgettedExpense] = useState(0)
    const [unBudgettedExpense, setUnBudgettedExpense] = useState(0)

    useEffect(() => {
        const _monthBudget = budgets.reduce((p, c) => p += c.goal, 0)
        const _unBudgettedExpenses = budgets.filter(b => !b.goal && b.current).reduce((p, c) => p += c.current, 0)
        const _budgettedExpenses = budgets.filter(b => !!b.goal && b.current).reduce((p, c) => p += c.current, 0)


        setMonthBudget(_monthBudget)
        setBudgettedExpense(_budgettedExpenses)
        setUnBudgettedExpense(_unBudgettedExpenses)

    }, [budgets])

    useEffect(() => {
        const _monthExpense = Math.abs(banksSummary.reduce((p, c) => p += c.expense, 0))
        const _monthExpenseInProject = Math.abs(banksSummary.reduce((p, c) => p += c.expenseByProject.reduce((_p, _c) => _p += _c.totalExpense, 0), 0))
        const _monthExpenseInternalPair = Math.abs(banksSummary.reduce((p, c) => p += c.internalExpense, 0))

        setMonthExpense(_monthExpense);
        setMonthExpenseInProject(_monthExpenseInProject)
        setMonthExpenseInternalPair(_monthExpenseInternalPair)
    }, [banksSummary])

    const goToAssignCategory = () => {
        history.push({
            pathname: '/administration/movimientos',
            search: 'category=__null'
        })
    }

    const personalExpense = monthExpense - monthExpenseInProject - monthExpenseInternalPair;
    return (
        <div className='presupuestosOverview card noShadow'>
            <div className='_case'>
                <p>
                    Egreso total del mes
                </p>
                <p className='currency neutral'>
                    <b>{formatCurrencyStyled(monthExpense)}</b>
                </p>
            </div>
            {
                // Show only if there is an internal pair movement
                monthExpenseInternalPair ?
                    <div className='_case'>
                        <p>
                            Pago tarjeta de crédito
                        </p>
                        <ToolTip text='Abono a tarjeta de crédito no es un gasto, pero se reporta como egreso en movimientos'>
                            <p className='currency neutral'>
                                {formatCurrencyStyled(monthExpenseInternalPair)}
                                <span className="material-icons _help">
                                    help_outline
                                </span>
                            </p>
                        </ToolTip>
                    </div>
                    : null
            }
            <div className='_case'>
                <p>
                    Gasto en proyectos
                </p>
                <ToolTip text='Asignados a proyectos de gastos o proyectos de negocio. Tienen su presupuesto y control por separado'>
                    <p className='currency orange'>
                        {formatCurrencyStyled(monthExpenseInProject)}
                        <span className="material-icons _help">
                            help_outline
                        </span>
                    </p>
                </ToolTip>
            </div>
            <div className='_case'>
                <p>
                    Gasto personales
                </p>
                <ToolTip text='Cantidad aplicable a gastos personales y presupuesto mensual'>
                    <p className='currency neutral'>
                        <b>{formatCurrencyStyled(personalExpense)}</b>
                        <span className="material-icons _help">
                            help_outline
                        </span>
                    </p>
                </ToolTip>
            </div>
            <div className='_case'>
                <p>
                    Sin presupuesto:
                </p>
                <ToolTip text='En Categorías sin presupuesto'>
                    <p className='currency orange'>
                        {formatCurrencyStyled(unBudgettedExpense)}
                        <span className="material-icons _help">
                            help_outline
                        </span>
                    </p>
                </ToolTip>
            </div>
            <div className='_case'>
                <p>
                    Sin categoría
                </p>
                <ToolTip text='Gastos sin cateorizar todavía'>
                    <p className='currency orange'>
                        {formatCurrencyStyled(personalExpense - (budgettedExpense + unBudgettedExpense))}
                        <span className="material-icons _help">
                            help_outline
                        </span>
                    </p>
                </ToolTip>
            </div>
            <div className='_case'>
                <p>
                    Con presupuesto:
                </p>
                <p className={`currency ${!!budgettedExpense && !!monthBudget && (budgettedExpense <= monthBudget) ? 'green' : 'neutral'}`}>
                    <b>
                        {formatCurrencyStyled(budgettedExpense)}
                    </b>
                </p>
                {
                    budgettedExpense > monthBudget ?
                        <ToolTip text={`En Categorías con presupuesto, Presupuesto excedido un ${Math.round(((budgettedExpense - monthBudget) / monthBudget) * 1000) / 10}%`}>
                            <p className='currency red'>
                                ({formatCurrencyStyled(budgettedExpense - monthBudget)})
                                <span className="material-icons _help">
                                    help_outline
                                </span>
                            </p>
                        </ToolTip>
                        : null
                }
            </div>
            <div className='_case'>
                <p>
                    Presupuesto actual
                </p>
                <p className='currency neutral'>
                    {formatCurrencyStyled(monthBudget)}
                </p>
                {
                    /*
                    */
                    personalExpense > monthBudget ?
                        <ToolTip text='Gasto personal por encima del presupuesto'>
                            <p className='currency red'>
                                (+{Math.round(((personalExpense) / monthBudget) * 10000) / 100}% usado)
                                <span className="material-icons _help">
                                    help_outline
                                </span>
                            </p>
                        </ToolTip>
                        : <ToolTip text='Gasto personal por debajo del presupuesto'>
                            <p className='currency green'>
                                ({
                                    !monthBudget ?
                                        0
                                        : Math.round(((personalExpense) / monthBudget) * 10000) / 100
                                }% usado)
                                <span className="material-icons _help">
                                    help_outline
                                </span>
                            </p>
                        </ToolTip>
                }
            </div>
            <div className='action'>
                <Button primary={true} setClass={'orange'} handleClick={goToAssignCategory}>
                    <span>
                        Asignar categorías
                    </span>
                </Button>
            </div>
        </div>
    )
}

export default PresupuestosOverview;