import React from 'react';
import { numeroALetras } from '../../../utils/numeroLetra';
import { calculateTotalWidthComission } from '../../../utils/calculate.conceptos';
import { formatCurrencyStyled } from '../../visuals/currency.formatted';
import { Cotizacion } from '../../../models/Cotizaciones';

function CotizacionTotales({ cotizacion }: { cotizacion: Cotizacion }) {

    const toLetter = (value: number) => {
        return numeroALetras(value, {
            plural: 'pesos mexicanos',
            singular: 'pesos mexicanos',
            centPlural: 'centavos',
            centSingular: 'centavo'
        }).toLowerCase();
    }

    const { subtotal, taxes, total, feeFiscalpop, feeTransaction } = calculateTotalWidthComission(cotizacion.conceptos, cotizacion.feeTransaction);
    return (
        <div className="card totales">
            <div className="letter">
                <p>
                    <b> Total cotizado en letra:</b> <br />{toLetter(total)}
                </p>
            </div>
            <div className={`totalIs ${cotizacion.translateFee ? 'feeTranslate' : ''}`}>
                <div className="groupTot">
                    <p>{formatCurrencyStyled(subtotal)}</p>
                    <p className="subtotal">Subtotal </p>
                </div>
                {
                    taxes.map((tax, i) => {
                        return (
                            <div className="groupTot" key={i}>
                                <p className={`taxvalue ${tax.value < 0 ? 'negative' : ''}`}> {formatCurrencyStyled(tax.value)}</p>
                                <p className={`tax ${tax.value < 0 ? 'negative' : ''}`}>{tax.key}</p>
                            </div>
                        )
                    })
                }

                {
                    cotizacion.translateFee ?
                        <div className="groupTot">
                            <p className="feeValue possitive">+{formatCurrencyStyled(feeFiscalpop + feeTransaction)}</p>
                            <p className="fee">Comisión tarjeta</p>
                            <p className="notelabel">Cargo a Cliente</p>
                        </div>
                        : ''
                }

                <div className="groupTot">
                    <p>{formatCurrencyStyled(cotizacion.translateFee ? total + feeFiscalpop + feeTransaction : total)}</p>
                    <p className="total">Total cotizado</p>
                </div>

                <div className="groupTot">
                    <p className="feeValue negative">{formatCurrencyStyled(-(feeFiscalpop + feeTransaction))}</p>
                    <p className="fee">Comisión tarjeta</p>
                    <p className="notelabel">Cargo a Vendedor</p>
                </div>
                <div className="groupTot">

                    <p className="masterTotal">{formatCurrencyStyled(cotizacion.translateFee ? total : total - (feeFiscalpop + feeTransaction))}</p>
                    <p className="masterTotal label">Ingreso a recibir</p>
                </div>
            </div>
        </div>
    )
}

export default CotizacionTotales;

// $116 (2 x $ 50) + IVA
// > 5.35% ($122.21)
// >>>>>  5% Stripe
// >>>>>  0.35% Fiscalpop