import React from 'react';

import './barPercent.scss';

function PercentBar({decimalPercent, divideByCero = false}: {decimalPercent: number, divideByCero?: boolean}) {
    decimalPercent = isNaN(decimalPercent) ? 0 : decimalPercent;
    divideByCero = divideByCero ? divideByCero && !!decimalPercent : divideByCero
    const width = divideByCero ? 100 : (decimalPercent > 1 ? 100 : Math.round(decimalPercent * 1000) / 10)
    return (
        <div className='__barPercent'>
            {
                divideByCero ?
                    <p>
                        <span style={{ fontSize: '1.5em' }}>∞</span>
                    </p>
                    :
                    <p>
                        {Math.round(decimalPercent * 100)}%
                    </p>
            }
            <div className='_reference'>
                <div className={`_value ${(decimalPercent >= 1) || divideByCero ? 'red' : (decimalPercent >= 0.9 ? 'orange' : 'green')}`} style={{ width: `${width}%` }} />
            </div>
            {
                (decimalPercent > 1) || divideByCero ?
                    <span className="material-icons">
                        warning
                    </span>
                    : null
            }
        </div>
    )
}

export default PercentBar