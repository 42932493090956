import React, { useState } from 'react';

import './Setup.Pagos.scss';
import { BaseReact } from '../../base.model';
import { ProfileQL } from '../../models/Profile'
import SectionTitleBar from '../components/titlebar';
import Button from '../Forms/Button';
import ActionCards from '../Account/CardDialogs/Account.cards';
import AccountPayment from '../Account/PaymentDialogs/Account.payment';
import SpeiConfirm from '../Account/SpeiDialog/Spei.confirm';
import { useQuery, useLazyQuery, useSubscription } from '@apollo/react-hooks';
import { graphqlSchema } from '../../services/graphql.schema';

import AccountCard from '../Account/Account.paymentMethod';
import moment from 'moment'
import { numberToCurrencyString } from '../../services/formatting';
import SetupMenu from './Setup.menu';
import AccountAvatarHolder from '../components/accountAvatarHolder';
import AccountAvatarHolderMobile from '../components/accountAvatarHolder.mobile';
import sharedToasterSubject from '../../services/shared.toasterSubject';
import { StripeCard_SourceCard, StripePaymentOrder, StripeSubscriptionInvoice } from '../../models/Stripe';
import GraphQlClient from '../../services/graphql';
import ProfesionalSvg from '../Planes/svgs/profesionalSvg';
import { BillingAccountUpdateSubscription } from '../../models/BillingAccount';
import { CancelSubscription } from '../Planes/CancelSubsDialog/CancelSubs.dialog';

moment.locale('es')

interface SubscriptionUsage { item: string, usage: number, period: { start: number } }

interface SetupProps extends BaseReact {
    currentUser: ProfileQL;
}

function SetupPagosFiscalPop({ currentUser, history }: SetupProps) {
    // Emisor options

    const [addCardOpen, setAddCardOpen] = useState(false);
    const [addPayment, setAddPayment] = useState(false);
    const [selectedCard, setSelectedCard] = useState('');

    const [paymentMethods, setPaymentMethods] = useState<StripeCard_SourceCard[]>([]);
    const [paymentOrders, setPaymentOrders] = useState<StripePaymentOrder[]>([]);
    const [subscriptionInvoice, setSubscriptionInvoice] = useState<StripeSubscriptionInvoice>(null);
    const [subscriptionUsage, setSubscriptionUsage] = useState<SubscriptionUsage[]>([]);
    // Display Modal for SPEI
    const [displaySpei, setDisplaySpei] = useState<StripePaymentOrder>(null);
    const [showSpei, setShowSpei] = useState(false)

    const [openCancelSubs, setOpenCancelSubs] = useState(false)


    useQuery(graphqlSchema.STRIPE.CARDS.getCards, {
        onCompleted: ({ getCards }: { getCards: StripeCard_SourceCard[] }) => {
            console.log('Payment methods gotten: ', getCards);
            setPaymentMethods(getCards)
        },
        fetchPolicy: 'network-only',
    });
    useQuery(graphqlSchema.STRIPE.ORDERS.getPaymentOrders, {
        onCompleted: ({ getPaymentOrders }: { getPaymentOrders: StripePaymentOrder[] }) => {
            console.log('Payment orders gotten: ', getPaymentOrders);
            setPaymentOrders(getPaymentOrders)
        },
        fetchPolicy: 'network-only',
    });
    useQuery(graphqlSchema.STRIPE.USAGE.getSubscriptionUsage, {
        onCompleted: ({ getSubscriptionUsage }: { getSubscriptionUsage: SubscriptionUsage[] }) => {
            console.log('Subscription usage gotten: ', getSubscriptionUsage);
            setSubscriptionUsage(getSubscriptionUsage)

        },
        fetchPolicy: 'network-only',
    });
    useQuery(graphqlSchema.STRIPE.USAGE.getSubscriptionInvoice, {
        onCompleted: ({ getSubscriptionInvoice }: { getSubscriptionInvoice: StripeSubscriptionInvoice }) => {
            console.log('Subscription invoice gotten: ', getSubscriptionInvoice);
            if (getSubscriptionInvoice?.subscriptionStatus === 'past_due') {
                // Ignore all paid invoices as they'll show as Payment Orders
                setSubscriptionInvoice(getSubscriptionInvoice)
            } else {
                setSubscriptionInvoice(null)
            }
        },
        fetchPolicy: 'network-only',
    });

    const [getStripeOrders] = useLazyQuery(graphqlSchema.STRIPE.ORDERS.getPaymentOrders, {
        onCompleted: ({ getPaymentOrders }: { getPaymentOrders: StripePaymentOrder[] }) => {
            console.log('Payment orders gotten: ', getPaymentOrders);
            setPaymentOrders(getPaymentOrders)
        },
        fetchPolicy: 'network-only'
    });
    const [getStripeSubscriptionInvoice] = useLazyQuery(graphqlSchema.STRIPE.USAGE.getSubscriptionInvoice, {
        onCompleted: ({ getSubscriptionInvoice }: { getSubscriptionInvoice: StripeSubscriptionInvoice }) => {
            console.log('Subscription invoice gotten: ', getSubscriptionInvoice);
            if (getSubscriptionInvoice?.subscriptionStatus === 'past_due') {
                // Ignore all paid invoices as they'll show as Payment Orders
                setSubscriptionInvoice(getSubscriptionInvoice)
            } else {
                setSubscriptionInvoice(null)
            }
        },
        fetchPolicy: 'network-only',
    });
    const [getStripeCards] = useLazyQuery(graphqlSchema.STRIPE.CARDS.getCards, {
        onCompleted: ({ getCards }: { getCards: StripeCard_SourceCard[] }) => {
            console.log('Payment methods gotten: ', getCards);
            setPaymentMethods(getCards)
        },
        fetchPolicy: 'network-only',
    });

    // Get BE notifications for Account Balance updates
    useSubscription<BillingAccountUpdateSubscription>(graphqlSchema.FISCALPOP.STRIPE.billingAccountUpdates, {
        onSubscriptionData: ({ subscriptionData }) => {
            // Check if re-call is actually needed
            const billingAccountUpdates = subscriptionData.data.billingAccountUpdates;
            console.log(`[Setup Pagos] <billingAccountUpdates> billingAccountUpdates`, billingAccountUpdates);
            getStripeOrders()
            getStripeSubscriptionInvoice()
            sharedToasterSubject.next({ type: 'confirm', message: 'Se ha actualizado tu cuenta' })
        }
    })

    const goToPlanes = () => {
        history.push({
            pathname: '/planes'
        })
    }


    const closeCardModal = (shouldRefresh: boolean) => {
        setAddCardOpen(false);
        console.log('Closing card modal: ', shouldRefresh);
        if (!!shouldRefresh) {
            getStripeCards();
            getStripeOrders();
        }
    }

    const openCardModal = () => {
        setAddCardOpen(true);
    }

    const closePaymentModal = (paymentResponse: StripePaymentOrder = null) => {
        setAddPayment(false);
        console.log(`<closePaymentModal> paymentResponse:`, paymentResponse);
        if (!paymentResponse) {
            return;
        }
        if (paymentResponse.method === 'spei') {
            // SPEI management
            setDisplaySpei(paymentResponse);
            setShowSpei(true)
            getStripeOrders();
        } else {
            // CARD MANAGEMENT
            getStripeOrders();
            if (paymentResponse.paymentIntentStatus === 'succeeded') {
                // If Payment was successful, it probably changed balance
                // >> Remember a disabled user might make a payment and become re-enabled 
                setTimeout(() => {
                    GraphQlClient.recallCurrentUser();
                }, 500);
            }
        }

    }
    const openPaymentModal = () => {
        setAddPayment(true);
    }


    const getOrderState = (ord: StripePaymentOrder) => {
        if (ord.paid) {
            return <p className="_state good">Pagado</p>;
        }
        else if (ord.paymentIntentStatus === 'succeeded') {
            return <p className="_state good">Pagado</p>
        }
        else if (ord.paymentIntentStatus === 'canceled') {
            return <p className="_state bad">Vencido</p>
        }
        else if (ord.paymentIntentStatus === 'requires_action') {
            return <p className="_state bad">Esperando</p>
        }
        else if (ord.paymentIntentStatus === 'partially_funded') {
            return <p className="_state bad">Pago parcial</p>
        }
        else if (ord.chargeStatus === 'failed') {
            return <p className="_state bad">Vencido</p>
        }
        else if (ord.chargeStatus === 'pending') {
            return <p className="_state meh">Pendiente</p>
        }
        else if (ord.chargeStatus === 'succeeded') {
            return <p className="_state good">Pagado</p>
        }
        else {
            return <p className="_state meh">No pagado</p>;
        }
    }

    const showSpeiInstructions = (ord: StripePaymentOrder) => () => {
        setShowSpei(true);
        setDisplaySpei(ord);
    }

    const getOrderType = (ord: StripePaymentOrder) => {
        if (ord.method === 'card') {
            return (
                <p className='_bold'>Tarjeta</p>
            );
        } else if (ord.method === 'spei') {
            return <p className='_bold'>SPEI</p>;

        } else if (ord.method === 'subscription') {
            return <p className='_bold'>Subscripción</p>;

        } else {
            return <p>Otro</p>;
        }
    }

    const getOrderActions = (ord: StripePaymentOrder) => {
        if (ord.method === 'spei') {
            if (ord.paymentIntentStatus === 'requires_action') {
                return (
                    <div className='iconPar' onClick={showSpeiInstructions(ord)}>
                        <span className="material-icons">assignment</span>
                        <p> <span className='md-r-1'>Instrucciones</span> SPEI</p>
                    </div>
                )
            }
        }
        if (ord.invoiceUUID) {
            return (
                <a rel="noopener noreferrer" href={`https://api.fiscalpop.com/api/v1/cfdi/download/pdf/${currentUser.professionalProfile.authToken}?uuid=${ord.invoiceUUID}`} download={true}>
                    <div className='iconPar'>
                        <span className="material-icons">
                            download
                        </span>
                        <p>
                            <span className='md-r-1'>Descargar</span> Factura
                        </p>
                    </div>
                </a>
            )
        }
        return (<div />)
    }

    const selectCard = (cardId: string) => {
        return () => {
            if (selectedCard === cardId) {
                setSelectedCard('');
            } else {
                setSelectedCard(cardId);
            }
        }
    }

    const eraseCard = (cardId: string) => {
        setPaymentMethods(paymentMethods.filter(pm => pm.id !== cardId));
    }
    const setDefaultCard = (cardId: string) => {
        const _paymentMethods: StripeCard_SourceCard[] = JSON.parse(JSON.stringify(paymentMethods));
        _paymentMethods.forEach(pM => {
            if (pM.id === cardId) {
                pM.default = true;
            } else {
                pM.default = false;
            }
        })
        setPaymentMethods(_paymentMethods);
    }

    const closeSpeiConfirm = () => {
        setShowSpei(false)
    }

    const cancelSubscriptionModal = () => {
        setOpenCancelSubs(true)
    }

    const closeCancelSubscriptionModal = () => {
        setOpenCancelSubs(true)
    }


    const renderLiteSaldo = () => {
        return (
            <div className='grid _upgradeGrid'>
                <div className='_upgrade professional center yCenter'>
                    <ProfesionalSvg height={120} width={120} />
                    <p>
                        Profesional
                    </p>
                    <Button secondary={true} handleClick={goToPlanes} disabled={currentUser.billing?.isClusterType}>
                        <span>Prueba 1 mes Gratis</span>
                    </Button>
                </div>
                <div className="row center yCenter saldoLg">
                    <div className="budget">
                        {
                            !!currentUser.billing && currentUser.billing?.isClusterType ?
                                <h2>
                                    Administrado por {currentUser.billing?.clusterName || 'gestor'}
                                </h2>
                                :
                                <h2>
                                    <b>Saldo actual </b>$ {numberToCurrencyString(currentUser.billing?.balance || 0)}
                                </h2>
                        }
                    </div>
                    <Button primary={true} handleClick={openPaymentModal} disabled={currentUser.billing?.isClusterType}>
                        <span>Agregar saldo</span>
                    </Button>
                </div>
            </div>
        )
    }

    const renderProfessionalState = (isMobile = false) => {
        return (
            <div className={`_professionalState ${isMobile ? '_mobile' : ''}`}>
                <div className='_state'>
                    <h1>
                        Profesional
                    </h1>
                    {
                        currentUser.professionalProfile.trialExpires ?
                            <div className='cancelTrial'>
                                <Button tertiary={true} setClass={'_cancel'} handleClick={cancelSubscriptionModal}>
                                    <span>
                                        Cancelar periodo de prueba
                                    </span>
                                </Button>
                            </div>
                            :
                            <div className='usage'>
                                {
                                    subscriptionUsage?.length ?
                                        subscriptionUsage.map((usage, index) => {
                                            const isFacturas = usage.item === 'facturas';
                                            const isBancos = usage.item === 'bancos';
                                            return (
                                                <div className='row' key={usage.item}>
                                                    {
                                                        isFacturas ?
                                                            <p className='gray'>Facturas Utilizadas: {usage.usage} / 100</p>
                                                            : null
                                                    }
                                                    {
                                                        isBancos ?
                                                            <p className='gray'>Bancos Asignados: {usage.usage} / 2</p>
                                                            : null
                                                    }
                                                </div>
                                            )

                                        })
                                        : null
                                }
                            </div>
                    }
                </div>
            </div>
        )
    }

    return (
        <div id="SetupPagosFiscalPop">
            <SectionTitleBar currentUser={currentUser} title="Planes & Pagos" />
            <div className="setupContent">
                <SetupMenu />
                <div className="card payments">
                    <AccountAvatarHolder currentUser={currentUser} />
                    <AccountAvatarHolderMobile currentUser={currentUser} />
                    {
                        currentUser.professionalProfile.planType === 'professional' ?
                            <div className='_logoWrap'>
                                <div className='_logo'>
                                    <ProfesionalSvg width={544} height={540} />
                                </div>
                            </div>
                            : null
                    }
                    <div className="setupPadding">
                        <div className="row two md-one">
                            <div className='grid _infoCobro'>
                                <div className="row start">
                                    <h4>
                                        Información de Cobro y Facturación
                                    </h4>
                                </div>
                                {
                                    currentUser.professionalProfile.planType !== 'professional' ?
                                        <div className="row yCenter saldoMd">
                                            <div className="budget">
                                                {
                                                    !!currentUser.billing && currentUser.billing?.isClusterType ?
                                                        <h2>
                                                            Administrado por {currentUser.billing?.clusterName || 'gestor'}
                                                        </h2>
                                                        :
                                                        <h2><b>Saldo actual </b>$ {numberToCurrencyString(currentUser.billing?.balance || 0)}</h2>
                                                }
                                            </div>
                                            <Button primary={true} handleClick={openPaymentModal} disabled={currentUser.billing?.isClusterType}>
                                                <span>Agregar saldo</span>
                                            </Button>
                                        </div>
                                        : renderProfessionalState(true)
                                }
                                <div className="cardBlock">
                                    <div className='cardMethods card'>
                                        <h4>
                                            Tarjetas Agregadas
                                        </h4>
                                        {
                                            !paymentMethods.length ?
                                                <div className='noCard'>
                                                    <p>No tienes tarjetas cargadas, agrega una!</p>
                                                </div>
                                                :
                                                paymentMethods.map((method, id) => {
                                                    return <AccountCard selectCard={selectCard} method={method} selectedCardId={selectedCard} key={id} onErrase={eraseCard} onSetDefaultCard={setDefaultCard} noDeleteOption={paymentMethods.length < 2} />
                                                })
                                        }
                                    </div>
                                    <div className='cardAddContain'>
                                        <div className='card addCard' onClick={openCardModal}>
                                            <div className='addCardIcon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="22" viewBox="0 0 45 22" fill="none">
                                                    <path d="M40.333 0H15.583C14.0642 0 12.833 1.23122 12.833 2.75V19.25C12.833 20.7688 14.0642 22 15.583 22H40.333C41.8518 22 43.083 20.7688 43.083 19.25V2.75C43.083 1.23122 41.8518 0 40.333 0Z" fill="#AC2E78" />
                                                    <path d="M12.833 8.25H43.083" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.58331 6.41667C4.89971 6.41666 5.15622 6.67314 5.15625 6.98954L5.15655 10.4268L8.59375 10.4271C8.91015 10.4271 9.16668 10.6836 9.16667 11C9.16665 11.3164 8.91015 11.5729 8.59375 11.5729H5.15655L5.15625 15.0104C5.15622 15.3268 4.89971 15.5834 4.58331 15.5833C4.26691 15.5833 4.01042 15.3268 4.01042 15.0104V11.5729H0.572966C0.256563 11.5729 1.36599e-05 11.3164 5.45576e-10 11C-1.36589e-05 10.6836 0.256464 10.4271 0.572867 10.4271L4.01042 10.4268V6.98964C4.01042 6.67323 4.2669 6.41669 4.58331 6.41667Z" fill="#AC2E78" />
                                                </svg>
                                                <span>
                                                    Agregar tarjeta
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                currentUser.professionalProfile.planType !== 'professional' ?
                                    renderLiteSaldo()
                                    : renderProfessionalState()
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <h4>Historial de pagos</h4>
                    <div className="card payments">
                        <div className="hisotryPadding">
                            {
                                !!subscriptionInvoice || paymentOrders.length ?
                                    <div className="paymentEntry header">
                                        <p className='_fecha'>Fecha</p>
                                        <p className='_tipo'>Tipo de pago</p>
                                        <p className='_cantidad'>Cantidad</p>
                                        <p className='_state'>Estado</p>
                                        <p className='_download'></p>
                                    </div>
                                    : null
                            }
                            {
                                !!subscriptionInvoice ?
                                    <div className='paymentEntry pendingInvoice'>
                                        <div className='_fecha'>
                                            <p>{moment(subscriptionInvoice.effectiveAt).format('D MMM, YYYY')}</p>
                                            <p className="_small">{moment(subscriptionInvoice.effectiveAt).format('hh:mm A')}</p>
                                        </div>
                                        <div className='_tipo'>
                                            <p className='_bold'>
                                                Subscripción
                                            </p>
                                        </div>
                                        <div className='_cantidad'>
                                            <p>$ {numberToCurrencyString(subscriptionInvoice.amount / 100)}</p>
                                        </div>
                                        <div className='_state'>
                                            <p className="_state bad">
                                                Vencido
                                            </p>
                                            <p className="center _small">
                                                {moment(subscriptionInvoice.effectiveAt).fromNow(false)}
                                            </p>
                                        </div>
                                        <a className='_download' href={subscriptionInvoice.hostedInvoiceUrl}>
                                            <div className='iconPar' >
                                                <span className="material-icons">shopping_cart_checkout</span>
                                                <p> Pagar <span className='md-r-1'>ahora</span></p>
                                            </div>
                                        </a>
                                    </div>

                                    : null
                            }
                            {
                                !paymentOrders.length ?
                                    null
                                    :
                                    paymentOrders.sort((a, b) => b.createdAt - a.createdAt).map((ord, i) => {
                                        return (
                                            <div className="paymentEntry" key={i}>
                                                <div className='_fecha'>
                                                    <p>{moment(ord.createdAt).format('D MMM, YYYY')}</p>
                                                    <p className="_small">{moment(ord.createdAt).format('hh:mm A')}</p>
                                                </div>
                                                <div className='_tipo'>
                                                    {getOrderType(ord)}
                                                </div>
                                                <div className='_cantidad'>
                                                    <p>$ {numberToCurrencyString(ord.amount / 100)}</p>
                                                </div>
                                                <div className='_state'>
                                                    {getOrderState(ord)}
                                                </div>
                                                <div className='_download'>
                                                    {getOrderActions(ord)}
                                                </div>
                                            </div>
                                        )
                                    })
                            }
                            {
                                !subscriptionInvoice && !paymentOrders.length ?
                                    <div className='noPayments'>
                                        <p>No hay pagos registrados todavía</p>
                                        <p className='small'>A medida que se realicen recargas o pagos, verás sus facturas en esta lista</p>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ActionCards isOpen={addCardOpen} professionalProfile={currentUser.professionalProfile} onRequestClose={closeCardModal} />
            <AccountPayment isOpen={addPayment} onRequestClose={closePaymentModal} paymentMethods={paymentMethods} />
            <SpeiConfirm speiOrder={displaySpei} email={currentUser.professionalProfile.email} isOpen={showSpei} onRequestClose={closeSpeiConfirm} />
            <CancelSubscription isOpen={openCancelSubs} currentUser={currentUser} onClose={closeCancelSubscriptionModal} />
        </div >
    )
}

export default SetupPagosFiscalPop;