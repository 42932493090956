/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";
import useComponentVisible from "../Hooks/useComponentIsVisible";

import './Input.scss';

interface SelectProps {
    onChange: Function;
    label?: string;
    value: any;
    disabled?: boolean;
    hasError?: boolean;
    errorLabel?: string;
    captionLabel?: string;
    displayLabel?: boolean;
    charLimit?: number;
    placeholder?: string;
    options: { label: string, display?: string; value: any, disabled?: boolean }[];
    // Optional extension for UX
    noOptionsDisplay?: JSX.Element;
}

const SelectNoOption = ({
    classInput,
    isActive,
    activate,
    deactivate,
    placeholder,
    noOptionsDisplay,
}: { classInput: string, isActive: boolean, activate: () => void, deactivate: () => void, placeholder: string, noOptionsDisplay: JSX.Element }) => {
    // For noOptions Display Click events
    const { ref: noOptionRef, isComponentVisible } = useComponentVisible(false)

    useEffect(() => {
        if (isActive && !isComponentVisible) {
            deactivate()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, isComponentVisible,])

    return (
        <div className={classInput} onClick={activate} ref={noOptionRef}>
            <div className="noOptionsContainer" >
                {noOptionsDisplay}
            </div>
            <div className={`selectContent _placeholdered`}>
                <span>{placeholder || ''}</span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none">
                <path d="M15 1L7.98532 8L1 1" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div >
    )
}

const Select = (props: SelectProps) => {
    const selectRef = React.createRef<HTMLSelectElement>();
    const [active, setActive] = useState(false);


    const onSelectRef = () => { }

    const activate = () => {
        setActive(true);
    }

    const deactivate = () => {
        setActive(false);
    }

    const substringChars = (opt: { label: string; display?: string; value: any }) => {
        const text: string = (!!opt && !!opt.label) && !!props.displayLabel ? opt.label : (!!opt && (opt.display || opt.label) || `${props.value}`);
        const exceeds = text.length > (props.charLimit || 0);
        if (!!props.charLimit) {
            return `${text.substring(0, props.charLimit)}${exceeds ? '...' : ''}`;
        } else {
            return text;
        }
    }

    const changeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const options = props.options.map(o => Object.assign({}, o));
        const candidate = options.map(o => { o.value = `${o.value}`; return o }).find(c => c.value === value);
        if (!candidate) {
            return props.onChange(value);
        }

        // Process Boolean and non string-reversible values
        const option = props.options.find(o => o.label === candidate.label);
        props.onChange(option.value);
    }

    const classInput = `inputWrapper ${props.disabled ? 'disabled' : ''} ${active ? 'active' : ''} ${props.hasError ? 'error' : ''} ${!!props.noOptionsDisplay ? 'hasDisplay' : ''}`;
    const opt = props.options.find(o => o.value === props.value);

    const renderSelectDefault = () => {
        const spanText = substringChars(opt) || props.placeholder || ''
        const spanFontSize = spanText.length > 38 ? '12.8px' : '12.8px';
        return (
            <div className={classInput} >
                <select ref={selectRef} onFocus={activate} onBlur={deactivate} onChange={changeValue} value={props.value} disabled={!!props.disabled} placeholder={props.placeholder || ''}>
                    {
                        props.placeholder ?
                            <option value="" disabled>{props.placeholder}</option>
                            : ''
                    }
                    {props.options.map((e, i) => {
                        if (!!e.disabled) {
                            return (
                                <option value={e.value} key={i} disabled>
                                    {e.label}
                                </option>
                            )
                        }
                        return (
                            <option value={e.value} key={i}>
                                {e.label}
                            </option>
                        )
                    })}
                </select>
                <div className={`selectContent ${!opt ? '_placeholdered' : ''}`} onClick={onSelectRef}>
                    <span style={{ fontSize: spanFontSize }}>{spanText}</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none">
                    <path d="M15 1L7.98532 8L1 1" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div >
        )
    }

    const renderSelectNoOptions = () => {
        return (
            <SelectNoOption
                classInput={classInput}
                isActive={active}
                activate={activate}
                deactivate={deactivate}
                placeholder={props.placeholder}
                noOptionsDisplay={props.noOptionsDisplay}
            />
        )
    }

    const optionSelected = props.options.find(o => o.value === props.value)
    return (
        <div className={`inputBlock selectType ${active ? 'active' : ''} ${props.disabled ? 'disabled' : ''} ${props.value || props.placeholder || !!optionSelected ? 'dirty' : ''} ${props.hasError ? 'error' : ''}`} >
            {
                !!props.label ?
                    <label>
                        {props.label}
                    </label>
                    : <label></label>
            }
            {
                !props.options.length && props.noOptionsDisplay ?
                    renderSelectNoOptions()
                    :
                    renderSelectDefault()
            }
            <div className="errorText">
                {
                    props.hasError ?
                        <span className="errorText">{props.errorLabel}</span>
                        : ''
                }
                {
                    props.captionLabel && !props.hasError ?
                        <span className="captionText">{props.captionLabel}</span>
                        : ''
                }
            </div>
        </div >
    )

}


export default Select;