import React from 'react'

import './CartaPorte.map.scss';

// basic react function component that returns an iframe within 2 divs
export default function GoggleMapWrapper({ googleMapsUrl }: { googleMapsUrl: string }) {
    return (
        <div className='GoogleMapIframe'>
            <div className='_overflowIframe'>
                {
                    !googleMapsUrl ?
                        null :
                        <iframe
                            title='Direcciones'
                            frameBorder="0" style={{ border: 0, borderRadius: '8px' }}
                            referrerPolicy="no-referrer-when-downgrade"
                            src={googleMapsUrl}
                            allowFullScreen />
                }
            </div>
        </div>
    )
}