
import React, { useEffect, useState } from 'react';
import { BaseReact } from '../../../../base.model';
import Input from '../../../Forms/Input';
import MoneySvg from '../../../visuals/svg/moneyIcon';

import { BankProject, BankProjectConcept_Expense } from '../../../../models/Administracion/Bank.Projects'

import moment from 'moment';
import Button from '../../../Forms/Button';
import ItemMenu from '../../../Forms/menu/MenuComponent';
import { numberToCurrencyString } from '../../../../services/formatting';
import unifiedMoment from '../../../../services/unifiedMoment';
import { ProjectionExpenseNewDialog } from './Projection.expense.dialog';
import { ProjectionGastosRoute } from './Projection.gastos.ruta';
import { useMutation } from '@apollo/react-hooks';
import { graphqlSchema } from '../../../../services/graphql.schema';
import sharedToasterSubject from '../../../../services/shared.toasterSubject';

import './Projection.gastos.new.scss';

interface ProjectsProps {
    history: BaseReact['history']
    editingProject?: BankProject<'expense'>
}

export function ProjectionGastosNew({
    history,
    editingProject
}: ProjectsProps) {

    const [expenseProject, setExpenseProject] = useState<BankProject<'expense'>>({
        type: 'expense',
        name: '',
        createdAt: moment().add(1, 'month').startOf('month').valueOf(),
        expenses: []
    })
    const [_openExpenses, setOpenExpenses] = useState(false);
    const [expenseToEdit, setExpenseToEdit] = useState<number>(null);

    const [isSaving, setIsSaving] = useState(false);
    const [grphUpdateTrigger, setGraphUpdateTrigger] = useState(0)

    useEffect(() => {
        if (!!editingProject && !!editingProject._id) {
            setExpenseProject(editingProject)
        }
    }, [editingProject])

    const [_createExpenseProject] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.createExpenseProject, {
        onCompleted: ({ createExpenseProject }: { createExpenseProject: BankProject<'expense'> }) => {
            console.log('<ProjectionGastosNew> createExpenseProject: ', createExpenseProject);
            sharedToasterSubject.next({ type: 'confirm', message: `Proyecto de gasto creado correctamente` })
            setIsSaving(false);
            history.push(`/administration/projection/${createExpenseProject._id}`)
        },
        onError: (e) => {
            console.error('<ProjectionGastosNew> Error creating project: ', e.graphQLErrors);
            sharedToasterSubject.next({ type: 'error', message: `Error al crear proyecto de gasto: ${e.graphQLErrors[0].message}` })
            setIsSaving(false);
        }
    })

    const [_updateExpenseProject] = useMutation(graphqlSchema.FISCALPOP.BANCOS.PROFESSIONAL.PROJECTIONS.updateExpenseProject, {
        onCompleted: ({ updateExpenseProject }: { updateExpenseProject: BankProject<'expense'> }) => {
            console.log('<ProjectionGastosNew> updateExpenseProject: ', updateExpenseProject);
            sharedToasterSubject.next({ type: 'confirm', message: `Proyecto de gasto actualizado` })
            setIsSaving(false);
            history.push(`/administration/projection/${updateExpenseProject._id}?recall=${Date.now()}`)
        },
        onError: (e) => {
            console.error('<ProjectionGastosNew> Error creating project: ', e.graphQLErrors);
            sharedToasterSubject.next({ type: 'error', message: `Error al crear proyecto de gasto: ${e.graphQLErrors[0].message}` })
            setIsSaving(false);
        }
    })

    const handleNameChange = (name: string) => {
        setExpenseProject(eP => ({ ...eP, name }));
    }

    const handleStartOfChange = (createdAt: Date) => {
        setExpenseProject(eP => ({ ...eP, createdAt: createdAt.getTime() }));
    }

    const handleAddExpense = (expense: BankProjectConcept_Expense) => {
        setExpenseProject(eP => {
            const expenses = eP.expenses.concat([expense])
            return ({ ...eP, expenses })
        });
        setGraphUpdateTrigger(Date.now())
        closeExpense()
    }

    const openExpense = () => {
        setOpenExpenses(true)
    }

    const closeExpense = () => {
        setOpenExpenses(false)
    }

    const editExpense = (index: number) => () => {
        setExpenseToEdit(index);
        openExpense()
    }

    const deleteExpense = (index: number) => () => {
        setExpenseProject(eP => {
            const expenses = eP.expenses.filter((e, i) => i !== index);
            return ({ ...eP, expenses })
        });
        setGraphUpdateTrigger(Date.now())
    }

    const handleUpdateExpense = (expense: BankProjectConcept_Expense) => {
        const index = expenseToEdit;
        setExpenseProject(eP => {
            const expenses = eP.expenses;
            expenses[index] = expense;
            return ({ ...eP, expenses })
        });
        setExpenseToEdit(null);
        setGraphUpdateTrigger(Date.now())
        closeExpense()
    }

    const handleCancel = () => {
        if (editingProject) {
            history.push(`/administration/projection/${editingProject._id}`)
        } else {
            history.push(`/administration/projection`)
        }
    }

    const submitExpenseProject = () => {
        console.log(`submitExpenseProject - expenseProject: `, expenseProject);
        setIsSaving(true);
        _createExpenseProject({
            variables: {
                project: expenseProject
            }
        })
    }

    const onUpdateExpenseProject = () => {
        const expenses: BankProjectConcept_Expense[] = expenseProject.expenses.map(e => ({
            _id: e._id,
            name: e.name,
            amount: e.amount,
            expenseType: e.expenseType || 'total',
            monthRepetition: e.monthRepetition,
            startsAt: e.startsAt
        }))
        const expenseUpdateInput: BankProject<'expense'> = Object.assign({}, {
            _id: expenseProject._id,
            name: expenseProject.name,
            createdAt: expenseProject.createdAt,
            type: 'expense',
            expenses
        } as BankProject<'expense'>)
        console.log(`updateExpenseProject - expenseProject: `, expenseUpdateInput);
        setIsSaving(true);
        _updateExpenseProject({
            variables: {
                project: expenseUpdateInput
            }
        })
    }

    const isValid = (expenseProject.name && expenseProject.expenses.length)

    return (
        <div id='ProjectosGastosNew' className='card'>
            <div className='title'>
                <MoneySvg width={26} color={'#AC2E78'} />
                {
                    editingProject ?
                        <h4>
                            Editar Proyecto de Gasto
                        </h4>
                        :
                        <h4>
                            Creación de Proyectos de Gasto
                        </h4>
                }
            </div>
            <div className='row'>
                <h4>
                    Proyecto
                </h4>
                <div className='row two start'>
                    <Input
                        label='Tītulo de Proyecto'
                        type='text'
                        value={expenseProject.name}
                        onChange={handleNameChange}
                        placeholder="E.g. vacaciones, etc."
                        hasError={!expenseProject.name && !!expenseProject.expenses.length}
                        errorLabel={`Es necesario agregar un nombre`}
                    />
                    <Input
                        label='Fecha de Inicio'
                        type='date'
                        minDate={moment().subtract(1, 'year').startOf('year').toDate()}
                        maxDate={moment().add(1, 'year').endOf('year').toDate()}
                        value={expenseProject.createdAt}
                        onChange={handleStartOfChange}
                    />
                </div>
            </div>
            <div className='row gastosSection'>
                <h4>
                    Gastos
                </h4>
                <Button
                    tertiary={true}
                    handleClick={openExpense}
                >
                    <div className='iconLabel'>
                        <span className='material-icons'>
                            add
                        </span>
                        <span>
                            Agregar gasto nuevo
                        </span>
                    </div>
                </Button>

                <div className='gastosList'>
                    <div className='gasto header'>
                        <p>Nombre de gasto</p>
                        <p className='center'>Cantidad</p>
                        <p className='center'>Número de Meses</p>
                        <p>Fecha de Inicio & Cierre</p>
                    </div>
                    {
                        expenseProject.expenses.map((expense, i) => {

                            return (
                                <div className='gasto' key={i}>
                                    <p>{expense.name}</p>
                                    <p className='center'>${numberToCurrencyString(expense.amount)}</p>
                                    <p className='center'>
                                        {
                                            expense.monthRepetition > 1 ? `${expense.monthRepetition} Meses`
                                                : 'Evento Único'
                                        }
                                    </p>
                                    {
                                        expense.monthRepetition > 1 ?
                                            <div className='_date dual'>
                                                <p>{unifiedMoment(expense.startsAt).format('MMM YYYY')}</p>
                                                <p className='center centerY'>-</p>
                                                <p>{unifiedMoment(expense.startsAt).add(expense.monthRepetition, 'month').format('MMM YYYY')}</p>
                                            </div>
                                            :
                                            <div className='_date'>
                                                <p> {unifiedMoment(expense.startsAt).format('MMM YYYY')}</p>
                                            </div>

                                    }
                                    <ItemMenu widthBehaviour={'auto'} openOrientation={'left'}
                                        triggerContent={<span className="material-icons _settings">more_vert</span>}
                                        noPadding={true}
                                    >
                                        <div className='cardMenu'>
                                            <div className='_option' onClick={editExpense(i)}>
                                                <span className="material-icons">
                                                    edit
                                                </span>
                                                <p>Editar gasto</p>
                                            </div>
                                            <div className='_option _warning' onClick={deleteExpense(i)}>
                                                <span className="material-icons">
                                                    delete
                                                </span>
                                                <p>Borrar gasto</p>
                                            </div>
                                        </div>
                                    </ItemMenu>
                                </div>
                            )
                        })
                    }
                    {
                        expenseProject.expenses.length ?
                            null :
                            <div className='row _empty'>
                                <div className='center centerY'>
                                    <p className='lightGray'>
                                        Agrega nuevos gastos para configurar tu proyecto
                                    </p>
                                    <p className='lightGray small'>
                                        Asociarás movimientos a estos gastos como un presupuesto especial y temporal
                                    </p>
                                </div>
                            </div>
                    }
                </div>
            </div>
            {
                expenseProject.expenses.length ?
                    <div className='row rutaSection'>
                        <h4>
                            Ruta
                        </h4>
                        <ProjectionGastosRoute expenses={expenseProject.expenses} lastUpdateTrigger={grphUpdateTrigger}/>
                    </div>
                    : null
            }
            <div className='row two actions'>
                <div className='start'>
                    <Button secondary handleClick={handleCancel}>
                        <span>
                            Cancelar
                        </span>
                    </Button>
                </div>
                <div className='end'>
                    {
                        editingProject ?
                            <Button primary disabled={!isValid || isSaving} handleClick={onUpdateExpenseProject}>
                                <span>
                                    Guardar cambios
                                </span>
                            </Button>
                            :
                            <Button primary disabled={!isValid || isSaving} handleClick={submitExpenseProject}>
                                <span>
                                    Crear Proyecto
                                </span>
                            </Button>
                    }
                </div>
            </div>
            <ProjectionExpenseNewDialog
                isOpen={_openExpenses}
                onRequestClose={closeExpense}
                onNewExpense={handleAddExpense}
                onEditExpense={handleUpdateExpense}
                toEditExpense={expenseToEdit === null ? null : expenseProject.expenses[expenseToEdit]}
                startDate={expenseProject.createdAt}
                existingExpenseNames={expenseProject.expenses.map(e => e.name.toLowerCase())}
            />

        </div>
    )
}