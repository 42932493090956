import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { PaybookTransaction } from '../../../../models/Administracion/Bank.Transactions';
import { numberToCurrencyString } from '../../../../services/formatting';
import Button from '../../../Forms/Button';
import Select from '../../../Forms/Select';

import { CATEGORIES_LIST, INGRESOS_CATEGORIES_LIST } from '../../helpers/categories.list';


import './MovimientosConfigure.scss'

interface MovimientosConfigureToasterProps {
    movements: PaybookTransaction[]
    projectOptions: CategoryProjectOptions[];
    onDeselectAll: () => void;
    onCategorize: (category: string, subcategory: string) => void;
    onProjectAssign: (projectId: string, projectName: string, projectTrackId: string) => void;
}

export interface CategoryProjectOptions {
    label: string;
    value: string;
    type: 'expense' | 'business';
    incomes: {
        label: string;
        value: string;
    }[]
    expenses: {
        label: string;
        value: string;
    }[]
}

function MovimientosConfigureToaster({
    movements,
    projectOptions,
    onDeselectAll,
    onCategorize,
    onProjectAssign
}: MovimientosConfigureToasterProps) {

    const [open, setOpen] = useState(false);
    const [openMovePreview, setOpenMovePreview] = useState(false)

    const [category, _setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');

    const [asProject, setAsProject] = useState(false);
    const [projectId, _setProjectId] = useState('');
    const [trackFilter, _setTrackFilter] = useState<'' | 'incomes' | 'expenses'>('');
    const [projectTrackId, _setProjectTrackId] = useState('');

    const toggle = () => {
        setOpen(!open);
    }

    const toggleAsProject = () => {
        setAsProject(!asProject);
    }

    const toggleMovePreview = () => {
        setOpenMovePreview(!openMovePreview);
    }

    const setCategory = (cat: string) => {
        _setCategory(cat);
        setSubcategory('')
    }

    const setProjectId = (proj: string) => {
        _setProjectId(proj);
        _setProjectTrackId('')
        // Pre filter to expenses only for expense type project
        const selected = projectOptions.find(pO => pO.value === proj)
        if (selected.type === 'expense') {
            _setTrackFilter('expenses')
        } else {
            // Left open
            _setTrackFilter('')
        }
    }

    const applyCategory = () => {
        // Extract category from subcategory composite to support "Quick Access" Ingresos Categories List
        const _category = subcategory.split('-')[0]
        onCategorize(_category, subcategory);
        setCategory('')
        setSubcategory('')
        setOpen(false);
    }

    const applyProject = () => {
        const _selected = projectOptions.find(pO => pO.value === projectId)
        onProjectAssign(projectId, _selected.label, projectTrackId)
        setAsProject(false)
        _setProjectId('')
        _setTrackFilter('')
        _setProjectTrackId('')
        setOpen(false);
    }

    const deselect = () => {
        _setCategory('')
        setSubcategory('')
        onDeselectAll()
        setOpenMovePreview(true)
    }

    const ALL_CATEGORIES = INGRESOS_CATEGORIES_LIST.concat(CATEGORIES_LIST)
    const selectedCategory = ALL_CATEGORIES.find(cat => cat.value === category);
    const SUBCATEGORIES_LIST = !selectedCategory ? [] : selectedCategory.children;
    const ALL_CATEGORIES_OPTIONS: { value: string, label: string, disabled?: boolean }[] = INGRESOS_CATEGORIES_LIST.concat([{ value: '__', label: ' ', disabled: true } as any]).concat(CATEGORIES_LIST)

    const PROJECT_SELECTED: CategoryProjectOptions = projectOptions.find((pO) => pO.value === projectId)
    const PROJECT_TRACK_FILTER = [{ label: 'Ingreso', value: 'incomes' }, { label: 'Egreso', value: 'expenses' }]
    const PROJECT_CONCEPTOS_LIST: { label: string, value: any }[] = (!projectId || !trackFilter) ? [] : PROJECT_SELECTED[trackFilter]


    return (
        <div className='__centering'>

            <div className={`card table _movementsConfigure ${movements.length ? 'peek' : ''} ${open ? 'open' : ''} ${openMovePreview ? '' : 'noPeek'}`}>
                <div className='cardTitle withClose'>
                    <p>
                        Administrar Categorías
                    </p>
                    <i className='material-icons noselect' onClick={toggle}>
                        expand_less
                    </i>
                    <div className='_preview'>
                        <p>
                            {movements.length} movimientos seleccionados
                        </p>
                        <p onClick={deselect}>
                            <span>
                                Deselect all
                            </span>
                        </p>
                    </div>
                </div>
                <div className='cardBody'>
                    <div className='inputWrapper'>
                        <div className='categoryChoose'>
                            <Select label="Categorías"
                                value={category}
                                onChange={setCategory}
                                placeholder="Elige tu Categoría"
                                options={ALL_CATEGORIES_OPTIONS}
                                disabled={asProject} />
                            <div className='subcatPadding'>
                                <Select label="Subcategorías"
                                    value={subcategory}
                                    onChange={setSubcategory}
                                    placeholder="Elige tu Subcategoría"
                                    disabled={asProject || !SUBCATEGORIES_LIST.length}
                                    options={SUBCATEGORIES_LIST}
                                />
                            </div>
                        </div>
                        <div className={`categoryForProject  ${asProject ? 'asProject' : ''}`}>
                            <div className='row two gap-compressed'>
                                <Select label="Proyecto"
                                    value={projectId}
                                    onChange={setProjectId}
                                    placeholder="Elige tu Poyecto"
                                    options={projectOptions}
                                    noOptionsDisplay={
                                        <div className='__noOptionsProject'>
                                            <p className='message'>
                                                ¡Debes crear un proyecto para que aparezca aquí!
                                            </p>
                                            <p className='link'>
                                                Ve a la página de <NavLink to="/administration/projection">Proyecciones</NavLink>
                                            </p>
                                        </div>
                                    }
                                />
                                <Select label="Ingreso o Egreso"
                                    value={trackFilter}
                                    onChange={_setTrackFilter}
                                    disabled={!projectId || PROJECT_SELECTED?.type === 'expense'}
                                    placeholder="Elige Ingreso o Egreso"
                                    options={PROJECT_TRACK_FILTER} />
                                <div className='_empty' />
                                <Select label={`Concepto ${!trackFilter ? '' : 'de ' + trackFilter}`}
                                    value={projectTrackId}
                                    disabled={!projectId || !trackFilter}
                                    onChange={_setProjectTrackId}
                                    placeholder={`Elige concepto ${!trackFilter ? '' : 'de ' + trackFilter}`}
                                    options={PROJECT_CONCEPTOS_LIST}
                                    hasError={!PROJECT_CONCEPTOS_LIST.length && !!trackFilter}
                                    errorLabel={`No tienes conceptos en ${trackFilter}`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`movementsPreview ${openMovePreview ? '' : 'hide'}`}>
                        <p>
                            {movements.length} movimientos seleccionados
                        </p>
                        <i className='material-icons noselect' onClick={toggleMovePreview}>
                            expand_less
                        </i>
                    </div>
                    <div className={`movementsPeek ${openMovePreview ? '' : 'hide'}`}>
                        <div className='_peek header'>
                            <p>
                                Cuenta / cliente
                            </p>
                            <p>
                                Monto
                            </p>
                        </div>
                        <div className='overflowPeek'>
                            {
                                movements.map((move) => (
                                    <div className='_peek' key={move.id_transaction}>
                                        <p className='description'>
                                            {move.description}
                                        </p>
                                        <p className='description'>
                                            ${numberToCurrencyString(move.amount)}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='actions'>
                        <Button tertiary={true} handleClick={toggleAsProject} disabled={asProject}>
                            <span>
                                Asignar a Proyecto
                            </span>
                        </Button>
                        <Button primary={true} disabled={asProject || !subcategory} handleClick={applyCategory}>
                            <span>
                                Categorizar
                            </span>
                        </Button>
                        <div className={`actionsForProject ${asProject ? 'asProject' : ''}`}>
                            <i className='material-icons noselect' onClick={toggleAsProject}>
                                arrow_back_ios
                            </i>
                            <Button primary={true} disabled={!(projectId && projectTrackId)} handleClick={applyProject}>
                                <span>
                                    Agregar a Proyecto
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MovimientosConfigureToaster;