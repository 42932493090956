import { Bill, ToBillArguments, ToBillEvent } from "../models/Factura";
import { calculateTotal } from "../utils/calculate.conceptos";

export const validateBillArgumentos = (billArgumento: ToBillArguments<ToBillEvent>, cfdi: Bill) => {
    const { total } = calculateTotal(cfdi.conceptos);
    if (billArgumento.multipayType === 1 || billArgumento.multipayType === 3) {
        // Avoid 
        if (billArgumento.upfront >= total) {
            return { key: 'upfront', text: 'El monto del adelanto es mayor que el total de la Factura' }
        }
    }
    if (billArgumento.multipayType === 2 && !billArgumento.multiPayments) {
        return { key: 'multiPayments', text: 'No se especificaron número de parcialidades' }
    }
    if (billArgumento.multipayType === 3 && !billArgumento.multiPayments) {
        return { key: 'multiPayments', text: 'No se especificaron número de parcialidades' }
    }
    if (billArgumento.multipayType === 3 && billArgumento.multiPayments < 2) {
        return { key: 'multiPayments', text: 'Adelanto y parcialidades necesita mínimo 2 parcialidades' }
    }

    // Guard agains having a PPD bill with NO multipayments
    if(cfdi.metodoPago === 'PPD' && !billArgumento.multiPayments){
        return { key: 'multiPayments', text: 'Factura PPD necesita al menos una parcialidad' }
    }

    return null;
}