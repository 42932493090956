import { BillCartaPorte } from "../../../models/Factura";


export const cartaPortePreMercanciasIsValid = (cartaPorte: BillCartaPorte, emisorRfc: string) => {
    const isThirdParty = cartaPorte.receptor.rfc !== emisorRfc;
    const isToMoral = cartaPorte.receptor.rfc.length === 12;

    if (!cartaPorte.receptor.rfc || !cartaPorte.receptor.nombre || !cartaPorte.receptor.regimen || !cartaPorte.receptor.usoCFDI || !cartaPorte.receptor.zip) {
        return { valid: false, reason: 'Es necesario definir un receptor', property: 'receptor' }
    }

    if (isThirdParty && cartaPorte.tipoDeComprobante === 'T') {
        return { valid: false, reason: 'Carta Porte a terceros debe ser Ingreso', property: 'tipoDeComprobante' }
    }
    if (isThirdParty && !cartaPorte.conceptos.length) {
        return { valid: false, reason: 'Es necesario al menos 1 concepto', property: 'conceptos' }
    }
    if (isThirdParty && isToMoral && !cartaPorte.conceptos.some(c => c.impuestos.some(im => im.tasa === 0.04))) {
        return { valid: false, reason: 'Carta Porte a Moral requiere 4% de IVA retenido', property: 'impuestos' }
    }

    const origen = cartaPorte.cartaPorte.ubicacion.origen
    const destino = cartaPorte.cartaPorte.ubicacion.destino

    if (!origen.domicilio.pais || !origen.domicilio.estado || !origen.domicilio.codigoPostal) {
        return { valid: false, reason: 'Domicilio origen requiere pais, estado y codigo postal', property: 'origen.domicilio' }
    }
    if (!destino.domicilio.pais || !destino.domicilio.estado || !destino.domicilio.codigoPostal) {
        return { valid: false, reason: 'Domicilio destino requiere pais, estado y codigo postal', property: 'destino.domicilio' }
    }
    if (!destino.distancia) {
        return { valid: false, reason: 'Domicilio destino requiere distancia aproximada', property: 'destino.distancia' }
    }

    const autotransporte = cartaPorte.cartaPorte.autotransporte;
    if (autotransporte.permisoSCT !== 'TPXX00' && !autotransporte.numPermisoSCT) {
        return { valid: false, reason: 'Tipo de permiso de SCT require un número de permiso', property: 'impuestos' }
    }
    if (!autotransporte.vehiculo.anoVehiculo || !autotransporte.vehiculo.placas || !autotransporte.vehiculo.tipoVehiculo) {
        return { valid: false, reason: 'Datos de vehículo incompletos', property: 'vehiculo' }
    }
    if (!autotransporte.seguro.aseguradora || !autotransporte.seguro.poliza) {
        return { valid: false, reason: 'Datos del seguro incompletos', property: 'seguro' }
    }
    const remolques = autotransporte.remolques;
    if (remolques.length && remolques.some(r => (!r.placa || !r.tipoRemolque))) {
        return { valid: false, reason: 'Datos de un remolque incompletos', property: 'remolques' }
    }

    const operadores = cartaPorte.cartaPorte.operadoresTransporte;
    if (!operadores.length) {
        return { valid: false, reason: 'Es necesario asignar operadores', property: 'operadores' }
    }
    if (operadores.length && !operadores.some(r => (r.tipo === '01'))) {
        return { valid: false, reason: 'Al menos 1 operador de transporte es necesario (tipo 01)', property: 'operadores' }
    }
    return { valid: true, reason: '', property: '' }
}