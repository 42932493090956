export const getQueryFromWindow = () => {
    if (window.location.search) {
        return window.location.search.replace('?', '').split('&').reduce((p, c) => {
            const touple = c.split('=');
            p[touple[0]] = touple[1];
            return p;
        }, {} as { [key: string]: string })
    } else {
        return {} as { [key: string]: string };
    }
}

export const getNormalizedCurrentUrl = () => {
    const queryString = window.location.search.replace('?', '');
    const setRedirectBaseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${!queryString ? '' : '?' + queryString}`;
    return setRedirectBaseUrl;
}