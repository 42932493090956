import { PaybookCredentialResponse } from "../../../../models/Administracion/Bank.Credentials"


export interface ActionByCode {
    action: boolean;
    actionText?: string;
    actionFunction?: 'sync' | 'retry' | 'assisted-retry' | 'assisted-visit-site' | 'support-error' | 'unavailable';
    daysAwait?: number;
}

export const stateIconByCode = (credential: PaybookCredentialResponse) => {
    if (credential.code === 102) {
        return 'sync'
    }
    else if (credential.code < 200) {
        return 'sync'
    }
    else if (credential.code === 401) {
        return 'no_encryption'
    }
    else if (credential.code === 403) {
        return 'priority_high'
    }
    else if (credential.code === 405) {
        return 'lock'
    }
    else if (credential.code === 406) {
        return 'report'
    }
    else if (credential.code === 408) {
        return 'lock'
    }
    else if (credential.code === 410) {
        return 'lock'
    }
    else if (credential.code === 411) {
        return 'lock_clock'
    }
    else if (credential.code === 412) {
        return 'settings'
    } else if (credential.code === 413) {
        return 'no_encryption'
    } else {
        return 'cancel'
    }
}

export const stateLabelByCode = (credential: {code: PaybookCredentialResponse['code']}) => {
    if (credential.code === 100) {
        return 'Iniciando'
    } else if (credential.code === 101) {
        return 'Iniciando'
    } else if (credential.code === 102) {
        return 'Procesando'
    } else if (credential.code === 103) {
        return 'Token Validando'
    } else if (credential.code === 401) {
        return 'Usuario Inválido'
    } else if (credential.code === 403) {
        return 'Token Inválido'
    } else if (credential.code === 405) {
        return 'Cuenta Bloqueada'
    } else if (credential.code === 406) {
        return 'Conflicto'
    } else if (credential.code === 408) {
        return 'Acción necesaria'
    } else if (credential.code === 410) {
        return 'Token Pendiente'
    } else if (credential.code === 411) {
        return 'Venció tiempo'
    } else if (credential.code === 412) {
        return 'Token Procesando'
    } else if (credential.code === 413) {
        return 'Token Procesando'
    } else if (credential.code === 500) {
        return 'Error Banco'
    } else if (credential.code === 501) {
        return 'Error Conexión'
    } else if (credential.code === 504) {
        return 'Error Conexión'
    } else if (credential.code === 509) {
        return 'Mantenimiento'
    } else {
        return 'Error Desconocido'
    }
}

export const requiresActionByCode = (credential: PaybookCredentialResponse): ActionByCode => {
    if (credential.code === 100) {
        return { action: false }
    } else if (credential.code === 101) {
        return { action: false }
    } else if (credential.code === 102) {
        return { action: false }
    } else if (credential.code === 103) {
        return { action: false }
    }
    // Errores de usuario
    else if (credential.code === 401) {
        return { action: true, actionText: 'Reintentar credenciales', actionFunction: 'retry' }
    } else if (credential.code === 403) {
        return { action: true, actionText: 'Reintentar Token', actionFunction: 'sync' }
    } else if (credential.code === 405) {
        return { action: true, actionText: 'Revisa cuenta primero', actionFunction: 'assisted-retry' }
    } else if (credential.code === 406) {
        if(!credential.can_sync && !credential.dt_ready) {
            return { action: false }
        }
        return { action: true, actionText: 'Inténtalo nuevamente', actionFunction: 'sync' }
    } else if (credential.code === 408) {
        return { action: true, actionText: 'Revisa cuenta primero', actionFunction: 'assisted-retry' }
    }
    // Interacciones con el usuario
    else if (credential.code === 410) {
        return { action: false }
    } else if (credential.code === 411) {
        return { action: true, actionText: 'Inténtalo nuevamente', actionFunction: 'sync', daysAwait: (1 / 24) * 0.5 }
    } else if (credential.code === 412) {
        return { action: false }
    } else if (credential.code === 413) {
        return { action: true, actionText: 'Reintentar credenciales', actionFunction: 'retry' }
    }
    // Error states need to be checked by support and eventually switch to a retry state
    else if (credential.code === 500) {
        return { action: true, actionText: 'Inténtalo nuevamente', actionFunction: 'support-error' }
    } else if (credential.code === 501) {
        return { action: true, actionText: 'Inténtalo nuevamente', actionFunction: 'support-error' }
    } else if (credential.code === 504) {
        return { action: true, actionText: 'Inténtalo nuevamente', actionFunction: 'retry' }
    } else if (credential.code === 509) {
        return { action: true, actionText: 'Inténtalo mas tarde', actionFunction: 'unavailable' }
    } 
    // Acciones Refresh
    else if (credential.is_twofa && ((Date.now() - credential.dt_execute) / (1 * 24 * 60 * 60 * 1000)) > 1) {
        return { action: true, actionText: 'Actualiza token', actionFunction: 'sync' }
    }
    else {
        return { action: false }
    }
}
