
import React, { useState } from 'react';
import { BillCartaPorte, BillConceptos } from '../../models/Factura';
import { ProfileQL } from '../../models/Profile';
import { numberToCurrencyString } from '../../services/formatting';
import Button from '../Forms/Button';
import Input from '../Forms/Input';
import Select from '../Forms/Select';
import { formatCurrencyStyled } from '../visuals/currency.formatted';
import { CartaPorteInfoConceptosDialog } from './dialogs/CartaPorte.info.conceptos';
import { CartaPorteConceptOptions } from './helper/CartaPorte.conceptos.options';

interface CartaPorteConceptosProps {
    isSelfReceptor: boolean;
    receptorIsMoral: boolean;
    cfdi: BillCartaPorte;
    currentUser: ProfileQL;
    onConceptoDispatch: (conceptos: BillConceptos[]) => void;
}


const conceptoOptions = CartaPorteConceptOptions()

export function CartaPorteConceptos({
    isSelfReceptor,
    receptorIsMoral,
    cfdi,
    currentUser,
    onConceptoDispatch,
}: CartaPorteConceptosProps) {

    const [conceptToAdd, setConceptToAdd] = useState<BillConceptos>(null)

    const _conceptOptionValue = () => {
        const option = conceptoOptions.find(cO => cO.data.claveProdServ === conceptToAdd.claveProdServ);
        return option.value
    }

    const _conceptTotal = (concept: BillConceptos) => {
        const subtotal = concept.valorUnitario * concept.cantidad;
        const taxesTotal = concept.impuestos.reduce((p, c) => {
            const amount = c.retencion ? -1 * (c.tasa * subtotal) : c.tasa * subtotal;
            return p + amount;
        }, 0)
        return Math.round((subtotal + taxesTotal) * 100) / 100;
    }

    const openNewConcepto = () => {
        setConceptToAdd({
            claveProdServ: '78101800',
            claveUnidad: 'E48',
            cantidad: 1,
            descripcion: 'Servicios de transporte de carga',
            valorUnitario: 0,
            impuestos: []
        })
    }

    const onConceptoSelect = (value: string) => {
        const option = conceptoOptions.find(cO => cO.value === value);
        setConceptToAdd((s) => {
            return Object.assign({}, s, { ...option.data })
        })
    }

    const handleConceptOptionChange = (property: 'valorUnitario' | 'cantidad') => (value: number) => {
        setConceptToAdd((s) => {
            const _s = Object.assign({}, s);
            _s[property] = value;
            return _s
        })
    }

    const handleConceptoDelete = (index: number) => () => {
        const conceptos = cfdi.conceptos;
        // dispatchBill({ property: 'conceptos', value: conceptos.filter((_, i) => i !== index) });
        onConceptoDispatch(conceptos.filter((_, i) => i !== index))
    }

    const handleConceptoAdd = () => {
        if (conceptToAdd.valorUnitario && conceptToAdd.cantidad) {
            const receptorIsMoral = cfdi.receptor.rfc.length === 12 && (cfdi.receptor.rfc !== currentUser.fiscalpopProfile.rfc);
            const conceptos = cfdi.conceptos;
            conceptos.push(conceptToAdd);
            conceptos.forEach((con) => {
                con.impuestos = !receptorIsMoral ? [{
                    type: 'iva',
                    retencion: false,
                    tasa: 0.16
                }] : [{
                    type: 'iva',
                    retencion: false,
                    tasa: 0.16
                }, {
                    type: 'iva',
                    retencion: true,
                    tasa: 0.04
                }]
            })
            // dispatchBill({ property: 'conceptos', value: conceptos });
            onConceptoDispatch(conceptos);
            setConceptToAdd(null);
        }
    }

    return (
        <div className='conceptosExtenal'>
            <h4>Conceptos</h4>
            <div className={`card table`}>
                <CartaPorteInfoConceptosDialog isSelfReceptor={isSelfReceptor} />
                {
                    isSelfReceptor ?
                        <div className="cardBody">
                            <p className='gray'>
                                Cuando la mercancía que mueves es de tu propiedad, no es necesario definir "conceptos", solamente mercancía.
                            </p>
                        </div>
                        :
                        <div className="cardBody">
                            <div className='addConcept'>
                                {
                                    !conceptToAdd ?
                                        <div className='row _action'>
                                            <div className="start">
                                                <Button tertiary={true} handleClick={openNewConcepto}>
                                                    <div className='withIcon'>
                                                        <span className='material-icons'>
                                                            add
                                                        </span>
                                                        <span>
                                                            Agregar concepto
                                                        </span>
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                        :
                                        <div className='row _form'>
                                            <div className='row _concept'>
                                                <Select
                                                    options={conceptoOptions}
                                                    onChange={onConceptoSelect}
                                                    value={_conceptOptionValue()}
                                                    label={'Concepto a agregar'}
                                                    captionLabel={`Clave SAT: ${conceptToAdd.claveProdServ}`}
                                                />
                                                <Input
                                                    type='number'
                                                    isCurrency={true}
                                                    min={0}
                                                    label={'Valor unitario'}
                                                    onChange={handleConceptOptionChange('valorUnitario')}
                                                    value={conceptToAdd.valorUnitario}
                                                />
                                                <Input
                                                    type='number'
                                                    min={0}
                                                    label={'Cantidad'}
                                                    onChange={handleConceptOptionChange('cantidad')}
                                                    value={conceptToAdd.cantidad}
                                                />
                                                <div className='centerY start _totalLg'>
                                                    <p className='small gray'>
                                                        Total: <br className='_onSMall' />{formatCurrencyStyled(_conceptTotal(conceptToAdd))}
                                                    </p>
                                                    {
                                                        receptorIsMoral ?
                                                            <p className='small lightGray'>
                                                                IVA Retenido (4%)
                                                            </p>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            <div className='doubleTotal row'>
                                                <div className='centerY start _totalLg' />
                                                <div className='centerY start _totalSm'>
                                                    <p className='small gray'>
                                                        Total: {formatCurrencyStyled(_conceptTotal(conceptToAdd))}
                                                    </p>
                                                    {
                                                        receptorIsMoral ?
                                                            <p className='small lightGray'>
                                                                IVA Ret. (4%)
                                                            </p>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className='end'>
                                                    <Button secondary={true} handleClick={handleConceptoAdd} disabled={!(conceptToAdd.valorUnitario && conceptToAdd.cantidad)}>
                                                        <span className='small'>
                                                            Agregar Gasto Nuevo
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className='conceptList'>
                                <div className='concepto header'>
                                    <p>Concepto</p>
                                    <p>Valor unitario</p>
                                    <p className='_cantidad'>Cantidad</p>
                                    <p>Impuestos</p>
                                </div>
                                <div className='concepto headerSm'>
                                    <p>Conceptos</p>
                                </div>
                                {
                                    cfdi.conceptos.map((con, i) => {
                                        return (
                                            <div className='concepto' key={i}>
                                                <p className='description'>{con.descripcion}</p>
                                                <p>{formatCurrencyStyled(con.valorUnitario)} <span className='_cantidadSm small gray'>(x{con.cantidad})</span></p>
                                                <p className='_cantidad'>{con.cantidad}</p>
                                                <div className='row taxes'>
                                                    {
                                                        con.impuestos.map((tax, i) => (
                                                            <p key={i} className='small gray'>
                                                                IVA {tax.retencion ? 'ret.' : ''} {tax.tasa * 100}%
                                                            </p>
                                                        ))
                                                    }
                                                </div>
                                                <Button tertiary={true} setClass="_delete" handleClick={handleConceptoDelete(i)}>
                                                    <span className='material-icons'>
                                                        delete
                                                    </span>
                                                </Button>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    !cfdi.conceptos.length ?
                                        <div className='_emtpy'>
                                            <p>
                                                Agrega el concepto por servicio de transporte de carga
                                            </p>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}