import React from 'react';
import { BankProject, BankProjectionStatus_Summary } from '../../../../models/Administracion/Bank.Projects';

import moment from 'moment';

import './AdministrationHub.Proyections.Projects.scss'
import { labelFormat } from '../../../components/textFormatting';
import { NavLink } from 'react-router-dom';

interface AdministrationHubProjectsProps {
    onProjectToggle: (projectId: string) => () => void;
    projectsLoaded: boolean;
    projectDict: { [key: string]: boolean }
    projects: BankProject<'business'>[]
    projectActivity: BankProjectionStatus_Summary[]

}

function AdministrationHubProjects({ onProjectToggle, projectsLoaded, projectDict, projects, projectActivity }: AdministrationHubProjectsProps) {


    return (
        <div className='statusProjectsContainer'>
            <div className='card noShadow _projectList'>
                <div className='stateEntry header'>
                    <p className='_date'>Fecha</p>
                    <p className='_description'>Proyecciones</p>
                    <p className='_category'>Categoría</p>
                    <p className='_project'>Proyecto</p>
                </div>
                <div className='_scroller'>
                    {
                        projectActivity.map(pA => {
                            const type = pA.elementType === 'goal' ? 'Meta' : (pA.elementType === 'project_business' ? 'Proyecto Negocio' : 'Proyecto Gasto')
                            return (
                                <div className='stateEntry' key={pA.elementId}>
                                    <p className='lightGray small _date'>{moment(pA.dateTs).format('MMMM DD, YYYY')}</p>
                                    <div className='_description'>
                                        <p className='small'>{labelFormat(pA.description)}</p>
                                    </div>
                                    <p className='_category small'>{type}</p>
                                    <p className='lightGray small _project'>{pA.elementName || ''}</p>
                                </div>

                            )
                        })
                    }
                    {
                        projectsLoaded && !projectActivity.length ?
                            <div className='_isEmpty'>
                                <div>
                                    <p className='lightGray _info'>
                                        ¡No tienes ninguna proyección activa!
                                    </p>
                                    <p className='lightGray small'>
                                        Navega a la tab de <NavLink to="/administration/projection">Proyecciones</NavLink> y crea tu primer meta. <br /> Las actualizaciones aparecerán aquí.
                                    </p>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
            <div className='card noShadow _projectHistory'>
                <h4>Mis Proyectos</h4>
                <div className='_scroller'>
                    <div className='proyectGrouping'>
                        {
                            projects.map(p => {
                                return (
                                    <div className='proyectEntry' key={p._id}>
                                        <div className='_text'>
                                            <p>{p.name}</p>
                                            {
                                                p.expenses.length ?
                                                    <p className='small lightGray'>{p.expenses.length} concepto{p.expenses.length > 1 ? 's' : ''} de gastos</p>
                                                    : null
                                            }
                                            {
                                                p.incomes.length ?
                                                    <p className='small lightGray'>{p.incomes.length} concepto{p.incomes.length > 1 ? 's' : ''} de ingresos</p>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        projectsLoaded && !projects.length ?
                            <div className='_isEmpty'>
                                <div>
                                    <p className='lightGray _info'>
                                        ¡No tienes proyectos!
                                    </p>
                                    <p className='lightGray small'>
                                        Haz <NavLink to="/administration/projection">click aquí</NavLink> para crear tu primer proyecto
                                    </p>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

export default AdministrationHubProjects;