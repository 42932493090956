import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BarAndLineChart from '../../../Graphs/BarAndLine/BarAndLine.chart'
import { BankProjectConcept_Expense, BankProjectConcept_Income } from '../../../../models/Administracion/Bank.Projects';

interface BusinessActivityGraphProps {
    incomes: BankProjectConcept_Income[]
    expenses: BankProjectConcept_Expense[]
    lastUpdateTrigger?: number; // Forces Update refresh
}

interface GraphGroup {
    name?: string;
    color: string;
    values: { x: number, y: number }[]
};

export const BusinessActivityGraph = ({ incomes, expenses, lastUpdateTrigger }: BusinessActivityGraphProps) => {

    const [barGroups, setBarGroups] = useState<GraphGroup[]>([])
    const [lineGroups, setLineGroups] = useState<GraphGroup[]>([])

    useEffect(() => {
        const setExpenses = expenses.reduce((p, c) => {
            const cycles: GraphGroup['values'] = new Array(c.monthRepetition).fill(0).map((_d, i) => ({
                x: moment(c.startsAt).add(i, 'months').valueOf(),
                y: Math.round((c.amount / c.monthRepetition) * 100) / 100
            }));
            cycles.forEach((cycle) => {
                const exists = p.find(e => e.x === cycle.x)
                if (exists) {
                    exists.y += cycle.y
                } else {
                    p.push(cycle)
                }
            })
            return p;
        }, [] as GraphGroup['values']).sort((a, b) => a.x - b.x)

        const setIncomes = incomes.reduce((p, c) => {
            const cycles: GraphGroup['values'] = new Array(c.monthRepetition).fill(0).map((_d, i) => ({
                x: moment(c.startsAt).add(i, 'months').valueOf(),
                y: c.amount * Math.pow(1 + ((c.expectedMonthChange?.value || 0) / 100), i)
            }));
            cycles.forEach((cycle) => {
                const exists = p.find(e => e.x === cycle.x)
                if (exists) {
                    exists.y += cycle.y
                } else {
                    p.push(cycle)
                }
            })
            return p;
        }, [] as GraphGroup['values']).sort((a, b) => a.x - b.x)

        const setBalance = setExpenses.map(e => ({ ...e, type: 'expense' })).concat(setIncomes.map(e => ({ ...e, type: 'income' })))
            .reduce((p, c) => {
                const exists = p.find(e => e.x === c.x)
                const value = c.type === 'expense' ? (c.y * -1) : c.y;
                if (exists) {
                    exists.y += value
                } else {
                    p.push(({ x: c.x, y: value }))
                }
                return p;
            }, [] as GraphGroup['values']).sort((a, b) => a.x - b.x)

        setBarGroups([
            {
                name: 'Gastos',
                color: '#FBD5D5',
                values: setExpenses
            },
            {
                name: 'Ingresos',
                color: '#D5FBD5',
                values: setIncomes
            },
        ])
        if(setExpenses.length && setIncomes.length) {
            setLineGroups([{
                name: 'Balance',
                color: '#AC2E78',
                values: setBalance
            }])
        }

    }, [expenses, incomes, lastUpdateTrigger])

    const shouldRenderGraph = barGroups.some(b => b?.values?.length > 2) || lineGroups.some(b => b?.values?.length > 2)

    if (!shouldRenderGraph) {
        return (
            <div className='row graphSection'>
            </div>
        )
    }
    return (
        <div className='row graphSection'>
            <h4>
                Actividad esperada
            </h4>
            <div className='_activityGraph'>
                <BarAndLineChart margin={{ left: 40, top: 20, bottom: 20, right: 40 }}
                    barGroups={barGroups}
                    lineGroups={lineGroups}
                    xAxisFormatting={(d: number) => moment(d).format('MMMM')}
                    xAxisTicks={'from-data'}
                />
            </div>
        </div>
    )
}