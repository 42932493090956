import React, { useState } from "react";
import Button from "./Button";

import './Input.buttonUpload.scss';

interface InputProps {
    onChange?: Function;
    onBlur?: Function;
    placeholder?: string;
    label?: string;
    value: string | number;
    step?: number;
    accept?: string;
    disabled?: boolean;
    hasError?: boolean;
    errorLabel?: string;
    min?: string | number;
    max?: string | number;
    isCurrency?: boolean;
    isPercent?: boolean;
    isDecimalAsPercent?: boolean;
    filenameTrim?: number;
}

const UploadButton = React.forwardRef((props: InputProps, ref: React.Ref<HTMLButtonElement>) => {
    const selectRef = React.createRef<HTMLInputElement>();

    const [active, setActive] = useState(false);
    const [fileName, setFileName] = useState('');

    const activate = () => {
        setActive(true);
    }

    const deactivate = () => {
        setActive(false);
        if (!!props.onBlur) {
            props.onBlur(props.value);
        }
    }

    const selectFile = () => {
        selectRef.current.click();
    }

    const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.target && !!event.target.files && !!event.target.files[0] && !!event.target.files[0].name) {
            setFileName(event.target.files[0].name);
            if (props.onChange) {
                props.onChange(event.target.files[0]);
            }
        } else {
            setFileName('');
        }
    }


    const classInput = `inputWrapper ${props.disabled ? 'disabled' : ''} ${active ? 'active' : ''} ${props.hasError ? 'error' : ''}`;
    return (
        <div className={`uploadButtonBlock ${active ? 'active' : ''} ${props.disabled ? 'disabled' : ''} ${(props.value || props.value === 0) ? 'dirty' : ''}`}>
            {
                !!props.label ?
                    <label>
                        {props.label}
                    </label>
                    : <label></label>
            }
            <div className={`${classInput} ${'fileWrapper'} `} >
                <div className="fileInput">
                    <input type='file'
                        readOnly={!props.onChange}
                        ref={selectRef}
                        accept={props.accept}
                        onFocus={activate} onBlur={deactivate}
                        placeholder={props.placeholder || ''} onChange={changeValue} disabled={!!props.disabled} />
                    <Button disabled={props.disabled} handleClick={selectFile} primary={true}>
                        <span>
                            Seleccionar <span className="toHide">archivo</span>
                        </span>
                    </Button>
                    <p>{(fileName || 'Nombre de archivo...').substring(0, props.filenameTrim || 100)}</p>
                </div>
            </div>
            <div className="errorText">
                {
                    props.hasError ?
                        <span className="errorText">{props.errorLabel}</span>
                        : ''
                }
            </div>
        </div>
    )

})

export default UploadButton;