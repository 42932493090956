import moment from "moment";
import { BankBudgetsActivityPrediction, BankIncomesActivityPrediction } from "../../../../models/Administracion/Bank.Budgets";
import { BankProjectConcept_Expense, BankProjectConcept_Income } from "../../../../models/Administracion/Bank.Projects";
import { BankMovementSummary } from "../../../../models/Administracion/Bank.Transactions";
import { processQuantiles, sequencePredictSimple } from "./prediction.stats.simple";


interface BoxChartData {
    x: number;
    yTop: number;
    yTopOutlier: number;
    median?: number; // As Reference
    yBottom: number;
    yBottomOutlier: number;
}

/**
 * Build Base Exponential smoothing with fixed Params
 * phi: 0.8     |   Dampening
 * beta: 0.3    |   Trend smoothing
 * alpha: 0.4   |   Old vs Recent value weight
 * h: 3         |   Predictions (Set along with Dataset)
 */

export const clusterSummaryByDateRange = (summaries: BankMovementSummary[], range: 'natural' | 'month') => {
    return summaries.reduce((p, c) => {
        const _date = range === 'natural' ? c.date : moment(c.date).startOf(range).valueOf()
        const exists = p.find(e => e.date === _date);
        if (exists) {
            exists.expense += c.expense;
            exists.income += c.income;
        } else {
            p.push(Object.assign({}, c, { date: _date }))
        }
        return p;
    }, [] as BankMovementSummary[]).sort((a, b) => b.date - a.date)
}

export const calculateCurrentBudget = (budgetActivity: BankBudgetsActivityPrediction[]) => {
    return Math.round(budgetActivity.reduce((p, bA) => {
        return p.concat(bA.activity.filter(a => a.goal).map((subC) => ({ subcategory: subC.subcategory, goal: subC.goal || 0 })))
    }, [] as { goal: number, subcategory: string }[]).reduce((p, gBC) => p += gBC.goal, 0) * 100) / 100
}



// ************************
// **** Stats for Expenses
const formatExpenseByMonth = (budgetActivity: BankBudgetsActivityPrediction[]) => {
    const bA = budgetActivity.reduce((p, c) => {
        c.activity.forEach(a => a.expenses.forEach(e => {
            const exists = p.find(_p => _p.monthTs === e.monthTs);
            if (exists) {
                exists.expense += e.expense;
            } else {
                p.push({ expense: e.expense, monthTs: e.monthTs })
            }
        }))
        return p;
        // TODO Miercoles !! (Revisar sin el Outlier de Octubre) 
        // }, [] as { expense: number, monthTs: number }[]).sort((a, b) => a.monthTs - b.monthTs)
    }, [] as { expense: number, monthTs: number }[]).sort((a, b) => a.monthTs - b.monthTs)
    return bA
    // sorting is oldeest to newest
}


export const processExpenseBoxChartData = (
    budgetActivity: BankBudgetsActivityPrediction[],
    currentBudgetTotal: number,
    MONTHS_PREDICTED: number,
    lastEntryTs: number,
    projectExpenses: BankProjectConcept_Expense[] = []
) => {
    // 1. Format Data
    const generalExpenseProgress = formatExpenseByMonth(budgetActivity)

    console.group('EXPENSES')
    console.time('Expense Sequennce Predict')
    const { data, vectors, predictionVectors } = sequencePredictSimple(MONTHS_PREDICTED, lastEntryTs, generalExpenseProgress.map(m => ({ value: m.expense, monthTs: m.monthTs })))
    console.timeEnd('Expense Sequennce Predict')
    // >> Offset BoxChart From Prediction Expense
    const expenseBoxPlot = data.map((eBP) => {
        const applicableExpenses = projectExpenses.filter((pE) => pE.startsAt === eBP.x)
        const offsetAmount = applicableExpenses.reduce((p, c) => p += c.amount, 0);

        const _eBP = {
            x: eBP.x,
            median: eBP.median + offsetAmount,
            yBottom: eBP.yBottom + offsetAmount,
            yBottomOutlier: eBP.yBottomOutlier + offsetAmount,
            yTop: eBP.yTop + offsetAmount,
            yTopOutlier: eBP.yTopOutlier + offsetAmount,
        }
        return _eBP;
    })

    console.table(expenseBoxPlot)
    console.groupEnd()
    // 2. Project Expense Calculations for Future Offset 

    // >>(TODO)

    return { expenseBoxPlot, generalExpenseProgress, expenseVectorsPath: vectors, expensePredictionVectorsPath: predictionVectors }
}





// ************************
// **** Stats for Income
const formatIncomeByMonth = (incomeActivity: BankIncomesActivityPrediction[]) => {
    const iA = incomeActivity.reduce((p, c) => {
        c.incomes.forEach((inc) => {
            const exists = p.find(_p => _p.monthTs === inc.monthTs);
            if (exists) {
                exists.income += inc.income;
            } else {
                p.push({ ...inc }); // Spread, as it has the same props
            }
        })
        return p;
    }, [] as { income: number, monthTs: number }[]).sort((a, b) => a.monthTs - b.monthTs)
    return iA;
    // sorting is oldeest to newest
}


export const processIncomeBoxChartData = (
    incomeActivity: BankIncomesActivityPrediction[],
    MONTHS_PREDICTED: number,
    lastEntryTs: number,
    projectIncomes: BankProjectConcept_Income[] = []
) => {
    // 1. Format Data
    const generalIncomeProgress = formatIncomeByMonth(incomeActivity)

    console.group('INCOMES')
    console.time('Income Sequennce Predict')
    const { data: incomeBoxPlot, vectors, predictionVectors } = sequencePredictSimple(MONTHS_PREDICTED, lastEntryTs, generalIncomeProgress.map(m => ({ value: m.income, monthTs: m.monthTs })))
    console.timeEnd('Income Sequennce Predict')

    console.table(incomeBoxPlot)
    console.groupEnd()

    // 2. Project Income Calculations for Future Offset 

    // >>(TODO)

    return { incomeBoxPlot, generalIncomeProgress, incomeVectorsPath: vectors, incomePredictionVectorsPath: predictionVectors }
}




// ************************
// **** Stats for Balance

const formatBalanceByMonth = (
    generalIncomeProgress: { income: number, monthTs: number }[],
    generalExpenseProgress: { expense: number, monthTs: number }[]
) => {
    const balanceByMonth: { expense?: number, income?: number, monthTs: number }[] = [];
    generalIncomeProgress.forEach((inc) => {
        const exists = balanceByMonth.find(b => b.monthTs === inc.monthTs)
        if (exists) {
            exists.income += inc.income
        } else {
            balanceByMonth.push({ income: inc.income, expense: 0, monthTs: inc.monthTs })
        }
    })
    generalExpenseProgress.forEach((inc) => {
        const exists = balanceByMonth.find(b => b.monthTs === inc.monthTs)
        if (exists) {
            exists.expense += inc.expense
        } else {
            balanceByMonth.push({ expense: inc.expense, income: 0, monthTs: inc.monthTs })
        }
    })
    return balanceByMonth.map(bBM => ({ monthTs: bBM.monthTs, balance: bBM.income - bBM.expense })).sort((a, b) => a.monthTs - b.monthTs)
    // sorting is oldest to newest
}

export const processBalanceBoxChartData = (
    generalIncomeProgress: { income: number, monthTs: number }[],
    generalExpenseProgress: { expense: number, monthTs: number }[],
    MONTHS_PREDICTED: number,
    lastEntryTs: number,
    lastStartBalanceReference: number,
    projectExpenses: BankProjectConcept_Expense[] = [],
    projectIncomes: BankProjectConcept_Income[] = [],
) => {


    // 1. Format Data
    // >> raw is the change itself
    const rawGeneralRoiProgress = formatBalanceByMonth(generalIncomeProgress, generalExpenseProgress)

    // >> based is the change itself offset from last balance registered (it's cloned as sort() affects original array)
    let lastStepOffset = lastStartBalanceReference + 0;
    const basedGeneralBalanceProgress = (JSON.parse(JSON.stringify(rawGeneralRoiProgress)) as { monthTs: number, balance: number }[])
        .sort((a, b) => b.monthTs - a.monthTs) // reverse array (newest first)
        .map((rGRP: { monthTs: number, balance: number }) => {
            const d = ({ balance: rGRP.balance + lastStepOffset, monthTs: rGRP.monthTs })
            lastStepOffset -= rGRP.balance;
            return d;
        })
        .sort((a, b) => a.monthTs - b.monthTs) // reverse array again (old -> new)
    console.time('Balance Sequennce Predict')
    const { data: balanceBoxPlot, vectors, predictionVectors } = sequencePredictSimple(MONTHS_PREDICTED, lastEntryTs, basedGeneralBalanceProgress.map(m => ({ value: m.balance, monthTs: m.monthTs })), true)
    console.timeEnd('Balance Sequennce Predict')

    console.table(balanceBoxPlot)

    return { balanceBoxPlot, generalBalanceProgress: basedGeneralBalanceProgress, balanceVectorsPath: vectors, balancePredictionVectorsPath: predictionVectors }
}

// ************************



// ************************
// **** Stats for Balance V2 (Determine from Income / Expense Quantiles)
export const processBalanceBoxChartDataV2 = (
    incomeBoxPlot: BoxChartData[],
    expenseBoxPlot: BoxChartData[],
    lastStartBalanceReference: number = 0,
) => {
    const xEntries = [...new Set(incomeBoxPlot.map(iBP => iBP.x).concat(expenseBoxPlot.map(eBP => eBP.x)))].sort((a, b) => a - b)
    let lastStepOffset = lastStartBalanceReference + 0;
    const balanceBoxPlot = xEntries.map(x => {
        const expenseAt = expenseBoxPlot.find(eBP => eBP.x === x);
        const incomeAt = incomeBoxPlot.find(eBP => eBP.x === x);
        const balanceValues = Object.values(incomeAt).reduce((p, c) => {
            p.push(...Object.values(expenseAt).map(expAmount => c - expAmount))
            return p;
        }, [] as number[])
        const { generalQuartiles } = processQuantiles(balanceValues)
        const data: BoxChartData = ({
            x: x,
            yTopOutlier: generalQuartiles.ExtremeTop + lastStepOffset,
            yTop: generalQuartiles.Q3 + lastStepOffset,
            median: generalQuartiles.median + lastStepOffset,
            yBottom: generalQuartiles.Q1 + lastStepOffset,
            yBottomOutlier: generalQuartiles.ExtremeLow + lastStepOffset,
        })
        lastStepOffset = generalQuartiles.median + lastStepOffset
        return data;
    })
    return { balanceBoxPlot }
}


// !!!! CONTINUE HERE  !!!!!