import React, { useEffect, useState } from 'react';
import { BillConceptTax, FiscalpopImpuesto } from '../../models/Factura';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import Select from '../Forms/Select';
import { getImpuestoUniqueId } from '../../utils/impuestos.formatting';

interface FacturarConceptoImpuestoProps {
    i: number,
    handleAddNewTax: () => void,
    handleRemoveTax: (index: number) => (_: any) => void,
    impuesto: BillConceptTax,
    impuestosList: FiscalpopImpuesto[],
    handleImpuestoUpdate: (i: number) => (update: BillConceptTax) => void
}

interface ImpuestoOption {
    value: string,
    label: string
}

export function ConceptoElementImpuesto({
    i,
    impuesto,
    impuestosList,
    handleImpuestoUpdate,
    handleAddNewTax,
    handleRemoveTax
}: FacturarConceptoImpuestoProps) {
    const [rangeParams, setRangeParams] = useState([0, 0.16]);

    useEffect(() => {
        // Support for Recover, Edit
        if(!impuesto._uniqueId && impuestosList.length) {
            // No uniqueId, set it to avoid re-calculating on render
            const _uniqueId = getImpuestoUniqueId(impuesto, impuestosList)
            const _impuesto: BillConceptTax = Object.assign({}, impuesto, { _uniqueId });
            handleImpuestoUpdate(i)(_impuesto);
        }
    }, [i, impuesto, impuestosList, handleImpuestoUpdate])

    const _taxToBillConceptTax = (tax: FiscalpopImpuesto): BillConceptTax => ({
        type: tax.impuesto.toLowerCase() as 'iva' | 'ieps' | 'isr',
        tipo: tax.tipo,
        retencion: tax.retencion,
        exento: tax.exento,
        tasa: tax.factor === 'Tasa' ? tax.max : null,
        cuota: tax.factor === 'Cuota' ? tax.max : null,
        _uniqueId: tax._uniqueId
    })

    const handleImpuestoFilterChange = (property: 'type' | 'retencion' | '_uniqueId') => (value: any) => {
        const currentImpuesto = Object.assign({}, impuesto);
        let assigner: BillConceptTax = null;

        if (property === 'type' && currentImpuesto.type !== value) {
            if (value === 'iva') {
                // Reset to default IVA
                assigner = _taxToBillConceptTax(impuestosList.find(iL => iL._uniqueId === 'IVA-TRAS-FIJO-TASA-0.16'))
                setRangeParams([0, 16]);
            }
            if (value === 'ieps') {
                // Reset to default IEPS
                assigner = _taxToBillConceptTax(impuestosList.find(iL => iL._uniqueId === 'IEPS-TRAS-FIJO-TASA-0.03'))
                setRangeParams([0, 16]);
            }
            if (value === 'isr') {
                // Reset to default ISR
                const defaultTax = impuestosList.find(iL => iL._uniqueId === 'ISR-RETE-RANGO-TASA-0.35')
                assigner = _taxToBillConceptTax(defaultTax)
                // ISR is ranged, set here as there's no other option to invoke handle change
                setRangeParams([defaultTax.min || 0, defaultTax.max]);
            }
        }

        if (property === 'retencion' && currentImpuesto.retencion !== value) {
            const { value: uniqueId } = impuestoValueOptions(currentImpuesto.type, value)[0];
            const defaultTax = impuestosList.find(iL => iL._uniqueId === uniqueId)
            assigner = _taxToBillConceptTax(defaultTax)
            // ISR is ranged, set here as there's no other option to invoke handle change
            setRangeParams([defaultTax.min || 0, defaultTax.max]);
        }

        if (property === '_uniqueId' && currentImpuesto._uniqueId !== value) {
            const defaultTax = impuestosList.find(iL => iL._uniqueId === value)
            assigner = _taxToBillConceptTax(defaultTax)
            // ISR is ranged, set here as there's no other option to invoke handle change
            setRangeParams([defaultTax.min || 0, defaultTax.max]);
        }

        const _impuesto: BillConceptTax = Object.assign({}, currentImpuesto, assigner);

        console.group('HICHange')
        console.log('HICHange prop: ', i, property)
        console.log('HICHange curr: ', i, currentImpuesto)
        console.log('HICHange ass: ', i, assigner)
        console.log('HICHange UPDATE: ', i, _impuesto)
        console.groupEnd()

        if (!assigner) {
            console.error('No Assigner set! ', property, value, assigner, currentImpuesto)
            return;
        }

        handleImpuestoUpdate(i)(_impuesto);
    }

    const handleImpuestoRangeValue = (property: 'tasa' | 'cuota') => (value: number) => {
        // Check if this is tasa or cuota
        const currentImpuesto = Object.assign({}, impuesto);
        currentImpuesto[property] = value;
        // Assign is used to re-trigger a state change
        const _impuesto: BillConceptTax = Object.assign({}, currentImpuesto);
        handleImpuestoUpdate(i)(_impuesto);
    }

    const impuestoTypeOptions = () => {
        const impuestosSet = new Set(impuestosList.map(iL => iL.impuesto));
        const options: { value: string, label: string }[] = [];
        for (const [e] of impuestosSet.entries()) {
            options.push({ value: e.toLowerCase(), label: e })
        }
        return options;
    }

    const impuestoTrasRetOptions = (type: 'iva' | 'ieps' | 'isr') => {
        const _type = type.toUpperCase()
        const candidates = impuestosList.filter(iL => iL.impuesto === _type)
        const options: { value: boolean, label: string }[] = [];
        if (candidates.some(c => c.traslado)) {
            options.push({ value: false, label: 'Traslado' })
        }
        if (candidates.some(c => c.retencion)) {
            options.push({ value: true, label: 'Retención' })
        }
        return options;
    }

    const _taxLabel = (tax: FiscalpopImpuesto) => {
        if (tax.exento) {
            return 'Exento'
        }
        return tax.factor === 'Cuota' ? `$ ${tax.min !== null ? tax.min + ' - ' : ''}${tax.max} / unidad` : tax.min !== null ? `${(tax.min * 100).toFixed(1)} - ${(tax.max * 100).toFixed(1)}%` : `${(tax.max * 100).toFixed(1)} %`
    }

    const _taxOption = (tax: FiscalpopImpuesto): ImpuestoOption => ({
        // value: tax.exento ? null : tax.max,
        value: tax._uniqueId,
        label: _taxLabel(tax)
    })


    const impuestoValueOptions = (type: 'iva' | 'ieps' | 'isr', retencion: boolean) => {
        const _type = type.toUpperCase()
        const candidates = impuestosList.filter(iL => iL.impuesto === _type).filter((iL) => {
            if (!retencion && iL.traslado) {
                return true;
            }
            else if (retencion && iL.retencion) {
                return true;
            } else {
                return false;
            }
        });
        const options = candidates.map(_taxOption)
        return options;
    }

    const isRange = impuesto.tipo === 'Rango'

    return (
        <div className="impuesto">
            <i className="material-icons delete" onClick={handleRemoveTax(i)}>
                delete
            </i>
            <div className="row five md-four sm-two xs-gap-compressed" key={i}>
                <Select
                    label="Impuesto"
                    value={impuesto.type}
                    options={impuestoTypeOptions()}
                    onChange={handleImpuestoFilterChange('type')} />
                <Select
                    label="Traslado / Retencion"
                    value={impuesto.retencion}
                    options={impuestoTrasRetOptions(impuesto.type)}
                    onChange={handleImpuestoFilterChange('retencion')} />

                <Select
                    label="Valor del impuesto"
                    value={getImpuestoUniqueId(impuesto, impuestosList)}
                    options={impuestoValueOptions(impuesto.type, impuesto.retencion)}
                    onChange={handleImpuestoFilterChange('_uniqueId')}
                />
                {
                    isRange ?
                        (
                            impuesto.tasa !== null ?
                                <Input
                                    type="number"
                                    isPercent={true}
                                    isDecimalAsPercent={true}
                                    label={`Valor de la tasa`}
                                    placeholder=""
                                    value={impuesto.tasa}
                                    onChange={handleImpuestoRangeValue('tasa')}
                                    min={rangeParams[0]}
                                    max={rangeParams[1]}
                                    step={0.00001}
                                />
                                :
                                <Input
                                    type="number"
                                    label={`Valor de la cuota`}
                                    placeholder=""
                                    value={impuesto.cuota}
                                    onChange={handleImpuestoRangeValue('cuota')}
                                    min={rangeParams[0]}
                                    max={rangeParams[1]}
                                    step={0.01} />
                        )
                        : <div></div>
                }
                {
                    <Button secondary={true} handleClick={handleAddNewTax}>
                        <span>Agregar otro impuesto</span>
                    </Button>
                }
            </div>
        </div>
    )
}